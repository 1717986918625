import { css } from 'styled-components';
import { lightenHsla, setAlphaHsla } from '@sharkfinesse/sfl-lib';
import { uiBase } from './colors';

const Theme = ({ font, colors, scale, device: { min_sm, max_sm, max_xs } }) => ({
  base: ({
    $selected,
    $inverse,
    $focused,
    $expanded,
    $autosize,
    $invalid,
    $rounded,
    $topRounded,
    $align,
    $multiline,
  }) => css`
    border: 0;
    outline: none;
    width: 100%;
    font-family: ${font.family};
    text-align: ${$align};
    font-weight: ${font.weightNormal};
    padding: 0 2rem;
    line-height: 30px;
    ::placeholder {
      color: ${$inverse ? lightenHsla(50, uiBase) : lightenHsla(70, uiBase)};
    }
    ${$multiline &&
    css`
      line-height: inherit;
      padding: 1rem 2rem;
    `}
    ${$expanded &&
    css`
      resize: none;
      padding: 0;
      font-size: 16px;
      line-height: unset;
    `}
    ${$autosize &&
    css`
      resize: none;
      padding: 0;
      font-size: 16px;
    `}
  `,
  container: ({ $fullWidth }) => css`
    display: flex;
    position: relative;
    height: 100%;
    align-items: center;
    ${$fullWidth &&
    css`
      width: 100%;
    `}
  `,
  wrapper: ({
    $focused,
    $autosize,
    $expanded,
    $invalid,
    $selected,
    $inverse,
    disabled,
    $rounded,
  }) => {
    const boxShadow = hover => {
      if ($rounded || $inverse || $autosize || $expanded) return 'none';
      if ($selected)
        return `0 0 0 1px ${colors.primary}${hover ? `, 0 0 8px ${colors.primary};` : ';'}`;
      if ($invalid)
        return `0 0 0 ${$focused ? '2px' : '1px'} ${colors.invalid}${
          hover ? `,0 0 8px ${colors.invalid};` : ';'
        };`;
      if ($focused)
        return `0 0 0 2px ${colors.primary}${hover ? `, 0 0 8px ${colors.primary};` : ';'}`;
      return `0 0 0 1px ${colors.uiLight}${
        hover && !disabled ? `, 0 0 8px ${colors.uiLight};` : ';'
      }`;
    };
    const bg = hover => {
      if ($autosize || $expanded) return 'transparent';
      if ($inverse) {
        if (disabled) return setAlphaHsla(0.2, colors.white);
        if ($focused) return colors.white;
        if (hover) return setAlphaHsla(0.8, colors.white);
        return setAlphaHsla(0.6, colors.white);
      }
      if ($selected) return setAlphaHsla(0.15, colors.primary);
      if (disabled) return lightenHsla(3, colors.uiLightest);
      if ($invalid) return colors.white;
      return colors.white;
    };
    return css`
      color: ${disabled ? setAlphaHsla(0.5, font.color) : font.color};
      width: 100%;
      display: flex;
      transition: ${$inverse
        ? 'none'
        : 'background-color 200ms ease-in-out, ease-in-out, box-shadow 200ms ease-in-out'};
      border-radius: ${$rounded ? '60px' : '2px'};
      box-shadow: ${boxShadow()};
      &:hover {
        box-shadow: ${boxShadow(true)};
        ${$inverse &&
        css`
          background: ${bg(true)};
        `}
      }
      background: ${bg()};
    `;
  },
  beforeContainer: () => css`
    display: flex;
    padding: 0 3px;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  `,
  beforeContainerRelative: () => css`
    display: flex;
    padding: 0 3px;
    justify-content: center;
    align-items: center;
  `,
  afterContainer: () => css`
    display: flex;
    padding: 0 3px;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
  `,
  afterContainerRelative: () => css`
    display: flex;
    padding: 0 3px;
    justify-content: center;
    align-items: center;
  `,
  label: ({ $inverse, $align, $bold }) => css`
    display: flex;
    color: ${font.color};
    font-weight: ${$bold ? font.weightSemiBold : font.weightNormal};
    justify-content: ${$align};
    width: 100%;
    height: 100%;
    align-items: center;
    line-height: 18px;
    ${$inverse &&
    css`
      color: ${setAlphaHsla(0.75, colors.white)};
    `}
  `,
  textFieldContainer: ({ $fullWidth }) => css`
    display: grid;
    align-items: center;
    ${$fullWidth &&
    css`
      width: 100%;
    `}
  `,
  column: () => css`
    grid-template-columns: 1fr;
    grid-row-gap: 4px;
  `,
  row: ({ rowGap = '4px' }) => css`
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: ${rowGap};
    @media ${min_sm} {
      grid-row-gap: 0;
      grid-column-gap: 8px;
      grid-template-columns: 2fr 1.5fr;
    }
  `,
  inputColumn: () => css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  inputWrapper: () =>
    css`
      display: flex;
      flex-direction: row;
      position: relative;
    `,
  button: () =>
    css`
      padding: 0;
      z-index: 0;
      border-radius: 3px;
      &:focus {
        outline: 2px solid ${colors.primary};
        z-index: 10;
      }
    `,
  bottomContainer: () => css`
    width: 100%;
    height: 31px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 1;
    border-top: 1px solid ${colors.uiLight};
    min-height: 31px;
    align-items: center;
    div:last-child {
      border-left: 1px solid ${colors.uiLight};
    }
  `,
  bottomButton: () => css`
    min-height: 30px;
    height: 30px;
    align-items: center;
    width: 50%;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${lightenHsla(10, colors.uiLight)};
    color: ${colors.uiDarkest};
    cursor: pointer;
    &:focus {
      outline: 2px solid ${colors.primary};
      z-index: 10;
    }
    &:hover {
      background-color: ${lightenHsla(15, colors.uiLight)};
    }
  `,
  inputLabel: () => css`
    padding: 0px 2rem 2rem;
    text-align: center;
    line-height: 1;
  `,
  helpText: ({ $invalid }) => css`
    display: flex;
    justify-content: flex-end;
    color: ${$invalid ? colors.invalid : font.color};
    text-align: right;
  `,
  formGrid: () => css`
    display: grid;
    grid-row-gap: 8px;
  `,
  outputWrapper: ({ $align }) => css`
    color: ${colors.uiDark};
    font-size: ${font.sizeLarge};
    font-weight: ${font.weightSemiBold};
    background: ${lightenHsla(90, colors.uiBase)};
    border-radius: 2px;
    padding: 8px;
    text-align: ${$align};
    min-height: 46px;
  `,
  compactGrid: () => css`
    display: grid;
    grid-row-gap: 2px;
  `,
  expandedInput: () => css`
    padding: 6px;
    background: ${colors.uiLightest};
  `,
  expandedInputFirst: ({ open }) => css`
    border-radius: ${open ? '6px 6px 0 0' : '6px'};
    @media ${min_sm} {
      border-radius: ${open ? '0 6px 0 0' : '0 6px 6px 0'};
    }
  `,
  expandedInputLast: () => css`
    border-radius: 0 0 6px 6px;
    @media ${min_sm} {
      border-radius: 0 0 6px 0;
    }
  `,
  expandedLabel: () => css`
    display: flex;
    background: ${colors.uiLightest};
    width: fit-content;
    justify-self: start;
    height: 100%;
    align-items: center;
    @media ${max_xs} {
      width: 100%;
      justify-content: start;
      padding: 4px 10px 0px 10px;
    }
    @media ${min_sm} {
      justify-self: end;
      margin-right: -8px;
      padding: 10px;
    }
  `,
  expandedLabelFirst: () => css`
    @media ${min_sm} {
      border-radius: 6px 0 0 0;
    }
  `,
  expandedLabelLast: () => css`
    @media ${min_sm} {
      border-radius: 0 0 0 6px;
    }
  `,
  expandedToggle: ({ $expanded }) => css`
    display: flex;
    padding: 0;
    width: fit-content;
    justify-self: start;
    height: 100%;
    align-items: center;
    @media ${min_sm} {
      justify-self: end;
      margin-right: -8px;
      background: ${colors.uiLightest};
      padding: 4px 0 4px 4px;
      border-radius: ${$expanded ? '6px 0 0 0' : '6px 0 0 6px'};
    }
  `,
  expandedInputLabelContainer: () => css`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    @media ${max_xs} {
      flex-direction: row-reverse;
      justify-content: start;
    }
    @media ${min_sm} {
      justify-content: space-between;
    }
  `,
  mockInputWrapper: ({ $rounded }) => css`
    padding: ${$rounded ? '1.8rem 2rem' : '1rem 2rem'};
  `,
  panelBase: () => css`
    background: ${colors.white};
    margin-bottom: 0;
    max-width: none;
  `,
  panelHeadingWrapper: () => css`
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
  `,
  panelHeading: () => css`
    font-size: ${font.sizeNormal};
    font-weight: ${font.weightSemiBold};
    color: ${colors.uiDark};
    margin: 0;
  `,
  panelHeaderContainer: () => css`
    background: transparent;
    padding-bottom: 2px;
    border: none;
  `,
  autoSizeInput: () => css`
    resize: none;
    background: transparent;
    padding: 0;
    font-size: 16px;
  `,
  expandingTextArea: () => css`
    resize: none;
    background: transparent;
    padding: 0;
    font-size: 16px;
    line-height: unset;
  `,
  absoluteAfterContainer: () => css`
    position: absolute;
    right: 0;
    top: 0;
    background: ${colors.white};
  `,
});

export default Theme;
