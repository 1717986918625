import { all, call, put, select, take } from 'redux-saga/effects';
import { actionCreators as sessionActionCreators } from 'redux/modules/session';
import { types as persistenceTypes } from 'redux/modules/persistence';
import { map, benefitsSelectors } from '@sharkfinesse/sfl-lib';
import caluclateEvaluator from '../evaluator/calculateEvaluator';

function* reCalculate({ meta }) {
  const benefitIds = yield select(benefitsSelectors.getIds);
  yield all(map(id => call(caluclateEvaluator, { id, meta, showErrorModal: true }), benefitIds));
  yield take(persistenceTypes.SERVER_SAVE.REQUEST);
  yield put(sessionActionCreators.save());
}

export default reCalculate;
