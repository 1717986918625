/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

const IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M2,9L0,1l4,4l2-5l2,5l4-4l-2,8H2z M10,10c0,0.4-0.6,1-1,1H3c-0.4,0-1-0.6-1-1l0,0H10L10,10z"
    />
  </Icon>
);

export default memo(IconComponent);
