"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calculateSimpleRoi = void 0;

var _fp = require("./fp");

var _constants = require("./constants");

const calculateSimpleRoi = (totalBenefits, recTotalCosts, nonRecTotalCosts) => {
  const costTotal = (0, _fp.add)(recTotalCosts, nonRecTotalCosts);
  const simpleRoi = (0, _fp.pipe)((0, _fp.converge)(_fp.divide, [(0, _fp.subtract)(totalBenefits), _fp.identity]), (0, _fp.multiply)(_constants.percentageToDecimalFactor))(costTotal) || null;
  return simpleRoi;
};

exports.calculateSimpleRoi = calculateSimpleRoi;