/* @flow */
import { simpleAction, createReducer, userInitialState } from '@sharkfinesse/sfl-lib';

const namespace = (name: string): string => `@@user/${name}`;

// types
export const types = {
  LOADED: namespace('LOADED'),
  SYNC: namespace('SYNC'),
  STATE: {
    OFFLINE: namespace('STATE.OFFLINE'),
    ONLINE: namespace('STATE.ONLINE'),
  },
  ACCEPT_PRIVACY: namespace('ACCEPT_PRIVACY'),
  ACCEPT_TERMS: namespace('ACCEPT_TERMS'),
  NAME: {
    UPDATE: namespace('NAME.UPDATE'),
  },
};

const initialState = userInitialState;

const reduceLoaded = (state, action) => action.payload;
const reduceSync = (state, action) => action.payload;
const reduceOffline = (state, action) => ({
  ...state,
  state: 'offline',
});
const reduceOnLine = (state, action) => ({
  ...state,
  state: 'online',
});
const reduceUpdateName = (state, action) => ({
  ...state,
  name: action.payload,
});

export default createReducer(
  {
    [types.LOADED]: reduceLoaded,
    [types.SYNC]: reduceSync,
    [types.STATE.OFFLINE]: reduceOffline,
    [types.STATE.ONLINE]: reduceOnLine,
    [types.NAME.UPDATE]: reduceUpdateName,
  },
  initialState
);

//Action Creators
const loaded = simpleAction(types.LOADED);
const sync = simpleAction(types.SYNC);
const goOnline = () => ({ type: types.STATE.ONLINE });
const acceptPrivacy = simpleAction(types.ACCEPT_PRIVACY);
const acceptTerms = simpleAction(types.ACCEPT_TERMS);
const updateName = simpleAction(types.NAME.UPDATE);

export const actionCreators = {
  loaded,
  sync,
  goOnline,
  acceptPrivacy,
  acceptTerms,
  updateName,
};
