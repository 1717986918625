/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

const IconComponent = props => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M22.7,5.2h-8.1c-2.1,0-4,0.8-5.5,2.3C7.7,9,6.9,10.9,6.9,13c0,0.4,0,0.9,0.1,1.3c-2.1,1.2-3.5,3.5-3.5,6.1V28
	c0,0.4,0.3,0.8,0.8,0.8H20c3.9,0,7.1-3.2,7.1-7.1v-0.9h2.6c0.4,0,0.8-0.3,0.8-0.8v-6.9C30.5,8.8,27,5.2,22.7,5.2z M25.6,21.7
	c0,3.1-2.5,5.6-5.6,5.6H5v-6.8c0-3.1,2.5-5.6,5.6-5.6H20c3.1,0,5.6,2.5,5.6,5.6V21.7z M29,19.2h-2c-0.5-3.4-3.5-6-7-6h-9.4
	c-0.8,0-1.5,0.1-2.2,0.4c0-0.2,0-0.4,0-0.6c0-1.7,0.6-3.2,1.8-4.4c1.2-1.2,2.7-1.8,4.4-1.8h8.1c3.5,0,6.3,2.8,6.3,6.3V19.2z"
    />
  </Icon>
);

export default memo(IconComponent);
