/* @flow */
import {
  simpleAction,
  createReducer,
  simpleReducer,
  preferencesDefaultSessionImageInitialState,
} from '@sharkfinesse/sfl-lib';
const namespace = (name: string): string => `@@preferences.defaultSessionImage/${name}`;

//Actions
export const types = {
  SESSION_MEDIA: {
    UPDATE: namespace('SESSION_MEDIA.UPDATE'),
    UPDATE_POSITION: namespace('SESSION_MEDIA.UPDATE_POSITION'),
  },
};

//Reducer
export const initialState = preferencesDefaultSessionImageInitialState;

const reduceSessionMedia = simpleReducer('media');
const reduceSessionMediaPosition = (state, action) => ({
  ...state,
  media: {
    ...state.media,
    position: action.payload,
  },
});

export default createReducer(
  {
    [types.SESSION_MEDIA.UPDATE]: reduceSessionMedia,
    [types.SESSION_MEDIA.UPDATE_POSITION]: reduceSessionMediaPosition,
  },
  initialState
);

//Action Creators
const updateSessionMedia = simpleAction(types.SESSION_MEDIA.UPDATE);
const updateSessionMediaPosition = simpleAction(types.SESSION_MEDIA.UPDATE_POSITION);

export const actionCreators = {
  updateSessionMedia,
  updateSessionMediaPosition,
};
