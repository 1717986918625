/* @flow */
import {
  simpleAction,
  createReducer,
  simpleReducer,
  serviceWorkerInitialState,
} from '@sharkfinesse/sfl-lib';

const namespace = (name: string): string => `@@service.worker/${name}`;

// Actions
export const types = {
  UPDATE: namespace('UPDATE'),
};

const initialState = serviceWorkerInitialState;

//Reducer

const reduceServiceWorkerUpdated = simpleReducer('updated');

export default createReducer(
  {
    [types.UPDATE]: reduceServiceWorkerUpdated,
  },
  initialState
);

//Action Creators
const updateServiceWorker = simpleAction(types.UPDATE);
export const actionCreators = {
  updateServiceWorker,
};
