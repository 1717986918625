import { css } from 'styled-components';

const SuperscriptNumber = ({ font, colors, scale }) => ({
  sup: () => css`
    font-size: 70%;
    top: 15%;
    padding-right: 2px;
    padding-left: 2px;
    line-height: 90%;
  `,
});

export default SuperscriptNumber;
