/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

const IconComponent = props => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M21.4,21.6c-0.1-0.3-0.4-0.5-0.7-0.5c-0.3,0-0.6,0.2-0.7,0.4L17.5,27c-0.2,0.4,0,0.8,0.4,1
		c0.1,0,0.2,0.1,0.3,0.1c0.3,0,0.6-0.2,0.7-0.4l0.4-0.9h2.6l0.4,0.9c0.2,0.4,0.6,0.6,1,0.4c0.2-0.1,0.3-0.2,0.4-0.4
		c0.1-0.2,0.1-0.4,0-0.6L21.4,21.6z M21.3,25.2H20l0.6-1.4L21.3,25.2z"
    />
    <path
      fill="currentColor"
      d="M24.6,9.4c0-3.7-3-6.8-6.8-6.8H9.3c-3.7,0-6.8,3-6.8,6.8v6c0,0.4,0.3,0.8,0.8,0.8h14.6
		C21.6,16.2,24.6,13.2,24.6,9.4z M23.1,9.4c0,2.9-2.4,5.3-5.3,5.3H4V9.4c0-2.9,2.4-5.3,5.3-5.3h8.6C20.7,4.1,23.1,6.5,23.1,9.4z"
    />
    <path
      fill="currentColor"
      d="M24.7,17.8h-8.6c-3.7,0-6.8,3-6.8,6.8s3,6.8,6.8,6.8h14.6c0.4,0,0.8-0.3,0.8-0.8v-6
		C31.5,20.8,28.5,17.8,24.7,17.8z M30,24.6v5.3H16.2c-2.9,0-5.3-2.4-5.3-5.3c0-2.9,2.4-5.3,5.3-5.3h8.6C27.6,19.3,30,21.7,30,24.6z"
    />
    <path
      fill="currentColor"
      d="M16.2,11.2c0.3-0.5,0.5-1.1,0.5-1.8c0-1.9-1.5-3.5-3.3-3.5S10,7.5,10,9.4s1.5,3.5,3.3,3.5
		c0.7,0,1.3-0.2,1.8-0.6l0.4,0.4c0.3,0.3,0.8,0.3,1.1,0c0.3-0.3,0.3-0.8,0-1.1L16.2,11.2z M13.7,9.9c-0.1,0.1-0.2,0.3-0.2,0.5
		c0,0.2,0.1,0.4,0.2,0.5l0.2,0.2c-0.2,0.1-0.4,0.1-0.7,0.1c-1,0-1.8-0.9-1.8-1.9c0-1.1,0.8-1.9,1.8-1.9c1,0,1.8,0.9,1.8,1.9
		c0,0.2,0,0.5-0.1,0.7l-0.2-0.2c-0.1-0.1-0.3-0.2-0.5-0.2C14,9.7,13.8,9.8,13.7,9.9z"
    />
  </Icon>
);

export default memo(IconComponent);
