export const IDEMPOTENT = 'IDEMPOTENT';
export const CATEGORY = 'CATEGORY';
export const DISTINCT = 'DISTINCT';
export const G_START_MONTH = 'START_MONTH';
export const G_EVALUATOR_INPUT = 'EVALUATOR_INPUT';
export const G_END_MONTH = 'END_MONTH';
export const G_GROWTH = 'GROWTH';
export const G_NOTES = 'NOTES';
export const G_PRIVATE_NOTES = 'PRIVATE_NOTES';
export const G_SUBHEADING = 'SUBHEADING';
export const G_EVALUATOR_ERROR = 'EVALUATOR_ERROR';
export const G_EVALUATOR_CASHFLOW = 'EVALUATOR_CASHFLOW';
export const G_EVALUATOR_CASHFLOW_REVERT = 'EVALUATOR_CASHFLOW_REVERT';
export const G_START_MONTH_LINKED = 'G_START_MONTH_LINKED';
export const G_END_MONTH_LINKED = 'G_END_MONTH_LINKED';
export const G_DEFAULT_START_MONTH_CHANGE = 'G_DEFAULT_START_MONTH_CHANGE';
export const G_EVALUATOR_CASHFLOW_ADOPTION_MOD = 'G_EVALUATOR_CASHFLOW_ADOPTION_MOD';
export const G_EVALUATOR_CASHFLOW_GROWTH_MOD = 'G_EVALUATOR_CASHFLOW_GROWTH_MOD';
export const G_EVALUATOR_CASHFLOW_RISK_MOD = 'G_EVALUATOR_CASHFLOW_RISK_MOD';
export const G_EVALUATOR_CASHFLOW_MODS_RESET = 'G_EVALUATOR_CASHFLOW_MODS_RESET';
export const G_EVALUATOR_SMOOTH = 'G_EVALUATOR_SMOOTH';
