export default {
  xxs: '(min-width: 0) and (max-width: 379px)',
  xs: '(min-width: 380px) and (max-width: 575px)',
  sm: '(min-width: 576px) and (max-width: 767px)',
  md: '(min-width: 768px) and (max-width: 991px)',
  lg: '(min-width: 992px) and (max-width: 1199px)',
  xl: '(min-width: 1200px) and (max-width: 1399px)',
  xxl: '(min-width: 1400px) and (max-width: 1799px)',
  xxxl: '(min-width: 1800px) and (max-width: 2399px)',
  ultrawide: '(min-width: 2400px)',
};
