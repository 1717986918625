/* @flow */
import { simpleAction, productInitialState } from '@sharkfinesse/sfl-lib';

const namespace = (name: string): string => `@@product/${name}`;

export const types = {
  LOAD: namespace('LOAD'),
  LOADED: namespace('LOADED'),
};

const initialState = productInitialState;

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.LOADED:
      return {
        ...state,
        ...action.payload,
        loaded: true,
      };

    default:
      return state;
  }
}

//Action Creators
const load = simpleAction(types.LOAD);
const loaded = simpleAction(types.LOADED);

export const actionCreators = {
  load,
  loaded,
};
