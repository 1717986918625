/* @flow */
/* eslint-disable no-confusing-arrow, no-nested-ternary  */

import React from 'react';
import styled, { css, withTheme } from 'styled-components';
import { ifProp } from 'styled-tools';
import { capitalizeFirstLetter } from '../utils/helpers';
import Tooltip from '../tooltip';
import { NavLink } from 'react-router-dom';
import { generateShortId } from '@sharkfinesse/sfl-lib';
import LoaderRing from '../loaders/ring';

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`;

const buttonStyles = css`
  ${({ theme, color, size, disabled, variant }) => {
    const {
      textTransform,
      letterSpacing,
      fontWeight,
      fontColor,
      textShadow,
      boxShadow,
      background,
      borderColor,
      borderStyle,
      borderWidth,
      hoverBackground,
      hoverBorderStyle,
      hoverBorderWidth,
      hoverBorderColor,
      hoverFontColor,
      focusBorderWidth,
      focusBorderColor,
      activeBoxShadow,
      activeCSS,
    } = theme[`button${capitalizeFirstLetter(color)}`];
    const { padding, borderRadius, lineHeight, fontSize } =
      theme[`button${capitalizeFirstLetter(size)}`];
    return css`
      align-items: center;
      transition: background-color 0.2s linear, opacity 0.2s;
      cursor: pointer;
      ${disabled
        ? css`
            opacity: 0.3;
            cursor: not-allowed;
          `
        : null}
      position: relative;
      z-index: 1;
      text-decoration: none;
      overflow: hidden;
      display: flex;
      align-items: 'center';
      justify-content: center;
      text-transform: ${textTransform};
      letter-spacing: ${letterSpacing};
      font-weight: ${fontWeight};
      color: ${fontColor};
      text-shadow: ${textShadow};
      border-radius: ${borderRadius};
      line-height: ${lineHeight};
      font-size: ${fontSize};
      ${borderStyle};
      ${borderWidth};
      ${padding};
      ${variant !== 'flat' &&
      css`
        &:hover {
          ${hoverBackground};
        }
      `}
      ${ifProp(
        { variant: 'flat' },
        css`
          transition: color 0.3s;
          background: transparent;
          border-color: transparent;
          ${p =>
            p.disabled
              ? null
              : css`
                  &:hover {
                    color: ${theme.colors.primary};
                  }
                `}
        `,
        css`
          box-shadow: ${boxShadow};
          ${background};
          ${borderColor};
          ${p =>
            p.disabled
              ? null
              : css`
                  &:hover,
                  &:focus {
                    ${hoverBorderStyle
                      ? css`
                          ${hoverBorderStyle};
                          ${hoverBorderWidth};
                          ${hoverBorderColor};
                          color: ${hoverFontColor};
                        `
                      : ''};
                  }
                  &:hover:after,
                  &:focus:after {
                    opacity: 1;
                  }
                  &:focus {
                    box-shadow: 0 0 0 ${focusBorderWidth} ${focusBorderColor};
                  }
                  &:active {
                    box-shadow: 0 0 0 0;
                  }
                  &:active:after {
                    box-shadow: ${activeBoxShadow};
                    ${hoverBackground};
                    ${activeCSS}
                  }
                `}
        `
      )};
      ${ifProp('wide', 'width: 100%')};
    `;
  }};
`;

const StyledButton = styled.button`
  ${buttonStyles};
`;

const StyledDiv = styled.div`
  ${buttonStyles};
`;

const Label = styled.span`
  width: 100%;
  display: inherit;
  align-items: center;
  justify-content: inherit;
`;

type DefaultProps = {
  color: 'Default' | 'Primary',
  size: 'Normal' | 'Small' | 'Large',
  component: React.Element<any> | string,
  variant: 'raised' | 'flat',
  disabled: boolean,
};

export type Props = DefaultProps & {
  children?: React.Element<any>,
  color?: 'default' | 'primary',
  size?: 'normal' | 'small' | 'large',
  component?: React.Element<any> | string,
  variant?: 'raised' | 'flat',
  tooltip?: React.Element<any> | string,
  to: string | Object,
  tooltipConfig: Object,
  toolTipContainerProps: Object,
  tooltipProps: Object,
  loading: Boolean,
  label?: Boolean,
};

const Button = ({
  color,
  size,
  variant,
  component,
  tooltip,
  children,
  to,
  disabled,
  tooltipConfig,
  tooltipProps,
  loading,
  toolTipContainerProps,
  label,
  theme,
  buttonRef,
  ...props
}: Props): React.Element<any> => {
  let Component = StyledButton;

  if (component === 'div') {
    Component = StyledDiv;
  }

  const { fontColor } = theme[`button${capitalizeFirstLetter(color)}`];

  const id = generateShortId();
  let buttonComponent = (
    <Component
      id="sfl-button"
      variant={variant}
      color={color}
      size={size}
      data-for={id}
      disabled={disabled}
      ref={buttonRef}
      {...props}
    >
      {label && <Label>{loading ? <LoaderRing size={20} color={fontColor} /> : children}</Label>}
      {!label && children}
    </Component>
  );

  if (to) {
    buttonComponent = (
      <StyledNavLink to={to} data-for={id}>
        {buttonComponent}
      </StyledNavLink>
    );
  }

  if (tooltip) {
    buttonComponent = tooltip ? (
      !disabled ? (
        <Tooltip
          tooltip={tooltip}
          tooltipConfig={tooltipConfig}
          delayShow={500}
          toolTipContainerProps={toolTipContainerProps}
          tooltipProps={tooltipProps}
        >
          {buttonComponent}
        </Tooltip>
      ) : (
        buttonComponent
      )
    ) : (
      buttonComponent
    );
  }

  return buttonComponent;
};

Button.defaultProps = {
  color: 'default',
  size: 'normal',
  variant: 'raised',
  component: 'button',
  disabled: false,
  loading: false,
};
export default withTheme(Button);
