"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hslGenerator = exports.hashEmail = exports.getInitials = exports.avatarUrl = void 0;

var _md = _interopRequireDefault(require("crypto-js/md5"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/*eslint-disable fp/no-let, fp/no-loops,fp/no-mutation, no-magic-numbers*/
const getInitials = string => string.split(' ').map(([firstLetter]) => firstLetter).filter((_, index, array) => index === 0 || index === array.length - 1).join('').toLowerCase();

exports.getInitials = getInitials;

const hashEmail = email => (0, _md.default)(email.trim().toLowerCase()).toString();

exports.hashEmail = hashEmail;

const hslGenerator = ({
  name,
  s = 50,
  l = 60
}) => {
  let hash = 0;

  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
};

exports.hslGenerator = hslGenerator;

const avatarUrl = ({
  email,
  size = 20
}) => {
  return `https://s.gravatar.com/avatar/${hashEmail(email)}?s=${size}&r=pg&d=404`;
};

exports.avatarUrl = avatarUrl;