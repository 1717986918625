"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reselect = require("reselect");

var _nonRecurringCosts = _interopRequireDefault(require("./non-recurring-costs"));

var _recurringCosts = _interopRequireDefault(require("./recurring-costs"));

var _sessionDetails = _interopRequireDefault(require("./session-details"));

var _index = require("../../index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* eslint-disable no-unused-vars  */
const getTotalCosts = (0, _reselect.createSelector)([_recurringCosts.default.getTotalCosts, _nonRecurringCosts.default.getTotalCosts], (recTotalCosts, nonRecTotalCosts) => {
  return (0, _index.add)(recTotalCosts, nonRecTotalCosts);
});
const getCostsDislay = (0, _reselect.createSelector)([getTotalCosts, _sessionDetails.default.getNumberFormatObj], (totalCosts, numberFormatObj) => {
  return (0, _index.formatForDisplay)('money', numberFormatObj, totalCosts);
});
const getCostsDislayExcel = (0, _reselect.createSelector)([getTotalCosts, _sessionDetails.default.getNumberFormatObj], (totalCosts, numberFormatObj) => {
  return (0, _index.formatForExcel)('money', numberFormatObj, totalCosts);
});
const getTotalCostsQuantity = (0, _reselect.createSelector)([_nonRecurringCosts.default.getQuantity, _recurringCosts.default.getQuantity], (nonRecQuantity, recQuantity) => {
  return (0, _index.add)(nonRecQuantity, recQuantity);
});
const getCostsQuantityDisplay = (0, _reselect.createSelector)([getTotalCostsQuantity, _sessionDetails.default.getNumberFormatObj], (quantity, numberFormatObj) => {
  return (0, _index.formatForDisplay)('number', numberFormatObj, (0, _index.multiply)(quantity, _index.floatingFixedPointFactor));
}); // Selectors

const selectors = {
  getTotalCostsQuantity,
  getCostsDislay,
  getCostsQuantityDisplay,
  getTotalCosts,
  getCostsDislayExcel
};
var _default = selectors;
exports.default = _default;