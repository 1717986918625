/* @flow */
import {
  createReducer,
  simpleAction,
  omit,
  sessionTrackingInitialState,
} from '@sharkfinesse/sfl-lib';

const namespace = (name: string): string => `@@session.tracking/${name}`;

//Actions
export const types = {
  ACTIVE: {
    ADD: namespace('ACTIVE.ADD'),
    REMOVE: namespace('ACTIVE.REMOVE'),
  },
  TRACK: namespace('TRACK'),
  RESET: namespace('RESET'),
};

//Reducer
export const initialState = sessionTrackingInitialState;

const reduceAddActive = (state, { payload: { id, data } }) => {
  return {
    ...state,
    active: {
      ...state.active,
      [id]: data,
    },
  };
};
const reduceRemoveActive = (state, { payload }) => ({
  ...state,
  active: omit([payload.id], state.active),
});
const reduceReset = (state, action) => initialState;

export default createReducer(
  {
    [types.ACTIVE.ADD]: reduceAddActive,
    [types.ACTIVE.REMOVE]: reduceRemoveActive,
    [types.RESET]: reduceReset,
  },
  initialState
);

//Action Creators
const addActive = simpleAction(types.ACTIVE.ADD);
const removeActive = simpleAction(types.ACTIVE.REMOVE);
const track = simpleAction(types.TRACK);
const reset = simpleAction(types.RESET);

export const actionCreators = {
  addActive,
  removeActive,
  track,
  reset,
};
