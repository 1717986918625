import { css } from 'styled-components';
import { setAlphaHsla } from '@sharkfinesse/sfl-lib';

const SelectToolbar = ({ font, colors, scale }) => ({
  container: () => css`
    width: 100%;
    display: flex;
    flex-direction: column;
  `,
  buttonWrapper: ({ selected }) => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    min-height: 40px;
    border-bottom: 1px solid ${colors.uiLightest};
    color: ${font.color};
    transition: background-color 200ms ease-in-out;
    ${selected &&
    css`
      background-color: ${setAlphaHsla(0.2, colors.primary)};
    `}
    &:hover {
      background-color: ${setAlphaHsla(0.2, colors.primary)};
    }
  `,
  pickButton: () => css`
    width: 100%;
    border: 0;
    text-align: left;
    padding: 12px;
    cursor: pointer;
    color: ${font.color};
    font-family: ${font.family};
    font-size: ${font.sizeNormal};
    font-weight: ${font.weightSemiBold};
    align-items: center;
    display: flex;
    line-height: 16px;
  `,
  buttonLabel: () => css`
    margin-left: 8px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  `,
  searchWrapper: () => css`
    margin: 8px 10px 0 10px;
  `,
  leafButton: () => css`
    color: ${font.color};
    transition: background-color 200ms ease-in-out;
    cursor: pointer;
    width: 40px;
    border: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 4px 12px 4px 4px;
    border-radius: 4px;
    &:hover {
      background: ${setAlphaHsla(0.4, colors.primary)};
    }
  `,
  backButton: () => css`
    color: ${font.color};
    width: 100%;
    border: 0;
    text-align: left;
    padding: 12px;
    cursor: pointer;
    font-family: ${font.family};
    font-size: ${font.sizeLarge};
    font-weight: ${font.weightSemiBold};
    align-items: flex-start;
    display: flex;
    align-items: center;
    line-height: 0;
    &:hover {
      background: ${colors.uiLightest};
    }
    line-height: 22px;
  `,
  message: () => css`
    display: flex;
    justify-content: center;
    margin-top: 16px;
  `,
  icon: () => css`
    display: flex;
    min-width: 24px;
  `,
});

export default SelectToolbar;
