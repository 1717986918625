/* @flow */
/* eslint-disable no-confusing-arrow, no-nested-ternary  */

import React from 'react';
import styled from 'styled-components';

export const Style = styled.section`
  ${p => p.theme.sections.base()};
`;

export type Props = {
  children?: React.Element<any>,
};

const Section = ({ children, ...props }: Props): React.Element<any> => (
  <Style {...props}>{children}</Style>
);

export default Section;
