import { css } from 'styled-components';

const General = ({
  font,
  colors,
  scale,
  device: { max_xxs, min_xxs, min_xs, min_md, max_sm },
}) => ({
  flexColumn: () => css`
    display: flex;
    flex-direction: column;
  `,
  flexRow: () => css`
    display: flex;
    flex-direction: row;
  `,
  semiBold: () => css`
    font-weight: ${font.weightSemiBold};
  `,
  bodyMargin: () => css`
    @media ${min_xxs} {
      margin: 6px 10px 10px 10px;
    }
    @media ${min_xs} {
      margin: 10px 20px 20px 20px;
    }
    @media ${min_md} {
      margin: 10px 40px 20px 40px;
    }
  `,
  bodyTitle: () => css`
    font-size: 18px;
    font-weight: 400;
    margin: 0 0 8px 0;
    font-weight: ${font.weightSemiBold};
  `,
  bodyTitleUnderline: () => css`
    border-bottom: 1px solid ${colors.uiLightest};
    padding-bottom: 8px;
  `,
  pillBg: () => css`
    background: ${colors.uiLightest};
    padding: 10px 50px;
    border-radius: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  styledLink: () => css`
    color: ${colors.primary};
    font-size: 14px;
    font-style: italic;
    text-decoration: underline;
    cursor: pointer;
    word-break: break-all;
  `,
  listWithDivider: () => css`
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      padding: 12px 0;
    }
    li:not(:last-child) {
      border-bottom: 1px solid ${colors.uiLightest};
    }
  `,
  spaceBetweenRow: () => css`
    display: flex;
    justify-content: space-between;
  `,
  charactersRemaining: () => css`
    font-size: 12px;
    text-align: right;
    margin-top: -4px;
  `,
  paddedColumn: () => css`
    padding: 20px 30px 30px 30px;
    display: flex;
    width: 100%;
    flex-direction: column;
    @media ${max_sm} {
      padding: 20px;
    }
  `,
  relativeWrapper: () => css`
    position: relative;
    height: 100%;
  `,
  errorText: () => css`
    color: ${colors.invalid};
  `,
  coverImage: () => css`
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    pointer-events: none;
  `,
  label: () => css`
    color: ${font.color};
  `,
  subLabel: () => css`
    color: ${colors.ui};
  `,
  stackingGrid: () => css`
    display: grid;
    grid-template-columns: 1fr;
    height: 100%;
  `,
  statusWrapper: () => css`
    font-size: 12px;
    margin-right: 4px;
    display: flex;
    align-items: center;
  `,
  statusComplete: () => css`
    color: green;
  `,
  statusIncomplete: () => css`
    color: orange;
  `,
  statusEmpty: () => css`
    color: red;
  `,
  fullWidth: () => css`
    width: 100%;
  `,
  formContainer: () => css`
    max-width: 700px;
  `,
  markDownContainer: () => css`
    padding: 0 16px 16px 16px;
    display: flex;
    flex-direction: column;
    color: ${font.color};
    h1 {
      font-size: 18px;
      font-weight: ${font.weightSemiBold};
      margin: 10px 0 0 0;
    }
    h2 {
      font-size: 18px;
      font-weight: ${font.weightSemiBold};
      margin: 10px 0 0 0;
    }
    h3 {
      font-size: 16px;
      line-height: 16px;
      font-weight: ${font.weightSemiBold};
      margin: 10px 0 0 0;
    }
    h4 {
      font-size: 14px;
      line-height: 14px;
      font-weight: ${font.weightSemiBold};
      margin: 10px 0 0 0;
    }
    p {
      margin: 6px 0;
      img {
        width: auto;
      }
    }
    ol,
    ul {
      padding-left: 20px;
      li {
        margin-bottom: 6px;
      }
    }
    img {
      width: 100%;
      margin: 10px 0;
      max-width: 800px;
    }
    img[alt='70percent'] {
      width: 70%;
      display: block;
    }
    img[alt='26pixel'] {
      width: 26px;
      margin: 0;
    }
    .link {
      padding: 6px 0;
      border: 0;
      text-align: left;
      color: ${colors.primary};
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .links-list {
      margin: 0;
      padding: 0;
      list-style: none;
      button {
        width: 100%;
      }
      li:not(:last-child) {
        border-bottom: 1px solid ${colors.uiLightest};
      }
    }
  `,
  viewportCover: () => css`
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    z-index: 9050;
  `,
  flexEnd: () => css`
    justify-content: flex-end;
    display: flex;
  `,
  imageBg: colors.white,
  pinTopRight: () => css`
    position: absolute;
    top: 3px;
    right: 3px;
  `,
  twoColumnGrid: () => css`
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 4px;
    width: 100%;
    @media ${min_md} {
      grid-row-gap: 8px;
      grid-column-gap: 14px;
      grid-template-columns: 1fr 1fr;
    }
  `,
  twoColumnGridNoGap: () => css`
    width: 100%;
    display: grid;
    grid-template-columns: minmax(330px, 1fr);
    overflow: hidden;
    @media ${min_md} {
      grid-template-columns: minmax(330px, 1fr) minmax(330px, 1fr);
    }
  `,
  primaryBadge: () => css`
    padding: 1px 6px;
    background-color: ${colors.primary};
    border-radius: 20px;
    font-size: 11px;
    color: ${colors.white};
    margin-left: 8px;
  `,
  fixedConatiner: () => css`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  `,
});

export default General;
