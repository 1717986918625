import { sessionSelectors, importQuestionnaire } from '@sharkfinesse/sfl-lib';
import { put, call, select, all, delay } from 'redux-saga/effects';
import { actionCreators as evaluatorActionCreators } from 'redux/modules/evaluator';
import { actionCreators as defaultSessionValuesActionCreators } from 'redux/modules/default-session-values';
import { actionCreators as sessionActionCreators } from 'redux/modules/session';
import undoToast from '../toast/undo';
import firebase from 'firebase/app';
import { updateQuestionnaire } from 'redux/firestore/questionnaire';

export function* importSession({
  meta,
  payload: { defaultSessionValues, benefitData, overwrite, account, id },
}) {
  const importedDate = new Date();
  const session = yield select(sessionSelectors.getSession);
  const { updatedDefaultValues, updatedBenefitData } = yield call(importQuestionnaire, {
    overwrite,
    benefitData,
    defaultSessionValues,
    session,
  });

  yield all([
    put(evaluatorActionCreators.syncData({ meta, data: updatedBenefitData })),
    put(defaultSessionValuesActionCreators.sync({ meta, data: updatedDefaultValues })),
  ]);
  yield put(sessionActionCreators.reCalculate({ meta }));
  yield call(updateQuestionnaire, {
    id,
    account,
    data: {
      imported: importedDate,
      importedHistory: firebase.firestore.FieldValue.arrayUnion({
        created: importedDate,
      }),
    },
  });
  yield delay(300);
  yield call(undoToast, {
    formattedMessageId: 'importer.undo.toast',
    defaultMessage: 'Undo import',
  });
}

export default importSession;
