/* eslint-disable prettier/prettier */
import { lightenHsla, darkenHsla, setAlphaHsla, hsla } from '@sharkfinesse/sfl-lib';
import { stripUnit } from 'polished';
import { css } from 'styled-components';
import styledBreakpoint from 'styled-components-breakpoint';

const charlie = 20;
const C = 0.3;
const F = 0.2;
const G = 70;
const baseAlpha = 1;
const grays = hsla(0, 0);
const uiBase = grays(0, baseAlpha);
const BenefitLayoutDesigner = ({ font, colors, scale }) => ({
  grid: () => css`
    width: 100%;
    display: flex;
    flex: 1 1 0%;
    position: relative;
    flex-direction: row;
    height: 100%;
  `,
  leftColumn: (expanded) => css`
    height: 100%;
  `,
  rightColumn: (expanded) => css`
    position: relative;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: ${colors.uiDark};
  `,
  leftColumnSmall: (expanded) => css`
    position: relative;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    height: 100%;
  `,
  smallWrapper: () => css`
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    overflow: hidden;
    height: 100%;
  `,
  suiteName: () => css`
    color: ${colors.uiDark};
    font-weight: 600 !important;
    font-size: 1em;
    &.autoSizeInput {
      input {
        ${styledBreakpoint('xxs')`
            max-width: 110px;
            `}
        ${styledBreakpoint('xs')`
            max-width: 200px;
            `}
            ${styledBreakpoint('sm')`
            max-width: 400px;
            `}
            ${styledBreakpoint('md')`
            max-width: 570px;
            `}
            ${styledBreakpoint('lg')`
            max-width: 320px;
            `}
            ${styledBreakpoint('xl')`
            max-width: 420px;
            `}
            ${styledBreakpoint('xxl')`
            max-width: 520px;
            `}
          text-overflow: ellipsis;
        font: inherit;
        border: 0;

        ::placeholder {
          color: ${lightenHsla(G, uiBase)};
        }
      }
    }
  `,
  benefitListHeading: () => css`
    margin: 10px 0 0 20px;
    color: rgba(255, 255, 255, 0.65);
    font-size: 18px;
  `,
  searchBar: () => css`
    padding: 10px 20px 0px 20px;
  `,
  benfitListGrid: () => css`
    position: relative;
    padding: 10px 20px 20px 20px;
    section {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
      width: 100%;
      gap: ${scale.minusThree};
    }
    .highlight {
      color: ${colors.primary};
      font-weight: ${font.weightSemiBold};
    }
  `,
  benfitListFooter: () => css`
    max-height: 50px;
    border-top: none;
    color: white;
  `,
  suiteAddButton: () => css`
    margin-bottom: ${scale.minusOne};
  `,
  benefitAddButton: () => css`
    user-select: none;
    margin: ${`${stripUnit(scale.minusOne) / 2}em`} 0 0 0;
    border-style: solid;
    border-width: 1px;
  `,
  selectSuiteButton: (disabled) => css`
    user-select: none;
    margin: ${`${stripUnit(scale.minusOne) / 2}em`} 0 0 0;
    border-style: solid;
    border-width: 1px;
    ${disabled
      ? css`
          opacity: 0.3;
          cursor: not-allowed;
        `
      : css`
          &:hover {
            background: ${setAlphaHsla(C, colors.white)};
          }
        `}
  `,
  footerWrapper: () => css`
    display: flex;
    flex-wrap: wrap;
    ${styledBreakpoint('sm')`
        flex-wrap: inherit;
      `};
  `,
  footerMessage: () => css`
    user-select: none;
    width: 100%;
    font-size: 16px;
    padding: 10px;
    color: ${darkenHsla(charlie, colors.primary)};
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: ${setAlphaHsla(F, colors.primary)};
    border-radius: 3px;
    margin: ${`${stripUnit(scale.minusOne) / 2}em`} 0 0 0;
  `,
  fullscreen: () => css`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100%;
  `,
  toolbar: () => css`
    display: flex;
    height: 50px;
    background: ${colors.white};
    border-bottom: 1px solid ${colors.uiLight};
    justify-content: flex-end;
    padding-right: 10px;
    align-items: center;
  `,
  editBodyWrapper: () => css`
    background: ${colors.white};
    padding: ${scale.two};
  `,
  subHeading: () => css`
    grid-column: 1 / -1;
    /* grid-row: auto; */
    display: flex;
    justify-content: end;
    flex-direction: column-reverse;
    width: 100%;
    color: ${colors.white};
    border-bottom: 1px solid ${colors.white};
    font-size: 18px;
    font-weight: ${font.weightNormal};
  `,

  message: () => css`
    color: ${colors.white};
    font-weight: ${font.weightNormal};
    font-size: ${font.sizeNormal};
    justify-content: center;
    display: flex;
  `,
});

export default BenefitLayoutDesigner;
