/* @flow */
/* eslint-disable max-len */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M77,19H29.8l-4.9-6.8c-0.6-0.8-1.5-1.2-2.4-1.2H3c-1.7,0-3,1.3-3,3v59c0,1.7,1.3,3,3,3h74c1.7,0,3-1.3,3-3
	V22C80,20.3,78.7,19,77,19z M77,73H3V33h74V73z M77,30H3V14h19.5l5.8,8H77V30z"
    />
    <rect x="30" y="44" fill="none" width="20" height="18" />
    <path
      fill="#474747"
      d="M48,44H32c-1.1,0-2,0.9-2,2l0,0v14c0,1.1,0.9,2,2,2l0,0h16c1.1,0,2-0.9,2-2l0,0V46
	C50,44.9,49.1,44,48,44L48,44 M48,60H32V46h16V60z"
    />
    <rect x="34" y="56" fill="currentColor" width="12" height="2" />
    <rect x="34" y="48" fill="inherit" width="4" height="6" />
    <rect x="40" y="48" fill="currentColor" width="6" height="2" />
    <rect x="40" y="52" fill="currentColor" width="6" height="2" />
  </Icon>
);

export default memo(IconComponent);
