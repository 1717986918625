import { select, put, call, all, take } from 'redux-saga/effects';
import { omit, accountSelectors, questionnaireSelectors } from '@sharkfinesse/sfl-lib';
import { types as accountTypes } from 'redux/modules/account';
import { actionCreators as questionnaireActionCreators } from 'redux/modules/questionnaire/questionnaire';
import { types as productTypes } from 'redux/modules/product';
import retryFetch from '../utils/retryFetch';
import fetchAuth from 'utils/fetchAuth';

export function* load(action) {
  try {
    const { sessionId: id } = action.payload;

    yield all([take(productTypes.LOADED), take(accountTypes.LOADED)]);

    const evaluators = yield select(accountSelectors.getEvaluators);

    const loadedQuestionnaire = yield call(retryFetch, fetchAuth, {
      url: `questionnaire/get?sessionId=${id}`,
    });

    const {
      suiteList,
      details,
      layout,
      benefits,
      questionnaire,
      defaultSessionValues,
      questionnaireSubmission,
      owner,
      policyId,
    } = loadedQuestionnaire;

    const state = {
      account: { evaluators },
      session: {
        present: {
          suiteList,
          details,
          layout,
          benefits,
          questionnaire,
          defaultSessionValues,
        },
      },
    };

    const { newLayout, benefitsData, defaultSessionValueIds, numberFormatObj } = yield call(
      questionnaireSelectors.getQuestionnaire,
      state
    );
    yield put(
      questionnaireActionCreators.loadSubmitBenefitData(questionnaireSubmission.benefitData)
    );
    if (questionnaireSubmission?.defaultSessionValues)
      yield put(
        questionnaireActionCreators.loadSubmittedDefaultSessionValues(
          questionnaireSubmission.defaultSessionValues
        )
      );

    if (defaultSessionValues)
      yield put(questionnaireActionCreators.updateDefaultSessionValues(defaultSessionValues));

    yield all([
      put(questionnaireActionCreators.updateId(questionnaireSubmission.id)),
      put(questionnaireActionCreators.updateLayout(newLayout)),
      put(questionnaireActionCreators.updateNumberFormatObj(numberFormatObj)),
      put(questionnaireActionCreators.updateBenefitData(benefitsData)),
      put(questionnaireActionCreators.updateTermsAccepted(questionnaireSubmission?.termsAccepted)),
      put(questionnaireActionCreators.updateDefaultSessionValuesSchema(defaultSessionValueIds)),
      put(
        questionnaireActionCreators.updateConfig({
          ...omit(['clientId', 'benefits', 'emails'], questionnaire),
          name: owner.name,
          customer: details.customerName,
          policyId,
        })
      ),
    ]);
    yield put(questionnaireActionCreators.loaded(true));
  } catch (error) {
    yield put(questionnaireActionCreators.loaded({ status: 'error', error: error.message }));
  }
}

export default load;
