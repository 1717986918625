// @flow

import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'styled-components/macro';
import styled from 'styled-components';
import Avatar from './avatar';
import { turnOffMainAxsis, fulllOffset, fullSameSize } from '../popper';

const initialModifiers = [turnOffMainAxsis, fulllOffset, fullSameSize];

export const Outline = styled.div`
  pointer-events: none;
  outline: 2px solid;
  z-index: 3;
`;

const ElementHighlight = ({
  elementProps,
  popperOptions,
  children,
  usePortal,
  portalContainer = document.body,
  users,
  modifiers = initialModifiers,
  highlightProps,
  ...other
}) => {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible, update } = usePopperTooltip(
    {
      delayShow: 0,
      placement: 'right',
      visible: true,
      mutationObserverOptions: null,
      ...elementProps,
    },
    { modifiers, ...popperOptions }
  );

  //watch for changes on the document to update the its position
  useEffect(() => {
    if (update && users) {
      const observer = new MutationObserver(update);
      observer.observe(document.getElementById('root'), {
        attributes: false,
        childList: true,
        subtree: true,
      });
      return () => observer.disconnect();
    }
  }, [update, users]);

  const getPopper = () => {
    const tooltipProps = getTooltipProps();
    return (
      <Outline
        ref={setTooltipRef}
        {...tooltipProps}
        style={{ ...tooltipProps.style, outlineColor: users[0].color }}
      >
        {users.map((user, i) => (
          <Avatar
            key={i}
            name={user.name}
            initials={user.initials}
            color={user.color}
            xPos={i * 6}
            zIndex={users.length - i}
          />
        ))}
      </Outline>
    );
  };

  return (
    <>
      <div ref={setTriggerRef} {...highlightProps}>
        {children}
      </div>
      {visible && users && (usePortal ? createPortal(getPopper(), portalContainer) : getPopper())}
    </>
  );
};

export default ElementHighlight;
