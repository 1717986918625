// @flow weak
/* eslint-disable no-magic-numbers */
import React, { useCallback, useState } from 'react';
import MaskedInput from 'react-text-mask';
import { noMask } from '@sharkfinesse/sfl-lib';
import useFormControl from '../form-control/useFormControl';
import { Base as Input, Container, Wrapper, InputColumn, InputWrapper } from './styled';
import Textarea from 'react-textarea-autosize';
import AutosizeInput from 'react-input-autosize';

const InputBase = ({
  autoComplete = 'off',
  type = 'text',
  align = 'left',
  after,
  before,
  children,
  component,
  inputProps,
  inputRef,
  mask = noMask,
  multiline,
  onBlur,
  onDirty,
  onFocus,
  onChange,
  rowsMax,
  expanded,
  autosize,
  id,
  selected,
  inverse,
  rounded,
  topRounded,
  wrapperProps,
  value = '',
  autoFocus,
  onKeyUp,
  onKeyDown,
  name,
  placeholder,
  rows,
  maxLength,
  tabIndex,
  bottom,
  ...other
}) => {
  const formControl = useFormControl();
  const [focused, setFocused] = useState();
  const onFocusHandler = event => {
    if (onFocus) {
      onFocus(event);
    }
    if (formControl?.onFocus) {
      formControl.onFocus(event);
    } else {
      setFocused(true);
    }
  };

  const onBlurHandler = event => {
    if (onBlur) {
      onBlur(event);
    }
    if (formControl?.onBlur) {
      formControl.onBlur(event);
    } else {
      setFocused(false);
    }
  };

  const handleRefInput = useCallback(
    event => {
      if (inputRef) inputRef(event);
    },
    [inputRef]
  );

  const getAs = () => {
    if (component) return component;
    if (formControl?.autosize) return AutosizeInput;
    if (formControl?.multiline) return 'textarea';
    if (formControl?.expanded) return Textarea;
    if (!!mask()) return MaskedInput;
    return 'input';
  };

  if (!!mask()) {
    inputProps = {
      ...inputProps,
      mask,
      render: (ref, props) => <Input ref={ref} $selected={formControl?.selected} {...props} />,
    };
  }

  return (
    <Container $fullWidth={formControl?.fullWidth} {...other}>
      <Wrapper
        $focused={formControl?.focused || focused}
        $inverse={formControl?.inverse}
        $invalid={formControl?.invalid}
        $expanded={formControl?.expanded}
        $autosize={formControl?.autosize}
        disabled={formControl?.disabled}
        $selected={formControl?.selected}
        $rounded={formControl?.rounded}
        {...wrapperProps}
      >
        <InputColumn>
          <InputWrapper>
            {before}
            <Input
              as={getAs()}
              $expanded={formControl?.expanded}
              $autosize={formControl?.autosize}
              $focused={formControl?.focused || focused}
              $selected={formControl?.selected}
              $invalid={formControl?.invalid}
              $rounded={formControl?.rounded}
              $inverse={formControl?.inverse}
              $multiline={formControl?.multiline}
              $topRounded={topRounded}
              $align={align}
              disabled={formControl?.disabled}
              aria-invalid={formControl?.invalid ? true : false}
              aria-required={formControl?.required ? true : false}
              autoComplete={autoComplete}
              autoFocus={autoFocus}
              onBlur={onBlurHandler}
              onFocus={onFocusHandler}
              onChange={onChange}
              onKeyUp={onKeyUp}
              onKeyDown={onKeyDown}
              value={value}
              id={formControl?.id || id}
              name={name}
              placeholder={placeholder}
              type={type}
              rows={rows}
              maxLength={maxLength}
              data-lpignore={true}
              tabIndex={tabIndex}
              ref={handleRefInput}
              {...inputProps}
            />
            {after}
          </InputWrapper>
          {bottom}
        </InputColumn>
      </Wrapper>
    </Container>
  );
};
InputBase.whyDidYouRender = false;

export default InputBase;
