/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

let IconProfiler = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M4,6v2v1v9h1h4h1h4h1h4h1v-1v-2v-1v-2v-1V9V8V6H4z M10,12h4v2h-4V12z M9,14H5v-2h4V14z M10,11V9h4v2H10z M9,9v2H5V9H9z
	 M5,17v-2h4v2H5z M10,17v-2h4v2H10z M19,17h-4v-2h4V17z M19,14h-4v-2h4V14z M19,11h-4V9h4V11z"
    />
  </Icon>
);

export default memo(IconProfiler);
