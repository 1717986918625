"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.variableCashflowForMathJsRawArgs = exports.updateCounters = exports.updateCashflow = exports.reduceCashflowForMathJsRawArgs = exports.pushCashflow = exports.mapIndexedCashflowForMathJsRawArgs = exports.mapCashflowForMathJsRawArgs = exports.incCounts = exports.getFreestyleValue = exports.getFirstValidConditionalName = exports.getFirstValidConditional = exports.getConditionalExpressionByName = exports.generateVariableRange = exports.generateVariableCashflow = exports.generateCounters = exports.generateConditionalRange = exports.generateConditionalCashflow = exports.formatRawConditionals = exports.evaluateMonthByCondition = exports.evaluateMonthAndUpdateCashflow = exports.evaluateMonthAndPushCashflow = exports.evaluateExpression = exports.evaluateCustomArg = exports.evaluateCondition = exports.evalAndRound = exports.conditionalMapIteratorBusiness = exports.conditionalMapIterator = exports.conditionalMapForMathJsRawArgs = exports.conditionalMap = exports.conditionalCashflowIteratorBusiness = exports.conditionalCashflowIterator = exports.conditionalCashflowForMathJsRawArgs = exports.annualCashflowForMathJsRawArgs = void 0;

var _cashflow = require("./cashflow");

var _fp = require("./fp");

var _math = require("./math");

var _constants = require("./constants");

var _arrays = require("./arrays");

var _objects = require("./objects");

var _arguments = require("./arguments");

var _units = require("./units");

var _type = require("./type");

var _mathjs = require("mathjs");

/* eslint-disable */
const math = (0, _mathjs.create)({
  parseDependencies: _mathjs.parseDependencies,
  compileDependencies: _mathjs.compileDependencies,
  evaluateDependencies: _mathjs.evaluateDependencies,
  sumDependencies: _mathjs.sumDependencies,
  formatDependencies: _mathjs.formatDependencies,
  roundDependencies: _mathjs.roundDependencies,
  smallerDependencies: _mathjs.smallerDependencies,
  smallerEqDependencies: _mathjs.smallerEqDependencies,
  largerDependencies: _mathjs.largerDependencies,
  largerEqDependencies: _mathjs.largerEqDependencies,
  divideDependencies: _mathjs.divideDependencies,
  log10Dependencies: _mathjs.log10Dependencies,
  andDependencies: _mathjs.andDependencies,
  unequalDependencies: _mathjs.unequalDependencies,
  maxDependencies: _mathjs.maxDependencies,
  minDependencies: _mathjs.minDependencies,
  modDependencies: _mathjs.modDependencies
});

const generateConditionalAccumulator = (conditionals = [], scope = {}, cashflow = []) => ({
  conditionals,
  scope,
  cashflow
});

const scopeLens = (0, _fp.lensProp)('scope');
const conditionalsLens = (0, _fp.lensProp)('conditionals');
const cashflowLens = (0, _fp.lensProp)('cashflow');
const indexLens = (0, _fp.lensPath)(['scope', 'index']);
const currentValueLens = (0, _fp.lensPath)(['scope', 'currentValue']);

const sanitizeResult = val => (0, _fp.cond)([[(0, _fp.equals)(NaN), (0, _fp.always)(0)], [(0, _fp.complement)(_fp.equals)(Infinity), (0, _fp.always)(val)], [_fp.T, (0, _fp.always)('')]])(val); // prettier-ignore


const evaluateExpression = (0, _fp.curryN)(2, (0, _fp.pipe)(math.evaluate, sanitizeResult)); // prettier-ignore

exports.evaluateExpression = evaluateExpression;
const evalAndRound = (0, _fp.curryN)(2, (0, _fp.pipe)(evaluateExpression, Math.round)); // prettier-ignore

exports.evalAndRound = evalAndRound;
const evaluateCustomArg = (0, _fp.converge)(evaluateExpression, [(0, _fp.pipe)(_fp.nth, (0, _fp.prop)('value')), (0, _fp.nthArg)(2)]);
exports.evaluateCustomArg = evaluateCustomArg;
const numberFour = 4; // prettier-ignore

const generateVariableRange = (0, _fp.converge)(_fp.call, [(0, _fp.pipe)((0, _fp.nthArg)(0), evalAndRound, _fp.map), (0, _fp.flip)((0, _fp.finchstarstar)((0, _fp.cardinalstar)((0, _fp.curryN)(numberFour, _arrays.generateIndexedArray))))]); // prettier-ignore

exports.generateVariableRange = generateVariableRange;
const generateVariableCashflow = (0, _fp.converge)(_cashflow.generateCashflowFromRange, [(0, _fp.nthArg)(1), (0, _fp.pipe)((0, _fp.nthArg)(3), (0, _fp.prop)('appraisalPeriod')), (a, b, c, d) => generateVariableRange(a, 1, c, d)]); //args: [{conditionals: array}, {cashflowKey: string}]
// prettier-ignore

exports.generateVariableCashflow = generateVariableCashflow;
const generateFunctorForMapCashflow = (0, _fp.converge)(_fp.map, [(0, _fp.pipe)((0, _fp.nthArg)(1), (0, _fp.flip)((0, _fp.set)((0, _fp.lensProp)('currentValue')))), evaluateCustomArg(1)]); // prettier-ignore

const formatRawConditionals = (0, _fp.pipe)(_objects.mapValues, (0, _fp.map)(_objects.mapValues)); // prettier-ignore

exports.formatRawConditionals = formatRawConditionals;
const evaluateCondition = (0, _fp.goldfinch)((0, _fp.flip)(evaluateExpression))((0, _fp.prop)('condition')); // prettier-ignore

exports.evaluateCondition = evaluateCondition;
const getFirstValidConditional = (0, _fp.cardinal_)(_fp.find)((0, _fp.flip)(evaluateCondition)); // prettier-ignore

exports.getFirstValidConditional = getFirstValidConditional;
const getFirstValidConditionalName = (0, _fp.pipe)((0, _fp.props)(["conditionals", "scope"]), (0, _fp.apply)(getFirstValidConditional), (0, _fp.prop)("name")); // prettier-ignore

exports.getFirstValidConditionalName = getFirstValidConditionalName;
const getConditionalExpressionByName = (0, _fp.curry)((conditionalName, conditionals) => (0, _fp.pipe)((0, _fp.find)((0, _fp.propEq)('name', conditionalName)), (0, _fp.prop)('expression'))(conditionals)); // prettier-ignore

exports.getConditionalExpressionByName = getConditionalExpressionByName;
const incCounts = (0, _fp.curry)((0, _fp.pipe)(_objects.incValue, (0, _objects.incValue)("index"))); // prettier-ignore

exports.incCounts = incCounts;
const pushCashflow = (0, _fp.useWith)((0, _fp.over)(cashflowLens), [_fp.append]); // prettier-ignore

exports.pushCashflow = pushCashflow;
const updateCounters = (0, _fp.useWith)((0, _fp.over)(scopeLens), [incCounts]); // prettier-ignore

exports.updateCounters = updateCounters;
const evaluateMonthByCondition = (0, _fp.converge)(evalAndRound, [(0, _fp.cardinal_)((0, _fp.flip)(getConditionalExpressionByName))((0, _fp.view)(conditionalsLens)), (0, _fp.pipe)((0, _fp.nthArg)(1), (0, _fp.view)(scopeLens))]); // prettier-ignore

exports.evaluateMonthByCondition = evaluateMonthByCondition;
const evaluateMonthAndPushCashflow = (0, _fp.curry)((conditionalName, accumulator) => pushCashflow(evaluateMonthByCondition(conditionalName, accumulator), accumulator)); // prettier-ignore

exports.evaluateMonthAndPushCashflow = evaluateMonthAndPushCashflow;
const conditionalCashflowIteratorBusiness = (0, _fp.converge)(_fp.reduceWithCall, [(0, _fp.nthArg)(1), (0, _fp.pipe)((0, _fp.nthArg)(0), evaluateMonthAndPushCashflow), (0, _fp.pipe)((0, _fp.nthArg)(0), updateCounters)]); // prettier-ignore

exports.conditionalCashflowIteratorBusiness = conditionalCashflowIteratorBusiness;
const conditionalCashflowIterator = (0, _fp.starling)((0, _fp.flip)(conditionalCashflowIteratorBusiness))(getFirstValidConditionalName); // prettier-ignore

exports.conditionalCashflowIterator = conditionalCashflowIterator;
const generateConditionalRange = (0, _fp.converge)((0, _fp.pipe)((0, _fp.reduce)(conditionalCashflowIterator), (0, _fp.prop)('cashflow')), [(0, _fp.pipe)((0, _arguments.nthArgs)(2, 4), (0, _fp.apply)(generateConditionalAccumulator)), (0, _fp.pipe)((0, _arguments.nthArgs)(0, 2), (0, _fp.apply)((0, _fp.flip)(_fp.subtract)), _fp.inc, (0, _fp.repeat)(0))]); // prettier-ignore

exports.generateConditionalRange = generateConditionalRange;
const generateConditionalCashflow = (0, _fp.converge)(_cashflow.generateCashflowFromRange, [(0, _fp.nthArg)(0), (0, _fp.pipe)((0, _fp.nthArg)(3), (0, _fp.prop)('appraisalPeriod')), generateConditionalRange]); // prettier-ignore

exports.generateConditionalCashflow = generateConditionalCashflow;
const updateCashflow = (0, _fp.converge)(_fp.call, [(0, _fp.pipe)((0, _fp.nthArg)(1), (0, _fp.flip)((0, _fp.over)(cashflowLens))), (0, _fp.cardinal_)(_fp.call)((0, _fp.pipe)((0, _fp.view)(indexLens), _fp.update))]); // prettier-ignore

exports.updateCashflow = updateCashflow;
const evaluateMonthAndUpdateCashflow = (0, _fp.curry)((conditionalName, accumulator) => updateCashflow(evaluateMonthByCondition(conditionalName, accumulator), accumulator)); // prettier-ignore

exports.evaluateMonthAndUpdateCashflow = evaluateMonthAndUpdateCashflow;
const conditionalMapIteratorBusiness = (0, _fp.converge)(_fp.reduceWithCall, [(0, _fp.nthArg)(1), (0, _fp.pipe)((0, _fp.nthArg)(0), evaluateMonthAndUpdateCashflow), (0, _fp.pipe)((0, _fp.nthArg)(0), updateCounters)]); // prettier-ignore

exports.conditionalMapIteratorBusiness = conditionalMapIteratorBusiness;
const conditionalMapIterator = (0, _fp.converge)(conditionalMapIteratorBusiness, [(0, _fp.pipe)((0, _fp.nthArg)(0), getFirstValidConditionalName), (0, _fp.flip)((0, _fp.set)(currentValueLens))]); // prettier-ignore

exports.conditionalMapIterator = conditionalMapIterator;
const conditionalMap = (0, _fp.converge)((0, _fp.pipe)((0, _fp.reduce)(conditionalMapIterator), (0, _fp.prop)('cashflow')), [generateConditionalAccumulator, (0, _fp.nthArg)(2)]); // prettier-ignore

exports.conditionalMap = conditionalMap;
const getFreestyleValue = (0, _fp.cond)([[_fp.isNil, (0, _fp.always)(_fp.Maybe.Nothing)], [(0, _fp.propSatisfies)((0, _fp.equals)(''), 'value'), (0, _fp.always)(_fp.Maybe.Nothing)], [(0, _fp.propSatisfies)(_type.isNumber, 'value'), (0, _fp.pipe)((0, _fp.prop)('value'), _fp.Maybe.Just)], [_fp.T, (0, _fp.always)(_fp.Maybe.Nothing)]]); // prettier-ignore

exports.getFreestyleValue = getFreestyleValue;
const generateCounters = (0, _fp.starling_)(_fp.zipObj)((0, _fp.pipe)((0, _fp.pluck)('name'), (0, _fp.append)('index')))((0, _fp.pipe)(_fp.length, _fp.inc, (0, _fp.repeat)(0))); // prettier-ignore

exports.generateCounters = generateCounters;

const mapCashflowForMathJsRawArgs = (args, math, scope) => (0, _fp.map)(evalAndRound(args[0].value), generateFunctorForMapCashflow(args, scope)); // prettier-ignore


exports.mapCashflowForMathJsRawArgs = mapCashflowForMathJsRawArgs;

const mapIndexedCashflowForMathJsRawArgs = (args, math, scope) => (0, _arrays.mapIndexed)((a, index) => Math.round(evaluateExpression(args[0].value, {
  index,
  ...a
})), generateFunctorForMapCashflow(args, scope)); // prettier-ignore


exports.mapIndexedCashflowForMathJsRawArgs = mapIndexedCashflowForMathJsRawArgs;

const variableCashflowForMathJsRawArgs = (args, math, scope) => generateVariableCashflow(args[0].value, evaluateExpression(args[1].value, scope), evaluateExpression(args[2].value, scope), scope); // prettier-ignore


exports.variableCashflowForMathJsRawArgs = variableCashflowForMathJsRawArgs;

const conditionalCashflowForMathJsRawArgs = (args, math, scope) => generateConditionalCashflow(evaluateExpression(args[0].value, scope), evaluateExpression(args[1].value, scope), formatRawConditionals(args[2].items), (0, _fp.merge)(scope, generateCounters(formatRawConditionals(args[2].items)))); //args: [{conditionals: Array}, {cashflowKey: string}]
// prettier-ignore


exports.conditionalCashflowForMathJsRawArgs = conditionalCashflowForMathJsRawArgs;

const conditionalMapForMathJsRawArgs = (args, math, scope) => conditionalMap(formatRawConditionals(args[0].items), (0, _fp.merge)(scope, generateCounters(formatRawConditionals(args[0].items))), evaluateExpression(args[1].value, scope)); // prettier-ignore


exports.conditionalMapForMathJsRawArgs = conditionalMapForMathJsRawArgs;

const reduceCashflowForMathJsRawArgs = (args, math, scope) => {
  const transformer = args[0].value;
  const functor = evaluateCustomArg(2, args, scope);
  const init = evaluateCustomArg(1, args, scope);

  const iterator = (accumulator, currentValue, index) => (0, _fp.append)(evaluateExpression(transformer, { ...scope,
    accumulator,
    currentValue,
    index
  }), accumulator);

  return (0, _fp.addIndex)(_fp.reduce)(iterator, init, functor);
}; // prettier-ignore


exports.reduceCashflowForMathJsRawArgs = reduceCashflowForMathJsRawArgs;

const annualCashflowForMathJsRawArgs = (args, math, scope) => (0, _fp.pipe)(generateVariableCashflow(args[0].value, 1, evaluateExpression(args[1].value, scope)), (0, _fp.slice)(1, evaluateExpression(args[1].value, scope) + 1))(scope);

exports.annualCashflowForMathJsRawArgs = annualCashflowForMathJsRawArgs;
conditionalCashflowForMathJsRawArgs.rawArgs = true;
conditionalMapForMathJsRawArgs.rawArgs = true;
variableCashflowForMathJsRawArgs.rawArgs = true;
mapCashflowForMathJsRawArgs.rawArgs = true;
reduceCashflowForMathJsRawArgs.rawArgs = true;
mapIndexedCashflowForMathJsRawArgs.rawArgs = true;
annualCashflowForMathJsRawArgs.rawArgs = true;
math.import({
  accumulate: _cashflow.accumulate,
  accumulateCashflow: _cashflow.accumulateCashflow,
  averageCashflow: _cashflow.averageCashflow,
  carbonEmissionFactorKgPerKwh: _constants.carbonEmissionFactorKgPerKwh,
  complementaryPercentage: _math.complementaryPercentage,
  conditionalCashflow: conditionalCashflowForMathJsRawArgs,
  conditionalMap: conditionalMapForMathJsRawArgs,
  daysPerMonth: _constants.daysPerMonth,
  daysPerYear: _constants.daysPerYear,
  freestyle: (0, _fp.pipe)(getFreestyleValue, _fp.Maybe.fromMaybe(0)),
  growRange: _cashflow.growRange,
  mapCashflow: mapCashflowForMathJsRawArgs,
  minutesPerFteDay: _constants.minutesPerFteDay,
  minutesPerHour: _constants.minutesPerHour,
  minutesToSeconds: _units.minutesToSeconds,
  monthlyCashflow: _cashflow.monthlyCashflowWithNthRootGrowth,
  monthsPerYear: _constants.monthsPerYear,
  monthsToSeconds: _units.monthsToSeconds,
  normalizingFactor: _math.normalizingFloatingFixedPointFactor,
  nth: _fp.nth,
  nthRootGrowCashflow: _cashflow.nthRootGrowCashflow,
  one: _constants.floatingFixedPointFactor,
  oneHundredPercent: _constants.oneHundredPercent,
  oneTimeCashflow: _cashflow.oneTimeCashflow,
  variableCashflow: variableCashflowForMathJsRawArgs,
  fteDaysPerYear: _constants.fteDaysPerYear,
  fteDaysToSeconds: _units.fteDaysToSeconds,
  fteDaysPerWeek: _constants.fteDaysPerWeek,
  hoursPerDay: _constants.hoursPerDay,
  hoursPerFteDay: _constants.hoursPerFteDay,
  hoursPerFteMonth: _constants.hoursPerFteMonth,
  hoursPerFteYear: _constants.hoursPerFteYear,
  hoursPerYear: _constants.hoursPerYear,
  hoursToSeconds: _units.hoursToSeconds,
  ratchetCashflow: _cashflow.ratchetCashflow,
  nth: _fp.nth,
  secondsPerMinute: _constants.secondsPerMinute,
  secondsToFteDays: _units.secondsToFteDays,
  secondsToDays: _units.secondsToDays,
  secondsToHours: _units.secondsToHours,
  secondsToMonths: _units.secondsToMonths,
  secondsToMinutes: _units.secondsToMinutes,
  secondsToWeeks: _units.secondsToWeeks,
  subtractCashflow: _cashflow.subtractCashflow,
  sumCashflows: _cashflow.sumCashflows,
  weeksToSeconds: _units.weeksToSeconds,
  weeksPerYear: _constants.weeksPerYear,
  monthToYear: _cashflow.monthToYear,
  annualTotals: _cashflow.unaccumulatedAnnualTotals,
  product: _fp.product,
  reduceCashflow: reduceCashflowForMathJsRawArgs,
  mapCashflowIndexed: mapIndexedCashflowForMathJsRawArgs,
  annualCashflow: annualCashflowForMathJsRawArgs
});