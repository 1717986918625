"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.switchOnProp = exports.maybeType = exports.isNotEmpty = exports.defaultFallback = exports.anyEquals = void 0;

var _fp = require("./fp");

const switchOnProp = (0, _fp.useWith)(_fp.ifElse, [(0, _fp.propEq)(_fp.placeholder, true), _fp.kestrel, _fp.kestrel]);
exports.switchOnProp = switchOnProp;
const defaultFallback = (0, _fp.useWith)(_fp.propOr, [_fp.idiot, _fp.idiot, _fp.idiot]);
exports.defaultFallback = defaultFallback;
const isNotEmpty = (0, _fp.complement)(_fp.isEmpty);
exports.isNotEmpty = isNotEmpty;
const anyEquals = (0, _fp.curry)((elements, value) => (0, _fp.flip)(_fp.any)(elements)((0, _fp.equals)(value)));
exports.anyEquals = anyEquals;
const maybeType = (0, _fp.ifElse)(_fp.placeholder, _fp.Maybe.Just, (0, _fp.always)(_fp.Maybe.Nothing));
exports.maybeType = maybeType;