import { css } from 'styled-components';
import { setAlphaHsla } from '@sharkfinesse/sfl-lib';

const H = 0.4;
const ToggleSwitch = ({ font, colors, scale }) => ({
  container: () => css`
    display: flex;
    align-self: center;
    align-items: center;
  `,
  labelWrapper: ({ labelPosition, labelSize }) => css`
    display: flex;
    line-height: 18px;
    margin-left: ${labelPosition === 'after' ? '8px' : 0};
    margin-right: ${labelPosition === 'after' ? 0 : '8px'};
    cursor: pointer;
    ${labelSize === 'small' &&
    css`
      font-size: 12px;
    `}
  `,
  onColor: colors.primaryHex,
  onHandleColor: '#ffffff',
  offColor: '#808080',
  offHandleColor: '#ffffff',
  activeBoxShadow: `0px 0px 2px 3px ${setAlphaHsla(H, colors.primary)}`,
});

export default ToggleSwitch;
