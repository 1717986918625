import { css } from 'styled-components';

const Mixins = () => ({
  forceLineBreak: () => css`
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-word;
    word-break: break-word;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  `,
  ellipses: () => css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
});

export default Mixins;
