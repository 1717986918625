// @flow
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import HotkeyLabel, { useHotkeys } from '../hotkey';
import Tooltip from '../tooltip';
import { ToggleWrapper, ToggleArrow } from './styled'; //
import { UserAgent } from '@quentin-sommer/react-useragent';
import { IconToggleTab, IconToggleChevron } from '../icon';

export type Props = {
  expanded: Boolean,
  onClick: Function,
  small: Boolean,
};

const ToggleButton = ({ expanded, onClick, small }: Props) => {
  useHotkeys('ctrl+/', onClick);
  useHotkeys('command+/', onClick);
  return (
    <ToggleWrapper onClick={onClick} expanded={expanded} small={small}>
      <Tooltip
        tooltip={
          <Fragment>
            {expanded && (
              <FormattedMessage id="sidebar.toggleButton.hide.label" defaultMessage="Hide" />
            )}
            {!expanded && (
              <FormattedMessage id="sidebar.toggleButton.show.label" defaultMessage="Show" />
            )}
            {!small && (
              <HotkeyLabel>
                <UserAgent mac>{uaIsMac => (uaIsMac ? 'Command+/' : 'Crtl+/')}</UserAgent>
              </HotkeyLabel>
            )}
          </Fragment>
        }
        delayShow={500}
        tooltipConfig={{
          placement: 'right',
          usePortal: true,
        }}
      >
        <IconToggleTab />
        <ToggleArrow expanded={expanded}>
          <IconToggleChevron />
        </ToggleArrow>
      </Tooltip>
    </ToggleWrapper>
  );
};

export default ToggleButton;
