"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchProduct = void 0;

var _utils = require("./utils");

const fetchProduct = async ({
  firestore,
  id,
  product
}) => {
  const [productDoc, abilities, logos, preferences, coverpages] = await Promise.all([(0, _utils.getDoc)({
    firestore,
    path: `products/${product}`
  }), (0, _utils.getDoc)({
    firestore,
    path: `products/${product}/abilities/data`
  }), (0, _utils.getCollection)({
    firestore,
    path: `products/${product}/logos`
  }), (0, _utils.getCollection)({
    firestore,
    path: `products/${product}/preferences`
  }), (0, _utils.getCollection)({
    firestore,
    path: `products/${product}/coverpages`
  })]);
  return { ...productDoc.data(),
    abilities: abilities.data(),
    logos: (0, _utils.getCollectionDocs)(logos),
    preferences: (0, _utils.getCollectionDocs)(preferences),
    coverpages: (0, _utils.getCollectionDocs)(coverpages)
  };
};

exports.fetchProduct = fetchProduct;