// @flow
import React, { useState } from 'react';
import Modal from '../modal';
import Button from '../button';
import { FormattedMessage } from 'react-intl';
import NiceModal from '@ebay/nice-modal-react';
import useNiceModal from '../modal/useNiceModal';
import { ModalBodyHeight } from '../modal';
import { useIntl } from 'react-intl';
import { CharactersRemaining, RelativeWrapper, PaddedColumn } from '../general-styles';
import Scroll from '../scroll';
import TextField from '../text-field';
import { FormGrid } from '../form/styled';

const MessageModal = ({ title }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const intl = useIntl();
  const { modal, modalProps } = useNiceModal();

  const confirm = () => {
    modal.resolve(message);
    setLoading(true);
  };

  return (
    <Modal
      {...modalProps}
      title={title}
      showHeaderKeyline
      showFooterKeyline
      closeOnOutsideClick={false}
      loading={loading}
      footer={
        <>
          <Button
            disabled={loading}
            style={{ marginLeft: '10px', minWidth: '60px' }}
            onClick={modalProps.onClose}
          >
            <FormattedMessage id="buttons.cancel.label" defaultMessage="Cancel" />
          </Button>
          <Button
            color="Primary"
            onClick={confirm}
            style={{ marginLeft: '10px', minWidth: '60px' }}
            label={true}
            loading={loading}
          >
            <FormattedMessage id="buttons.send.label" defaultMessage="Send" />
          </Button>
        </>
      }
    >
      <ModalBodyHeight maxHeight="310px">
        <RelativeWrapper>
          <Scroll noScrollX={true}>
            <FormGrid as={PaddedColumn}>
              <TextField
                layout="column"
                autoComplete="off"
                placeholder={intl.formatMessage({
                  id: 'share.message.modal.placeholder',
                  defaultMessage: 'Include a message (optional)',
                })}
                name="message"
                multiline
                rows="11"
                value={message}
                onChange={e => setMessage(e.target.value)}
                maxLength="1200"
              />
              <CharactersRemaining>
                <FormattedMessage
                  id="dealShare.shareModal.notfiy.message.chractersRemaining"
                  defaultMessage="{numberRemaining} characters"
                  values={{
                    numberRemaining: `${message.length}/1200`,
                  }}
                />
              </CharactersRemaining>
            </FormGrid>
          </Scroll>
        </RelativeWrapper>
      </ModalBodyHeight>
    </Modal>
  );
};

export default NiceModal.create(MessageModal);
