/* @flow */
/* eslint-disable id-length, id-match */

import React from 'react';
import { Panel, HeadingWrapper, Heading, HeaderContainer } from './styled';

const FormPanel = ({ children, heading, ...props }: Props): React.Element<any> => (
  <Panel
    headerContainer={HeaderContainer}
    header={
      heading && (
        <HeadingWrapper>
          <Heading>{heading}</Heading>
        </HeadingWrapper>
      )
    }
    {...props}
  >
    {children}
  </Panel>
);

export default FormPanel;
