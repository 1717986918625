// @flow
/* eslint-disable no-confusing-arrow, no-nested-ternary  */
import React, { memo } from 'react';
import Tree, { TreeNode } from 'rc-tree';
import SwitcherIcon from './switcher-icon';
import Checkbox from './checkbox';

type DefaultProps = {
  useCheckboxes: Boolean,
};

type Props = DefaultProps & {
  treeData: Array,
  defaultSelectedKeys: Array,
  defaultCheckedKeys: Array,
  onCheck: Function,
  useCheckboxes: Boolean,
  selectable: Boolean,
  defaultExpandedKeys: Array,
  checkedKeys: Array,
  prefixCls: String,
};

const onEnterActive = node => {
  return { height: node.scrollHeight };
};

const motion = {
  motionName: 'rc-tree-node-motion',
  motionAppear: false,
  onEnterActive,
  onLeaveStart: node => ({ height: node.offsetHeight }),
};

const renderNodes = treeData => {
  const getNodes = data =>
    data.map(({ children, key, title, icon }) => (
      <TreeNode
        icon={props => (
          <>
            {icon} <Checkbox {...props} />
          </>
        )}
        key={key}
        title={title}
      >
        {children && getNodes(children)}
      </TreeNode>
    ));

  return getNodes(treeData);
};

const TreeComponent = ({
  onCheck,
  treeData,
  useCheckboxes,
  defaultExpandedKeys,
  selectable,
  checkedKeys,
  defaultSelectedKeys,
  defaultCheckedKeys,
  prefixCls = 'rc-tree',
}: Props) => (
  <Tree
    showLine={false}
    checkable={useCheckboxes}
    selectable={selectable}
    defaultExpandedKeys={defaultExpandedKeys}
    defaultSelectedKeys={defaultSelectedKeys}
    defaultCheckedKeys={defaultCheckedKeys}
    onCheck={onCheck}
    switcherIcon={props => <SwitcherIcon {...props} />}
    motion={motion}
    showIcon={useCheckboxes}
    checkedKeys={checkedKeys}
    prefixCls={prefixCls}
  >
    {renderNodes(treeData)}
  </Tree>
);

TreeComponent.defaultProps = {
  useCheckboxes: true,
  defaultExpandedKeys: [],
  selectable: false,
  checkedKeys: [],
};

export default memo(TreeComponent);
