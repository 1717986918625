/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon viewBox="0 0 28 28" {...props}>
    <path
      fill="currentColor"
      d="M1.8,0h24.5c1,0,1.8,0.8,1.8,1.8v24.5c0,1-0.8,1.8-1.8,1.8H1.8c-1,0-1.8-0.8-1.8-1.8V1.8C0,0.8,0.8,0,1.8,0
		z"
    />
    <path
      fill="#FFFFFF"
      d="M11.4,18.4h3.4v-3.3h1c1.5,0,2.7-1.2,2.7-2.7c0-1.5-1.2-2.7-2.7-2.7c0,0,0,0,0,0h-4.4V18.4z M16.5,20.1H9.6
		V7.9h6.2c2.5,0,4.5,2,4.5,4.5c0,2.2-1.6,4.1-3.8,4.4C16.5,16.8,16.5,20.1,16.5,20.1z"
    />
  </Icon>
);

export default memo(IconComponent);
