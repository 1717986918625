/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

const IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M9.3,7.8V2.5L0,11.8l9.3,9.3v-5.5c6.7,0,11.3,2.1,14.7,6.8C22.7,15.8,18.7,9.2,9.3,7.8z"
    />
  </Icon>
);

export default memo(IconComponent);
