// @flow
/* eslint-disable no-confusing-arrow */
import React from 'react';
import Scroll from '../scroll';
import { Aside, MenuContainer, Heading } from './styled';
import MenuItem from './menu-item';

export type Props = {
  collapsed: Boolean,
  open: Boolean,
  float: Boolean,
  items: Array,
  menuChildren: Array,
};

const MainMenu = ({ collapsed, open, float, items, menuChildren }: Props) => {
  return (
    <Aside collapsed={collapsed} open={open} float={float}>
      <Scroll noScrollX>
        <MenuContainer>
          {items.map((item, key) =>
            item.subheading ? (
              <Heading key={key}>{item.subheading}</Heading>
            ) : (
              <MenuItem
                icon={item.icon}
                key={key}
                path={item.path}
                exact={item.exact}
                onClick={item.onClick}
                menuChildren={menuChildren}
              >
                {item.label}
              </MenuItem>
            )
          )}
        </MenuContainer>
      </Scroll>
    </Aside>
  );
};

export default MainMenu;
