/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

const IconComponent = props => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M21.9,8.2C21.6,6.9,20.5,6,19.1,6h-11C6.6,6,5.4,7.3,5.4,8.8V9c0,0.5,0.1,0.9,0.4,1.3c-1.4,0.2-2.5,1.3-2.5,2.8v0.2
	c0,1.5,1.3,2.8,2.8,2.8h0.7c-0.2,0.4-0.3,0.8-0.3,1.3v0.2c0,0.5,0.1,0.9,0.3,1.3H6c-1.5,0-2.8,1.3-2.8,2.8v0.2
	c0,1.5,1.3,2.8,2.8,2.8h7.4c1.8,2,4.4,3.3,7.3,3.3c5.5,0,9.9-4.5,9.9-9.9C30.8,13,26.9,8.7,21.9,8.2z M6.9,8.8
	c0-0.7,0.6-1.3,1.3-1.3h11c0.5,0,0.9,0.3,1.1,0.7c-2.1,0.1-4.1,0.9-5.6,2.1H8.1c-0.7,0-1.3-0.6-1.3-1.3V8.8z M4.8,13.3v-0.2
	c0-0.7,0.6-1.3,1.3-1.3h2.1h5c-0.7,0.8-1.2,1.8-1.6,2.8H9.2H6C5.3,14.6,4.8,14,4.8,13.3z M7.9,17.6v-0.2c0-0.7,0.6-1.3,1.3-1.3h1.8
	c-0.1,0.6-0.2,1.3-0.2,2c0,0.3,0,0.6,0,0.9H9.2C8.5,18.9,7.9,18.3,7.9,17.6z M6,23.2c-0.7,0-1.3-0.6-1.3-1.3v-0.2
	c0-0.7,0.6-1.3,1.3-1.3h3.1c0,0,0.1,0,0.1,0h1.9c0.2,1,0.6,1.9,1.2,2.8H6z M20.8,26.5c-4.7,0-8.4-3.8-8.4-8.4s3.8-8.4,8.4-8.4
	s8.4,3.8,8.4,8.4S25.5,26.5,20.8,26.5z"
    />
    <path
      fill="currentColor"
      d="M20.8,10.9c-4,0-7.2,3.2-7.2,7.2s3.2,7.2,7.2,7.2s7.2-3.2,7.2-7.2S24.8,10.9,20.8,10.9z M20.8,23.8c-3.2,0-5.7-2.6-5.7-5.7
	s2.6-5.7,5.7-5.7s5.7,2.6,5.7,5.7S24,23.8,20.8,23.8z"
    />
    <path
      fill="currentColor"
      d="M21.1,17.3c-1-0.3-1-0.4-1-0.6c0-0.2,0.2-0.4,0.4-0.4c0.5-0.2,1.1,0.1,1.4,0.6c0.2,0.4,0.7,0.5,1,0.3s0.5-0.7,0.3-1
	c-0.4-0.6-0.9-1-1.6-1.2v-0.6c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8v0.6c0,0,0,0,0,0c-0.8,0.2-1.3,0.9-1.4,1.7
	c-0.2,1.6,1.2,1.9,2.1,2.2c1,0.3,1.1,0.4,1.1,0.7c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.3,0.2-0.5,0.2c-0.5,0.1-1.1-0.2-1.5-0.6
	c-0.3-0.3-0.7-0.4-1.1-0.1s-0.4,0.7-0.1,1.1c0.4,0.5,1,0.9,1.6,1v0.6c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-0.6
	c0.4-0.1,0.7-0.2,1-0.5c0.4-0.4,0.7-0.9,0.7-1.4C23.3,17.9,21.9,17.5,21.1,17.3z"
    />
  </Icon>
);

export default memo(IconComponent);
