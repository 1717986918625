"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tenderCost = exports.priceImprovementRequired = exports.grossTenderCost = void 0;

var _fp = require("./fp");

var _math = require("./math");

const monthCount = 12;
const divideByMonths = (0, _fp.divide)(_fp.placeholder, monthCount);
const tenderCost = (0, _fp.converge)((0, _fp.unapply)((0, _fp.pipe)(_fp.sum, Math.floor)), [(0, _fp.pipe)(_fp.multiply, (0, _math.decreaseByFloatingFixedPointFactor)(1)), (0, _fp.pipe)((0, _fp.unapply)((0, _fp.curryN)(2, (0, _fp.pipe)(_fp.pickAll, _fp.values))([0, 2, 3]) // eslint-disable-line no-magic-numbers
), (0, _fp.reduce)(_fp.multiply, 1), divideByMonths, (0, _math.decreaseByFloatingFixedPointFactor)(2)), (0, _fp.nthArg)(4) // eslint-disable-line no-magic-numbers
]);
exports.tenderCost = tenderCost;
const grossTenderCost = (0, _fp.converge)((0, _fp.unapply)(_fp.sum), [(0, _fp.pipe)(_fp.multiply, _fp.negate, (0, _math.decreaseByFloatingFixedPointFactor)(1)), tenderCost, (0, _fp.converge)((0, _fp.pipe)(_fp.multiply, (0, _math.decreaseByFloatingFixedPointFactor)(1)), [(0, _fp.nthArg)(5), (0, _fp.nthArg)(0)] // eslint-disable-line no-magic-numbers
)]);
exports.grossTenderCost = grossTenderCost;
const priceImprovementRequired = (0, _fp.pipe)((0, _fp.converge)(_fp.divide, [tenderCost, (0, _fp.nthArg)(5)]), (0, _fp.multiply)(100 * (0, _math.normalizingFloatingFixedPointFactor)(1))); // eslint-disable-line no-magic-numbers

exports.priceImprovementRequired = priceImprovementRequired;