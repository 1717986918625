/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

const IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M17,5H3C1.9,5,1,5.9,1,7v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V7C19,5.9,18.1,5,17,5z M17,21H3V7h14V21z"
    />
    <path
      fill="currentColor"
      d="M11.9,12.1c0.6,0.6,1,1.5,1,2.4c0,0.9-0.4,1.7-1,2.4L9.7,19c-0.6,0.7-1.5,1-2.4,1c-0.9,0-1.7-0.3-2.4-1
	c-1.3-1.3-1.3-3.4,0-4.7l1.4-1.4l0,0.6c0,0.5,0.1,1,0.2,1.4l0.1,0.2l-0.4,0.4c-0.3,0.3-0.5,0.7-0.5,1.1c0,0.4,0.2,0.8,0.5,1.1
	c0.6,0.6,1.7,0.6,2.3,0l2.2-2.2c0.3-0.3,0.5-0.7,0.5-1.2c0-0.4-0.2-0.8-0.5-1.1c-0.2-0.2-0.3-0.4-0.3-0.6c0-0.2,0.1-0.5,0.3-0.6
	C11,11.7,11.6,11.7,11.9,12.1"
    />
    <path
      fill="currentColor"
      d="M16,11.4c0,0.9-0.4,1.7-1,2.4l-1.3,1.4v-0.6c0-0.5-0.1-1-0.2-1.4l-0.1-0.1l0.4-0.4c0.3-0.3,0.5-0.7,0.5-1.1
	c0-0.4-0.2-0.8-0.5-1.1c-0.6-0.6-1.7-0.6-2.3,0l-2.2,2.2c-0.3,0.3-0.5,0.7-0.5,1.1c0,0.4,0.2,0.8,0.5,1.1c0.2,0.2,0.3,0.4,0.3,0.6
	c0,0.2-0.1,0.5-0.3,0.6c-0.2,0.2-0.4,0.2-0.6,0.2c-0.2,0-0.5-0.1-0.6-0.2c-1.3-1.3-1.3-3.4,0-4.8L10.3,9c1.3-1.3,3.4-1.3,4.7,0
	C15.6,9.6,16,10.5,16,11.4z"
    />
    <path
      fill="currentColor"
      d="M21,1H7C5.9,1,5,1.9,5,3h2h14v14v2c1.1,0,2-0.9,2-2V3C23,1.9,22.1,1,21,1z"
    />
  </Icon>
);

export default memo(IconComponent);
