/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

let IconCalculator = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      d="M20,3V21a1.1,1.1,0,0,1-1,1H5a1.1,1.1,0,0,1-1-1V3A1.1,1.1,0,0,1,5,2H19A1.1,1.1,0,0,1,20,3ZM19,21V3H5V21Z"
      fill="currentColor"
    />
    <rect x="15" y="13" width="2" height="6" fill="inherit" />
    <path
      d="M9,11V9H7v2Zm0,4V13H7v2Zm0,4V17H7v2Zm4-8V9H11v2Zm0,4V13H11v2Zm0,4V17H11v2Zm4,0V13H15v6Zm0-8V9H15v2Zm1-3V20H6V8Z"
      fill="currentColor"
    />
    <rect x="7" y="5" width="10" height="1" fill="inherit" />
    <path d="M17,6V5H7V6Zm1-2V7H6V4Z" fill="currentColor" />
  </Icon>
);

export default memo(IconCalculator);
