// @flow
import usePolicy from './usePolicy';

type Props = {
  language: Object,
  id: String,
};

const Policy = ({ language, id, collection }: Props) =>
  usePolicy({
    id,
    language,
    collection,
  });
export default Policy;
