import { css } from 'styled-components';

const Account = ({ font, colors, scale }) => ({
  profileImage: src => css`
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-image: url(${src});
  `,
  profileImageLarge: () => css`
    width: 62px;
    height: 62px;
    display: inline-block;
    position: relative;
    margin-right: 14px;
  `,
  wrapper: () => css`
    padding: ${scale.zero};
  `,
  profile: () => css`
    display: inline-block;
    vertical-align: top;
    text-align: left;
  `,
  name: () => css`
    font-weight: ${font.weightSemiBold};
    margin: 0;
  `,
  email: () => css`
    font-weight: ${font.normal};
    color: ${colors.ui};
    margin: 0;
  `,
  divider: () => css`
    margin: 10px 0;
    height: 1px;
    background-color: ${colors.uiLightest};
  `,
  animatedIcon: () => css`
    position: absolute;
    width: 36px;
    height: 36px;
    cursor: pointer;
    will-change: transform, opacity;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  animatedLabelWrapper: () => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    background: ${colors.uiLightest};
    padding: 2px;
    height: 40px;
    border-radius: 20px;
  `,
  animatedLabel: () => css`
    color: ${font.color};
    font-size: 12px;
    align-self: flex-start;
    align-items: center;
    flex: 1 1 auto;
    display: flex;
    margin: 0 20px 0 20px;
    will-change: opacity;
  `,
  labelBackgroundFrom: colors.uiLightest,
  labelBackgroundTo: colors.white,
});

export default Account;
