import { css } from 'styled-components';

const SlideGrid = ({ font, colors, scale, device: { xs, md, lg, min_xl, max_md, max_sm } }) => ({
  gridContainer: () => css`
    width: 100%;
    @media ${max_md} {
      padding: 6px;
    }
    @media ${lg} {
      padding: 8px 18px 18px 18px;
    }
    @media ${min_xl} {
      padding: 8px 30px 30px 30px;
    }
  `,
  gridWrapper: () => css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-rows: 180px;
    width: 100%;
    grid-gap: 36px 6px;
    @media ${max_sm} {
      grid-gap: 6px 6px;
    }
  `,
  compactGridWrapper: () => css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-auto-rows: 140px;
    width: 100%;
    grid-gap: 6px 6px;
    @media ${max_sm} {
      grid-gap: 6px 6px;
    }
  `,
  emptyMessage: () => css`
    font-size: 16px;
    margin-top: 20px;
    color: ${font.color};
  `,
});

export default SlideGrid;
