"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

const getLoggedIn = state => state.login.loggedIn;

const getLoading = state => state.login.loading;

const getUser = state => state.login.user;

const getUID = state => {
  var _state$login, _state$login$user;

  return state === null || state === void 0 ? void 0 : (_state$login = state.login) === null || _state$login === void 0 ? void 0 : (_state$login$user = _state$login.user) === null || _state$login$user === void 0 ? void 0 : _state$login$user.uid;
};

const getName = state => state.login.user.displayName;

const getAvatar = state => state.login.user.photoURL;

const getEmail = state => state.login.user.email;

const getLastLogin = state => state.login.user.lastLoginAt;

const getCreatedAt = state => state.login.user.createdAt;

const getComplete = state => state.login.complete;

const getQuestionnaireAuthenticated = state => state.login.questionnaire.authenticated;

const selectors = {
  getLoggedIn,
  getLoading,
  getUser,
  getUID,
  getName,
  getAvatar,
  getEmail,
  getLastLogin,
  getCreatedAt,
  getComplete,
  getQuestionnaireAuthenticated
};
var _default = selectors;
exports.default = _default;