import styled from 'styled-components';

export const HeaderContainer = styled.header`
  ${p => p.theme.header.base()};
`;

export const Heading = styled.h1`
  ${p => p.theme.header.h1({ ...p })};
`;

export const IconWrapper = styled.div`
  ${p => p.theme.header.iconWrapper()};
`;
