import { put, all, call, select } from 'redux-saga/effects';
import { actionCreators as appActionCreators } from 'redux/modules/app';
import { map, businessCaseSelectors, userSelectors } from '@sharkfinesse/sfl-lib';
import { actionCreators as compareActionCreators } from 'redux/modules/compare';
import { fetchSession } from 'redux/firestore/hooks';
import { history } from '../../../index';

const checkEmptyArray = compareArray => {
  let empty = true;
  for (let x = 0; x < compareArray.length; x++) {
    if (compareArray[x] !== 0) {
      empty = false;
      break;
    }
  }
  if (empty) {
    return [];
  }
  return compareArray;
};

export function* getSessionForCompare({ account, id }) {
  const session = yield call(fetchSession, { account, id });

  const reportContent = yield select((state, args) =>
    businessCaseSelectors.getReportContent(
      {
        account: state.account,
        product: state.product,
        intl: state.intl,
        session: { present: session },
      },
      args
    )
  );

  const { dealName, irrCompareCashflow } = reportContent;
  const reportObj = {
    ...reportContent,
    filename: dealName,
    irrCompareCashflow: checkEmptyArray(irrCompareCashflow),
    sessionId: id,
  };

  return reportObj;
}

export function* compare(action) {
  yield call([history, history.push], {
    pathname: '/compare',
    state: { from: action.payload.location.pathname },
  });
  const account = yield select(userSelectors.getAccount);
  yield all([
    put(appActionCreators.updateLoadingActive(true)),
    put(appActionCreators.updateLoadingText('')),
  ]);

  // Load comparison data
  const comparisonSessionData = yield all(
    map(id => call(getSessionForCompare, { account, id }), action.payload.checked)
  );

  yield put(compareActionCreators.data(comparisonSessionData));

  yield put(appActionCreators.updateLoadingActive(false));
}

export default compare;
