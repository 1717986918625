import { select, take, delay } from 'redux-saga/effects';

function* waitFor(selector) {
  if (yield select(selector)) return;

  while (true) {
    yield take('*');
    if (yield select(selector)) {
      yield delay(100);
      return;
    }
  }
}
export default waitFor;
