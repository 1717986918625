import { put, all, select, race, take } from 'redux-saga/effects';
import { actionCreators as sessionDetailsActionCreators } from 'redux/modules/session-details';
import {
  isNil,
  sessionDetailsSelectors,
  preferencesSelectors,
  deviceSelectors,
} from '@sharkfinesse/sfl-lib';
import { types as sessionTypes } from 'redux/modules/session';
import fetchAuth from 'utils/fetchAuth';

function* fetchImage(action) {
  const [media, autoImageEnabled = false, autoImageKeyword = 'work', isOnline] = yield all([
    select(sessionDetailsSelectors.getSessionMedia),
    select(preferencesSelectors.getAutoImageEnabled),
    select(preferencesSelectors.getAutoImageKeyword),
    select(deviceSelectors.isOnline),
  ]);
  if (!isOnline) return;
  if (!autoImageEnabled) return;
  if (isNil(media?.fetch)) return;
  try {
    const { result } = yield race({
      result: fetchAuth({
        url: `media/get?service=unsplash_random&q=${autoImageKeyword}`,
      }),
      unloaded: take(sessionTypes.UNLOAD),
    });

    if (result) {
      if (!result?.errors) {
        yield all([
          put(
            sessionDetailsActionCreators.updateSessionMedia({
              src: result.urls.small,
              url: result.urls.regular,
              id: result.id,
              type: 'photo',
              position: {
                percent: { y: '-30', x: 0 },
                pixel: { y: 0, x: 0 },
              },
            })
          ),
          fetchAuth({
            url: `media/get?service=unsplash_download&page=${result.links.download_location}`,
          }),
        ]);
      }
      return result;
    } else {
      return;
    }
  } catch (error) {
    console.log('fetch image error', error);
    return;
  }
}

export default fetchImage;
