import { takeEvery } from 'redux-saga/effects';
import { types as layoutTypes } from 'redux/modules/layout';
import deleteBenefit from './benefit/deleteBenefit';
import addBenefit from './benefit/addBenefit';
import duplicateBenefit from './benefit/duplicateBenefit';
import addSuite from './suite/addSuite';
import deleteSuite from './suite/deleteSuite';
import moveSuite from './suite/moveSuite';
import duplicateSuite from './suite/duplicateSuite';
import deleteSuiteBenefits from './suite/deleteSuiteBenefits';

export const effects = [
  takeEvery(layoutTypes.SUITE.ADD, addSuite),
  takeEvery(layoutTypes.SUITE.DELETE, deleteSuite),
  takeEvery(layoutTypes.BENEFIT.ADD, addBenefit),
  takeEvery(layoutTypes.BENEFIT.DELETE, deleteBenefit),
  takeEvery(layoutTypes.BENEFIT.CUSTOM_BENEFIT_DELETE, deleteBenefit),
  takeEvery(layoutTypes.BENEFIT.DUPLICATE, duplicateBenefit),
  takeEvery(layoutTypes.SUITE.MOVE, moveSuite),
  takeEvery(layoutTypes.SUITE.DUPLICATE, duplicateSuite),
  takeEvery(layoutTypes.SUITE.DELETE_BENEFITS, deleteSuiteBenefits),
];

export default effects;
