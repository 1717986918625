import { select, put, take, all, fork, call, cancel } from 'redux-saga/effects';
import { types as productTypes } from 'redux/modules/product';
import { actionCreators as sessionActionCreators } from 'redux/modules/session';
import { actionCreators as appActionCreators } from 'redux/modules/app';
import { actionCreators as layoutDesignerActionCreators } from 'redux/modules/layoutDesigner';
import { actionCreators as trackingActionCreators } from 'redux/modules/tracking';
import sync from './sync';
import { types as sessionTypes } from 'redux/modules/session';
import setSelectedSuite from '../layoutDesigner/setSelectedSuite';
import clearSessionStorage from '../clearSessionStorage';
import fetchImage from './fetch-image';
import {
  productSelectors,
  intlSelectors,
  userSelectors,
  deviceSelectors,
} from '@sharkfinesse/sfl-lib';
import { sync as syncTracking, deleteTrack } from './tracking';
import rsfApp from 'redux/rsf';
import { getDeviceIdLocalStorage } from '../device';
import { errorModal } from 'utils';
import { history } from 'index';

export function* load(action) {
  try {
    //display loading message
    const [intl, isOnline, isOnlinePending] = yield all([
      select(intlSelectors.getMessages),
      select(deviceSelectors.isOnline),
      select(deviceSelectors.isOnlinePending),
    ]);
    let { sessionId, reCalculate } = action.payload;

    const deviceId = getDeviceIdLocalStorage();

    yield all([
      put(appActionCreators.updateLoadingActive(true)),
      put(appActionCreators.updateSessionFetching(true)),
      put(appActionCreators.updateLoadingText(intl['app.loader.extracting'])),
    ]);
    const product = yield select(productSelectors.getProductId);

    //wait for product to load if it hasnt already
    if (!product) {
      yield take(productTypes.LOADED);
    }
    //start the session

    const [account, userId] = yield all([
      select(userSelectors.getAccount),
      select(userSelectors.getId),
    ]);

    yield put(appActionCreators.updateLoadingText(intl['app.loader.synchronise']));

    const session = yield fork(sync, { account, sessionId });
    const tracking = yield fork(syncTracking, { sessionId });
    const dbUser = userId.replace(/\./g, ',');
    const dbRef = rsfApp.database.ref(`/devices/${dbUser}/${deviceId}`);
    if (isOnline && !isOnlinePending) {
      yield call([dbRef, dbRef.update], { sessionId });
    } else if (isOnlinePending) {
      dbRef.update({ sessionId });
    }

    yield all([put(sessionActionCreators.clearHistory()), take(sessionTypes.SYNCED)]);

    yield fork(fetchImage);

    yield all([
      put(appActionCreators.updateLoadingActive(false)),
      put(appActionCreators.updateSessionFetching(false)),
      put(trackingActionCreators.track()),
    ]);
    yield call(setSelectedSuite);
    if (reCalculate) {
      yield put(sessionActionCreators.reCalculate({ meta: null }));
    }
    yield take(sessionTypes.UNLOAD);

    yield all([...clearSessionStorage, cancel(session), cancel(tracking)]);
    yield all([
      put(layoutDesignerActionCreators.updateUnload()),
      put(appActionCreators.updateLoadingText('')),
      put(appActionCreators.updateLoadingActive(false)),
    ]);
    yield call(deleteTrack, { sessionId });
    if (isOnline) {
      yield call([dbRef, dbRef.update], { sessionId: null });
    }
  } catch (error) {
    console.log('Session load error', error);

    errorModal({ error: error.message });
    yield all([
      put(appActionCreators.updateSessionFetching(false)),
      put(appActionCreators.updateLoadingActive(false)),
      call([history, history.push], { pathname: '/' }),
    ]);
  }
}

export default load;
