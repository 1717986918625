import styled, { css } from 'styled-components';
import applyTheme from '../../styles/apply-theme';

const checkbox = applyTheme('checkbox');

export const Icon = checkbox('icon', styled.svg);
export const Label = checkbox('label');
export const StyledCheckbox = checkbox('base');
export const CheckboxContainer = styled.div`
  ${({ theme }) => theme.checkbox.checkboxContainer()}
  ${({ round, checked }) =>
    round &&
    !checked &&
    css`
      &:hover ${Icon} {
        visibility: visible;
      }
    `}
`;
export const HiddenCheckbox = checkbox('hiddenCheckbox', styled.input.attrs({ type: 'checkbox' }));
