import applyTheme from '../../styles/apply-theme';
import styled from 'styled-components';
import { SemiBold, FlexColumn } from '../general-styles';
import AutosizeInput from 'react-input-autosize';

export const FormSemiBoldLabel = styled(SemiBold)`
  margin-bottom: 2px;
`;
export const FormLabelColumn = styled(FlexColumn)`
  margin-top: 16px;
  margin-bottom: 4px;
`;

const theme = applyTheme('form');
export const FormGrid = theme('formGrid');
export const TextFieldRow = theme('row');
export const TextFieldColumn = theme('column');
export const TextFieldContainer = theme('textFieldContainer');
export const StyledAutosizeInput = theme(['base', 'autoSizeInput'], styled(AutosizeInput));
export const AbsoluteAfterContainer = theme('absoluteAfterContainer');
