"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

const getToken = state => state.auth0.token;

const getProfile = state => state.auth0.profile;

const getUserId = state => state.auth0.profile.sub;

const selectors = {
  getToken,
  getProfile,
  getUserId
};
var _default = selectors;
exports.default = _default;