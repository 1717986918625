// @flow
import React, { useState } from 'react';
import { IconAnimated } from '../icon/icons';
import { useTheme } from 'styled-components';
import { StyledAnimtaedMenuItem, AnimatedIconBefore, IconAfter } from './styled';

const AnimatedMenuItem = ({ icon, iconPosition = 'left', children, ...props }) => {
  const [playing, setPlaying] = useState('stopped');
  const theme = useTheme();

  const mouseLeave = () => {
    setPlaying('stopped');
  };
  const mouseEnter = () => {
    setPlaying('playing');
  };

  const animatedIcon = (
    <IconAnimated
      playingState={playing}
      speed={1}
      direction={1}
      primaryColor={theme.animatedIcons.primary}
      secondaryColor={theme.animatedIcons.secondary}
      size="width: 48px; height: 48px;"
      animationData={icon}
      css={`
        display: flex;
      `}
    />
  );

  return (
    <StyledAnimtaedMenuItem onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} {...props}>
      {iconPosition === 'left' && <AnimatedIconBefore>{animatedIcon}</AnimatedIconBefore>}
      {children}
      {iconPosition === 'right' && <IconAfter>{animatedIcon}</IconAfter>}
    </StyledAnimtaedMenuItem>
  );
};

export default AnimatedMenuItem;
