import { margin, borderStyle, borderColor, borderWidth, padding, backgrounds } from 'polished';
import { setAlphaHsla } from '@sharkfinesse/sfl-lib';

const xray = 0.5;
const A = 0.6;
const B = 0.1;
const C = 0.3;
const NavBarPills = ({ font, colors, scale }) => ({
  fontSize: font.sizeNormal,
  background: backgrounds(colors.white),
  borderStyle: borderStyle(null),
  borderWidth: borderWidth(null),
  borderColor: borderColor(colors.uiLight),
  borderRadius: '6px',
  padding: padding(0, scale.Three),
  fontColor: setAlphaHsla(A, colors.ui),
  fontColorHover: colors.ui,
  fontColorActive: colors.ui,
  navItemMargin: margin(0, 0, 0, '-1px'),
  navItemBackground: backgrounds(colors.white),
  navItemBorderStyle: borderStyle('solid'),
  navItemBorderWidth: borderWidth('1px'),
  navItemBorderColor: borderColor(colors.uiLight),
  navItemBackgroundHover: backgrounds(setAlphaHsla(B, colors.primary)),
  navItemBorderStyleHover: borderStyle('solid'),
  navItemBorderWidthHover: borderWidth('1px'),
  navItemBorderColorHover: borderColor(setAlphaHsla(xray, colors.primary)),
  navItemBackgroundActive: backgrounds(setAlphaHsla(C, colors.primary)),
  navItemBorderStyleActive: borderStyle('solid'),
  navItemBorderWidthActive: borderWidth('1px'),
  navItemBorderColorActive: borderColor(colors.primary),
  navItemFlex: '1 1 auto;',
  labelPadding: padding('4px', '14px', '5px', '14px'),
  labelTextAlign: 'center',
  inkBarColor: backgrounds(colors.primary),
  inkBarHeight: '0px',
  inkBarMargin: margin(0, '5px'),
  iconSize: '5rem',
});

export default NavBarPills;
