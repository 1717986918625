/* @flow */
import {
  createReducer,
  simpleAction,
  simpleReducer,
  questionnaireInitialState,
} from '@sharkfinesse/sfl-lib';

const namespace = (name: string): string => `@@questionnaire/${name}`;

//Actions
export const types = {
  ID: namespace('ID'),
  LOAD: namespace('LOAD'),
  LOADED: namespace('LOADED'),
  LAYOUT: {
    UPDATE: namespace('LAYOUT.UPDATE'),
  },
  NUMBER_FORMAT_OBJ: {
    UPDATE: namespace('NUMBER_FORMAT_OBJ.UPDATE'),
  },
  BENEFIT_DATA: {
    UPDATE: namespace('BENEFIT_DATA.UPDATE'),
    SUBMITTED: namespace('BENEFIT_DATA.SUBMITTED'),
    LOAD_SUBMITTED: namespace('BENEFIT_DATA.LOAD_SUBMITTED'),
  },
  BENEFIT: {
    UPDATE: namespace('BENEFIT.UPDATE'),
  },
  DEFAULT_SESSION_VALUES: {
    UPDATE: namespace('DEFAULT_SESSION_VALUES.UPDATE'),
    SUBMITTED: namespace('DEFAULT_SESSION_VALUES.SUBMITTED'),
    LOAD_SUBMITTED: namespace('DEFAULT_SESSION_VALUES.LOAD_SUBMITTED'),
  },
  DEFAULT_SESSION_VALUES_SCHEMA: {
    UPDATE: namespace('DEFAULT_SESSION_VALUES_SCHEMA.UPDATE'),
  },
  COMMON_VALUES: {
    UPDATE: namespace('COMMON_VALUES.UPDATE'),
  },
  CONFIG: {
    UPDATE: namespace('CONFIG.UPDATE'),
  },
  SUBMIT: {
    UPDATE: namespace('SUBMIT.UPDATE'),
  },
  TERMS_ACCEPTED: {
    UPDATE: namespace('TERMS_ACCEPTED.UPDATE'),
    SUBMIT: namespace('TERMS_ACCEPTED.SUBMIT'),
  },
};

//Reducer
export const initialState = questionnaireInitialState;

const reduceId = simpleReducer('id');
const reduceLayout = simpleReducer('layout');
const reduceLoaded = simpleReducer('loaded');
const reduceNumberFormatObj = simpleReducer('numberFormatObj');
const reduceBenefitData = simpleReducer('benefitData');
const reduceSubmittedBenefitData = simpleReducer('submittedBenefitData');
const reduceSubmittedDefaultSessionValues = simpleReducer('submittedDefaultSessionValues');
const reduceDefaultSessionValues = simpleReducer('defaultSessionValues');
const reduceDefaultSessionValuesSchema = simpleReducer('defaultSessionValuesSchema');
const reduceConfig = simpleReducer('config');
const reduceSubmit = simpleReducer('submitted');
const reduceTermsAccepted = simpleReducer('termsAccepted');

export default createReducer(
  {
    [types.ID]: reduceId,
    [types.LOADED]: reduceLoaded,
    [types.LAYOUT.UPDATE]: reduceLayout,
    [types.NUMBER_FORMAT_OBJ.UPDATE]: reduceNumberFormatObj,
    [types.BENEFIT_DATA.UPDATE]: reduceBenefitData,
    [types.BENEFIT_DATA.SUBMITTED]: reduceSubmittedBenefitData,
    [types.BENEFIT_DATA.LOAD_SUBMITTED]: reduceSubmittedBenefitData,
    [types.DEFAULT_SESSION_VALUES.UPDATE]: reduceDefaultSessionValues,
    [types.DEFAULT_SESSION_VALUES.SUBMITTED]: reduceSubmittedDefaultSessionValues,
    [types.DEFAULT_SESSION_VALUES.LOAD_SUBMITTED]: reduceSubmittedDefaultSessionValues,
    [types.DEFAULT_SESSION_VALUES_SCHEMA.UPDATE]: reduceDefaultSessionValuesSchema,
    [types.CONFIG.UPDATE]: reduceConfig,
    [types.SUBMIT.UPDATE]: reduceSubmit,
    [types.TERMS_ACCEPTED.UPDATE]: reduceTermsAccepted,
  },
  initialState
);

//Action Creators
const updateId = simpleAction(types.ID);
const load = simpleAction(types.LOAD);
const loaded = simpleAction(types.LOADED);
const updateLayout = simpleAction(types.LAYOUT.UPDATE);
const updateNumberFormatObj = simpleAction(types.NUMBER_FORMAT_OBJ.UPDATE);
const updateBenefitData = simpleAction(types.BENEFIT_DATA.UPDATE);
const submitBenefitData = simpleAction(types.BENEFIT_DATA.SUBMITTED);
const loadSubmitBenefitData = simpleAction(types.BENEFIT_DATA.LOAD_SUBMITTED);
const updateBenefit = simpleAction(types.BENEFIT.UPDATE);
const updateDefaultSessionValues = simpleAction(types.DEFAULT_SESSION_VALUES.UPDATE);
const submitDefaultSessionValues = simpleAction(types.DEFAULT_SESSION_VALUES.SUBMITTED);
const loadSubmittedDefaultSessionValues = simpleAction(types.DEFAULT_SESSION_VALUES.LOAD_SUBMITTED);
const updateDefaultSessionValuesSchema = simpleAction(types.DEFAULT_SESSION_VALUES_SCHEMA.UPDATE);
const updateCommonValues = simpleAction(types.COMMON_VALUES.UPDATE);
const updateConfig = simpleAction(types.CONFIG.UPDATE);
const updateSubmit = simpleAction(types.SUBMIT.UPDATE);
const updateTermsAccepted = simpleAction(types.TERMS_ACCEPTED.UPDATE);
const submitTermsAccepted = simpleAction(types.TERMS_ACCEPTED.SUBMIT);

export const actionCreators = {
  updateId,
  load,
  loaded,
  updateLayout,
  updateNumberFormatObj,
  updateBenefitData,
  submitBenefitData,
  loadSubmitBenefitData,
  updateBenefit,
  updateDefaultSessionValues,
  submitDefaultSessionValues,
  loadSubmittedDefaultSessionValues,
  updateDefaultSessionValuesSchema,
  updateCommonValues,
  updateConfig,
  updateSubmit,
  updateTermsAccepted,
  submitTermsAccepted,
};
