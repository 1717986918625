import { css } from 'styled-components';
import { setAlphaHsla } from '@sharkfinesse/sfl-lib';

const Timeline = ({ font, colors, scale, device: { max_sm } }) => ({
  base: () => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0;
    padding: 0;
  `,
  item: ({ $position, $hasOppositeContent }) => css`
    list-style: none;
    display: flex;
    position: relative;
    min-height: 45px;
    flex-direction: ${$position === 'left' ? 'row-reverse' : 'row'};
    text-align: left;
    .opposite {
      text-align: right;
    }
    ${$position === 'alternate' &&
    css`
      :nth-of-type(even) {
        flex-direction: row-reverse;
        text-align: right;
        .opposite {
          text-align: left;
        }
      }
    `}
    ${!$hasOppositeContent &&
    ($position === 'alternate' || $position === 'left') &&
    css`
      :before {
        content: '';
        flex: 1;
      }
    `}
  `,
  separator: () => css`
    display: flex;
    flex-direction: column;
    flex: 0;
    align-items: center;
  `,
  avatar: () => css`
    display: flex;
    width: 28px;
    height: 28px;
    border-radius: 50px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
      0px 1px 3px 0px rgb(0 0 0 / 12%);
    margin: 11px 0;
  `,
  timelineDot: ({ $color }) => css`
    padding: 5px;
    display: flex;
    border-radius: 50px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
      0px 1px 3px 0px rgb(0 0 0 / 12%);
    margin: 11px 0;
    ${$color === 'primary' &&
    css`
      background: ${colors.primary};
      color: ${colors.white};
    `}
    ${$color === 'grey' &&
    css`
      background: ${colors.uiLight};
    `}
  `,
  connector: ({ $inverse }) => css`
    width: 1px;
    background-color: ${$inverse ? colors.white : colors.ui};
    opacity: 0.5;
    flex-grow: 1;
  `,
  content: () => css`
    margin: 0;
    flex: 1;
  `,
  oppositeContent: ({ $position }) => css`
    margin: 0;
    flex: 1;
    margin-right: auto;
  `,
  bubble: () => css`
    border-radius: 3px;
    background: hsla(0, 0%, 100%, 0.1);
    padding: 8px 12px 12px 12px;
    margin: 10px 0px 0px 12px;
    color: ${font.white};
  `,
  date: () => css`
    margin-top: 4px;
    font-size: 11px;
    color: ${colors.white};
    text-align: right;
  `,
  title: () => css`
    color: ${setAlphaHsla(0.8, colors.white)};
    font-size: 15px;
    letter-spacing: 0.6px;
  `,
  boldTitle: () => css`
    color: ${colors.white};
    font-weight: ${font.weightSemiBold};
    font-size: 15px;
    letter-spacing: 0.6px;
  `,
  label: () => css`
    color: ${colors.white};
    font-size: 14px;
  `,
  labelContainer: () => css`
    padding: 10px;
  `,
  dateLabel: () => css`
    font-weight: ${font.weightSemiBold};
    font-size: 13px;
    padding: 13px 10px;
    @media ${max_sm} {
      line-height: 15px;
    }
  `,
  labelBubble: () => css`
    font-variant: small-caps;
    font-size: 16px;
    letter-spacing: 0.8px;
    font-weight: ${font.weightSemiBold};
    @media ${max_sm} {
      font-size: 13px;
      line-height: 15px;
    }
  `,
  detail: () => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0;
    padding: 0;
    margin-top: 8px;
    color: ${setAlphaHsla(0.75, colors.white)};
    font-size: 13px;
    li {
      display: flex;
      justify-content: space-between;
      padding: 3px 0;
      list-style: none;
      border-bottom: 1px solid ${setAlphaHsla(0.2, colors.white)};
      span {
        padding-left: 5px;
        word-break: break-all;
        display: flex;
        color: white;
        font-weight: ${font.weightSemiBold};
        text-align: right;
      }
    }
    li:last-child {
      border-bottom: none;
    }
  `,
});

export default Timeline;
