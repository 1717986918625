"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reselect = require("reselect");

var _index = require("../../index");

var _sessionDetails = _interopRequireDefault(require("./session-details"));

var _cashflows = _interopRequireDefault(require("./cashflows"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* eslint-disable no-unused-vars  */
// Selectors
const getResult = (0, _reselect.createSelector)([_cashflows.default.getNetCashflow], (0, _index.pipe)(_index.payback, _index.Maybe.fromMaybe(-1), (0, _index.ifElse)((0, _index.gt)(0), (0, _index.always)(null), _index.identity)));
const getResultDisplay = (0, _reselect.createSelector)([getResult, _sessionDetails.default.getNumberFormatObj], (value, numberFormatObj) => {
  const paybackNumberFormatObj = { ...numberFormatObj,
    unit: 'months',
    decimalPlaces: 2
  };
  return (0, _index.formatForDisplay)('time', paybackNumberFormatObj, value);
});
const getResultDisplayExcel = (0, _reselect.createSelector)([getResult, _sessionDetails.default.getNumberFormatObj], (value, numberFormatObj) => {
  const paybackNumberFormatObj = { ...numberFormatObj,
    unit: 'months',
    decimalPlaces: 2
  };
  return (0, _index.formatForExcel)('time', paybackNumberFormatObj, value);
});
const selectors = {
  getResult,
  getResultDisplay,
  getResultDisplayExcel
};
var _default = selectors;
exports.default = _default;