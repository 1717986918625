import { select, put, call, all, delay } from 'redux-saga/effects';
import { actionCreators as appActionCreators } from 'redux/modules/app';
import { history } from '../../../index';
import { intlSelectors } from '@sharkfinesse/sfl-lib';

export function* open(action) {
  //display loading message
  const intl = yield select(intlSelectors.getMessages);
  yield all([
    put(appActionCreators.updateLoadingActive(true)),
    put(appActionCreators.updateSessionFetching(true)),
    put(appActionCreators.updateLoadingText(intl['app.loader.extracting'])),
  ]);

  yield delay(300);
  //start the session
  yield call([history, history.push], {
    pathname: `/s/${action.payload.sessionId}`,
    state: { from: action.payload.location.pathname },
  });
}

export default open;
