/* eslint-disable prettier/prettier */
import { backgrounds } from 'polished';

const Body = ({ font, colors }) => ({
  background: backgrounds(colors.uiLightest),
  color: font.color,
  fontWeight: font.weightNormal,
});

export default Body;
