import { useQuery } from 'react-query';
import useQuestionnaire from './useQuestionnaire';
import { forEachObjIndexed, is } from '@sharkfinesse/sfl-lib';

const getItem = (obj, itemKey, item) => (obj[itemKey] ? [...obj[itemKey], item] : [item]);
// customOneTimeBenefit_2059.userTitle
const getItems = async data => {
  const submissions = data.reduce(
    (obj, { benefitData, defaultSessionValues, email, submitted }) => {
      if (submitted) {
        if (benefitData) {
          forEachObjIndexed((benefit, benefitKey) => {
            if (benefit?.values) {
              forEachObjIndexed((value, valueKey) => {
                if (is(Object, value)) {
                  obj[`${benefitKey}_${valueKey}`] = getItem(obj, `${benefitKey}_${valueKey}`, {
                    value: value.value,
                    email,
                    submitted,
                    id: valueKey,
                  });
                  obj[`${benefitKey}_${valueKey}_description`] = getItem(
                    obj,
                    `${benefitKey}_${valueKey}_description`,
                    { value: value.label, email, submitted, id: `${valueKey}_description` }
                  );
                } else {
                  const itemKey = `${benefitKey}_${valueKey}`;
                  const item = { value, email, submitted, id: valueKey };
                  obj[itemKey] = getItem(obj, itemKey, item);
                }
              }, benefit.values);
            }
            if (benefit?.notes) {
              const itemKey = `${benefitKey}_notes`;

              const item = { value: benefit.notes, email, submitted, id: 'notes' };
              obj[itemKey] = getItem(obj, itemKey, item);
            }
          }, benefitData);
        }
        if (defaultSessionValues) {
          if (defaultSessionValues?.data) {
            forEachObjIndexed((defaultValue, defaultValueKey) => {
              const itemKey = `defaultSessionValues_${defaultValueKey}`;
              const item = { value: defaultValue, email, submitted, id: defaultValueKey };
              obj[itemKey] = getItem(obj, itemKey, item);
            }, defaultSessionValues?.data);
          }
        }
      }
      return obj;
    },
    {}
  );

  return submissions;
};

const useQuestionnaireSubmissionData = ({ owner, account, sessionId }, config) => {
  const { data, isSuccess, isFetching } = useQuestionnaire({ owner, account, sessionId }, config);

  return useQuery(['questionnaireSubmissionData', sessionId], () => getItems(data), {
    enabled: isSuccess && !isFetching,
    ...config,
  });
};

export default useQuestionnaireSubmissionData;
