// @flow
import { useContext, Children } from 'react';
import { TimelineItem } from './styled';
import TimelineContext from './timeline-context';

const Component = ({ children, ...other }) => {
  const { position } = useContext(TimelineContext);

  let hasOppositeContent = false;

  Children.forEach(children, child => {
    if (child.type.displayName === 'timelineOppositeContent') hasOppositeContent = true;
  });

  return (
    <TimelineItem $hasOppositeContent={hasOppositeContent} $position={position} {...other}>
      {children}
    </TimelineItem>
  );
};

export default Component;
