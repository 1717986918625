// @flow
import React from 'react';
import { PickerListItem, HoverContent } from './styled';

const Item = ({ children, hover, ...other }) => (
  <PickerListItem {...other}>
    {children}
    {hover && (
      <div className="hoverContainer">
        <HoverContent className="hoverContent">{hover}</HoverContent>
      </div>
    )}
  </PickerListItem>
);

export default Item;
