"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

const getApp = state => state.app;

const getLoading = state => state.app.loading;

const getLoadingText = state => state.app.loadingText;

const getLoadingImmediate = state => state.app.loadingImmediate;

const getNonRecurringCalculating = state => state.app.nonRecurringCalculating;

const getRecurringCalculating = state => state.app.recurringCalculating;

const getSessionFetching = state => state.app.sessionFetching;

const getPreCache = state => state.app.preCache;

const getFirestoreTerminated = state => state.app.firestoreTerminated;

const getPolicyId = state => state.app.policyId;

const getTermsId = state => state.app.termsId;

const getPersistState = state => state.app.persistState;

const selectors = {
  getApp,
  getLoading,
  getLoadingText,
  getNonRecurringCalculating,
  getRecurringCalculating,
  getSessionFetching,
  getPreCache,
  getFirestoreTerminated,
  getPolicyId,
  getTermsId,
  getPersistState,
  getLoadingImmediate
};
var _default = selectors;
exports.default = _default;