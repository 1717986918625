import styled from 'styled-components';

export const StyledPanel = styled.div`
  ${p => p.theme.panel.base({ ...p })};
`;

export const PanelHeader = styled.header`
  ${p => p.theme.panel.header({ ...p })};
`;

export const PanelHeaderContainer = styled.div`
  ${p => p.theme.panel.panelHeaderContainer({ ...p })};
`;

export const HeaderContainer = styled.div`
  ${p => p.theme.panel.headerContainer()};
`;

export const ToolbarContainer = styled.div`
  ${p => p.theme.panel.toolbarContainer()};
`;
export const IconWrapper = styled.div`
  ${p => p.theme.panel.iconWrapper()};
`;
export const PanelBody = styled.div`
  ${p => p.theme.panel.panelBody({ ...p })};
`;
export const NotAnimatedBody = styled.div``;
