"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.percentageMask = exports.numberMask = exports.noMask = exports.moneyMask = exports.getThousandsSeparatorSymbol = exports.getDecimalSeparatorSymbol = void 0;

var _fp = require("./fp");

var _textMaskAddons = require("text-mask-addons");

const noMask = _fp.F;
/* eslint-disable */

exports.noMask = noMask;

const getDecimalSeparatorSymbol = numberFormat => {
  switch (numberFormat) {
    case 'commaDot':
      return '.';

    case 'dotComma':
      return ',';

    case 'spaceDot':
      return '.';

    case 'apostropheDot':
      return '.';

    default:
      return '.';
  }
};

exports.getDecimalSeparatorSymbol = getDecimalSeparatorSymbol;

const getThousandsSeparatorSymbol = numberFormat => {
  switch (numberFormat) {
    case 'commaDot':
      return ',';

    case 'dotComma':
      return '.';

    case 'spaceDot':
      return ' ';

    case 'apostropheDot':
      return "'";

    default:
      return ',';
  }
};
/* eslint-enable */


exports.getThousandsSeparatorSymbol = getThousandsSeparatorSymbol;

const numberMask = (formatObj, decimalPlaces, allowNegative) => {
  return formatObj ? (0, _textMaskAddons.createNumberMask)({
    thousandsSeparatorSymbol: getThousandsSeparatorSymbol(formatObj.numberFormat),
    decimalSymbol: getDecimalSeparatorSymbol(formatObj.numberFormat),
    prefix: '',
    suffix: '',
    allowDecimal: true,
    decimalLimit: (0, _fp.isNil)(decimalPlaces) ? formatObj.decimalPlaces : decimalPlaces,
    allowNegative: (0, _fp.isNil)(allowNegative) ? false : allowNegative,
    integerLimit: 13
  }) : _fp.F;
};

exports.numberMask = numberMask;

const percentageMask = (formatObj, decimalPlaces, allowNegative) => {
  return formatObj ? (0, _textMaskAddons.createNumberMask)({
    thousandsSeparatorSymbol: getThousandsSeparatorSymbol(formatObj.numberFormat),
    decimalSymbol: getDecimalSeparatorSymbol(formatObj.numberFormat),
    prefix: '',
    suffix: '%',
    allowDecimal: true,
    decimalLimit: (0, _fp.isNil)(decimalPlaces) ? formatObj.decimalPlaces : decimalPlaces,
    allowNegative: (0, _fp.isNil)(allowNegative) ? false : allowNegative,
    integerLimit: 13
  }) : _fp.F;
};

exports.percentageMask = percentageMask;

const moneyMask = (formatObj, decimalPlaces) => {
  return formatObj ? (0, _textMaskAddons.createNumberMask)({
    thousandsSeparatorSymbol: getThousandsSeparatorSymbol(formatObj.numberFormat),
    decimalSymbol: getDecimalSeparatorSymbol(formatObj.numberFormat),
    prefix: formatObj.currencySymbolPosition === 'prefix' ? formatObj.currencySymbol : '',
    suffix: formatObj.currencySymbolPosition === 'suffix' ? formatObj.currencySymbol : '',
    allowDecimal: true,
    decimalLimit: (0, _fp.isNil)(decimalPlaces) ? formatObj.decimalPlaces : decimalPlaces,
    integerLimit: 13
  }) : _fp.F;
};

exports.moneyMask = moneyMask;