import { all, fork, call, takeLatest, debounce } from 'redux-saga/effects';
import nonRecurringCosts from './non-recurring-costs-saga';
import recurringCostsSagas from './recurring-costs-saga';
import user from './user';
import load from './load';
import layout from './layout/layout';
import evaluator from './evaluator/evaluator';
import preferences from './preferences';
import settings from './settings';
import language from './language';
import session from './session';
import costs from './costs';
import benefits from './benefits';
import { saveActions } from './persistence';
import savingWatcher from './persistence/saving-watcher';
import login from './login';
import sessionDetails from './session-details';
import abilities from './abilities';
import businessPreparation from './session/business-preparation';
import presence from './presence';
import { types as deviceTypes } from 'redux/modules/device';
import { onSyncDevice, updateDevice, debounceStateUpdate } from './device';
import defaultSessionValues from './default-session-values';
import { types as loginTypes } from 'redux/modules/login';
import { types as loginQuestionnaireTypes } from 'redux/modules/login-questionnaire';
import costOfTender from './cost-of-tender';
import costOfDelay from './cost-of-delay';
import compliance, { runCompliance } from './compliance/compliance';
import { types as preferencesTypes } from 'redux/modules/preferences';
import { types as sessionTypes } from 'redux/modules/session';
import logRocket from './logrocket';
import goToCurrent from './benefit/goToCurrent';
import clearSessionStorage from './clearSessionStorage';
import { syncDevice } from './device';
import questionnaire from './questionnaire';
import tracking from './session/tracking';
import { doSync } from './session/sync';

function* bootstrap() {
  yield call(presence);
  yield fork(savingWatcher);
  yield all([
    call(runCompliance),
    ...load,
    ...clearSessionStorage,
    ...session,
    ...compliance,
    call(user),
    ...layout,
    ...evaluator,
    ...preferences,
    ...settings,
    ...costs,
    ...benefits,
    ...nonRecurringCosts,
    ...recurringCostsSagas,
    ...sessionDetails,
    ...abilities,
    ...businessPreparation,
    ...defaultSessionValues,
    ...costOfTender,
    ...costOfDelay,
    ...goToCurrent,
    ...tracking,
  ]);
  yield debounce(1500, sessionTypes.DO_SYNC, doSync);
  yield fork(syncDevice);
}

function* bootstrapQuestionnaire(action) {
  if (action.payload) {
    yield fork(savingWatcher);
    yield all([...load, ...session, ...layout, ...evaluator]);
  }
}

export default function* rootSaga() {
  yield fork(login);
  yield all([
    ...questionnaire,
    fork(debounceStateUpdate),
    ...language,
    takeLatest(loginQuestionnaireTypes.QUESTIONNAIRE.AUTHENTICATED, bootstrapQuestionnaire),
    takeLatest(loginTypes.LOGIN.SUCCESS, bootstrap),
    takeLatest(deviceTypes.SYNC, updateDevice),
    takeLatest(deviceTypes.SYNC, onSyncDevice),
    takeLatest(preferencesTypes.LOADED, logRocket),
    ...saveActions,
    // takeEvery(appTypes.TEST, runTest),
  ]);
}
