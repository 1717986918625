"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

const getRouter = state => state.router;

const getLocation = state => state.router.location;

const getAction = state => state.router.action;

const getPathname = state => state.router.location.pathname;

const getState = state => state.router.location.state;

const getSearch = state => state.router.location.search;

const selectors = {
  getRouter,
  getLocation,
  getAction,
  getPathname,
  getState,
  getSearch
};
var _default = selectors;
exports.default = _default;