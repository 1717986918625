import { css } from 'styled-components';
import { lightenHsla } from '@sharkfinesse/sfl-lib';

const ReactTable = ({ font, colors, scale }) => ({
  container: () => css`
    width: 100%;
    //overflow: auto;
    box-shadow: 0 0 0 1px ${lightenHsla(90, colors.uiBase)};
    padding-bottom: 1px;
  `,
  table: () => css`
    background: white;
    font-size: ${font.sizeNormal};
  `,
  head: () => css`
    left: 0;
    top: 0;
    z-index: 50;
    display: block;
  `,
  row: ({ $variant, $numberOfChildren, $selected, $useRowSelect }) => css`
    height: 38px;
    left: 0;
    top: 0;
    z-index: 50;
    display: block;
    background-color: ${colors.white};

    ${$useRowSelect &&
    css`
      cursor: pointer;
      ${!$selected &&
      css`
        &:hover {
          background-color: ${colors.uiLightest};
        }
      `}
      ${$selected &&
      css`
        background-color: ${colors.uiLightest};
        box-shadow: 0 0 0 2px ${colors.primary};
        border-radius: 2px;
        position: relative;
      `}
    `}
    ${$variant === 'head' &&
    css`
      user-select: none;
      background: ${lightenHsla(90, colors.uiBase)};
      ${$numberOfChildren > 1 &&
      css`
        &:first-child {
          background: ${lightenHsla(80, colors.uiBase)};
          font-weight: ${font.weightSemiBold};
        }
      `}
    `}
  `,
  cell: ({ $variant, $isSticky, $hover, $hovered, $selected }) => css`
    margin: 0;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px ${lightenHsla(90, colors.uiBase)};
    ${$isSticky &&
    css`
      position: sticky !important;
      left: 0px;
      z-index: 40;
      background-color: inherit;
    `}
    ${$hover &&
    css`
      cursor: pointer;
    `}
    ${$hovered &&
    !$selected &&
    css`
      background-color: ${lightenHsla(98, colors.uiBase)};
      border: 1px solid ${lightenHsla(98, colors.uiBase)};
    `}
    ${$selected &&
    css`
      background-color: ${lightenHsla(95, colors.uiBase)};
      border: 1px solid ${lightenHsla(95, colors.uiBase)}; ;
    `}
    ${$variant === 'head' &&
    css`
      border-right: solid 1px ${lightenHsla(70, colors.uiBase)};
      &:first-child {
        justify-content: center;
      }
      &:last-child {
        border-right: none;
      }
    `};
  `,
  body: () => css`
    display: block;
  `,
  resizer: ({ $resizing, $last }) => css`
    display: inline-block;
    background: none;
    width: 6px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    touch-action: none;
    &:hover {
      background: ${lightenHsla(20, colors.primary)};
    }
    ${$resizing &&
    css`
      background: ${lightenHsla(20, colors.primary)};
    `}
    ${$last &&
    css`
      right: 3px;
    `}
  `,
  sortHeader: () => css`
    display: flex;
    justify-content: center;
    width: 100%;
  `,
  sortChevron: () => css`
    position: absolute;
    right: 1px;
  `,
  leftAlignCell: () => css`
    justify-content: left;
  `,
});

export default ReactTable;
