// @flow
import styled from 'styled-components';
import { animated } from 'react-spring';
import applyTheme from '../../styles/apply-theme';

const expandingListItem = applyTheme('expandingListItem');
export const ExpandingList = expandingListItem('ul', styled.ul);
export const ExpandingListColumns = expandingListItem('ulColumns', styled.ul);
export const ExpandButton = expandingListItem('expandButton', styled(animated.div));
export const Container = expandingListItem('container', styled.li);
export const Wrapper = expandingListItem('wrapper');
export const Label = expandingListItem('label');
export const ChevronIcon = expandingListItem('chevronIcon');
export const Body = expandingListItem('body', styled(animated.div));
export const LeftContent = expandingListItem('leftContent');
export const RightContent = expandingListItem('rightContent');
