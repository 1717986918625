import React, { useRef } from 'react';

import { Player, Video, DefaultUi, Vimeo } from '@vime/react';

// Default theme.
import '@vime/core/themes/default.css';

const VimePlayer = ({ src, title, videoId, ...other }) => {
  // Obtain a ref if you need to call any methods.
  const player = useRef(null);

  // const onPlaybackReady = () => {
  //   // ...
  // };

  // // If you prefer hooks :)
  // const [currentTime] = usePlayerContext(player, 'currentTime', 0);

  // useEffect(() => {
  //   console.log(currentTime);
  // }, [currentTime]);

  return (
    <div {...other}>
      <Player ref={player} mediaTitle={title}>
        {src && (
          <Video>
            <source data-src={src} type="video/mp4" />
          </Video>
        )}
        {videoId && <Vimeo noAutoAspectRatio videoId={videoId} />}

        <DefaultUi></DefaultUi>
      </Player>
    </div>
  );
};

export default VimePlayer;
