/* @flow */
import React from 'react';
import PropTypes from 'prop-types';
import type { Element } from 'react';
import styled from 'styled-components';

const StyledTableBody = styled.div`
  ${({ theme, themeKey }) => theme[`${themeKey}`].bodyPadding};
`;

type Props = {
  themeKey?: string,
  children?: Node,
  ...other,
};

const TableBody = ({ themeKey: themeKeyProp, children }: Props, context): Element<any> => {
  const { sfluiTable } = context;

  let themeKey = themeKeyProp;

  if (sfluiTable) {
    if (typeof themeKey === 'undefined') {
      themeKey = sfluiTable.themeKey;
    }
  }
  return <StyledTableBody themeKey={themeKey}>{children}</StyledTableBody>;
};

TableBody.contextTypes = {
  sfluiTable: PropTypes.object,
};

export default TableBody;
