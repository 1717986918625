import rsfApp from 'redux/rsf';
import firebase from 'firebase/app';
import { omit, hashId, importQuestionnaire } from '@sharkfinesse/sfl-lib';
import { copyCollection } from './collection';
import fetchSession from './hooks/fetch-session';
import { createOrUpdateFolder } from './folders';
import { updateQuestionnaire } from './questionnaire';

export const approveSession = async ({ id, account }) => {
  await rsfApp.firestore.collection('accounts').doc(account).collection('sessions').doc(id).update({
    pending: firebase.firestore.FieldValue.delete(),
  });
  return;
};

export const deleteSession = async ({ id, account }) => {
  await rsfApp.firestore
    .collection('accounts')
    .doc(account)
    .collection('sessions')
    .doc(id)
    .delete();
  return;
};

export const getSession = async ({ id, account, source = 'default' }) =>
  rsfApp.firestore.collection(`accounts/${account}/sessions/${id}/file`).get({ source });

export const getFileDoc = async ({ id, account, doc, source = 'default' }) =>
  rsfApp.firestore.doc(`accounts/${account}/sessions/${id}/file/${doc}`).get({ source });

export const getSessionFile = ({ id, account }) =>
  rsfApp.firestore.doc(`accounts/${account}/sessions/${id}`).get();

export const createSession = async ({ owner, account, defaults }) => {
  const sessionRef = rsfApp.firestore
    .collection('accounts')
    .doc(account)
    .collection('sessions')
    .doc();

  sessionRef.set({
    ...defaults,
    id: sessionRef.id,
    created: new Date(),
    updated: new Date(),
  });

  return sessionRef.id;
};

export const updateSessionDetails = async ({ id, account, details }) => {
  await rsfApp.firestore.doc(`accounts/${account}/sessions/${id}/file/details`).update(details);

  return;
};

export const updateFileDoc = async ({ id, account, doc, data }) => {
  await rsfApp.firestore.doc(`accounts/${account}/sessions/${id}/file/${doc}`).update(data);

  return 'success';
};
export const updateSession = async ({ id, account, data }) => {
  await rsfApp.firestore.doc(`accounts/${account}/sessions/${id}`).update(data);

  return;
};

export const duplicateSession = async ({
  id,
  account,
  template,
  owner,
  immediate,
  appendToDealName,
  name,
  email,
}) => {
  const snapshot = await getSessionFile({ id, account });
  const session = snapshot.data();

  if (appendToDealName) {
    session.dealName = `${session.dealName ? session.dealName : ''} ${appendToDealName}`;
  } else if (session.dealName && !template) {
    session.dealName = `${session.dealName} copy`;
  }

  if (owner) session.owner = owner;

  const newSessionId = await createSession({
    owner,
    account,
    defaults: {
      ...omit(
        [
          'sharedTo',
          'sharedBy',
          'editors',
          'templateFor',
          'templateCategory',
          'ownerName',
          'ownerEmail',
          'updatedBy',
          'updatedByName',
          'updatedByEmail',
          'updatedAction',
        ],
        session
      ),
    },
  });

  await copyCollection({
    src: `accounts/${account}/sessions/${id}/file`,
    dest: `accounts/${account}/sessions/${newSessionId}/file`,
    immediate,
    omitted: ['plugins', 'questionnaire'],
  });
  if (session.dealName) {
    updateSessionDetails({ id: newSessionId, account, details: { dealName: session.dealName } });
  }

  await updateSession({
    id: newSessionId,
    account,
    data: { updatedBy: owner, updatedByName: name, updatedByEmail: email, updateAction: 'create' },
  });

  return newSessionId;
};

const updateDefaultValues = async ({ updatedDefaultValues, newSessionId, account }) => {
  if (updatedDefaultValues) {
    await updateFileDoc({
      id: newSessionId,
      account,
      doc: 'defaultSessionValues',
      data: updatedDefaultValues,
    });
  }
  return;
};

const updateBenefitData = async ({ updatedBenefitData, newSessionId, account }) => {
  if (updatedBenefitData) {
    await updateFileDoc({
      id: newSessionId,
      account,
      doc: 'benefits',
      data: updatedBenefitData,
    });
  }
  return;
};

export const createQuestionnaireSession = async ({
  account,
  sessionId,
  owner,
  ownerName,
  ownerEmail,
  intl,
  data,
  overwrite,
}) => {
  const parentFolderId = `bc${hashId(owner)}`;
  const sessionFolderId = `bc${hashId(sessionId)}`;
  const { id, benefitData, defaultSessionValues, email } = data;

  const newSessionId = await duplicateSession({
    id: sessionId,
    account,
    appendToDealName: ` - ${email}`,
    owner,
    name: ownerName,
    email: ownerEmail,
  });

  const session = await fetchSession({ id: newSessionId, account });

  const { updatedDefaultValues, updatedBenefitData } = await importQuestionnaire({
    overwrite,
    benefitData,
    defaultSessionValues,
    session,
  });

  const createdDate = new Date();

  await Promise.all([
    updateDefaultValues({ newSessionId, account, updatedDefaultValues }),
    updateBenefitData({ newSessionId, account, updatedBenefitData }),
    createOrUpdateFolder({
      id: parentFolderId,
      account,
      name: intl.formatMessage({
        id: 'questionnaire.folderName',
        defaultMessage: 'Questionnaires',
      }),
      owner,
      starred: true,
      media: {
        position: { percent: { x: 0, y: 0 }, pixel: { x: 0, y: 0 } },
        src: 'https://firebasestorage.googleapis.com/v0/b/sfl-app-e6cdc.appspot.com/o/images%2Ffolder-questionnaire.svg?alt=media&token=24cc4e13-5f6a-4beb-9336-1f6f42874759',
        type: 'image/svg+xml',
        url: 'https://firebasestorage.googleapis.com/v0/b/sfl-app-e6cdc.appspot.com/o/images%2Ffolder-questionnaire.svg?alt=media&token=24cc4e13-5f6a-4beb-9336-1f6f42874759',
      },
    }),
    createOrUpdateFolder({
      id: sessionFolderId,
      account,
      parent: parentFolderId,
      name: session.details.dealName,
      owner,
      media: session.details.media,
    }),
    updateSession({ id: newSessionId, account, data: { folder: sessionFolderId } }),
    updateQuestionnaire({
      id,
      account,
      data: {
        sessionCreated: createdDate,
        sessionCreatedHistory: firebase.firestore.FieldValue.arrayUnion({
          sessionId: newSessionId,
          created: createdDate,
        }),
      },
    }),
  ]);

  return newSessionId;
};

export const deleteEditor = async ({ sessionId, account, id }) => {
  await rsfApp.firestore
    .collection('accounts')
    .doc(account)
    .collection('sessions')
    .doc(sessionId)
    .update({
      editors: firebase.firestore.FieldValue.arrayRemove(id),
    });

  return id;
};
