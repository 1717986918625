import styled from 'styled-components';
import { animated } from 'react-spring';

export const Blurred = styled(animated.div)`
  ${({ theme }) => theme.removeFromOutput.blurred()};
`;
export const Removed = styled(animated.div)`
  ${({ theme }) => theme.removeFromOutput.removed()};
`;
export const RemovedWrapper = styled(animated.div)`
  ${({ theme }) => theme.removeFromOutput.removedWrapper()};
`;
export const CenterWrapper = styled(animated.div)`
  ${({ theme }) => theme.removeFromOutput.centerWrapper()};
`;
export const UndoBox = styled(animated.div)`
  ${({ theme }) => theme.removeFromOutput.undoBox()};
`;
