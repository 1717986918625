import applyTheme from '../../styles/apply-theme';

const cashFlow = applyTheme('cashFlow');
export const Toolbar = cashFlow('toolbar');
export const ToolbarButtonGroup = cashFlow('toolbarButtonGroup');
export const Dragger = cashFlow('dragger');
export const DraggerWrapper = cashFlow('draggerWrapper');
export const Grid = cashFlow('grid');
export const Cell = cashFlow('cell');
export const FirstColumnHeader = cashFlow(['rowHeader', 'header']);
export const ColumnHeader = cashFlow(['columnHeader', 'header']);
export const RowHeader = cashFlow('rowHeader');
export const Ellipsis = cashFlow('ellipsis');
export const Container = cashFlow('container');
export const Wrapper = cashFlow('wrapper');
