import { css } from 'styled-components';
import { setAlphaHsla } from '@sharkfinesse/sfl-lib';
const UploadFile = ({ font, colors, scale }) => ({
  progressWrapper: ({ selected, width, height }) => css`
    display: flex;
    align-items: center;
    padding: 0 0 4px 0;
    margin: 0 0 4px 0;
    border-bottom: 1px solid;
  `,
  error: () => css`
    color: ${colors.invalid};
    background: ${setAlphaHsla(0.6, colors.white)};
    padding: 4px 8px;
    border-radius: 3px;
    width: 100%;
  `,
});

export default UploadFile;
