// @flow
import React, { useState } from 'react';
import { withTheme } from 'styled-components';
import { IconAnimated } from '../icon';
import { HeaderContainer, Heading, IconWrapper } from './styled';

export type Props = {
  headingTheme?: Object,
  beforeHeading?: Element<*> | string,
  heading?: string,
  toolBar?: Element<*> | string,
  children?: Element<*> | string,
  headerIcon?: Element<*> | string,
  theme: Object,
  headerIconProps: Object,
  ...other,
};

const Header = ({
  beforeHeading,
  heading,
  children,
  toolBar,
  headerIcon,
  headerIconProps,
  theme,
}: Props): React.Element<any> => {
  const [playing, setPlaying] = useState('stopped');
  const handleLottieMouseLeave = () => {
    setPlaying('stopped');
  };
  const handleLottieMouseEnter = () => {
    setPlaying('playing');
  };
  return (
    <HeaderContainer onMouseEnter={handleLottieMouseEnter} onMouseLeave={handleLottieMouseLeave}>
      {beforeHeading}
      {headerIcon && (
        <IconWrapper>
          <IconAnimated
            playingState={playing}
            speed={1}
            direction={1}
            primaryColor={theme.header.animatedIcons.primary}
            secondaryColor={theme.header.animatedIcons.secondary}
            size="width: 50px; height: 50px;"
            animationData={headerIcon}
            {...headerIconProps}
          />
        </IconWrapper>
      )}
      {heading && (
        <Heading className="_lr-hide" headerIcon={headerIcon}>
          {heading}
        </Heading>
      )}
      {children}
      {toolBar}
    </HeaderContainer>
  );
};

export default withTheme(Header);
