import enLocale from '../locales/en.json';

const initState = {
  intl: {
    locale: 'en',
    messages: enLocale,
  },
};

export default initState;
