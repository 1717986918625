import { css, keyframes } from 'styled-components';

const skeletonKeyframes = keyframes`
 0% {
    //background-position: -100% 0;
    opacity: 0.5;
  }
  50% {
    
    opacity: 1;
  }
  100% {
    //background-position: 200% 0;
    opacity: 0.5;
  }
`;

const getBgColor = ({ color, colors }) => {
  //const color = css`background: linear-gradient(0deg, rgba(30, 135, 240, 1) 0%, rgba(64, 188, 249, 1) 100%)`
  if (color === 'uiLightest') return colors.uiLightest;
  if (color === 'white') return colors.white;
  if (color === 'primary') return colors.primary;
};

const Skeleton = ({ font, colors, scale, bgColor = getBgColor }) => ({
  container: ({ color = 'uiLightest', borderRadius = '2px', id }) => css`
    width: 100%;
    height: 100%;
    border-radius: ${borderRadius};
    background: ${bgColor({ color, colors })};
    display: inline-block;
    line-height: 1;
    animation: ${skeletonKeyframes} 1s ease-in-out infinite;
    animation-delay: -${id * 0.3}s;
  `,
});

export default Skeleton;
