"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.zeroFillProp = exports.paths = exports.mapValues = exports.mapKeys = exports.indexData = exports.incValue = exports.hasNot = exports.countKeys = exports.callAndSetWithKey = exports.callAndSet = exports.callAndMergeKeys = exports.callAndMerge = void 0;

var _fp = require("./fp");

// prettier-ignore
const paths = (0, _fp.useWith)(_fp.ap, [(0, _fp.map)(_fp.path), _fp.of]); // prettier-ignore

exports.paths = paths;
const mapKeys = (0, _fp.useWith)((0, _fp.pipe)(_fp.map, _fp.fromPairs), [(0, _fp.adjust)(0, _fp.placeholder), _fp.toPairs]); // prettier-ignore

exports.mapKeys = mapKeys;
const callAndSet = (0, _fp.curry)((k, f, o) => (0, _fp.assoc)(k, f(o), o)); // prettier-ignore

exports.callAndSet = callAndSet;
const callAndSetWithKey = (0, _fp.curry)((k, f, o) => (0, _fp.assoc)(k, f(k, o), o)); // prettier-ignore

exports.callAndSetWithKey = callAndSetWithKey;
const callAndMerge = (0, _fp.curry)((f, o) => (0, _fp.merge)(o, f(o))); // prettier-ignore

exports.callAndMerge = callAndMerge;
const callAndMergeKeys = (0, _fp.curry)((keys, f, o) => (0, _fp.merge)(o, (0, _fp.pick)(keys, f(o)))); // prettier-ignore

exports.callAndMergeKeys = callAndMergeKeys;
const indexData = (0, _fp.cardinalstar)((0, _fp.converge)((0, _fp.assoc)('index'), [_fp.add, (0, _fp.nthArg)(2)])); // prettier-ignore

exports.indexData = indexData;
const mapValues = (0, _fp.map)((0, _fp.pipe)(_fp.values, _fp.head)); // prettier-ignore

exports.mapValues = mapValues;
const incValue = callAndSetWithKey(_fp.placeholder, (0, _fp.pipe)(_fp.prop, _fp.inc)); // prettier-ignore

exports.incValue = incValue;
const hasNot = (0, _fp.complement)(_fp.has); // prettier-ignore

exports.hasNot = hasNot;
const zeroFillProp = (0, _fp.curry)((prop, scope) => (0, _fp.assoc)(prop, 0, scope)); // prettier-ignore

exports.zeroFillProp = zeroFillProp;
const countKeys = (0, _fp.pipe)(_fp.keys, _fp.length);
exports.countKeys = countKeys;