// @flow
import React, { Fragment } from 'react';
import Button from '../button';
import { IconTrash, IconPencil } from '../icon';
import { isNil, isEmpty } from '@sharkfinesse/sfl-lib';
import {
  TableRow,
  TitleRow,
  RolloverDivider,
  RowLabel,
  RowValue,
  RowSubLabel,
  RowWrapper,
} from './styled';

export type Props = {
  heading: Element<*> | String,
  label: Element<*> | String,
  subLabel: Element<*> | String,
  value: Element<*> | String,
  onEditClick: Function,
  onDeleteClick: Function,
};
const Row = ({ heading, label, subLabel, value, onEditClick, onDeleteClick }: Props) => (
  <Fragment>
    {!isNil(heading) && <TitleRow>{isEmpty(heading) ? '-' : heading}</TitleRow>}
    <TableRow>
      <RowWrapper>
        <RowLabel>{label}</RowLabel>
        <RowValue>{value}</RowValue>
      </RowWrapper>
      {subLabel && <RowSubLabel>{subLabel}</RowSubLabel>}
      {(onEditClick || onDeleteClick) && (
        <div className="hoverContainer">
          <div className="buttons">
            {onEditClick && (
              <Button size="icon" variant="flat" color="dark" onClick={onEditClick}>
                <IconPencil size="small" />
              </Button>
            )}
            <RolloverDivider />
            {onDeleteClick && (
              <Button size="icon" variant="flat" color="dark" onClick={onDeleteClick}>
                <IconTrash size="small" />
              </Button>
            )}
          </div>
        </div>
      )}
    </TableRow>
  </Fragment>
);

export default Row;
