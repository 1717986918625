// @flow
import { connect } from 'react-redux';
import { preferencesSelectors } from '@sharkfinesse/sfl-lib';
import { ThemeProvider } from 'components/theme';

const mapStateToProps = state => ({
  id: preferencesSelectors.getTheme(state).value,
});

const ConnectedThemeProvider = connect(mapStateToProps)(ThemeProvider);

export default ConnectedThemeProvider;
