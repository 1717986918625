/* @flow */
import { simpleAction, createReducer, compareInitialState } from '@sharkfinesse/sfl-lib';

const namespace = (name: string): string => `@@compare/${name}`;

export const types = {
  DATA: namespace('DATA'),
  UNLOAD: namespace('UNLOAD'),
};

export const initialState = compareInitialState;

const reduceData = (state, action) => {
  return {
    ...state,
    data: action.payload,
  };
};
const reduceUnload = (state, action) => initialState;

export default createReducer(
  {
    [types.DATA]: reduceData,
    [types.UNLOAD]: reduceUnload,
  },
  initialState
);

const data = simpleAction(types.DATA);
const unload = simpleAction(types.UNLOAD);

export const actionCreators = {
  data,
  unload,
};
