import { useQuery } from 'react-query';
import useQuestionnaire from './useQuestionnaire';
import { questionnaireStatus } from '@sharkfinesse/sfl-lib';

const useQuestionnaireWithStatus = (props, config) => {
  const { data, isSuccess, isFetching } = useQuestionnaire(props, config);

  return useQuery(['questionnairesWithStatus', props.sessionId], () => questionnaireStatus(data), {
    enabled: isSuccess && !isFetching,
    ...config,
  });
};

export default useQuestionnaireWithStatus;
