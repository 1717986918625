import { call } from 'redux-saga/effects';

export const effects = [
  call([sessionStorage, sessionStorage.removeItem], 'scroll_sl'),
  call([sessionStorage, sessionStorage.removeItem], 'scroll_ov'),
  call([sessionStorage, sessionStorage.removeItem], 'scroll_ct'),
  call([sessionStorage, sessionStorage.removeItem], 'scroll_bldsl'),
  call([sessionStorage, sessionStorage.removeItem], 'scroll_bldbl'),
];
export default effects;
