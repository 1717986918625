/* @flow */
/* eslint-disable no-confusing-arrow, react/display-name */
import React, { useRef, useState, useCallback, useMemo, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Scrollbar from 'react-scrollbars-custom';
import { default as domCss } from 'dom-css';
import { prop } from 'styled-tools';
import { useMedia } from 'react-media';

const Container = styled.div`
  position: relative;
  height: 100%;
  flex: 1;
  overflow: hidden;
  ${() =>
    css`
      ${prop('containerStyles')};
    `};
`;
const ScrollContent = styled.div`
  display: flex;
  flex-direction: column;
  // min-height: 100%;
`;

const Shadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 6px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0;
  z-index: 2;
`;

const NativeScroll = styled.div`
  margin: 0 auto;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  position: relative;
  ${({ noScrollX }) =>
    noScrollX &&
    css`
      overflow-x: hidden;
    `};
  ${({ noScrollY }) =>
    noScrollY &&
    css`
      overflow-y: hidden;
    `};
`;

const ScrollBars = styled(Scrollbar)`
  ${p => p.theme.scroll.base(p.styleHeight)};
  ${p => p.theme.scroll.track(p.transparenttrackbar)};
  ${p => p.theme.scroll.trackX()};
  ${p => p.theme.scroll.trackY()};
  ${p => p.theme.scroll.thumb()};
`;

type DefaultProps = {
  shadow: boolean,
  noScrollX: boolean,
  noScrollY: boolean,
  transparenttrackbar: boolean,
};
export type Props = DefaultProps & {
  id: String | Number,
  theme: Any,
  children: React.Element<any> | String,
  shadow?: Boolean,
  containerStyles?: Any,
  scrollHeight: React.Element<any> | String,
  scrollStopHandler: Function,
  scrollLeft: Boolean,
  elementRef: Any,
  style: Object,
  contentStyle: Object,
  restoreScroll: String,
  scrollerRef: Any,
  small: Boolean,
};

const Scroll = ({
  shadow = true,
  noScrollX = false,
  noScrollY = false,
  transparenttrackbar = 'true',
  id,
  theme,
  children,
  containerStyles,
  scrollHeight,
  scrollStopHandler,
  scrollLeft,
  contentStyle,
  restoreScroll,
  scrollerRef,
  show,
  ...other
}: Props) => {
  const topShadowRef = useRef(null);
  const internalRef = useRef();
  const scrollRef = scrollerRef ? scrollerRef : internalRef;
  const [isScrolling, setIsScrolling] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [internalScrollLeft, setInternalScrollLeft] = useState(scrollLeft);
  const isShow = show ? true : isScrolling || isMouseOver;
  //const { uaResults } = useContext(UAContext);
  const matches = useMedia({
    queries: {
      small: '(min-width: 0) and (max-width: 991px)',
    },
  });
  const small = matches.small;

  useEffect(() => {
    if (scrollRef.current && restoreScroll) {
      const scrollPos = sessionStorage.getItem(restoreScroll);
      scrollRef.current.scrollTo(0, parseInt(scrollPos, 10));
    }
  }, [scrollRef, restoreScroll, id]);

  const onScrollStart = useCallback(() => {
    setIsScrolling(true);
  }, []);
  const onScrollStop = useCallback(
    values => {
      if (scrollStopHandler) {
        setInternalScrollLeft(values.scrollLeft);
      }
      setIsScrolling(false);
    },
    [scrollStopHandler]
  );
  const onMouseEnter = useCallback(() => {
    setIsMouseOver(true);
  }, []);
  const onMouseLeave = useCallback(() => {
    setIsMouseOver(false);
  }, []);

  const trackXProps = useMemo(
    () => ({
      renderer: props => {
        const { elementRef, style, ...restProps } = props;
        return (
          <span
            {...restProps}
            ref={elementRef}
            style={{
              ...style,
              height: small ? '3px' : '8px',
              opacity: isShow ? 1 : 0,
              transition: 'opacity 0.3s ease-in-out',
            }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        );
      },
    }),
    [isShow, onMouseEnter, onMouseLeave, small]
  );
  const trackYProps = useMemo(
    () => ({
      renderer: ({ elementRef, style, ...restProps }) => (
        <span
          {...restProps}
          ref={elementRef}
          style={{
            ...style,
            width: small ? '3px' : '8px',
            opacity: isShow ? 1 : 0,
            transition: 'opacity 0.3s ease-in-out',
          }}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      ),
    }),
    [isShow, onMouseEnter, onMouseLeave, small]
  );

  const shadowAmount = 20;

  const onScroll = values => {
    if (restoreScroll) sessionStorage.setItem(restoreScroll, values.scrollTop);

    return (
      shadow &&
      topShadowRef &&
      domCss(topShadowRef.current, {
        opacity: (1 / shadowAmount) * Math.min(values.scrollTop, shadowAmount),
      })
    );
  };

  const onNativeScroll = () => {
    const values = scrollRef.current;

    if (restoreScroll) sessionStorage.setItem(restoreScroll, values.scrollTop);
    return onScroll(values);
  };

  const native = false;

  return (
    <Container containerStyles={containerStyles} {...other}>
      {native ? (
        <NativeScroll
          onScroll={onNativeScroll}
          id={id}
          noScrollX={noScrollX}
          noScrollY={noScrollY}
          ref={scrollRef}
        >
          {children}
        </NativeScroll>
      ) : (
        <ScrollBars
          ref={scrollRef}
          onScroll={onScroll}
          scrollerProps={{
            renderer: props => {
              const { elementRef, ...restProps } = props;
              return <span id={id} {...restProps} ref={elementRef} />;
            },
          }}
          wrapperProps={{
            renderer: props => {
              const { elementRef, style, ...restProps } = props;
              return <span {...restProps} ref={elementRef} style={{ ...style, right: 0 }} />;
            },
          }}
          trackYProps={trackYProps}
          trackXProps={trackXProps}
          onScrollStart={onScrollStart}
          onScrollStop={onScrollStop}
          contentProps={{
            renderer: props => {
              const { elementRef, style, ...restProps } = props;
              return (
                <ScrollContent
                  style={{ ...contentStyle }}
                  {...restProps}
                  ref={elementRef}
                  className="ScrollbarsCustom-Content"
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                />
              );
            },
          }}
          style={{ height: scrollHeight, display: 'flex' }}
          noScrollX={noScrollX}
          noScrollY={noScrollY}
          scrollLeft={internalScrollLeft}
          scrollDetectionThreshold={500}
          transparenttrackbar={transparenttrackbar.toString()}
        >
          {children}
        </ScrollBars>
      )}
      {shadow && <Shadow id="shadow" ref={topShadowRef} />}
    </Container>
  );
};
export default Scroll;
