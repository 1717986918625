"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const initialState = {
  data: {},
  ids: [],
  current: '',
  startMonthLinkedFlag: false,
  endMonthLinkedFlag: false,
  showModifiers: false
};
var _default = initialState;
exports.default = _default;