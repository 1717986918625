import { types } from 'redux/modules/recurring-costs';
import { types as cashflowTypes } from 'redux/modules/cashflow';
import { put, takeLatest, all, call, select, takeEvery } from 'redux-saga/effects';
import { actionCreators as recurringCostsActionCreators } from 'redux/modules/recurring-costs';

import {
  recurringCosts,
  sum,
  assocPath,
  pipe,
  negate,
  divide,
  placeholder,
  filter,
  head,
  path,
  ifElse,
  equals,
  always,
  roundWithFloatingFixedPoint,
  isNil,
  recurringCostsSelectors,
  sessionDetailsSelectors,
  cashflowsSelectors,
  generateShortId,
  last,
} from '@sharkfinesse/sfl-lib';
import { actionCreators as cashflowActionCreators } from 'redux/modules/cashflow';
import undoToast from 'redux/sagas/toast/undo';

function* addDynamicRow(action) {
  const meta = path(['meta'], action);
  const [costs, ids, reviewPeriod] = yield all([
    select(recurringCostsSelectors.getCosts),
    select(recurringCostsSelectors.getIds),
    select(sessionDetailsSelectors.getReviewPeriod),
  ]);
  if (!costs[last(ids)].pristine) {
    yield put(
      recurringCostsActionCreators.updateAddDynamicRow({
        id: generateShortId(),
        reviewPeriod,
        meta,
      })
    );
  }
}

export function* updateResult(action) {
  const { unedittedCashflow, id } = action.payload;

  const meta = path(['meta'], action);
  const reviewPeriod = yield select(sessionDetailsSelectors.getReviewPeriod);
  const currentCosts = !isNil(path(['currentCosts'], action.payload))
    ? action.payload.currentCosts
    : yield select(recurringCostsSelectors.getRecurringCosts);

  let result = yield call(recurringCosts, reviewPeriod, currentCosts);

  if (unedittedCashflow) {
    result = assocPath(
      ['costs', id, 'cost'],
      pipe(
        filter(y => y !== 0),
        head
      )(unedittedCashflow),
      result
    );

    result = assocPath(['costs', id, 'cashflow'], unedittedCashflow, result);
  }

  yield put(cashflowActionCreators.updateRecurringCostsResult({ ...result, meta }));

  const totalRecurringCostsCashflow = yield select(
    cashflowsSelectors.getTotalRecurringCostsCashflow
  );

  const updatedResult = assocPath(
    ['result'],
    pipe(sum, negate, divide(placeholder, reviewPeriod))(totalRecurringCostsCashflow),
    result
  );

  yield put(
    recurringCostsActionCreators.updateResult({ ...updatedResult, id: action.payload.id, meta })
  );
}

export function* updateResultAddRow(action) {
  yield call(updateResult, action);
  yield call(addDynamicRow, action);
}

export function* costChangeCashflowEdit(action) {
  const meta = path(['meta'], action);
  let [reviewPeriod, currentCosts] = yield all([
    select(sessionDetailsSelectors.getReviewPeriod),
    select(recurringCostsSelectors.getRecurringCosts),
  ]);

  currentCosts = assocPath(
    ['costs', action.payload.id, 'cost'],
    sum(action.payload.value) / reviewPeriod,
    currentCosts
  );

  const result = yield call(recurringCosts, reviewPeriod, currentCosts);

  yield put(recurringCostsActionCreators.updateResult({ ...result, id: action.payload.id, meta }));
}

export function* revertCashFlowEditing(action) {
  const unedittedCashflow = yield select(recurringCostsSelectors.getCostCashflow, action.payload);

  yield call(costChange, {
    ...action,
    payload: {
      ...action.payload,
      unedittedCashflow,
      value: pipe(
        filter(y => y !== 0),
        head
      )(unedittedCashflow),
    },
  });
}

export function* costChange(action) {
  const { id, value } = action.payload;
  const meta = path(['meta'], action);
  const currentCosts = yield select(recurringCostsSelectors.getRecurringCosts);

  const costObject = {
    ...currentCosts,
    costs: {
      ...currentCosts.costs,
      [id]: {
        ...currentCosts.costs[id],
        cost: ifElse(equals(''), always(''), roundWithFloatingFixedPoint)(value),
        pristine: false,
        cashflowEdited: false,
      },
    },
  };

  yield put(recurringCostsActionCreators.updateCost({ ...costObject, meta }));
  yield call(updateResultAddRow, {
    ...action,
    payload: { ...action.payload, currentCosts: costObject },
    meta,
  });
}

function* reset(action) {
  const { meta } = action;

  yield call(undoToast, {
    action,
    undoActions: [
      put(recurringCostsActionCreators.updateReset({ meta })),
      put(cashflowActionCreators.updateRecurringCostsReset({ meta })),
    ],
    extraActions: [call(updateResult, action)],
    formattedMessageId: 'costs.recurring.delete.all.toast.message',
    defaultMessage: 'Recurring Costs Deleted',
  });
}

function* deleteRow(action) {
  const { payload, meta } = action;
  yield call(undoToast, {
    action,
    undoActions: [
      put(recurringCostsActionCreators.updateRemoveRow({ id: payload, meta })),
      put(cashflowActionCreators.updateRecurringCostsRemoveRow({ id: payload, meta })),
    ],
    extraActions: [call(updateResult, action)],
    formattedMessageId: 'costs.recurring.delete.row.toast.message',
    defaultMessage: 'Cost Deleted',
  });
}

export const effects = [
  takeLatest(
    [types.START.CHANGE, types.END.CHANGE, types.GROWTH.CHANGE, types.RECURRENCE.CHANGE],
    updateResultAddRow
  ),
  takeLatest([types.DESCRIPTION.CHANGE, types.TYPE.CHANGE], addDynamicRow),
  takeLatest(types.COST.CHANGE_START, costChange),
  takeLatest(types.END.DEAL_PERIOD_CHANGE, updateResult),
  takeEvery(types.ROW.DO_DELETE, deleteRow),
  takeLatest(types.DO_RESET, reset),
  takeEvery(cashflowTypes.COSTS.RECURRING.UPDATE, costChangeCashflowEdit),
  takeEvery(cashflowTypes.COSTS.RECURRING.REVERT_EDIT, revertCashFlowEditing),
];
export default effects;
