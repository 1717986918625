/* eslint-disable no-confusing-arrow, no-nested-ternary  */
import { css } from 'styled-components';
import { setAlphaHsla } from '@sharkfinesse/sfl-lib';

const Dropzone = ({ font, colors, scale }) => ({
  container: ({ selected, width, height }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    border-width: 1px;
    border-radius: 2px;
    border-color: ${({ isDragAccept, isDragReject, isDragActive }) =>
      isDragAccept
        ? '#00e676'
        : isDragReject
        ? colors.invalid
        : isDragActive
        ? colors.primary
        : '#eeeeee'};
    border-style: dashed;
    background-color: ${setAlphaHsla(0.1, colors.white)};
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
    font-size: 16px;
  `,
});

export default Dropzone;
