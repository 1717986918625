/* @flow */
import { createReducer, simpleAction, auth0InitialState } from '@sharkfinesse/sfl-lib';

const namespace = (name: string): string => `@@auth0/${name}`;

//Types
export const types = {
  LOGIN: {
    SUCCESS: namespace('LOGIN.SUCCESS'),
    ERROR: namespace('LOGIN.ERROR'),
  },
};

//Reducer
const initialState = auth0InitialState;

const reduceLoginSuccess = (state, { payload: { profile, token, appMetadata } }) => ({
  ...state,
  token,
  profile,
  appMetadata,
});

const reduceInitialState = () => initialState;

export default createReducer(
  {
    [types.LOGIN.SUCCESS]: reduceLoginSuccess,
    [types.LOGIN.ERROR]: reduceInitialState,
  },
  initialState
);

//Action Creators
const loginSuccess = simpleAction(types.LOGIN.SUCCESS);
const loginError = simpleAction(types.LOGIN.ERROR);

export const actionCreators = {
  loginSuccess,
  loginError,
};
