/* @flow */
import {
  simpleAction,
  createReducer,
  preferencesReportThemeInitialState,
} from '@sharkfinesse/sfl-lib';
const namespace = (name: string): string => `@@preferences.reportTheme/${name}`;

//Actions
export const types = {
  UPDATE: namespace('UPDATE'),
};

//Reducer
export const initialState = preferencesReportThemeInitialState;

const reduceReportTheme = (state, action) => ({
  ...state,
  ...action.payload,
});

export default createReducer(
  {
    [types.UPDATE]: reduceReportTheme,
  },
  initialState
);

//Action Creators
const updateReportTheme = simpleAction(types.UPDATE);

export const actionCreators = {
  updateReportTheme,
};
