import {
  omit,
  isNil,
  isEmpty,
  reject,
  anyPass,
  contains,
  pickBy,
  questionnaireSelectors,
  mergeWith,
  equals,
} from '@sharkfinesse/sfl-lib';
import { select, put, all } from 'redux-saga/effects';
import { actionCreators as questionnaireActionCreators } from 'redux/modules/questionnaire/questionnaire';

export function* saveBenefit(action) {
  const { benefitId, formData, notes, saveNotes, inputList, suiteId } = action.payload;
  const [submittedBenefitData, benefit] = yield all([
    select(questionnaireSelectors.getSubmittedBenefitData),
    select(questionnaireSelectors.getBenefit, { benefitId, suiteId }),
  ]);
  const existingBenefitData = benefit?.values;

  const removeExisting = existingBenefitData
    ? mergeWith(
        (left, right) => {
          return equals(left, right) ? null : left;
        },
        formData,
        existingBenefitData
      )
    : formData;

  const doesContain = (val, key) => contains(key, inputList);

  const cleanData = reject(anyPass([isEmpty, isNil]));
  const valuesObj = pickBy(doesContain, cleanData(removeExisting));

  const parentObj = {
    ...omit(['values'], submittedBenefitData?.[benefitId]),
    ...(saveNotes && !isNil(notes) && { notes }),
  };
  const benefitObject = {
    ...cleanData(parentObj),
    ...(!isEmpty(valuesObj) && { values: valuesObj }),
  };

  const updatedBenefits = {
    ...omit([benefitId], submittedBenefitData),
    ...((benefitObject?.notes || benefitObject?.values) && {
      [benefitId]: benefitObject,
    }),
  };

  yield put(questionnaireActionCreators.submitBenefitData(updatedBenefits));
}

export default saveBenefit;
