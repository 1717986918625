/* @flow */
/* eslint-disable react/prop-types */
import React from 'react';
import { Head } from './styled';
import TableContext2 from './context2';

const Component = ({ children, ...props }) => {
  const table = {
    variant: 'head',
    numberOfChildren: children.length,
  };

  return (
    <TableContext2.Provider value={table}>
      <Head {...props}>{children}</Head>
    </TableContext2.Provider>
  );
};

export default Component;
