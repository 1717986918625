"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createReducer = void 0;
exports.getActionTypePath = getActionTypePath;
exports.simpleReducer = exports.simpleActionMeta = exports.simpleAction = void 0;

var _fp = require("./fp");

var _id = require("./id");

var _toolkit = require("@reduxjs/toolkit");

/* eslint-disable fp/no-nil */
const simpleAction = (type, prepare = args => {
  return {
    meta: {
      parentActionGroup: {
        name: type,
        type: 'CATEGORY',
        id: (0, _id.generateTwoSecondId)()
      }
    },
    payload: args
  };
}) => (0, _toolkit.createAction)(type, prepare);

exports.simpleAction = simpleAction;

const simpleActionMeta = type => ({
  meta,
  ...payload
}) => simpleAction(type, (0, _fp.isNil)(meta) ? null : args => ({
  meta,
  payload: args
}))(payload);

exports.simpleActionMeta = simpleActionMeta;
const simpleReducer = (0, _fp.pipe)(_fp.assoc, _fp.flip, (0, _fp.useWith)(_fp.placeholder, [_fp.idiot, (0, _fp.prop)('payload')]));
exports.simpleReducer = simpleReducer;
const createReducer = (0, _fp.useWith)((0, _fp.ifElse)((0, _fp.is)(Object)), [(0, _fp.pipe)((0, _fp.flip)((0, _fp.propOr)(_fp.idiot)), (0, _fp.uncurryN)(2), (0, _fp.converge)(_fp.placeholder, [(0, _fp.pipe)((0, _fp.nthArg)(1), (0, _fp.propOr)('', 'type')), (0, _fp.nthArg)(0), (0, _fp.pipe)((0, _fp.nthArg)(1), (0, _fp.defaultTo)({}))]), (0, _fp.uncurryN)(1)), _fp.always]);
exports.createReducer = createReducer;

function getActionTypePath(type) {
  return (0, _fp.split)('.', (0, _fp.split)('/', (0, _fp.replace)('@@', '', type))[0]);
}