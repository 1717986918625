import { useQuery } from 'react-query';
import fetchSession from './fetch-session';

const getItems = async (props) => {
  const sessions = Promise.all(
    props.ids.map(async (id) => fetchSession({ id, account: props.account }))
  );
  return sessions;
};

const useSessionsFile = (props, config) => {
  return useQuery('preCacheSession', () => getItems(props), config);
};

export default useSessionsFile;
