"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findByValue = exports.findById = void 0;

var _fp = require("./fp");

/* eslint-disable fp/no-mutation, no-confusing-arrow, fp/no-nil  */
const findById = id => (0, _fp.find)((0, _fp.propEq)('id', id));

exports.findById = findById;

const findByValue = value => (0, _fp.find)((0, _fp.propEq)('value', value));

exports.findByValue = findByValue;