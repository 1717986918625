/* eslint-disable prettier/prettier */
const Commentary = ({ font, colors, scale }) => ({
  fontColor: font.color,
  headingFontWeight: font.weightNormal,
  headingFontSize: font.sizeLarge,
  resultColor: colors.primary,
  resultFontWeight: font.weightSemiBold,
});

export default Commentary;
