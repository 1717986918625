/* eslint-disable default-case */
import { put, take } from 'redux-saga/effects';
import {
  types as persistenceTypes,
  actionCreators as persistenceActionScreators,
} from 'redux/modules/persistence';

export default function* savingWatcher() {
  let processingRequestsNumber = 0;

  while (true) {
    const action = yield take([
      persistenceTypes.SERVER_SAVE.REQUEST,
      persistenceTypes.SERVER_SAVE.SUCCESS,
      persistenceTypes.SERVER_SAVE.FAILURE,
    ]);
    switch (action.type) {
      case persistenceTypes.SERVER_SAVE.REQUEST: {
        processingRequestsNumber += 1;
        yield put(persistenceActionScreators.signalUnsavedChanges(true));
        break;
      }
      case persistenceTypes.SERVER_SAVE.SUCCESS:
      case persistenceTypes.SERVER_SAVE.FAILURE: {
        processingRequestsNumber -= 1;
        if (processingRequestsNumber < 0) {
          processingRequestsNumber = 0;
        }
        if (processingRequestsNumber === 0) {
          yield put(persistenceActionScreators.signalUnsavedChanges(false));
        }
      }
    }
  }
}
