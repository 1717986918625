import { getReportTemplates } from 'redux/firestore';
import { useQuery } from 'react-query';

const getItems = async props => {
  const response = await getReportTemplates(props);
  return response;
};

const useReportTemplates = (props, config) =>
  useQuery('report_templates', () => getItems(props), config);

export default useReportTemplates;
