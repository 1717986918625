"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

const getSettings = state => state.settings;

const getBenefitFiltersVisible = state => {
  if (state.settings.benefits) {
    return state.settings.benefits.filtersVisible;
  }

  return false;
};

const getSessions = state => state.settings.sessions;

const getOfflineSessions = state => {
  var _state$settings, _state$settings$sessi;

  return (_state$settings = state.settings) === null || _state$settings === void 0 ? void 0 : (_state$settings$sessi = _state$settings.sessions) === null || _state$settings$sessi === void 0 ? void 0 : _state$settings$sessi.offline;
};

const getRecentSessionsVisible = state => {
  var _state$settings2, _state$settings2$ui;

  return (_state$settings2 = state.settings) === null || _state$settings2 === void 0 ? void 0 : (_state$settings2$ui = _state$settings2.ui) === null || _state$settings2$ui === void 0 ? void 0 : _state$settings2$ui.rsv;
};

const getHomeHeroVisible = state => {
  var _state$settings3, _state$settings3$ui;

  return (_state$settings3 = state.settings) === null || _state$settings3 === void 0 ? void 0 : (_state$settings3$ui = _state$settings3.ui) === null || _state$settings3$ui === void 0 ? void 0 : _state$settings3$ui.hhv;
};

const getSharedSessionsVisible = state => {
  var _state$settings4, _state$settings4$ui;

  return (_state$settings4 = state.settings) === null || _state$settings4 === void 0 ? void 0 : (_state$settings4$ui = _state$settings4.ui) === null || _state$settings4$ui === void 0 ? void 0 : _state$settings4$ui.ssv;
};

const selectors = {
  getBenefitFiltersVisible,
  getSettings,
  getSessions,
  getOfflineSessions,
  getRecentSessionsVisible,
  getHomeHeroVisible,
  getSharedSessionsVisible
};
var _default = selectors;
exports.default = _default;