/* @flow */
import React, { memo } from 'react';
import { withTheme } from '@sharkfinesse/sfl-ui';

export type Props = {
  width: string | number,
  height: string | number,
};

let Image = ({ width, height, theme }: Props): React.Element<any> => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 128 105.85">
    <rect x="91.09" y="46.5" width="30.01" height="57.8" fill="#f3f3f3" />
    <rect x="121.02" y="46.5" width="6.98" height="57.8" fill="#dfdfdf" />
    <rect x="93.57" y="50.16" width="25.04" height="4.83" fill="#dfdfdf" />
    <circle cx="95.37" cy="51.96" r="0.85" fill="#f3f3f3" />
    <path
      d="M636.59,578.78h-13a.25.25,0,0,1,0-.5h13a.25.25,0,0,1,0,.5Z"
      transform="translate(-526.62 -526.56)"
      fill="#f3f3f3"
    />
    <rect x="93.57" y="58.32" width="25.04" height="4.83" fill="#dfdfdf" />
    <circle cx="95.37" cy="60.12" r="0.85" fill="#f3f3f3" />
    <path
      d="M636.59,586.94h-13a.25.25,0,0,1,0-.5h13a.25.25,0,1,1,0,.5Z"
      transform="translate(-526.62 -526.56)"
      fill="#f3f3f3"
    />
    <rect x="93.57" y="66.48" width="25.04" height="4.83" fill="#dfdfdf" />
    <circle cx="95.37" cy="68.28" r="0.85" fill="#f3f3f3" />
    <path
      d="M636.59,595.1h-13a.25.25,0,0,1-.25-.25.26.26,0,0,1,.25-.26h13a.26.26,0,0,1,.25.26A.25.25,0,0,1,636.59,595.1Z"
      transform="translate(-526.62 -526.56)"
      fill="#f3f3f3"
    />
    <rect x="93.57" y="74.63" width="25.04" height="4.83" fill="#dfdfdf" />
    <circle cx="95.37" cy="76.44" r="0.85" fill="#f3f3f3" />
    <path
      d="M636.59,603.26h-13a.26.26,0,0,1,0-.51h13a.26.26,0,0,1,0,.51Z"
      transform="translate(-526.62 -526.56)"
      fill="#f3f3f3"
    />
    <rect x="93.57" y="82.79" width="25.04" height="4.83" fill="#dfdfdf" />
    <circle cx="95.37" cy="84.6" r="0.85" fill="#f3f3f3" />
    <path
      d="M636.59,611.42h-13a.26.26,0,0,1,0-.51h13a.26.26,0,0,1,0,.51Z"
      transform="translate(-526.62 -526.56)"
      fill="#f3f3f3"
    />
    <path
      d="M653,580.32h-3.75a.13.13,0,0,1-.13-.13.12.12,0,0,1,.13-.12H653a.12.12,0,0,1,.13.12A.13.13,0,0,1,653,580.32Z"
      transform="translate(-526.62 -526.56)"
      fill="#f3f3f3"
    />
    <path
      d="M653,581.29h-3.75a.12.12,0,0,1-.13-.12.13.13,0,0,1,.13-.13H653a.13.13,0,0,1,.13.13A.12.12,0,0,1,653,581.29Z"
      transform="translate(-526.62 -526.56)"
      fill="#f3f3f3"
    />
    <path
      d="M653,586.93h-3.75a.12.12,0,0,1-.13-.12.13.13,0,0,1,.13-.13H653a.13.13,0,0,1,.13.13A.12.12,0,0,1,653,586.93Z"
      transform="translate(-526.62 -526.56)"
      fill="#f3f3f3"
    />
    <path
      d="M653,587.91h-3.75a.13.13,0,0,1,0-.26H653a.13.13,0,0,1,0,.26Z"
      transform="translate(-526.62 -526.56)"
      fill="#f3f3f3"
    />
    <path
      d="M653,595h-3.75a.12.12,0,0,1-.13-.12.13.13,0,0,1,.13-.13H653a.13.13,0,0,1,.13.13A.12.12,0,0,1,653,595Z"
      transform="translate(-526.62 -526.56)"
      fill="#f3f3f3"
    />
    <path
      d="M653,596h-3.75a.13.13,0,1,1,0-.26H653a.13.13,0,1,1,0,.26Z"
      transform="translate(-526.62 -526.56)"
      fill="#f3f3f3"
    />
    <path
      d="M653,602.75h-3.75a.13.13,0,0,1-.13-.13.12.12,0,0,1,.13-.12H653a.12.12,0,0,1,.13.12A.13.13,0,0,1,653,602.75Z"
      transform="translate(-526.62 -526.56)"
      fill="#f3f3f3"
    />
    <path
      d="M653,603.72h-3.75a.12.12,0,0,1-.13-.12.13.13,0,0,1,.13-.13H653a.13.13,0,0,1,.13.13A.12.12,0,0,1,653,603.72Z"
      transform="translate(-526.62 -526.56)"
      fill="#f3f3f3"
    />
    <rect x="0.4" y="26.73" width="53.26" height="30.34" fill="#f3f3f3" />
    <rect x="4.47" y="31.51" width="30.19" height="21.92" rx="2.98" fill="#d0d0d0" />
    <path
      d="M608.92,586.29a50.76,50.76,0,0,1,5.6,4.9c1.37.37,7.33,2.64,12.53,3.44,0,.73-.39,3.17-.39,3.17s-12.53-1.55-14-2a38,38,0,0,1-8.69-5.33A46.34,46.34,0,0,1,608.92,586.29Z"
      transform="translate(-526.62 -526.56)"
      fill="#de8e68"
    />
    <ellipse cx="101.77" cy="69.75" rx="2.81" ry="2.08" fill="#de8e68" />
    <polygon points="62.2 78.53 62.2 105.85 71.48 105.85 66.84 78.82 62.2 78.53" fill="#56cad8" />
    <polygon
      points="77.77 79.61 77.77 105.85 70.31 105.85 64.28 78.25 77.77 79.61"
      fill="#74d5de"
    />
    <path
      d="M586.91,587.14a22.94,22.94,0,0,0-1.94,2.51c-.81-.71-14.41-13.21-14.73-13.21a.57.57,0,0,0-.56.53c0,1.66,1.21,1.74,2.71,3.33,3.14,5.65,10.68,15.88,12.65,15.88,1.13,0,5.86-4.62,5.86-5.22S586.91,587.14,586.91,587.14Z"
      transform="translate(-526.62 -526.56)"
      fill="#de8e68"
    />
    <path
      d="M593.51,580.34c-3.14-1.9-8.15,5.07-8,6.24s4.72,4.55,6.65,4.18C593.37,590.51,596.8,582.32,593.51,580.34Z"
      transform="translate(-526.62 -526.56)"
      fill="#fcc486"
    />
    <path
      d="M597.91,578.08c9.33,0,8.72,26.46,7.07,27.8-2.06,1.66-15.42.12-16.16-.79S587.53,578.08,597.91,578.08Z"
      transform="translate(-526.62 -526.56)"
      fill="#fed385"
    />
    <path
      d="M600.53,579.64c0,.78-4.33,1-4.33,0v-5.31h4.33Z"
      transform="translate(-526.62 -526.56)"
      fill="#de8e68"
    />
    <ellipse
      cx="597.71"
      cy="572.05"
      rx="5.21"
      ry="3.88"
      transform="translate(-680.65 391.03) rotate(-68.43)"
      fill="#de8e68"
    />
    <ellipse
      cx="600.78"
      cy="574.01"
      rx="1.24"
      ry="0.87"
      transform="translate(-742.31 201.73) rotate(-54.4)"
      fill="#de8e68"
    />
    <path
      d="M604.81,571.41c0-.72-.68-1.51-.8-2.42-.07-.46.07-2.37-.46-3-1.12-1.34-1.78-1.07-2.63-1.39-1.48-.54-2-1.44-3.28-1.44-1.39,0-1.95.72-2.95.72s-2.07-.52-3.67-.52a2.36,2.36,0,0,0-2,2.1c0,2.32,2.16,2,2.16,2.56,0,.39-.12.7-.12,1.24,0,.71.92,1.12,1.44,1.12,1.6,0,1.81-.2,1.81-.2a7.71,7.71,0,0,1,1.2-1.74,8.11,8.11,0,0,0,2.8.72c1.39.05,2.37.32,2.37,1,0,.45-.17,1.81-.16,2.2s.15.62.24.62a1.43,1.43,0,0,1,.66-.12c.58,0,.62.7.72.7C602.74,573.52,604.81,573.06,604.81,571.41Z"
      transform="translate(-526.62 -526.56)"
      fill="#fd8369"
    />
    <path
      d="M602.16,580.74c3.14-1.9,8.15,5.07,8.05,6.24s-4.71,4.55-6.65,4.18C602.3,590.91,598.87,582.73,602.16,580.74Z"
      transform="translate(-526.62 -526.56)"
      fill="#fed892"
    />
    <path
      d="M572.71,580a2.8,2.8,0,1,0,3,4.7,2.87,2.87,0,0,0,.94-1Z"
      transform="translate(-526.62 -526.56)"
      fill="#de8e68"
    />
    <rect x="15.66" y="0.25" width="38" height="22.53" fill="#f3f3f3" />
    <rect width="13.37" height="22.53" fill="#f3f3f3" />
    <path
      d="M546.35,546.26a.17.17,0,0,1-.12,0,.2.2,0,0,1,0-.27,11.82,11.82,0,0,1,6.74-4.41c3-.64,4.75-2.91,6.19-4.73,1.12-1.42,2.09-2.65,3.29-2.65a8.25,8.25,0,0,1,4.3,1.25,3.81,3.81,0,0,0,1.59.6c2.6,0,5.31-2.41,6.75-6a.19.19,0,1,1,.35.14c-1.49,3.73-4.35,6.24-7.1,6.24a4,4,0,0,1-1.77-.65,8.06,8.06,0,0,0-4.12-1.2c-1,0-1.89,1.11-3,2.51-1.48,1.87-3.31,4.19-6.41,4.86a11.46,11.46,0,0,0-6.53,4.28A.16.16,0,0,1,546.35,546.26Z"
      transform="translate(-526.62 -526.56)"
      fill="#d0d0d0"
    />
    <rect x="1.97" y="3.56" width="9.44" height="0.86" fill="#dedede" />
    <rect x="1.97" y="6.47" width="9.44" height="0.86" fill="#dedede" />
    <rect x="1.97" y="9.38" width="9.44" height="0.86" fill="#dedede" />
    <rect x="1.95" y="12.29" width="9.44" height="0.86" fill="#dedede" />
    <rect x="1.95" y="15.2" width="9.44" height="0.86" fill="#dedede" />
    <rect x="1.95" y="18.1" width="9.44" height="0.86" fill="#dedede" />
    <rect x="6.69" y="34.47" width="12.74" height="0.86" fill="#fff" />
    <rect x="6.69" y="37.38" width="12.74" height="0.86" fill="#fff" />
    <rect x="6.69" y="40.29" width="12.74" height="0.86" fill="#fff" />
    <rect x="6.66" y="43.2" width="12.74" height="0.86" fill="#fff" />
    <rect x="6.66" y="46.1" width="12.74" height="0.86" fill="#fff" />
    <rect x="6.66" y="49.01" width="12.74" height="0.86" fill="#fff" />
    <rect x="24.58" y="29.56" width="21.41" height="12.91" rx="2.98" fill="#fff" />
    <circle cx="34.78" cy="31.21" r="0.72" fill="#f3f3f3" />
    <circle cx="34.78" cy="40.82" r="0.72" fill="#f3f3f3" />
    <path
      d="M561.4,567.37a.19.19,0,0,1-.19-.19V558a.2.2,0,0,1,.19-.19.19.19,0,0,1,.19.19v9.17A.18.18,0,0,1,561.4,567.37Z"
      transform="translate(-526.62 -526.56)"
      fill="#f3f3f3"
    />
    <path
      d="M563.15,564.75a.19.19,0,0,1-.19-.19v-5.35a.19.19,0,0,1,.19-.19.2.2,0,0,1,.19.19v5.35A.2.2,0,0,1,563.15,564.75Z"
      transform="translate(-526.62 -526.56)"
      fill="#f3f3f3"
    />
    <path
      d="M561.4,567.57l-.1,0a.19.19,0,0,1-.06-.26l1.75-2.83a.18.18,0,0,1,.26-.06.19.19,0,0,1,.06.26l-1.75,2.82A.18.18,0,0,1,561.4,567.57Z"
      transform="translate(-526.62 -526.56)"
      fill="#f3f3f3"
    />
    <path
      d="M628.59,556.84A5.13,5.13,0,0,1,634,548.4a7.11,7.11,0,0,1,12.48,1.11,5.12,5.12,0,0,1,6.34,7.33Z"
      transform="translate(-526.62 -526.56)"
      fill="#dfdfdf"
    />
    <rect x="123.21" y="29.84" width="0.5" height="17.26" fill="#dfdfdf" />
    <path
      d="M621.4,568.59a5.2,5.2,0,0,1-4.68-2.5.26.26,0,0,1,.11-.34.25.25,0,0,1,.34.1,4.74,4.74,0,0,0,4.23,2.24c3.7,0,4.93-3.61,4.93-4.58a.25.25,0,1,1,.5,0A5.59,5.59,0,0,1,621.4,568.59Z"
      transform="translate(-526.62 -526.56)"
      fill="#dfdfdf"
    />
    <path
      d="M616.94,560.56h-.05a.26.26,0,0,1-.2-.3c0-.19.08-.38.13-.57a32.2,32.2,0,0,0,.88-7.25,10.08,10.08,0,0,0-1.21-4.58.25.25,0,1,1,.44-.23,10.54,10.54,0,0,1,1.27,4.81,32.23,32.23,0,0,1-.89,7.35l-.12.58A.27.27,0,0,1,616.94,560.56Z"
      transform="translate(-526.62 -526.56)"
      fill="#dfdfdf"
    />
    <path
      d="M597.79,545.72a.25.25,0,0,1-.19-.09.25.25,0,0,1,0-.36c3.07-2.55,5.91-3.75,9-3.75a11.63,11.63,0,0,1,6.95,2.15.25.25,0,0,1,.06.35.27.27,0,0,1-.36.06,11.1,11.1,0,0,0-6.65-2.05c-2.92,0-5.66,1.15-8.63,3.63A.23.23,0,0,1,597.79,545.72Z"
      transform="translate(-526.62 -526.56)"
      fill="#dfdfdf"
    />
    <path
      d="M584.13,556.72a.25.25,0,1,1,0-.5c2.62,0,5-2.34,7.51-4.94a.24.24,0,0,1,.35,0,.25.25,0,0,1,0,.36C589.47,554.31,587,556.72,584.13,556.72Z"
      transform="translate(-526.62 -526.56)"
      fill="#dfdfdf"
    />
    <rect x="66.1" y="19.67" width="3.75" height="3.75" fill="#dfdfdf" />
    <circle cx="88.44" cy="18.95" r="1.65" fill="#dfdfdf" />
    <polygon points="93.01 36.65 89.34 34.27 89.1 38.64 93.01 36.65" fill="#dfdfdf" />
  </svg>
);

export default withTheme(memo(Image));
