/* @flow */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Menu from '../menu';
import TextField from '../text-field';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';
import { StyledCalendar } from './styled';

const DatePicker = ({ value, onChange, ...props }) => {
  const [open, setOpen] = useState(false);

  return (
    <Menu
      open={open}
      usePortal
      refernceAlignment="left"
      referenceComponent={
        <TextField
          layout="column"
          value={moment(value).format('ll')}
          onClick={() => setOpen(!open)}
          onTouchStart={() => setOpen(!open)}
          onChange={() => {}}
        />
      }
    >
      <StyledCalendar
        value={value}
        onChange={onChange}
        onClickDay={value => {
          onChange(value);
          setOpen(false);
        }}
        {...props}
      />
    </Menu>
  );
};

export default DatePicker;
