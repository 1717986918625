import { css } from 'styled-components';

const Styles = ({ font, colors, scale, device: { min_xxs, min_md } }) => ({
  base: () => css`
    display: flex;
    background: #2cab30;
    border-radius: 10px;

    padding: 0px 16px;
    display: flex;
    align-items: center;
    color: ${colors.white};
    @media ${min_xxs} {
      padding: 0px 8px;
      height: 16px;
      font-size: 11px;
    }
    @media ${min_md} {
      padding: 0px 16px;
      height: 20px;
      font-size: 12px;
    }
  `,
});

export default Styles;
