import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import usePrevious from 'hooks/usePrevious';
import { isNil } from '@sharkfinesse/sfl-lib';
import { Switch as Toggle, Section, AlertWarning } from '@sharkfinesse/sfl-ui';

export type Props = {
  sessionId: String | Number,
};

const Privacy = ({
  debuggingEnabled,
  analyticsEnabled,
  updateDebuggingEnabled,
  updateAnalyticsEnabled,
}: Props) => {
  const prevDebuggingEnabled = usePrevious(debuggingEnabled);

  return (
    <Fragment>
      <Section style={{ paddingBottom: 0 }}>
        <Toggle
          labelPosition="before"
          labelProps={{ style: { fontWeight: 500 } }}
          label={
            <FormattedMessage
              id="preferences.privacy.debugging.toggle"
              defaultMessage="Crash reports and debugging"
            />
          }
          checked={debuggingEnabled}
          onChange={() => updateDebuggingEnabled(!debuggingEnabled)}
        />
        {!isNil(prevDebuggingEnabled) && prevDebuggingEnabled !== debuggingEnabled && (
          <AlertWarning style={{ paddingBotton: '12px' }}>
            <FormattedMessage
              id="preferences.privacy.debugging.warning"
              defaultMessage="This change will only take effect when you reload the page."
            />
          </AlertWarning>
        )}
        <p>
          <FormattedMessage
            id="preferences.privacy.debugging.description"
            defaultMessage="Allow Shark Finesse to automatically collect anonymous session and crash reports to debug and improve your experience."
          />
        </p>
      </Section>
      <Section style={{ paddingBottom: 0, marginTop: '20px' }}>
        <Toggle
          labelPosition="before"
          labelProps={{ style: { fontWeight: 500 } }}
          label={
            <FormattedMessage
              id="preferences.privacy.analytics.toggle"
              defaultMessage="Analytics and stats"
            />
          }
          checked={analyticsEnabled}
          onChange={() => updateAnalyticsEnabled(!analyticsEnabled)}
        />
        <p>
          <FormattedMessage
            id="preferences.privacy.analytics.description"
            defaultMessage="Allow Shark Finesse to automatically collect anonymous analytics and states to improve your experience."
          />
        </p>
      </Section>
    </Fragment>
  );
};

export default Privacy;
