// @flow
import React, { useState } from 'react';
import {
  Modal,
  Scroll,
  ModalBodyHeight,
  TabsPanel,
  Tab,
  Button,
  Checkbox,
  useNiceModal,
} from '@sharkfinesse/sfl-ui';
import { FormattedMessage } from 'react-intl';
import { Footer } from './styled';
import { BodyWrapper } from './styled';
import Policy from './policy';
import PrivacyPreferences from 'containers/preferences/privacy';
import Offline from './offline';
import {
  getOfflineEnabled,
  getOfflineEnabledModal,
  setOfflineEnabled,
  setOfflineEnabledModal,
  setUpdateCache,
  isNil,
} from '@sharkfinesse/sfl-lib';
import NiceModal from '@ebay/nice-modal-react';

type Props = {
  intl: Object,
};

const Compliance = ({
  isAuthenticated,
  privacyId,
  termsId,
  language,
  acceptPrivacy,
  acceptTerms,
  userPrivacyId,
  userTermsId,
}: Props) => {
  const { modalProps } = useNiceModal();
  const [currentPage, setCurrentPage] = useState(0);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const { value } = language;

  const items = [];
  if (privacyId) items.push({ id: 'privacy' });
  if (termsId) items.push({ id: 'terms' });
  if (isNil(userPrivacyId)) items.push({ id: 'settings' });
  if (!getOfflineEnabled() && !getOfflineEnabledModal()) items.push({ id: 'offline' });

  const currentView = items[currentPage]?.id;

  const next = () => {
    if (currentPage + 1 < items.length) {
      setCurrentPage(currentPage + 1);
    } else {
      modalProps.onClose();
    }
  };
  const getBtnLabel = () => {
    if (currentPage + 1 < items.length) {
      return <FormattedMessage id="modal.button.next.label" defaultMessage="Next" />;
    } else {
      return <FormattedMessage id="modal.button.ok.label" defaultMessage="OK" />;
    }
  };

  return (
    <Modal
      {...modalProps}
      onClose={null}
      title={
        <>
          {currentView === 'privacy' &&
            (!isNil(userPrivacyId) && privacyId !== userPrivacyId ? (
              <FormattedMessage
                id="compliance.privacy.update.heading"
                defaultMessage="Updated Privacy Policy"
              />
            ) : (
              <FormattedMessage
                id="compliance.privacy.heading.label"
                defaultMessage="Privacy and Cookie Policy"
              />
            ))}
          {currentView === 'terms' &&
            (!isNil(userTermsId) && termsId !== userTermsId ? (
              <FormattedMessage
                id="compliance.terms.update.heading"
                defaultMessage="Updated Terms and Conditions"
              />
            ) : (
              <FormattedMessage
                id="compliance.terms.heading.label"
                defaultMessage="Terms and Conditions"
              />
            ))}

          {currentView === 'settings' && (
            <FormattedMessage
              id="compliance.settings.heading.label"
              defaultMessage="Privacy Settings"
            />
          )}
          {currentView === 'offline' && (
            <FormattedMessage id="modal.offline.title" defaultMessage="Enable Offline" />
          )}
        </>
      }
      showHeaderKeyline
      showFooterKeyline
      closeOnEsc={true}
      closeOnOutsideClick={false}
      width="large"
      footer={
        <Footer>
          {currentView === 'privacy' && (
            <>
              <Checkbox
                label={
                  <FormattedMessage
                    id="compliance.privacy.agree.checkbox.label"
                    defaultMessage="I have carefully read and agree to the above privacy policy"
                  />
                }
                checked={privacyAccepted}
                onChange={() => setPrivacyAccepted(!privacyAccepted)}
              />

              <Button
                color="Primary"
                onClick={() => {
                  next();
                  acceptPrivacy({ privacyId });
                }}
                disabled={!privacyAccepted}
                style={{ marginLeft: '10px', minWidth: '60px' }}
              >
                {getBtnLabel()}
              </Button>
            </>
          )}
          {currentView === 'terms' && (
            <>
              <Checkbox
                label={
                  <FormattedMessage
                    id="compliance.terms.agree.checkbox.label"
                    defaultMessage="I have carefully read and agree to the above terms and conditions"
                  />
                }
                checked={termsAccepted}
                onChange={() => setTermsAccepted(!termsAccepted)}
              />

              <Button
                color="Primary"
                onClick={() => {
                  next();
                  acceptTerms({ termsId });
                }}
                disabled={!termsAccepted}
                style={{ marginLeft: '10px', minWidth: '60px' }}
              >
                {getBtnLabel()}
              </Button>
            </>
          )}
          {currentView === 'settings' && (
            <>
              <div></div>
              <Button
                color="Primary"
                onClick={next}
                style={{ marginLeft: '10px', minWidth: '60px' }}
              >
                {getBtnLabel()}
              </Button>
            </>
          )}
          {currentView === 'offline' && (
            <>
              <div></div>
              <Button
                color="Primary"
                onClick={() => {
                  next();
                  setCurrentPage(null);
                  setOfflineEnabled(enabled);
                  setOfflineEnabledModal(true);
                  if (enabled) {
                    setUpdateCache(true);
                    window.location.reload();
                  }
                }}
                style={{ marginLeft: '10px', minWidth: '60px' }}
              >
                {getBtnLabel()}
              </Button>
            </>
          )}
        </Footer>
      }
    >
      <ModalBodyHeight maxHeight="400px">
        <Scroll noScrollX>
          <TabsPanel tabPosition={currentPage} onTabChange={setCurrentPage} hideTabs>
            {privacyId && (
              <Tab>
                <BodyWrapper>
                  <Policy id={privacyId} language={value} collection="privacy" />
                </BodyWrapper>
              </Tab>
            )}
            {termsId && (
              <Tab>
                <BodyWrapper>
                  <Policy id={termsId} language={value} collection="terms" />
                </BodyWrapper>
              </Tab>
            )}
            {isNil(userPrivacyId) && (
              <Tab>
                <BodyWrapper style={{ paddingTop: '20px' }}>
                  <PrivacyPreferences />
                </BodyWrapper>
              </Tab>
            )}
            {!getOfflineEnabled() && !getOfflineEnabledModal() && (
              <Tab>
                <BodyWrapper style={{ paddingTop: '20px' }}>
                  <Offline enabled={enabled} setEnabled={setEnabled} />
                </BodyWrapper>
              </Tab>
            )}
          </TabsPanel>
        </Scroll>
      </ModalBodyHeight>
    </Modal>
  );
};

export default NiceModal.create(Compliance);
