/* @flow */
import React from 'react';
import type { Element } from 'react';
import { Hyperlink, Button } from '@sharkfinesse/sfl-ui';
import { FormattedMessage } from 'react-intl';
import { useSpring } from 'react-spring';
import { Blurred, Removed, RemovedWrapper, UndoBox, CenterWrapper } from './styled';
import * as easings from 'd3-ease';

type Props = {
  updateInBusinessCase: Function,
  inBusinessCase: Boolean,
  message: Element<any> | String,
  children: Element<any>,
  showUndo: Boolean,
  showRemove: Boolean,
};

const RemoveFromOutput = ({
  updateInBusinessCase,
  inBusinessCase,
  message,
  showUndo,
  showRemove,
  children,
}: Props) => {
  const blur = useSpring({
    filter: `blur(${inBusinessCase ? 0 : 6}px)`,
    opacity: inBusinessCase ? 1 : 0.3,
    config: {
      duration: 350,
      easing: easings.easeCubic,
    },
  });

  const zoom = useSpring({
    transform: `scale(${inBusinessCase ? 0.7 : 1})`,
    opacity: inBusinessCase ? 0 : 1,
    pointerEvents: inBusinessCase ? 'none' : 'unset',
    config: {
      duration: 350,
      easing: easings.easeCubic,
    },
  });

  return (
    <RemovedWrapper>
      <Removed style={zoom}>
        <CenterWrapper>
          <UndoBox>
            {message}
            {showUndo && (
              <Button onClick={() => updateInBusinessCase(true)}>
                <FormattedMessage id="businesscase.output.undo.label" defaultMessage="Undo" />
              </Button>
            )}
          </UndoBox>
        </CenterWrapper>
      </Removed>
      <Blurred style={blur}>
        {children}
        {showRemove && (
          <CenterWrapper>
            <Hyperlink onClick={() => updateInBusinessCase(false)}>
              <FormattedMessage
                id="businesscase.output.remove.link"
                defaultMessage="Remove from outputs"
              />
            </Hyperlink>
          </CenterWrapper>
        )}
      </Blurred>
    </RemovedWrapper>
  );
};

export default RemoveFromOutput;
