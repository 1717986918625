// @flow
import applyTheme from '../../styles/apply-theme';
import styled from 'styled-components';

const breadcrumb = applyTheme('breadcrumb');

export const BreadcrumbWrapper = breadcrumb('breadcrumbWrapper', styled.ol);
export const BreadcrumbItem = breadcrumb('breadcrumbItem', styled.li);
export const BreadcrumbsSeparator = breadcrumb('breadcrumbsSeparator', styled.li);
export const BreadcrumbsWrapper = breadcrumb('breadcrumbsWrapper');
export const BreadcrumbsLeft = breadcrumb('breadcrumbsLeft');
export const BreadcrumbsRight = breadcrumb('breadcrumbsRight');
