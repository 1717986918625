// @flow
import applyTheme from '../../styles/apply-theme';
import styled from 'styled-components';
import { animated } from 'react-spring';

const drawer = applyTheme('drawer');

export const Wrapper = drawer('wrapper', styled(animated.div));
export const Container = drawer('container');
export const Background = drawer('background');
export const Contents = drawer('contents');
export const ToggleArrow = drawer('toggleArrow');
export const ToggleWrapper = drawer('toggleWrapper');
export const Shadow = drawer('shadow');
export const DropShadow = drawer('dropShadow', styled(Shadow));
export const PinRight = drawer(['pin', 'pinRight']);
export const PinLeft = drawer(['pin', 'pinLeft']);
export const Title = drawer('title');
export const Header = drawer('header');
