/* @flow */
/* eslint-disable max-len */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path fill="currentColor" d="M6,10l6-4.5l6,4.5v9H6V10z M12,3L4,9v12h16V9L12,3z" />
    <rect fill="inherit" x="9" y="13" width="6" height="6" />
  </Icon>
);

export default memo(IconComponent);
