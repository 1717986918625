/* @flow */
/* eslint-disable max-len */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15,2H3C1.9,2,1,2.9,1,4v10c0,1.1,0.9,2,2,2V4h14C17,2.9,16.1,2,15,2z"
    />

    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7,18V8h12v1.3c0.7,0.1,1.4,0.3,2,0.5V8c0-1.1-0.9-2-2-2H7C5.9,6,5,6.9,5,8v10c0,1.1,0.9,2,2,2h3.6v-2"
    />

    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.3,10.2c-3.2,0-5.7,1.3-5.7,2.9s2.6,2.9,5.7,2.9S23,14.6,23,13S20.4,10.2,17.3,10.2 M11.6,14.4v2.1
c0,1.6,2.6,2.9,5.7,2.9s5.7-1.3,5.7-2.9v-2.1c0,1.6-2.6,2.9-5.7,2.9S11.6,16,11.6,14.4 M11.6,18v2.1c0,1.6,2.6,2.9,5.7,2.9
s5.7-1.3,5.7-2.9V18c0,1.6-2.6,2.9-5.7,2.9S11.6,19.6,11.6,18z"
    />
  </Icon>
);

export default memo(IconComponent);
