import rsfApp from 'redux/rsf';
import fetchAuth from 'utils/fetchAuth';

export const getQuestionnaireSubmission = async ({ email, account, sessionId }) => {
  const snapshot = await rsfApp.firestore
    .collection(`accounts/${account}/questionnaires`)
    .where('email', '==', email)
    .where('sessionId', '==', sessionId)
    .limit(1)
    .get();
  return !snapshot.empty ? snapshot.docs[0].data() : null;
};

export const submitQuestionnaire = async ({ sessionId }) => {
  await fetchAuth({
    url: 'questionnaire/submit',
    config: {
      method: 'POST',
      body: JSON.stringify({ sessionId }),
    },
  });

  return;
};

export const updateQuestionnaire = async ({ id, account, data }) => {
  await rsfApp.firestore.doc(`accounts/${account}/questionnaires/${id}`).update(data);
  return;
};
export const getQuestionnaires = ({ account, owner }) =>
  rsfApp.firestore
    .collection(`accounts/${account}/questionnaires`)
    .where('owner', '==', owner)
    .where('submitted', '!=', null)
    .orderBy('submitted', 'desc')
    .orderBy('sessionId', 'desc')
    .get();

export const getSessionQuestionnaires = ({ account, owner, sessionId }) =>
  rsfApp.firestore
    .collection(`accounts/${account}/questionnaires`)
    .where('owner', '==', owner)
    .where('submitted', '!=', null)
    .where('sessionId', '==', sessionId)
    .orderBy('submitted', 'desc')
    .get();

export const getQuestionnaire = ({ account, id }) =>
  rsfApp.firestore.doc(`accounts/${account}/questionnaires/${id}`).get();
