import ButtonDefault from './button-default';

const ButtonLightest = config => ({
  ...ButtonDefault(config),
  fontColor: config.colors.uiLightest,
  hoverBackground: 'background: transparent',
  hoverBorderColor: `border-color: transparent; color: ${config.colors.white}`,
  fontSize: config.font.sizeNormal,
  activeBoxShadow: 'none',
});

export default ButtonLightest;
