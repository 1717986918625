// @flow
/* eslint-disable no-magic-numbers, react/prop-types */
import React, { useState, useRef, createContext, memo, useEffect } from 'react';
import Portal from './portal';
import SlideCard from './slide-card';
import SlideCardHovered from './slide-card-hovered';
import { isNil } from '@sharkfinesse/sfl-lib';
import { SlideContainer } from './styled';
import 'styled-components/macro';
import { CornerMenu } from './styled';

export const SlideContext = createContext();

const Slide = ({
  onClick,
  hoverContent,
  onMouseEnter,
  onCheckChange,
  checked,
  tag,
  hoverDisabled,
  selecting,
  onTouchStart,
  onTouchEnd,
  onTouchMove,
  containerStyle,
  disabled,
  overlay,
  blur,
  bounds,
  footer,
  hideFooterBorder,
  cornerMenu,
  toolbarItems,
  hoverFooter,
  onClickSetLoading,
  ...props
}) => {
  const [hovered, setHovered] = useState(false);
  const [animating, setAnimating] = useState(false);
  const slideCardRef = useRef();
  const [delayHandler, setDelayHandler] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      setHovered(null);
      setAnimating(false);
      setDelayHandler(null);
    };
  }, []);

  const stopHover = () => {
    setHovered(false);
    setAnimating(false);
    clearTimeout(delayHandler);
  };

  const onClickHandler = e => {
    //nasty workaround to stop menu click through as stopPropagation was preventing other menus hiding
    const targetId = e?.target?.id;

    if (
      targetId !== 'sfl-button' &&
      targetId !== 'icon-wrapper' &&
      targetId !== 'icon-svg' &&
      targetId !== 'icon-path'
    ) {
      if (!loading && !disabled) {
        if (onClickSetLoading) setLoading(true);
        onClick();
      }
    }
  };
  return (
    <SlideContext.Provider
      value={{
        footer,
        hideFooterBorder,
        cornerMenu,
        hovered,
        toolbarItems,
        hoverContent,
        setHovered,
        animating,
        setAnimating,
        hoverFooter,
        loading,
        setLoading,
      }}
    >
      <SlideContainer
        onClick={onClickHandler}
        onMouseLeave={() => {
          setAnimating(true);
          setHovered(false);
        }}
      >
        {cornerMenu && <CornerMenu>{cornerMenu}</CornerMenu>}
        <SlideCard
          blur={blur}
          disabled={disabled}
          loading={loading}
          slideBodyProps={{
            onMouseEnter: () => {
              if (!hoverDisabled && !disabled && !loading) {
                setDelayHandler(
                  setTimeout(() => {
                    setHovered(true);
                    setAnimating(true);
                  }, 400)
                );
              }
            },
            onMouseLeave: () => {
              if (!hoverDisabled && !disabled && !loading) {
                clearTimeout(delayHandler);
              }
            },
          }}
          onMouseEnter={() => {
            if (!loading && !disabled && onMouseEnter) onMouseEnter();
          }}
          slideCardRef={slideCardRef}
          onCheckChange={!disabled && onCheckChange}
          checked={checked}
          tag={tag}
          checkBoxVisibility={!disabled && selecting ? 'visible' : 'hidden'}
          onTouchStart={() => {
            if (!loading && !disabled) {
              if (!checked && delayHandler) {
                stopHover();
              }
              if (onTouchStart) onTouchStart();
            }
          }}
          onTouchEnd={onTouchEnd}
          onTouchMove={onTouchMove}
          overlay={overlay}
          {...props}
        />

        {!hoverDisabled &&
          !disabled &&
          !loading &&
          (hovered || animating) &&
          !isNil(slideCardRef.current) && (
            <Portal>
              <SlideCardHovered
                slideCardRef={slideCardRef}
                hovered={hovered}
                setHovered={setHovered}
                animating={animating}
                setAnimating={setAnimating}
                onCheckChange={
                  onCheckChange
                    ? () => {
                        if (!checked && delayHandler) {
                          stopHover();
                        }
                        onCheckChange();
                      }
                    : null
                }
                checked={checked}
                tag={tag}
                checkBoxVisibility="visible"
                bounds={bounds}
                {...props}
              />
            </Portal>
          )}
      </SlideContainer>
    </SlideContext.Provider>
  );
};
Slide.whyDidYouRender = false;
export default memo(Slide);
