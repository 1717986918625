import { call, fork, take, put, all, select, cancelled, debounce } from 'redux-saga/effects';
import { UAParser } from 'ua-parser-js';
import rsfApp from '../rsf';
import { actionCreators as deviceActionCreators } from '../modules/device';
import {
  isNil,
  hasPath,
  deviceSelectors,
  userSelectors,
  auth0Selectors,
} from '@sharkfinesse/sfl-lib';
import { history } from '../../index';
import LogRocket from 'logrocket';
import { types as deviceTypes } from 'redux/modules/device';
import retryFetch from './utils/retryFetch';
import fetchAuth from 'utils/fetchAuth';

function getUa() {
  const ua = new UAParser();
  return ua.getResult();
}

function* validateDevice(deviceId) {
  const ua = yield call(getUa);
  const clientId = rsfApp.firebaseApp.options.clientId;
  const device = { ua, deviceId, clientId };
  return yield call(retryFetch, fetchAuth, {
    url: 'device/validate',
    config: {
      method: 'POST',
      body: JSON.stringify({ device }),
    },
  });
}

export function* onSyncDevice(action) {
  if (hasPath(['errorCode'], action.payload)) {
    yield call([history, history.push], { pathname: `/logout/${action.payload.errorCode}` });
  }
}

function* syncChannel() {
  const deviceId = getDeviceIdLocalStorage();
  const [userId, auth0UserId] = yield all([
    select(userSelectors.getId),
    select(auth0Selectors.getUserId),
  ]);
  const user = userId || auth0UserId;

  if (isNil(deviceId)) {
    yield call([history, history.push], { pathname: '/logout' });
  } else {
    //if (isNil(getDeviceIdLocalStorage())) window.location.href = '/';
    const channel = rsfApp.rsf.firestore.channel(`/devices/${user}/active/${deviceId}`, 'document');

    try {
      while (true) {
        const previousState = yield select(deviceSelectors.getState);

        const state = yield take(channel);

        const data = state.data();
        //device signed in in different tab
        let stateData = data;

        if (!isNil(deviceId) && isNil(data)) {
          stateData = {
            error: 'Logged out because a login occurred from another device',
            errorCode: 'D300',
          };
        } else if (isNil(data)) {
          stateData = {
            error: 'Logged out because the device is not recognised',
            errorCode: 'D400',
          };
        }
        yield put(deviceActionCreators.debounceSync({ previousState, ...stateData }));
        //yield put(deviceActionCreators.sync({ previousState, ...stateData }));
      }
    } catch (error) {
      console.log('Device sync error', error);
      LogRocket.captureException(error);
    } finally {
      if (yield cancelled()) channel.close();
    }
  }
}

function* updateState(action) {
  yield put(deviceActionCreators.sync(action.payload));
}

export function* debounceStateUpdate() {
  yield debounce(2000, deviceTypes.DEBOUNCE_SYNC, updateState);
}

export function* syncDevice(user) {
  yield fork(syncChannel, user);
}

export function* updateDevice(action) {
  yield put(deviceActionCreators.update(action.payload));
}

export const getDeviceIdLocalStorage = () => localStorage.getItem('did');
export const setDeviceIdLocalStorage = deviceId => localStorage.setItem('did', deviceId);
export const removeDeviceIdLocalStorage = () => localStorage.removeItem('did');

export default validateDevice;
