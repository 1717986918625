"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

const getSessions = state => state.compare.data;

const selectors = {
  getSessions
};
var _default = selectors;
exports.default = _default;