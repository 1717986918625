import { darkenHsla } from '@sharkfinesse/sfl-lib';
import { css } from 'styled-components';

const ToolbarButton = ({ font, colors, scale }) => ({
  button: ({ toggled, disabled }) => css`
    align-items: center;
    display: flex;
    border: 0;
    color: ${colors.ui};
    background: none;
    transition: background-color 0.1s linear, border-color 0.1s linear, color 0.1s linear;
    font-weight: ${font.weightSemiBold};
    padding: 4px 8px;
    border-radius: 4px;
    ${toggled &&
    css`
      background: ${darkenHsla(3, colors.uiLightest)};
    `}

    ${disabled
      ? css`
          opacity: 0.3;
          cursor: auto;
        `
      : css`
          cursor: pointer;
          &:hover {
            background: ${colors.uiLightest};
          }
        `}
  `,
  icon: ({ iconPosition }) => css`
    display: flex;
    margin-right: ${iconPosition === 'right' ? '8px' : '0px'};
    margin-left: ${iconPosition === 'left' ? '8px' : '0px'};
  `,
  label: () => css`
    line-height: 24px;
    display: flex;
  `,
});

export default ToolbarButton;
