// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import * as easings from 'd3-ease';
import { ImgWrapper, Img } from './styled';
import Menu from './menu';

type Props = {
  width: String | Number,
  height: String | Number,
  src: String,
  onSelect: Function,
  selected: String | Number,
  imageId: String | Number,
  originalData: Object,
  deleteFunction: Function,
  userId: String,
};

export const ImageWrapper = styled.div``;

const Image = ({
  width,
  height,
  src,
  onSelect,
  selected,
  imageId,
  originalData,
  deleteFunction,
  userId,
}: Props) => {
  const [loading, setLoading] = useState(true);
  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: {
      easing: easings.easeCubicIn,
    },
    cancel: loading,
  });

  const fadeInOut = useSpring({
    loop: true,
    from: { opacity: 0.3 },
    to: async (next) => {
      while (1) {
        await next({ opacity: 0.3 });
        await next({ opacity: 0.1 });
      }
    },
    delay: Math.random() * 1000,
    config: {
      easing: easings.easeCubicInOut,
    },
  });

  return (
    <>
      {loading && (
        <animated.div
          style={{
            width: width,
            height: height,
            background: 'white',
            position: 'absolute',
            opacity: 0.3,
            ...fadeInOut,
          }}
        ></animated.div>
      )}
      <ImgWrapper width={width} height={height} selected={selected === imageId} onClick={onSelect}>
        <Menu
          className="media-menu"
          originalData={originalData}
          deleteFunction={deleteFunction}
          userId={userId}
        />
        <Img
          src={src}
          width={width}
          height={height}
          alt="img"
          onLoad={() => {
            setLoading(false);
          }}
          onError={() => {
            setLoading(false);
            console.log('Error');
          }}
          style={fadeIn}
        />
      </ImgWrapper>
    </>
  );
};

export default Image;
