/* @flow */
/* eslint-disable max-len */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22.9,22.4C22.8,22.8,22.4,23,22,23H2c-0.6,0-1-0.4-1-1V2c0-0.4,0.2-0.8,0.6-0.9C2,0.9,2.4,1,2.7,1.3L10,8.6
	c-0.5,0.5-0.8,1.2-0.9,1.9L3,4.4V21h16.6l-5.9-5.9c0.6-0.3,1.2-0.7,1.6-1.2l7.4,7.4C23,21.6,23.1,22,22.9,22.4z M12.7,17.3
	c0.3,0.3,0.4,0.7,0.2,1.1C12.8,18.8,12.4,19,12,19H6c-0.6,0-1-0.4-1-1v-6c0-0.4,0.2-0.8,0.6-0.9c0.2-0.1,0.5-0.1,0.7,0H6l1.9,2.4
	c0.7,0.9,1.8,1.6,3,1.9L12.7,17.3z M9.6,17L7,14.4V17H9.6z M22.8,2.2c0.3,0.3,0.3,0.7,0,1l-6.6,6.5l-2-2l6.6-6.5
	c0.3-0.3,0.8-0.3,1,0L22.8,2.2 M14.9,11.1c0,1.6-1.3,2.9-2.9,2.9c-1.1,0-2.3-0.6-2.9-1.4c0.6,0,1.5-0.5,1.5-1.4c0-1.2,1-2.2,2.2-2.2
	S14.9,9.9,14.9,11.1z"
    />
  </Icon>
);

export default memo(IconComponent);
