import { css } from 'styled-components';
import { lightenHsla, setAlphaHsla } from '@sharkfinesse/sfl-lib';

const Theme = ({ font, colors, scale }) => ({
  list: () => css`
    list-style: none;
    color: ${font.color};
    font-size: 12px;
    margin: 0;
    padding: 0;
    li:not(:last-child) {
      border-bottom: 1px solid ${colors.uiLightest};
    }
  `,
  listItem: () => css`
    cursor: pointer;
    display: flex;
    position: relative;
    .hoverContainer {
      display: flex;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      justify-content: center;
      align-items: center;
      background: ${setAlphaHsla(0.95, lightenHsla(33, colors.primary))};
      transition: opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1);
      padding: 3px;
      height: 100%;
      .hoverContent {
        display: flex;
        flex-direction: row;
        transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
        transform: translateY(10px);
        height: 100%;
        align-items: center;
      }
    }
    &:hover > .hoverContainer {
      opacity: 1;
      .hoverContent {
        transform: translateY(0);
      }
    }
  `,
  summaryListItem: () => css`
    cursor: auto;
  `,
  rowWrapper: () => css`
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 0.65fr;
    padding: 4px 4px;
    align-items: center;
  `,
  rowValue: () => css`
    display: flex;
    justify-content: right;
    font-size: 14px;
    color: ${colors.primary};
  `,
  columnValue: () => css`
    font-size: 13px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 14px;
    padding: 4px 0 2px 0;
  `,
  wrapper: () => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 8px 4px;
  `,
  labelWrapper: () => css`
    display: grid;
    grid-template-columns: 1fr;
  `,
  rowLabelWrapper: () => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  `,
  rowLabel: () => css`
    line-height: 13px;
    font-weight: 600;
  `,
  subLabel: () => css`
    font-size: 11px;
    line-height: 12px;
  `,
  title: () => css`
    font-size: 14px;
    font-weight: ${font.weightsemiBold};
    padding: 4px 0;
    margin: 0 4px 0px 4px;
  `,
  menuWrapper: () => css`
    padding: 0 8px;
    width: 300px;
    text-align: left;
  `,
  hoverValue: () => css`
    border: 1px dashed ${colors.primary};
    background-color: ${setAlphaHsla(0.7, colors.white)};
    padding: 0px 12px;
    border-radius: 19px;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  `,
  slideBox: () => css`
    border-radius: 12px;
    box-shadow: 0 0 0 1px ${colors.uiLight};
    background: ${colors.white};
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 6px 12px;
    margin-bottom: 12px;
  `,

  summaryContainer: () => css`
    margin: 12px 6px;
  `,
  summaryTitle: () => css`
    border-bottom: 1px solid ${colors.uiLight};
    margin: 4px 0 8px 0;
    padding-bottom: 10px;
    font-variant: small-caps;
    font-size: 16px;
    letter-spacing: 0.8px;
  `,
  expandedContainer: () => css`
    width: 100%;
    padding: 5px;
    overflow: hidden;
    background: #f9fafb;
    border-bottom: 1px solid ${colors.uiLight};
  `,
  hoverContent: () => css`
    overflow: hidden;
  `,
});

export default Theme;
