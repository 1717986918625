"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const initialState = {
  data: {},
  linked: {},
  schema: {}
};
var _default = initialState;
exports.default = _default;