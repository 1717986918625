"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reselect = require("reselect");

var _reReselect = _interopRequireDefault(require("re-reselect"));

var _intl = _interopRequireDefault(require("./intl"));

var _index = require("../../index");

var _session = _interopRequireDefault(require("./session"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* eslint-disable no-unused-vars  */
const getLayout = state => state.session.present.layout;

const getOriginal = state => state.session.present.layout.original;

const getCategoryType = state => state.session.present.categoryType;

const getSuites = state => state.session.present.layout.suites;

const getSuitesOrder = state => state.session.present.layout.suitesOrder;

const getBenefits = state => state.session.present.layout.benefits;

const getBenefit = (state, props) => state.session.present.layout.benefits[props.benefitId];

const getBenefitId = (state, props) => state.session.present.layout.benefits[props.benefitId].id;

const getBenefitENo = (state, props) => {
  var _state$session$presen, _state$session$presen2;

  return ((_state$session$presen = state.session.present.layout.benefits) === null || _state$session$presen === void 0 ? void 0 : (_state$session$presen2 = _state$session$presen[props.benefitId]) === null || _state$session$presen2 === void 0 ? void 0 : _state$session$presen2.eNo) || '';
};

const getBenefitENoById = (state, benefitId) => state.session.present.layout.benefits[benefitId].eNo || '';

const getSelectedSuiteId = state => state.session.present.layout.selectedSuiteId;

const getSuite = (state, props) => state.session.present.layout.suites[props.suiteId];

const getSuiteName = (state, props) => state.session.present.layout.suites[props.suiteId].name;

const getSuiteCustomName = (state, props) => state.session.present.layout.suites[props.suiteId].customName;

const getSuiteCategoryId = (state, props) => state.session.present.layout.suites[props.suiteId].categoryId;

const getSuiteBenefits = (state, props) => state.session.present.layout.suites[props.suiteId].benefits;

const getSorting = (state, props) => state.session.present.layout.sorting;

const getFilters = (state, props) => state.session.present.layout.filters;

const getSearch = (state, props) => state.session.present.layout.search;

const getBenefitsData = state => state.session.present.benefits.data;

const getSuiteENumbers = (0, _reselect.createSelector)([getSuite, getBenefits, getBenefitsData], (suite, benefits, benefitData) => {
  return {
    name: suite.name,
    id: suite.id,
    benefits: (0, _index.map)(i => ({
      id: i,
      eNo: benefits[i].eNo,
      ...benefitData[i]
    }), suite.benefits)
  };
});

const getVisibleList = state => (0, _index.path)(['session', 'present', 'suiteList', 'visible'], state) || [];

const getFilteredSuitesOrder = (0, _reselect.createSelector)([getVisibleList, getSuitesOrder, getSuites], (visible, suitesOrder, suites) => {
  const getItem = item => (0, _index.find)((0, _index.propEq)('value', item))(visible) ? item : null;

  const filtered = suitesOrder.filter(getItem);
  return filtered;
});
const getBenefitIds = (0, _reselect.createSelector)([getFilteredSuitesOrder, getSuites], (suitesOrder, suites) => {
  return (0, _index.pipe)((0, _index.reduce)((accum, suiteId) => {
    var _suites$suiteId;

    if ((_suites$suiteId = suites[suiteId]) !== null && _suites$suiteId !== void 0 && _suites$suiteId.benefits) return (0, _index.append)(suites[suiteId].benefits, accum);
    return accum;
  }, []), _index.flatten)(suitesOrder);
});
const getNextOrPreviousBenefit = (0, _reselect.createSelector)([(state, props) => props.benefitId, getBenefitIds], (benefitId, benefitIds) => {
  const pos = (0, _index.findIndex)((0, _index.equals)(benefitId))(benefitIds);
  if (pos <= 0 && benefitIds.length === 1) return '';
  if (pos + 1 === benefitIds.length) return benefitIds[pos - 1];
  return benefitIds[pos + 1];
});
const getFirstBenefit = (0, _reselect.createSelector)([getSuites, getSuitesOrder], (suites, suitesOrder) => {
  let benefit = null;
  if ((0, _index.isNil)(suitesOrder)) return '';

  for (var i = 0; i < suitesOrder.length; i++) {
    benefit = (0, _index.path)([suitesOrder[i], 'benefits', 0], suites);
    if (!(0, _index.isNil)(benefit)) break;
  }

  return (0, _index.isNil)(benefit) ? '' : benefit;
});
const getSuiteSelectList = (0, _reselect.createSelector)([getSuites, getSuitesOrder], (suites, suitesOrder) => {
  let options = [];

  for (var i = 0; i < suitesOrder.length; i++) {
    options[i] = {
      value: (0, _index.path)([suitesOrder[i], 'id'], suites),
      label: (0, _index.isNil)((0, _index.path)([suitesOrder[i], 'customName'], suites)) ? (0, _index.path)([suitesOrder[i], 'name'], suites) : (0, _index.path)([suitesOrder[i], 'customName'], suites),
      custom: (0, _index.isNil)((0, _index.path)([suitesOrder[i], 'customName'], suites)) ? false : true,
      categoryId: (0, _index.isNil)((0, _index.path)([suitesOrder[i], 'categoryId'], suites)) ? 'empty' : (0, _index.path)([suitesOrder[i], 'categoryId'], suites)
    };
  }

  return options;
});

function getState(state) {
  return state;
}

const getAllSuitesAndBenefits = (0, _reselect.createSelector)([getState, getSuiteSelectList], (state, suites) => {
  let suitesBens = [];

  for (let s = 0; s < suites.length; s++) {
    let suiteObj = {};
    suiteObj.suite = suites[s];
    suiteObj.benefits = [];
    const benList = getSuiteBenefits(state, {
      suiteId: suites[s].value
    });

    for (let b = 0; b < benList.length; b++) {
      const benefit = getBenefit(state, {
        benefitId: benList[b]
      });
      suiteObj.benefits.push(benefit);
    }

    suitesBens.push(suiteObj);
  }

  return suitesBens;
});
const getTreeData = (0, _reselect.createSelector)([getAllSuitesAndBenefits, _intl.default.getMessages, getBenefitsData], (allSuitesAndBenefits, intl, benefits) => {
  let treeData = [];

  for (let s = 0; s < allSuitesAndBenefits.length; s++) {
    const suitedata = `0-${s}`;
    let _suiteObj = {
      key: suitedata,
      title: allSuitesAndBenefits[s].suite.custom ? allSuitesAndBenefits[s].suite.label : intl[`category.${allSuitesAndBenefits[s].suite.categoryId}.label`],
      children: []
    };

    for (let b = 0; b < allSuitesAndBenefits[s].benefits.length; b++) {
      const _id = allSuitesAndBenefits[s].benefits[b].id;
      let _subHeading = '';

      let _title;

      let _userTitle;

      if (benefits[_id]) {
        if (benefits[_id].values) {
          if (benefits[_id].values.userTitle) {
            _userTitle = benefits[_id].values.userTitle;
          }
        }

        if (benefits[_id].subHeading) {
          _subHeading = ' - ' + benefits[_id].subHeading;
        }
      }

      _title = intl[`${allSuitesAndBenefits[s].benefits[b].eNo}.title.label`] + _subHeading;

      if (_userTitle) {
        _title = _userTitle + _subHeading;
      }

      const bendata = `0-${s}-${b}`;

      _suiteObj.children.push({
        key: bendata,
        title: _title
      });
    }

    treeData.push(_suiteObj);
  }

  return treeData;
});
const getPrepSuites = (0, _reselect.createSelector)([getAllSuitesAndBenefits, _session.default.getSelectedBenefits], (allSuitesAndBenefits, checkedKeys) => {
  let prepSuites = {};

  for (let y = 0; y < checkedKeys.length; y++) {
    const x = (0, _index.pipe)((0, _index.split)('-'), (0, _index.drop)(1))(checkedKeys[y]);

    if (x.length === 2) {
      const suiteId = allSuitesAndBenefits[Number(x[0])].suite.value;

      if (!prepSuites[suiteId]) {
        prepSuites[suiteId] = {};
      }

      prepSuites[suiteId].suite = allSuitesAndBenefits[Number(x[0])].suite;

      if (!prepSuites[suiteId].benefits) {
        prepSuites[suiteId].benefits = [];
      }

      prepSuites[suiteId].benefits.push(allSuitesAndBenefits[Number(x[0])].benefits[Number(x[1])]);
    }
  }

  return prepSuites;
});
const getAllKeys = (0, _reselect.createSelector)([getAllSuitesAndBenefits], allSuitesAndBenefits => {
  let allKeys = [];

  for (let s = 0; s < allSuitesAndBenefits.length; s++) {
    const suitedata = `0-${s}`;
    allKeys.push(suitedata);

    for (let b = 0; b < allSuitesAndBenefits[s].benefits.length; b++) {
      const bendata = `0-${s}-${b}`;
      allKeys.push(bendata);
    }
  }

  return allKeys;
});
const getSuiteCompletionStatus = (0, _reselect.createSelector)([getSuites, getBenefitsData], (suites, benData) => {
  let suiteStatus = {};

  const checkSuite = s => {
    for (let b = 0; b < s.benefits.length; b++) {
      const bId = s.benefits[b];

      if (benData[bId]) {
        suiteStatus[s.id] = true;
        break;
      }
    }
  };

  (0, _index.map)(checkSuite, suites);
  return suiteStatus;
});
const selectors = {
  getLayout,
  getOriginal,
  getCategoryType,
  getSuites,
  getSuiteBenefits,
  getSuitesOrder,
  getBenefits,
  getBenefit,
  getBenefitId,
  getBenefitENo,
  getBenefitENoById,
  getSelectedSuiteId,
  getFirstBenefit,
  getSuite,
  getSuiteName,
  getSuiteCustomName,
  getSuiteCategoryId,
  getSuiteSelectList,
  getAllSuitesAndBenefits,
  getSorting,
  getFilters,
  getSearch,
  getSuiteENumbers,
  getTreeData,
  getPrepSuites,
  getAllKeys,
  getSuiteCompletionStatus,
  getBenefitsData,
  getNextOrPreviousBenefit
};
var _default = selectors;
exports.default = _default;