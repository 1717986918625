// @flow
import applyTheme from '../../styles/apply-theme';
import styled from 'styled-components';

const general = applyTheme('general');

export const FlexColumn = general('flexColumn');
export const FlexRow = general('flexRow');
export const SemiBold = general('semiBold');
export const BodyMargin = general('bodyMargin');
export const BodyTitle = general('bodyTitle');
export const BodyTitleUnderline = general(['bodyTitle', 'bodyTitleUnderline']);
export const PillBg = general('pillBg');
export const StyledLink = general('styledLink');
export const ListWithDivider = general('listWithDivider', styled.ul);
export const SpaceBetweenRow = general('spaceBetweenRow');
export const CharactersRemaining = general('charactersRemaining');
export const PaddedColumn = general('paddedColumn');
export const RelativeWrapper = general('relativeWrapper');
export const ErrorText = general('errorText');
export const CoverImage = general('coverImage', styled.img);
export const SubLabel = general('subLabel');
export const Label = general('label');
export const StackingGrid = general('stackingGrid');
export const StatusWrapper = general('statusWrapper');
export const StatusComplete = general('statusComplete');
export const StatusIncomplete = general('statusIncomplete');
export const StatusEmpty = general('statusEmpty');
export const FullWidth = general('fullWidth');
export const FormContainer = general('formContainer');
export const MarkDownContainer = general('markDownContainer');
export const ViewportCover = general('viewportCover');
export const FlexEnd = general('flexEnd');
export const FlexRowFullWidth = general(['flexRow', 'fullWidth']);
export const PinTopRight = general('pinTopRight');
export const TwoColumnGrid = general('twoColumnGrid');
export const TwoColumnGridNoGap = general('twoColumnGridNoGap');
export const PrimaryBadge = general('primaryBadge');
export const FixedConatiner = general('fixedConatiner');
