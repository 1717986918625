import { stripUnit, borderStyle, borderColor, borderWidth, padding, backgrounds } from 'polished';
import { lightenHsla, setAlphaHsla } from '@sharkfinesse/sfl-lib';
import { css } from 'styled-components';

const foxtrot = 0;
const golf = 90;
const hotel = 0.9;
const india = 30;
const Costs = ({ font, colors, scale, device: { min_md, min_xxl } }) => {
  const TableCellPadding = `${stripUnit(scale.minusFour) / 2}em`;
  const BaseColour = lightenHsla(golf, colors.uiBase);
  return {
    fontColor: colors.uiDark,
    borderStyle: borderStyle('solid'),
    borderWidth: borderWidth('1px'),
    borderColor: borderColor(BaseColour),
    headBorderColor: borderColor(colors.uiLight),
    headBackground: backgrounds(BaseColour),
    headPadding: padding(foxtrot),
    headCellPadding: padding(scale.minusOne, TableCellPadding),
    wrapBreakpoints: { xxs: true, md: false },
    bodyPadding: padding(foxtrot),
    bodyCellPadidng: padding(foxtrot, TableCellPadding),
    rowPadding: `0 ${TableCellPadding} 0 ${TableCellPadding}`,
    collapsedRowPadding: `${scale.zero}`,
    footBackground: backgrounds(BaseColour),
    footPadding: padding(foxtrot),
    rowBackground: colors.white,
    rowBackgroundDrag: setAlphaHsla(hotel, lightenHsla(india, colors.primary)),
    rowBackgroundHover: backgrounds(colors.uiLightest),
    droppableBackground: colors.ui,
    droppableBackgroundDrag: colors.primary,
    staticErrorColor: colors.invalid,
    headerBorderWidth: borderWidth('0px'),
    headerBorderColor: borderColor(colors.uiLight),
    headerBorderStyle: borderStyle('solid'),
    cashflowWrapper: () => css`
      background: ${colors.white};
      padding: 0 10px 4px 10px;
      border-bottom: 1px solid ${colors.uiLightest};
    `,
    edittedWrapper: () => css`
      display: flex;
      align-items: center;
      justify-content: center;
    `,
    profileWrapper: () => css`
      position: absolute;
      right: 2px;
    `,
    resultRow: () => css`
      width: 100%;
      grid-template-columns: 1fr;
      grid-column-gap: 4px;
      @media ${min_md} {
        grid-row-gap: 0;
        grid-column-gap: 8px;
        grid-template-columns: 2fr 20%;
      }
      @media ${min_xxl} {
        grid-row-gap: 0;
        grid-column-gap: 8px;
        grid-template-columns: 2fr 15%;
      }
    `,
    resultLabel: () => css`
      justify-content: center;
      margin-top: 6px;
      @media ${min_md} {
        margin-top: 0;
        justify-content: right;
      }
    `,
    rowContainer: () => css`
      height: 100%;
      width: 100%;
    `,
    rowWrapper: ({ $hideBottomBorder }) => css`
      position: relative;
      border: 1px solid ${BaseColour};
      border-width: 0 0 1px 0;
      ${$hideBottomBorder &&
      css`
        border-bottom: 0;
      `}
    `,
  };
};

export default Costs;
