/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <polygon
      fill="currentColor"
      points="18,14 18,11 16,11 16,14 13,14 13,16 16,16 16,19 18,19 18,16 21,16 21,14 "
    />
    <polyline fill="currentColor" points="15,7 7,7 7,8 15,8 " />
    <polyline fill="currentColor" points="14,11 7,11 7,12 14,12 " />
    <polyline fill="currentColor" points="11,15 7,15 7,16 11,16 " />
    <path fill="currentColor" d="M3,6v3h3V6H3z M5,8H4V7h1V8z" />
    <path fill="currentColor" d="M3,10v3h3v-3H3z M5,12H4v-1h1V12z" />
    <path fill="currentColor" d="M3,14v3h3v-3H3z M5,16H4v-1h1V16z" />
  </Icon>
);

export default memo(IconComponent);
