/* @flow */
import React from 'react';
import { Button, Label, Icon } from './styled';

const ToolbarButton = ({ icon, iconPosition = 'right', label, ...other }) => (
  <Button {...other}>
    {iconPosition === 'right' && icon && <Icon iconPosition={iconPosition}>{icon}</Icon>}
    {iconPosition === 'center' && icon && <Icon>{icon}</Icon>}
    {label && <Label>{label}</Label>}
    {iconPosition === 'left' && icon && <Icon iconPosition={iconPosition}>{icon}</Icon>}
  </Button>
);

export default ToolbarButton;
