// @flow
/* eslint-disable no-confusing-arrow, no-nested-ternary  */
import React, { memo } from 'react';
import Checkbox from '../checkbox';

type Props = {
  halfChecked: Boolean,
  checked: Boolean,
};

const CheckboxComponent = ({ halfChecked, checked }: Props) =>
  halfChecked ? (
    <Checkbox checked indeterminate onChange={() => {}} />
  ) : checked ? (
    <Checkbox checked onChange={() => {}} />
  ) : (
    <Checkbox checked={false} onChange={() => {}} />
  );

export default memo(CheckboxComponent);
