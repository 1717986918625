// @flow
/* eslint-disable no-magic-numbers */
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Container } from './styled';
import { FormattedMessage } from 'react-intl';

type Props = {
  onDrop: Function,
  onDropRejected: Function,
  multiple: Booolean,
  minSize: Number,
  maxSize: Number,
  accept: String,
  disabled: Boolean,
};

const Dropzone = ({
  onDrop,
  onDropRejected,
  multiple = true,
  minSize = 0,
  maxSize = 5242880,
  accept = 'image/svg+xml, image/gif, image/bmp, image/jpeg, image/png, image/tiff, image/webp',
  disabled = false,
}: Props) => {
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    onDropRejected,
    accept,
    minSize,
    maxSize,
  });

  if (disabled) {
    return null;
  }

  return (
    <div className="container">
      <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        <input {...getInputProps()} multiple={multiple} />
        {!isDragActive && (
          <FormattedMessage
            id="app.dropzone.upload"
            defaultMessage="Click here or drop a file to upload. Max size: {maxSize}MB"
            values={{ maxSize: (maxSize / (1024 * 1024)).toFixed(0) }}
          />
        )}
        {isDragActive && !isDragReject && (
          <FormattedMessage id="app.dropzone.drop" defaultMessage="Drop it" />
        )}
        {isDragReject && (
          <FormattedMessage
            id="app.dropzone.reject"
            defaultMessage="File type not accepted, sorry!"
          />
        )}
      </Container>
    </div>
  );
};

export default Dropzone;
