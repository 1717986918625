/*eslint-disable no-throw-literal*/
import firebase from 'firebase/app';
import rsfApp from 'redux/rsf';
import { getFolderSessions } from 'redux/firestore';

export const getFolders = ({ userId, account, limit = 20 }) => {
  return rsfApp.firestore
    .collection(`accounts/${account}/folders`)
    .where('owner', '==', userId)
    .orderBy('updated', 'desc')
    .limit(limit)
    .get();
};
export const getFolder = async ({ id, account }) =>
  rsfApp.firestore
    .doc(`accounts/${account}/folders/${id}`)
    .get()
    .catch(function (error) {
      console.log('FOLDER ERROR');
      return error;
    });

export const getParentsFolders = ({ userId, id, account, limit = 20 }) =>
  rsfApp.firestore
    .collection(`accounts/${account}/folders`)
    .where('parent', '==', id)
    .where('owner', '==', userId)
    .orderBy('updated', 'desc')
    .limit(limit)
    .get();

export const mutateFolder = async ({ id, account, reload, data }) => {
  await rsfApp.firestore.doc(`accounts/${account}/folders/${id}`).update(data);
  return reload;
};

export const createFolder = async ({ account, name, owner, parent }) => {
  const folderRef = rsfApp.firestore.collection(`accounts/${account}/folders`).doc();

  folderRef.set({
    id: folderRef.id,
    name,
    owner,
    parent,
    created: new Date(),
    updated: new Date(),
  });

  return folderRef.id;
};
export const createOrUpdateFolder = async ({
  account,
  id,
  name,
  owner,
  parent = '',
  media,
  starred = false,
}) => {
  let document = await getFolder({ account, id });
  if (document && document.exists) {
    await document.ref.update({
      updated: firebase.firestore.FieldValue.serverTimestamp(),
    });
  } else {
    await document.ref.set(
      {
        id: id,
        name,
        owner,
        parent,
        media,
        starred,
        created: firebase.firestore.FieldValue.serverTimestamp(),
        updated: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true }
    );
  }
  return;
};

export const deleteFolder = async ({ id, account, userId }) => {
  const [sessions, folders] = await Promise.all([
    getFolderSessions({
      userId,
      account,
      limit: 1,
      folderId: id,
    }),
    getParentsFolders({ userId, id, account, limit: 1 }),
  ]);

  if (!sessions.empty || !folders.empty)
    throw { name: 'ChildrenExist', message: 'String you pass in the constructor' };
  await rsfApp.firestore.doc(`accounts/${account}/folders/${id}`).delete();
  return;
};
