import { css } from 'styled-components';

const Avatar = ({ font, colors, scale }) => ({
  wrapper: ({ $border, $hideBorder }) => css`
    border-radius: 50%;
    border: ${$hideBorder ? 'none' : `1px solid ${colors.uiLight}`};
    ${$border &&
    css`
      box-shadow: 0 0 0 2px ${colors.white};
    `}
  `,
  container: ({ $border }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: normal;
    ${$border &&
    css`
      box-shadow: 0 0 0 2px ${colors.white};
    `}
  `,
  initials: ({ $border }) => css`
    color: ${colors.white};
    font-size: 0.55em;
    //margin-top: -0.1em;
  `,
});

export default Avatar;
