// @flow
import styled from 'styled-components';
import applyTheme from '../../styles/apply-theme';

const titlePane = applyTheme('titlePane');

export const Container = titlePane('container');
export const Header = titlePane('header');
export const Title = titlePane('title');
export const Body = titlePane('body');
export const ViewMoreContainer = titlePane('viewMoreContainer');
export const Toolbar = titlePane('toolbar');
export const HeaderWrapper = titlePane('headerWrapper');
export const OpenCloseLink = titlePane('openCloseLink', styled.a);
