/* eslint-disable prettier/prettier */
import Mixins from '../mixins';
import { lightenHsla, darkenHsla, setAlphaHsla, isNil } from '@sharkfinesse/sfl-lib';
import { css } from 'styled-components';

const juliet = 5;
const yankee = 7;
const xray = 0.5;
const oscar = 12;
const D = 32;
const SuiteButton = ({ colors, scale, font }) => ({
  buttonWrapper: () => css`
    display: flex;
    word-break: break-word;
    position: relative;
    width: 100%;
    cursor: move;
    user-select: none;
    &.suite-button-chosen {
      .suite-button {
        box-shadow: 0px 0px 0px 3px ${colors.secondary}, 0px 15px 15px -15px rgba(0, 0, 0, 0.3);
      }
    }
  `,
  button: ({ result, isError }) => css`
    position: relative;
    overflow: hidden;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    min-height: 100px;
    height: 100%;
    text-align: center;
    border-radius: 3px;
    padding: 0;
    align-items: stretch;
    cursor: pointer;
    background: ${colors.white};
    transition: box-shadow 0.2s ease-in-out, background 0.2s ease-in-out, border 0.2s ease-in-out;
    ${!isNil(result)
      ? css`
          border: 2px solid ${colors.secondary};
        `
      : css`
          border: 1px solid;
          border-color: ${lightenHsla(yankee, colors.uiLight)}
            ${lightenHsla(juliet, colors.uiLight)} ${darkenHsla(oscar, colors.uiLight)}
            ${lightenHsla(yankee, colors.uiLight)};
        `}
    ${isError
      ? css`
          border: 2px solid ${colors.invalid};
          &:hover {
            background: ${colors.white};
            box-shadow: 0px 0px 0px 2px
              ${result ? setAlphaHsla(xray, colors.invalid) : setAlphaHsla(xray, colors.invalid)};
          }
        `
      : null}
      &:hover {
      background: ${colors.white};
      box-shadow: 0px 0px 0px 2px
        ${result ? setAlphaHsla(xray, colors.secondary) : setAlphaHsla(xray, colors.uiLight)};
    }
    &.nav-item-active {
      border: 2px solid ${colors.primary};
    }
    &.nav-item-active:hover {
      box-shadow: 0px 0px 0px 2px ${setAlphaHsla(xray, colors.primary)};
    }
    ${Mixins().forceLineBreak()}
  `,
  headingWrapper: () => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    padding: 2rem;
  `,
  heading: () => css`
    color: ${colors.uiDark};
    font-size: ${font.sizeNormal};
    font-weight: ${font.weightNormal};
    font-family: ${font.family};
    margin: 0;
  `,
  subHeading: () => css`
    color: ${colors.ui};
    font-size: ${font.sizeNormal};
    font-weight: ${font.weightNormal};
    font-family: ${font.family};
    margin: 0;
  `,
  result: () => css`
    color: ${colors.uiDark};
    font-size: ${font.sizeNormal};
    font-weight: ${font.weightSemiBold};
    font-family: ${font.family};
  `,
  footer: () => css`
    margin: 0;
    width: 100%;
    border-top-style: solid;
    border-width: 1px;
    border-color: ${lightenHsla(yankee, colors.uiLight)};
    padding: 0 ${scale.minusTwo};
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    > div {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      will-change: transform, opacity;
      padding-left: 10px;
      padding-right: 10px;
    }
  `,

  deleteButton: () => css`
    position: absolute;
    right: 5px;
    top: 1px;
  `,
  menuButton: () => css`
    position: absolute;
    right: -5px;
    top: 1px;
  `,
  hovered: () => css`
    position: absolute;
    top: 0;
    opacity: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1),
      transform 0.4s cubic-bezier(0.4, 0, 0.2, 1), border 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    > .center-button {
      opacity: 0;
    }
    > .right-button {
      opacity: 0;
    }
    > .left-button {
      opacity: 0;
    }

    &:hover {
      opacity: var(--bo);
      > .center-button {
        opacity: 1;
        visibility: var(--vs);
        transform: translate(-50%, -50%);
      }
      > .right-button {
        opacity: 1;
        visibility: var(--vs);
        transform: translateX(0%);
      }
      > .left-button {
        opacity: 1;
        visibility: var(--vs);
        transform: translateX(0%);
      }
    }
  `,
  hoveredButton: ({ btnStyle }) => css`
    ${btnStyle === 'dark'
      ? css`
          background: radial-gradient(
            farthest-corner at 50% 50%,
            rgba(50, 50, 50, 0.5) 30%,
            #323232 100%
          );
        `
      : css`
          background: radial-gradient(
            farthest-corner at 50% 50%,
            rgba(255, 255, 255, 0.5) 30%,
            #e8e8e8 100%
          );
        `};
    border-radius: 3px;
    height: 100%;
    border: 2px solid ${colors.primary};
  `,
  centerButton: () => css`
    transition: all 0.2s;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -0%);
    position: absolute;
  `,
  rightButton: () => css`
    transition: all 0.2s;
    top: 32%;
    transform: translateX(40%);
    position: absolute;
    right: 10%;
  `,
  leftButton: () => css`
    transition: all 0.2s;
    top: 32%;
    transform: translateX(-40%);
    position: absolute;
    left: 10%;
  `,
  addButtonBg: () => colors.uiDarkest,
  layoutButtonBg: () => colors.white,
  wrapper: ({ open }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    overflow: hidden;
    ${open
      ? css`
          > .eval-menu {
            opacity: 1;
            transform: translateX(0%);
            z-index: 1;
            button {
              color: ${colors.primary};
            }
          }
        `
      : css`
          > .eval-menu {
            transition: transform 0.2s ease, opacity 0.2s ease;
            opacity: 0;
            z-index: 2;
            transform: translateX(40%);
          }
          &:hover {
            > .eval-menu {
              opacity: 1;
              transform: translateX(0%);
            }
          }
        `}
  `,
});

export default SuiteButton;
