/* @flow */
import { connect } from 'react-redux';
import {
  appSelectors,
  preferencesSelectors,
  userSelectors,
  loginSelectors,
} from '@sharkfinesse/sfl-lib';
import Compliance from 'components/compliance';
import { actionCreators as userActionCreators } from 'redux/modules/user/user';

const mapStateToProps = (state, props) => ({
  privacyId: appSelectors.getPolicyId(state, props),
  termsId: appSelectors.getTermsId(state, props),
  language: preferencesSelectors.getLanguage(state),
  userPrivacyId: userSelectors.getPrivacyId(state),
  userTermsId: userSelectors.getTermsId(state),
  isAuthenticated: loginSelectors.getLoggedIn(state),
});

const mapDispatchToProps = {
  acceptPrivacy: userActionCreators.acceptPrivacy,
  acceptTerms: userActionCreators.acceptTerms,
};

export default connect(mapStateToProps, mapDispatchToProps)(Compliance);
