"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const initialState = {
  isDirty: false,
  isUsed: false,
  isCollapsed: true,
  delayPeriod: null
};
var _default = initialState;
exports.default = _default;