import { select, takeLatest, put, call, all, fork } from 'redux-saga/effects';
import rsfApp from '../rsf';
import { actionCreators as productActionCreators } from 'redux/modules/product';
import { actionCreators as accountActionCreators } from 'redux/modules/account';
import {
  initialState as preferencesInitialState,
  actionCreators as preferencesActionCreators,
} from 'redux/modules/preferences';
import {
  fetchAccount,
  fetchProduct,
  fetchPreferences,
  userSelectors,
  loginSelectors,
  mergePreferences,
  equals,
  setPreferences,
} from '@sharkfinesse/sfl-lib';
import { types as userTypes } from '../modules/user/user';
import retryFetch from './utils/retryFetch';
import { history } from '../../index';
import { syncPreferences } from './preferences';
function* loadSaga() {
  try {
    const { firestore } = rsfApp;
    const [product, account, userId, questionnaire] = yield all([
      select(userSelectors.getProduct),
      select(userSelectors.getAccount),
      select(loginSelectors.getUID),
      select(loginSelectors.getQuestionnaireAuthenticated),
    ]);

    const [fetchedProduct, fetchedAccount, fetchedPreferences] = yield all([
      call(retryFetch, fetchProduct, {
        firestore,
        product,
      }),
      call(retryFetch, fetchAccount, {
        firestore,
        account,
      }),
      userId
        ? call(retryFetch, fetchPreferences, {
            firestore,
            userId,
          })
        : null,
    ]);

    if (userId) {
      const mergedPrefs = yield call(mergePreferences, {
        preferences: fetchedPreferences,
        preferencesInitialState,
        accountPreferences: fetchedAccount?.preferences,
        productPreferences: fetchedProduct?.preferences,
      });

      if (!equals(mergedPrefs, fetchedPreferences)) {
        yield call(setPreferences, {
          firestore,
          userId,
          preferences: mergedPrefs,
        });
      }
    }

    yield all([
      put(productActionCreators.loaded(fetchedProduct)),
      put(accountActionCreators.loaded(fetchedAccount)),
      put(preferencesActionCreators.loaded()),
      userId && !questionnaire && fork(syncPreferences),
    ]);

    yield console.log('************ Account & Product Successfully Loaded ************');
  } catch (error) {
    console.log('Load error', error);
    yield call([history, history.push], { pathname: '/logout/L100' });
  }
}

export const effects = [takeLatest(userTypes.LOADED, loadSaga)];

export default effects;
