/* eslint-disable prettier/prettier */
import { borderWidth } from 'polished';
import NonRecurringCostsColumns from './non-recurring-costs-columns';

const RecurringCostsColumns = () => ({
  ...NonRecurringCostsColumns(),
  description: {
    size: { md: 5 / 20, xxl: 8 / 20 },
    head: {
      borderWidth: borderWidth('0px', '1px', '0px', '0px'),
    },
  },
  growth: {
    size: { md: 3 / 20, xxl: 2 / 20 },
    head: {
      borderWidth: borderWidth('0px', '1px', '0px', '0px'),
    },
  },
  recurrence: {
    size: { md: 3 / 20, xxl: 2 / 20 },
    head: {
      borderWidth: borderWidth('0px', '1px', '0px', '0px'),
    },
  },
  start: {
    size: { md: 1 / 20 },
    head: {
      borderWidth: borderWidth('0px', '1px', '0px', '0px'),
    },
  },
  end: {
    size: { md: 1 / 20 },
    head: {
      borderWidth: borderWidth('0px', '1px', '0px', '0px'),
    },
  },
});

export default RecurringCostsColumns;
