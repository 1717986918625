// @flow
import { useContext } from 'react';
import { TimelineContent } from './styled';
import TimelineContext from './timeline-context';

const Component = ({ children, ...other }) => {
  const { position } = useContext(TimelineContext);
  return (
    <TimelineContent $position={position} {...other}>
      {children}
    </TimelineContent>
  );
};

export default Component;
