// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MenuInfo, MenuInfoTitle, MenuCreator, MenuLink } from './styled';
import Hyperlink from '../hyperlink';

type Props = {
  data: Object,
};

const MenuInfoComponent = ({ data }: Props): React.Element<any> => {
  const { creator, pageURL, providerName, providerUrl, description, creatorProfile } = data;

  return (
    <>
      {(creator || pageURL || description) && (
        <MenuInfo>
          {description && <MenuInfoTitle>{description}</MenuInfoTitle>}
          {creatorProfile && (
            <MenuCreator>
              <FormattedMessage
                id="gallery.menu.photoBy"
                defaultMessage="Photo by {profileLink} on {providerLink}"
                values={{
                  profileLink: (
                    <a href={creatorProfile} target="_blank" rel="nopener noreferrer">
                      {creator}
                    </a>
                  ),
                  providerLink: (
                    <a href={providerUrl} target="_blank" rel="nopener noreferrer">
                      {providerName}
                    </a>
                  ),
                }}
              />
            </MenuCreator>
          )}
          <MenuLink>
            <Hyperlink href={pageURL} target="_blank" rel="noopener noreferrer">
              <FormattedMessage
                id="gallery.menu.viewOn"
                defaultMessage="View on {providerName}"
                values={{ providerName }}
              />
            </Hyperlink>
          </MenuLink>{' '}
        </MenuInfo>
      )}
    </>
  );
};

export default MenuInfoComponent;
