// @flow
/* eslint-disable no-confusing-arrow, no-nested-ternary  */
import React, { isValidElement } from 'react';
import { BreadcrumbWrapper, BreadcrumbItem, BreadcrumbsSeparator } from './styled';
import Menu from './menu';
import { IconChevronRight } from '../icon';

type Props = {
  children: Element<any>,
  separator: String | Element<any>,
  maxItems: Number,
  itemsBeforeCollapse: Number,
  itemsAfterCollapse: Number,
};

const insertSeparators = ({ items, separator }) => {
  return items.reduce((acc, current, index) => {
    if (index < items.length - 1) {
      acc = acc.concat(
        current,
        <BreadcrumbsSeparator aria-hidden key={`separator-${index}`}>
          {separator}
        </BreadcrumbsSeparator>
      );
    } else {
      acc.push(current);
    }

    return acc;
  }, []);
};

const Breadcrumb = ({
  children,
  maxItems = 3,
  itemsAfterCollapse = 1,
  itemsBeforeCollapse = 1,
  separator = <IconChevronRight size="small" />,
  ...props
}: Props) => {
  const renderItemsBeforeAndAfter = () => {
    const menuItems = React.Children.toArray(children);
    return [
      ...menuItems
        .slice(0, itemsBeforeCollapse)
        .map((child, index) => (
          <BreadcrumbItem key={`child-before-${index}`}>{child}</BreadcrumbItem>
        )),
      <li key="ellipsis">
        <Menu>{menuItems.slice(itemsBeforeCollapse, menuItems.length - itemsAfterCollapse)}</Menu>
      </li>,
      ...menuItems
        .slice(menuItems.length - itemsAfterCollapse, menuItems.length)
        .map((child, index) => (
          <BreadcrumbItem key={`child-after-${index}`}>{child}</BreadcrumbItem>
        )),
    ];
  };

  const allItems = React.Children.toArray(children)
    .filter((child) => {
      return isValidElement(child);
    })
    .map((child, index) => <BreadcrumbItem key={`child-${index}`}>{child}</BreadcrumbItem>);

  return (
    <BreadcrumbWrapper>
      {insertSeparators({
        items: maxItems && allItems.length <= maxItems ? allItems : renderItemsBeforeAndAfter(),
        separator,
      })}
    </BreadcrumbWrapper>
  );
};

export default Breadcrumb;
