/* @flow */
import { simpleAction } from '@sharkfinesse/sfl-lib';
const namespace = (name: string): string => `@@session.benefits/${name}`;

//Actions
export const types = {
  DELETE: {
    ALL: namespace('DELETE.ALL'),
  },
  GO_TO: namespace('GO_TO'),
};

//Action Creators
const updateDeleteAll = simpleAction(types.DELETE.ALL);
const goTo = simpleAction(types.GO_TO);

export const actionCreators = {
  updateDeleteAll,
  goTo,
};
