// @flow
import React, { Children, isValidElement } from 'react';
import { useTransition } from 'react-spring';
import { TabWrapper, TabPanel, ContentContainer } from './styled';
import * as easings from 'd3-ease';
import usePrevious from 'hooks/usePrevious';

export type Props = {
  theme: Object,
  items: Array,
  hideTabs: Boolean,
  hideContent: Boolean,
  selectedIndex: Number,
  value: Number,
  orientation: String,
};

const TabContent = ({ children, value, orientation = 'horizontal', ...props }: Props) => {
  const prevValue = usePrevious(value);
  const reverse = value < prevValue ? true : false;
  const transition = useTransition(value, {
    initial: {
      opacity: 1,
      transform: 'translate3d(0%,0,0)',
      position: 'relative',
      height: '100%',
    },
    from: {
      position: 'absolute',
      opacity: 0,
      transform: `translate3d(${orientation === 'horizontal' ? (!reverse ? '40px' : '-40px') : 0},${
        orientation === 'horizontal' ? 0 : !reverse ? '40px' : '-40px'
      },0)`,
    },
    enter: {
      opacity: 1,
      transform: 'translate3d(0%,0,0)',
      position: 'relative',
      height: '100%',
    },
    leave: {
      position: 'absolute',
      opacity: 0,
      transform: `translate3d(${orientation === 'horizontal' ? (!reverse ? '-40px' : '40px') : 0},${
        orientation === 'horizontal' ? 0 : !reverse ? '-40px' : '40px'
      },0)`,
    },
    config: {
      duration: 400,
      easing: easings.easeCubic,
    },
  });

  const items = Children.toArray(children).filter(child => {
    return isValidElement(child);
  });

  return (
    <TabWrapper orientation={orientation} {...props}>
      <TabPanel>
        {transition((props, item) => (
          <ContentContainer style={props}>
            {items?.[item]?.props?.children && items[item].props.children}
          </ContentContainer>
        ))}
      </TabPanel>
    </TabWrapper>
  );
};

export default TabContent;
