// @flow
import styled from 'styled-components';
import { animated } from 'react-spring';
import applyTheme from '../../styles/apply-theme';

const circularProgressBar = applyTheme('circularProgressBar');
export const Container = circularProgressBar('container');
export const SVG = circularProgressBar('svg', styled.svg);
export const CircleBg = circularProgressBar('circleBg', styled.circle);
export const Circle = circularProgressBar('circle', styled(animated.circle));
export const PercentWrapper = circularProgressBar('percentWrapper');
export const PercentLabel = circularProgressBar('percentLabel', styled(animated.span));
export const PercentSymbol = circularProgressBar('percentSymbol', styled.span);
export const LabelsWrapper = circularProgressBar('labelsWrapper');
export const StepProgress = circularProgressBar('stepProgress');
