import { css } from 'styled-components';

const Styles = ({ font, colors, scale }) => ({
  container: () => css`
    display: flex;
    align-items: center;
  `,
  outline: ({ $checked }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    min-width: 14px;
    min-height: 14px;
    max-width: 14px;
    max-height: 14px;

    box-shadow: 0 0 0 2px ${$checked ? colors.primary : colors.uiLight};
    border-radius: 8px;
  `,
  selected: () => css`
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${colors.primary};
  `,
  label: () => css`
    margin-left: 8px;
  `,
  hidden: () => css`
    border: 0;
    opacity: 0;
    height: 18px;
    margin: -2px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 18px;
  `,
});

export default Styles;
