/* @flow */
import React from 'react';
import { formatForDisplay } from '@sharkfinesse/sfl-lib';
import { Sup } from './styled';

type Props = {
  numberFormatObj: Object,
  valueType: String,
  value: String | Number,
  position: String,
  superscriptValue: String | Number,
};

const isPrefix = ({ numberFormatObj, valueType, position }) => {
  if (valueType === 'moneyNoSymbol') {
    if (numberFormatObj.currencySymbolPosition === 'prefix') return true;
  } else if (position === 'prefix') {
    return true;
  }

  return false;
};

const getSuperscript = ({ numberFormatObj, valueType, superscriptValue }) => {
  switch (valueType) {
    case 'percentageNoSymbol':
      return '%';
    case 'moneyNoSymbol':
      return numberFormatObj.currencySymbol;
    default:
      return superscriptValue;
  }
};

const SuperscriptNumber = ({
  numberFormatObj,
  valueType = 'moneyNoSymbol',
  value = 0,
  position = 'suffix',
  superscriptValue,
  notSuperscript,
}: Props) => {
  const symbol = getSuperscript({ numberFormatObj, valueType, superscriptValue });
  const prefix = isPrefix({ numberFormatObj, valueType, position });
  return (
    <>
      {prefix && (notSuperscript ? symbol : <Sup>{symbol}</Sup>)}
      {formatForDisplay(valueType, numberFormatObj, value)}
      {!prefix && (notSuperscript ? symbol : <Sup>{symbol}</Sup>)}
    </>
  );
};

export default SuperscriptNumber;
