/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon viewBox="0 0 58 58" {...props}>
    <circle fill="inherit" opacity="0.8" cx="29" cy="29" r="29" />
    <path
      fill="currentColor"
      d="M29,2c14.9,0,27,12.1,27,27S43.9,56,29,56S2,43.9,2,29S14.1,2,29,2 M29,0C13,0,0,13,0,29s13,29,29,29
	s29-13,29-29S45,0,29,0L29,0z"
    />
    <path
      fill="currentColor"
      d="M40.6,22.4c0.5-0.5,0.5-1.4,0-1.9l-3.1-3.1c-0.5-0.5-1.4-0.5-1.9,0l-2.5,2.4l5,5 M17,36v5h5l14.7-14.8l-5-5
	L17,36z"
    />
  </Icon>
);

export default memo(IconComponent);
