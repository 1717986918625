/* eslint-disable react-hooks/exhaustive-deps */

import {
  sessionSelectors,
  intlSelectors,
  preferencesSelectors,
  deviceSelectors,
} from '@sharkfinesse/sfl-lib';
import { useSelector } from 'react-redux';
import {
  createOutput,
  createCompareOutput,
  createMultipleOutputs,
  createPrepOutput,
} from '../outputs/reports/firebase-functions-report';
import { useModal } from '@ebay/nice-modal-react';
import { FormattedMessage } from 'react-intl';
import ModalInfo from 'components/modal/modal-info';
import { types as reportsTypes } from 'redux/modules/reports';
import { useDispatch } from 'react-redux';

const useReport = () => {
  const sessionId = useSelector(sessionSelectors.getSessionId);
  const locale = useSelector(intlSelectors.getLocale);
  const docxTemplate = useSelector(preferencesSelectors.getDocxTemplate);
  const pptxTemplate = useSelector(preferencesSelectors.getPptxTemplate);
  const isOnline = useSelector(deviceSelectors.isOnline);

  const infoModal = useModal(ModalInfo);
  const dispatch = useDispatch();

  const createReport = ({ type, suiteId, sessions }) => {
    if (isOnline) {
      switch (type) {
        case 'shortformdocx':
          dispatch({ type: reportsTypes.SHORT.GENERATE });
          break;
        case 'longformdocx':
          dispatch({ type: reportsTypes.LONG.GENERATE });
          break;
        case 'xlsx':
          dispatch({ type: reportsTypes.XLSX_SINGLE.GENERATE });
          break;
        case 'pptx':
          dispatch({ type: reportsTypes.PPTX_SINGLE.GENERATE });
          break;
        case 'comparedocx':
          dispatch({ type: reportsTypes.COMPARE_DOC.GENERATE });
          break;
        case 'comparexlsx':
          dispatch({ type: reportsTypes.COMPARE_XLSX.GENERATE });
          break;
        case 'prepdocx':
          dispatch({ type: reportsTypes.PREP.GENERATE });
          break;
        default:
          break;
      }

      switch (type) {
        case 'prepdocx_single_suite':
          createPrepOutput(suiteId, sessionId, locale, docxTemplate, pptxTemplate);
          break;
        case 'docx_multiple':
          createMultipleOutputs(sessions, docxTemplate, pptxTemplate, locale);
          break;
        case 'comparedocx':
          createCompareOutput(sessions, locale, type, docxTemplate, pptxTemplate);
          break;
        case 'comparexlsx':
          createCompareOutput(sessions, locale, type, docxTemplate, pptxTemplate);
          break;
        default:
          createOutput(sessionId, locale, type, docxTemplate, pptxTemplate);
          break;
      }
    } else {
      infoModal.show({
        title: (
          <FormattedMessage id="reports.pro.modal.title" defaultMessage="Output is unavailable" />
        ),
        body: (
          <FormattedMessage
            id="reports.pro.modal.body"
            defaultMessage="You are currently offline. Please go online to generate outputs"
          />
        ),
      });
    }
  };

  return {
    createReport,
  };
};

export default useReport;
