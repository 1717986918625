/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

const IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M24,11.8l-9.3-9.3v5.3C5.3,9.2,1.3,15.8,0,22.5c3.3-4.7,8-6.8,14.7-6.8v5.5L24,11.8z"
    />
  </Icon>
);

export default memo(IconComponent);
