/* @flow */
/* eslint-disable max-len */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path fill="inherit" d="M2,6v14h18v2H2c-1.1,0-2-0.9-2-2V6H2" />
    <path
      fill="currentColor"
      d="M22,4h-8l-2-2H6C4.9,2,4,2.9,4,4v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V6C24,4.9,23.1,4,22,4
	 M22,16H6V6h16V16z"
    />
  </Icon>
);

export default memo(IconComponent);
