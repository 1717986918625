// @flow
/* eslint-disable no-magic-numbers, react/prop-types */
import React from 'react';
import {
  Container,
  Left,
  Center,
  Right,
  Wrapper,
  Number,
  NumberLabel,
  NumberWrapper,
  SelectedLabel,
  SelectLink,
} from './styled';
import { useTransition } from 'react-spring';
import { length } from '@sharkfinesse/sfl-lib';
import { FormattedMessage } from 'react-intl';
import { useMedia } from 'react-media';
import Hyperlink from '../hyperlink';

const SelectToolbar = ({
  isOpen,
  children,
  rightContent,
  items,
  selectAll,
  deSelectAll,
  ...props
}) => {
  const toolBarTransition = useTransition(isOpen, {
    from: { transform: 'translate3d(0,-100%,0)' },
    enter: { transform: 'translate3d(0,0px,0)' },
    leave: { transform: 'translate3d(0,-100%,0)' },
  });

  const matches = useMedia({
    queries: {
      small: '(min-width: 0) and (max-width: 767px)',
    },
  });

  return toolBarTransition(
    (props, item) =>
      item && (
        <Container style={props}>
          <Wrapper>
            <Left>
              <NumberWrapper>
                <Number>{length(items)}</Number>
              </NumberWrapper>
              <NumberLabel>
                {!matches.small && (
                  <SelectedLabel>
                    <FormattedMessage id="selectToolbar.selected" defaultMessage="Selected" />

                    {!deSelectAll && (
                      <SelectLink onClick={selectAll}>
                        <FormattedMessage
                          id="selectToolbar.selectAll"
                          defaultMessage="Select all"
                        />
                      </SelectLink>
                    )}
                    {deSelectAll && (
                      <SelectLink onClick={deSelectAll}>
                        <FormattedMessage
                          id="selectToolbar.deSelectAll"
                          defaultMessage="Deselect all"
                        />
                      </SelectLink>
                    )}
                  </SelectedLabel>
                )}
              </NumberLabel>
            </Left>
            <Center>{children}</Center>
            <Right>{rightContent}</Right>
          </Wrapper>
        </Container>
      )
  );
};

export default SelectToolbar;
