import { useMutation } from 'react-query';
import fetchAuth from 'utils/fetchAuth';

const useChangePassword = config => {
  return useMutation(async () => {
    const response = await fetchAuth({
      url: 'user/password/change',
      config: {
        method: 'POST',
      },
    });
    return response;
  }, config);
};

export default useChangePassword;
