import { css } from 'styled-components';

const Images = ({ font, colors, scale }) => ({
  benefits: {
    leftBubble: {
      background: '#A0BCCB',
      highlight: '#7BA0A4',
      lines: '#484848',
      border: '#484848',
    },
    rightBubble: {
      background: '#33B1F9',
      highlight: '#5CC1FA',
      lines: '#484848',
      border: '#484848',
    },
  },
  costs: {
    bag: {
      background: '#FFD311',
      highlight: '#FFC200',
      cord: '#F67A54',
      label: {
        background: '#FFFFFF',
        highlight: '#EFEFEF',
      },
    },
    money: {
      stack: '#33B1F9',
      note: {
        outer: '#33B1F9',
        inner: '#5CC1FA',
        middleCircle: {
          background: '#E7ECED',
          highlight: '#D4D8DA',
        },
        leftCircle: '#E7ECED',
        rightCircle: '#E7ECED',
      },
    },
    coin: {
      stack: {
        background: '#FDCA5C',
        highlight: '#FCBD3F',
      },
      background: '#FCBD3F',
    },
    border: '#484848',
  },
  reports: {
    border: '#484848',
    background: '#F9F9F9',
    highlight: '#EFEFEF',
    squareOne: '#098BDF',
    squareTwo: '#FFD311',
    lines: '#C1C1c1',
    corner: '#C1C1c1',
  },
  spreadsheets: {
    border: '#484848',
    background: '#F7F9FC',
    highlight: '#EAEDF4',
    titleBar: '#609b48',
    titleBarButtons: '#ebf5aa',
    labelCells: '#609b48',
    mainCells: '#dddddd',
    headerCells: '#7f7f7f',
    toolbarBackground: '#d7deed',
    headingBarBackground: '#c7cfe2',
    toolBarButton1: '#c7cfe2',
    toolBarButton2: '#ffd782',
    toolBarButton3: '#b4e1fa',
    toolBarButton4: '#eff2fa',
  },
  presentations: {
    border: '#484848',
    background: '#098bdf',
    highlight: '#20839e',
    topBottomBarBackground: '#a3aaaf',
    topBottomBarHighlight: '#94999b',
    labels: '#ffffff',
    pieSegment1: '#ffd311',
    pieSegment2: '#e7eced',
    pole: '#ededed',
  },
  interactive: {
    border: '#484848',
    circles: '#FFDB4E',
    circlesHighlight: '#d1af43',
    bubble: '#098bdf',
    bubbleText: '#484848',
    bubbleHighlight: '#20839e',
    hand: '#ffece4',
    handHighlight: '#e8d0c8',
  },
  imageWrapper: () => css`
    color: ${font.color};
    fill: ${colors.primary};
    opacity: 0.6;
  `,
  imageLabel: () => css`
    font-size: 16px;
    font-weight: ${font.weightSemiBold};
    color: ${font.color};
  `,
});

export default Images;
