/* eslint-disable prettier/prettier */
import { lightenHsla, darkenHsla, setAlphaHsla } from '@sharkfinesse/sfl-lib';
import { css } from 'styled-components';

const E = 0.8;
const Suite = ({ colors, scale, font }) => ({
  base: () => css`
    transition: background 300ms ease-in-out, box-shadow 300ms ease-in-out;
    background: ${colors.uiLightest};
    border: 1px solid;
    border-color: ${lightenHsla(8, colors.uiLight)} ${colors.uiLight}
      ${darkenHsla(8, colors.uiLight)} ${colors.uiLight};
    border-radius: 3px;
    margin: 0 0 ${scale.minusOne} 0;
    max-width: unset;
  `,
  designerBase: ({ selected }) => css`
    user-select: none;
    ${selected &&
    css`
      box-shadow: 0px 0px 0px 2px ${colors.primary};
      background: ${lightenHsla(32, colors.primary)};
    `};

    &.suite-chosen {
      box-shadow: 0px 0px 0px 3px ${colors.secondary}, 0px 15px 15px -15px rgba(0, 0, 0, 0.3);
    }
  `,
  header: () => css`
    padding: ${scale.minusOne} ${scale.minusOne} 0 ${scale.minusOne};
    white-space: pre-line;
  `,
  headerContainer: () => css`
    background: transparent;
    padding: 0;
    border: none;
  `,
  headingWrapper: () => css`
    flex-direction: row;
    align-items: left;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    flex-direction: row;
  `,
  heading: () => css`
    color: ${colors.uiDark};
    font-size: ${font.sizeNormal};
    font-weight: ${font.weightSemiBold};
    margin: 0;
    align-self: center;
  `,
  subHeading: () => css`
    font-size: ${font.sizeNormal};
    font-weight: ${font.weightNormal};
    color: ${setAlphaHsla(E, colors.uiDark)};
    margin: -3px 0 0 0;
    word-wrap: break-word;
  `,
  gridContainer: () => css`
    padding: ${scale.minusOne};
    margin: 0;
    section {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
      width: 100%;
      gap: 6px;
    }
  `,
  suiteContainer: () => css`
    padding: ${scale.minusOne};
    margin: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    width: 100%;
    gap: 6px;
  `,
  body: () => css`
    padding: 0;
  `,
  list: () => css`
    width: 100%;
    margin: 0;
    padding: ${scale.minusOne} ${scale.minusOne} 0 ${scale.minusOne};
  `,

  scrollContainer: () => css`
    background: ${colors.white};
  `,
  footer: () => css`
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    padding: ${scale.minusOne};
    margin: 0px;
    border-top: 1px solid ${colors.uiLight};
  `,
  footerButtonContainer: () => css`
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    justify-content: center;
    button {
      width: 100%;
    }
    &:first-child {
      border-right: 1px solid ${colors.uiLight};
    }
    &:last-child {
      border-right: 0;
    }
  `,
  footerButtonLabel: () => css`
    font-size: 12px;
    margin-left: 6px;
    margin-right: 6px;
  `,
  dragHandle: () => css`
    color: ${colors.ui};
    cursor: n-resize;
    display: flex;
    margin-left: -4px;
  `,
});

export default Suite;
