import { types as layoutTypes } from 'redux/modules/layout';
import { types as evaluatorTypes } from 'redux/modules/evaluator';
import { types as cashflowTypes } from 'redux/modules/cashflow';
import { types as defaultSessionValuesTypes } from 'redux/modules/default-session-values';
import { types as undoredoTypes } from 'redux/modules/undoredo';
import { types as sessionDetailsTypes } from 'redux/modules/session-details';
import { types as recurringCostsTypes } from 'redux/modules/recurring-costs';
import { types as nonRecurringCostsTypes } from 'redux/modules/non-recurring-costs';
import { types as businessCaseTypes } from 'redux/modules/business-case';
import { types as tenderTypes } from 'redux/modules/tender';
import { types as delayTypes } from 'redux/modules/delay';

const undoableActions = [
  layoutTypes.BENEFIT.INSERTING,
  layoutTypes.BENEFIT.UPDATE_ORDER,
  layoutTypes.BENEFIT.DELETING,
  layoutTypes.BENEFIT.DUPLICATING,
  layoutTypes.SUITE.UPDATE_ORDER,
  layoutTypes.SUITE.UPDATE_CUSTOM_NAME,
  layoutTypes.SUITE.INSERTING,
  layoutTypes.SUITE.DUPLICATING,
  layoutTypes.RESET,
  evaluatorTypes.SUBHEADING.UPDATE,
  evaluatorTypes.USER_TITLE,
  evaluatorTypes.BENEFIT.UPDATE,
  evaluatorTypes.NOTES.UPDATE,
  evaluatorTypes.PRIVATE_NOTES.UPDATE,
  evaluatorTypes.SUBHEADING.UPDATE,
  evaluatorTypes.ERROR,
  evaluatorTypes.DELETE,
  evaluatorTypes.RESET,
  evaluatorTypes.CASHFLOW.MODIFIER.ADOPTION.UPDATE,
  evaluatorTypes.CASHFLOW.MODIFIER.GROWTH.UPDATE,
  evaluatorTypes.CASHFLOW.MODIFIER.RISK.UPDATE,
  evaluatorTypes.CASHFLOW.MODIFIER.RESET,
  evaluatorTypes.CASHFLOW.SMOOTH,
  evaluatorTypes.MULTI_YEAR_VALUES.UPDATE,
  evaluatorTypes.MULTI_YEAR_VALUES.UPDATE_ALL,
  evaluatorTypes.BENEFIT.UPDATE_VALUE,
  undoredoTypes.SUITE.DELETING,
  cashflowTypes.EVALUATOR.UPDATE,
  cashflowTypes.EVALUATOR.CALCULATE,
  cashflowTypes.EVALUATOR.REVERT_EDIT,
  cashflowTypes.EVALUATOR.RESET,
  cashflowTypes.EVALUATOR.DELETE_ALL,
  cashflowTypes.COSTS.RESET,
  cashflowTypes.COSTS.NONRECURRING.UPDATE,
  cashflowTypes.COSTS.NONRECURRING.CALCULATE,
  cashflowTypes.COSTS.NONRECURRING.REVERT_EDIT,
  cashflowTypes.COSTS.RECURRING.UPDATE,
  cashflowTypes.COSTS.RECURRING.CALCULATE,
  cashflowTypes.COSTS.RECURRING.REVERT_EDIT,
  defaultSessionValuesTypes.CHANGE,
  defaultSessionValuesTypes.CHANGE_ALL,
  defaultSessionValuesTypes.PRISTINE_CHANGE,
  defaultSessionValuesTypes.UNLINK_INPUT,
  defaultSessionValuesTypes.LINK_INPUT,
  defaultSessionValuesTypes.DELETE.ALL,
  sessionDetailsTypes.DEAL_NAME.UPDATE,
  sessionDetailsTypes.CUSTOMER_NAME.UPDATE,
  sessionDetailsTypes.SUPPLIER_NAME.UPDATE,
  sessionDetailsTypes.MINIMUM_RETURN.UPDATE,
  sessionDetailsTypes.REVIEW_PERIOD.UPDATE,
  sessionDetailsTypes.INDUSTRY.UPDATE,
  sessionDetailsTypes.NOTES.UPDATE,
  sessionDetailsTypes.CURRENCY.UPDATE,
  sessionDetailsTypes.NUMBER_FORMAT.UPDATE,
  sessionDetailsTypes.DATE_FORMAT.UPDATE,
  sessionDetailsTypes.SESSION_MEDIA.UPDATE,
  sessionDetailsTypes.SESSION_MEDIA.UPDATE_POSITION,
  sessionDetailsTypes.DEFAULT_START_MONTH.UPDATE,
  recurringCostsTypes.RESET,
  recurringCostsTypes.ROW.REORDER,
  recurringCostsTypes.ROW.DYNAMIC_ADD,
  recurringCostsTypes.DESCRIPTION.CHANGE,
  recurringCostsTypes.GROWTH.CHANGE,
  recurringCostsTypes.RECURRENCE.CHANGE,
  recurringCostsTypes.START.CHANGE,
  recurringCostsTypes.END.CHANGE,
  recurringCostsTypes.COST.CHANGE,
  recurringCostsTypes.COST.CHANGE_START,
  recurringCostsTypes.TYPE.CHANGE,
  recurringCostsTypes.ROW.DELETE,
  recurringCostsTypes.ROW.DO_DELETE,
  recurringCostsTypes.CALCULATE,
  recurringCostsTypes.RESET,
  recurringCostsTypes.NOTES.CHANGE,
  nonRecurringCostsTypes.RESET,
  nonRecurringCostsTypes.ROW.REORDER,
  nonRecurringCostsTypes.ROW.DYNAMIC_ADD,
  nonRecurringCostsTypes.DESCRIPTION.CHANGE,
  nonRecurringCostsTypes.MONTH.CHANGE,
  nonRecurringCostsTypes.COST.CHANGE,
  nonRecurringCostsTypes.COST.CHANGE_START,
  nonRecurringCostsTypes.TYPE.CHANGE,
  nonRecurringCostsTypes.ROW.DELETE,
  nonRecurringCostsTypes.ROW.DO_DELETE,
  nonRecurringCostsTypes.CALCULATE,
  nonRecurringCostsTypes.RESET,
  nonRecurringCostsTypes.NOTES.CHANGE,
  businessCaseTypes.NPV.UPDATE,
  businessCaseTypes.IRR.UPDATE,
  businessCaseTypes.PAYBACK.UPDATE,
  businessCaseTypes.TENDER.UPDATE,
  businessCaseTypes.DELAY.UPDATE,
  businessCaseTypes.DONOTHING.UPDATE,
  tenderTypes.TENDER_PERIOD.UPDATE,
  tenderTypes.STAFF_ENGAGED.UPDATE,
  tenderTypes.ANNUAL_LOADED.UPDATE,
  tenderTypes.OTHER_COSTS.UPDATE,
  delayTypes.DELAY_PERIOD.UPDATE,
  delayTypes.DELETE_ALL,
  tenderTypes.DELETE_ALL,
];

export default undoableActions;
