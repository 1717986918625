/* eslint-disable no-confusing-arrow */
import { css } from 'styled-components';
import { setAlphaHsla } from '@sharkfinesse/sfl-lib';

const Styles = ({ font, colors, scale }) => ({
  base: () => css`
    font-size: 18px;
    width: 100%;
    max-width: 320px;
    outline: 0;
  `,
  button: () => css`
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0;
    padding-top: 1em;
    padding-bottom: 1em;
    transition: background-color 0.15s ease-in-out, opacity 0.15s ease-in-out;
    background-color: ${({ color }) =>
      color === 'primary'
        ? setAlphaHsla(0.7, colors.primary)
        : color === 'dark'
        ? 'hsla(0, 0%, 100%, 0.08)'
        : 'transparent'};
    color: #fff;
    &:hover {
      background-color: ${({ color }) =>
        color === 'primary'
          ? colors.primary
          : color === 'dark'
          ? 'hsla(0, 0%, 100%, 0.18)'
          : 'hsla(0, 0%, 100%, 0.16)'};
    }

    ${({ isLarge }) =>
      isLarge &&
      css`
        grid-column-end: span 2;
      `}

    &:focus {
      outline: 0;
    }

    &:active {
      opacity: 0.9;
    }
  `,
  styledIndicatorList: () => css`
    font-size: 0.75em;
    line-height: 1;
    opacity: 0.4;
    text-align: right;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.25em;
    min-height: 1em;
  `,
  styledExpression: () => css`
    margin-left: auto;
  `,
  styleScreen: () => css`
    font-size: 2em;
    min-height: 1.4em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
    border: none;
    background: none;
  `,
  styledDisplay: () => css`
    color: #fff;
    padding: 1.5em 0;
  `,
  styledPad: () => css`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
  `,
});

export default Styles;
