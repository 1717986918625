import { getSession, getSessionFile } from 'redux/firestore';
import { includes } from '@sharkfinesse/sfl-lib';

const fetchSession = async ({ id, account }) => {
  const [sessionFile, sessionFiles] = await Promise.all([
    getSessionFile({ id, account }),
    getSession({ id, account }),
  ]);

  const session = { session: sessionFile.data(), cashflow: {} };
  sessionFiles.forEach((doc) => {
    if (
      includes(doc.id, [
        'benefitsCashflow',
        'nonRecurringCostsCashflow',
        'recurringCostsCashflow',
        'editted_benefitsCashflow',
        'editted_nonRecurringCostsCashflow',
        'editted_recurringCostsCashflow',
      ])
    ) {
      return (session.cashflow = {
        ...session.cashflow,
        ...doc.data(),
      });
    }
    return (session[doc.id] = doc.data());
  });
  return session;
};

export default fetchSession;
