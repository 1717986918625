/* @flow */
/* eslint-disable max-len */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M77,19H29.8l-4.9-6.8c-0.6-0.8-1.5-1.2-2.4-1.2H3c-1.7,0-3,1.3-3,3v59c0,1.7,1.3,3,3,3h74c1.7,0,3-1.3,3-3
	V22C80,20.3,78.7,19,77,19z M77,73H3V33h74V73z M77,30H3V14h19.5l5.8,8H77V30z"
    />
    <rect x="32" y="44" fill="none" width="16" height="18" />
    <path
      fill="currentColor"
      d="M43,45v-1h-6v1h-5v2h1v13c0,1.1,0.9,2,2,2h10c1.1,0,2-0.9,2-2V47h1v-2H43z M45,60H35V47h10V60z
	"
    />
    <rect x="37" y="49" fill="inherit" width="2" height="9" />
    <rect x="41" y="49" fill="inherit" width="2" height="9" />
  </Icon>
);

export default memo(IconComponent);
