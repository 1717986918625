"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPreferredAccepts = exports.getAccepts = void 0;

var _fp = require("./fp");

const getAccepts = (0, _fp.pipe)((0, _fp.split)(/(?:;[^,]+)?(?:,\s*|$)/), (0, _fp.filter)(_fp.length));
exports.getAccepts = getAccepts;
const getPreferredAccepts = (0, _fp.useWith)((0, _fp.pipe)(_fp.intersection, _fp.head, (0, _fp.defaultTo)('*')), [_fp.idiot, getAccepts]);
exports.getPreferredAccepts = getPreferredAccepts;