export const turnOffMainAxsis = {
  name: 'preventOverflow',
  options: {
    mainAxis: false, // true by default
  },
};

export const fulllOffset = {
  name: 'offset',
  options: {
    offset: ({ reference }) => {
      return [0, -reference.width];
    },
  },
};

export const fullSameSize = {
  name: 'sameSize',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`;
    state.styles.popper.height = `${state.rects.reference.height - 1}px`;
  },
  effect: ({ state }) => {
    state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
    state.elements.popper.style.height = `${state.elements.reference.offsetHeight - 1}px`;
  },
};

export const insetOffset = {
  name: 'offset',
  options: {
    offset: ({ reference }) => {
      return [2, -(reference.width - 2)];
    },
  },
};

export const insetSameSize = {
  name: 'sameSize',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width - 4}px`;
    state.styles.popper.height = `${state.rects.reference.height - 5}px`;
  },
  effect: ({ state }) => {
    state.elements.popper.style.width = `${state.elements.reference.offsetWidth - 4}px`;
    state.elements.popper.style.height = `${state.elements.reference.offsetHeight - 5}px`;
  },
};
