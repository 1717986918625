import Avatar from 'components/avatar';

const createOption = ({ id, name, email, account, product }) => ({
  value: email,
  label: `${name} <${email}>`,
  id: id,
  name: name,
  account,
  product,
  chipLabel: (
    <>
      <Avatar
        uid={id}
        name={name}
        email={email}
        size="20"
        style={{ marginRight: '8px', marginLeft: '-6px' }}
      />

      {name || email}
    </>
  ),
});

export default createOption;
