import styled from 'styled-components';
import applyTheme from '../../styles/apply-theme';

const hero = applyTheme('hero');

export const Hero = hero('hero');
export const Background = hero('background');
export const Image = hero('image', styled.img);
export const Button = hero('button', styled.button);
export const Title = hero('title', styled.h2);
export const Lede = hero('lede');
export const Body = hero('body');
export const Content = hero('content');
