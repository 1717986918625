/* @flow */
/* eslint-disable max-len */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M15,4V3H9v1H4v2h1v13c0,1.1,0.9,2,2,2h10c1.1,0,2-0.9,2-2V6h1V4H15z M17,19H7V6h10V19z"
    />
    <rect fill="inherit" x="9" y="8" width="2" height="9" />
    <rect fill="inherit" x="13" y="8" width="2" height="9" />
  </Icon>
);

export default memo(IconComponent);
