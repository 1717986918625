/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
  </Icon>
);

export default memo(IconComponent);
