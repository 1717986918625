import React from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/performance';
import ReduxSagaFirebase from 'redux-saga-firebase';
import { getFirebaseEnvironment } from '../environment';
import { call, fork, all, put, select } from 'redux-saga/effects';
import {
  isNil,
  isEmpty,
  getOfflineEnabled,
  generateShortId,
  setOfflineEnabled,
  appSelectors,
} from '@sharkfinesse/sfl-lib';
import { actionCreators as appActionCreators } from 'redux/modules/app';
import ModalInfo from 'components/modal/modal-info';
import NiceModal from '@ebay/nice-modal-react';
import { FormattedMessage } from 'react-intl';
import LogRocket from 'logrocket';
const rsfApp = {
  rsf: undefined,
  firebaseApp: undefined,
  database: undefined,
  performance: undefined,
  storage: undefined,
  firestore: {},
};

export const initRsf = () => {
  if (!isNil(rsfApp.rsf)) {
    return rsfApp;
  } else {
    const environment = getFirebaseEnvironment();
    rsfApp.firebaseApp = firebase.initializeApp({
      apiKey: environment.firebase.apiKey,
      authDomain: environment.firebase.authDomain,
      databaseURL: environment.firebase.databaseURL,
      projectId: environment.firebase.projectId,
      storageBucket: environment.firebase.storageBucket,
      messagingSenderId: environment.firebase.messagingSenderId,
      clientId: generateShortId(),
      appId: environment.firebase.appId,
    });
    rsfApp.database = firebase.database();
    rsfApp.performance = firebase.performance();
    rsfApp.storage = firebase.storage();
    rsfApp.rsf = new ReduxSagaFirebase(rsfApp.firebaseApp);

    if (process.env.REACT_APP_MODE === 'development') {
      firebase.functions().useFunctionsEmulator('http://localhost:5001');
      //rsfApp.database.useEmulator('localhost', 9000);
    }
    return rsfApp;
  }
};

export function* initFirestore() {
  const isTerminated = yield select(appSelectors.getFirestoreTerminated);

  if (isEmpty(rsfApp.firestore) || isTerminated) {
    firebase.firestore().settings({
      experimentalForceLongPolling: true,
      merge: true,
    });
    rsfApp.firestore = rsfApp.firebaseApp.firestore();
    // if (process.env.REACT_APP_MODE === 'development')
    //   rsfApp.firestore.useEmulator('localhost', 8080);
    yield put(appActionCreators.updateFirestoreTeminated(false));
  }
  return rsfApp.firestore;
}

//firebase.firestore.setLogLevel('error'); // "debug" | "error" | "silent"

export function* enablePersistence() {
  try {
    const firestore = rsfApp.firestore;
    yield call([firestore, firestore.enablePersistence], { synchronizeTabs: true });
  } catch (error) {
    LogRocket.captureException(error);
    setOfflineEnabled(false);
    NiceModal.show(ModalInfo, {
      title: (
        <FormattedMessage
          id="app.state.offline.browser.incompatible.header"
          defaultMessage="Offline not available in this browser"
        />
      ),
      body: (
        <FormattedMessage
          id="app.state.offline.browser.incompatible.body"
          defaultMessage="Offline mode is unavailable in this browser. Please update or change your browser to use it."
        />
      ),
    });
  }
}

export function* disableNetwork() {
  const firestore = rsfApp.firestore;
  yield call([firestore, firestore.disableNetwork]);
}
export function* enableNetwork() {
  const firestore = rsfApp.firestore;
  yield call([firestore, firestore.enableNetwork]);
}

export function goOnlineTest() {
  const database = rsfApp.database;
  database.goOnline();
}

export function* goOnline() {
  const database = rsfApp.database;
  yield call([database, database.goOnline]);
}
export function* clearPersistence() {
  const firestore = rsfApp.firestore;
  yield put(appActionCreators.updateFirestoreTeminated(true));
  yield call([firestore, firestore.terminate]);
  yield call([firestore, firestore.clearPersistence]);
  rsfApp.rsf = undefined;
  rsfApp.firebaseApp = undefined;
  rsfApp.database = undefined;
  rsfApp.performance = undefined;
  rsfApp.storage = undefined;
  rsfApp.firestore = {};
}

export function* startFirestore() {
  const [offlineEnabled] = yield all([call(getOfflineEnabled), call(initFirestore)]);

  if (offlineEnabled) yield fork(enablePersistence);
}

export default rsfApp;
