import { useQuery } from 'react-query';
import { getCollectionData, getQuestionnaireSubmissions } from '@sharkfinesse/sfl-lib';

const getItems = async props => {
  const response = await getQuestionnaireSubmissions(props);
  const data = getCollectionData(response);
  return data;
};

const useQuestionnaireSubmissions = (props, config) => {
  return useQuery(['questionnaireSubmissions', props.sessionId], () => getItems(props), config);
};

export default useQuestionnaireSubmissions;
