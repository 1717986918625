import { indexOf, move, length, layoutSelectors } from '@sharkfinesse/sfl-lib';
import { put, select } from 'redux-saga/effects';
import { actionCreators as layoutActionCreators } from 'redux/modules/layout';

function* moveSuite(action) {
  const { suiteId, moveBy } = action.payload;
  const suiteOrder = yield select(layoutSelectors.getSuitesOrder);
  const currentPos = indexOf(suiteId, suiteOrder);
  const newPos = currentPos + moveBy;
  const listLength = length(suiteOrder) - 1;

  if (currentPos !== 0 || moveBy !== -1) {
    if (!(newPos > listLength)) {
      const newOrder = move(currentPos, currentPos + moveBy, suiteOrder);
      yield put(
        layoutActionCreators.updateOrderSuite({
          suiteOrder: newOrder,
        })
      );
    }
  }
}

export default moveSuite;
