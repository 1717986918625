// @flow weak
import React from 'react';
import FormControl from '../form-control/';
import FormLabel from '../form-label';
import Select from '../select';
import { TextFieldContainer, Column, Row } from './styled';

const SelectField = ({
  id,
  label,
  required,
  inverse,
  invalid,
  fullWidth = true,
  layout,
  labelContainerProps,
  labelAlign,
  labelProps,
  ...other
}) => {
  return (
    <FormControl invalid={invalid} inverse={inverse} required={required} fullWidth={fullWidth}>
      <TextFieldContainer
        as={layout === 'column' ? Column : Row}
        $fullWidth={fullWidth}
        {...labelContainerProps}
      >
        {label && (
          <FormLabel align={labelAlign} {...labelProps}>
            {label}
          </FormLabel>
        )}
        <Select inverse={inverse} {...other} />
      </TextFieldContainer>
    </FormControl>
  );
};

export default SelectField;
