/* @flow */
/* eslint-disable max-len */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3,17v2h6v-2H3 M3,5v2h10V5H3 M13,21v-2h8v-2h-8v-2h-2v6H13 M7,9v2H3v2h4v2h2V9H7 M21,13v-2H11v2H21 M15,9h2
	V7h4V5h-4V3h-2V9z"
    />
  </Icon>
);

export default memo(IconComponent);
