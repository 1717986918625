"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getURLBlob = exports.getURL = exports.getImageDimensions = exports.getFileExtension = void 0;

/* eslint-disable fp/no-mutation, fp/no-unused-expression, fp/no-let, fp/no-nil, fp/no-this, fp/no-mutating-methods  */
const getURL = async url => await fetch(url);

exports.getURL = getURL;

const getURLBlob = async url => {
  const image = await getURL(url);
  return image.blob();
};

exports.getURLBlob = getURLBlob;

const getImageDimensions = async image => {
  const _URL = window.URL || window.webkitURL;

  const img = new Image();

  const objectUrl = _URL.createObjectURL(image);

  img.src = objectUrl;
  let dimensions = null;
  await new Promise((resolve, reject) => {
    img.onload = function () {
      const width = this.width === 0 ? 150 : this.width;
      const height = this.height === 0 ? 150 : this.height;

      _URL.revokeObjectURL(objectUrl);

      dimensions = {
        width,
        height
      };
      resolve();
    };
  });
  return dimensions;
};

exports.getImageDimensions = getImageDimensions;

const getFileExtension = fileName => fileName.split('.').pop();

exports.getFileExtension = getFileExtension;