import { css } from 'styled-components';
import { setAlphaHsla } from '@sharkfinesse/sfl-lib';

const Slider = ({ font, colors, scale }) => ({
  railOuter: () => css`
    position: absolute;
    width: 100%;
    height: 14px;
    transform: translate(0%, -50%);
    border-radius: 0px;
    cursor: pointer;
  `,
  railInner: () => css`
    position: absolute;
    width: 100%;
    height: 2px;
    transform: translate(0%, -50%);
    border-radius: 0px;
    pointer-events: none;
    background-color: ${colors.uiLight};
  `,
  handle: ({ percent, disabled }) => css`
    left: ${percent}%;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    background-color: ${disabled ? setAlphaHsla(0.2, colors.primary) : colors.primary};
    cursor: ew-resize;
  `,
  track: ({ disabled }) => css`
    position: absolute;
    transform: translate(0%, -50%);
    height: 2px;
    z-index: 1;
    background-color: ${disabled ? setAlphaHsla(0.2, colors.primary) : colors.primary};
    border-radius: 0;
    cursor: pointer;
    left: ${({ source: { percent } }) => percent}%;
    width: ${({ target: { percent } }) => percent}%;
  `,
  tickLine: () => css`
    position: absolute;
    margin-top: 3px;
    width: 1px;
    height: 8px;
    background-color: rgb(200, 200, 200);
    left: ${({ tick: { percent } }) => percent}%;
  `,
  tickLabel: () => css`
    position: absolute;
    margin-top: 12px;
    font-size: 12px;
    text-align: center;
    margin-left: ${({ count }) => -(100 / count) / 2}%;
    width: ${({ count }) => 100 / count}%;
    left: ${({ tick: { percent } }) => percent}%;
  `,
});

export default Slider;
