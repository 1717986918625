import { css } from 'styled-components';
import { grid } from 'styled-components-grid';
import styledBreakpoint from 'styled-components-breakpoint';

const SuitesToolbar = ({ colors, scale, font }) => ({
  container: () => css`
    background: ${colors.uiLightest};
    border-bottom: 1px solid ${colors.uiLight};
    display: flex;
    flex-wrap: wrap;
  `,
  searchWrapper: () => css`
    ${grid.unit({
      size: { xs: 1 / 1 },
    })};
    padding: 6px 10px;
  `,
  viewWrapper: () => css`
    ${grid.unit({
      size: { xs: 1 / 1, xxl: 1 / 2 },
    })};
    padding-bottom: 6px;
  `,
  filtersWrapper: () => css`
    ${grid.unit({
      size: { xxs: 1 / 1, xxl: 1 / 2 },
    })};
    ${styledBreakpoint('xxs')`
        padding-bottom: 0px;
        padding-right: 0px;
      `};
    ${styledBreakpoint('xxl')`
        padding-bottom: 5px;
        padding-right: 5px
      `};
  `,
});

export default SuitesToolbar;
