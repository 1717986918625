import styled from 'styled-components';

import { animated } from 'react-spring';

export const Main = styled.div`
  position: relative;
  width: 100%;
  height: ${({ height }) => height};
  background: transparent;
  box-shadow: 0 0 0 1px #d9dadb;
  overflow: hidden;
`;

export const Fill = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #d9dadb;
  will-change: background;
`;
