import { css } from 'styled-components';

const Sort = ({ font, colors, scale }) => ({
  sortMenuContainer: () => css`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 4px;
  `,
  sortMenuWrapper: () => css`
    display: flex;
    color: ${font.color};
    font-size: 12px;
  `,
  referenceButton: ({ disabled }) => css`
    border: none;
    cursor: ${disabled ? 'auto' : 'pointer'};
    opacity: ${disabled ? '0.25' : '1'};
    display: flex;
    align-items: center;
    white-space: nowrap;
    ${!disabled &&
    css`
      &:hover {
        text-decoration: underline;
      }
    `}
  `,
});

export default Sort;
