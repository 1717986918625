import { placeholder, hsla, lightenHsla } from '@sharkfinesse/sfl-lib';
import { css } from 'styled-components';

const I = 100;
const J = 204;
const K = 33;
const L = 50;
const M = 18;
const N = 62;
const O = 72;
const P = 28;
const Q = 82;
const R = 94;
const baseSaturation = hsla(placeholder, I);
const baseAlpha = 1;

//colors
export const grays = hsla(0, 0);
export const uiBase = grays(0, baseAlpha);
export const blues = baseSaturation(J);
export const oranges = baseSaturation(K);
export const reds = baseSaturation(M);

export const gradients = [
  css`
    background: rgb(0, 192, 250);
    background: linear-gradient(111deg, rgba(0, 192, 250, 1) 18%, rgba(1, 94, 234, 1) 100%);
  `,
  css`
    background: rgb(123, 202, 255);
    background: linear-gradient(180deg, rgba(123, 202, 255, 1) 0%, rgba(13, 157, 255, 1) 100%);
  `,
  css`
    background: rgb(148, 148, 148);
    background: linear-gradient(180deg, rgba(148, 148, 148, 1) 0%, rgba(93, 93, 93, 1) 100%);
  `,
  css`
    background-image: radial-gradient(
      circle 465px at -15.1% -25%,
      rgba(17, 130, 193, 1) 0%,
      rgba(67, 166, 238, 1) 49%,
      rgba(126, 203, 244, 1) 90.2%
    );
  `,
  css`
    background-image: linear-gradient(
      109.6deg,
      rgba(48, 207, 208, 1) 11.2%,
      rgba(51, 8, 103, 1) 92.5%
    );
  `,
  css`
    background-color: #8bc6ec;
    background-image: linear-gradient(135deg, #8bc6ec 0%, #9599e2 100%);
  `,
  css`
    background-image: radial-gradient(
      circle farthest-corner at 22.4% 21.7%,
      rgba(4, 189, 228, 1) 0%,
      rgba(2, 83, 185, 1) 100.2%
    );
  `,
  css`
    background-image: radial-gradient(
      circle farthest-corner at 10% 20%,
      rgba(7, 121, 222, 1) 0%,
      rgba(20, 72, 140, 1) 90%
    );
  `,
  css`
    background: linear-gradient(to right, #0575e6, #00f260);
  `,
  css`
    background-image: linear-gradient(
      138deg,
      rgba(32, 201, 255, 1) 36.7%,
      rgba(0, 8, 187, 1) 84.4%,
      rgba(255, 255, 255, 1) 119.7%
    );
  `,
  css`
    background-image: linear-gradient(
      171.8deg,
      rgba(5, 111, 146, 1) 13.5%,
      rgba(6, 57, 84, 1) 78.6%
    );
  `,
  css`
    background-image: radial-gradient(
      circle farthest-corner at 50.3% 44.5%,
      rgba(116, 147, 179, 1) 0%,
      rgba(62, 83, 104, 1) 100.2%
    );
  `,
  css`
    background-image: linear-gradient(
      110.2deg,
      rgba(54, 197, 240, 1) 8.2%,
      rgba(74, 21, 75, 1) 92.4%
    );
  `,
  css`
    background: linear-gradient(0deg, rgba(30, 135, 240, 1) 0%, rgba(64, 188, 249, 1) 100%);
  `,
  css`
    background: linear-gradient(-90deg, rgba(249, 212, 35, 1) 0%, rgba(248, 54, 0, 1) 100%);
  `,
  css`
    background: linear-gradient(180deg, rgba(40, 242, 154, 1) 0%, rgba(9, 178, 228, 1) 100%);
  `,
];

const Colors = () => ({
  primaryHex: '#3db1ff',
  black: hsla(0, 0, 0, baseAlpha),
  white: hsla(0, 0, I, baseAlpha),
  primary: blues(N, baseAlpha),
  secondary: oranges(O, baseAlpha),
  warning: oranges(L, baseAlpha),
  invalid: reds(L, baseAlpha),
  uiBase: uiBase,
  uiDarkest: lightenHsla(P, uiBase),
  uiDark: lightenHsla(K, uiBase),
  ui: lightenHsla(L, uiBase),
  uiLight: lightenHsla(Q, uiBase),
  uiLightest: lightenHsla(R, uiBase),
  popperBox: hsla(0, 0, I, baseAlpha),
  gradientsCls: css`
    ${gradients.map((val, index) => `.gradient${index}{${val}}`)}
  `,
});

export default Colors;
