/* @flow */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Wrapper,
  Container,
  LeftContent,
  RightContent,
  IconWrapper,
  LabelWrapper,
  Label,
  SubLabel,
} from './styled';
import { useSpring } from 'react-spring';

const Notification = ({ icon, label, subLabel, rightContent, onClick, variant }) => {
  const animationStyles = useSpring({
    from: { transform: 'translate3d(0,8%,0)', opacity: '0' },
    to: {
      transform: 'translate3d(0,0%,0)',
      opacity: '1',
    },
  });
  return (
    <Container style={animationStyles}>
      <Wrapper onClick={onClick} $variant={variant}>
        <LeftContent>
          {icon && <IconWrapper>{icon}</IconWrapper>}
          <LabelWrapper>
            {label && (
              <Label className="label" $variant={variant}>
                {label}
              </Label>
            )}
            {subLabel && <SubLabel>{subLabel}</SubLabel>}
          </LabelWrapper>
        </LeftContent>
        {rightContent && <RightContent>{rightContent}</RightContent>}
      </Wrapper>
    </Container>
  );
};

export default Notification;
