// @flow
/* eslint-disable no-confusing-arrow, no-nested-ternary  */
import React from 'react';
import Switch from 'react-switch';
import { withTheme } from 'styled-components';
import { SwitchContainer, LabelWrapper } from './styled';

type Props = {
  label?: String | Element<any>,
  onChange: Function,
  checked: Boolean,
  theme: Object,
  labelPosition: String,
  labelProps: Object,
};

const SwitchComp = ({
  label,
  checked,
  onChange,
  theme,
  labelPosition = 'after',
  labelProps,
  labelSize,
  ...props
}: Props) => (
  <SwitchContainer>
    {labelPosition === 'before' && label && (
      <LabelWrapper labelSize={labelSize} labelPosition={labelPosition} {...labelProps}>
        {label}
      </LabelWrapper>
    )}
    <Switch
      onChange={onChange}
      checked={checked}
      onColor={theme.toggleSwitch.onColor}
      offColor={theme.toggleSwitch.offColor}
      onHandleColor={theme.toggleSwitch.onHandleColor}
      offHandleColor={theme.toggleSwitch.offHandleColor}
      handleDiameter={16}
      uncheckedIcon={false}
      checkedIcon={false}
      activeBoxShadow={theme.toggleSwitch.activeBoxShadow}
      height={18}
      width={32}
      {...props}
    />
    {labelPosition === 'after' && label && (
      <LabelWrapper labelSize={labelSize} labelPosition={labelPosition}>
        {label}
      </LabelWrapper>
    )}
  </SwitchContainer>
);

export default withTheme(SwitchComp);
