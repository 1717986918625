// @flow
import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import { Switch, LayoutColumns, Column, RichText, css } from '@sharkfinesse/sfl-ui';
import { ImageWrapper, ContentWrapper, SwitchWrapper } from './styled';
import Image from './image';

type Props = {
  updated: boolean,
  history: Object,
  intl: Object,
};

const Offline = ({ updated, history, intl, isMobile, enabled, setEnabled }: Props) => (
  <LayoutColumns>
    <Column size={{ md: 7 / 12 }}>
      <ContentWrapper>
        <RichText>
          <ReactMarkdown linkTarget="_blank">
            {intl.formatMessage({
              id: 'modal.offline.body',
              defaultMessage: `### Great news, you can now take your sessions offline.\n\nBy enabling offline we will will make your most recent and selected sessions available for those times you dont have an internet connection. We do this by storing them in the browser so if you are using a public computer it is not recommended. Don't worry you can change this setting at anytime in preferences.\n\nWhile this is a great feature, it's only intended for short periods so please make sure you dont stay offline for too long.`,
            })}
          </ReactMarkdown>
        </RichText>

        <SwitchWrapper>
          <Switch
            label={
              enabled ? (
                <FormattedMessage
                  id="modal.offline.enable"
                  defaultMessage="Enable offline (browser will reload)"
                />
              ) : (
                <FormattedMessage id="modal.offline.disable" defaultMessage="Disable offline" />
              )
            }
            checked={enabled}
            onChange={() => {
              const toggled = !enabled;
              setEnabled(toggled);
            }}
          />
        </SwitchWrapper>
      </ContentWrapper>
    </Column>
    <Column
      size={{ md: 5 / 12 }}
      styles={css`
        display: flex;
        flex-direction: column-reverse;
      `}
    >
      <ImageWrapper>
        <Image height="100%" width="100%" />
      </ImageWrapper>
    </Column>
  </LayoutColumns>
);

export default injectIntl(Offline);
