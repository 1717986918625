"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

const getTemplates = state => state.templates;

const getAll = state => state.templates.all || [];

const getFetching = state => state.templates.fetching;

const selectors = {
  getTemplates,
  getAll,
  getFetching
};
var _default = selectors;
exports.default = _default;