// @flow
import React, { useState } from 'react';
import { useSpring } from 'react-spring';
import useMeasure from '../utils/useMeasure';
import { Main, Fill } from './styled';
import * as easings from 'd3-ease';

type Props = {
  progress: string | number,
  springRef: any,
  height: String,
};

const ProgressBar = ({ progress, springRef, height = '2px' }: Props): React.Element<any> => {
  const [bind, { width }] = useMeasure();

  const [immediate, setImmediate] = useState(true);
  const FullPercent = 100;
  const spring = useSpring({
    width: (width * progress) / FullPercent,
    ref: springRef,
    immediate,
    config: {
      duration: 400,
      easing: easings.easeCubic,
    },
    onRest: () => {
      if (immediate) setImmediate(false);
    },
  });

  return (
    <Main height={height} {...bind}>
      <Fill style={spring} />
    </Main>
  );
};

export default React.memo(ProgressBar);
