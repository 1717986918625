import { css } from 'styled-components';
import { setAlphaHsla } from '@sharkfinesse/sfl-lib';

const MainMenu = ({ font, colors, scale }) => ({
  aside: ({ open, float }) => css`
    height: 100%;
    background: ${colors.white};
    width: 260px;
    border-top: 1px solid ${colors.uiLight};
    border-right: 1px solid ${colors.uiLight};
    position: ${float ? 'absolute' : 'relative'};
    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    transform: translateX(${float ? (open ? 0 : '-260px') : 0});
    z-index: 3;
  `,
  menuContainer: () => css`
    height: 100%;
    padding: 12px;
  `,
  menuItemWrapper: () => css`
    background: none;
    color: ${font.color};
    width: 100%;
    padding: 10px 14px;
    border-radius: 4px;
    font-weight: ${font.weightNormal};
    display: flex;
    align-items: center;
    text-decoration: none;
    &:hover {
      background: ${setAlphaHsla(0.06, colors.primary)};
    }
    &.active {
      cursor: pointer;
      background: ${setAlphaHsla(0.2, colors.primary)};
      font-weight: ${font.weightSemiBold};
    }
  `,
  menuLabel: () => css`
    margin-left: 8px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 16px;
  `,
  heading: () => css`
    font-size: 12px;
    margin: 14px 14px 6px 14px;
    font-weight: 600;
  `,
  icon: () => css`
    display: flex;
    min-width: 24px;
  `,
});

export default MainMenu;
