import firebase from 'firebase/app';
import { getFirebaseEnvironment } from 'environment';
const environment = getFirebaseEnvironment();
import LogRocket from 'logrocket';

const fetchAuth = async ({ url, config }, download) => {
  const token = await firebase.auth().currentUser.getIdToken(true);

  const response = await fetch(`${environment.functionsRoot}${url}`, {
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    ...config,
  });

  const result = download ? await response : await response.json();

  if (!response.ok) {
    LogRocket.captureException(result.error);
    throw new Error(result.error);
  }
  return result;
};

export default fetchAuth;
