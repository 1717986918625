import React from 'react';
import { fork, take, all, put, call, select, cancelled } from 'redux-saga/effects';
import { buffers } from 'redux-saga';
import {
  reduce,
  mapObjIndexed,
  find,
  isEmpty,
  propEq,
  deviceSelectors,
  cashflowsSelectors,
  trackingSelectors,
  evaluatorSelectors,
  layoutSelectors,
  includes,
  sessionSelectors,
  isNil,
  routerSelectors,
  omit,
} from '@sharkfinesse/sfl-lib';
import { actionCreators as sessionActionCreators } from 'redux/modules/session';
import rsfApp from 'redux/rsf';
import ModalInfo from 'components/modal/modal-info';
import NiceModal from '@ebay/nice-modal-react';
import { errorModal } from 'utils';
import { FormattedMessage } from 'react-intl';
import { actionCreators as appActionCreators } from 'redux/modules/app';
import { getSessionRef, getFileRef, sessionDoc_Set } from '../persistence/backend/session.js';
import { goToCurrent } from 'redux/sagas/benefit/goToCurrent';
import { initialState as questionnaireInitialState } from 'redux/modules/questionnaire';
import channel from 'redux/sagas/firestore/channel';
import { history } from 'index';
import { initialState as detailsInitialState } from 'redux/modules/session-details';
import { initialState as businessCaseInitialState } from 'redux/modules/business-case';
import { initialState as benefitsInitialState } from 'redux/modules/evaluator';
import { initialState as nonRecurringCostsInitialState } from 'redux/modules/non-recurring-costs';
import { initialState as recurringCostsInitialState } from 'redux/modules/recurring-costs';
import { initialState as businessPreparationInitialState } from 'redux/modules/business-preparation';
import { initialState as defaultSessionValuesInitialState } from 'redux/modules/default-session-values';
import { initialState as tenderInitialState } from 'redux/modules/tender';
import { initialState as doNothingInitialState } from 'redux/modules/do-nothing';
import { initialState as delayInitialState } from 'redux/modules/delay';
import { initialState as layoutInitialState } from 'redux/modules/layout';
import { initialState as suiteListInitialState } from 'redux/modules/suite-list';
import { initialState as pluginsInitialState } from 'redux/modules/plugins/plugins-root';
import LogRocket from 'logrocket';

const actionList = {
  details: detailsInitialState,
  layout: layoutInitialState,
  suiteList: suiteListInitialState,
  businessCase: businessCaseInitialState,
  businessPreparation: businessPreparationInitialState,
  benefits: benefitsInitialState,
  nonRecurringCosts: nonRecurringCostsInitialState,
  recurringCosts: recurringCostsInitialState,
  defaultSessionValues: defaultSessionValuesInitialState,
  benefitsCashflow: null,
  editted_benefitsCashflow: null,
  editted_nonRecurringCostsCashflow: null,
  editted_recurringCostsCashflow: null,
  nonRecurringCostsCashflow: null,
  recurringCostsCashflow: null,
  tender: tenderInitialState,
  delay: delayInitialState,
  doNothing: doNothingInitialState,
  plugins: pluginsInitialState,
  questionnaire: questionnaireInitialState,
};

export function* doSync(action) {
  const [cashflow, benefitId, session, pathname, isOnline, isOnlinePending] = yield all([
    select(cashflowsSelectors.getCashflows),
    select(evaluatorSelectors.getCurrent),
    select(sessionSelectors.getSession),
    select(routerSelectors.getPathname),
    select(deviceSelectors.isOnline),
    select(deviceSelectors.isOnlinePending),
  ]);

  const newSession = reduce(
    (obj, document) => {
      const doc = document?.id ? document : document.doc;
      const id = doc.id;
      const data = doc.data();
      if (isOnline && !isOnlinePending && doc?.metadata?.hasPendingWrites) return obj;

      if (
        includes(id, [
          'benefitsCashflow',
          'editted_benefitsCashflow',
          'editted_nonRecurringCostsCashflow',
          'editted_recurringCostsCashflow',
          'nonRecurringCostsCashflow',
          'recurringCostsCashflow',
        ])
      ) {
        return {
          ...obj,
          cashflow: {
            ...obj.cashflow,
            ...data,
          },
        };
      } else {
        if (actionList[id]) {
          return {
            ...obj,
            [id]: { ...actionList[id], ...data },
          };
        }
        return {
          ...obj,
          [id]: data,
        };
      }
    },
    { cashflow }
  )(action.payload);

  const state = {
    session: {
      present: { ...session, ...newSession },
    },
  };
  const eNo = layoutSelectors.getBenefitENo(state, { benefitId });

  if (pathname.indexOf('/benefits/b/') !== -1) {
    if (!isEmpty(benefitId) && isEmpty(eNo)) {
      if (!isNil(newSession?.benefits?.current) && newSession.benefits.current !== benefitId) {
        yield call(goToCurrent, { id: newSession.benefits.current });
      }
    }
  }

  yield put(sessionActionCreators.syncUpdate(newSession));
}

const onError = error => {
  LogRocket.captureException(error);
  history.push('/');
  NiceModal.show(ModalInfo, {
    title: (
      <FormattedMessage
        id="session.denied.modal.title"
        defaultMessage="Access to session has been lost"
      />
    ),
    body: (
      <FormattedMessage
        id="session.denied.modal.body"
        defaultMessage="You no longer have access to this session. This can happen for several reasons. If this was a shared session it could mean the session owner has revoked access or deleted it. Alternatively your could have been disconnected from the server in which case you should try loading the session again."
      />
    ),
  });
};

function* syncCollection({ collectionPath, fileRef }) {
  const collectionChannel = channel(
    rsfApp.firestore.collection(collectionPath),
    buffers.none(),
    {
      includeMetadataChanges: true,
    },
    onError
  );
  let init = true;
  try {
    while (true) {
      const state = yield take(collectionChannel);
      const docs = state.docChanges();

      const [isOnline, isOnlinePending] = yield all([
        select(deviceSelectors.isOnline),
        select(deviceSelectors.isOnlinePending),
      ]);

      const trackingActive = yield select(trackingSelectors.getActive);

      if (
        isOnline &&
        !isOnlinePending &&
        !isEmpty(trackingActive) &&
        isEmpty(docs) &&
        !state.metadata.hasPendingWrites
      ) {
        yield put(sessionActionCreators.doSync(state.docs));
      } else {
        if (init) {
          //check if this session has all required documents and create them if not
          const firestore = rsfApp.firestore;

          const batch = yield call([firestore, firestore.batch]);
          yield all(
            mapObjIndexed((initialState, docId) => {
              const docExists = find(propEq('id', docId))(state.docs);
              return docExists
                ? null
                : sessionDoc_Set(
                    docId,
                    docId === 'questionnaire' ? questionnaireInitialState : {},
                    fileRef,
                    batch
                  );
            }, actionList)
          );
          if (isOnline && !isOnlinePending) yield call([batch, batch.commit]);
          else batch.commit();
          yield call(doSync, { payload: docs });
        } else if (isOnline && !isOnlinePending) {
          yield put(sessionActionCreators.doSync(docs));
        } else if (!isOnline && !state.metadata.hasPendingWrites) {
          yield put(sessionActionCreators.doSync(docs));
        } else if (isOnlinePending) {
          yield put(sessionActionCreators.doSync(docs));
        }

        yield put(sessionActionCreators.synced());
        init = false;
      }
    }
  } catch (error) {
    console.log('Session collection  sync error', error);
    errorModal({ error: error.message });
    yield all([
      put(appActionCreators.updateSessionFetching(false)),
      put(appActionCreators.updateLoadingActive(false)),
      call([history, history.push], { pathname: '/' }),
    ]);
  } finally {
    if (yield cancelled()) collectionChannel.close();
  }
}

function* sync({ sessionId, account }) {
  try {
    const trace = rsfApp.performance.trace('SessionSync');
    const collectionPath = `/accounts/${account}/sessions/${sessionId}/file/`;
    const isOnline = yield select(deviceSelectors.isOnline);
    const sessionRef = getSessionRef({ account, id: sessionId });
    const fileRef = getFileRef({ sessionRef });
    let exists = true;
    yield call([trace, trace.start]);

    try {
      yield call(rsfApp.rsf.firestore.getCollection, collectionPath);
    } catch (error) {
      errorModal({ error: error.message });
      exists = false;
    }

    if (exists) {
      const session = yield call(
        rsfApp.rsf.firestore.getDocument,
        `/accounts/${account}/sessions/${sessionId}/`
      );
      yield put(
        sessionActionCreators.sync({
          ...omit(
            [
              'sharedTo',
              'sharedBy',
              'archived',
              'templateFor',
              'folder',
              'editors',
              'ownerName',
              'ownerEmail',
              'updatedBy',
              'updatedByName',
              'updatedByEmail',
              'updateAction',
              'backup',
            ],
            session.data()
          ),
          loaded: true,
        })
      );
      yield fork(syncCollection, { collectionPath, fileRef });
    } else {
      NiceModal.show(ModalInfo, {
        title: (
          <FormattedMessage
            id="session.unavailable.window.title"
            defaultMessage="Session is unavailable"
          />
        ),
        body: isOnline ? (
          <FormattedMessage
            id="session.unavailable.window.body.online"
            defaultMessage="Please make sure you have the right permissions for this session and are logged in with the correct user credentials."
          />
        ) : (
          <FormattedMessage
            id="session.unavailable.window.body.offline"
            defaultMessage="You are currently offline. Please go online to acccess this session and ensure you are logged in with the correct user credentials."
          />
        ),
      });
      yield all([
        put(appActionCreators.updateSessionFetching(false)),
        put(appActionCreators.updateLoadingActive(false)),
        call([history, history.push], { pathname: '/' }),
        call([trace, trace.stop]),
      ]);
    }
  } catch (error) {
    errorModal({ error: error.message });
    yield all([
      put(appActionCreators.updateSessionFetching(false)),
      put(appActionCreators.updateLoadingActive(false)),
      call([history, history.push], { pathname: '/' }),
    ]);
  }
}
export default sync;
