/* @flow */
import {
  assoc,
  clamp,
  createReducer,
  minReviewPeriod,
  maxReviewPeriod,
  normalizeTime,
  simpleAction,
  simpleReducer,
  sessionDetailsInitialState,
  simpleActionMeta,
} from '@sharkfinesse/sfl-lib';
const namespace = (name: string): string => `@@session.details/${name}`;

//Actions
export const types = {
  DEAL_NAME: { UPDATE: namespace('DEAL_NAME.UPDATE') },
  CUSTOMER_NAME: { UPDATE: namespace('CUSTOMER_NAME.UPDATE') },
  SUPPLIER_NAME: { UPDATE: namespace('SUPPLIER_NAME.UPDATE') },
  MINIMUM_RETURN: { UPDATE: namespace('MINIMUM_RETURN.UPDATE') },
  REVIEW_PERIOD: { UPDATE: namespace('REVIEW_PERIOD.UPDATE') },
  INDUSTRY: { UPDATE: namespace('INDUSTRY.UPDATE') },
  NOTES: { UPDATE: namespace('NOTES.UPDATE') },
  CURRENCY: { UPDATE: namespace('CURRENCY.UPDATE') },
  NUMBER_FORMAT: { UPDATE: namespace('NUMBER_FORMAT.UPDATE') },
  DATE_FORMAT: { UPDATE: namespace('DATE_FORMAT.UPDATE') },
  MONTHLY_BENEFIT_CHART_ORDER: { UPDATE: namespace('MONTHLY_BENEFIT_CHART_ORDER.UPDATE') },
  ONE_TIME_BENEFIT_CHART_ORDER: { UPDATE: namespace('ONE_TIME_BENEFIT_CHART_ORDER.UPDATE') },
  TOTAL_BENEFIT_CHART_ORDER: { UPDATE: namespace('TOTAL_BENEFIT_CHART_ORDER.UPDATE') },
  RECURRING_COSTS_CHART_ORDER: { UPDATE: namespace('RECURRING_COSTS_CHART_ORDER.UPDATE') },
  NON_RECURRING_COSTS_CHART_ORDER: { UPDATE: namespace('NON_RECURRING_COSTS_CHART_ORDER.UPDATE') },
  SESSION_MEDIA: {
    UPDATE: namespace('SESSION_MEDIA.UPDATE'),
    UPDATE_POSITION: namespace('SESSION_MEDIA.UPDATE_POSITION'),
  },
  DEFAULT_START_MONTH: {
    UPDATE: namespace('DEFAULT_START_MONTH.UPDATE'),
    COMPLETE: namespace('DEFAULT_START_MONTH.COMPLETE'),
  },
};

//Reducer
export const initialState = sessionDetailsInitialState;

const reduceDealName = simpleReducer('dealName');
const reduceCustomerName = simpleReducer('customerName');
const reduceSupplierName = simpleReducer('supplierName');
const reduceMinimumReturn = simpleReducer('minimumReturn');
const reduceIndustry = simpleReducer('industry');
const reduceNotes = simpleReducer('notes');
const reduceSessionMedia = simpleReducer('media');
const reduceSessionMediaPosition = (state, action) => ({
  ...state,
  media: {
    ...state.media,
    position: action.payload,
  },
});

const reduceReviewPeriod = (state, { payload: { current } }) => ({
  ...state,
  reviewPeriod:
    current === '' ? '' : normalizeTime('months', clamp(minReviewPeriod, maxReviewPeriod, current)),
});

const reduceCurrency = (state, action) => {
  return {
    ...state,
    currency: {
      ...state.currency,
      ...(action.payload.value === 'custom' ? assoc('value', '', action.payload) : action.payload),
    },
  };
};

const reduceNumberFormat = (state, action) => ({
  ...state,
  numberFormat: action.payload.value,
});

const reduceDateFormat = (state, action) => ({
  ...state,
  dateFormat: action.payload.value,
});

const reduceMonthlyBenefitChartOrder = (state, action) => ({
  ...state,
  monthlyBenefitChartOrder: action.payload,
});

const reduceOneTimeBenefitChartOrder = (state, action) => ({
  ...state,
  oneTimeBenefitChartOrder: action.payload,
});

const reduceTotalBenefitChartOrder = (state, action) => ({
  ...state,
  totalBenefitChartOrder: action.payload,
});

const reduceRecurringCostsChartOrder = (state, action) => ({
  ...state,
  recurringCostsChartOrder: action.payload,
});

const reduceNonRecurringCostsChartOrder = (state, action) => ({
  ...state,
  nonRecurringCostsChartOrder: action.payload,
});

const reduceDefaultStartMonth = (state, { payload: { defaultStartMonth } }) => ({
  ...state,
  defaultStartMonth: normalizeTime(
    'months',
    clamp(minReviewPeriod, maxReviewPeriod, defaultStartMonth)
  ),
});

export default createReducer(
  {
    [types.DEAL_NAME.UPDATE]: reduceDealName,
    [types.CUSTOMER_NAME.UPDATE]: reduceCustomerName,
    [types.SUPPLIER_NAME.UPDATE]: reduceSupplierName,
    [types.MINIMUM_RETURN.UPDATE]: reduceMinimumReturn,
    [types.REVIEW_PERIOD.UPDATE]: reduceReviewPeriod,
    [types.INDUSTRY.UPDATE]: reduceIndustry,
    [types.NOTES.UPDATE]: reduceNotes,
    [types.CURRENCY.UPDATE]: reduceCurrency,
    [types.NUMBER_FORMAT.UPDATE]: reduceNumberFormat,
    [types.DATE_FORMAT.UPDATE]: reduceDateFormat,
    [types.MONTHLY_BENEFIT_CHART_ORDER.UPDATE]: reduceMonthlyBenefitChartOrder,
    [types.ONE_TIME_BENEFIT_CHART_ORDER.UPDATE]: reduceOneTimeBenefitChartOrder,
    [types.TOTAL_BENEFIT_CHART_ORDER.UPDATE]: reduceTotalBenefitChartOrder,
    [types.RECURRING_COSTS_CHART_ORDER.UPDATE]: reduceRecurringCostsChartOrder,
    [types.NON_RECURRING_COSTS_CHART_ORDER.UPDATE]: reduceNonRecurringCostsChartOrder,
    [types.SESSION_MEDIA.UPDATE]: reduceSessionMedia,
    [types.SESSION_MEDIA.UPDATE_POSITION]: reduceSessionMediaPosition,
    [types.DEFAULT_START_MONTH.UPDATE]: reduceDefaultStartMonth,
  },
  initialState
);

//Action Creators
const updateDealName = simpleAction(types.DEAL_NAME.UPDATE);
const updateCustomerName = simpleAction(types.CUSTOMER_NAME.UPDATE);
const updateSupplierName = simpleAction(types.SUPPLIER_NAME.UPDATE);
const updateMinimumReturn = simpleAction(types.MINIMUM_RETURN.UPDATE);
const updateIndustry = simpleAction(types.INDUSTRY.UPDATE);
const updateNotes = simpleAction(types.NOTES.UPDATE);
const updateCurrency = simpleAction(types.CURRENCY.UPDATE);
const updateNumberFormat = simpleAction(types.NUMBER_FORMAT.UPDATE);
const updateDateFormat = simpleAction(types.DATE_FORMAT.UPDATE);
const updateMonthlyBenefitChartOrder = simpleAction(types.MONTHLY_BENEFIT_CHART_ORDER.UPDATE);
const updateOneTimeBenefitChartOrder = simpleAction(types.ONE_TIME_BENEFIT_CHART_ORDER.UPDATE);
const updateTotalBenefitChartOrder = simpleAction(types.TOTAL_BENEFIT_CHART_ORDER.UPDATE);
const updateRecurringCostsChartOrder = simpleAction(types.RECURRING_COSTS_CHART_ORDER.UPDATE);
const updateNonRecurringCostsChartOrder = simpleAction(
  types.NON_RECURRING_COSTS_CHART_ORDER.UPDATE
);

const updateReviewPeriod = simpleAction(types.REVIEW_PERIOD.UPDATE);

const updateSessionMedia = simpleAction(types.SESSION_MEDIA.UPDATE);
const updateSessionMediaPosition = simpleAction(types.SESSION_MEDIA.UPDATE_POSITION);
const updateDefaultStartMonth = simpleActionMeta(types.DEFAULT_START_MONTH.UPDATE);

const updateDefaultStartMonthComplete = simpleAction(types.DEFAULT_START_MONTH.COMPLETE);

export const actionCreators = {
  updateDealName,
  updateCustomerName,
  updateSupplierName,
  updateMinimumReturn,
  updateReviewPeriod,
  updateIndustry,
  updateNotes,
  updateCurrency,
  updateNumberFormat,
  updateDateFormat,
  updateMonthlyBenefitChartOrder,
  updateOneTimeBenefitChartOrder,
  updateTotalBenefitChartOrder,
  updateRecurringCostsChartOrder,
  updateNonRecurringCostsChartOrder,
  updateSessionMedia,
  updateSessionMediaPosition,
  updateDefaultStartMonth,
  updateDefaultStartMonthComplete,
};
