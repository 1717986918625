import { css } from 'styled-components';

const TitlePane = ({ font, colors, scale, device: { min_sm, min_md, min_xs } }) => ({
  container: ({ visibility }) => css`
    display: flex;
    flex-direction: column;
    padding: 7px;
    ${visibility === 'hidden'
      ? css`
          height: 0;
          padding: 0;
          visibility: hidden;
          width: 0;
        `
      : css`
          width: 100%;
          @media ${min_sm} {
            padding: 10px;
          }
          @media ${min_md} {
            padding: 20px;
          }
        `}
  `,
  headerWrapper: () => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 12px;
  `,
  header: () => css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  openCloseLink: () => css`
    display: flex;
    font-size: 12px;
    color: ${font.color};
    align-items: center;
    align-self: flex-end;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  `,
  title: () => css`
    color: ${font.color};
    font-weight: ${font.weightSemiBold};
    font-size: 16px;
    line-height: 16px;
    @media ${min_xs} {
      font-size: 18px;
      line-height: 18px;
    }
    @media ${min_sm} {
      font-size: 20px;
      line-height: 20px;
    }
    @media ${min_md} {
      font-size: 24px;
      line-height: 24px;
    }
  `,
  body: () => css``,
  viewMoreContainer: () => css`
    line-height: 18px;
    align-self: flex-end;
    margin-left: 10px;
  `,
  toolbar: () => css`
    display: flex;
    flex-direction: row;
    margin-left: 16px;
  `,
});

export default TitlePane;
