/* @flow */
/* eslint-disable max-len */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M13.4,16.7l-2,2c-1.7,1.7-4.4,1.7-6.1,0c-1.7-1.7-1.7-4.4,0-6.1l2-2l1.2,1.2l-2,2c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0l2-2
	L13.4,16.7z M11.8,8.5l2-2c1-1,2.7-1,3.7,0c1,1,1,2.7,0,3.7l-2,2l1.2,1.2l2-2c1.7-1.7,1.7-4.4,0-6.1c-1.7-1.7-4.4-1.7-6.1,0l-2,2
	L11.8,8.5z M4.3,4.3L4.3,4.3c-0.3,0.3-0.3,0.7,0,1l2.5,2.5c0.3,0.3,0.7,0.3,1,0l0,0c0.3-0.3,0.3-0.7,0-1L5.3,4.3C5,4,4.6,4,4.3,4.3z
	 M8.4,2.6L8.4,2.6C8.1,2.7,7.9,3.1,8,3.5L8.9,7C9,7.4,9.4,7.6,9.7,7.5l0,0c0.4-0.1,0.6-0.5,0.5-0.9L9.3,3.1C9.2,2.8,8.8,2.5,8.4,2.6
	z M2.7,8.4L2.7,8.4C2.6,8.8,2.8,9.2,3.2,9.3l3.5,0.9c0.4,0.1,0.8-0.1,0.9-0.5l0,0C7.6,9.3,7.4,9,7,8.9L3.5,7.9
	C3.2,7.8,2.8,8,2.7,8.4z M19.8,19.8L19.8,19.8c0.3-0.3,0.3-0.7,0-1l-2.5-2.5c-0.3-0.3-0.7-0.3-1,0h0c-0.3,0.3-0.3,0.7,0,1l2.5,2.5
	C19.1,20.1,19.5,20.1,19.8,19.8z M15.7,21.4L15.7,21.4c0.4-0.1,0.6-0.5,0.5-0.9l-0.9-3.5c-0.1-0.4-0.5-0.6-0.9-0.5h0
	c-0.4,0.1-0.6,0.5-0.5,0.9l0.9,3.5C14.9,21.3,15.3,21.5,15.7,21.4z M21.5,15.7L21.5,15.7c0.1-0.4-0.1-0.8-0.5-0.9l-3.5-0.9
	c-0.4-0.1-0.8,0.1-0.9,0.5v0c-0.1,0.4,0.1,0.8,0.5,0.9l3.5,0.9C21,16.3,21.4,16,21.5,15.7z"
    />
  </Icon>
);

IconComponent = memo(IconComponent);

export default IconComponent;
