/* @flow */
import {
  simpleAction,
  createReducer,
  simpleReducer,
  loginQuestionnaireInitialState,
} from '@sharkfinesse/sfl-lib';

const namespace = (name: string): string => `@@login.questionnaire/${name}`;

// types
export const types = {
  QUESTIONNAIRE: {
    AUTHENTICATED: namespace('QUESTIONNAIRE.AUTHENTICATED'),
    AUTHENTICATE: namespace('QUESTIONNAIRE.AUTHENTICATE'),
  },
};

const initialState = loginQuestionnaireInitialState;

const reduceSessionMedia = simpleReducer('authenticated');

export default createReducer(
  {
    [types.QUESTIONNAIRE.AUTHENTICATED]: reduceSessionMedia,
  },
  initialState
);

//Action Creators
const authenticate = simpleAction(types.QUESTIONNAIRE.AUTHENTICATE);
const updateAuthenticated = simpleAction(types.QUESTIONNAIRE.AUTHENTICATED);

export const actionCreators = {
  authenticate,
  updateAuthenticated,
};
