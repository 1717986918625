/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M0.3,15.5l10,13.3c0.5,0.6,1.3,0.7,1.9,0.2c0.6-0.4,0.7-1.3,0.3-1.8L3,14.7l9.4-12.5
      c0.5-0.6,0.3-1.4-0.2-1.9S10.7,0,10.3,0.5c0,0,0,0,0,0l-10,13.3C-0.1,14.4-0.1,15,0.3,15.5z"
    />
  </Icon>
);

export default memo(IconComponent);
