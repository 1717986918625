"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reselect = require("reselect");

/* eslint-disable no-unused-vars  */
const getUser = state => state.user;

const getProduct = state => state.user.product;

const getAccount = state => state.user.account;

const getLastChanged = state => state.user.lastChanged;

const getEmail = state => state.user.email;

const getId = state => state.user.id;

const getName = state => state.user.name;

const getPrivacyId = state => state.user.privacyId;

const getTermsId = state => state.user.termsId;

const getEncryptedUserId = state => state.user.encryptedUserId;

const selectors = {
  getUser,
  getProduct,
  getAccount,
  getLastChanged,
  getEmail,
  getId,
  getName,
  getPrivacyId,
  getTermsId,
  getEncryptedUserId
};
var _default = selectors;
exports.default = _default;