import { MessageModal } from '@sharkfinesse/sfl-ui';
import { intlSelectors } from '@sharkfinesse/sfl-lib';
import { useCallback } from 'react';
import UserModal from 'containers/user-modal';
import { useModal } from '@ebay/nice-modal-react';
import { useSendEditorInvite } from 'redux/firestore/hooks';
import { errorModal } from 'utils';
import { toast } from 'react-toastify';
import { notifyConfig } from 'components/toast/config';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import EditorList from 'containers/editor-list';

const useInviteEditors = ({ sessionId, ...other }) => {
  const userModal = useModal(UserModal);
  const messageModal = useModal(MessageModal);
  const locale = useSelector(intlSelectors.getLocale);

  const { mutate } = useSendEditorInvite({
    onSuccess: ({ length }) => {
      messageModal.hide();
      toast(
        () => (
          <FormattedMessage
            id="share.invite.toast"
            defaultMessage="{numberSharedWith,plural,=0{Not Shared}one{# person invited to edit}other{{numberSharedWith} people invited to edit}}"
            values={{
              numberSharedWith: length,
            }}
          />
        ),
        notifyConfig
      );
    },
    onError: error => {
      errorModal({ error: error.message });
      messageModal.hide();
    },
  });

  const openInviteEditors = useCallback(() => {
    userModal
      .show({
        title: <FormattedMessage id="share.invite.modal.title" defaultMessage="Invite to edit" />,
        label: (
          <FormattedMessage
            id="share.invite.modal.select.label"
            defaultMessage="Add people to invite"
          />
        ),
        children: <EditorList sessionId={sessionId} {...other} />,
        excludeEditors: true,
        sessionId,
        ...other,
      })
      .then(({ emailList }) => {
        userModal.hide();
        if (emailList?.length > 0) {
          messageModal
            .show({
              title: (
                <FormattedMessage
                  id="share.invite.message.modal.title"
                  defaultMessage="Invite message"
                />
              ),
            })
            .then(message => {
              mutate({
                locale,
                sessionId,
                message,
                users: emailList,
              });
            });
        }
      });
  }, [userModal, sessionId, messageModal, mutate, locale, other]);

  return { openInviteEditors };
};

export default useInviteEditors;
