const SalesforceBrowserInit = () => {
  // THIS WILL CHANGE WITH EACH INSTANCE OF SALESFORCE
  const clientId =
    '3MVG9xB_D1giir9qFRxYvnjqeQuEwbtzn6ILzTay9oXVi7WGjov7UoRDJFkzZWPRVYcMn9mczStzdJTvz2hJ9';

  import('jsforce').then((module) => {
    module.browser.init({
      clientId,
      redirectUri: process.env.REACT_APP_REDIRECT_SALESFORCE,
    });
  });

  return null;
};

export default SalesforceBrowserInit;
