"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = require("../../index");

var _reselect = require("reselect");

var _layout = _interopRequireDefault(require("./layout"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const getSuiteList = state => state.session.present.suiteList;

const getVisibleList = state => (0, _index.path)(['session', 'present', 'suiteList', 'visible'], state) || [];

const getFilteredSuitesOrder = (0, _reselect.createSelector)([getVisibleList, _layout.default.getSuitesOrder], (visible, suitesOrder) => {
  const getItem = item => (0, _index.find)((0, _index.propEq)('value', item))(visible) ? item : null;

  const filtered = suitesOrder.filter(getItem);
  return filtered;
});
const selectors = {
  getSuiteList,
  getVisibleList,
  getFilteredSuitesOrder
};
var _default = selectors;
exports.default = _default;