"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.showLinkButton = exports.showConfirm = exports.scopeValueEqualsDefaultSessionValue = exports.removeUnlinkedIds = exports.isDefaultSessionValuePristine = exports.getSchemaDefaultSessionValues = exports.getPlaceholder = exports.getLinkedItemId = exports.getFieldLinkStatus = exports.getDefaultSessionValuesIds = exports.getDefaultSessionValuesId = exports.getDefaultSessionValue = exports.getBenefitUnlinkedFieldIds = exports.getBenefitLinkedFieldIds = exports.getAllDefaultSessionValues = exports.formatDefaultSessionValue = exports.filterLinkedValueMatches = void 0;

var _fp = require("./fp");

var _type = require("./type");

var _normalization = require("./normalization");

// prettier-ignore
const isDefaultSessionValuePristine = (0, _fp.curryN)(2, (0, _fp.allPass)([_type.isNotNil, (0, _fp.pipe)(_fp.has, _fp.not)]));
exports.isDefaultSessionValuePristine = isDefaultSessionValuePristine;
const showConfirm = (0, _fp.curryN)(3, (defaultSessionId, defaultSessionValues, value) => isDefaultSessionValuePristine(defaultSessionId, defaultSessionValues) && (0, _type.isNumber)(value)); // prettier-ignore

exports.showConfirm = showConfirm;
const getDefaultSessionValue = (0, _fp.curryN)(2, (0, _fp.pipe)(_fp.prop, _type.maybeNumber)); // prettier-ignore

exports.getDefaultSessionValue = getDefaultSessionValue;
const getSchemaDefaultSessionValues = (0, _fp.pipe)((0, _fp.prop)('properties'), (0, _fp.pluck)('defaultSessionValueId'), (0, _fp.filter)(_type.isNotNil)); // prettier-ignore

exports.getSchemaDefaultSessionValues = getSchemaDefaultSessionValues;
const scopeValueEqualsDefaultSessionValue = (0, _fp.curry)((linkedData, scope, linkId, fieldId) => !(0, _fp.isNil)(linkedData[linkId]) && scope[fieldId] === linkedData[linkId]); // prettier-ignore

exports.scopeValueEqualsDefaultSessionValue = scopeValueEqualsDefaultSessionValue;

const filterLinkedValueMatches = (linkedData, scope, schemaLinkedFields) => (0, _fp.pipe)((0, _fp.mapObjIndexed)(scopeValueEqualsDefaultSessionValue(linkedData, scope)), (0, _fp.filter)(_fp.identity), _fp.keys)(schemaLinkedFields);

exports.filterLinkedValueMatches = filterLinkedValueMatches;
const getDefaultSessionValuesIds = (0, _fp.curryN)(2, (0, _fp.pipe)(_fp.props, (0, _fp.pluck)('defaultSessionValueId')));
exports.getDefaultSessionValuesIds = getDefaultSessionValuesIds;
const getDefaultSessionValuesId = (0, _fp.curryN)(2, (0, _fp.pipe)(_fp.prop, (0, _fp.prop)('defaultSessionValueId'), _type.maybeNil)); // prettier-ignore

exports.getDefaultSessionValuesId = getDefaultSessionValuesId;
const removeIterator = (0, _fp.curryN)(3, (id, acc, item) => (0, _fp.evolve)({
  [item]: (0, _fp.reject)((0, _fp.equals)(id))
}, acc));
const removeUnlinkedIds = (0, _fp.curryN)(3, (evalUid, unlinkFields, linkedEvals) => (0, _fp.reduce)(removeIterator(evalUid), linkedEvals, unlinkFields));
exports.removeUnlinkedIds = removeUnlinkedIds;
const getPlaceholder = (0, _fp.curry)((defaultVal, formatter, defaultSessionValue) => (0, _fp.pipe)(a => (0, _type.isNumber)(a) ? a : defaultVal, formatter)(_fp.Maybe.fromMaybe(NaN)(defaultSessionValue))); // prettier-ignore

exports.getPlaceholder = getPlaceholder;
const getLinkedItemId = (0, _fp.useWith)((0, _fp.pipe)(_fp.filter, _fp.keys, _fp.head, _type.maybeString), [(0, _fp.propEq)('defaultSessionValueId'), _fp.identity]); // prettier-ignore

exports.getLinkedItemId = getLinkedItemId;
const showLinkButton = (0, _fp.allPass)([(0, _fp.pipe)((0, _fp.nthArg)(0), _fp.Maybe.fromMaybe(NaN), _type.isNumber), (a, b) => _fp.Maybe.fromMaybe(NaN)(a) !== b]);
exports.showLinkButton = showLinkButton;

const formatDefaultSessionValue = (formatter, measurement, unit, value) => (0, _fp.pipe)(_fp.Maybe.fromMaybe(0), (0, _normalization.adjustToDisplayUnit)(measurement, unit), formatter)(value);

exports.formatDefaultSessionValue = formatDefaultSessionValue;

const getAllDefaultSessionValues = schema => (0, _fp.pipe)((0, _fp.prop)('properties'), (0, _fp.pluck)('defaultSessionValueId'), (0, _fp.filter)(_type.isNotNil))(schema);

exports.getAllDefaultSessionValues = getAllDefaultSessionValues;

const getBenefitLinkedFieldIds = (linked, schema, benefitUid) => (0, _fp.pipe)((0, _fp.pickBy)((0, _fp.contains)(benefitUid)), _fp.keys, (0, _fp.pipe)((0, _fp.flip)(_fp.contains), (0, _fp.pickBy)(_fp.placeholder, getAllDefaultSessionValues(schema))), _fp.keys)(linked);

exports.getBenefitLinkedFieldIds = getBenefitLinkedFieldIds;

const getBenefitUnlinkedFieldIds = (linked, schema, benefitUid) => (0, _fp.without)(getBenefitLinkedFieldIds(linked, schema, benefitUid), (0, _fp.keys)(getAllDefaultSessionValues(schema)));

exports.getBenefitUnlinkedFieldIds = getBenefitUnlinkedFieldIds;

const getFieldLinkStatus = (linked, unlinked, fieldId, defaultSessionValue) => (0, _fp.cond)([[() => (0, _fp.contains)(fieldId, linked), (0, _fp.always)('linked')], [() => (0, _fp.contains)(fieldId, unlinked) && (0, _fp.pipe)(_fp.Maybe.fromMaybe(NaN), _type.isNumber)(defaultSessionValue), (0, _fp.always)('unlinked')], [_fp.T, (0, _fp.always)('none')]])();

exports.getFieldLinkStatus = getFieldLinkStatus;