// @flow
import styled from 'styled-components';
import applyTheme from '../../styles/apply-theme';
import { animated } from 'react-spring';

const sidebar = applyTheme('sidebar');

export const NavItemWrapper = sidebar('navItemWrapper');
export const NavLabel = sidebar('navLabel', styled.label);
export const Aside = sidebar('aside', styled.aside);
export const Nav = sidebar('nav', styled.nav);
export const NavBg = sidebar('navBg');
export const Drawer = sidebar('drawer');
export const NavWrapper = sidebar('navWrapper');
export const NavSlider = sidebar('navSlider');
export const Tab = sidebar('tab');
export const ToggleWrapper = sidebar('toggleWrapper');
export const ToggleArrow = sidebar('toggleArrow');
export const MenuItemWrapper = sidebar('menuItemWrapper');
export const MenuLabelWrapper = sidebar('menuLabelWrapper');
export const MenuLabelTitle = sidebar('menuLabelTitle');
export const MenuItemIcon = sidebar('menuItemIcon');
export const Divider = sidebar('divider', styled.hr);
export const ContentContainer = sidebar('contentContainer', styled(animated.div));
export const ContentWrapper = sidebar('contentWrapper');
export const Section = sidebar('section');
export const SectionHeader = sidebar('sectionHeader', styled.h2);
export const DisplayFigure = sidebar('displayFigure');
export const DisplayFigureTitle = sidebar('displayFigureTitle');
export const DisplayFigureValue = sidebar('displayFigureValue');
export const DisplayFigureFooter = sidebar('displayFigureFooter');
export const TitleRow = sidebar('titleRow');
export const TableRow = sidebar('tableRow');
export const RolloverDivider = sidebar('rolloverDivider');
export const RowLabel = sidebar('rowLabel');
export const RowValue = sidebar('rowValue');
export const RowSubLabel = sidebar('rowSubLabel');
export const RowWrapper = sidebar('rowWrapper');
export const BackgroundSection = sidebar('backgroundSection');
export const H2Title = sidebar('h2Title', styled.h2);
export const Link = sidebar('link', styled.a);
export const MenuLabelSubtitle = sidebar('menuLabelSubtitle');
export const Button = sidebar('button', styled.button);
export const IconLabelWrapper = sidebar('iconLabelWrapper');
export const IconWrapper = sidebar('iconWrapper');
export const HelpItemWrapper = sidebar('helpItemWrapper');
export const HelpItemLabel = sidebar('helpItemLabel');
