"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const initialState = {
  tenderPeriod: null,
  staffEngaged: null,
  annualLoadedCostPerPerson: null,
  otherCosts: null,
  isDirty: false,
  isUsed: false,
  isCollapsed: true
};
var _default = initialState;
exports.default = _default;