"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reselect = require("reselect");

var _index = require("../../index");

// Selectors
const getSessionDetails = state => state.session.present.details;

const getDealName = state => state.session.present.details.dealName;

const getCustomerName = state => state.session.present.details.customerName;

const getSupplierName = state => state.session.present.details.supplierName;

const getIndustry = state => state.session.present.details.industry;

const getMinimumReturnForDisplay = state => state.session.present.details.minimumReturn;

const getNotes = state => state.session.present.details.notes;

const getCurrency = state => state.session.present.details.currency;

const getCurrencySymbol = state => state.session.present.details.currency.value;

const getCurrencySymbolPosition = state => state.session.present.details.currency.position;

const getNumberFormat = state => state.session.present.details.numberFormat;

const getDateFormat = state => state.session.present.details.dateFormat || 'DD/MM/YYYY';

const getMinRet = state => state.session.present.details.minimumReturn;

const getRevPeriod = state => state.session.present.details.reviewPeriod;

const getMonBenChartOrder = state => state.session.present.details.monthlyBenefitChartOrder;

const getOTBChartOrder = state => state.session.present.details.oneTimeBenefitChartOrder;

const getTotalBenefitsChartOrder = state => state.session.present.details.totalBenefitChartOrder;

const getNonRecurringCostsChartOrder = state => state.session.present.details.nonRecurringCostsChartOrder;

const getRecurringCostsChartOrder = state => state.session.present.details.recurringCostsChartOrder;

const getSessionMedia = state => state.session.present.details.media;

const getSessionMediaPosition = state => state.session.present.details.media.position;

const getMinimumReturn = (0, _reselect.createSelector)([getMinRet], minimumReturn => {
  return minimumReturn === '' ? 0 : minimumReturn;
});
const getReviewPeriod = (0, _reselect.createSelector)([getRevPeriod], reviewPeriod => {
  return reviewPeriod === '' ? _index.defaultReviewPeriod : (0, _index.adjustTimeUnit)('months', reviewPeriod);
});
const getReviewPeriodForDisplay = (0, _reselect.createSelector)([getSessionDetails], details => {
  return details.reviewPeriod === '' ? '' : (0, _index.adjustTimeUnit)('months', details.reviewPeriod);
});
const getNumberFormatObj = (0, _reselect.createSelector)([getNumberFormat, getCurrencySymbol, getCurrencySymbolPosition], (numberFormat, currencySymbol, currencySymbolPosition) => {
  return {
    numberFormat,
    currencySymbol,
    currencySymbolPosition,
    thousandsSeparatorSymbol: (0, _index.getThousandsSeparatorSymbol)(numberFormat),
    decimalSeparatorSymbol: (0, _index.getDecimalSeparatorSymbol)(numberFormat),
    decimalPlaces: _index.defaultDecimalPlaces
  };
});
const getMinimumReturnDisplay = (0, _reselect.createSelector)([getMinimumReturn, getNumberFormatObj], (value, numberFormatObj) => {
  return (0, _index.formatForDisplay)('percentage', { ...numberFormatObj,
    decimalPlaces: 2
  }, value);
});

const getDefaultStartMonth = state => {
  var _state$session, _state$session$presen, _state$session$presen2;

  return (_state$session = state.session) === null || _state$session === void 0 ? void 0 : (_state$session$presen = _state$session.present) === null || _state$session$presen === void 0 ? void 0 : (_state$session$presen2 = _state$session$presen.details) === null || _state$session$presen2 === void 0 ? void 0 : _state$session$presen2.defaultStartMonth;
};

const getDefaultStartMonthForDisplay = (0, _reselect.createSelector)([getDefaultStartMonth], defaultStartMonth => defaultStartMonth && (0, _index.adjustTimeUnit)('months', defaultStartMonth));
const selectors = {
  getCurrency,
  getCurrencySymbol,
  getCurrencySymbolPosition,
  getCustomerName,
  getDateFormat,
  getDealName,
  getIndustry,
  getMinimumReturn,
  getMinimumReturnDisplay,
  getMinimumReturnForDisplay,
  getNumberFormatObj,
  getNotes,
  getNumberFormat,
  getReviewPeriod,
  getReviewPeriodForDisplay,
  getSupplierName,
  getMonBenChartOrder,
  getOTBChartOrder,
  getTotalBenefitsChartOrder,
  getNonRecurringCostsChartOrder,
  getRecurringCostsChartOrder,
  getSessionMedia,
  getSessionMediaPosition,
  getDefaultStartMonth,
  getDefaultStartMonthForDisplay,
  getRevPeriod
};
var _default = selectors;
exports.default = _default;