const config = {
  hideProgressBar: true,
  position: 'bottom-center',
  pauseOnHover: false,
  draggable: false,
  autoClose: 7000,
  closeButton: false,
  className: 'toast',
  bodyClassName: 'toast-body',
};
export default config;
