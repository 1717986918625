// @flow
import React, { Suspense, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { GlobalStyles, createGlobalStyle, Loader } from '@sharkfinesse/sfl-ui';
import styled from 'styled-components';
import { ConnectedRouter } from 'connected-react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { registerTheme } from 'echarts/lib/echarts';
import { Can } from 'ability/ability-context';
import PreCache from 'containers/pre-cache';
import lazyWithRetry from 'utils/lazyWithRetry';
import NiceModal from '@ebay/nice-modal-react';
import { IntlProvider } from 'react-intl';
import ErrorBoundary from 'components/error-boundary/error-boundary';
import HelpProvider from 'components/help/provider';

const Routes = lazyWithRetry(() => import('containers/app/routes'));
import useWindowSize from 'hooks/useWindowSize';
import SalesforceBrowserInit from 'components/salesforce/browser-init';
import 'styled-components/macro';

export const queryClient = new QueryClient();

// register theme object
registerTheme('light', {
  backgroundColor: 'transparent',
  color: [
    '#ffbf6e',
    '#00e476',
    '#e69d87',
    '#2d8fdf',
    '#ff7644',
    '#eedd78',
    '#73a373',
    '#73b9bc',
    '#7289ab',
    '#91ca8c',
    '#f49f42',
  ],
  textStyle: {
    color: 'rgb(71, 71, 71)',
  },
  legend: {
    textStyle: {
      color: 'rgb(71, 71, 71)',
    },
  },
  categoryAxis: {
    axisLine: {
      lineStyle: {
        color: '#d0d0d0',
      },
    },
    axisTick: {
      lineStyle: {
        color: '#d0d0d0',
      },
    },
    axisLabel: {
      color: 'rgb(71, 71, 71)',
    },
  },
  valueAxis: {
    axisLine: {
      lineStyle: {
        color: '#d0d0d0',
      },
    },
    axisTick: {
      lineStyle: {
        color: '#d0d0d0',
      },
    },
    axisLabel: {
      color: 'rgb(71, 71, 71)',
    },
  },
});
// register theme object
registerTheme('dark', {
  backgroundColor: 'transparent',
  color: [
    '#ffbf6e',
    '#00e476',
    '#2d8fdf',
    '#ff7644',
    '#ea7e53',
    '#eedd78',
    '#73a373',
    '#73b9bc',
    '#7289ab',
    '#91ca8c',
    '#f49f42',
  ],
  textStyle: {
    color: 'rgb(218, 218, 218)',
  },
  legend: {
    textStyle: {
      color: 'rgb(218, 218, 218)',
    },
  },
  categoryAxis: {
    axisLine: {
      lineStyle: {
        color: '#d0d0d0',
      },
    },
    axisTick: {
      lineStyle: {
        color: '#d0d0d0',
      },
    },
    axisLabel: {
      color: 'rgb(218, 218, 218)',
    },
  },
  valueAxis: {
    axisLine: {
      lineStyle: {
        color: '#d0d0d0',
      },
    },
    axisTick: {
      lineStyle: {
        color: '#d0d0d0',
      },
    },
    axisLabel: {
      color: 'rgb(218, 218, 218)',
    },
  },
});

registerTheme('aqua', {
  backgroundColor: 'transparent',
  color: [
    '#db6714',
    '#2385a9',
    '#e69d87',
    '#8dc1a9',
    '#ea7e53',
    '#eedd78',
    '#73a373',
    '#73b9bc',
    '#7289ab',
    '#91ca8c',
    '#f49f42',
  ],
  textStyle: {
    color: 'rgb(71, 71, 71)',
  },
  legend: {
    textStyle: {
      color: 'rgb(71, 71, 71)',
    },
  },
  categoryAxis: {
    axisLine: {
      lineStyle: {
        color: '#d0d0d0',
      },
    },
    axisTick: {
      lineStyle: {
        color: '#d0d0d0',
      },
    },
    axisLabel: {
      color: 'rgb(71, 71, 71)',
    },
  },
  valueAxis: {
    axisLine: {
      lineStyle: {
        color: '#d0d0d0',
      },
    },
    axisTick: {
      lineStyle: {
        color: '#d0d0d0',
      },
    },
    axisLabel: {
      color: 'rgb(71, 71, 71)',
    },
  },
});
registerTheme('lime', {
  backgroundColor: 'transparent',
  color: [
    '#e6740a',
    '#7EB843',
    '#e69d87',
    '#8dc1a9',
    '#ea7e53',
    '#eedd78',
    '#73a373',
    '#73b9bc',
    '#7289ab',
    '#91ca8c',
    '#f49f42',
  ],
  textStyle: {
    color: 'rgb(71, 71, 71)',
  },
  legend: {
    textStyle: {
      color: 'rgb(71, 71, 71)',
    },
  },
  categoryAxis: {
    axisLine: {
      lineStyle: {
        color: '#d0d0d0',
      },
    },
    axisTick: {
      lineStyle: {
        color: '#d0d0d0',
      },
    },
    axisLabel: {
      color: 'rgb(71, 71, 71)',
    },
  },
  valueAxis: {
    axisLine: {
      lineStyle: {
        color: '#d0d0d0',
      },
    },
    axisTick: {
      lineStyle: {
        color: '#d0d0d0',
      },
    },
    axisLabel: {
      color: 'rgb(71, 71, 71)',
    },
  },
});

export const GlobalStyled = createGlobalStyle`
.toast {
    ${p => p.theme.toast.base};
  }
  .toast-body {
    ${p => p.theme.toast.body};
  }
  
  .progress-bar {
    ${p => p.theme.toast.progressBar};
  }

  .Toastify__close-button--default{
    ${p => p.theme.toast.closeDefault};
  }
  ${({ theme }) => theme.colors.gradientsCls}
  .carousel__slider{
    outline: none
  }  
`;

export const RenderChartsOffScreen = styled.div`
  width: 605px;
  position: absolute;
  top: -9999px;
  left: -9999px;
`;

type DefaultProps = {
  loading: Boolean,
  loadingText: Boolean,
};

export type Props = DefaultProps & {
  loading?: Boolean,
  loadingText?: Boolean,
  history: Object,
};

const intlError = () => {};

const App = ({
  history,
  loading = true,
  loadingText = '',
  locale,
  messages,
  loadingImmediate,
}: Props) => {
  document.documentElement.style.setProperty('--bo', 1);
  document.documentElement.style.setProperty('--vs', 'visible');
  //fix to set main section hegiht for mobile devices where vh uses whole screen height
  const resize = useWindowSize();
  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, [resize]);

  return (
    <>
      <Can not I="access_denied" a="software">
        <Suspense fallback={<Loader isLoading />}>
          <QueryClientProvider client={queryClient}>
            <ConnectedRouter history={history} noInitialPop>
              <IntlProvider locale={locale} messages={messages} onError={intlError}>
                <HelpProvider>
                  <NiceModal.Provider>
                    <ErrorBoundary errorCode="RootError">
                      <SalesforceBrowserInit />
                      <GlobalStyled />
                      <GlobalStyles />
                      <PreCache />
                      <Routes />
                      <Loader
                        isLoading={loading}
                        zIndex="20000"
                        label={loadingText}
                        immediate={loadingImmediate}
                      />
                      <ToastContainer closeOnClick={false} />
                    </ErrorBoundary>
                  </NiceModal.Provider>
                </HelpProvider>
              </IntlProvider>
            </ConnectedRouter>
          </QueryClientProvider>
        </Suspense>
      </Can>
      <Can I="access_denied" a="software">
        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            fontFamily: 'Arial, Helvetica, sans-serif',
          }}
        >
          <p>
            The Shark software service is no longer funded so is now discontinued. If you wish to
            discuss funding for future access to the service then please contact{' '}
            <a href={'mailto:sales@sharkfinesse.com'}>sales@sharkfinesse.com</a>
          </p>
        </div>
      </Can>
    </>
  );
};

export default App;
