/* @flow */
/* eslint-disable max-len */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20,7H8C6.9,7,6,7.9,6,9v10c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V9C22,7.9,21.1,7,20,7z M20,19H8V9h12V19z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16,3H4C2.9,3,2,3.9,2,5v10c0,1.1,0.9,2,2,2V5h14C18,3.9,17.1,3,16,3z"
    />
  </Icon>
);

export default memo(IconComponent);
