/* @flow */
import React from 'react';
import type { Element } from 'react';
import { Button, Icon, Label } from './styled';
import { IconAdd } from '../icon';
import Tooltip from '../tooltip';

type DefaultProps = {
  icon?: Element<*> | string,
};
type Props = DefaultProps & {
  children?: Element<*> | string,
  ...other,
};

const AddButton = ({
  icon = <IconAdd />,
  children,
  tooltip,
  tooltipConfig,
  toolTipContainerProps = { style: { display: 'flex', width: '100%', justifyContent: 'center' } },
  tooltipProps,
  tipStyle = 'light',
  ...other
}: Props): Element<any> => {
  const buttonComponent = (
    <Button {...other}>
      <Icon>{icon}</Icon> <Label>{children}</Label>
    </Button>
  );

  return tooltip ? (
    <Tooltip
      tooltip={tooltip}
      tooltipConfig={tooltipConfig}
      delayShow={500}
      toolTipContainerProps={toolTipContainerProps}
      tooltipProps={tooltipProps}
      tipStyle={tipStyle}
    >
      {buttonComponent}
    </Tooltip>
  ) : (
    buttonComponent
  );
};

export default AddButton;
