import { css } from 'styled-components';
import { lighten } from '@sharkfinesse/sfl-lib';

const Styles = ({ font, colors, scale, device: { min_xxs, min_xs, min_md } }) => ({
  ul: ({ $isChild }) => css`
    margin: 0;
    padding: 0;
    width: 100%;
    li:not(:last-child) {
      margin-bottom: 10px;
    }
  `,

  container: ({ $state, $isChild }) => css`
    display: flex;
    flex-direction: column;
    list-style: none;
    color: ${colors.secondary};

    &:hover {
    }
    ${$isChild &&
    css`
      &:first-child {
        padding-top: 10px;
      }
    `}
  `,
  wrapper: ({ $isChild }) => css`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: ${$isChild ? '20px' : 0};
    text-decoration: none;
  `,
  label: ({ $fontColor, $state, $isChild }) => css`
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-left: 8px;
    color: ${$fontColor};
    opacity: ${$state === 'selected' || $state === 'complete' ? 1 : 0.3};
    font-weight: ${$state === 'selected' ? 600 : 400};
    ${!$isChild &&
    css`
      font-variant: small-caps;
      font-size: 16px;
    `}
  `,
  body: () => css`
    overflow: hidden;
  `,

  icon: ({ $color, $state }) => css`
    opacity: ${$state === 'selected' || $state === 'complete' ? 1 : 0.3};
    display: flex;
    color: ${$color};
  `,
});

export default Styles;
