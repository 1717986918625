import React from 'react';
import { all, call, race, take, delay } from 'redux-saga/effects';
import { toastConfig } from 'components/toast/undo';
import { toast } from 'react-toastify';
import Undo from 'components/toast/undo';
import { FormattedMessage } from 'react-intl';
import { types as sessionTypes } from 'redux/modules/session';
import { actionCreators as sessionActionCreators } from 'redux/modules/session';
import undoableActions from 'redux/undo/undoable-actions';
import { isNil } from '@sharkfinesse/sfl-lib';

function* undoToast({
  action,
  undoActions = [],
  extraActions = [],
  formattedMessageId = '',
  defaultMessage = '',
}) {
  const undoId = yield call(toast, '', toastConfig());
  yield all([
    call(toast.update, undoId, {
      render: (
        <Undo
          id={undoId}
          message={<FormattedMessage id={formattedMessageId} defaultMessage={defaultMessage} />}
          undo={sessionActionCreators.undo}
        />
      ),
    }),
    ...undoActions,
  ]);
  if (!isNil(extraActions)) yield all(extraActions);
  yield race({
    undo: take([...undoableActions, sessionTypes.IMPORT, sessionTypes.UNDO, sessionTypes.REDO]),
    archive: delay(14000),
  });

  yield call(toast.dismiss, undoId);
}

export default undoToast;
