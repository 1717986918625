import { IDEMPOTENT, CATEGORY, DISTINCT } from './constants.js';
import { isNil } from '@sharkfinesse/sfl-lib';
const parentActionGroup = 'parentActionGroup';
const groupBy = action => {
  if (!isNil(action.meta)) {
    if (action.meta.hasOwnProperty(parentActionGroup) && !isNil(action.meta.parentActionGroup)) {
      switch (action.meta.parentActionGroup.type) {
        case IDEMPOTENT:
          return action.meta.parentActionGroup.name;
        case CATEGORY:
        case DISTINCT:
        default:
          return action.meta.parentActionGroup.name + '_' + action.meta.parentActionGroup.id;
      }
    } else {
      //Actions with no parent group will be distinct (undoable) since the group is null
      return null;
    }
  } else {
    return null;
  }
};

export default groupBy;
