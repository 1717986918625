/* eslint-disable react/prop-types */
import * as React from 'react';
import 'styled-components/macro';
import { Tab as TabStyle, TabLabel } from './styled';

const Tab = React.forwardRef(function Tab(props, ref) {
  const {
    className,
    disabled = false,
    fullWidth,
    icon,
    indicator,
    label,
    onChange,
    onClick,
    onFocus,
    selected,
    selectionFollowsFocus,
    value,
    wrapped = false,
    inverse,
    ...other
  } = props;

  const handleClick = (event) => {
    if (!selected && onChange) {
      onChange(event, value);
    }

    if (onClick) {
      onClick(event);
    }
  };

  const handleFocus = (event) => {
    if (selectionFollowsFocus && !selected && onChange) {
      onChange(event, value);
    }

    if (onFocus) {
      onFocus(event);
    }
  };

  return (
    <TabStyle
      fullWidth={fullWidth}
      wrapped={wrapped}
      selected={selected}
      label={label}
      icon={icon}
      ref={ref}
      role="tab"
      aria-selected={selected}
      disabled={disabled}
      onClick={handleClick}
      onFocus={handleFocus}
      tabIndex={selected ? 0 : -1}
      inverse={inverse}
      {...other}
    >
      {icon}
      <TabLabel selected={selected} inverse={inverse} icon={icon}>
        {label}
      </TabLabel>
      {indicator}
    </TabStyle>
  );
});

export default Tab;
