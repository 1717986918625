import { setAlphaHsla } from '@sharkfinesse/sfl-lib';
import { css } from 'styled-components';

const Loader = ({ font, colors, scale }) => ({
  color: colors.primary,
  lightColor: colors.white,
  container: () => css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${setAlphaHsla(0.9, colors.white)};
  `,
  label: () => css`
    font-size: 13px;
    margin-top: 12px;
  `,
});

export default Loader;
