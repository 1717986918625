import { call, select, all } from 'redux-saga/effects';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { version } from '../../../package.json';
import { isNil, userSelectors, preferencesSelectors } from '@sharkfinesse/sfl-lib';

const firebaseEnvironment = localStorage.getItem('firebaseEnvironment');

export function setUpTracking({ encryptedUserId, account, product }) {
  //Log Rocket
  LogRocket.identify(encryptedUserId, {
    deploymentType: process.env.REACT_APP_MODE,
    account: account,
    product: product,
  });
}

function* rootSaga() {
  const [encryptedUserId, account, product, enabled] = yield all([
    select(userSelectors.getEncryptedUserId),
    select(userSelectors.getAccount),
    select(userSelectors.getProduct),
    select(preferencesSelectors.getDebuggingEnabled),
  ]);

  if (enabled) {
    if (
      (process.env.REACT_APP_MODE === 'production' ||
        process.env.REACT_APP_MODE === 'staging' ||
        process.env.REACT_APP_MODE === 'devapp') &&
      isNil(firebaseEnvironment)
    ) {
      yield call([LogRocket, LogRocket.init], 'gupxvo/sflpro', {
        release: version,
        network: {
          requestSanitizer: request => {
            //sanatize any auth requests

            if (
              request.url.toLowerCase().indexOf('auth.sharkfinesse.com') !== -1 ||
              request.url.toLowerCase().indexOf('auth0.com') !== -1
            ) {
              // ignore the request response pair
              return null;
            }
            // otherwise log the request normally
            return request;
          },
        },
      });
      yield call(setupLogRocketReact, LogRocket);
      yield call(setUpTracking, { encryptedUserId, account, product });
    }
  }
}

export default rootSaga;
