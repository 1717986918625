import { css } from 'styled-components';

const Search = ({ font, colors, scale, device: { min_xxs, min_lg } }) => ({
  container: ({ fullWidth }) => css`
    font-size: 16px;
    width: 100%;
    max-width: ${fullWidth ? '100%' : '500px'};
  `,
  center: () => css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media ${min_xxs} {
      padding: 50px 20px;
    }
    @media ${min_lg} {
      padding: 80px;
    }
  `,
  beforeContainerRelative: ({ $rounded }) => css`
    color: ${colors.ui};
    ${$rounded &&
    css`
      padding-left: 10px;
    `}
  `,
  afterContainerRelative: ({ $rounded }) => css`
    color: ${colors.ui};
    ${$rounded &&
    css`
      padding-right: 10px;
    `}
  `,
  wrapper: () => css`
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    z-index: 1;
  `,
  toolbar: () => css`
    margin-left: 4.5px;
    justify-content: flex-end;
    display: flex;
    flex: 1;
    align-items: center;
  `,
});

export default Search;
