/* @flow */
/* eslint-disable max-len */
import React, { memo } from 'react';
import styled from 'styled-components';
import Icon from '../icon';
import { prop } from 'styled-tools';

const StyledPathBase = styled.path`
  fill: ${prop('theme.icons.defaultColor')};
`;

let IconLinked = (props): React.Element<any> => (
  <Icon {...props}>
    <g>
      <StyledPathBase
        d={`M577.5,688c24-0.4,48.4-4.1,71.3-12.7c5.9-1.6,11.3-4.5,17-7c5.5-2.3,11.1-5,16.3-8.4c5.4-3.2,10.4-5.7
        ,15.7-9.7c5.4-3.9,10.7-7.9,15.9-12c3.9-3.2,6.3-5.5,9.3-8.2l4.5-4.1l2.1-2.1l3.6-3.6l16.8-17.2
        l33.4-34.5l66.5-69.7l66.1-70.1l16.4-17.5l8.4-8.8c2.9-2.9,6.3-7,9.1-10.5c12.7-14.1,22.5-30.4,31.3-47c37.7-56.3
        ,46.7-134.9,18.2-202l-5.4-12.5l-6.6-12c-3.9-7.9-9.8-16.3-15.7-24.3l-4.5-5.9l-3.6-4.3l-7.1-8.2l-1.8-2.1
        l-2.3-2.3l-0.5-0.5l-1.3-1.3l-5-4.6l-10-9.3c-28.1-23.8-62-40.6-97.8-49.2c-35.7-8.6-73.6-8.6-109.4,0l-13.4
        ,3.4c-4.3,1.4-8.8,3-13,4.5c-8.8,2.7-17.2,6.8-25.4,10.7c-8.4,3.8-16.1,8.9-24,13.6c-8,5.2-16.6,12-24,17.9l-8
        ,7.3l-4.1,3.8l-3.9,3.6l-2,2l-15.6,15.6l-30.9,30.9c-20.7,20.4-41.6,40.9-62.7,61c-7.5,7.1-12.7,24.8-12.2
        ,32.9c1.6,15.9,18.2,13.9,37.7,5.5c32.4-13.8,64.3-32.5,95.6-54.9c15.7-11.1,31.3-23.1,46.5-35.7l0.4-0.4
        c1.1-1.1-1.3,1.3-1.1,1.1l0,0l0,0l0.2-0.2l0.9-0.5l1.4-1.1l3-2.3l5.9-4.5c4.3-3.6,7.3-4.8,10.4-6.8c5.7-3.9
        ,13.8-7.7,20.9-11.3c35.9-16.6,77.6-18.2,113.5-5.5c18.1,6.3,34.5,15.9,48.6,27.9c13.8,11.6,27.5,29,34.7
        ,43.3c17.5,32.2,22.2,70.8,13.8,106c-4.1,17.7-11.3,34.5-21.4,49.7c-2.9,3.6-4.8,7.7-8.2,11.1l-4.3,5.4c-1.4
        ,1.8-2.7,3.4-5,5.7L858,372.5c-17.7,16.3-35.2,32.7-52.9,49c-27.7,26.3-55.4,52.5-82.6,79l-40.8,40l-10.2
        ,10.2l-4.8,4.8l-0.9,0.9l-2.7,2.3l-10.4,9.3c-2.3,1.6-4.6,3.2-7,4.8c-10.7,7.1-23.4,14.5-36.6,18.8c-13.2,4.3-27.2
        ,7.1-41.3,8c-14.1,0.5-28.6-0.7-42.5-3.6c-14.1-3.4-27.5-8.6-40.6-15.6c-3.6-2.1-7.1-4.3-10.7-6.1
        s-6.8-4.3-10.2-5.9c-7-3.4-13.2-7.1-19.5-9.5c-12.2-5-23.2-6.8-32,0.9c-7.9,6.8-11.1,19.3-6.3,35.2c2.3,8,6.6,16.6
        ,13.2,25c6.1,8.9,15,17.2,25.4,25C481.9,672.8,529.1,688.9,577.5,688z
      `}
      />
      <StyledPathBase
        d={`M103,749.3c4.1-17.7,11.3-34.5,21.4-49.7c2.9-3.6,4.8-7.7,8.2-11.1l4.5-5.2c1.4-1.8,2.7-3.4,5-5.7l24.8-26.6
        c17.7-16.3,35.2-32.7,52.9-49c27.7-26.3,55.4-52.5,82.6-79l40.8-40l10.2-10.2l4.8-4.8l0.9-0.9l2.7-2.3l10.4-9.3
        c2.3-1.6,4.6-3.2,7-4.8c10.7-7.1,23.4-14.5,36.6-18.8s27.2-7.1,41.3-8c14.1-0.5,28.6,0.7,42.5,3.6c14.1,3.4,27.5
        ,8.6,40.6,15.6c3.6,2.1,7.1,4.3,10.7,6.1c3.6,1.8,6.8,4.3,10.2,5.9c7,3.4,13.2,7.1,19.5,9.5c12.2,5,23.2,6.8
        ,32-0.9c7.9-6.8,11.1-19.3,6.3-35.2c-2.3-8-6.6-16.6-13.2-25c-6.1-8.9-15-17.2-25.4-25C542.3,351,494.8,335,446.5
        ,336c-24,0.4-48.4,4.1-71.3,12.7c-5.9,1.6-11.3,4.5-17,7c-5.5,2.3-11.1,5-16.3,8.4c-5.4,3.2-10.4,5.7-15.7
        ,9.7c-5.4,3.9-10.7,7.9-15.9,12c-3.9,3.2-6.3,5.5-9.5,8.4l-4.5,4.1l-2.1,2.1l-3.6,3.6l-16.8,17.2l-33.4,34.5l-66.5
        ,69.7l-66.1,70.1l-16.4,17.5l-8.4,8.8c-2.9,2.9-6.3,7-8.9,10.4c-12.7,14.1-22.5,30.4-31.3,47C5,735.3-3.9,814,24.5
        ,881l5.4,12.5l6.6,12c3.9,7.9,9.8,16.3,15.7,24.3l4.5,5.9l3.6,4.3l7.1,8.2l1.8,2.1l2.3,2.3l0.5,0.5l1.3,1.3l5
        ,4.6l10,9.3c28.1,23.8,62,40.6,97.8,49.2c35.7,8.6,73.6,8.6,109.4,0l13.4-3.4c4.3-1.4,8.8-3,13-4.5c8.8-2.7
        ,17.2-6.8,25.4-10.7c8.4-3.8,16.1-8.9,24-13.6c8-5.2,16.6-12,24-17.9l8-7.3l4.1-3.8l3.9-3.6l2-2l15.6-15.6
        l30.9-30.9c20.7-20.4,41.6-40.9,62.7-61c7.5-7.1,12.7-24.8,12.2-32.9c-1.6-15.9-18.2-13.9-37.7-5.5c-32.4
        ,13.8-64.3,32.5-95.6,54.9C385.5,871,370,883,354.8,895.7l-0.4,0.4c-1.1,1.1,1.3-1.3,1.1-1.1l0,0l0,0l-0.2
        ,0.2l-0.9,0.5l-1.4,1.1l-3,2.3l-5.9,4.5c-4.3,3.6-7.3,4.8-10.4,6.8c-5.7,3.9-13.8,7.7-20.9,11.3c-35.9,16.6-77.6
        ,18.2-113.5,5.5c-18.1-6.3-34.5-15.9-48.6-27.9c-13.8-11.6-27.5-29-34.7-43.3C99.4,822.9,94.7,784.7,103,749.3z
      `}
      />
    </g>
  </Icon>
);

export default memo(IconLinked);
