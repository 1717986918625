"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reselect = require("reselect");

/* eslint-disable no-unused-vars  */
const getState = state => state.device.state;

const isOnlinePending = state => state.device.pending;

const getPreviousState = state => state.device.previousState;

const getId = state => state.device.id;

const isOnline = (0, _reselect.createSelector)([getState, isOnlinePending], (onlineState, pending) => {
  return onlineState === 'online' || pending ? true : false;
});
const selectors = {
  getState,
  getPreviousState,
  isOnline,
  isOnlinePending,
  getId
};
var _default = selectors;
exports.default = _default;