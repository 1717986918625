"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const initialState = {
  persistState: true,
  loading: false,
  loadingText: 'Loading...',
  nonRecurringCalculating: false,
  recurringCalculating: false,
  fetching: false,
  firestoreTerminated: false,
  showCompliance: false
};
var _default = initialState;
exports.default = _default;