/* @flow */
import { createReducer, dissoc, sessionUndoredoInitialState } from '@sharkfinesse/sfl-lib';
const namespace = (name: string): string => `@@session.undoredo/${name}`;

//Actions
export const types = {
  SUITE: {
    DELETING: namespace('SUITE.DELETING'),
    DELETING_BENEFITS: namespace('SUITE.DELETING_BENEFITS'),
    DELETING_COMPLETE: namespace('SUITE.DELETING_COMPLETE'),
    DELETING_BENEFITS_COMPLETE: namespace('SUITE.DELETING_BENEFITS_COMPLETE'),
  },
};

//Reducer
const initialState = sessionUndoredoInitialState;

const reduceSuiteDeleting = (state, action) => {
  return {
    ...state,
    deletingSuite: true,
    deletingSuiteBenefits: true,
  };
};

const reduceSuiteDeletingComplete = (state, action) => {
  return dissoc('deletingSuite', state);
};

const reduceSuiteDeletingBenefitsComplete = (state, action) => {
  return dissoc('deletingSuiteBenefits', state);
};

export default createReducer(
  {
    [types.SUITE.DELETING]: reduceSuiteDeleting,
    [types.SUITE.DELETING_COMPLETE]: reduceSuiteDeletingComplete,
    [types.DELETING_BENEFITS_COMPLETE]: reduceSuiteDeletingBenefitsComplete,
  },
  initialState
);

//Action Creators
const updateSuiteDeleting = () => ({ type: types.SUITE.DELETING });
const updateSuiteBenefitsDeleting = () => ({ type: types.SUITE.DELETING_BENEFITS });
const updateSuiteDeletingComplete = () => ({ type: types.SUITE.DELETING_COMPLETE });
const updateSuiteBenefitsDeletingComplete = () => ({
  type: types.SUITE.DELETING_BENEFITS_COMPLETE,
});

export const actionCreators = {
  updateSuiteDeleting,
  updateSuiteBenefitsDeleting,
  updateSuiteDeletingComplete,
  updateSuiteBenefitsDeletingComplete,
};
