import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import createReducer from './modules';
import initState from './init';
import { responsiveStoreEnhancer } from 'redux-responsive';
import rootSaga from './sagas';
import LogRocket from 'logrocket';
import { persistStore, persistReducer } from 'redux-persist';
import localforage from 'localforage';
import abilityPermission from '../ability/abilityPermission';
import { pick } from '@sharkfinesse/sfl-lib';
import googleAnalytics from './reactGAMiddlewares';

export const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage: localforage,
  whitelist: ['preferences', 'intl'],
};

const abilitiesMiddleware = store => next => action => {
  if (action.type === '@@abilities/SYNC') {
    const abilities = action.payload;
    let abilitiesArray = [];
    for (let i = 0; i < Object.keys(abilities).length; i++) {
      abilitiesArray.push({
        subject: Object.keys(abilities)[i],
        actions: abilities[Object.keys(abilities)[i]],
      });
    }
    // Set users abilities
    abilityPermission.update(abilitiesArray);
  }
  next(action);
};

export default function store(history) {
  const persistedReducer = persistReducer(persistConfig, createReducer(history));
  let store;
  let middlewares = [
    routerMiddleware(history),
    googleAnalytics,
    sagaMiddleware,
    LogRocket.reduxMiddleware({
      stateSanitizer: function (state) {
        return {
          ...state,
          _persist: undefined,
          abilities: undefined,
          account: {
            ...state.account,
            _persist: undefined,
            categories: undefined,
            categoryTypes: undefined,
            evaluators: undefined,
            locales: undefined,
            logos: undefined,
            preferences: undefined,
            coverpages: undefined,
          },
          app: undefined,
          auth0: undefined,
          browser: undefined,
          compare: undefined,
          device: undefined,
          intl: undefined,
          login: undefined,
          persistence: undefined,
          preferences: undefined,
          product: undefined,
          serviceWorker: undefined,
          session: {
            ...state.session,
            _latestUnfiltered: undefined,
            future: undefined,
            group: undefined,
            index: undefined,
            limit: undefined,
            past: undefined,
          },
          settings: undefined,
          user: undefined,
          salesforce: undefined,
        };
      },
      actionSanitizer: function (action) {
        const type = pick(['type'], action);
        if (type === '@@router/LOCATION_CHANGE') return action;
        return type;
      },
    }),
    abilitiesMiddleware,
  ];

  const enhancers = [];

  if (
    process.env.REACT_APP_MODE !== 'production' &&
    process.env.REACT_APP_MODE !== 'staging' &&
    process.env.REACT_APP_MODE !== 'devapp' &&
    process.env.REACT_APP_MODE !== 'bjones'
  ) {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension({ trace: true }));
    }
  }

  store = createStore(
    persistedReducer,
    initState,
    compose(responsiveStoreEnhancer, applyMiddleware(...middlewares), ...enhancers)
  );
  let persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  return { store, persistor };
}
