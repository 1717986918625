import { css } from 'styled-components';
import { darkenHsla, lightenHsla } from '@sharkfinesse/sfl-lib';

const DatePicker = ({ font, colors, scale }) => ({
  base: () => css`
    &.react-calendar {
      width: 300px;
      border: none;
      font-family: ${font.family};
      background: none;
      button {
        font-family: ${font.family};
      }
    }
    abbr[title] {
      text-decoration: none;
    }
    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus {
      background-color: ${darkenHsla(3, colors.uiLightest)};
    }
    .react-calendar__navigation button[disabled] {
      background-color: ${colors.uiLightest};
    }
    .react-calendar__tile {
      color: ${font.color};
    }
    .react-calendar__month-view__days__day--weekend {
      color: ${font.color};
    }
    .react-calendar__month-view__days__day--neighboringMonth {
      color: ${lightenHsla(50, font.color)};
    }
    .react-calendar__tile:disabled {
      background-color: ${colors.uiLightest};
    }
    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
      background-color: ${darkenHsla(3, colors.uiLightest)};
    }
    .react-calendar__tile--now {
      background-color: ${colors.uiLightest};
    }
    .react-calendar__tile--now:enabled:hover,
    .react-calendar__tile--now:enabled:focus {
      background-color: ${darkenHsla(3, colors.uiLightest)};
    }
    .react-calendar__tile--hasActive {
      background: ${colors.primary};
      color: ${colors.white};
    }
    .react-calendar__tile--hasActive:enabled:hover,
    .react-calendar__tile--hasActive:enabled:focus {
      background: ${darkenHsla(8, colors.primary)};
    }

    .react-calendar__tile--active {
      background: ${colors.primary};
      color: ${colors.white};
    }
    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
      background: ${darkenHsla(8, colors.primary)};
    }
  `,
});

export default DatePicker;
