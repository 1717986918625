// @flow
/* eslint-disable no-magic-numbers */
import React, { useState, useEffect, Fragment } from 'react';
import { Slider as CompoundSlider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';
import { last } from '@sharkfinesse/sfl-lib';
import SliderRail from './rail';
import Handle from './handle';
import Track from './track';
import Tick from './tick';

const sliderStyle = {
  position: 'relative',
  width: '100%',
  touchAction: 'none',
  marginTop: 8,
};

type Props = {
  sliderValue: Array,
  updateSliderValue: Function,
  domain: Array,
  tickValues: Array,
  controlledValue: Array,
};

const Slider = ({
  sliderValue,
  updateSliderValue,
  domain,
  tickValues,
  controlledValue,
  ...other
}: Props): React.Element<any> => {
  const [values, setValues] = useState(sliderValue);
  const [update, setUpdate] = useState(sliderValue);
  const max = last(domain);

  useEffect(() => {
    if (sliderValue <= max) {
      setValues(sliderValue);
    } else {
      setValues([max]);
    }
  }, [sliderValue, max]);

  const onChange = value => {
    if (value !== values) {
      updateSliderValue(value * 1000000);
    }
    setValues(value);
  };

  const onUpdate = value => {
    if (value !== update) {
      updateSliderValue(value * 1000000);
    }
    setUpdate(value);
  };

  return (
    <CompoundSlider
      mode={1}
      step={1}
      domain={domain}
      rootStyle={sliderStyle}
      onUpdate={onUpdate}
      onChange={onChange}
      values={values}
      {...other}
    >
      <Rail>{({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}</Rail>
      <Handles>
        {({ handles, getHandleProps }) => (
          <Fragment>
            {handles.map(handle => (
              <Handle
                key={handle.id}
                handle={handle}
                domain={domain}
                getHandleProps={getHandleProps}
              />
            ))}
          </Fragment>
        )}
      </Handles>
      <Tracks right={false}>
        {({ tracks, getTrackProps }) => (
          <Fragment>
            {tracks.map(({ id, source, target }) => (
              <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
            ))}
          </Fragment>
        )}
      </Tracks>
      {tickValues && (
        <Ticks values={tickValues}>
          {({ ticks }) => (
            <div>
              {ticks.map(tick => (
                <Tick key={tick.id} tick={tick} count={ticks.length} />
              ))}
            </div>
          )}
        </Ticks>
      )}
    </CompoundSlider>
  );
};

export default Slider;
