import { defineAbility } from '@casl/ability';

function subjectName(item) {
  if (!item || typeof item === 'string') {
    return item;
  }

  return item.__type;
}

export default defineAbility({ subjectName }, can => {
  //   can(['create'], 'PowerPoint');
  //   can(['create'], 'Word');
  //can(['create'], 'benefit_layout');
});
