/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

const IconComponent = props => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M32.2,6.8c-0.1,0-1.9-0.2-3.7,0.3c-3.1,0.7-4.5,2.4-4.6,2.4c0,0.1-0.1,0.1-0.1,0.2c-1.7-0.4-5-1-6.4-1
	c-0.7,0-1.4,0.3-2.2,0.8H9.5l0.1-0.5c0.1-0.3,0-0.7-0.3-0.8C9.1,8.2,8.1,7.5,5.5,7.1C3.7,6.8,1.9,6.8,1.9,6.8
	c-0.4,0-0.7,0.3-0.7,0.7v12.2c0,0.4,0.3,0.8,0.8,0.8H6c0.3,0,0.6-0.2,0.7-0.6l0.1-0.4c2.1,2.1,7,6.7,9.5,7.4
	c0.4,0.1,0.8,0.2,1.2,0.2c0.6,0,1.1-0.2,1.4-0.4c0.5,0.2,1,0.2,1.6-0.1c0.5-0.3,0.9-0.7,1.2-1.1c0.6,0.3,1.1,0.2,1.7-0.1
	c0.6-0.3,1-0.8,1.3-1.2c0.3,0,0.6-0.1,0.9-0.3c0.2-0.1,0.7-0.5,0.8-0.7c0.5-0.6,0.6-1.4,0.3-2.2l0.8-0.7c0.1,0.1,0.3,0.2,0.4,0.2
	h4.1c0.4,0,0.8-0.3,0.8-0.8V7.6C32.9,7.2,32.6,6.9,32.2,6.8z M2.6,19.1V8.3c0.6,0,1.6,0.1,2.6,0.2C6.6,8.8,7.5,9.1,8,9.3l-2.6,9.8
	H2.6z M25.3,22.3c-0.1,0.1-0.3,0.3-0.4,0.3c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.4-0.2-1.1-1-2.8-2.9
	c-0.4-0.5-0.9-1-1.4-1.6c-0.3-0.3-0.8-0.3-1.1-0.1c-0.3,0.3-0.3,0.7-0.1,1.1c0.5,0.6,1,1.1,1.4,1.6c1.2,1.4,2.1,2.4,2.8,3
	c-0.2,0.2-0.4,0.4-0.6,0.5c-0.2,0.1-0.3,0.1-0.6-0.1c0-0.1-0.1-0.1-0.2-0.1c0,0,0,0,0,0c-0.5-0.4-1.2-1.2-2.5-2.7
	c-0.4-0.5-0.9-1-1.4-1.6c-0.3-0.3-0.8-0.3-1.1-0.1c-0.3,0.3-0.3,0.7-0.1,1.1c0.5,0.6,1,1.1,1.4,1.6c1,1.1,1.7,1.9,2.3,2.5
	c-0.2,0.3-0.5,0.5-0.7,0.7c-0.4,0.2-0.8-0.1-3.3-2.9c-0.4-0.5-0.9-1-1.4-1.6c-0.3-0.3-0.7-0.3-1.1-0.1c-0.3,0.3-0.3,0.7-0.1,1.1
	c0.5,0.6,1,1.1,1.4,1.6c0.9,1,1.5,1.7,2.1,2.3c-0.2,0-0.4,0-0.7-0.1C14.6,25,9.4,20.1,7.2,18L9.1,11h4.4c-0.8,0.8-1.4,1.6-1.6,2.1
	c-0.3,0.7-0.5,1.9,0,2.9c0.3,0.5,0.8,1,2,1.1c1.6,0.1,3.6-1.3,4.5-2.1c2,1.7,5.2,4.5,6.6,5.9C25.2,21.2,25.6,21.9,25.3,22.3z
	 M25.9,19.8C25.9,19.8,25.9,19.8,25.9,19.8c-1.6-1.5-5.2-4.7-7.2-6.4c-0.3-0.2-0.7-0.2-1,0c-0.9,0.9-2.8,2.2-3.8,2.1
	c-0.6,0-0.7-0.3-0.8-0.4c-0.2-0.4-0.1-1.1,0.1-1.5c0.4-0.9,2.9-3.5,4.2-3.5c0,0,0,0,0,0c1.3,0,4.9,0.7,6.7,1.1l2.8,7.7L25.9,19.8z
	 M31.4,19.1h-2.8l-3.2-8.9c0.5-0.4,1.6-1.2,3.4-1.6c0.9-0.2,1.9-0.3,2.6-0.2V19.1z"
    />
  </Icon>
);

export default memo(IconComponent);
