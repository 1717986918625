/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

let IconCross = ({ ...props }): React.Element<any> => (
  <Icon {...props}>
    <path
      d="M12 10.586L6.707 5.293a1 1 0 0 0-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 0 0 1.414 1.414L12 13.414l5.293 5.293a1 1 0 0 0 1.414-1.414L13.414 12l5.293-5.293a1 1 0 1 0-1.414-1.414L12 10.586z"
      fill="currentColor"
    />
  </Icon>
);

export default memo(IconCross);
