// @flow
import { useState, useEffect } from 'react';
import LogRocket from 'logrocket';
import { useSessionsFile } from 'redux/firestore/hooks';
import { isNil, isEmpty, getOfflineEnabled } from '@sharkfinesse/sfl-lib';

const isOfflineEnabled = getOfflineEnabled();

const PreCache = ({ offlineSessions, account }) => {
  const [sessionsFileEnabled, setSessionsFileEnabled] = useState(false);
  const [rendered, setRendered] = useState(false);
  useSessionsFile(
    { ids: offlineSessions, account },
    {
      enabled: sessionsFileEnabled,
      onSuccess: (session) => {
        setSessionsFileEnabled(false);
      },
      onError: (error) => {
        console.log('error', error);
        LogRocket.captureException(error);
        setSessionsFileEnabled(false);
      },
    }
  );

  useEffect(() => {
    if (isOfflineEnabled && !isNil(offlineSessions) && !isEmpty(offlineSessions) && !rendered) {
      setSessionsFileEnabled(true);
      setRendered(true);
    }
  }, [setSessionsFileEnabled, setRendered, rendered, offlineSessions]);
  return null;
};

export default PreCache;
