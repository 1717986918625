import { call, all, put, select } from 'redux-saga/effects';
import { actionCreators as evaluatorActionCreators } from 'redux/modules/evaluator';
import { actionCreators as defaultSessionValuesActionCreators } from 'redux/modules/default-session-values';
import {
  __,
  contains,
  filterLinkedValueMatches,
  getDefaultSessionValuesIds,
  getSchemaDefaultSessionValues,
  reject,
  values as objValues,
  defaultSessionValuesSelectors,
  evaluatorSelectors,
} from '@sharkfinesse/sfl-lib';
import calculateEvaluator from './calculateEvaluator';

export function* unlinkDefaultSessionValues({ id, schema, formData, meta }) {
  const defaultSessionsData = yield select(
    defaultSessionValuesSelectors.getDefaultSessionValuesData
  );
  const schemaDefaultSessionValues = getSchemaDefaultSessionValues(schema);
  const linkedMatches = filterLinkedValueMatches(
    defaultSessionsData,
    formData,
    schemaDefaultSessionValues
  );
  const matchedLinkIds = getDefaultSessionValuesIds(linkedMatches, schema.properties);
  const unmatchedLinkIds = reject(
    contains(__, matchedLinkIds),
    objValues(schemaDefaultSessionValues)
  );
  yield put(
    defaultSessionValuesActionCreators.updateCalculate({
      evalUid: id,
      linkedIds: matchedLinkIds,
      unlinkedIds: unmatchedLinkIds,
      meta,
    })
  );
}

export function* linkDefaultSessionValues(action) {
  const meta = action?.meta;
  const { evalUid, fieldId, value, defaultSessionValueId } = action.payload;
  const [currentValue] = yield all([
    select(evaluatorSelectors.getBenefitValue, evalUid, fieldId),
    put(evaluatorActionCreators.updateLinkInput({ ...action.payload, meta })),
  ]);
  if (currentValue !== value) {
    yield call(calculateEvaluator, { id: evalUid, meta });
  } else {
    yield put(
      defaultSessionValuesActionCreators.updateLinkInputComplete({ defaultSessionValueId, evalUid })
    );
  }
}
