import styled from 'styled-components';

export const ButtonLabel = styled.span`
  ${({ theme }) => theme.expandIconButton.buttonLabel()};
`;

export const Button = styled.button`
  ${({ theme }) => theme.expandIconButton.button()};
  &:hover ${ButtonLabel} {
    max-width: 20rem;
    opacity: 1;
  }
`;
