import { evaluatorSelectors } from '@sharkfinesse/sfl-lib';
import { put, call, select, delay } from 'redux-saga/effects';
import { actionCreators as layoutActionCreators } from 'redux/modules/layout';
import { actionCreators as cashflowActionCreators } from 'redux/modules/cashflow';
import { actionCreators as evaluatorActionCreators } from 'redux/modules/evaluator';
import goToNextOrPrevious from '../../benefit/goToNextOrPrevious';
import undoToast from 'redux/sagas/toast/undo';

function* deleteBenefit(action) {
  const { benefitId, suiteId, deleteCurrent = false, goTo } = action.payload;
  const meta = action.meta;

  let currentBenefit = yield select(evaluatorSelectors.getCurrent);

  if (currentBenefit === benefitId && !deleteCurrent) {
    currentBenefit = yield call(goToNextOrPrevious, benefitId, false, goTo);

    yield delay(100);
  }
  yield call(undoToast, {
    action,
    undoActions: [
      put(evaluatorActionCreators.updateDelete({ id: benefitId, deleteCurrent, meta })),
      put(cashflowActionCreators.updateEvaluatorReset({ id: benefitId, meta })),
      put(layoutActionCreators.doDeleteBenefit({ benefitId, suiteId, currentBenefit })),
    ],
    formattedMessageId: 'benefits.delete.toast.message.value',
    defaultMessage: 'Undo delete benefit',
  });
}

export default deleteBenefit;
