import { useQuery } from 'react-query';
import { getCollectionData } from '@sharkfinesse/sfl-lib';
import { fetchUsers } from '../users';

const getItems = async ({ account, product }) => {
  const response = await fetchUsers({ account, product });
  return getCollectionData(response);
};

const useUsers = (props, config) => {
  return useQuery('users', () => getItems(props), config);
};

export default useUsers;
