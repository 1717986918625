/* eslint-disable no-magic-numbers */
import { darkenHsla, setAlphaHsla } from '@sharkfinesse/sfl-lib';
import { css } from 'styled-components';
import { setSaturation } from 'polished';

const Styles = ({
  font,
  colors,
  scale,
  drawerColor = 'hsla(206, 34%, 21%, 1)',
  navbarColor,
  toggleColor = '#466f90',
  defaultDrawerWidth = '366px',
  tabWidth = '66px',
  scrollWidth = '8px',
}) => {
  const navColor = navbarColor ? navbarColor : darkenHsla(10, drawerColor);
  return {
    iconColor: darkenHsla(24, colors.white),
    iconHoverColor: colors.white,
    menuItemIconColor: colors.white,
    aside: ({ expanded, small, collapsible, $drawerWidth = defaultDrawerWidth }) => css`
      position: ${collapsible ? 'relative' : 'unset'};
      will-change: width;
      transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1),
        transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      transition-delay: ${!expanded ? '0' : '0.1s'};
      width: ${small
        ? expanded
          ? 'calc(100vw - 20px)'
          : $drawerWidth
        : expanded
        ? $drawerWidth
        : tabWidth};
      max-width: ${$drawerWidth};
      display: flex;
      flex-direction: row;
      ${small
        ? css`
            position: absolute;
            height: 100%;
            z-index: 20;
            transform: translate3d(${expanded ? '0' : `calc(0px - ${$drawerWidth})`}, 0, 0);
          `
        : null}
    `,
    nav: ({ expanded }) => css`
      height: 100%;
      z-index: 3;
      width: ${expanded ? 'calc(100% - 8px)' : '100%'};
    `,
    navBg: ({ expanded }) => css`
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: ${expanded ? scrollWidth : 0};
      background-color: ${navColor};
      z-index: 1;
    `,
    drawer: ({ expanded, small, $drawerWidth = defaultDrawerWidth }) => css`
      color: ${setAlphaHsla(0.75, colors.white)};
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      max-width: 100%;
      background-color: ${drawerColor};
      background-image: linear-gradient(to right, ${drawerColor} 20%, ${toggleColor} 100%);
      /* background-image: linear-gradient(to left, #333c42 0%, #39444c 100%); */
      overflow: hidden;
    `,
    navWrapper: ({ expanded }) => css`
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      flex-grow: 0;
      height: 100%;
      min-width: ${expanded ? `calc(${tabWidth} + ${scrollWidth})` : tabWidth};
      z-index: 2;
    `,
    navSlider: () => css`
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    `,
    tab: ({ position, opacity, tabHeight }) => css`
      transform: translate3d(0px, ${position}px, 0px);
      height: ${tabHeight}px;
      width: ${tabWidth};
      opacity: ${opacity};
      transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out,
        -webkit-transform 0.25s ease-in-out;
      background: ${drawerColor};
      &::before,
      &::after {
        background: radial-gradient(
            circle closest-side,
            transparent 0,
            transparent 50%,
            ${drawerColor} 0
          )
          200% 200%/400% 400%;
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        overflow: hidden;
        right: 0;
      }
      &::before {
        top: -8px;
      }
      &::after {
        transform: scaleY(-1);
        bottom: -8px;
      }
    `,
    navItemWrapper: ({ selected, tabHeight }) => css`
      position: relative;
      min-height: ${tabHeight}px;
      width: ${tabWidth};
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      label,
      svg {
        transition: opacity 0.3s;
        opacity: ${selected ? 1 : 0.7};
      }
      &:hover {
        label,
        svg {
          opacity: 1;
        }
      }
    `,
    navLabel: ({ selected }) => css`
      cursor: pointer;
      color: ${colors.white};
      font-size: 10px;
      box-sizing: border-box;
      width: 100%;
      line-height: 14px;
      padding: 0 4px;
      text-align: center;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-smooth: always;
      font-family: Open Sans, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
      font-weight: 600;
      z-index: 10;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-word;
    `,
    contentWrapper: () => css`
      padding: 16px 14px 16px 16px;
      width: 100%;
    `,
    contentContainer: ({ small, $drawerWidth = defaultDrawerWidth }) => css`
      position: absolute;
      left: ${tabWidth};
      width: calc(100% - ${tabWidth} - 2px);
      max-width: 100%;
      height: 100%;
      overflow: hidden;
    `,
    toggleWrapper: ({ expanded, small }) => css`
      transition: right 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      transition-delay: ${!expanded ? '0.3s' : 0};
      width: 20px;
      position: absolute;
      top: 50%;
      right: ${small ? '2px' : expanded ? '2px' : '20px'};
      transform: translate(100%, -50%);
      z-index: 2;
      color: ${toggleColor};
      cursor: pointer;
    `,
    toggleArrow: ({ expanded }) => css`
      display: flex;
      position: absolute;
      top: calc(50% - 6px);
      color: ${colors.white};
      width: 12px;
      height: 12px;
      transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      ${!expanded
        ? css`
            transform: rotate(180deg);
            left: 3px;
          `
        : null}
    `,
    menuItemWrapper: ({ iconLeft }) => css`
      text-decoration: none;
      position: relative;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      padding: 4px;
      background: hsla(0, 0%, 100%, 0.1);
      transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
      border-radius: 3px;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      &:hover {
        background: hsla(0, 0%, 100%, 0.2);
      }
      ${iconLeft &&
      css`
        flex-direction: row;
        padding: 12px 10px;
      `}
      border: none;
    `,
    menuLabelWrapper: ({ iconLeft }) => css`
      color: ${colors.white};
      display: block;
      //white-space: ${iconLeft ? 'unset' : 'nowrap'};
      overflow: hidden;
      width: 100%;
      line-height: ${iconLeft ? '13px' : '18px'};
      text-overflow: ellipsis;
      padding: ${iconLeft ? '0 0 0 6px' : '0 8px'};
      padding-top: ${iconLeft ? 0 : '8px'};
      font-family: Open Sans, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
      text-align: ${iconLeft ? 'left' : 'center'};
    `,
    menuLabelTitle: ({ iconLeft }) => css`
      color: ${colors.white};
      line-height: ${iconLeft ? '16px' : '18px'};
      font-size: ${iconLeft ? font.sizeNormal : '12px'};
      font-weight: 600;
      margin-bottom: ${iconLeft ? '0px' : '8px'};
    `,
    menuLabelSubtitle: () => css`
      color: ${setAlphaHsla(0.75, colors.white)};
      font-size: 11px;
      margin-bottom: 0px;
      margin-top: 4px;
    `,
    menuItemIcon: ({ iconLeft }) => css`
      margin-top: ${iconLeft ? 0 : '8px'};
      z-index: 2;
    `,
    divider: () => css`
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid ${setAlphaHsla(0.15, colors.white)};
      padding: 0;
      margin: 20px 4px;
    `,
    section: () => css`
      margin-bottom: 20px;
    `,
    sectionHeader: () => css`
      font-weight: 500;
      margin-top: 0px;
      margin-bottom: 10px;
      font-size: 18px;
    `,
    displayFigure: ({ noBorder }) => css`
      display: flex;
      flex-direction: column;
      padding: ${noBorder ? '12px 4px 0px 4px' : '20px 4px'};
      border: ${noBorder ? 'none' : '1px solid hsla(0, 0%, 100%, 0.1)'};
      border-radius: 3px;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
    `,
    displayFigureTitle: () => css`
      font-size: 14px;
      color: ${setAlphaHsla(0.85, colors.white)};
      font-weight: 400;
    `,
    displayFigureValue: () => css`
      font-size: 28px;
      color: ${colors.primary};
      font-weight: 400;
      display: flex;
    `,
    displayFigureFooter: () => css`
      padding-top: 6px;
    `,
    titleRow: () => css`
      font-size: 14px;
      color: ${setAlphaHsla(0.85, colors.white)};
      font-weight: 500;
      padding: 6px;
      margin-top: 14px;
    `,
    tableRow: () => css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 12px;
      color: ${setAlphaHsla(0.75, colors.white)};
      background-image: linear-gradient(to left, hsla(0, 0%, 100%, 0.08), hsla(0, 0%, 100%, 0));
      font-size: 12px;
      padding: 4px 6px;
      border-bottom: 1px solid ${setAlphaHsla(0.2, colors.white)};
      position: relative;
      .hoverContainer {
        display: flex;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        justify-content: center;
        align-items: center;
        background: ${setSaturation(0.35, setAlphaHsla(0.85, darkenHsla(22, colors.primary)))};
        transition: opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1);
        padding: 3px;
        height: 100%;
        .buttons {
          display: flex;
          flex-direction: row;
          transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
          transform: translateY(10px);
          height: 100%;
          align-items: center;
        }
      }
      &:hover > .hoverContainer {
        opacity: 1;
        .buttons {
          transform: translateY(0);
        }
      }
    `,
    rolloverDivider: () => css`
      height: 80%;
      width: 1px;
      margin: 0 10px;
      background: ${setAlphaHsla(0.2, colors.white)};
    `,
    rowLabel: () => css`
      display: block;
      white-space: nowrap;
      overflow: hidden;
      box-sizing: border-box;
      width: 100%;
      text-overflow: ellipsis;
    `,
    rowValue: () => css`
      text-align: right;
      min-width: 40%;
    `,
    rowSubLabel: () => css`
      font-size: 11px;
      color: ${setAlphaHsla(0.6, colors.white)};
    `,
    rowWrapper: () => css`
      display: flex;
    `,
    backgroundSection: () => css`
      background: ${setAlphaHsla(0.1, colors.white)};
      padding: 12px;
      margin-bottom: 12px;
    `,
    h2Title: () => css`
      font-size: 18px;
      font-weight: 400;
      margin: 0 0 8px 0;
    `,
    link: () => css`
      color: ${colors.white};
      cursor: pointer;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    `,
    button: () => css`
      height: auto;
      color: ${font.color};
      font-size: 13px;
      cursor: pointer;
      border: none;
      border-radius: 4px;
      padding: 4px 8px;
      background-color: ${setAlphaHsla(0.6, colors.white)};
      display: flex;
      justify-content: center;
      align-items: center;
      transition: background 0.3s, box-shadow 0.3s;
      &:hover {
        background-color: ${setAlphaHsla(0.8, colors.white)};
      }
      &:focus {
        background-color: ${setAlphaHsla(0.8, colors.white)};
        box-shadow: 0 0 0 2px ${colors.primary};
      }
    `,
    iconLabelWrapper: () => css`
      display: flex;
      line-height: 1;
      align-items: center;
    `,
    iconWrapper: () => css`
      color: ${colors.white};
    `,
    helpItemWrapper: () => css`
      padding: 8px 8px;
      color: ${colors.white};
      border-left: 5px solid ${darkenHsla(5, colors.primary)};
      background-color: ${setAlphaHsla(0.1, colors.white)};
      font-size: 13px;
      margin-bottom: 10px;
      cursor: pointer;
      border-radius: 3px;
      transition: background-color 0.3s ease-in-out, opacity 0.3s ease-in-out;
      &:hover {
        background-color: ${setAlphaHsla(0.2, colors.white)};
      }
      &:focus {
        background-color: ${setAlphaHsla(0.2, colors.white)};
        box-shadow: 0 0 0 2px ${colors.primary};
      }
    `,
    helpItemLabel: () => css`
      margin-left: 6px;
      display: flex;
      align-items: center;
    `,
  };
};

export default Styles;
