"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const initialState = {
  suites: {},
  suitesOrder: [],
  benefits: {},
  categoryType: '',
  original: {},
  sorting: {
    field: 'title',
    direction: 'asc'
  }
};
var _default = initialState;
exports.default = _default;