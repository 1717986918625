import React from 'react';

const IconToggleTab = () => (
  <svg viewBox="0 0 20 118" width="20" height="118" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M12.6,18l-1.9-1.1C7.7,15.1,2,12.7,2,10.2H0V107.8H2c0-2.5,5.7-4.9,8.7-6.7l1.9-1.1c6-3.5,7.4-8.3,7.4-13.3V31.3c0-5-1.4-9.8-7.4-13.3"
    />
  </svg>
);

export default IconToggleTab;
