/* @flow */
import { simpleAction, createReducer, accountInitialState } from '@sharkfinesse/sfl-lib';

const namespace = (name: string): string => `@@account/${name}`;

// types
export const types = {
  LOADED: namespace('LOADED'),
};

const initialState = accountInitialState;

const reduceLoaded = (state, action) => action.payload;

export default createReducer(
  {
    [types.LOADED]: reduceLoaded,
  },
  initialState
);

//Action Creators
const loaded = simpleAction(types.LOADED);

export const actionCreators = {
  loaded,
};
