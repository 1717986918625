/* @flow */
import React from 'react';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';

import { includes } from '@sharkfinesse/sfl-lib';
import { withTheme } from 'styled-components';

type DefaultProps = {
  maxWidth: any,
  allowSelectAll: boolean,
};

export type Props = DefaultProps & {
  theme: Object,
  styles: Object,
  maxWidth: any,
  allowSelectAll: boolean,
  allOption: {
    label: string,
    value: string,
  },
  options: Array,
  onChange: Function,
  inverse: Boolean,
  async: Boolean,
  isMulti: Boolean,
  useCheckbox: Boolean,
  creatable: Boolean,
};

const Select = ({
  theme,
  styles,
  maxWidth,
  allowSelectAll,
  allOption,
  options,
  onChange,
  inverse,
  async = false,
  isMulti = false,
  useCheckbox = false,
  creatable,
  ...props
}: Props): Element<any> => {
  const customStyles = {
    control: (base, { isFocused }) => ({
      ...base,
      backgroundColor: isFocused
        ? theme.selectControl.backgroundFocused
        : theme.selectControl.background({ inverse }),
      borderColor: isFocused
        ? theme.selectControl.borderColorFocused
        : theme.selectControl.borderColor,
      borderStyle: isFocused
        ? theme.selectControl.borderStyleFocused({ inverse })
        : theme.selectControl.borderStyle({ inverse }),
      borderWidth: theme.selectControl.borderWidth,
      boxShadow: isFocused ? `0 0 0 1px ${theme.colors.primary}` : 'none',
      borderRadius: theme.selectControl.borderRadius,
      padding: theme.selectControl.padding,
      minHeight: '0px',
      maxWidth: maxWidth,
      margin: 0,
      lineHeight: '20px',
      ':hover': {
        boxShadow: isFocused
          ? `0 0 0 1px ${theme.colors.primary}, 0 0 6px ${theme.colors.primary}`
          : `0 0 5px ${theme.selectControl.boxShadowColorHover({ inverse })}`,
        borderColor: isFocused
          ? theme.selectControl.borderColorFocused
          : theme.selectControl.borderColor,
      },
      width: '100%',
      transition:
        'background-color 200ms ease-in-out, border-color 200ms ease-in-out, box-shadow 200ms ease-in-out',
    }),
    valueContainer: base => ({
      ...base,
      padding: !allowSelectAll && isMulti ? '2px 2rem' : theme.selectValueContainer.padding,
      minHeight: '30px',
      color: theme.selectSingleValue.color,
    }),
    singleValue: base => ({
      ...base,
      color: theme.selectSingleValue.color,
    }),
    placeholder: base => ({
      ...base,
      color: inverse ? theme.selectPlaceholder.inverseColor : theme.selectPlaceholder.color,
    }),
    option: (base, { isDisabled, isFocused, isSelected, data }) => ({
      ...base,
      WebkitTapHighlightColor: 'red',
      backgroundColor: isDisabled
        ? theme.selectOption.backgroundDisabled
        : isSelected
        ? theme.selectOption.backgroundSelected
        : isFocused
        ? theme.selectOption.backgroundFocused
        : null,
      color: isDisabled
        ? theme.selectOption.colorDisabled
        : isSelected
        ? theme.selectOption.colorSelected
        : theme.selectOption.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      textAlign: 'left',
      borderBottom: data.isHighlighted ? theme.selectOption.hightlightBorder : 'none',
      fontWeight: data.isHighlighted
        ? theme.selectOption.hightlightFontWeight
        : theme.selectOption.fontWeight,
    }),
    dropdownIndicator: (base, { isFocused }) => ({
      ...base,
      paddingLeft: theme.selectDropdownIndicator.paddingLeft,
      paddingRight: theme.selectDropdownIndicator.paddingRight,
      paddingTop: theme.selectDropdownIndicator.paddingTop,
      paddingBottom: theme.selectDropdownIndicator.paddingBottom,
      color: isFocused
        ? theme.selectDropdownIndicator.colorFocused
        : theme.selectDropdownIndicator.color({ inverse }),
      ':hover': {
        color: theme.selectDropdownIndicator.colorHover,
      },
      cursor: 'pointer',
    }),
    clearIndicator: (base, { isFocused }) => ({
      ...base,
      padding: theme.selectDropdownIndicator.paddingLeft,
      cursor: 'pointer',
      ':hover': {
        color: theme.selectDropdownIndicator.colorHover,
      },
    }),
    menu: base => ({
      ...base,
      marginTop: 2,
      borderRadius: theme.selectMenu.borderRadius,
      background: theme.selectMenu.background,
      border: theme.selectMenu.border,
      boxShadow: theme.selectMenu.boxShadow,
      zIndex: 999,
    }),
    input: base => ({
      ...base,
      paddingBottom: 0,
      margin: 0,
      paddingTop: 0,
    }),
    multiValue: base => ({
      ...base,
      background: theme.multiValue.background,
      margin: '2px',
      lineHeight: '18px',
      borderRadius: '20px',
    }),
    multiValueLabel: base => ({
      ...base,
      padding: '0',
      paddingLeft: '6px',
      display: 'flex',
      alignItems: 'center',
      color: theme.multiValueLabel.color,
      margin: '1px',
      fontSize: '11px',
      fontWeight: '500',
    }),
    multiValueRemove: base => ({
      ...base,
      background: theme.multiValueRemove.background,
      ':hover': {
        color: theme.multiValueRemove.colorHover,
        background: theme.multiValueRemove.backgroundHover,
      },
      cursor: 'pointer',
      margin: '1px',
      padding: '0px 4px',
      borderRadius: '20px',
    }),
    indicatorSeparator: base => ({
      ...base,
      backgroundColor: theme.selectControl.indicatorSeparatorColor({ inverse }),
    }),
    ...styles,
  };

  const SelectComponent = async
    ? creatable
      ? AsyncCreatableSelect
      : AsyncSelect
    : creatable
    ? CreatableSelect
    : ReactSelect;

  return allowSelectAll ? (
    <SelectComponent
      styles={customStyles}
      isMulti={isMulti}
      useCheckbox={useCheckbox}
      {...props}
      options={[allOption, ...options]}
      onChange={(selected, event) => {
        if (options.length === 1) {
          if (selected[selected.length - 1].value === allOption.value) {
            if (event.action === 'deselect-option') {
              return onChange([]);
            }
            return onChange([allOption, ...options]);
          } else if (selected === 1) {
            return onChange([allOption, ...options]);
          } else {
            onChange([]);
          }
        }
        if (selected !== null && selected.length > 0) {
          let result = [];
          if (selected[selected.length - 1].value === allOption.value) {
            return onChange([allOption, ...options]);
          }
          if (selected.length === options.length) {
            if (includes(allOption, selected)) {
              result = selected.filter(option => option.value !== allOption.value);
            } else if (event.action === 'select-option') {
              result = [allOption, ...options];
            }
            return onChange(result);
          }
        }

        return onChange(selected);
      }}
    />
  ) : (
    <SelectComponent
      styles={customStyles}
      options={options}
      onChange={onChange}
      isMulti={isMulti}
      useCheckbox={useCheckbox}
      {...props}
    />
  );
};

Select.defaultProps = {
  maxWidth: null,
  allowSelectAll: false,
  allOption: {
    label: 'Select all',
    value: '*',
  },
};

export default withTheme(Select);
