/* @flow */
import React from 'react';
import { Image } from './styled';

type Props = {
  children: Any,
};

const HeroImage = ({ children, ...props }: Props) => {
  return (
    <Image onError={e => (e.target.style.display = 'none')} {...props}>
      {children}
    </Image>
  );
};

export default HeroImage;
