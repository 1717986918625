const breakpoints = {
  toastMobile: 480,
  xxs: 0,
  xs: 380,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  xxxl: 1600,
  ultrawide: 2400,
};

export default breakpoints;
