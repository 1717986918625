/* @flow */
import {
  createLayout,
  simpleAction,
  simpleActionMeta,
  omit,
  without,
  dissoc,
  isNil,
  sessionLayoutInitialState,
} from '@sharkfinesse/sfl-lib';

const namespace = (name: string): string => `@@session.layout/${name}`;

// types
export const types = {
  CREATE: namespace('CREATE'),
  RESET: namespace('RESET'),
  REVERT: namespace('REVERT'),
  ORIGINAL: {
    SAVE: namespace('ORIGINAL.SAVE'),
  },
  SUITE: {
    ADD: namespace('SUITE.ADD'),
    INSERT: namespace('SUITE.INSERT'),
    INSERTING: namespace('SUITE.INSERTING'),
    INSERTING_COMPLETE: namespace('SUITE.INSERTING_COMPLETE'),
    REMOVE: namespace('SUITE.REMOVE'),
    DELETE: namespace('SUITE.DELETE'),
    DELETE_BENEFITS: namespace('SUITE.DELETE_BENEFITS'),
    DELETE_COMPLETE: namespace('SUITE.DELETE_COMPLETE'),
    MOVE: namespace('SUITE.MOVE'),
    UPDATE_ORDER: namespace('SUITE.UPDATE_ORDER'),
    UPDATE_CUSTOM_NAME: namespace('SUITE.UPDATE_CUSTOM_NAME'),
    DUPLICATE: namespace('SUITE.DUPLICATE'),
    DUPLICATING: namespace('SUITE.DUPLICATING'),
    DUPLICATING_COMPLETE: namespace('SUITE.DUPLICATING_COMPLETE'),
  },
  BENEFIT: {
    ADD: namespace('BENEFIT.ADD'),
    INSERT: namespace('BENEFIT.INSERT'),
    INSERTING: namespace('BENEFIT.INSERTING'),
    INSERTING_COMPLETE: namespace('BENEFIT.INSERTING_COMPLETE'),
    DELETE: namespace('BENEFIT.DELETE'),
    DELETING: namespace('BENEFIT.DELETING'),
    CUSTOM_BENEFIT_DELETE: namespace('BENEFIT.CUSTOM_BENEFIT_DELETE'),
    DO_DELETE: namespace('BENEFIT.DO_DELETE'),
    UNDO_DELETE: namespace('BENEFIT.UNDO_DELETE'),
    UPDATE_ORDER: namespace('BENEFIT.UPDATE_ORDER'),
    DUPLICATE: namespace('BENEFIT.DUPLICATE'),
    DUPLICATING: namespace('BENEFIT.DUPLICATING'),
    DUPLICATING_COMPLETE: namespace('BENEFIT.DUPLICATING_COMPLETE'),
  },
  UPDATE: namespace('UPDATE'),
  SORTING: namespace('SORTING'),
  FILTERS: namespace('FILTERS'),
  SEARCH: namespace('SEARCH'),
};

export const initialState = sessionLayoutInitialState;
// Reducer
export const layout = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE:
      return {
        categoryType: action.payload.categoryType.id,
        ...createLayout(action.payload.categoryType, action.payload.categories),
      };
    case types.RESET:
      return {
        ...state,
        ...state.original,
      };
    case types.BENEFIT.INSERT:
      return {
        ...state,
        ...action.payload.layout,
      };
    case types.SUITE.INSERT:
    case types.SUITE.REMOVE:
      return {
        ...state,
        ...action.payload,
      };
    case types.BENEFIT.DO_DELETE:
      const { benefitId, suiteId } = action.payload;

      return {
        ...state,
        benefits: omit([benefitId], state.benefits),
        suites: {
          ...state.suites,
          [suiteId]: {
            ...state.suites[suiteId],
            benefits: without([benefitId], state.suites[suiteId].benefits),
          },
        },
      };
    case types.BENEFIT.DELETING:
      return {
        ...state,
        benefits: {
          ...state.benefits,
          [action.payload.benefitId]: {
            ...state.benefits[action.payload.benefitId],
            deleting: true,
          },
        },
      };
    case types.BENEFIT.UPDATE_ORDER:
      return {
        ...state,
        suites: {
          ...state.suites,
          [action.payload.suiteId]: {
            ...state.suites[action.payload.suiteId],
            benefits: action.payload.benefits,
          },
        },
      };
    case types.BENEFIT.INSERTING:
      return {
        ...state,
        insertingBenefit: true,
      };
    case types.BENEFIT.INSERTING_COMPLETE:
      return dissoc('insertingBenefit', state);

    case types.BENEFIT.DUPLICATING:
      return {
        ...state,
        benefits: {
          ...state.benefits,
          [action.payload.benefitId]: {
            ...state.benefits[action.payload.benefitId],
            duplicating: true,
          },
        },
      };
    case types.BENEFIT.DUPLICATING_COMPLETE:
      return {
        ...state,
        benefits: {
          ...state.benefits,
          [action.payload.benefitId]: dissoc(
            'duplicating',
            state.benefits[action.payload.benefitId]
          ),
        },
      };
    case types.REVERT:
      return { ...action.payload };
    case types.ORIGINAL.SAVE:
      return {
        ...state,
        original: {
          suites: state.suites,
          benefits: state.benefits,
          suitesOrder: state.suitesOrder,
          categoryType: state.categoryType,
        },
      };
    case types.SUITE.UPDATE_ORDER:
      return {
        ...state,
        suitesOrder: action.payload.suiteOrder,
      };
    case types.SUITE.UPDATE_CUSTOM_NAME:
      return {
        ...state,
        suites: {
          ...state.suites,
          [action.payload.suiteId]: {
            ...state.suites[action.payload.suiteId],
            customName: action.payload.name,
          },
        },
      };
    case types.SUITE.DUPLICATING:
      return {
        ...state,
        suites: {
          ...state.suites,
          [action.payload.suiteId]: {
            ...state.suites[action.payload.suiteId],
            duplicating: true,
          },
        },
      };
    case types.SUITE.DUPLICATING_COMPLETE:
      return {
        ...state,
        suites: {
          ...state.suites,
          [action.payload.suiteId]: dissoc('duplicating', state.suites[action.payload.suiteId]),
        },
      };
    case types.SUITE.INSERTING:
      return {
        ...state,
        insertingSuite: true,
      };
    case types.SUITE.INSERTING_COMPLETE:
      return dissoc('insertingSuite', state);
    case types.UPDATE:
      return action.payload;
    case types.SORTING:
      return {
        ...state,
        sorting: action.payload,
      };
    case types.FILTERS:
      return !isNil(action.payload)
        ? {
            ...state,
            filters: action.payload,
          }
        : { ...state, filters: [] };
    case types.SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    default:
      return state;
  }
};

//Action Creators
const create = simpleAction(types.CREATE);
const addBenefit = simpleAction(types.BENEFIT.ADD);
const insertBenefit = simpleAction(types.BENEFIT.INSERT);
const insertingBenefit = () => ({ type: types.BENEFIT.INSERTING });
const insertingBenefitComplete = simpleAction(types.BENEFIT.INSERTING_COMPLETE);
const updateReset = simpleActionMeta(types.RESET);
const updateRevert = simpleAction(types.REVERT);
const deletingBenefit = simpleAction(types.BENEFIT.DELETING);
const deleteBenefit = simpleAction(types.BENEFIT.DELETE);
const deleteCustomBenefit = simpleAction(types.BENEFIT.CUSTOM_BENEFIT_DELETE);
const doDeleteBenefit = simpleAction(types.BENEFIT.DO_DELETE);
const undoDeleteBenefit = simpleAction(types.BENEFIT.UNDO_DELETE);
const updateOrderBenefit = simpleAction(types.BENEFIT.UPDATE_ORDER);
const duplicateBenefit = simpleAction(types.BENEFIT.DUPLICATE);
const duplicatingBenefit = simpleAction(types.BENEFIT.DUPLICATING);
const duplicatingBenefitComplete = simpleAction(types.BENEFIT.DUPLICATING_COMPLETE);
const updateOriginal = () => ({ type: types.ORIGINAL.SAVE });
const updateOrderSuite = simpleAction(types.SUITE.UPDATE_ORDER);
const updateCustomName = simpleAction(types.SUITE.UPDATE_CUSTOM_NAME);
const addSuite = simpleAction(types.SUITE.ADD);
const insertSuite = simpleAction(types.SUITE.INSERT);
const insertingSuite = () => ({ type: types.SUITE.INSERTING });
const insertingSuiteComplete = () => ({ type: types.SUITE.INSERTING_COMPLETE });
const removeSuite = simpleAction(types.SUITE.REMOVE);
const deleteSuite = simpleAction(types.SUITE.DELETE);
const deleteSuiteComplete = simpleAction(types.SUITE.DELETE_COMPLETE);
const deleteSuiteBenefits = simpleAction(types.SUITE.DELETE_BENEFITS);
const moveSuite = simpleAction(types.SUITE.MOVE);
const duplicateSuite = simpleAction(types.SUITE.DUPLICATE);
const duplicatingSuite = simpleAction(types.SUITE.DUPLICATING);
const duplicatingSuiteComplete = simpleAction(types.SUITE.DUPLICATING_COMPLETE);
const update = simpleAction(types.UPDATE);
const updateSorting = simpleAction(types.SORTING);
const updateFilters = simpleAction(types.FILTERS);
const updateSearch = simpleAction(types.SEARCH);

export const actionCreators = {
  create,
  addBenefit,
  insertBenefit,
  insertingBenefit,
  insertingBenefitComplete,
  updateReset,
  updateRevert,
  deletingBenefit,
  deleteBenefit,
  deleteCustomBenefit,
  doDeleteBenefit,
  undoDeleteBenefit,
  updateOrderBenefit,
  duplicateBenefit,
  duplicatingBenefit,
  duplicatingBenefitComplete,
  updateOriginal,
  updateOrderSuite,
  updateCustomName,
  addSuite,
  insertSuite,
  removeSuite,
  deleteSuite,
  deleteSuiteComplete,
  deleteSuiteBenefits,
  moveSuite,
  duplicateSuite,
  duplicatingSuite,
  duplicatingSuiteComplete,
  insertingSuite,
  insertingSuiteComplete,
  update,
  updateSorting,
  updateFilters,
  updateSearch,
};

export default layout;
