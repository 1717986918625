// @flow
import React from 'react';
import SelectField from '../select-field';
import { components } from 'react-select';
import { StyledCheckbox, ValueWrapper } from './styled';
import { injectIntl } from 'react-intl';
import { IconCross } from '../icon';

type OptionProps = {
  isSelected: boolean,
  label: string | any,
  getStyles: Function,
  useCheckbox: Boolean,
  selectProps: Object,
};

const Option = (props: OptionProps) => {
  const styles = props.getStyles('option', props);
  return (
    <components.Option style={styles} {...props}>
      {props.selectProps.useCheckbox ? (
        <StyledCheckbox checked={props.isSelected} onChange={() => null} label={props.label} />
      ) : (
        props.label
      )}
    </components.Option>
  );
};

const allOption = {
  label: 'Select all',
  value: '*',
  isHighlighted: true,
};

type ValueContainerProps = {
  getValue: Function,
  children: any,
  hasValue: Boolean,
  options: Array,
  intl: Object,
  getStyles: Function,
};

const ValueContainer = ({
  getValue,
  hasValue,
  children,
  options,
  intl,
  ...props
}: ValueContainerProps) => {
  const currentValues = getValue();
  const numSelected = getValue().length;
  const totalLength = options.length - 1;
  let toBeRendered = children;

  if (currentValues.some(val => val.value === allOption.value)) {
    toBeRendered = intl.formatMessage({
      id: 'benefits.suite.viewSelect.viewingAll',
      defaultMessage: 'Viewing all suites',
    });
  } else {
    toBeRendered = intl.formatMessage(
      {
        id: 'benefits.suite.viewSelect.viewing',
        defaultMessage: 'Viewing {numSelected} of {totalLength} suites',
      },
      { numSelected, totalLength }
    );
  }

  return (
    <components.ValueContainer {...props}>
      <ValueWrapper>
        {toBeRendered}
        <span className="dummy-input-wrapper">{children}</span>
      </ValueWrapper>
    </components.ValueContainer>
  );
};

type ClearProps = {
  isSelected: boolean,
  label: string | any,
  getStyles: Function,
};

const ClearIndicator = (props: ClearProps) => {
  return (
    <components.ClearIndicator {...props}>
      <IconCross size="small" />
    </components.ClearIndicator>
  );
};

type ChipLabelMultiValueProps = {
  data: Objectboolean,
  chipLabel: String | Any,
};

const ChipLabelMultiValue = ({ data: { chipLabel }, ...props }: ChipLabelMultiValueProps) => (
  <components.MultiValue {...props}>{chipLabel}</components.MultiValue>
);

type Props = {
  allowSelectAll: Boolean,
  useChipLabel: Boolean,
  hideDropdown: Boolean,
  useCheckbox: Boolean,
  creatable: Boolean,
};

const MultiSelect = ({
  allowSelectAll,
  useChipLabel = false,
  hideDropdown = false,
  useCheckbox,
  creatable,
  ...props
}: Props): React.Element<any> => {
  const compnents = {
    Option,
    ...(allowSelectAll && { ValueContainer: injectIntl(ValueContainer) }),
    ...(useChipLabel && { MultiValue: ChipLabelMultiValue }),
    ...(hideDropdown && { DropdownIndicator: () => null }),
    ...(hideDropdown && { IndicatorSeparator: () => null }),
    ClearIndicator,
  };
  return (
    <SelectField
      layout="column"
      isMulti
      openMenuOnClick={true}
      closeMenuOnSelect={true}
      hideSelectedOptions={false}
      isSearchable={false}
      components={compnents}
      allowSelectAll={allowSelectAll}
      useCheckbox={useCheckbox}
      creatable={creatable}
      {...props}
    />
  );
};

export default React.memo(MultiSelect);
