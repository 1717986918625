/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

const IconComponent = props => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M8.7,18.6c-0.2-0.2-0.5-0.3-0.8-0.2c-0.3,0.1-0.5,0.4-0.5,0.7V25c0,0.4,0.3,0.8,0.8,0.8H14
		c0.3,0,0.6-0.2,0.7-0.5s0.1-0.6-0.2-0.8L8.7,18.6z M8.9,24.3V21l3.3,3.3H8.9z"
    />
    <path
      fill="currentColor"
      d="M24.9,15.2c2.5-3.6,6.5-9.8,5.2-10.7l0,0c-1.4-1-5.8,4.9-8.3,8.5c-1.2,1.8-2,3.1-2.3,4
		c-0.1,0.1-0.1,0.3-0.1,0.4c-1.3-0.3-2.7,0.1-3.5,1.3c-0.2,0.2-0.3,0.5-0.4,0.8L4.8,8.9C4.6,8.7,4.3,8.6,4,8.7
		C3.7,8.8,3.5,9.1,3.5,9.4v19.5c0,0.4,0.3,0.8,0.8,0.8h19.5c0.3,0,0.6-0.2,0.7-0.5c0.1-0.3,0.1-0.6-0.2-0.8l-4.8-4.8
		c0.7-0.3,1.3-0.7,1.6-1.2c0.5-0.7,0.7-1.6,0.5-2.5c-0.1-0.3-0.2-0.6-0.3-0.8c0.2-0.1,0.4-0.2,0.6-0.4C22.7,18.1,23.7,17,24.9,15.2z
		 M22,28.2H5v-17l9.8,9.8l-0.2,0.5l-0.6,0.9c0,0,0,0,0,0c-0.1,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0.1
		c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0.1,0c0,0,0,0,0,0
		c0,0,0,0,0.1,0c0.8,0.3,1.7,0.4,2.5,0.4c0.2,0,0.4,0,0.7,0L22,28.2z M19.9,21.5c-0.3,0.4-1,0.8-1.9,0.9c0,0,0,0,0,0
		c-0.5,0.1-1.3,0-2-0.1c0-0.1,0-0.1,0.1-0.2c0,0,0,0,0-0.1l0-0.1c0.1-0.3,0.2-0.6,0.3-1c0.2-0.6,0.3-1.2,0.6-1.6
		c0.6-0.8,1.7-1,2.5-0.4c0,0,0,0,0,0c0.4,0.3,0.7,0.7,0.7,1.2C20.3,20.7,20.2,21.1,19.9,21.5z M20.8,17.8c0.1-0.3,0.4-1.3,2.2-3.9
		c1.6-2.3,3.5-4.7,4.9-6.3c-1,1.9-2.6,4.5-4.2,6.8C21.9,17,21.1,17.6,20.8,17.8z"
    />
  </Icon>
);

export default memo(IconComponent);
