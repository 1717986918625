// @flow
import React, { useState, isValidElement } from 'react';
import Menu, { MenuList } from '../menu';
import MenuItem from '../menu-item';
import Button from '../button';
import { IconDotsVertical } from '../icon';
import 'styled-components/macro';

type Props = {
  children: Any,
};

const EllipsisMenu = ({ children }: Props) => {
  const [open, setOpen] = useState(false);

  const updateOpen = (value, e) => {
    e.stopPropagation();
    setOpen(value);
  };

  const set = open => setOpen(open);

  const allItems = React.Children.toArray(children)
    .filter(child => {
      return isValidElement(child);
    })
    .map((child, index) => {
      return (
        <MenuItem
          key={`menu-child-${index}`}
          onClick={e => {
            updateOpen(false, e);
          }}
        >
          {child}
        </MenuItem>
      );
    });

  return (
    <Menu
      open={open}
      onVisibilityChange={set}
      referenceComponent={
        <Button size="icon" variant="flat" color="light">
          <IconDotsVertical
            css={`
              transform: rotate(90deg);
            `}
          />
        </Button>
      }
      usePortal={true}
      triggerOnClick={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <MenuList>{allItems}</MenuList>
    </Menu>
  );
};

export default EllipsisMenu;
