/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

const IconComponent = props => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M25.2,31.2H8.8c-1.7,0-3.1-1.4-3.1-3V5.8c0-1.7,1.4-3,3.1-3h16.3c1.7,0,3.1,1.4,3.1,3v22.4C28.2,29.9,26.9,31.2,25.2,31.2z
	 M8.8,4.2C8,4.2,7.2,4.9,7.2,5.8v22.4c0,0.8,0.7,1.5,1.6,1.5h16.3c0.9,0,1.6-0.7,1.6-1.5V5.8c0-0.8-0.7-1.5-1.6-1.5H8.8z"
    />
    <path
      fill="currentColor"
      d="M11.7,24.8H9.8c-0.3,0-0.6,0.2-0.6,0.6v1.9c0,0.3,0.2,0.6,0.6,0.6h1.9c0.3,0,0.6-0.2,0.6-0.6v-1.9
	C12.2,25,12,24.8,11.7,24.8L11.7,24.8z"
    />
    <path
      fill="currentColor"
      d="M18.1,24.8h-1.9c-0.3,0-0.6,0.2-0.6,0.6v1.9c0,0.3,0.2,0.6,0.6,0.6h1.9c0.3,0,0.6-0.2,0.6-0.6v-1.9
	C18.6,25,18.4,24.8,18.1,24.8L18.1,24.8z"
    />
    <path
      fill="currentColor"
      d="M11.7,18.8H9.8c-0.3,0-0.6,0.2-0.6,0.6v1.9c0,0.3,0.2,0.6,0.6,0.6h1.9c0.3,0,0.6-0.2,0.6-0.6v-1.9
	C12.2,19,12,18.8,11.7,18.8L11.7,18.8z"
    />
    <path
      fill="currentColor"
      d="M18,18.8h-1.9c-0.3,0-0.6,0.2-0.6,0.6v1.9c0,0.3,0.2,0.6,0.6,0.6H18c0.3,0,0.6-0.2,0.6-0.6v-1.9C18.6,19,18.3,18.8,18,18.8
	L18,18.8z"
    />
    <path
      fill="currentColor"
      d="M11.7,12.8H9.8c-0.3,0-0.6,0.2-0.6,0.6v1.9c0,0.3,0.2,0.6,0.6,0.6h1.9c0.3,0,0.6-0.2,0.6-0.6v-1.9
	C12.2,13.1,12,12.8,11.7,12.8L11.7,12.8z"
    />
    <path
      fill="currentColor"
      d="M18.1,12.8h-1.9c-0.3,0-0.6,0.2-0.6,0.6v1.9c0,0.3,0.2,0.6,0.6,0.6h1.9c0.3,0,0.6-0.2,0.6-0.6v-1.9
	C18.6,13.1,18.4,12.8,18.1,12.8L18.1,12.8z"
    />
    <path
      fill="currentColor"
      d="M24.4,12.8h-1.9c-0.3,0-0.6,0.2-0.6,0.6v1.9c0,0.3,0.2,0.6,0.6,0.6h1.9c0.3,0,0.6-0.2,0.6-0.6v-1.9
	C25,13.1,24.8,12.8,24.4,12.8L24.4,12.8z"
    />
    <path
      fill="currentColor"
      d="M10.6,7.5L10.6,7.5L10.6,7.5 M23.5,7.5c0,0,0,0.1,0,0.2v1.6c0,0.1,0,0.1,0,0.2H10.5c0,0,0-0.1,0-0.2V7.7c0-0.1,0-0.1,0-0.2
	H23.5 M23.6,6H10.4C9.6,6,9,6.8,9,7.7v1.6c0,0.9,0.6,1.7,1.4,1.7h13.2c0.8,0,1.4-0.8,1.4-1.7V7.7C25,6.8,24.4,6,23.6,6L23.6,6z"
    />
    <path
      fill="currentColor"
      d="M24.4,18.8h-1.9c-0.3,0-0.6,0.3-0.6,0.7l0,7.6c0,0.4,0.2,0.7,0.6,0.7h1.9c0.3,0,0.6-0.3,0.6-0.7l0-7.6
	C25,19.1,24.7,18.8,24.4,18.8L24.4,18.8z"
    />
  </Icon>
);

export default memo(IconComponent);
