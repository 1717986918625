/* @flow */
import {
  simpleAction,
  createReducer,
  simpleReducer,
  sessionBusinessPreparationInitialState,
} from '@sharkfinesse/sfl-lib';
const namespace = (name: string): string => `@@session.businessPreparation/${name}`;

//Actions
export const types = {
  SELECTED_BENEFITS: {
    UPDATE: namespace('SELECTED_BENEFITS.UPDATE'),
  },
};

//Reducer
export const initialState = sessionBusinessPreparationInitialState;

const reduceSelectedBenefits = simpleReducer('selectedBenefits');

export default createReducer(
  {
    [types.SELECTED_BENEFITS.UPDATE]: reduceSelectedBenefits,
  },
  initialState
);

//Action Creators
const updateSelectedBenefits = simpleAction(types.SELECTED_BENEFITS.UPDATE);

export const actionCreators = {
  updateSelectedBenefits,
};
