import { css } from 'styled-components';
import { setAlphaHsla } from '@sharkfinesse/sfl-lib';

const Hero = ({ font, colors, scale, device: { min_sm, min_lg, min_md } }) => ({
  hero: () => css`
    display: flex;
    width: 100%;
    position: relative;
    overflow: hidden;
    text-shadow: 0px 1px ${setAlphaHsla(0.16, colors.black)};
    color: ${colors.white};
  `,
  background: () => css`
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `,
  image: () => css`
    display: flex;
    position: absolute;
    mix-blend-mode: luminosity;
  `,
  button: () => css`
    border: 0;
    border-radius: 60px;
    background: ${setAlphaHsla(0.3, colors.white)};
    padding: 8px 18px;
    text-shadow: 0px 1px ${setAlphaHsla(0.16, colors.black)};
    font-size: 14px;
    color: ${colors.white};
    font-weight: ${font.weightSemiBold};
    letter-spacing: 1px;
    cursor: pointer;
    width: fit-content;
    margin: 12px 0;
    text-decoration: none;
    transition: background 0.3s;
    &:hover {
      background: ${setAlphaHsla(0.45, colors.white)};
    }
  `,
  content: () => css`
    display: flex;
    flex-direction: column;
    z-index: 1;
    justify-content: center;
    margin: 24px 26px;
    @media ${min_sm} {
      margin: 30px;
    }
  `,
  title: () => css`
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 14px;
    line-height: 26px;
    @media ${min_sm} {
      font-size: 24px;
    }
  `,
  lede: () => css`
    font-size: 16px;
    margin-bottom: 4px;
    margin-top: 4px;
    line-height: 20px;
    max-width: 100%;
    @media ${min_sm} {
      font-size: 18px;
      line-height: 20px;
      max-width: 80%;
      margin-bottom: 10px;
      margin-top: 10px;
    }
  `,
  body: () => css`
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 18px;
    p {
      margin-top: 0;
      margin-bottom: 10px;
    }
  `,
  openCloseLinkContainer: () => css`
    position: absolute;
    right: 6px;
    top: 2px;
    @media ${min_sm} {
      right: 12px;
    }
  `,
  openCloseLink: ({ isOpen }) => css`
    display: flex;
    font-size: 12px;
    text-shadow: 0px 1px ${setAlphaHsla(0.16, colors.black)};
    color: ${isOpen ? colors.white : font.color};
    align-items: center;
    align-self: flex-end;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  `,
  homeHeroImage: () => css`
    opacity: 1;
  `,
});

export default Hero;
