/* @flow */
import {
  simpleAction,
  createReducer,
  preferencesLanguageInitialState,
} from '@sharkfinesse/sfl-lib';
const namespace = (name: string): string => `@@preferences.language/${name}`;

//Actions
export const types = {
  LANGUAGE: {
    UPDATE: namespace('LANGUAGE.UPDATE'),
  },
};

//Reducer
export const initialState = preferencesLanguageInitialState;

const reduceLanguage = (state, action) => ({
  ...state,
  ...action.payload,
});

export default createReducer(
  {
    [types.LANGUAGE.UPDATE]: reduceLanguage,
  },
  initialState
);

//Action Creators
const updateLanguage = simpleAction(types.LANGUAGE.UPDATE);

export const actionCreators = {
  updateLanguage,
};
