/* eslint-disable no-magic-numbers */
import {
  placeholder,
  hsla,
  lightenHsla,
  darkenHsla,
  setAlphaHsla,
  merge,
} from '@sharkfinesse/sfl-lib';
import {
  stripUnit,
  margin,
  borderStyle,
  borderColor,
  borderWidth,
  padding,
  backgrounds,
} from 'polished';

import { css } from 'styled-components';

import {
  Checkbox,
  Colors,
  Font,
  Scale,
  Body,
  HeadingH1Default,
  HeadingH1Suite,
  HeadingSubDefault,
  HeadingSubSuite,
  Panel,
  ButtonSmall,
  ButtonNormal,
  ButtonLarge,
  ButtonDefault,
  ButtonPrimary,
  ButtonLight,
  Suite,
  SuiteButton,
  Evaluator,
  Scroll,
  DividerDefault,
  Icons,
  Costs,
  NavBarEvaluator,
  NavBarPills,
  Logo,
  Header,
  DividerNav,
  SelectControl,
  SelectDropdownIndicator,
  SelectSingleValue,
  SelectPlaceholder,
  SelectMenu,
  SelectOption,
  Modal,
  PageToolBar,
  Popper,
  Menu,
  Toast,
  BusinessCaseOutputs,
  Page,
  Toolbar,
  BenefitLayoutDesigner,
  AddButton,
  CompareSessions,
  ReactTable,
  SuitesToolbar,
  MultiValueLabel,
  Account,
  Loader,
  Sections,
  Alerts,
  InputConfirm,
  CashFlow,
  AnimatedIcons,
  Sidebar,
  ExpandIconButton,
  Preferences,
  MediaManager,
  breakpoints,
  Device,
  MainMenu,
  Slide,
  Tabs,
  Skeleton,
  BurgerMenu,
  TitlePane,
  Carousel,
  Tender,
  Delay,
  DoNothing,
  Hero,
  SelectToolbar,
  Notification,
  ListPanel,
  ExpandingListItem,
  General,
  DatePicker,
  Drawer,
  ToolbarButton,
  Avatar,
  Form,
  Search,
  Picker,
  Timeline,
  RadioButton,
} from './core';
import BaseTheme from './base';

const baseAlpha = 1;
const baseSaturation = hsla(placeholder, 73);
const grays = hsla(0, 0);
const black = hsla(0, 0, 0, baseAlpha);
const white = hsla(0, 0, 100, baseAlpha);
const oranges = baseSaturation(33);
const reds = baseSaturation(354);
const uiBase = grays(0, baseAlpha);
//*******************************
// config
//******************************
const colors = Colors();
//colors.primary = greens(42, baseAlpha);
colors.secondary = white;
colors.warning = oranges(50, baseAlpha);
colors.invalid = reds(45, baseAlpha);
colors.uiBase = uiBase;
colors.uiDarkest = lightenHsla(0, uiBase);
colors.uiDark = lightenHsla(2, uiBase);
colors.ui = lightenHsla(9, uiBase);
colors.uiLight = lightenHsla(13, uiBase);
colors.uiLightest = lightenHsla(40, uiBase);
colors.popperBox = hsla(0, 0, 100, baseAlpha);

colors.gradientsCls = css`
  ${colors.gradientsCls}
  .gradient1,
  .gradient2,
  .gradient4,
  .gradient12,
  .gradient9,
  .gradient8,
  .gradient11 {
    background: linear-gradient(180deg, rgb(84 84 84) 0%, rgb(5 5 5) 80%, rgb(5 5 5) 100%);
  }
`;

const font = Font();
font.size = '14px';
font.color = lightenHsla(75, uiBase);

const config = {
  colors,
  font,
  scale: Scale(),
  breakpoints: breakpoints,
  device: Device,
};

const scale = Scale();
//*******************************
// overrides
//******************************
const chartTheme = 'dark';

const body = Body(config);
body.background = backgrounds(colors.ui);

const headingH1Default = HeadingH1Default(config);
headingH1Default.fontColor = colors.secondary;

const headingH1Suite = HeadingH1Suite(config);
headingH1Suite.fontColor = colors.secondary;

const headingSubDefault = HeadingSubDefault(config);
headingSubDefault.fontColor = colors.secondary;

const headingSubSuite = HeadingSubSuite(config);
headingSubSuite.fontColor = colors.secondary;

const panelBase = Panel(config);
const panel = merge(panelBase, {
  base: () => css`
    ${panelBase.base}
    background: ${colors.ui};
    border: none;
    border-radius: 0;
  `,
  header: ({ collapsible, isOpen }) => css`
    ${panelBase.header}
    border-bottom-color: ${lightenHsla(3, colors.uiLight)};
    ${collapsible &&
    !isOpen &&
    css`
      transition: background 0.3s;

      &:hover {
        background: ${colors.uiLightest};
      }
      cursor: pointer;
    `}
  `,
  iconSecondaryColor: colors.ui,
});

const buttonSmall = ButtonSmall(config);
buttonSmall.borderRadius = '2em';

const buttonNormal = ButtonNormal(config);
buttonNormal.borderRadius = '2em';

const buttonLarge = ButtonLarge(config);
buttonLarge.borderRadius = '3em';

const buttonDefault = ButtonDefault(config);
buttonDefault.background = backgrounds(setAlphaHsla(0.2, colors.ui));
buttonDefault.fontColor = colors.secondary;
buttonDefault.letterSpacing = '2px';
buttonDefault.borderWidth = borderWidth('2px');
buttonDefault.borderColor = borderColor(setAlphaHsla(0.5, colors.secondary));
buttonDefault.textShadow = 'none';
buttonDefault.boxShadow = 'none';
buttonDefault.hoverBackground = backgrounds(null);
buttonDefault.hoverBorderStyle = borderStyle('solid');
buttonDefault.hoverBorderWidth = borderWidth('2px');
buttonDefault.hoverBorderColor = borderColor(colors.secondary);
buttonDefault.activeBoxShadow = 'none';
buttonDefault.borderStyle = borderStyle('solid');
buttonDefault.hoverFontColor = colors.white;

const buttonPrimary = ButtonPrimary(config);
buttonPrimary.letterSpacing = '2px';
buttonPrimary.textShadow = 'none';
buttonPrimary.boxShadow = 'none';

const buttonLight = ButtonLight(config);
buttonLight.fontColor = colors.white;

const suiteBase = Suite(config);
const suite = merge(suiteBase, {
  base: () => css`
    ${suiteBase.base}
    background: ${colors.ui};
    border: none;
  `,
  header: () => css`
    ${suiteBase.header}
    padding: ${scale.minusOne} ${scale.minusOne} ${scale.minusFive} ${scale.minusOne};
    border-style: 0 0 1px 0;
    border-width: solid;
    border-color: ${lightenHsla(10, colors.uiLight)};
  `,
  headerContainer: () => css`
    ${suiteBase.headerContainer}
    padding: 0 0 ${scale.minusFour} 0;
  `,
  heading: () => css`
    ${suiteBase.heading}
    color: ${colors.secondary};
    font-size: ${font.sizeLarge};
    font-weight: ${font.weightBold};
  `,
  subHeading: () => css`
    ${suiteBase.subHeading}
    color: ${setAlphaHsla(0.5, colors.secondary)};
  `,

  scrollContainer: () => css`
    ${suiteBase.scrollContainer}
    background: ${colors.uiDark};
  `,
  designerBase: ({ selected }) => css`
    ${selected &&
    css`
      box-shadow: 0px 0px 0px 2px ${colors.primary};
    `}
  `,
  dragHandle: () => css`
    ${suiteBase.dragHandle}
    color: ${colors.white};
  `,
});

const suiteButtonBase = SuiteButton(config);
const suiteButton = merge(suiteButtonBase, {
  gridUnit: () => css`
    ${suiteButtonBase.gridUnit}
    &.sortable-chosen {
      .suite-btn {
        border: 2px solid ${colors.primary};
        background: ${colors.black};
        &:hover {
          box-shadow: none;
        }
      }
    }
  `,
  button: ({ result, isError }) => css`
    ${suiteButtonBase.button}
    background: ${colors.uiLight};
    border: none;
    border-radius: 0;
    ${result
      ? css`
          border: 1px solid ${colors.secondary};
        `
      : null}
    ${isError
      ? css`
          border: 2px solid ${colors.invalid};
        `
      : null}
        &:hover {
      background: ${lightenHsla(10, colors.uiLight)};
      box-shadow: none;
    }
    &.nav-item-active {
      border: 1px solid ${colors.primary};
    }
    &.nav-item-active:hover {
      box-shadow: none;
    }
  `,
  heading: () => css`
    ${suiteButtonBase.heading}
    color: ${colors.secondary};
  `,
  subHeading: () => css`
    ${suiteButtonBase.subHeading}
    color: ${colors.uiLightest};
  `,
  result: () => css`
    ${suiteButtonBase.result}
    color: ${colors.secondary};
    font-weight: ${font.weightLight};
    border-color: ${colors.secondary};
    background: transparent;
  `,

  hoveredButton: ({ btnStyle }) => css`
    ${btnStyle === 'dark'
      ? css`
          background: radial-gradient(
            farthest-corner at 50% 50%,
            rgba(50, 50, 50, 0.5) 30%,
            #000000 100%
          );
        `
      : css`
          background: radial-gradient(
            farthest-corner at 50% 50%,
            rgba(50, 50, 50, 0.5) 30%,
            #000000 100%
          );
        `};
    border-radius: 3px;
    height: 100%;
    border: 2px solid ${colors.primary};
  `,
  layoutButtonBg: () => colors.uiDarkest,
});

const scrollBase = Scroll(config);
const scroll = merge(scrollBase, {
  thumb: () => css`
    .ScrollbarsCustom-Thumb {
      border-radius: 0px !important;
      background: ${colors.uiLightest} !important;
      &:hover {
        background: ${lightenHsla(15, colors.uiLightest)} !important;
      }
    }
    .dragging {
      background: ${lightenHsla(15, colors.uiLightest)} !important;
    }
  `,
  track: src => css`
    .ScrollbarsCustom-Track {
      border-radius: 0px !important;
      background: ${src ? 'transparent' : colors.uiLightest} !important;
    }
  `,
});

const dividerDefault = DividerDefault(config);
dividerDefault.background = backgrounds(colors.uiLightest);

const icons = Icons(config);
icons.defaultColor = colors.uiLightest;
icons.inverseColor = black;

const TableCellPadding = `${stripUnit(scale.minusFour) / 2}em`;

const costsBase = Costs(config);
const costs = merge(costsBase, {
  fontColor: colors.white,
  borderColor: borderColor(colors.uiLightest),
  headBorderColor: borderColor(colors.uiLightest),
  borderWidth: borderWidth('0px'),
  headBackground: backgrounds('transparent'),
  footBackground: backgrounds('transparent'),
  rowBackground: colors.ui,
  droppableBackground: colors.ui,
  rowBackgroundHover: backgrounds(colors.uiLight),
  headCellPadding: padding(scale.minusFour, TableCellPadding),
  cashflowWrapper: () => css`
    ${costsBase.cashflowWrapper()}
    background: ${colors.ui};
  `,
  rowWrapper: ({ $hideBottomBorder }) => css`
    ${costsBase.rowWrapper({ $hideBottomBorder })}
    border: 0;
  `,
});

const navBarEvaluator = NavBarEvaluator(config);
navBarEvaluator.background = backgrounds(colors.ui);
navBarEvaluator.borderColor = borderColor(colors.uiLight);
navBarEvaluator.navItemBorderWidth = borderWidth('0px');
navBarEvaluator.navItemBorderWidthHover = borderWidth('0px');
navBarEvaluator.navItemBorderWidthActive = borderWidth('0px');
navBarEvaluator.fontColor = colors.uiLightest;
navBarEvaluator.fontColorHover = colors.secondary;
navBarEvaluator.fontColorActive = colors.secondary;
navBarEvaluator.navItemBackground = backgrounds(colors.ui);
navBarEvaluator.navItemBackgroundHover = backgrounds(colors.ui);
navBarEvaluator.navItemBackgroundActive = backgrounds(colors.ui);
navBarEvaluator.labelPadding = padding('11px', '14px', '8px', '14px');
navBarEvaluator.inkBarHeight = '3px';
navBarEvaluator.inkBarMargin = margin(0, '18px');

const navBarPills = NavBarPills(config);
navBarPills.background = backgrounds(colors.ui);
navBarPills.borderColor = borderColor(colors.ui);
navBarPills.navItemBorderWidth = borderWidth('0px');
navBarPills.navItemBorderWidthHover = borderWidth('0px');
navBarPills.navItemBorderWidthActive = borderWidth('0px');
navBarPills.fontColor = colors.uiLightest;
navBarPills.fontColorHover = colors.secondary;
navBarPills.fontColorActive = colors.secondary;
navBarPills.navItemBackground = backgrounds(colors.ui);
navBarPills.navItemBackgroundHover = backgrounds(colors.ui);
navBarPills.navItemBackgroundActive = backgrounds(colors.ui);
navBarPills.labelPadding = padding('11px', '14px', '8px', '14px');
navBarPills.inkBarHeight = '3px';
navBarPills.inkBarMargin = margin(0, '18px');

const logo = Logo(config);
logo.bottomBlockColor = colors.uiLightest;

const headerBase = Header(config);
const header = merge(headerBase, {
  base: () => css`
    ${headerBase.base}
    background: ${colors.uiDark};
  `,
  h1: () => css`
    ${headerBase.h1}
    color: ${colors.secondary};
  `,
  animatedIcons: {
    primary: colors.white,
    secondary: colors.primary,
  },
  toolbar: () => css`
    ${headerBase.toolbar}
    background: ${colors.uiDark};
  `,
});

const dividerNav = DividerNav(config);
dividerNav.background = backgrounds(colors.uiLightest);

const selectControl = SelectControl(config);

selectControl.background = ({ inverse }) => (inverse ? colors.uiLight : colors.uiLight);
selectControl.backgroundFocused = colors.uiLight;
selectControl.indicatorSeparatorColor = ({ inverse }) =>
  inverse ? setAlphaHsla(0.5, colors.uiLightest) : setAlphaHsla(0.5, colors.uiLightest);

const selectDropdownIndicator = SelectDropdownIndicator(config);
selectDropdownIndicator.color = ({ inverse }) => (inverse ? colors.uiLightest : colors.uiLightest);
selectDropdownIndicator.colorHover = colors.white;
selectDropdownIndicator.colorFocused = colors.white;

const selectSingleValue = SelectSingleValue(config);
selectSingleValue.color = colors.white;

const selectPlaceholder = SelectPlaceholder(config);
selectPlaceholder.color = colors.uiLightest;

const selectMenu = SelectMenu(config);
selectMenu.background = colors.ui;
selectMenu.border = '1px solid white';

const multiValueLabel = MultiValueLabel(config);
multiValueLabel.color = colors.white;

const selectOption = SelectOption(config);
selectOption.color = colors.white;

const pageToolBar = PageToolBar(config);
pageToolBar.iconSecondaryColor = colors.uiDarkest;

const modalBase = Modal(config);
const modal = merge(modalBase, {
  iconSecondaryColor: colors.ui,
  header: () => css`
    ${modalBase.header}
    color: ${colors.white};
    background: ${colors.ui};
  `,
  headerKeyline: () => css`
    ${modalBase.headerKeyline}
    box-shadow: 0 1px 0 0 ${colors.uiDarkest};
    background: ${colors.uiLight};
  `,
  body: ({ showFooterKeyline, showHeaderKeyline, showFooter }) => css`
    ${modalBase.body({ showFooterKeyline, showHeaderKeyline, showFooter })}
    color: ${colors.white};
    background: ${colors.ui};
  `,
  footer: () => css`
    ${modalBase.footer}
    color: ${colors.white};
    background: ${colors.ui};
  `,
  footerKeyline: () => css`
    ${modalBase.footerKeyline}
    box-shadow: 0 -1px 0 0 ${colors.uiDarkest};
    color: ${colors.white};
    background: ${colors.uiLight};
  `,
  blanket: () => css`
    ${modalBase.blanket}
    background-color: ${setAlphaHsla(0.54, colors.white)};
  `,
  loadingWrapper: () => css`
    ${modalBase.loadingWrapper}
    background-color: ${setAlphaHsla(0.8, colors.uiDarkest)};
  `,
});

const TooltipColor = colors.uiLight;
const popperBase = Popper(config);
const popper = merge(popperBase, {
  box: ({ tipStyle, zIndex = 9000 }) => css`
      ${popperBase.box}
      background-color: ${TooltipColor};
      border: ${TooltipColor} 1px solid;
      color: ${font.color}};
      box-shadow: 0px 10px 25px 0px rgba(0,0,0,1);
      &[data-popper-placement*='bottom'] .tooltip-arrow {
        top: 0;
      left: 0;
      margin-top: -10px;
      &::before {
        top: -11px;
        left: -1px;
        border: 11px solid;
        border-color: transparent transparent ${TooltipColor} transparent;
      }
      &::after {
        bottom: 9px;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent ${TooltipColor} transparent;
      }
    }
    &[data-popper-placement*='top'] .tooltip-arrow {
      bottom: 0;
      left: 0;
      margin-bottom: -10px;
      &::before {
        bottom: -11px;
        left: -1px;
        border: 11px solid;
        border-color: ${TooltipColor} transparent transparent transparent;
      }
      &::after {
        top: 9px;
        border-width: 10px 10px 0 10px;
        border-color: ${TooltipColor} transparent transparent transparent;
      }
    }
    &[data-popper-placement*='right'] .tooltip-arrow {
      left: 0;
      margin-left: -10px;
      &::before {
        left: -11px;
        border: 11px solid;
        border-color: transparent ${TooltipColor} transparent transparent;
        top: -1px;
      }
      &::after {
        right: 9px;
        border-width: 10px 10px 10px 0;
        border-color: transparent ${TooltipColor} transparent transparent;
      }
    }
    &[data-popper-placement*='left'] .tooltip-arrow {
      right: 0;
      margin-right: -11px;
      &::before {
        right: -11px;
        border: 11px solid;
        border-color: transparent transparent transparent ${TooltipColor};
        top: -1px;
      }
      &::after {
        left: 8px;
        border-width: 10px 0 10px 10px;
        border-color: transparent transparent transparent ${TooltipColor};
      }
    }
    `,
});

const menuBase = Menu(config);
const menu = merge(menuBase, {
  item: ({ compact }) => css`
    ${menuBase.item({ compact })}
    color: ${font.color};
    &:hover {
      background-color: ${colors.uiLightest};
      color: ${colors.white};
    }
    a {
      color: ${font.color};
      text-decoration: none;
    }
  `,
  tip: () => css`
    ${menuBase.tip()}
    color: inherit;
  `,
});

const toastBase = Toast(config);
const toast = merge(toastBase, {
  base: () => css`
    ${toastBase.base}
    background-color: ${colors.uiLight};
  `,
  savingContainer: () => css`
    ${toastBase.savingContainer}
    background-color: ${colors.uiDark};
  `,
});

const evaluatorBase = Evaluator(config);
const evaluator = merge(evaluatorBase, {
  base: () => css`
    ${evaluatorBase.base}
    background-color: ${colors.ui};
  `,
  heading: () => css`
    ${evaluatorBase.heading}
    color: ${colors.secondary};
  `,
  subHeading: () => css`
    ${evaluatorBase.subHeading}
    color: ${setAlphaHsla(0.5, colors.secondary)};
  `,
  scrollContainer: () => css`
    ${evaluatorBase.scrollContainer}
    border-left: none;
  `,
  backButton: {
    ...evaluatorBase.backButton,
    iconColor: colors.secondary,
  },
  drawerEdge: () => css`
    ${evaluatorBase.drawerEdge}
    border-right: 1px solid ${colors.uiLightest};
  `,
  drawerShadow: () => css`
    ${evaluatorBase.drawerShadow()}
    background: linear-gradient(to left, rgb(0 0 0 / 30%) 0%, rgba(0, 0, 0, 0) 100%);
  `,
  chartHeading: () => css`
    ${evaluatorBase.chartHeading()}
    color: ${colors.white};
  `,
  chartPanel: () => css`
    ${evaluatorBase.chartPanel()}
    background: ${colors.uiLight};
  `,
});

const businessCaseOutputsBase = BusinessCaseOutputs(config);
const businessCaseOutputs = merge(businessCaseOutputsBase, {
  iconColumn: () => css`
    ${businessCaseOutputsBase.iconColumn}
    h2 {
      font-size: ${font.sizeLarge};
      color: ${colors.secondary};
      font-weight: ${font.weightSemiBold};
      margin: 10px 0 0 0;
    }
  `,
  outputsColumn: () => css`
    ${businessCaseOutputsBase.outputsColumn}
    h3 {
      font-size: ${font.sizeNormal};
      color: ${colors.secondary};
      font-weight: ${font.weightSemiBold};
      margin: 0;
    }
  `,
});

const pageBase = Page(config);
const page = merge(pageBase, {
  mainSection: ({ floatingMenu }) => css`
    ${pageBase.mainSection({ floatingMenu })}
    background: ${colors.uiDark};
    border-top: 1px solid ${colors.uiLightest};
  `,
});

const toolbarBase = Toolbar(config);
const toolbar = merge(toolbarBase, {
  divider: color => css`
    ${toolbarBase.divider}
    border-right: 1px solid ${color === 'dark'
      ? colors.uiLightest
      : setAlphaHsla(0.3, colors.uiLightest)};
  `,
});

const checkboxBase = Checkbox(config);
const checkbox = merge(checkboxBase, {
  base: ({ checked, round, indeterminate }) => css`
    ${checkboxBase.base({ checked, round, indeterminate })}
    background: ${checked || indeterminate ? colors.primary : round ? font.color : 'transparent'};
  `,
});

const benefitLayoutDesignerBase = BenefitLayoutDesigner(config);
const benefitLayoutDesigner = merge(benefitLayoutDesignerBase, {
  toolbar: () => css`
    ${benefitLayoutDesignerBase.toolbar}
    background: ${colors.uiDarkest};
  `,
  suiteName: () => css`
    ${benefitLayoutDesignerBase.suiteName}
    color: ${colors.secondary};
  `,
  footerMessage: () => css`
    ${benefitLayoutDesignerBase.footerMessage}
    color: ${colors.white};
    background: ${colors.primary};
  `,
  editBodyWrapper: () => css`
    ${benefitLayoutDesignerBase.editBodyWrapper}
    background: ${colors.ui};
  `,
});
const addButtonBase = AddButton(config);
const addButton = merge(addButtonBase, {
  button: () => css`
    ${addButtonBase.button}
    border: 2px dashed ${colors.white};
    color: ${colors.white};
  `,
});

const compareSessionsBase = CompareSessions(config);
const compareSessions = merge(compareSessionsBase, {
  selected: {
    background: black,
  },
  unselected: {
    background: 'transparent',
  },
});

const reactTableBase = ReactTable(config);
const reactTable = merge(reactTableBase, {
  container: () => css`
    ${reactTableBase.container}
    box-shadow: none;
    color: white;
  `,
  table: () => css`
    ${reactTableBase.table}
    background: ${colors.ui};
  `,
  head: () => css`
    ${reactTableBase.head}
    background: ${colors.ui};
  `,
  row: ({ $variant, $numberOfChildren, $selected, $useRowSelect }) => css`
    ${reactTableBase.row({ $variant, $numberOfChildren, $selected, $useRowSelect })}

    background-color: ${colors.ui};
    ${$useRowSelect &&
    css`
      cursor: pointer;
      ${!$selected &&
      css`
        &:hover {
          background-color: ${colors.uiLight};
        }
      `}
      ${$selected &&
      css`
        background-color: ${colors.uiLightest};
        box-shadow: 0 0 0 2px ${colors.primary};
        border-radius: 2px;
        position: relative;
      `}
    `}
    border-bottom: solid 1px ${lightenHsla(3, colors.uiLight)};
    ${$variant === 'head' &&
    css`
      user-select: none;
      background: ${colors.ui};
      &:first-child {
        background: ${colors.ui};
        font-weight: ${font.weightSemiBold};
        border-bottom: none;
      }
    `}
  `,
  cell: ({ $variant, $isSticky, $hover, $hovered, $selected }) => css`
    ${reactTableBase.cell({ $variant, $isSticky, $hover, $hovered, $selected })}
    border-bottom: none;
    ${$hovered &&
    !$selected &&
    css`
      background-color: ${colors.uiDark};
      border: 1px solid ${colors.uiDark};
    `}
    ${$selected &&
    css`
      background-color: ${colors.uiDarkest};
      border: 1px solid ${colors.uiDarkest};
    `}
    ${$variant === 'head' &&
    css`
      border-right: solid 1px ${lightenHsla(3, colors.uiLight)};
      &:first-child {
        justify-content: center;
      }
      &:last-child {
        border-right: none;
      }
    `};
  `,
});

const suitesToolbarBase = SuitesToolbar(config);
const suitesToolbar = merge(suitesToolbarBase, {
  container: () => css`
    ${suitesToolbarBase.container}
    background: ${colors.uiDark};
    border-bottom: 1px solid ${colors.uiLight};
  `,
});

const accountBase = Account(config);
const account = merge(accountBase, {
  animatedLabel: () => css`
    ${accountBase.animatedLabel}
    color: ${colors.white};
  `,
  name: () => css`
    ${accountBase.name}
    color: ${font.color};
  `,
  email: () => css`
    ${accountBase.email}
    color: ${font.color};
  `,
  labelBackgroundFrom: colors.uiLightest,
  labelBackgroundTo: colors.uiDark,
});

const loaderBase = Loader(config);
const loader = merge(loaderBase, {
  color: colors.white,
  lightColor: colors.white,
  container: () => css`
    ${loaderBase.container}
    background: ${setAlphaHsla(0.8, colors.uiDarkest)};
  `,
  label: () => css`
    ${loaderBase.label}
    color: ${font.color};
  `,
});

const alertsBase = Alerts(config);
const alerts = merge(alertsBase, {
  base: () => css`
    ${alertsBase.base}
    border: 1px solid ${colors.uiLightest};
  `,
});

const sectionsBase = Sections(config);
const sections = merge(sectionsBase, {
  base: () => css`
    ${sectionsBase.base}
    border: 1px solid ${colors.uiLightest};
  `,
});

const inputConfirmBase = InputConfirm(config);
const inputConfirm = merge(inputConfirmBase, {
  iconWrapper: () => css`
    ${inputConfirmBase.base}
    opacity: 1;
    color: ${colors.black};
  `,
});

const cashFlowBase = CashFlow(config);
const cashFlow = merge(cashFlowBase, {
  dragger: () => css`
    ${cashFlowBase.dragger}
    border-color: ${colors.uiLight};
  `,
  wrapper: ({ $expanded }) => css`
    ${cashFlowBase.wrapper({ $expanded })}
    background: ${colors.ui};
  `,
  header: () => css`
    ${cashFlowBase.header()}
    background: ${colors.ui};
  `,
  rowHeader: () => css`
    ${cashFlowBase.rowHeader()}
    background: ${colors.ui};
  `,
  cell: () => css`
    ${cashFlowBase.cell()}
    background: ${colors.ui};
  `,
});
const animatedIconsBase = AnimatedIcons(config);
const animatedIcons = merge(animatedIconsBase, {
  primary: colors.white,
  secondary: colors.primary,
});

const sidebarBase = Sidebar({
  ...config,
  drawerColor: colors.ui,
  navbarColor: colors.uiDark,
  toggleColor: colors.ui,
});
const sidebar = merge(sidebarBase, {
  button: () => css`
    ${sidebarBase.button()}
    color: ${colors.white};
    background-color: ${setAlphaHsla(0.2, colors.white)};
    &:hover {
      background-color: ${setAlphaHsla(0.4, colors.white)};
    }
    &:focus {
      background-color: ${setAlphaHsla(0.4, colors.white)};
      box-shadow: 0 0 0 2px ${colors.primary};
    }
  `,
  navBg: ({ expanded }) => css`
    ${sidebarBase.navBg({ expanded })}
    border-top: 1px solid ${colors.uiLightest};
    border-right: 1px solid ${colors.uiLightest};
  `,
  tab: ({ position, opacity, tabHeight }) => css`
    ${sidebarBase.tab({ position, opacity, tabHeight })}
    border-top: 1px solid ${colors.uiLightest};
    border-bottom: 1px solid ${colors.uiLightest};
    &::before,
    &::after {
      background: none;
    }
  `,
  drawer: ({ expanded, small, $drawerWidth = '366px' }) => css`
    ${sidebarBase.drawer({ expanded, small, $drawerWidth })}
    border-top: 1px solid ${colors.uiLightest};
    border-right: 1px solid ${colors.uiLightest};
  `,
  toggleWrapper: ({ expanded, small }) => css`
    ${sidebarBase.toggleWrapper({ expanded, small })}
    filter: ${`drop-shadow(1px 0px 0px ${colors.uiLightest})`};
    right: ${small ? '2px' : expanded ? '2px' : '21px'};
  `,
});

const expandIconButtonBase = ExpandIconButton(config);
const expandIconButton = merge(expandIconButtonBase, {
  button: () => css`
    ${expandIconButtonBase.button()}
    border: 1px solid ${colors.ui};
    background: ${font.color};
    color: ${colors.ui};
    &:hover {
      border: 1px solid ${colors.primary};
      color: ${colors.ui};
    }
  `,
});
const preferencesBase = Preferences(config);
const preferences = merge(preferencesBase, {
  reportThemeSection: () => css`
    ${preferencesBase.reportThemeSection()}
    background: ${colors.uiDarkest};
  `,
});
const mediaManagerBase = MediaManager(config);
const mediaManager = merge(mediaManagerBase, {
  addSessionImageButton: ({ inverse }) => css`
    ${mediaManagerBase.addSessionImageButton({ inverse })}
    color: ${inverse ? setAlphaHsla(0.6, colors.white) : colors.white};
  `,
});

const mainMenuBase = MainMenu(config);
const mainMenu = merge(mainMenuBase, {
  aside: ({ open, float }) => css`
    ${mainMenuBase.aside({ open, float })}
    background: ${colors.ui};
    border-right: none;
    border-top: none;
  `,
  menuContainer: ({ open, float }) => css`
    ${mainMenuBase.menuContainer({ open, float })}
    padding: 12px 0;
  `,
  menuItemWrapper: ({ active }) => css`
    ${mainMenuBase.menuItemWrapper({ active })}
    background: none;
    cursor: pointer;
    &:hover {
      background: none;
      color: ${colors.white};
    }
    &.active {
      color: ${colors.white};
      background: none;
      span {
        position: relative;
        &::before {
          background: ${colors.primary};
          content: '';
          position: absolute;
          left: -14px;
          border-radius: 3px;
          width: 3px;
          height: 100%;
        }
      }
    }
  `,
  heading: () => css`
    ${mainMenuBase.heading()}
    color: ${colors.uiLightest};
  `,
});

const slideBase = Slide(config);
const slide = merge(slideBase, {
  slideBox: ({ checked, disabled }) => css`
    ${slideBase.slideBox({ checked, disabled })}
    background: ${colors.uiLight};
  `,
  slideBody: ({ $empty }) => css`
    ${slideBase.slideBody({ $empty })}
    ${$empty &&
    css`
      background: ${colors.ui};
    `};
  `,
  footerLabel: ({ empty }) => css`
    ${slideBase.footerLabel({ empty })}
    color: ${colors.white};
  `,
  footerCenter: () => css`
    ${slideBase.footerCenter()}
    color: ${colors.white};
  `,
  footerEmptyLabel: ({ $empty }) => css`
    ${slideBase.footerEmptyLabel({ $empty })}
    color: ${font.color};
  `,
  footerDate: ({ $empty }) => css`
    ${slideBase.footerDate({ $empty })}
    color: ${font.color};
  `,
  detailsLabel: () => css`
    ${slideBase.detailsLabel()}
    color: ${font.color};
  `,
  detailsValue: () => css`
    ${slideBase.detailsValue()}
    font-weight: 700;
  `,
  valueLabel: () => css`
    ${slideBase.valueLabel()}
    color: ${font.color};
  `,
  toolbarContent: () => css`
    ${slideBase.toolbarContent()}
    border-top: 1px solid ${setAlphaHsla(0.3, colors.uiLightest)};
  `,
  toolbarDivider: () => css`
    ${slideBase.toolbarDivider()}
    border-right: 1px solid ${setAlphaHsla(0.3, colors.uiLightest)};
  `,
  presetHoverSummary: () => css`
    ${slideBase.presetHoverSummary()}
    color: ${font.color};
  `,
  columnList: () => css`
    ${slideBase.columnList()}
    color: ${font.color};
  `,
  pendingBox: () => css`
    ${slideBase.pendingBox()}
    background: ${setAlphaHsla(0.95, colors.ui)};
    color: ${colors.white};
  `,
  emptySvg: () => css`
    ${slideBase.emptySvg()}
    rect:first-of-type {
      fill: brown;
    }
  `,
  dateModified: () => css`
    ${slideBase.dateModified()}
    color: ${font.color};
  `,
  loading: () => css`
    ${slideBase.loading()}
    background: ${setAlphaHsla(0.75, colors.ui)};
  `,
});

const tabsBase = Tabs(config);
const tabs = merge(tabsBase, {
  verticalTabs: ({ selected }) => css`
    ${tabsBase.verticalTabs({ selected })}
  `,
  tab: ({ disabled, fullWidth, wrapped, label, selected, icon, inverse }) => css`
    ${tabsBase.tab({ disabled, fullWidth, wrapped, label, selected, icon, inverse })}
    color: ${inverse ? colors.white : font.color};
    ${selected &&
    css`
      color: ${inverse ? colors.white : colors.primary};
      opacity: 1;
    `};
  `,
});

const getBgColor = ({ color, colors }) => {
  //const color = css`background: linear-gradient(0deg, rgba(30, 135, 240, 1) 0%, rgba(64, 188, 249, 1) 100%)`
  if (color === 'uiLightest') return colors.uiLightest;
  if (color === 'white') return colors.ui;
  if (color === 'primary') return colors.primary;
};

const skeletonBase = Skeleton({ ...config, bgColor: getBgColor });
const skeleton = merge(skeletonBase, {
  container: ({ color = 'uiLightest', borderRadius, id }) => css`
    ${skeletonBase.container({ color, borderRadius, id })}
  `,
});

const burgerMenuBase = BurgerMenu(config);
const burgerMenu = merge(burgerMenuBase, {
  burger: ({ isOpen }) => css`
    ${burgerMenuBase.burger({ isOpen })}
    div {
      background: ${colors.white};
    }
  `,
});

const titlePaneBase = TitlePane(config);
const titlePane = merge(titlePaneBase, {
  title: ({ isOpen }) => css`
    ${titlePaneBase.title({ isOpen })}
    color: ${colors.white};
  `,
});

const carouselBase = Carousel(config);
const carousel = merge(carouselBase, {
  sliderContainer: () => css`
    ${carouselBase.sliderContainer()}
    .sliderBtn {
      background: ${colors.uiLightest};
    }
  `,
});

const tenderBase = Tender(config);
const tender = merge(tenderBase, {
  label: () => css`
    ${tenderBase.label()}
    color: ${colors.ui};
  `,
  priceImporvementWrapper: () => css`
    ${tenderBase.priceImporvementWrapper()}
    background: ${darkenHsla(10, colors.uiLightest)};
  `,
  lostBenefitsWrapper: () => css`
    ${tenderBase.lostBenefitsWrapper()}
    background: ${darkenHsla(10, colors.uiLightest)};
  `,
});
const delayBase = Delay(config);
const delay = merge(delayBase, {
  label: () => css`
    ${delayBase.label()}
    color: ${colors.ui};
  `,
  lostBenefitsWrapper: () => css`
    ${delayBase.lostBenefitsWrapper()}
    background: ${darkenHsla(10, colors.uiLightest)};
  `,
});
const doNothingBase = DoNothing(config);
const doNothing = merge(doNothingBase, {
  label: () => css`
    ${doNothingBase.label()}
    color: ${colors.ui};
  `,
  lostValueWrapper: () => css`
    ${doNothingBase.lostValueWrapper()}
    background: ${darkenHsla(10, colors.uiLightest)};
  `,
});

const heroBase = Hero(config);
const hero = merge(heroBase, {
  homeHeroImage: () => css`
    ${heroBase.homeHeroImage()}
    opacity: 0.3;
  `,
});

const selectToolbarBase = SelectToolbar(config);
const selectToolbar = merge(selectToolbarBase, {
  container: () => css`
    ${selectToolbarBase.container()}
    background-color: ${setAlphaHsla(0.9, colors.uiLight)};
  `,
});

const notificationBase = Notification(config);
const notification = merge(notificationBase, {
  wrapper: ({ onClick, $variant }) => css`
    ${notificationBase.wrapper({ onClick, $variant })}
    background-color: ${setAlphaHsla(0.9, colors.uiLight)};
  `,
  subLabel: () => css`
    ${notificationBase.subLabel()}
    color: ${font.color};
  `,
  iconContainer: () => css`
    ${notificationBase.iconContainer()}
    color: ${colors.white};
  `,
});
const listPanelBase = ListPanel(config);
const listPanel = merge(listPanelBase, {
  itemLabel: () => css`
    ${listPanelBase.itemLabel()}
    color: ${colors.white};
  `,
  itemSubLabel: () => css`
    ${listPanelBase.itemSubLabel()}
    color: ${font.color};
  `,
  itemRow: () => css`
    ${listPanelBase.itemRow()}
    &:hover {
      background: ${setAlphaHsla(0.1, colors.white)};
      border-radius: 4px;
      border: 0;
    }
  `,
});
const drawerBase = Drawer(config);
const drawer = merge(drawerBase, {
  toggleArrow: ({ expanded }) => css`
    ${drawerBase.toggleArrow({ expanded })}
    color: ${colors.white};
  `,
  toggleWrapper: () => css`
    ${drawerBase.toggleWrapper()}
    color: ${colors.ui};
    filter: ${`drop-shadow(1px 0px 0px ${colors.uiLightest})`};
  `,

  background: ({ $draggable, $position, $showToggle, $background }) => css`
    ${drawerBase.background({ $draggable, $position, $showToggle, $background })}
    background: ${$background === '2' ? colors.ui : colors.ui};
  `,
});

const expandingListItemBase = ExpandingListItem(config);
const expandingListItem = merge(expandingListItemBase, {
  container: ({ isOpen, selected, small }) => css`
    ${expandingListItemBase.container({ isOpen, selected, small })}
    background: ${colors.uiLight};
    ${selected
      ? css`
          box-shadow: 0 0 0 ${small ? '1px' : '2px'} ${colors.primary};
          background: ${lightenHsla(10, colors.uiLight)};
        `
      : css`
          transition: background 0.3s, box-shadow 0.3s;
          box-shadow: 0 0 0 1px ${colors.uiLight};
          &:hover {
            box-shadow: 0 0 0 ${small ? '1px' : '2px'} ${lightenHsla(10, colors.primary)};
            background: ${lightenHsla(10, colors.uiLight)};
          }
        `}
  `,
});

const generalBase = General(config);
const general = merge(generalBase, {
  label: () => css`
    ${generalBase.label()}
    color: ${colors.white};
  `,
  subLabel: () => css`
    ${generalBase.subLabel()}
    color: ${font.color};
  `,
  pillBg: () => css`
    ${generalBase.pillBg()}
    background: ${colors.uiLight};
  `,
  imageBg: colors.ui,
});
const datePickerBase = DatePicker(config);
const datePicker = merge(datePickerBase, {
  base: () => css`
    ${datePickerBase.base()}
    &.react-calendar {
      width: 300px;
      border: none;
      font-family: ${font.family};
      background: none;
      button {
        font-family: ${font.family};
      }
    }
    abbr[title] {
      text-decoration: none;
    }
    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus {
      background-color: ${darkenHsla(3, colors.uiLightest)};
    }
    .react-calendar__navigation button[disabled] {
      background-color: ${colors.uiDarkest};
      color: ${colors.uiLightest};
    }
    .react-calendar__tile {
      color: ${colors.white};
    }
    .react-calendar__month-view__days__day--weekend {
      color: ${colors.white};
    }
    .react-calendar__month-view__days__day--neighboringMonth {
      color: ${font.color};
    }
    .react-calendar__tile:disabled {
      background-color: ${colors.uiDarkest};
      color: ${colors.uiLightest};
    }
    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
      background-color: ${darkenHsla(3, colors.uiLightest)};
    }
    .react-calendar__tile--now {
      background-color: ${colors.uiLightest};
    }
    .react-calendar__tile--now:enabled:hover,
    .react-calendar__tile--now:enabled:focus {
      background-color: ${darkenHsla(3, colors.uiLightest)};
    }
    .react-calendar__tile--hasActive {
      background: ${colors.primary};
      color: ${colors.white};
    }
    .react-calendar__tile--hasActive:enabled:hover,
    .react-calendar__tile--hasActive:enabled:focus {
      background: ${darkenHsla(8, colors.primary)};
    }

    .react-calendar__tile--active {
      background: ${colors.primary};
      color: ${colors.white};
    }
    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
      background: ${darkenHsla(8, colors.primary)};
    }
  `,
});

const toolbarButtonBase = ToolbarButton(config);
const toolbarButton = merge(toolbarButtonBase, {
  button: ({ toggled, disabled }) => css`
    ${toolbarButtonBase.button({ toggled, disabled })}
    color: ${colors.secondary};
  `,
});
const avatarBase = Avatar(config);
const avatar = merge(avatarBase, {
  wrapper: ({ $border }) => css`
    ${avatarBase.wrapper({ $border })}
    border: none;
    ${$border &&
    css`
      box-shadow: 0 0 0 2px ${colors.uiDark};
    `}
  `,
  container: ({ $border }) => css`
    ${avatarBase.container({ $border })}
    ${$border &&
    css`
      box-shadow: 0 0 0 2px ${colors.uiDark};
    `}
  `,
});

const formBase = Form(config);
const form = merge(formBase, {
  base: ({
    $selected,
    $inverse,
    $focused,
    $expanded,
    $autosize,
    $invalid,
    $rounded,
    $topRounded,
    $align,
    $multiline,
  }) => css`
    ${formBase.base({
      $selected,
      $inverse,
      $focused,
      $expanded,
      $autosize,
      $invalid,
      $rounded,
      $topRounded,
      $align,
      $multiline,
    })}
    ::placeholder {
      color: ${$inverse ? colors.uiLightest : colors.uiLightest};
    }
  `,
  wrapper: ({
    $focused,
    $autosize,
    $expanded,
    $invalid,
    $selected,
    $inverse,
    disabled,
    $rounded,
  }) => {
    const boxShadow = hover => {
      if ($rounded || $inverse || $autosize || $expanded) return 'none';
      if ($selected)
        return `0 0 0 1px ${colors.primary}${hover ? `, 0 0 8px ${colors.primary};` : ';'}`;
      if ($invalid)
        return `0 0 0 ${$focused ? '2px' : '1px'} ${colors.invalid}${
          hover ? `,0 0 8px ${colors.invalid};` : ';'
        };`;
      if ($focused)
        return `0 0 0 2px ${colors.primary}${hover ? `, 0 0 8px ${colors.primary};` : ';'}`;
      return `${hover && !disabled ? `0 0 8px ${colors.uiLightest};` : 'none'}`;
    };
    const bg = hover => {
      if ($autosize || $expanded) return 'transparent';
      if ($inverse) {
        if (disabled) return setAlphaHsla(0.2, colors.uiLight);
        if ($focused) return colors.uiLight;
        if (hover) return setAlphaHsla(0.8, colors.uiLight);
        return setAlphaHsla(0.6, colors.uiLight);
      }
      if ($selected) return setAlphaHsla(0.15, colors.primary);
      if (disabled) return lightenHsla(3, colors.uiLightest);
      if ($invalid) return colors.uiLight;
      return colors.uiLight;
    };
    return css`
      color: ${disabled ? setAlphaHsla(0.5, font.color) : font.color};
      width: 100%;
      display: flex;
      transition: ${$inverse
        ? 'none'
        : 'background-color 200ms ease-in-out, ease-in-out, box-shadow 200ms ease-in-out'};
      border-radius: ${$rounded ? '60px' : '2px'};
      box-shadow: ${boxShadow()};
      &:hover {
        box-shadow: ${boxShadow(true)};
        ${$inverse &&
        css`
          background: ${bg(true)};
        `}
      }
      background: ${bg()};
    `;
  },
  bottomButton: () => css`
    ${formBase.bottomButton()}
    color: ${colors.white};
  `,
  outputWrapper: ({ $align }) => css`
    ${formBase.outputWrapper({ $align })}
    background: ${colors.uiLightest};
    border-radius: 1px;
  `,
  expandedInput: () => css`
    ${formBase.expandedInput()}
    background: ${setAlphaHsla(0.4, colors.uiLightest)};
  `,
  expandedLabel: () => css`
    ${formBase.expandedLabel()}
    background: ${setAlphaHsla(0.4, colors.uiLightest)};
  `,
  expandedToggle: ({ $expanded }) => css`
    ${formBase.expandedToggle({ $expanded })}
    @media ${Device.min_sm} {
      justify-self: end;
      margin-right: -8px;
      background: ${setAlphaHsla(0.4, colors.uiLightest)};
      padding: 4px 0 4px 4px;
      border-radius: ${$expanded ? '6px 0 0 0' : '6px 0 0 6px'};
    }
  `,
  panelBase: () => css`
    ${formBase.panelBase}
    background-color: ${colors.ui};
  `,

  panelHeading: () => css`
    ${formBase.panelHeading}
    color: ${colors.white};
  `,
  panelHeaderContainer: () => css`
    ${formBase.panelHeaderContainer}
    margin-bottom: 8px;
    border-bottom: 1px solid ${colors.uiLightest};
  `,
});

const searchBase = Search(config);
const search = merge(searchBase, {
  beforeContainerRelative: ({ $rounded }) => css`
    ${searchBase.beforeContainerRelative({ $rounded })}
    color: ${colors.white};
  `,
});

const pickerBase = Picker(config);
const picker = merge(pickerBase, {
  expandedContainer: () => css`
    ${pickerBase.expandedContainer()}
    background: none;
  `,
  slideBox: () => css`
    ${pickerBase.slideBox()}
    background: ${colors.uiDarkest};
  `,
  title: () => css`
    ${pickerBase.title()}
    color: ${colors.white};
  `,
  listItem: () => css`
    ${pickerBase.listItem()}
    .hoverContainer {
      display: flex;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      justify-content: center;
      align-items: center;
      background: ${setAlphaHsla(0.8, colors.uiLight)};
      transition: opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1);
      padding: 3px;
      height: 100%;
      .hoverContent {
        display: flex;
        flex-direction: row;
        transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
        transform: translateY(10px);
        height: 100%;
        align-items: center;
      }
    }
  `,
  hoverValue: () => css`
    ${pickerBase.hoverValue()}
    background-color: ${setAlphaHsla(0.2, colors.uiDark)};
    color: ${colors.white};
  `,
});

const timelineBase = Timeline(config);
const timeline = merge(timelineBase, {
  timelineDot: ({ $color }) => css`
    ${timelineBase.timelineDot({ $color })}

    ${$color === 'grey' &&
    css`
      background: ${colors.uiLightest};
    `}
  `,
});

const radioButtonBase = RadioButton(config);
const radioButton = merge(radioButtonBase, {
  outline: ({ $checked }) => css`
    ${radioButtonBase.outline({ $checked })}
    box-shadow: 0 0 0 2px ${$checked ? colors.primary : colors.uiLightest};
  `,
});

const overrides = {
  chartTheme,
  body,
  headingH1Default,
  headingH1Suite,
  headingSubDefault,
  headingSubSuite,
  panel,
  buttonSmall,
  buttonNormal,
  buttonLarge,
  buttonDefault,
  buttonPrimary,
  buttonLight,
  suite,
  suiteButton,
  evaluator,
  scroll,
  dividerDefault,
  icons,
  costs,
  navBarEvaluator,
  navBarPills,
  logo,
  header,
  dividerNav,
  selectControl,
  selectDropdownIndicator,
  selectSingleValue,
  selectPlaceholder,
  selectMenu,
  selectOption,
  modal,
  pageToolBar,
  popper,
  menu,
  toast,
  businessCaseOutputs,
  page,
  toolbar,
  checkbox,
  benefitLayoutDesigner,
  addButton,
  compareSessions,
  reactTable,
  suitesToolbar,
  multiValueLabel,
  account,
  loader,
  sections,
  alerts,
  inputConfirm,
  cashFlow,
  animatedIcons,
  sidebar,
  expandIconButton,
  preferences,
  mediaManager,
  mainMenu,
  slide,
  tabs,
  skeleton,
  burgerMenu,
  titlePane,
  carousel,
  tender,
  delay,
  doNothing,
  hero,
  selectToolbar,
  notification,
  listPanel,
  expandingListItem,
  general,
  datePicker,
  drawer,
  toolbarButton,
  avatar,
  form,
  search,
  picker,
  timeline,
  radioButton,
};

const theme = BaseTheme('Dark', config, overrides);
export default theme;
