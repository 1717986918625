"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getProgressData = exports.createLayout = void 0;

var _id = require("./id");

var _fp = require("./fp");

/* eslint-disable fp/no-mutation  */
const createLayout = (categoryType, categories) => {
  const benefits = {};

  const convertToBenefits = evaluators => evaluators.map(n => {
    const id = (0, _id.generateShortId)();
    benefits[id] = {
      id,
      eNo: n
    };
    return id;
  });

  const suitesOrder = (0, _fp.times)(_id.generateShortId, categoryType.categories.length);
  const suites = Object.assign({}, ...Object.keys(categoryType.categories).map((k, index) => {
    const id = suitesOrder[index];
    return {
      [id]: {
        id: id,
        categoryId: categories[categoryType.categories[k]].id,
        name: categories[categoryType.categories[k]].name,
        benefits: convertToBenefits(categories[categoryType.categories[k]].evaluators)
      }
    };
  }));
  return {
    suites,
    suitesOrder,
    benefits
  };
};

exports.createLayout = createLayout;

const getProgressData = (uiSchema, benefitData) => {
  const isInput = type => {
    const widgetType = (0, _fp.prop)('ui:widget', type);
    return !(0, _fp.isNil)(widgetType) ? (0, _fp.contains)('Input', widgetType) ? true : false : false;
  };

  const inputs = (0, _fp.mapObjIndexed)(isInput, uiSchema);
  const progressData = (0, _fp.pickBy)(val => val === true, inputs);
  const inputKeys = (0, _fp.keys)(progressData);
  const completedKeys = !(0, _fp.isNil)(benefitData) ? (0, _fp.keys)(benefitData.values) : 0;
  const completed = (0, _fp.filter)(item => (0, _fp.contains)(item, inputKeys), completedKeys);
  const numberOfInputs = (0, _fp.length)(inputKeys);
  const numberOfInputsCompleted = (0, _fp.length)(completed);
  const status = {
    numberOfInputs,
    numberOfInputsCompleted,
    status: (0, _fp.pathEq)(['status'], 'error')(benefitData) ? 'error' : !(0, _fp.isNil)((0, _fp.path)(['result'], benefitData)) ? 'complete' : numberOfInputsCompleted > 0 ? 'partial' : 'incomplete'
  };
  return status;
};

exports.getProgressData = getProgressData;