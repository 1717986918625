import { types } from 'redux/modules/non-recurring-costs';
import { types as cashflowTypes } from 'redux/modules/cashflow';
import { all, put, call, select, takeLatest, takeEvery } from 'redux-saga/effects';
import { actionCreators as nonRecurringCostsActionCreators } from 'redux/modules/non-recurring-costs';

import {
  nonRecurringCosts,
  sum,
  assocPath,
  negate,
  pipe,
  path,
  ifElse,
  equals,
  always,
  roundWithFloatingFixedPoint,
  isNil,
  nonRecurringCostsSelectors,
  sessionDetailsSelectors,
  cashflowsSelectors,
  generateShortId,
  last,
} from '@sharkfinesse/sfl-lib';
import { actionCreators as cashflowActionCreators } from 'redux/modules/cashflow';
import undoToast from 'redux/sagas/toast/undo';

function* addDynamicRow(action) {
  const meta = path(['meta'], action);
  const [costs, ids] = yield all([
    select(nonRecurringCostsSelectors.getCosts),
    select(nonRecurringCostsSelectors.getIds),
  ]);
  if (!costs[last(ids)].pristine) {
    yield put(nonRecurringCostsActionCreators.updateAddDynamicRow({ meta, id: generateShortId() }));
  }
}

export function* updateResult(action) {
  const meta = path(['meta'], action);
  const reviewPeriod = yield select(sessionDetailsSelectors.getReviewPeriod);
  const currentCosts = !isNil(path(['currentCosts'], action.payload))
    ? action.payload.currentCosts
    : yield select(nonRecurringCostsSelectors.getNonRecurringCosts);

  const result = yield call(nonRecurringCosts, reviewPeriod, currentCosts);

  if (action.fromEdit !== true) {
    yield put(cashflowActionCreators.updateNonRecurringCostsResult({ ...result, meta }));
  }
  const totalNonRecurringCostsCashflow = yield select(
    cashflowsSelectors.getTotalNonRecurringCostsCashflow
  );

  const updatedResult = assocPath(
    ['result'],
    pipe(sum, negate)(totalNonRecurringCostsCashflow),
    result
  );

  yield put(
    nonRecurringCostsActionCreators.updateResult({ ...updatedResult, id: action.payload.id, meta })
  );
}

export function* updateResultAddRow(action) {
  yield call(updateResult, action);
  yield call(addDynamicRow, action);
}

export function* costChangeCashflowEdit(action) {
  const currentCosts = yield select(nonRecurringCostsSelectors.getNonRecurringCosts);

  const costObject = assocPath(
    ['costs', action.payload.id, 'cost'],
    sum(action.payload.value),
    currentCosts
  );

  yield call(updateResult, {
    ...action,
    payload: { ...action.payload, currentCosts: costObject },
    fromEdit: true,
  });
}

export function* revertCashFlowEditing(action) {
  const unedittedCashflow = yield select(
    nonRecurringCostsSelectors.getCostCashflow,
    action.payload
  );

  yield call(costChange, {
    ...action,
    payload: { ...action.payload, value: sum(unedittedCashflow) },
  });
}

export function* costChange(action) {
  const { id, value } = action.payload;
  const meta = path(['meta'], action);
  const currentCosts = yield select(nonRecurringCostsSelectors.getNonRecurringCosts);

  const costObject = {
    ...currentCosts,
    costs: {
      ...currentCosts.costs,
      [id]: {
        ...currentCosts.costs[id],
        cost: ifElse(equals(''), always(''), roundWithFloatingFixedPoint)(value),
        pristine: false,
        cashflowEdited: false,
      },
    },
  };

  yield put(nonRecurringCostsActionCreators.updateCost({ ...costObject, meta }));
  yield call(updateResultAddRow, {
    ...action,
    payload: { ...action.payload, currentCosts: costObject },
    meta,
  });
}

function* reset(action) {
  const { meta } = action;

  yield call(undoToast, {
    action,
    undoActions: [
      put(nonRecurringCostsActionCreators.updateReset({ meta })),
      put(cashflowActionCreators.updateNonRecurringCostsReset({ meta })),
    ],
    extraActions: [call(updateResult, action)],
    formattedMessageId: 'costs.non-recurring.delete.all.toast.message',
    defaultMessage: 'Non-Recurring Costs Deleted',
  });
}

function* deleteRow(action) {
  const { payload, meta } = action;

  yield call(undoToast, {
    action,
    undoActions: [
      put(nonRecurringCostsActionCreators.updateRemoveRow({ id: payload, meta })),
      put(cashflowActionCreators.updateNonRecurringCostsRemoveRow({ id: payload, meta })),
    ],
    extraActions: [call(updateResult, action)],
    formattedMessageId: 'costs.non-recurring.delete.row.toast.message',
    defaultMessage: 'Cost Deleted',
  });
}

export const effects = [
  takeLatest(types.MONTH.CHANGE, updateResultAddRow),
  takeLatest(types.CASHFLOW.CHANGE, updateResult),
  takeLatest([types.DESCRIPTION.CHANGE, types.TYPE.CHANGE], addDynamicRow),
  takeLatest(types.COST.CHANGE_START, costChange),
  takeEvery(types.ROW.DO_DELETE, deleteRow),
  takeLatest(types.DO_RESET, reset),
  takeEvery(cashflowTypes.COSTS.NONRECURRING.UPDATE, costChangeCashflowEdit),
  takeEvery(cashflowTypes.COSTS.NONRECURRING.REVERT_EDIT, revertCashFlowEditing),
];

export default effects;
