import applyTheme from '../../styles/apply-theme';

const theme = applyTheme('reactTable');

export const TableContainer = theme('container');
export const Table = theme('table');
export const Head = theme('head');
export const Row = theme('row');
export const Cell = theme('cell');
export const Body = theme('body');
export const Resizer = theme('resizer');
export const SortHeader = theme('sortHeader');
export const SortChevron = theme('sortChevron');
export const LeftAlignCell = theme('leftAlignCell');
