"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDocData = exports.getCollectionData = void 0;

/* eslint-disable fp/no-mutation, no-confusing-arrow, fp/no-nil  */
const getDocData = doc => doc.exists === true ? {
  id: doc.id,
  ...doc.data()
} : null;

exports.getDocData = getDocData;

const getCollectionData = collection => collection.docs.map(getDocData);

exports.getCollectionData = getCollectionData;