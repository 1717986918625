/* @flow */
import React from 'react';
import PropTypes from 'prop-types';
import type { Element } from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import Tooltip from '../tooltip';
import { Link } from 'react-scroll';

const activeClassName = 'nav-item-active';
const activeClass = 'nav-item-active';

const InkBar = styled.div`
  ${({ themeKey, theme }) => css`
    ${theme[themeKey].background};
    ${theme[themeKey].inkBarMargin};
    height: ${theme[themeKey].inkBarHeight};
  `};
`;

const SharedCSS = css`
  text-decoration: none;
  padding: 0;
  cursor: pointer;
  white-space: nowrap;
  width: 100%;
  ${({ themeKey, theme }) => css`
    font-weight: ${theme[themeKey].fontWeight};
    color: ${theme[themeKey].fontColor};
    ${theme[themeKey].navItemBackground};
    ${theme[themeKey].navItemBorderStyle};
    ${theme[themeKey].navItemBorderWidth};
    ${theme[themeKey].navItemBorderColor};
    &:hover {
      color: ${theme[themeKey].fontColorHover};
      ${theme[themeKey].navItemBackgroundHover};
      ${theme[themeKey].navItemBorderStyleHover};
      ${theme[themeKey].navItemBorderWidthHover};
      ${theme[themeKey].navItemBorderColorHover};
      z-index: 1;
    }

    &.${activeClassName} {
      color: ${theme[themeKey].fontColorActive};
      ${theme[themeKey].navItemBackgroundActive};
      ${theme[themeKey].navItemBorderStyleActive};
      ${theme[themeKey].navItemBorderWidthActive};
      ${theme[themeKey].navItemBorderColorActive};
      z-index: 2;
    }
    &.${activeClassName} ${InkBar} {
      ${theme[themeKey].inkBarColor};
    }
  `};
`;

const StyledNavItem = styled(({ themeKey, InkBar, ...rest }) => <NavLink {...rest} />).attrs({
  activeClassName,
})`
  ${SharedCSS};
`;

const AnchorLink = styled(({ themeKey, InkBar, ...rest }) => <Link {...rest} />).attrs({
  activeClass,
})`
  ${SharedCSS};
`;

const ListItem = styled.li`
  display: flex;
  ${({ themeKey, theme }) => css`
    ${theme[themeKey].navItemMargin};
    flex: ${theme[themeKey].navItemFlex};
  `};
`;

const Item = styled.div`
  ${({ themeKey, theme }) => css`
    ${theme[themeKey].navItemPadding};
    display: flex;
    flex-direction: column;
    align-items: ${theme[themeKey].navItemAlign};
    position: relative;
    flex: 1;
  `};
`;

type DefaultProps = {
  exact: boolean,
  hideInkBar: boolean,
  anchorLink: boolean,
};

type Props = DefaultProps & {
  themeKey?: string,
  anchorLink?: boolean,
  children?: Element<*> | string,
  exact?: boolean,
  hideInkBar?: boolean,
  navItemProps?: Object,
  to?: string | Object,
  containerId?: string,
  spy?: boolean,
  smooth?: boolean | string,
  duration?: number | Function,
  offset?: number,
  isDynamic?: number,
  tooltip?: Element<any> | string,
  onSetActive?: Function,
  onClick: Function,
};

const NavItem = (
  {
    themeKey: themeKeyProp,
    anchorLink,
    children,
    exact,
    hideInkBar: hideInkBarProp,
    navItemProps: navItemPropsProp,
    to,
    containerId,
    spy,
    smooth,
    duration,
    offset,
    isDynamic,
    onSetActive,
    tooltip,
    onClick,
  }: Props,
  context
): Element<any> => {
  const { sfluiNavBar } = context;

  let themeKey = themeKeyProp;
  let hideInkBar = hideInkBarProp;
  let navItemProps = {
    ...navItemPropsProp,
  };

  if (sfluiNavBar) {
    if (typeof themeKey === 'undefined') {
      themeKey = sfluiNavBar.themeKey;
    }
    if (typeof hideInkBar === 'undefined') {
      hideInkBar = sfluiNavBar.hideInkBar;
    }
  }
  let ComponentMain = StyledNavItem;
  if (anchorLink) {
    ComponentMain = AnchorLink;
    navItemProps = {
      activeClass: activeClassName,
      containerId: containerId,
      spy: spy,
      smooth: smooth,
      duration: duration,
      offset: offset,
      isDynamic: isDynamic,
      onSetActive,
      ...navItemProps,
    };
  } else {
    navItemProps = {
      activeClassName: activeClassName,
      exact: exact,
      ...navItemProps,
    };
  }

  const navItemComponent = <Item themeKey={themeKey}>{children}</Item>;

  return (
    <ListItem themeKey={themeKey}>
      <ComponentMain themeKey={themeKey} to={to} onClick={onClick} {...navItemProps}>
        <Tooltip tooltip={tooltip} delayShow={500}>
          {navItemComponent}
        </Tooltip>
        {!hideInkBar && <InkBar themeKey={themeKey} />}
      </ComponentMain>
    </ListItem>
  );
};

NavItem.contextTypes = {
  sfluiNavBar: PropTypes.object,
};

NavItem.defaultProps = {
  exact: false,
  anchorLink: false,
};
export default NavItem;
