"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reselect = require("reselect");

var _nonRecurringCosts = _interopRequireDefault(require("./non-recurring-costs"));

var _benefits = _interopRequireDefault(require("./benefits"));

var _recurringCosts = _interopRequireDefault(require("./recurring-costs"));

var _paybackCalculator = _interopRequireDefault(require("./payback-calculator"));

var _charts = _interopRequireDefault(require("./charts"));

var _sessionDetails = _interopRequireDefault(require("./session-details"));

var _index = require("../../index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* eslint-disable no-unused-vars  */
const getCashflow = (0, _reselect.createSelector)([_benefits.default.getMonthlyCashflowData, _nonRecurringCosts.default.getCostsCashflowData, _recurringCosts.default.getCostsCashflowData], (benefitsCashflow, nonRecCostsCashflow, recCostsCashflow) => {
  const mapNegate = (0, _index.map)(_index.negate);
  return (0, _index.pipe)(_index.transpose, (0, _index.map)(_index.sum), _index.accumulateCashflow)([benefitsCashflow, mapNegate(nonRecCostsCashflow), mapNegate(recCostsCashflow)]);
});
const getCashflowIntersect = (0, _reselect.createSelector)([getCashflow, _paybackCalculator.default.getResult], (netCashflow, payback) => {
  const lastNeg = (0, _index.findLastIndex)((0, _index.lt)(_index.placeholder, 0), netCashflow);
  return lastNeg + (0, _index.interceptX)(netCashflow[lastNeg], netCashflow[(0, _index.inc)(lastNeg)]);
});
const getCashflowChartData = (0, _reselect.createSelector)([getCashflow, _charts.default.getAddChartMonths], (cashflow, addChartMonths) => {
  return addChartMonths(cashflow);
});
const getTotalCashPosition = (0, _reselect.createSelector)([getCashflowChartData], cashflow => {
  return (0, _index.pipe)(_index.last, _index.last)(cashflow);
});
const getTotalCashPositionDisplay = (0, _reselect.createSelector)([getTotalCashPosition, _sessionDetails.default.getNumberFormatObj], (value, numberFormatObj) => {
  return (0, _index.formatForDisplay)('money', numberFormatObj, value);
});
const getMaxExposure = (0, _reselect.createSelector)([getCashflow], cashflow => {
  return (0, _index.pipe)((0, _index.filter)(x => x < 0), (0, _index.map)(_index.negate), _index.sum)(cashflow);
});
const getMaxExposureDisplay = (0, _reselect.createSelector)([getMaxExposure, _sessionDetails.default.getNumberFormatObj], (value, numberFormatObj) => {
  return (0, _index.formatForDisplay)('money', numberFormatObj, value);
});
const getMaxExposureMonth = (0, _reselect.createSelector)([getCashflow], cashflow => {
  let minValue;
  let month;

  for (let x = 0; x < cashflow.length; x++) {
    if (x === 0) {
      minValue = cashflow[x];
      month = 0;
    }

    if (cashflow[x] < minValue) {
      minValue = cashflow[x];
      month = x;
    }
  }

  return month;
});
const selectors = {
  getCashflow,
  getCashflowIntersect,
  getCashflowChartData,
  getTotalCashPosition,
  getTotalCashPositionDisplay,
  getMaxExposure,
  getMaxExposureDisplay,
  getMaxExposureMonth
};
var _default = selectors;
exports.default = _default;