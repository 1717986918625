import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 0px;
  right: 0px;
  top: 0;
  bottom: 0;
  flex: 1 1 0%;
  height: 100%;
  width: 100%;
  position: absolute;
  outline: none;
  overflow: hidden;
  justify-content: center;
`;
const Component = ({ children }) => <Container>{children}</Container>;

export default Component;
