import { useQuery } from 'react-query';
import { getCollectionData } from '@sharkfinesse/sfl-lib';
import { fetchPolicy } from '../policy';

const getItems = async props => {
  const response = await fetchPolicy(props);
  return getCollectionData(response);
};

const usePolicy = (props, config) => {
  return useQuery('policy', () => getItems(props), config);
};

export default usePolicy;
