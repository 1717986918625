/* @flow */
/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { Table, TableContainer } from './styled';
import Scroll from '../scroll';
import TableContext from './context';

const Component = ({
  children,
  height = '100%',
  scrollStyle,
  stickyColumns = [],
  scrollBody,
  scrollTable,
  useRowSelect,
  containerProps,
  ...props
}) => {
  const table = useMemo(
    () => ({ stickyColumns, scrollBody, scrollTable, height, useRowSelect }),
    [stickyColumns, scrollBody, scrollTable, height, useRowSelect]
  );
  const tableComponent = <Table {...props}>{children}</Table>;
  return (
    <TableContext.Provider value={table}>
      {scrollTable && !scrollBody ? (
        <TableContainer style={{ height }} {...containerProps}>
          <Scroll show contentStyle={scrollStyle}>
            {tableComponent}
          </Scroll>
        </TableContainer>
      ) : (
        <TableContainer {...containerProps}>{tableComponent}</TableContainer>
      )}
    </TableContext.Provider>
  );
};

export default Component;
