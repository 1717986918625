/* @flow */
/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { Body } from './styled';
import TableContext from './context';
import TableContext2 from './context2';
import Scroll from '../scroll';

const Component = ({ children, ...props }) => {
  const { scrollBody, scrollTable, height } = useContext(TableContext);

  const table = {
    variant: 'body',
    numberOfChildren: children.length,
  };

  const bodyComponent = <Body {...props}>{children}</Body>;

  return (
    <TableContext2.Provider value={table}>
      {scrollBody && !scrollTable ? (
        <Scroll show containerStyles={{ height }}>
          {bodyComponent}
        </Scroll>
      ) : (
        bodyComponent
      )}
    </TableContext2.Provider>
  );
};

export default Component;
