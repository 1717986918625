import React, { useContext } from 'react';
import { ToolbarDivider } from '../toolbar';
import Button from '../button';
import { IconCross } from '../icon/icons';
import {
  Wrapper,
  Container,
  Contents,
  Shadow,
  Background,
  DropShadow,
  Title,
  Header,
} from './styled';
import { FlexRow } from '../general-styles';
import 'styled-components/macro';
import Menu from './menu';
import { DrawerContext } from './drawer';
import { Transition } from 'react-spring';

const Component = ({
  children,
  internalShadow,
  dropShadow,
  makeDraggable,
  isExpanded,
  title,
  rnd,
  background,
  close,
  showClose,
  pin,
  disableAnim,
  onRest,
  onClose,
}) => {
  const { draggable, position } = useContext(DrawerContext);

  return (
    <Transition
      native
      items={isExpanded}
      from={{
        opacity: 1,
        transform: `translate3d(${position === 'left' ? '-100%' : '100%'}, 0, 0)`,
      }}
      enter={{ opacity: 1, transform: 'translate3d(0%, 0, 0)' }}
      leave={{
        opacity: 1,
        transform: `translate3d(${position === 'left' ? '-100%' : '100%'}, 0, 0)`,
      }}
      onRest={() => {
        if (!isExpanded && onRest && !disableAnim) {
          onRest();
        }
      }}
      immediate={draggable || disableAnim}
    >
      {(styles, isExpanded) =>
        isExpanded && (
          <Wrapper style={styles}>
            {dropShadow && !draggable && <DropShadow expanded={isExpanded} $position={position} />}
            <Container>
              <Background $background={background} $draggable={draggable} $position={position}>
                <Contents>
                  {(title || rnd || showClose) && (
                    <Header>
                      <Title as="h1">{title}</Title>
                      <FlexRow
                        css={`
                          height: 30px;
                          align-items: center;
                        `}
                      >
                        <>
                          {rnd && (
                            <>
                              <Menu
                                makeDraggable={makeDraggable}
                                draggable={draggable}
                                pin={pin}
                                position={position}
                              />
                              <ToolbarDivider />
                            </>
                          )}
                          {showClose && (
                            <Button
                              onClick={() => {
                                onClose && onClose();
                                close();
                              }}
                              size="icon"
                              variant="flat"
                              color="light"
                            >
                              <IconCross />
                            </Button>
                          )}
                        </>
                      </FlexRow>
                    </Header>
                  )}
                  {children}
                </Contents>
              </Background>
            </Container>
            {internalShadow && !draggable && <Shadow expanded={isExpanded} />}
          </Wrapper>
        )
      }
    </Transition>
  );
};

export default Component;
