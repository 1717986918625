/* @flow */
import React from 'react';
import type { Element } from 'react';
import { ModalBody } from './styled';

type Props = {
  children?: Element<any>,
  showHeaderKeyline: boolean,
  showFooterKeyline: boolean,
  showFooter: boolean,
};

const Body = ({ children, showHeaderKeyline, showFooterKeyline, showFooter, ...props }: Props) => (
  <ModalBody
    showHeaderKeyline={showHeaderKeyline}
    showFooterKeyline={showFooterKeyline}
    showFooter={showFooter}
    {...props}
  >
    {children}
  </ModalBody>
);

export default Body;
