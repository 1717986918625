// @flow
import React from 'react';
import { Themes, ThemeProvider as StyledThemeProvider } from '@sharkfinesse/sfl-ui';

type Props = {
  id: string,
};

const ThemeProvider = ({ id, children }: Props) => (
  <StyledThemeProvider theme={Themes[id]}>{children}</StyledThemeProvider>
);

export default ThemeProvider;
