"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.zeroFillUndefinedYears = exports.updateYearlyVal = exports.unaccumulatedAnnualTotals = exports.sumCashflows = exports.sumCashflow = exports.subtractCashflow = exports.stepGrowCashflow = exports.smooth = exports.ratchetCashflow = exports.rangeLength = exports.quarterlyCashflowWithStepGrowth = exports.quarterlyCashflowWithNthRootGrowth = exports.quarterlyCashflow = exports.oneTimeCashflow = exports.nthRootGrowCashflow = exports.negateCashflow = exports.monthlyCashflowWithStepGrowth = exports.monthlyCashflowWithNthRootGrowth = exports.monthlyCashflow = exports.monthZeroCashflow = exports.monthToYear = exports.monthToFullYearRemainder = exports.monthToFullYear = exports.mapCashflow = exports.idiotOrGenerateMonthZeroCashflow = exports.idiotOrGenerateCashflow = exports.growRange = exports.generateQuarterlyCashflow = exports.generateOneTimeCashflow = exports.generateNetCashflow = exports.generateMonthZeroCashflow = exports.generateCashflowFromZeroMonthRange = exports.generateCashflowFromRange = exports.generateCashflowFromMixed = exports.generateCashflow = exports.generateBlankCashflow = exports.generateAnnualCashflow = exports.delayCashflow = exports.checkRangeOverlap = exports.cashflowModifiers = exports.averageCashflow = exports.appendZeros = exports.annualTotals = exports.annualCashflowWithStepGrowth = exports.annualCashflowWithNthRootGrowth = exports.annualCashflow = exports.accumulatedAnnualTotals = exports.accumulateCashflow = exports.accumulate = void 0;

var _fp = require("./fp");

var _math = require("./math");

var _arrays = require("./arrays");

var _growth = require("./growth");

var _constants = require("./constants");

var _type = require("./type");

// prettier-ignore
const generateCashflow = (0, _fp.curryN)(2, (0, _fp.pipe)((0, _fp.flip)(_fp.repeat), (0, _fp.prepend)(0))); // prettier-ignore

exports.generateCashflow = generateCashflow;
const generateBlankCashflow = generateCashflow(_fp.__, 0); // prettier-ignore

exports.generateBlankCashflow = generateBlankCashflow;
const rangeLength = (0, _fp.curryN)(2, (0, _fp.pipe)((0, _fp.flip)(_fp.subtract), _fp.inc)); // prettier-ignore

exports.rangeLength = rangeLength;
const generateOneTimeCashflow = (0, _fp.curry)((period, month, value) => (0, _fp.update)(month, value, generateCashflow(period, 0)));
exports.generateOneTimeCashflow = generateOneTimeCashflow;
const q = 3; // prettier-ignore

const generateQuarterlyCashflow = (0, _fp.curryN)(2, (0, _fp.pipe)(generateCashflow, (0, _arrays.mapIndexed)((a, b) => (b - 1) % q === 0 ? a : 0)));
exports.generateQuarterlyCashflow = generateQuarterlyCashflow;
const y = 12; // prettier-ignore

const generateAnnualCashflow = (0, _fp.curryN)(2, (0, _fp.pipe)(generateCashflow, (0, _arrays.mapIndexed)((a, b) => (b - 1) % y === 0 ? a : 0))); // prettier-ignore

exports.generateAnnualCashflow = generateAnnualCashflow;
const checkRangeOverlap = (0, _fp.converge)(_fp.call, [(0, _fp.nAry)(2, (0, _fp.useWith)( // eslint-disable-line
(0, _fp.pipe)((0, _fp.unapply)(_fp.idiot), _fp.allPass), [_fp.lte, _fp.gte])), (0, _fp.nthArg)(2)]); // prettier-ignore

exports.checkRangeOverlap = checkRangeOverlap;
const delayCashflow = (0, _fp.curry)((start, end, cashflow) => {
  const significantRange = (0, _fp.slice)(1, (0, _fp.inc)(rangeLength(start, end)), cashflow);
  const insertInto = (0, _fp.pipe)(_fp.length, (0, _fp.subtract)(_fp.__, rangeLength(start, end)), _fp.dec, generateCashflow(_fp.__, 0), (0, _fp.update)(0, (0, _fp.head)(cashflow)))(cashflow);
  return (0, _fp.insertAll)(start, significantRange, insertInto);
}); // prettier-ignore

exports.delayCashflow = delayCashflow;
const sumCashflow = _fp.sum; // prettier-ignore

exports.sumCashflow = sumCashflow;
const sumCashflows = (0, _fp.pipe)((0, _fp.unapply)(_fp.transpose), (0, _fp.map)(_fp.sum)); // prettier-ignore

exports.sumCashflows = sumCashflows;
const mapCashflow = (0, _fp.useWith)( // eslint-disable-line
_arrays.mapIndexed, [(0, _fp.pipe)((0, _fp.curryN)(2), (0, _fp.blackbird)(Math.floor)), _fp.idiot]); // prettier-ignore

exports.mapCashflow = mapCashflow;
const accumulateCashflow = (0, _fp.lift)((0, _fp.scan)(_fp.add))(_fp.head, _fp.tail);
exports.accumulateCashflow = accumulateCashflow;
const clearPostEndMonth = (0, _fp.curry)((endMonth, cashflow) => (0, _arrays.mapIndexed)((a, b) => b > endMonth ? 0 : a, cashflow)); // prettier-ignore

const accumulate = (0, _fp.curry)((endMonth, cashflow) => (0, _fp.pipe)(accumulateCashflow, clearPostEndMonth(endMonth))(cashflow)); // prettier-ignore

exports.accumulate = accumulate;
const stepGrowCashflow = (0, _fp.uncurryN)(2, (0, _fp.pipe)((0, _fp.nthArg)(0), _growth.stepGrowValue, _fp.flip, _arrays.mapIndexed)); // prettier-ignore

exports.stepGrowCashflow = stepGrowCashflow;
const nthRootGrowCashflow = (0, _fp.uncurryN)(2, (0, _fp.pipe)((0, _fp.nthArg)(0), _growth.nthRootGrowValue, _fp.flip, _arrays.mapIndexed)); // prettier-ignore

exports.nthRootGrowCashflow = nthRootGrowCashflow;
const averageCashflow = (0, _fp.useWith)( // eslint-disable-line
(0, _fp.pipe)(_math.divideBy, Math.floor), [_fp.idiot, sumCashflow]); // prettier-ignore

exports.averageCashflow = averageCashflow;
const negateCashflow = (0, _fp.map)((0, _fp.ifElse)((0, _fp.equals)(0), _fp.idiot, _fp.negate)); // prettier-ignore

exports.negateCashflow = negateCashflow;
const generateMonthZeroCashflow = generateOneTimeCashflow(_fp.placeholder, 0); // prettier-ignore

exports.generateMonthZeroCashflow = generateMonthZeroCashflow;
const idiotOrGenerateCashflow = (0, _fp.ifElse)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _fp.is)(Number)), generateCashflow, (0, _fp.nthArg)(1)); // prettier-ignore

exports.idiotOrGenerateCashflow = idiotOrGenerateCashflow;
const idiotOrGenerateMonthZeroCashflow = (0, _fp.ifElse)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _fp.is)(Number)), generateMonthZeroCashflow, (0, _fp.nthArg)(1)); // prettier-ignore

exports.idiotOrGenerateMonthZeroCashflow = idiotOrGenerateMonthZeroCashflow;
const generateCashflowFromMixed = (0, _fp.converge)((0, _fp.pipe)(_fp.ap, (0, _fp.apply)(sumCashflows)), [(0, _fp.pipe)((0, _fp.unary)(idiotOrGenerateCashflow), (0, _fp.append)(_fp.placeholder, [])), (0, _fp.unapply)((0, _fp.remove)(0, 1))]); // prettier-ignore

exports.generateCashflowFromMixed = generateCashflowFromMixed;
const generateCashflowFromRange = (0, _fp.converge)(_fp.insertAll, [(0, _fp.nthArg)(0), (0, _fp.nthArg)(2), (0, _fp.bunting)(generateCashflow(_fp.placeholder, 0))((0, _fp.finchstar)((0, _fp.jalt_)((0, _fp.goldfinch)(_fp.subtract)(_fp.length))))]); // prettier-ignore

exports.generateCashflowFromRange = generateCashflowFromRange;
const generateCashflowFromZeroMonthRange = (0, _fp.curryN)(3, (0, _fp.pipe)(generateCashflowFromRange, _fp.tail));
exports.generateCashflowFromZeroMonthRange = generateCashflowFromZeroMonthRange;

const generateNetCashflow = data => generateCashflowFromMixed(data.period, idiotOrGenerateMonthZeroCashflow(data.period, data.nonRecurringCosts), idiotOrGenerateMonthZeroCashflow(data.period, data.oneTimeBenefits), idiotOrGenerateCashflow(data.period, data.monthlyBenefits), idiotOrGenerateCashflow(data.period, data.recurringCosts)); // prettier-ignore


exports.generateNetCashflow = generateNetCashflow;
const monthlyCashflow = (0, _fp.curry)((period, start, end, value) => (0, _fp.pipe)(generateCashflow(period), delayCashflow(start, end))(value)); // prettier-ignore

exports.monthlyCashflow = monthlyCashflow;
const quarterlyCashflow = (0, _fp.curry)((period, start, end, value) => (0, _fp.pipe)(generateQuarterlyCashflow(period), delayCashflow(start, end))(value)); // prettier-ignore

exports.quarterlyCashflow = quarterlyCashflow;
const annualCashflow = (0, _fp.curry)((period, start, end, value) => (0, _fp.pipe)(generateAnnualCashflow(period), delayCashflow(start, end))(value)); // prettier-ignore

exports.annualCashflow = annualCashflow;
const oneTimeCashflow = (0, _fp.curry)((period, month, value) => generateOneTimeCashflow(period, month, value)); // prettier-ignore

exports.oneTimeCashflow = oneTimeCashflow;
const monthZeroCashflow = (0, _fp.curry)((period, value) => generateOneTimeCashflow(period, 0, value)); // prettier-ignore

exports.monthZeroCashflow = monthZeroCashflow;
const monthlyCashflowWithStepGrowth = (0, _fp.curry)((period, growth, start, end, value) => (0, _fp.pipe)(generateCashflow(period), stepGrowCashflow(growth), delayCashflow(start, end))(value)); // prettier-ignore

exports.monthlyCashflowWithStepGrowth = monthlyCashflowWithStepGrowth;
const quarterlyCashflowWithStepGrowth = (0, _fp.curry)((period, growth, start, end, value) => (0, _fp.pipe)(generateQuarterlyCashflow(period), stepGrowCashflow(growth), delayCashflow(start, end))(value)); // prettier-ignore

exports.quarterlyCashflowWithStepGrowth = quarterlyCashflowWithStepGrowth;
const annualCashflowWithStepGrowth = (0, _fp.curry)((period, growth, start, end, value) => (0, _fp.pipe)(generateAnnualCashflow(period), stepGrowCashflow(growth), delayCashflow(start, end))(value)); // prettier-ignore

exports.annualCashflowWithStepGrowth = annualCashflowWithStepGrowth;
const monthlyCashflowWithNthRootGrowth = (0, _fp.curry)((period, growth, start, end, value) => (0, _fp.pipe)(generateCashflow(period), nthRootGrowCashflow(growth), delayCashflow(start, end))(value)); // prettier-ignore

exports.monthlyCashflowWithNthRootGrowth = monthlyCashflowWithNthRootGrowth;
const quarterlyCashflowWithNthRootGrowth = (0, _fp.curry)((period, growth, start, end, value) => (0, _fp.pipe)(generateQuarterlyCashflow(period), nthRootGrowCashflow(growth), delayCashflow(start, end))(value)); // prettier-ignore

exports.quarterlyCashflowWithNthRootGrowth = quarterlyCashflowWithNthRootGrowth;
const annualCashflowWithNthRootGrowth = (0, _fp.curry)((period, growth, start, end, value) => (0, _fp.pipe)(generateAnnualCashflow(period), nthRootGrowCashflow(growth), delayCashflow(start, end))(value)); // prettier-ignore

exports.annualCashflowWithNthRootGrowth = annualCashflowWithNthRootGrowth;
const unaccumulatedAnnualTotals = (0, _fp.pipe)(_fp.tail, (0, _fp.splitEvery)(_constants.monthsPerYear), (0, _fp.map)(_fp.sum)); // prettier-ignore

exports.unaccumulatedAnnualTotals = unaccumulatedAnnualTotals;
const accumulatedAnnualTotals = (0, _fp.pipe)(unaccumulatedAnnualTotals, accumulateCashflow); // prettier-ignore

exports.accumulatedAnnualTotals = accumulatedAnnualTotals;
const annualTotals = (0, _fp.curry)((accumulate, cashflow) => accumulate ? accumulatedAnnualTotals(cashflow) : unaccumulatedAnnualTotals(cashflow));
exports.annualTotals = annualTotals;
const subtractCashflow = (0, _fp.curry)((a, b) => (0, _arrays.mapIndexed)((val, idx) => (0, _fp.subtract)(val, b[idx] ? b[idx] : 0), a)); // prettier-ignore

exports.subtractCashflow = subtractCashflow;
const ratchetCashflow = (0, _fp.reduce)((a, b) => (0, _fp.pipe)(_fp.last, (0, _fp.max)(b), (0, _fp.append)(_fp.__, a))(a), []);
exports.ratchetCashflow = ratchetCashflow;
const growRange = (0, _fp.curry)((startMonth, growthPeriod, rate, cashflow) => {
  const f = (0, _fp.curry)((monthlyGrowth, prev, current, index) => {
    return index >= startMonth && index <= startMonth + growthPeriod - 1 ? monthlyGrowth + prev : current;
  });
  return (0, _fp.tail)((0, _fp.addIndex)(_fp.scan)(f(cashflow[startMonth] / growthPeriod), 0, cashflow));
});
exports.growRange = growRange;

const monthToYear = month => Math.ceil(month / _constants.monthsPerYear);

exports.monthToYear = monthToYear;

const monthToFullYear = month => Math.floor(month / _constants.monthsPerYear);

exports.monthToFullYear = monthToFullYear;

const monthToFullYearRemainder = month => month - monthToFullYear(month) * _constants.monthsPerYear;

exports.monthToFullYearRemainder = monthToFullYearRemainder;
const sanitizeMod = (0, _fp.map)(a => isNaN(a) ? _constants.oneHundredPercent * _math.normalizingFloatingFixedPointFactor : a);
const numberOrHundred = (0, _type.numberOr)((0, _math.normalizingFloatingFixedPointFactor)(1) * _constants.oneHundredPercent);
const cashflowModifier = (0, _fp.curryN)(2, (mod, cashflow) => {
  return cashflow ? (0, _arrays.mapIndexed)((val, idx) => {
    return val * (numberOrHundred(mod[monthToYear(idx) - 1]) / _constants.oneHundredPercent) / (0, _math.normalizingFloatingFixedPointFactor)(1);
  }, cashflow) : cashflow;
});
const mergeMods = (0, _fp.reduce)((0, _fp.mergeWith)((0, _fp.pipe)(_fp.multiply, (0, _fp.divide)(_fp.__, _constants.oneHundredPercent * (0, _math.normalizingFloatingFixedPointFactor)(1)))), {});
const cashflowModifiers = (0, _fp.curryN)(2, (mods, cashflow) => (0, _fp.pipe)(mergeMods, cashflowModifier(_fp.__, cashflow), sanitizeMod)(mods));
exports.cashflowModifiers = cashflowModifiers;
const shiftRange = (0, _fp.curryN)(2, (0, _fp.pipe)(_fp.append, _fp.tail));

const runAv = (range, val) => (0, _fp.pipe)(shiftRange(_fp.__, range), _fp.sum, (0, _fp.divide)(_fp.__, range.length))(val);

const smoothIterator = (obj, val, idx) => {
  const newCf = (0, _fp.update)(idx, runAv(obj.range, val), obj.cf);
  return (0, _fp.pipe)((0, _fp.assoc)('range', shiftRange(val, obj.range)), (0, _fp.assoc)('cf', newCf))(obj);
};

const runningAvCashflow = (months, cf) => (0, _fp.pipe)((0, _fp.addIndex)(_fp.reduce)(smoothIterator, {
  cf,
  range: (0, _fp.repeat)(0, months)
}), (0, _fp.prop)('cf'))(cf);

const smooth = (0, _fp.curryN)(3, (recursions, months, cf) => (0, _fp.reduce)((0, _fp.flip)(runningAvCashflow), cf, (0, _fp.repeat)(months, recursions)));
exports.smooth = smooth;
const appendZeros = (0, _fp.curry)((arr, no, fillVal) => (0, _fp.concat)(arr, (0, _fp.repeat)(fillVal, no)));
exports.appendZeros = appendZeros;
const zeroFillUndefinedYears = (0, _fp.curry)((arr, fillVal, period, startMonth, endMonth) => {
  const diff = monthToYear(period) - (0, _fp.length)(arr) > 0 ? monthToYear(period) - (0, _fp.length)(arr) : 0;
  return (0, _fp.pipe)(a => diff ? appendZeros(a, diff, fillVal) : a, a => a === null || a === void 0 ? void 0 : a.slice(0, monthToYear(period)), a => a ? (0, _arrays.mapIndexed)((val, idx) => idx + 1 < monthToYear(startMonth) || idx + 1 > monthToYear(endMonth) ? 0 : val, a) : a)(arr);
});
exports.zeroFillUndefinedYears = zeroFillUndefinedYears;
const updateYearlyVal = (0, _fp.curry)((idx, val, yearlyVals, reviewPeriod, startMonth, endMonth) => (0, _fp.pipe)(zeroFillUndefinedYears(yearlyVals, 0, _fp.placeholder, startMonth, endMonth), (0, _fp.update)(idx, val))(reviewPeriod));
exports.updateYearlyVal = updateYearlyVal;