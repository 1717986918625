/* @flow */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTableFoot = styled.div`
  ${({ theme, themeKey }) => theme[`${themeKey}`].footBackground};
  ${({ theme, themeKey }) => theme[`${themeKey}`].footPadding};
`;

type Props = {
  themeKey?: string,
  children?: Node,
  ...other,
};

class TableFoot extends Component<Props, void> {
  render() {
    const { themeKey: themeKeyProp, children, ...other } = this.props;

    const { sfluiTable } = this.context;

    let themeKey = themeKeyProp;

    if (sfluiTable) {
      if (typeof themeKey === 'undefined') {
        themeKey = sfluiTable.themeKey;
      }
    }

    return (
      <StyledTableFoot themeKey={themeKey} {...other}>
        {children}
      </StyledTableFoot>
    );
  }
}

TableFoot.contextTypes = {
  sfluiTable: PropTypes.object,
};

export default TableFoot;
