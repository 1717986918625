/* @flow */
/* eslint-disable max-len */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19,20v-1v-6V9H5v11h7H19z M8,19H6v-3h2V19z M8,15H6v-2h2V15z M8,12H6v-2h2V12z M11,19H9v-3h2V19z M11,15H9
		v-2h2V15z M11,12H9v-2h2V12z M16,10h2v2h-2V10z M16,13h2v2h-2V13z M16,16h2v3h-2V16z M12,10h3v2h-3V10z M12,13h3v2h-3V13z M12,16h3
		v3h-3V16z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20,3h-1c0-0.2-0.8-1-1-1h-1c-0.2,0-1,0.8-1,1H8c0-0.2-0.8-1-1-1H6C5.8,2,5,2.8,5,3H4C3.8,3,3,3.8,3,4v3.2
		V21c0,0.2,0.8,1,1,1h16c0,0,0.6-0.3,1-1V7V4C21,3.8,20.2,3,20,3z M17,4V3h1v1v1h-1V4z M6,4V3h1v1v1H6V4z M20,21H4V8h16V21z M20,7H4
		V4h1v1c0,0.2,0.8,1,1,1h1c0.2,0,1-0.8,1-1V4h8v1c0,0.2,0.8,1,1,1h1c0.2,0,1-0.8,1-1V4h1V7z"
    />
  </Icon>
);

export default memo(IconComponent);
