import { connect } from 'react-redux';
import { userSelectors } from '@sharkfinesse/sfl-lib';
import EditorList from 'components/editor-list';

const mapStateToProps = (state, props) => ({
  account: userSelectors.getAccount(state, props),
  product: userSelectors.getProduct(state, props),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EditorList);
