"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stepMonthlyInterest = exports.stepGrowthPercentage = exports.stepGrowValue = exports.nthRootMonthlyInterest = exports.nthRootGrowthPercentage = exports.nthRootGrowValue = void 0;

var _fp = require("./fp");

var _constants = require("./constants");

var _dates = require("./dates");

var _math = require("./math");

const n = _constants.monthsPerYear; // prettier-ignore

const stepGrowthPercentage = (0, _fp.curry)((rate, month) => ((0, _dates.getYear)(month) - 1) * rate); // prettier-ignore

exports.stepGrowthPercentage = stepGrowthPercentage;
const stepMonthlyInterest = (0, _fp.curry)((rate, month, value) => value * stepGrowthPercentage(rate, month) / _constants.percentageToDecimalFactor); // prettier-ignore

exports.stepMonthlyInterest = stepMonthlyInterest;
const stepGrowValue = (0, _fp.curry)((rate, month, value) => value + stepMonthlyInterest(rate, month, value)); // prettier-ignore
// P' = P(1+r)^m/12

exports.stepGrowValue = stepGrowValue;
const nthRootGrowthPercentage = (0, _fp.curry)((rate, month) => ((0, _math.pow)(month / n, 1 + (0, _math.percentageToDecimal)(rate)) * (0, _math.normalizingFloatingFixedPointFactor)(1) - (0, _math.normalizingFloatingFixedPointFactor)(1)) * (0, _math.normalizingFloatingFixedPointFactor)(2)); // prettier-ignore

exports.nthRootGrowthPercentage = nthRootGrowthPercentage;
const nthRootMonthlyInterest = (0, _fp.curry)((rate, month, value) => Math.round(value * nthRootGrowthPercentage(rate, month) / (0, _math.normalizingFloatingFixedPointFactor)(3))); // prettier-ignore

exports.nthRootMonthlyInterest = nthRootMonthlyInterest;
const nthRootGrowValue = (0, _fp.curry)((rate, month, value) => value + nthRootMonthlyInterest(rate, month, value));
exports.nthRootGrowValue = nthRootGrowValue;