import { css } from 'styled-components';

const Circle = ({ font, colors, scale }) => ({
  base: src => css`
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    color: white;
    background: purple;
    vertical-align: sub;
  `,
});

export default Circle;
