import { isNil } from '@sharkfinesse/sfl-lib';

const FB_PROJECT_ID_DEVELOPMENT = 'sfl-internal';
const FB_PROJECT_ID_STAGING = 'sfl-pro-8052a';
const FB_PROJECT_ID = 'sfl-app-e6cdc';
const FB_PROJECT_ID_PL_NORTH_AMERICA_NORTH_EAST = 'plnorthamericanortheast1';
//*****************************************************
// Uncomment to use auth0 dev tenants
//*****************************************************
// let environment_development = {
//   production: false,
//   auth: {
//     clientId: 'T5bkP0gkXKsgWlJijfwdQvWlpVcqRq6E',
//     clientDomain: 'sfl-dev.eu.auth0.com', // e.g., you.auth0.com
//     audience: 'http://10.44.96.195:1337/', // e.g., http://localhost:1337/
//     redirect: 'http://localhost:3000',
//     scope: 'openid profile email user_metadata app_metadata',
//   },
//   helpUrl: 'https://devdocs.sharkfinesse.com/',
//   benefitsHelp: 'user-guide/using-shark/agree-benefits/',
//   costsHelp: 'user-guide/using-shark/profile-costs/',
//   roiHelp: 'user-guide/using-shark/roi-measures/',
// };
let environment_development = {
  production: false,
  auth: {
    clientId: 'mtvm17Hl9IKCFKdODQP2UKNtA0V3utJM',
    clientDomain: 'auth.sharkfinesse.com', // e.g., you.auth0.com
    audience: 'http://10.44.96.195:1337/', // e.g., http://localhost:1337/
    redirect: 'http://localhost:3000',
    scope: 'openid profile email user_metadata app_metadata',
  },
  helpUrl: 'https://devdocs.sharkfinesse.com/',
  benefitsHelp: 'user-guide/using-shark/agree-benefits/',
  costsHelp: 'user-guide/using-shark/profile-costs/',
  roiHelp: 'user-guide/using-shark/roi-measures/',
};

let firebase_development = {
  firebase: {
    apiKey: 'AIzaSyBqyQQ8BoeaHMfHAZjbs9Ik6pY1Zx21gco',
    authDomain: `${FB_PROJECT_ID_DEVELOPMENT}.firebaseapp.com`,
    databaseURL: `https://${FB_PROJECT_ID_DEVELOPMENT}.firebaseio.com`,
    projectId: FB_PROJECT_ID_DEVELOPMENT,
    storageBucket: `${FB_PROJECT_ID_DEVELOPMENT}.appspot.com`,
    messagingSenderId: '541624737246',
    appId: '1:541624737246:web:e6873f5d508a7278dad14f',
  },
  apiRoot: 'http://localhost:5000/',
};

let environment_staging = {
  production: false,
  auth: {
    clientId: 'mtvm17Hl9IKCFKdODQP2UKNtA0V3utJM',
    clientDomain: 'auth.sharkfinesse.com', // e.g., you.auth0.com
    audience: 'http://10.44.96.195:1337/', // e.g., http://localhost:1337/
    redirect: 'https://devapp.sharkfinesse.com',
    scope: 'openid profile email user_metadata app_metadata',
  },
  helpUrl: 'https://devdocs.sharkfinesse.com/',
  benefitsHelp: 'user-guide/using-shark/agree-benefits/',
  costsHelp: 'user-guide/using-shark/profile-costs/',
  roiHelp: 'user-guide/using-shark/roi-measures/',
};

let firebase_staging = {
  firebase: {
    apiKey: 'AIzaSyD2W_f9ykbTybC12tqnvJUHRM6_KvzV3Yg',
    authDomain: `${FB_PROJECT_ID_STAGING}.firebaseapp.com`,
    databaseURL: `https://${FB_PROJECT_ID_STAGING}.firebaseio.com`,
    projectId: FB_PROJECT_ID_STAGING,
    storageBucket: `${FB_PROJECT_ID_STAGING}.appspot.com`,
    messagingSenderId: '1087022661287',
    appId: '1:1087022661287:web:f9145b4bb36e0033f6a582',
  },
  apiRoot: 'https://devapp.sharkfinesse.com/',
};

let environment_production = {
  production: true,
  auth: {
    clientId: 'mtvm17Hl9IKCFKdODQP2UKNtA0V3utJM',
    clientDomain: 'auth.sharkfinesse.com', // e.g., you.auth0.com
    audience: 'http://10.44.96.195:1337/', // e.g., http://localhost:1337/
    redirect: 'https://app.sharkfinesse.com',
    scope: 'openid profile email user_metadata app_metadata',
  },
  helpUrl: 'https://docs.sharkfinesse.com/',
  benefitsHelp: 'user-guide/using-shark/agree-benefits/',
  costsHelp: 'user-guide/using-shark/profile-costs/',
  roiHelp: 'user-guide/using-shark/roi-measures/',
};

let firebase_production = {
  firebase: {
    apiKey: 'AIzaSyAhnVrcYHg1keUEZUi25FO_eX9T3-z9arY',
    authDomain: `${FB_PROJECT_ID}.firebaseapp.com`,
    databaseURL: `https://${FB_PROJECT_ID}.firebaseio.com`,
    projectId: FB_PROJECT_ID,
    storageBucket: `${FB_PROJECT_ID}.appspot.com`,
    messagingSenderId: '593069438111',
    appId: '1:593069438111:web:cad3bc932153e0c9125eaa',
  },
  apiRoot: 'https://app.sharkfinesse.com/',
};

let firebase_plNorthAmericaNorthEast1 = {
  firebase: {
    apiKey: 'AIzaSyBr5-lvVLGBBJ82s0JS1RY3PNRUhyZaA3I',
    authDomain: `${FB_PROJECT_ID_PL_NORTH_AMERICA_NORTH_EAST}.firebaseapp.com`,
    databaseURL: `https://${FB_PROJECT_ID_PL_NORTH_AMERICA_NORTH_EAST}.firebaseio.com`,
    projectId: FB_PROJECT_ID_PL_NORTH_AMERICA_NORTH_EAST,
    storageBucket: `${FB_PROJECT_ID_PL_NORTH_AMERICA_NORTH_EAST}.appspot.com`,
    messagingSenderId: '184276527671',
    appId: '1:184276527671:web:98b3803ac5936aa0d72db2',
    measurementId: 'G-71Z0W78YGV',
  },
  apiRoot: 'https://plnorthamericanortheast1.sharkfinesse.com/',
};

if (process.env.REACT_APP_MODE === 'staging') {
  environment_staging.auth.redirect = 'https://staging.sharkfinesse.com';
  firebase_staging.apiRoot = 'https://staging.sharkfinesse.com/';
}

if (process.env.REACT_APP_MODE === 'devapp') {
  environment_staging.auth.redirect = 'https://devapp.sharkfinesse.com';
  firebase_staging.apiRoot = 'https://devapp.sharkfinesse.com/';
}

if (process.env.REACT_APP_MODE === 'bjones') {
  environment_development.auth.redirect = 'https://bjones.sharkfinesse.com';
  firebase_development.apiRoot = 'https://bjones.sharkfinesse.com/';
}

if (process.env.REACT_APP_MODE === 'swhitehall') {
  environment_development.auth.redirect = 'https://swhitehall.sharkfinesse.com';
  firebase_development.apiRoot = 'https://swhitehall.sharkfinesse.com/';
}

if (process.env.REACT_APP_MODE === 'ifreestone') {
  environment_development.auth.redirect = 'https://ifreestone.sharkfinesse.com';
  firebase_development.apiRoot = 'https://ifreestone.sharkfinesse.com/';
}

const fbEnvironments = {
  firebase_plNorthAmericaNorthEast1,
};

const getEnvironment = () => {
  let environment =
    process.env.REACT_APP_MODE === 'production'
      ? environment_production
      : process.env.REACT_APP_MODE === 'staging' || process.env.REACT_APP_MODE === 'devapp'
      ? environment_staging
      : environment_development;
  return environment;
};

export const getFirebaseEnvironment = () => {
  let environment =
    process.env.REACT_APP_MODE === 'production' || process.env.REACT_APP_MODE === 'startApp'
      ? firebase_production
      : process.env.REACT_APP_MODE === 'staging' ||
        process.env.REACT_APP_MODE === 'devapp' ||
        process.env.REACT_APP_MODE === 'startStaging'
      ? firebase_staging
      : firebase_development;

  const firebaseEnvironment = localStorage.getItem('firebaseEnvironment');

  if (!isNil(firebaseEnvironment) && process.env.REACT_APP_MODE === 'production') {
    environment.firebase = fbEnvironments[`firebase_${firebaseEnvironment}`].firebase;
    environment.apiRoot = fbEnvironments[`firebase_${firebaseEnvironment}`].apiRoot;
  }

  environment.functionsRoot = `${environment.apiRoot}api/`;
  if (environment.apiRoot === 'http://localhost:5000/')
    environment.functionsRoot = 'http://localhost:5001/sfl-internal/us-central1/api/api/';

  return environment;
};

export default getEnvironment;
