import { css } from 'styled-components';
import styledBreakpoint from 'styled-components-breakpoint';

const BusinessCaseOutputs = ({ font, colors, scale }) => ({
  iconColumn: () => css`
    flex-direction: column;
    h2 {
      font-size: ${font.sizeLarge};
      color: ${colors.uiDarkest};
      font-weight: ${font.weightSemiBold};
      margin: 10px 0 0 0;
    }
    ${styledBreakpoint('xxs')`
      margin: ${scale.zero} 0 0 0;
    `};
    ${styledBreakpoint('sm')`
      margin: ${scale.two} 0 ${scale.two} ${scale.two};
    `};
  `,
  outputsColumn: () => css`
    flex-direction: column;
    ${styledBreakpoint('xxs')`
      margin: ${scale.minusOne} 0 ${scale.zero} 0;
      `};

    ${styledBreakpoint('sm')`
      border-left: 3px solid ${colors.uiLight};
      padding: 0 ${scale.zero};
      margin: ${scale.two} 0 ${scale.two} 0;
    `};
    h3 {
      font-size: ${font.sizeNormal};
      color: ${colors.uiDarkest};
      font-weight: 600;
      margin: 0;
    }
    h3:not(:first-of-type) {
      margin-top: 15px;
    }
    p {
      color: ${font.color};
      font-weight: ${font.weightNormal};
      font-size: ${font.sizeNormal};
      margin-top: 0;
      margin-bottom: ${scale.minusThree};
    }
  `,
  rowStyles: () => css`
    border-top: 1px solid ${colors.uiLight};
  `,
  divider: () => css`
    margin: 0 ${scale.two};
    height: 1px;
    background-color: ${colors.uiLightest};
  `,
  linkContainer: () => css`
    align-items: center;
    position: relative;
    display: flex;
    flex-direction: row;
  `,
});

export default BusinessCaseOutputs;
