import { saveAs } from 'file-saver';
import fetchAuth from '../../utils/fetchAuth';
import { toast } from 'react-toastify';
import { notifyConfig } from 'components/toast/config';
import { FormattedMessage } from 'react-intl';
import { errorModal } from 'utils';
import { replace } from '@sharkfinesse/sfl-lib';

export const createCompareOutput = (sessions, locale, type, docxTemplate, pptxTemplate) => {
  let sessionId = '';
  if (sessions.length === 1) {
    sessionId = sessions[0];
  } else {
    for (let i = 0; i < sessions.length; i++) {
      if (i === sessions.length - 1) {
        sessionId += sessions[i];
      } else {
        sessionId += sessions[i] + ';';
      }
    }
  }
  // Send array of sessionIds to api for comparison
  createOutput(sessionId, locale, type, docxTemplate, pptxTemplate);
};

export const createMultipleOutputs = (checked, docxTemplate, pptxTemplate, locale) => {
  for (let x = 0; x < checked.length; x++) {
    createOutput(checked[x], locale, 'longformdocx', docxTemplate, pptxTemplate);
  }
};

export const createOutput = (id, locale, type, docxTemplate, pptxTemplate, suiteId) => {
  let template = type === 'pptx' ? pptxTemplate.value : docxTemplate.value;
  saveDataToFile(id, locale, type, template, suiteId);
};

export const createPrepOutput = (suiteId, sessionId, locale, docxTemplate, pptxTemplate) => {
  createOutput(sessionId, locale, 'prepdocx', docxTemplate, pptxTemplate, suiteId);
};

const createReportServerless = async (sessionId, locale, type, template, suiteId) => {
  let urlString = '';

  if (sessionId.indexOf(';') > -1) {
    // request.query shows as undefined if using ; separator although works fine locally
    sessionId = replace(/;/g, '!*!', sessionId); //=> 'bar bar bar'
  }

  if (type === 'longformdocx' || type === 'shortformdocx') {
    urlString = `output/wordreport/get?locale=${locale}&sessionId=${sessionId}&reportType=${type}&template=${template}&suiteId=${
      suiteId ? suiteId : 'all_suites'
    }`;
  }
  if (type === 'comparedocx') {
    urlString = `output/wordreportmulti/get?locale=${locale}&sessionId=${sessionId}&reportType=${type}&template=${template}&suiteId=${
      suiteId ? suiteId : 'all_suites'
    }`;
  }
  if (type === 'xlsx') {
    urlString = `output/spreadsheet/get?locale=${locale}&sessionId=${sessionId}`;
  }
  if (type === 'comparexlsx') {
    urlString = `output/spreadsheetmulti/get?locale=${locale}&sessionId=${sessionId}&reportType=${type}&template=${template}&suiteId=${
      suiteId ? suiteId : 'all_suites'
    }`;
  }
  if (type === 'prepdocx') {
    urlString = `output/preparation/get?locale=${locale}&sessionId=${sessionId}&reportType=${type}&template=${template}&suiteId=${
      suiteId ? suiteId : 'all_suites'
    }`;
  }
  if (type === 'pptx') {
    urlString = `output/presentation/get?locale=${locale}&sessionId=${sessionId}&reportType=${type}&template=${template}&suiteId=${
      suiteId ? suiteId : 'all_suites'
    }`;
  }

  const response = await fetchAuth(
    {
      url: urlString,
    },
    true
  );

  return response;
};

const saveDataToFile = (id, locale, type, template, suiteId) => {
  toast.loading(
    <FormattedMessage id="report.generate.generating" defaultMessage="Generating report..." />,
    { ...notifyConfig, autoClose: false, toastId: 'generateReport' }
  );

  createReportServerless(id, locale, type, template, suiteId)
    .then(data => {
      let fileName = data.headers.get('output-filename');
      data
        .blob()
        .then(blob => saveAs(blob, fileName))
        .then(x => {
          toast.dismiss('generateReport');
          toast(
            <FormattedMessage id="report.generate.ready" defaultMessage="Your report is ready!" />,
            { ...notifyConfig, closeButton: true }
          );
        });
    })
    .catch(err => {
      toast.dismiss('generateReport');
      errorModal({ error: err.message });
    });
};
