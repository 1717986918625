import { select, fork, take, all, put, call } from 'redux-saga/effects';
import { mergeDeepRight, isNil, loginSelectors, productSelectors } from '@sharkfinesse/sfl-lib';
import {
  actionCreators as abilitiesActionCreators,
  types as abilitiesTypes,
} from '../modules/abilities';
import { types as productTypes } from '../modules/product';
import rsfApp from '../rsf';

export function* getAbilities() {
  const user = yield select(loginSelectors.getUID);
  const abilities = yield call(rsfApp.rsf.firestore.getDocument, `/abilities/${user}`);
  yield put(abilitiesActionCreators.loaded(abilities.data()));
}

export function* mergeAbilities() {
  const [abilities] = yield all([take(abilitiesTypes.LOADED), take(productTypes.LOADED)]);
  const productAbilities = yield select(productSelectors.getAbilities);
  const allAbilities = mergeDeepRight(
    isNil(productAbilities) ? {} : productAbilities,
    isNil(abilities.payload) ? {} : abilities.payload
  );
  yield put(abilitiesActionCreators.sync(allAbilities));
}

export const effects = [call(getAbilities), fork(mergeAbilities)];

export default effects;
