import { css } from 'styled-components';
import { setAlphaHsla } from '@sharkfinesse/sfl-lib';

const MediaManager = ({ font, colors, scale, device: { min_xxs, min_md, min_lg, min_xl } }) => ({
  galleryWrapper: ({ selected, width, height, showSearch }) => css`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: ${showSearch ? '2px' : '16px'} 4px 0px 16px;
  `,
  bodyWrapper: ({ selected, width, height }) => css`
    height: 100%;
    width: 100%;
  `,
  addSessionImageButton: ({ inverse, disabled, addButtonFullWidth }) => css`
    max-width: ${addButtonFullWidth ? '100%' : '1300px'};
    color: ${inverse ? setAlphaHsla(0.6, colors.white) : colors.ui};
  `,
  sessionImageWidth: () => css`
    display: flex;
    flex-basis: 0px;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: center;
    max-width: 1300px;
    position: relative;
    width: 100%;
    height: 100%;
  `,
  sessionImageHeight: () => css`
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    width: 100%;
    padding-top: 22%;
    border-radius: 3px;
  `,
  sessionImageContainer: () => css`
    height: 0;
    overflow: hidden;
    position: absolute;
    width: 100%;
    left: 50%;
    position: absolute;
    top: 0%;
    transform: translate(-50%, 0%);
    padding-top: 75%;
  `,
  sessionImage: ({ isDraggable }) => css`
    display: flex;
    width: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    ${isDraggable &&
    css`
      cursor: move;
    `};
  `,
  sessionImageWrapper: () => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  saveToolbar: () => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    justify-content: space-between;
    padding: 10px;
    z-index: 2;
    background-color: hsla(206, 34%, 11%, 0.7);
  `,
  toolbarLabel: () => css`
    color: ${colors.white};
    font-weight: 600;
  `,
  toolbarButtons: () => css`
    display: flex;
  `,
  menuWrapper: () => css`
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 6px;
  `,
  skeleton: () => css`
    width: 100%;
    height: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    opacity: 0.8;
  `,
  blurredBackground: ({ bgImage }) => css`
    display: flex;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    position: relative;
    ${bgImage
      ? css`
          &::before {
            content: '';
            position: absolute;
            left: -10px;
            right: -10px;
            top: -10px;
            bottom: -10px;
            background-image: url('${bgImage}');
            filter: blur(10px);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }
        `
      : css`
          @media ${min_xxs} {
            padding: ${scale.minusThree} ${scale.minusThree} 0 ${scale.minusThree};
          }
          @media ${min_md} {
            padding: ${scale.zero} ${scale.zero} 0 ${scale.zero};
          }
          @media ${min_lg} {
            padding: ${scale.two} ${scale.two} 0 ${scale.two};
          }
        `}
  `,
});

export default MediaManager;
