/* @flow */
import { simpleAction, createReducer, simpleReducer, appInitialState } from '@sharkfinesse/sfl-lib';
const namespace = (name: string): string => `@@app/${name}`;

//Actions
export const types = {
  PERSIST_STATE: namespace('PERSIST_STATE'),
  LOADING: {
    ACTIVE: namespace('LOADING.ACTIVE'),
    TEXT: namespace('LOADING.TEXT'),
    IMMEDIATE: namespace('LOADING.IMMEDIATE'),
  },
  NON_RECURRING: {
    CALCULATING: namespace('NON_RECURRING.CALCULATING'),
  },
  RECURRING: {
    CALCULATING: namespace('RECURRING.CALCULATING'),
  },
  SESSION: {
    FETCHING: namespace('SESSION.FETCHING'),
  },
  PRECACHE_COMPLETE: namespace('PRECACHE_COMPLETE'),
  SHOW_STATE_MESSAGE: namespace('SHOW_STATE_MESSAGE'),
  FIRESTORE: {
    TERMINATED: namespace('FIRESTORE.TERMINATED'),
  },
  POLICY_ID: namespace('POLICY_ID'),
  TERMS_ID: namespace('TERMS_ID'),
};

//Reducer
const initialState = appInitialState;

const reducePersistState = simpleReducer('persistState');
const reduceLoadingActive = simpleReducer('loading');
const reduceLoadingText = simpleReducer('loadingText');
const reduceLoadingImmediate = simpleReducer('loadingImmediate');
const reduceNonRecurringCalculating = simpleReducer('nonRecurringCalculating');
const reduceRecurringCalculating = simpleReducer('recurringCalculating');
const reduceSessionFetching = simpleReducer('sessionFetching');
const reduceFirestoreTerminated = simpleReducer('firestoreTerminated');
const reducePolicyId = simpleReducer('policyId');
const reduceTermsId = simpleReducer('termsId');

export default createReducer(
  {
    [types.PERSIST_STATE]: reducePersistState,
    [types.LOADING.ACTIVE]: reduceLoadingActive,
    [types.LOADING.TEXT]: reduceLoadingText,
    [types.LOADING.IMMEDIATE]: reduceLoadingImmediate,
    [types.NON_RECURRING.CALCULATING]: reduceNonRecurringCalculating,
    [types.RECURRING.CALCULATING]: reduceRecurringCalculating,
    [types.SESSION.FETCHING]: reduceSessionFetching,
    [types.FIRESTORE.TERMINATED]: reduceFirestoreTerminated,
    [types.POLICY_ID]: reducePolicyId,
    [types.TERMS_ID]: reduceTermsId,
  },
  initialState
);

//Action Creators
const updatePersistState = simpleAction(types.PERSIST_STATE);
const updateLoadingActive = simpleAction(types.LOADING.ACTIVE);
const updateLoadingText = simpleAction(types.LOADING.TEXT);
const updateLoadingImmediate = simpleAction(types.LOADING.IMMEDIATE);
const updateNonRecurringCalculating = simpleAction(types.NON_RECURRING.CALCULATING);
const updateRecurringCalculating = simpleAction(types.RECURRING.CALCULATING);
const updateSessionFetching = simpleAction(types.SESSION.FETCHING);
const preChacheComplete = () => ({ type: types.PRECACHE_COMPLETE });
const updateFirestoreTeminated = simpleAction(types.FIRESTORE.TERMINATED);
const updatePolicyId = simpleAction(types.POLICY_ID);
const updateTermsId = simpleAction(types.TERMS_ID);

export const actionCreators = {
  updatePersistState,
  updateLoadingActive,
  updateLoadingText,
  updateLoadingImmediate,
  updateNonRecurringCalculating,
  updateRecurringCalculating,
  updateSessionFetching,
  preChacheComplete,
  updateFirestoreTeminated,
  updatePolicyId,
  updateTermsId,
};
