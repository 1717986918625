/* @flow */
/* eslint-disable max-len */
import React, { memo } from 'react';
import Icon from '../icon';

let IconDotsVertical = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="#000000"
      fillOpacity="0.6"
      d="M12,22L12,22c-2.8,0-5-2.2-5-5V7c0-2.8,2.2-5,5-5h0c2.8,0,5,2.2,5,5v10C17,19.8,14.8,22,12,22z"
    />
    <path
      fill="currentColor"
      d="M12,15c0.8,0,1.5,0.7,1.5,1.5c0,0.8-0.7,1.5-1.5,1.5
	s-1.5-0.7-1.5-1.5C10.5,15.7,11.2,15,12,15 M12,10.5c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5S11.2,10.5,12,10.5
	 M12,6c0.8,0,1.5,0.7,1.5,1.5S12.8,9,12,9s-1.5-0.7-1.5-1.5S11.2,6,12,6z"
    />
  </Icon>
);

export default memo(IconDotsVertical);
