/* @flow */
/* eslint-disable no-magic-numbers */

import {
  Scale,
  Font,
  Colors,
  Body,
  HeadingH1Default,
  HeadingH1Suite,
  HeadingSubDefault,
  HeadingSubSuite,
  Panel,
  ButtonIcon,
  ButtonSmall,
  ButtonNormal,
  ButtonLarge,
  ButtonDefault,
  ButtonPrimary,
  ButtonLight,
  ButtonDark,
  ButtonLightest,
  DropDownMessageDefault,
  DropDownMessageFocused,
  DropDownMessageInvalid,
  DropDownMessageWarning,
  Suite,
  SuiteButton,
  Evaluator,
  Scroll,
  DividerDefault,
  Icons,
  LayoutBenefits,
  Page,
  LayoutHeader,
  CostsPanel,
  Columns,
  Costs,
  NonRecurringCostsColumns,
  RecurringCostsColumns,
  NavBarEvaluator,
  NavBarPills,
  Logo,
  Header,
  DividerNav,
  Commentary,
  ZIndex,
  SavingMessage,
  SelectControl,
  SelectValueContainer,
  SelectDropdownIndicator,
  SelectSingleValue,
  SelectPlaceholder,
  SelectMenu,
  SelectOption,
  Modal,
  PageToolBar,
  Popper,
  Menu,
  Toast,
  HeadlineFigure,
  Hyperlink,
  BusinessCaseOutputs,
  Preferences,
  Account,
  TooltipError,
  Mixins,
  Images,
  LinkButton,
  Toolbar,
  BenefitLayoutDesigner,
  AddButton,
  Checkbox,
  ChartColors,
  Circle,
  ReactTable,
  CompareSessions,
  SuitesToolbar,
  MultiValue,
  MultiValueRemove,
  MultiValueLabel,
  ToggleSwitch,
  Tree,
  RichText,
  Headings,
  Sections,
  Alerts,
  TooltipInfo,
  InputConfirm,
  CashFlow,
  Badge,
  AnimatedIcons,
  Slider,
  RemoveFromOutput,
  Tender,
  DoNothing,
  Delay,
  Sidebar,
  Calculator,
  HotkeyLabel,
  DealIndicator,
  ExpandIconButton,
  Gallery,
  MediaManager,
  Dropzone,
  UploadFile,
  Carousel,
  ResultBox,
  SuperscriptNumber,
  MainMenu,
  Skeleton,
  Slide,
  TitlePane,
  SelectToolbar,
  BurgerMenu,
  SlideGrid,
  Search,
  Sort,
  Hero,
  breakpoints,
  Device,
  Tabs,
  Breadcrumb,
  TreePicker,
  ExpandingListItem,
  Pill,
  General,
  DatePicker,
  CircularProgressBar,
  ProgressMenu,
  Notification,
  ListPanel,
  Drawer,
  EmailPreview,
  ToolbarButton,
  Avatar,
  Timeline,
  ButtonGroup,
  Form,
  Picker,
  Loader,
  RadioButton,
} from './core';

const BaseTheme = (
  name = 'Base',
  config = {
    colors: Colors(),
    scale: Scale(),
    font: Font(),
    breakpoints: breakpoints,
    device: Device,
  },
  overrides = {}
) => {
  const { colors, scale, font, breakpoints, device } = config;
  const {
    chartTheme = 'light',
    body = Body(config),
    headingH1Default = HeadingH1Default(config),
    headingH1Suite = HeadingH1Suite(config),
    headingSubDefault = HeadingSubDefault(config),
    headingSubSuite = HeadingSubSuite(config),
    panel = Panel(config),
    buttonIcon = ButtonIcon(config),
    buttonSmall = ButtonSmall(config),
    buttonNormal = ButtonNormal(config),
    buttonLarge = ButtonLarge(config),
    buttonDefault = ButtonDefault(config),
    buttonPrimary = ButtonPrimary(config),
    buttonLight = ButtonLight(config),
    buttonDark = ButtonDark(config),
    buttonLightest = ButtonLightest(config),
    dropDownMessageDefault = DropDownMessageDefault(config),
    dropDownMessageFocused = DropDownMessageFocused(config),
    dropDownMessageInvalid = DropDownMessageInvalid(config),
    dropDownMessageWarning = DropDownMessageWarning(config),
    suite = Suite(config),
    suiteButton = SuiteButton(config),
    evaluator = Evaluator(config),
    scroll = Scroll(config),
    dividerDefault = DividerDefault(config),
    icons = Icons(config),
    layoutBenefits = LayoutBenefits(config),
    page = Page(config),
    layoutHeader = LayoutHeader(config),
    costsPanel = CostsPanel(config),
    columns = Columns(config),
    costs = Costs(config),
    nonRecurringCostsColumns = NonRecurringCostsColumns(config),
    recurringCostsColumns = RecurringCostsColumns(config),
    navBarEvaluator = NavBarEvaluator(config),
    navBarPills = NavBarPills(config),
    logo = Logo(config),
    header = Header(config),
    dividerNav = DividerNav(config),
    commentary = Commentary(config),
    zIndex = ZIndex(config),
    savingMessage = SavingMessage(config),
    selectControl = SelectControl(config),
    selectValueContainer = SelectValueContainer(config),
    selectDropdownIndicator = SelectDropdownIndicator(config),
    selectSingleValue = SelectSingleValue(config),
    selectPlaceholder = SelectPlaceholder(config),
    selectMenu = SelectMenu(config),
    selectOption = SelectOption(config),
    modal = Modal(config),
    pageToolBar = PageToolBar(config),
    popper = Popper(config),
    menu = Menu(config),
    toast = Toast(config),
    headlineFigure = HeadlineFigure(config),
    hyperlink = Hyperlink(config),
    businessCaseOutputs = BusinessCaseOutputs(config),
    preferences = Preferences(config),
    account = Account(config),
    tooltipError = TooltipError(config),
    mixins = Mixins(config),
    images = Images(config),
    linkButton = LinkButton(config),
    toolbar = Toolbar(config),
    benefitLayoutDesigner = BenefitLayoutDesigner(config),
    addButton = AddButton(config),
    checkbox = Checkbox(config),
    chartColors = ChartColors(config),
    circle = Circle(config),
    reactTable = ReactTable(config),
    compareSessions = CompareSessions(config),
    suitesToolbar = SuitesToolbar(config),
    multiValue = MultiValue(config),
    multiValueRemove = MultiValueRemove(config),
    multiValueLabel = MultiValueLabel(config),
    toggleSwitch = ToggleSwitch(config),
    tree = Tree(config),
    richText = RichText(config),
    headings = Headings(config),
    sections = Sections(config),
    alerts = Alerts(config),
    tooltipInfo = TooltipInfo(config),
    inputConfirm = InputConfirm(config),
    cashFlow = CashFlow(config),
    badge = Badge(config),
    animatedIcons = AnimatedIcons(config),
    slider = Slider(config),
    removeFromOutput = RemoveFromOutput(config),
    tender = Tender(config),
    doNothing = DoNothing(config),
    delay = Delay(config),
    sidebar = Sidebar(config),
    calculator = Calculator(config),
    hotkeyLabel = HotkeyLabel(config),
    dealIndicator = DealIndicator(config),
    expandIconButton = ExpandIconButton(config),
    gallery = Gallery(config),
    mediaManager = MediaManager(config),
    dropzone = Dropzone(config),
    uploadFile = UploadFile(config),
    carousel = Carousel(config),
    resultBox = ResultBox(config),
    superscriptNumber = SuperscriptNumber(config),
    mainMenu = MainMenu(config),
    skeleton = Skeleton(config),
    slide = Slide(config),
    titlePane = TitlePane(config),
    selectToolbar = SelectToolbar(config),
    burgerMenu = BurgerMenu(config),
    slideGrid = SlideGrid(config),
    search = Search(config),
    sort = Sort(config),
    hero = Hero(config),
    tabs = Tabs(config),
    breadcrumb = Breadcrumb(config),
    treePicker = TreePicker(config),
    expandingListItem = ExpandingListItem(config),
    pill = Pill(config),
    general = General(config),
    datePicker = DatePicker(config),
    circularProgressBar = CircularProgressBar(config),
    progressMenu = ProgressMenu(config),
    notification = Notification(config),
    listPanel = ListPanel(config),
    drawer = Drawer(config),
    emailPreview = EmailPreview(config),
    toolbarButton = ToolbarButton(config),
    avatar = Avatar(config),
    timeline = Timeline(config),
    buttonGroup = ButtonGroup(config),
    form = Form(config),
    picker = Picker(config),
    loader = Loader(config),
    radioButton = RadioButton(config),
  } = overrides;

  return {
    name,
    chartTheme,
    breakpoints,
    device,
    colors,
    font,
    scale,
    body,
    headingH1Default,
    headingH1Suite,
    headingSubDefault,
    headingSubSuite,
    panel,
    buttonIcon,
    buttonSmall,
    buttonNormal,
    buttonLarge,
    buttonDefault,
    buttonPrimary,
    buttonLight,
    buttonDark,
    buttonLightest,
    dropDownMessageDefault,
    dropDownMessageFocused,
    dropDownMessageInvalid,
    dropDownMessageWarning,
    suite,
    suiteButton,
    evaluator,
    scroll,
    dividerDefault,
    icons,
    layoutBenefits,
    page,
    layoutHeader,
    costsPanel,
    columns,
    costs,
    nonRecurringCostsColumns,
    recurringCostsColumns,
    navBarEvaluator,
    navBarPills,
    logo,
    header,
    dividerNav,
    commentary,
    zIndex,
    savingMessage,
    selectControl,
    selectValueContainer,
    selectDropdownIndicator,
    selectSingleValue,
    selectPlaceholder,
    selectMenu,
    selectOption,
    modal,
    pageToolBar,
    popper,
    menu,
    toast,
    headlineFigure,
    hyperlink,
    businessCaseOutputs,
    preferences,
    account,
    tooltipError,
    mixins,
    images,
    linkButton,
    toolbar,
    benefitLayoutDesigner,
    addButton,
    checkbox,
    chartColors,
    circle,
    reactTable,
    compareSessions,
    suitesToolbar,
    multiValue,
    multiValueRemove,
    multiValueLabel,
    toggleSwitch,
    tree,
    richText,
    headings,
    sections,
    alerts,
    tooltipInfo,
    inputConfirm,
    cashFlow,
    badge,
    animatedIcons,
    slider,
    removeFromOutput,
    tender,
    doNothing,
    delay,
    sidebar,
    calculator,
    hotkeyLabel,
    dealIndicator,
    expandIconButton,
    gallery,
    mediaManager,
    dropzone,
    uploadFile,
    carousel,
    resultBox,
    superscriptNumber,
    mainMenu,
    skeleton,
    slide,
    titlePane,
    selectToolbar,
    burgerMenu,
    slideGrid,
    search,
    sort,
    hero,
    tabs,
    breadcrumb,
    treePicker,
    expandingListItem,
    pill,
    general,
    datePicker,
    circularProgressBar,
    progressMenu,
    notification,
    listPanel,
    drawer,
    emailPreview,
    toolbarButton,
    avatar,
    timeline,
    buttonGroup,
    form,
    picker,
    loader,
    radioButton,
  };
};
export default BaseTheme;
