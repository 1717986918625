import * as React from 'react';
import { Indicator } from './styled';

const TabIndicator = React.forwardRef(function TabIndicator(props, ref) {
  const { orientation, inverse, ...other } = props;

  return <Indicator orientation={orientation} inverse={inverse} ref={ref} {...other} />;
});

export default TabIndicator;
