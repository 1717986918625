// @flow
import React from 'react';
import { Burger } from './styled';

type Props = {
  isOpen: Boolean,
};
const BurgerMenu = ({ isOpen, ...props }: Props) => (
  <Burger isOpen={isOpen} {...props}>
    <div />
    <div />
    <div />
  </Burger>
);

export default BurgerMenu;
