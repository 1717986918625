"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reselect = require("reselect");

var _index = require("../../index");

var _account = _interopRequireDefault(require("./account"));

var _intl = _interopRequireDefault(require("./intl"));

var _fuse = _interopRequireDefault(require("fuse.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* eslint-disable no-unused-vars  */
const getProduct = state => state.product;

const getProductId = state => state.product.id;

const getLoaded = state => state.product.loaded;

const getName = state => state.product.name;

const getProductCategoryTypes = state => state.product.categoryTypes;

const getLocales = state => state.product.locales;

const getLogos = state => state.product.logos;

const getLogo = (state, logo) => state.product.logos[logo];

const getLogoBase64 = (state, logo) => state.product.logos[logo].base64;

const getLogoUrl = (state, logo) => state.product.logos[logo].url;

const getPreferences = state => state.product.preferences;

const getResourceOwner = state => state.product.resourceOwner;

const getCoverpages = state => state.product.coverpages;

const getCoverpage = (state, coverpage) => state.product.coverpages[coverpage];

const getCoverpageBase64 = (state, coverpage) => state.product.coverpages[coverpage].base64;

const getCoverpageUrl = (state, coverpage) => state.product.coverpages[coverpage].url;

const getLoadIntl = state => state.product.loadIntl;

const getCategoryTypes = (0, _reselect.createSelector)([getProductCategoryTypes, _account.default.getCategoryTypes, _intl.default.getMessages], (productCategoryTypes, accountCategoryTypes, intl) => {
  const findCategoryType = type => {
    const category = accountCategoryTypes[type];
    const result = { ...category,
      label: intl[`category.${category.id}.label`]
    };
    return result;
  };

  return accountCategoryTypes && productCategoryTypes ? (0, _index.map)(findCategoryType, productCategoryTypes) : [];
});
const getCategories = (0, _reselect.createSelector)([getCategoryTypes], (0, _index.pipe)((0, _index.map)(v => v.categories), (0, _index.reduce)(_index.concat, []), _index.uniq));
const getEvaluatorsIds = (0, _reselect.createSelector)([getCategories, _account.default.getCategories], (productCategories, accountCategories) => {
  const f = (0, _index.pipe)((0, _index.map)(v => accountCategories[v].evaluators), (0, _index.reduce)(_index.concat, []), _index.uniq);
  return f(productCategories);
});

const getLabels = (intl, id) => {
  return (0, _index.keys)(intl).filter(function (k) {
    return k.indexOf(id) === 0 && k.indexOf('Error') === -1 && k.indexOf('title') === -1;
  }).reduce(function (newData = [], k) {
    newData[newData.length] = intl[k];
    return newData;
  }, []);
};

const getEvaluatorsList = (0, _reselect.createSelector)([getEvaluatorsIds, _account.default.getEvaluators, _intl.default.getMessages], (evaluatorIds, evaluators, intl) => (0, _index.map)(v => {
  const id = evaluators[v].schema.id;
  const title = intl[`${id}.title.label`];
  return {
    id,
    title: (0, _index.isNil)(title) ? evaluators[v].title : title,
    benefitCategory: evaluators[v].schema.benefitCategory,
    labels: getLabels(intl, id)
  };
}, evaluatorIds));

const getEvaluatorsListSorting = state => state.product.evaluatorListSorting;

const getEvaluatorsListFilters = state => state.product.evaluatorListFilters;

const getEvaluatorsListSearch = state => state.product.evaluatorListSearch;

const getEvaluatorsListFiltered = (0, _reselect.createSelector)([getEvaluatorsListFilters, getEvaluatorsList], (filter, evaluators) => {
  if (!(0, _index.isNil)(filter)) {
    const fuse = new _fuse.default(evaluators, {
      shouldSort: false,
      threshold: 0,
      location: 0,
      distance: 300,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      tokenize: true,
      keys: [filter.key]
    });
    const fuseResult = (0, _index.map)((0, _index.prop)('item'), fuse.search(filter.value));
    return fuseResult;
  } else {
    return evaluators;
  }
});
const fuseSearchOptions = {
  shouldSort: true,
  includeMatches: true,
  threshold: 0.1,
  tokenize: true,
  matchAllTokens: true,
  location: 0,
  distance: 300,
  maxPatternLength: 32,
  minMatchCharLength: 2,
  findAllMatches: true,
  keys: [{
    name: 'title',
    weight: 0.9
  }, {
    name: 'labels',
    weight: 0.1
  }]
};
const getEvaluatorsListSearched = (0, _reselect.createSelector)([getEvaluatorsListSearch, getEvaluatorsListFiltered], (searchTxt, evaluators) => {
  const fuse = new _fuse.default(evaluators, fuseSearchOptions);
  return searchTxt ? fuse.search(searchTxt.trim()) : evaluators;
});
const getEvaluatorsListSorted = (0, _reselect.createSelector)([getEvaluatorsListSorting, getEvaluatorsListFiltered], (sorting, evaluators) => (0, _index.sortCaseInsensitive)({
  data: evaluators,
  direction: sorting.direction,
  field: sorting.field
}));
const getEvaluatorsBenefitCategories = (0, _reselect.createSelector)([getEvaluatorsList], (0, _index.pipe)((0, _index.map)(v => (0, _index.isNil)(v.benefitCategory.length) ? [v.benefitCategory] : v.benefitCategory), (0, _index.reduce)(_index.concat, []), _index.uniq, (0, _index.sort)((a, b) => a - b)));

const getAbilities = state => state.product.abilities;

const selectors = {
  getProduct,
  getProductId,
  getLoaded,
  getName,
  getCategoryTypes,
  getCategories,
  getEvaluatorsIds,
  getEvaluatorsListSorting,
  getEvaluatorsListSearch,
  getEvaluatorsList,
  getEvaluatorsListFilters,
  getEvaluatorsListSearched,
  getEvaluatorsListSorted,
  getEvaluatorsBenefitCategories,
  getEvaluatorsListFiltered,
  getAbilities,
  getLocales,
  getLogos,
  getLogo,
  getLogoBase64,
  getLogoUrl,
  getPreferences,
  getResourceOwner,
  getCoverpages,
  getCoverpage,
  getCoverpageBase64,
  getCoverpageUrl,
  getLoadIntl
};
var _default = selectors;
exports.default = _default;