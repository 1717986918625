// @flow
/* eslint-disable no-magic-numbers, react/prop-types */
import React from 'react';
import { GridContainer, GridWrapper, CompactGridWrapper } from './styled';
import Skeleton from '../skeleton';

const getSkeletons = (length) => {
  const skeletons = [];
  for (let i = 0; i < length; i += 1) {
    skeletons.push(<Skeleton color="white" borderRadius="12px" key={i} id={i} />);
  }
  return skeletons;
};

const SlideGrid = ({ children, isLoading, tools, type, skeletons = 60, ...props }) => {
  const skeleton = getSkeletons(skeletons);

  let GridComponent = GridWrapper;

  if (type === 'compact') {
    GridComponent = CompactGridWrapper;
  }

  return (
    <GridContainer {...props}>
      {tools}
      <GridComponent>{isLoading ? skeleton : children}</GridComponent>
    </GridContainer>
  );
};

export default SlideGrid;
