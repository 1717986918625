/* @flow */
import { combineReducers } from 'redux';
import undoable, { includeAction } from 'redux-undo';
import { simpleAction, sessionInitialState, simpleActionMeta } from '@sharkfinesse/sfl-lib';
import details from './session-details';
import nonRecurringCosts from './non-recurring-costs';
import recurringCosts from './recurring-costs';
import benefits from './evaluator';
import businessCase from './business-case';
import suiteList from 'redux/modules/suite-list';
import cashflow from './cashflow';
import delay from './delay';
import tender from './tender';
import doNothing from './do-nothing';
import { layout } from './layout';
import owner from './owner';
import undoredo from './undoredo';
import businessPreparation from './business-preparation';
import defaultSessionValues from './default-session-values';
import groupBy from 'redux/undo/groupBy';
import undoableActions from 'redux/undo/undoable-actions';
import plugins from 'redux/modules/plugins/plugins-session';
import questionnaire from 'redux/modules/questionnaire';
const namespace = (name: string): string => `@@session/${name}`;

// types
export const types = {
  UNDO: namespace('UNDO'),
  REDO: namespace('REDO'),
  CLEAR_HISTORY: namespace('CLEAR_HISTORY'),
  LOAD: namespace('LOAD'),
  UNLOAD: namespace('UNLOAD'),
  LEAVE: namespace('LEAVE'),
  INIT: namespace('INIT'),
  CREATE: namespace('CREATE'),
  SYNC: namespace('SYNC'),
  RESET: namespace('RESET'),
  DUPLICATE: namespace('DUPLICATE'),
  COMPARE: namespace('COMPARE'),
  UPDATE: namespace('UPDATE'),
  SYNCED: namespace('SYNCED'),
  OPEN: namespace('OPEN'),
  RECALCULATE: namespace('RECALCULATE'),
  DO_SYNC: namespace('DO_SYNC'),
  SYNC_UPDATE: namespace('SYNC_UPDATE'),
  IMPORT: namespace('IMPORT'),
  SAVE: namespace('SAVE'),
};

export const id = (state = '', action) => {
  return state;
};

const updated = (state = '', action) => {
  return state;
};
const created = (state = '', action) => {
  return state;
};
const dealName = (state = '', action) => {
  return state;
};
const account = (state = '', action) => {
  return state;
};
const product = (state = '', action) => {
  return state;
};

const loaded = (state = '', action) => {
  return state;
};
const imageUrl = (state = '', action) => {
  return state;
};
const imageId = (state = '', action) => {
  return state;
};

const session = combineReducers({
  dealName,
  account,
  imageUrl,
  imageId,
  product,
  updated,
  created,
  benefits,
  cashflow,
  businessCase,
  details,
  nonRecurringCosts,
  owner,
  id,
  layout,
  recurringCosts,
  suiteList,
  undoredo,
  businessPreparation,
  defaultSessionValues,
  delay,
  tender,
  doNothing,
  loaded,
  questionnaire,
  plugins,
});

const initialState = sessionInitialState;
const SessionReducer =
  () =>
  (state = initialState, action) => {
    switch (action.type) {
      case types.SYNC:
        //loaded state from firestore
        if (!action.payload) {
          return state;
        } else {
          return session(action.payload, action);
        }
      case types.SYNCED:
        //loaded state from firestore
        return { ...state, loaded: true };
      case types.RESET:
        return session(initialState, action);
      case types.UPDATE:
        return action.payload;
      case types.UNLOAD:
        return initialState;
      case types.SYNC_UPDATE:
        return { ...state, ...action.payload };
      case types.IMPORT:
        return { ...state };

      default:
        return session(state, action);
    }
  };

//Action Creators
const load = simpleAction(types.LOAD);
const unLoad = simpleAction(types.UNLOAD);
const init = simpleAction(types.INIT);
const create = simpleAction(types.CREATE);
const sync = simpleAction(types.SYNC);
const reset = () => ({ type: types.RESET });
const duplicate = simpleAction(types.DUPLICATE);
const update = simpleAction(types.UPDATE);
const undo = () => ({ type: types.UNDO });
const redo = () => ({ type: types.REDO });
const clearHistory = () => ({ type: types.CLEAR_HISTORY });
const synced = simpleAction(types.SYNCED);
const compare = payload => {
  return { type: types.COMPARE, payload };
};
const open = simpleAction(types.OPEN);
const leave = simpleAction(types.LEAVE);
const reCalculate = simpleActionMeta(types.RECALCULATE);
const doSync = simpleAction(types.DO_SYNC);
const syncUpdate = simpleAction(types.SYNC_UPDATE);
const importSession = simpleActionMeta(types.IMPORT);
const save = simpleAction(types.SAVE);

export const actionCreators = {
  load,
  unLoad,
  init,
  create,
  sync,
  reset,
  duplicate,
  update,
  undo,
  redo,
  clearHistory,
  compare,
  synced,
  open,
  leave,
  reCalculate,
  doSync,
  syncUpdate,
  importSession,
  save,
};

export default undoable(SessionReducer(), {
  syncFilter: true,
  undoType: types.UNDO,
  redoType: types.REDO,
  clearHistoryType: types.CLEAR_HISTORY,
  limit: 10,
  debug: false,
  groupBy,
  filter: includeAction([...undoableActions, types.IMPORT]),
});
