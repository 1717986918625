"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMostFrequentCurrency = exports.generateComparisonObjects = exports.generateComparison = exports.addRecurrenceToSession = void 0;

var _cashflow = require("./cashflow");

var _fp = require("./fp");

var _normalization = require("./normalization");

var _irr = require("./irr");

var _npv = require("./npv");

var _payback = require("./payback");

const getRate = session => session.details.minimumReturn;

const getCurrency = session => session.details.currency.value;

const getCurrencyPosition = session => session.details.currency.position;

const getReviewPeriod = session => (0, _normalization.adjustTimeUnit)('months', session.details.reviewPeriod);

const getSessionName = session => session.details.dealName;

const filterMonthly = (0, _fp.pipe)((0, _fp.filter)((0, _fp.propEq)('recurrence', 'monthly')), _fp.keys);
const filterOneTime = (0, _fp.pipe)((0, _fp.filter)((0, _fp.propEq)('recurrence', 'once')), _fp.keys);

const getMonthlyCashflows = session => session.benefits ? (0, _fp.props)(filterMonthly(session.benefits.data), session.cashflow.benefits) : [];

const getOneTimeCashflows = session => session.benefits ? (0, _fp.props)(filterOneTime(session.benefits.data), session.cashflow.benefits) : []; // prettier-ignore


const getTotalMonthlyCashflow = (0, _fp.pipe)(getMonthlyCashflows, (0, _fp.apply)(_cashflow.sumCashflows)); // prettier-ignore

const getTotalMonthlyBenefits = (0, _fp.pipe)(getTotalMonthlyCashflow, _cashflow.sumCashflow); // prettier-ignore

const getTotalOneTimeCashflow = (0, _fp.pipe)(getOneTimeCashflows, (0, _fp.apply)(_cashflow.sumCashflows)); // prettier-ignore

const getTotalOneTimeBenefits = (0, _fp.pipe)(getTotalOneTimeCashflow, _cashflow.sumCashflow); // prettier-ignore

const getTotalBenefits = (0, _fp.converge)(_fp.add, [getTotalOneTimeBenefits, getTotalMonthlyBenefits]); // prettier-ignore

const getTotalBenefitsCashflow = (0, _fp.converge)(_cashflow.sumCashflows, [getTotalOneTimeCashflow, getTotalMonthlyCashflow]);

const getNonRecurringCashflows = session => session.cashflow ? (0, _fp.values)(session.cashflow.nonRecurringCosts) : [];

const getRecurringCashflows = session => session.cashflow ? (0, _fp.values)(session.cashflow.recurringCosts) : []; // prettier-ignore


const getTotalNonRecurringCashflow = (0, _fp.pipe)(getNonRecurringCashflows, (0, _fp.apply)(_cashflow.sumCashflows), _cashflow.negateCashflow); // prettier-ignore

const getTotalNonRecurringCosts = (0, _fp.pipe)(getTotalNonRecurringCashflow, _cashflow.sumCashflow, _fp.negate); // prettier-ignore

const getTotalRecurringCashflow = (0, _fp.pipe)(getRecurringCashflows, (0, _fp.apply)(_cashflow.sumCashflows), _cashflow.negateCashflow); // prettier-ignore

const getTotalRecurringCosts = (0, _fp.pipe)(getTotalRecurringCashflow, _cashflow.sumCashflow, _fp.negate); // prettier-ignore

const getTotalCosts = (0, _fp.converge)(_fp.add, [getTotalNonRecurringCosts, getTotalRecurringCosts]); // prettier-ignore

const getTotalCostsCashflow = (0, _fp.converge)(_cashflow.sumCashflows, [getTotalNonRecurringCashflow, getTotalRecurringCashflow]); // prettier-ignore

const getNetCashflow = (0, _fp.converge)(_cashflow.sumCashflows, [getTotalMonthlyCashflow, getTotalOneTimeCashflow, getTotalNonRecurringCashflow, getTotalRecurringCashflow]); // prettier-ignore

const getNet = (0, _fp.pipe)(getNetCashflow, _cashflow.sumCashflow); // prettier-ignore

const getNpvWithCashflow = (0, _fp.converge)(_npv.npvWithDcf, [getRate, getNetCashflow]); // prettier-ignore

const getIrrWithCashflow = (0, _fp.converge)((0, _fp.pipe)(_irr.generateIrrObject, _irr.irrWithDcf), [getReviewPeriod, getTotalNonRecurringCashflow, getTotalRecurringCashflow, getTotalOneTimeCashflow, getTotalMonthlyCashflow, getRate]); // prettier-ignore

const getPayback = (0, _fp.pipe)(getNetCashflow, _payback.payback);

const getCustomerName = session => session.details.customerName;

const getSupplierName = session => session.details.supplierName;

const getIndustry = session => session.details.industry;

const getPeakBenefits = session => {
  return (0, _fp.reduce)(_fp.max, 0)(getTotalMonthlyCashflow(session));
};

const getBenefitsDetails = session => session.benefits;

const getNonRecurringCostsDetails = session => session.nonRecurringCosts;

const getRecurringCostsDetails = session => session.recurringCosts;

const getLayout = session => session.layout;

const getCashflow = session => session.cashflow;

const generateComparisonObject = session => {
  const sessionName = getSessionName(session);

  const payback = _fp.Maybe.fromMaybe(-1)(getPayback(session));

  const irr = _fp.Maybe.fromMaybe({
    irr: '-',
    cashflow: []
  })(getIrrWithCashflow(session));

  const totalBenefitsCashflow = getTotalBenefitsCashflow(session);
  return {
    sessionName: sessionName ? sessionName : '-',
    period: getReviewPeriod(session),
    minimumReturnRate: getRate(session),
    nonRecurringCosts: getTotalNonRecurringCosts(session),
    nonRecurringCostsCashflow: (0, _cashflow.negateCashflow)(getTotalNonRecurringCashflow(session)),
    recurringCosts: getTotalRecurringCosts(session),
    recurringCostsCashflow: (0, _cashflow.negateCashflow)(getTotalRecurringCashflow(session)),
    totalCosts: getTotalCosts(session),
    totalCostsCashflow: (0, _cashflow.negateCashflow)(getTotalCostsCashflow(session)),
    oneTimeBenefits: getTotalOneTimeBenefits(session),
    oneTimeBenefitsCashflow: getTotalOneTimeCashflow(session),
    monthlyBenefits: getTotalMonthlyBenefits(session),
    monthlyBenefitsCashflow: getTotalMonthlyCashflow(session),
    totalBenefits: getTotalBenefits(session),
    totalBenefitsCashflow: totalBenefitsCashflow ? totalBenefitsCashflow : [],
    net: getNet(session),
    netCashflow: getNetCashflow(session),
    npv: getNpvWithCashflow(session).npv,
    npvCashflow: getNpvWithCashflow(session).cashflow,
    irr: irr.irr,
    irrCashflow: irr.cashflow,
    payback: payback >= 0 ? (0, _normalization.adjustTimeUnit)('months', (0, _normalization.decreaseFormFieldValueByFfpf)(payback)) : '-',
    paybackCashflow: (0, _cashflow.accumulateCashflow)(getNetCashflow(session)),
    currency: getCurrency(session),
    currencyPosition: getCurrencyPosition(session),
    customerName: getCustomerName(session),
    supplierName: getSupplierName(session),
    industry: getIndustry(session),
    peakBenefits: getPeakBenefits(session),
    benefitsDetails: getBenefitsDetails(session),
    nonRecurringCostsDetails: getNonRecurringCostsDetails(session),
    recurringCostsDetails: getRecurringCostsDetails(session),
    layout: getLayout(session),
    cashflow: getCashflow(session)
  };
}; // prettier-ignore


const getCurrenciesByFrequency = a => (0, _fp.map)((0, _fp.pipe)((0, _fp.pathEq)(['numberFormatObj', 'currencySymbol'], _fp.__), (0, _fp.filter)(_fp.__, a)), (0, _fp.uniq)((0, _fp.pipe)((0, _fp.pluck)('numberFormatObj'), (0, _fp.pluck)('currencySymbol'))(a))).sort(); // prettier-ignore


const getMostFrequentCurrency = (0, _fp.pipe)(getCurrenciesByFrequency, _fp.last, _fp.head, (0, _fp.path)(['numberFormatObj', 'currencySymbol']));
exports.getMostFrequentCurrency = getMostFrequentCurrency;

const generateComparisonObjects = sessions => (0, _fp.reduce)((a, b) => (0, _fp.append)(generateComparisonObject(b), a), [], sessions); // Temporary fix to add evaluator recurrence to older sessions for comparison
// Remove in future when recent deals all have recurrence set
// Deals with no recurrence set will show 0 benefits in comparison


exports.generateComparisonObjects = generateComparisonObjects;
const generateENoLookup = (0, _fp.map)((0, _fp.prop)('eNo'));
const setRecurrence = (0, _fp.assoc)('recurrence');
const getRecurrence = (0, _fp.curry)((evaluators, eNo) => {
  return evaluators[eNo].schema.recurrence;
});
const addRecurrenceToSession = (0, _fp.curry)((evaluators, session) => {
  const eNoLookup = generateENoLookup(session.layout.benefits);
  const recurrenceLookup = (0, _fp.map)(getRecurrence(evaluators), eNoLookup);
  const newBenefitsData = (0, _fp.map)(ben => setRecurrence(recurrenceLookup[ben.id], ben), session.benefits.data);
  return (0, _fp.assocPath)(['benefits', 'data'], newBenefitsData, session);
});
exports.addRecurrenceToSession = addRecurrenceToSession;

const generateComparison = sessions => generateComparisonObjects(sessions);

exports.generateComparison = generateComparison;