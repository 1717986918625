/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

let IconChevronLeft = (props): React.Element<any> => (
  <Icon {...props}>
    <path fill="currentColor" d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
  </Icon>
);

export default memo(IconChevronLeft);
