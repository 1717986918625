import rsfApp from 'redux/rsf';
import firebase from 'firebase/app';

import moment from 'moment';

export const getSessions = ({ userId, account, limit = 20, source = 'default' }) =>
  rsfApp.firestore
    .collection(`accounts/${account}/sessions`)
    .where('owner', '==', userId)
    .where('archived', '<', true)
    .orderBy('archived', 'desc')
    .orderBy('updated', 'desc')
    .limit(limit)
    .get({ source });

export const getFolderSessions = ({ userId, folderId, account, limit = 500, source = 'default' }) =>
  rsfApp.firestore
    .collection(`accounts/${account}/sessions`)
    .where('owner', '==', userId)
    .where('archived', '<', true)
    .where('folder', '==', folderId)
    .orderBy('archived', 'desc')
    .orderBy('updated', 'desc')
    .limit(limit)
    .get({ source });

export const getEditorSessions = ({ userId, account, limit = 20, source = 'default' }) =>
  rsfApp.firestore
    .collection(`accounts/${account}/sessions`)
    .where('editors', 'array-contains', userId)
    .where('archived', '<', true)
    .orderBy('archived', 'desc')
    .orderBy('updated', 'desc')
    .limit(limit)
    .get({ source });

export const getTrashedSessions = ({ userId, account, limit = 300 }) => {
  const fromDate = moment().subtract(12, 'months').toDate();

  const updatedFrom = firebase.firestore.Timestamp.fromDate(fromDate);

  return rsfApp.firestore
    .collection(`accounts/${account}/sessions`)
    .where('owner', '==', userId)
    .where('archived', '==', true)
    .where('updated', '>=', updatedFrom)
    .orderBy('updated', 'desc')
    .limit(limit)
    .get();
};
