// @flow
import styled from 'styled-components';

export const BodyWrapper = styled.div`
  ${p => p.theme.modal.bodyWrapper()};
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 0 0 30px;
  max-width: 300px;
  align-self: flex-end;
`;
export const ContentWrapper = styled.div`
  padding: 0 0 20px 0;
`;
export const SwitchWrapper = styled.div`
  padding-top: 4px;
`;
