import { css } from 'styled-components';

const Icons = ({ font, colors, scale }) => ({
  defaultColor: colors.ui,
  inverseColor: colors.white,
  starred: () => css`
    color: #ffcc00;
  `,
  pro: ({ $disabled }) => css`
    color: ${$disabled ? colors.white : '#ffcc00'};
  `,
});

export default Icons;
