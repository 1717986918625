import rsfApp from 'redux/rsf';

export const getReportTemplates = async ({ product, account, limit = 20 }) => {
  let templatePathPresentation = {};
  let templatePathReport = {};

  const presentation = await rsfApp.firestore
    .doc(`products/${product}/templates/presentation`)
    .get()
    .then(snapshot => {
      const file = snapshot.data();
      templatePathPresentation = file ? file : {};
      return Object.values(templatePathPresentation);
    });

  const report = await rsfApp.firestore
    .doc(`products/${product}/templates/report`)
    .get()
    .then(snapshot => {
      const file = snapshot.data();
      templatePathReport = file ? file : {};
      return Object.values(templatePathReport);
    });

  const templatesObj = {
    presentation: presentation,
    report: report,
  };

  return templatesObj;
};
