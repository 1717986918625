/* @flow */
import React from 'react';
import type { Element } from 'react';
import { ModalFooter } from './styled';

type Props = {
  children?: Element<any>,
  showKeyline: boolean,
};

const Footer = ({ children, showKeyline, ...props }: Props) => (
  <ModalFooter {...props} showKeyline={showKeyline}>
    {children}
  </ModalFooter>
);

export default Footer;
