/* @flow */
import React from 'react';
import type { Element } from 'react';
import { BadgeWrapper } from './styled';

type Props = {
  children: Any,
  selected: Boolean,
  tooltip: Element<*> | string,
};

const Badge = ({ selected, tooltip, children, ...props }: Props): Element<any> => (
  <BadgeWrapper selected={selected}>{children}</BadgeWrapper>
);

Badge.defaultProps = {
  checked: false,
  indeterminate: false,
};

export default Badge;
