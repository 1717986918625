import { buffers, eventChannel } from 'redux-saga';

function channel(
  ref,
  buffer = buffers.none(),
  snapshotListenOptions,
  onError = error => {
    console.log('Channel error', error);
  }
) {
  const channel = eventChannel(emit => {
    const unsubscribe = snapshotListenOptions
      ? ref.onSnapshot(snapshotListenOptions, emit, onError)
      : ref.onSnapshot(emit, onError);

    // Returns unsubscribe function
    return unsubscribe;
  }, buffer);

  return channel;
}

export default channel;
