import { css } from 'styled-components';

const ResultBox = ({ font, colors, scale }) => ({
  container: () => css`
    display: flex;
    color: ${colors.white};
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding: 0 6px 0 18px;
    word-break: break-all;
  `,
  label: () => css`
    display: flex;
    font-size: 16px;
    z-index: 2;
    line-height: 16px;
    padding-bottom: 4px;
    min-height: 15px;
    margin-right: 6px;
  `,
  value: () => css`
    display: flex;
    font-size: 24px;
    z-index: 2;
    line-height: 22px;
    font-weight: ${font.weightLight};
    min-height: 23px;
  `,
  bgBox: () => css`
    border-radius: 4px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
  `,
  bgBoxCircle1: () => css`
    background: linear-gradient(80deg, rgb(117 116 116 / 30%) 0%, rgb(234 234 234 / 20%) 100%);
    top: -30px;
    right: -10px;
    width: 94px;
    height: 94px;
    border-radius: 47px;
    position: absolute;
    z-index: 2;
    mix-blend-mode: luminosity;
  `,
  bgBoxCircle2: () => css`
    background: linear-gradient(100deg, rgb(187 187 187 / 0.8) 0%, rgb(70 70 70 / 0.5) 100%);
    bottom: -50px;
    right: -40px;
    width: 94px;
    height: 94px;
    border-radius: 47px;
    position: absolute;
    z-index: 1;
    mix-blend-mode: soft-light;
  `,
});

export default ResultBox;
