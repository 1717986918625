"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toError = void 0;

var _fp = require("./fp");

// wrap Error constructor in a returned function this will only allow one parameter
const toError = (0, _fp.construct)(Error);
exports.toError = toError;