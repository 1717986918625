// @flow
import React, {
  createContext,
  useState,
  memo,
  isValidElement,
  Children,
  cloneElement,
  useEffect,
} from 'react';
import { useTransition } from 'react-spring';
import Scroll from '../scroll';
import { isNil } from '@sharkfinesse/sfl-lib';
import ToggleButton from './toggle-button';
import { Aside, Nav, Drawer, NavWrapper, NavSlider, Tab, NavBg, ContentContainer } from './styled';
import * as easings from 'd3-ease';
import usePrevious from 'hooks/usePrevious';

export const SideBarAPI = createContext();

const SideBar = ({ small, collapsible, drawerWidth, children, tooltips }) => {
  const childrenLength = Children.toArray(children).length;
  const [expanded, setExpanded] = useState(collapsible ? false : true);
  const [tabPostion, setTabPostion] = useState(0);
  const [currentItem, setCurrentItem] = useState(0);
  const [reverse, setReverse] = useState(false);
  const prevLength = usePrevious(childrenLength);
  const tabHeight = tooltips ? 54 : 72;

  useEffect(() => {
    if (prevLength !== childrenLength && expanded) {
      const amount = Math.abs(prevLength - childrenLength);
      if (prevLength > childrenLength) {
        setTabPostion((currentItem - amount) * tabHeight);
        setCurrentItem(currentItem - amount);
      }
      if (prevLength < childrenLength) {
        setTabPostion((currentItem + amount) * tabHeight);
        setCurrentItem(currentItem + amount);
      }
    }
  }, [prevLength, childrenLength, tabHeight, currentItem, expanded]);

  const transition = useTransition(currentItem, {
    from: {
      opacity: 0,
      transform: `translate3d(0,${!reverse ? '40px' : '-40px'},0)`,
    },
    enter: { opacity: 1, transform: 'translate3d(0,0px,0)' },
    leave: {
      opacity: 0,
      transform: `translate3d(0,${!reverse ? '-40px' : '40px'},0)`,
    },
    config: {
      duration: 400,
      easing: easings.easeCubic,
    },
  });

  const toggle = () => {
    if (collapsible) {
      if (!expanded && isNil(currentItem)) setCurrentItem(0);
      if (expanded) {
        setExpanded(false);
      } else {
        setExpanded(true);
      }
    }
  };

  const updateCurrentItem = props => {
    const { onClick, onSetItem, standalone, tabOrder } = props;
    if (onClick) {
      onClick();
      if (small) {
        setExpanded(false);
        setCurrentItem(null);
      }
    } else {
      onSetItem && onSetItem();

      if (tabOrder === currentItem && expanded && !standalone) {
        setExpanded(false);
        setCurrentItem(null);
      } else {
        setTabPostion(tabOrder * tabHeight);
        setExpanded(true);
        if (tabOrder < currentItem) {
          setReverse(true);
        } else {
          setReverse(false);
        }
        setCurrentItem(tabOrder);
      }
    }
  };

  let order = 0;
  const content = [];
  const tabsWithOrder = Children.map(children, child => {
    if (isValidElement(child)) {
      if (isValidElement(child.props.children)) {
        content.push(child.props.children);
      } else {
        content.push(false);
      }
      const tabOrder = order;
      const element = cloneElement(child, {
        onClick: () => updateCurrentItem({ tabOrder, ...child.props }),
        order,
        tooltip: tooltips,
        tabHeight,
      });
      order++;
      return element;
    }
    return child;
  });

  return (
    <SideBarAPI.Provider
      value={{
        expanded,
        currentItem,
        setCurrentItem,
        small,
        toggle,
        setTabPostion,
        setReverse,
        setExpanded,
      }}
    >
      <Aside expanded={expanded} small={small} collapsible={collapsible} $drawerWidth={drawerWidth}>
        {collapsible && <ToggleButton small={small} expanded={expanded} onClick={toggle} />}
        <NavWrapper expanded={expanded}>
          <NavBg expanded={expanded} />
          <Scroll noScrollX>
            <NavSlider>
              <Tab position={tabPostion} opacity={expanded ? 100 : 0} tabHeight={tabHeight} />
            </NavSlider>
            <Nav expanded={expanded}>{tabsWithOrder}</Nav>
          </Scroll>
        </NavWrapper>
        <Drawer small={small} $drawerWidth={drawerWidth}>
          {transition((props, item) => (
            <ContentContainer style={props} small={small ? 1 : 0} $drawerWidth={drawerWidth}>
              {!isNil(item) && content[item]}
            </ContentContainer>
          ))}
        </Drawer>
      </Aside>
    </SideBarAPI.Provider>
  );
};

export default memo(SideBar);
