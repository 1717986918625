"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reselect = require("reselect");

var _session = _interopRequireDefault(require("./session"));

var _index = require("../../index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* eslint-disable no-unused-vars  */
const getSelectedSuiteId = state => state.layoutDesigner.selectedSuiteId;

const getShowParent = state => state.layoutDesigner.showParent;

const selectors = {
  getSelectedSuiteId,
  getShowParent
};
var _default = selectors;
exports.default = _default;