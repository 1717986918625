import { css } from 'styled-components';
import { setAlphaHsla } from '@sharkfinesse/sfl-lib';

const Slide = ({ font, colors, scale }) => ({
  slideContainer: () => css`
    height: 100%;
    padding: 2px;
    position: relative;
  `,
  slideBox: ({ checked, disabled }) => css`
    border-radius: 12px;
    box-shadow: 0 0 0 ${checked ? '2px' : '1px'} ${checked ? colors.primary : colors.uiLight};
    background: ${colors.white};
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    will-change: box-shadow;
    position: relative;
    ${!disabled &&
    css`
      cursor: pointer;
      &:hover {
        box-shadow: 0 0 0 2px ${colors.primary};
      }
    `}
  `,
  slideBody: ({ $empty }) => css`
    height: 0;
    width: 100%;
    flex: 1;
    border-radius: 12px 12px 0 0;
    background: ${$empty ? colors.uiLightest : 'none'};
  `,
  slideFooter: ({ $hideFooterBorder }) => css`
    height: 100%;
    width: 100%;
    border-top: ${$hideFooterBorder ? 'none' : `1px solid ${colors.uiLight}`};
    display: flex;
    justify-content: center;
    flex-direction: column;
    will-change: height;
    overflow: hidden;
  `,
  slideIcon: () => css`
    height: 100%;
    width: 100%;
    position: absolute;
    pointer-events: none;
    color: ${colors.primary};
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  slideIconBottomAlign: () => css`
    height: 100%;
    width: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    color: ${font.color};
  `,
  slideImage: () => css`
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    border-radius: 12px 12px 0 0;
    pointer-events: none;
  `,
  slideGraphics: () => css`
    position: relative;
    width: 100%;
    height: 100%;
    flex: 1;
    overflow: hidden;
  `,
  footerCenter: () => css`
    justify-self: center;
    font-size: 14px;
    font-weight: ${font.weightSemiBold};
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 16px 10px;
    line-height: 18px;
    text-align: center;
  `,
  footer: () => css`
    font-size: 14px;
    display: flex;
    flex-direction: row;
    padding: 10px 2px 10px 16px;
    line-height: 18px;
    justify-content: space-between;
    align-items: center;
  `,
  footerLabel: ({ empty }) => css`
    line-height: 16px;
    font-weight: ${empty ? font.weightNormal : font.weightSemiBold};
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: ${empty ? setAlphaHsla(0.7, font.color) : font.color};
  `,
  footerEmptyLabel: () => css`
    line-height: 16px;
    font-weight: ${font.weightLight};
    color: ${colors.ui};
  `,
  footerDate: () => css`
    margin-top: 2px;
    font-size: 12px;
    color: ${colors.ui};
  `,
  positioner: () => css`
      position absolute;
    z-index: 9001;
    will-change: left, top, width, height;

  `,
  hoverContent: () => css`
    display: flex;
    opacity: 0;
    flex-direction: column;
    transform-origin: top;
    width: 100%;
    will-change: transform, opacity;
    height: 100%;
  `,
  sessionInfo: () => css`
    display: flex;
    color: ${colors.ui};
    font-size: 13px;
    position: relative;
    flex-direction: column;
    padding: 6px 14px 14px 14px;
  `,
  sessionValues: () => css`
    display: flex;
    width: 60%;
    padding-right: 18px;
    flex-direction: column;
  `,
  valueRow: () => css`
    height: 100%;
    display: flex;
    width: 100%;
    margin-bottom: 12px;
    line-height: 24px;
    justify-content: space-between;
  `,
  valueLabel: () => css`
    display: block;
    width: 66%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  value: () => css`
    display: flex;
    justify-content: flex-end;
    font-size: 24px;
    color: ${colors.primary};
    font-weight: ${font.weightLight};
    white-space: nowrap;
  `,
  sessionDetails: () => css`
    display: flex;
    width: 40%;
    flex-direction: column;
  `,
  detailsRow: () => css`
    display: flex;
    margin-bottom: 7px;
    width: 100%;
    height: 18px;
  `,
  detailsLabel: () => css`
    display: inline;
  `,
  detailsValue: () => css`
    display: inline;
    color: ${font.color};
    padding-left: 4px;
    font-weight: ${font.weightSemiBold};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  sessionRow: () => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  sessionTitleWrapper: () => css`
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 4px;
  `,
  sessionTitle: () => css`
    font-weight: ${font.weightSemiBold};
    color: ${font.color};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
    display: block;
    width: 100%;
    padding: 2px 0 4px 0;
  `,
  sessionTitleInfo: () => css`
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    align-items: flex-end;
    margin-left: 4px;
    font-size: 12px;
  `,

  toolbar: () => css`
    display: flex;
    flex: 1;
    align-items: center;
    overflow: hidden;

    bottom: 0;
    position: absolute;
    right: 0;
    left: 0;
  `,
  toolbarContent: () => css`
    border-top: 1px solid ${colors.uiLightest};
    padding: 0 10px;
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    height: 40px;
  `,
  toolbarDivider: () => css`
    border-right: 1px solid ${colors.uiLightest};
    display: block;
    height: 100%;
    position: relative;
    top: 0;
    margin: 10px;
  `,
  footerContent: () => css`
    padding: 0 10px;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    display: flex;
    height: 46px;
  `,
  emptySvg: () => css`
    position: absolute;
    width: 100%;
    height: calc(100% + 50%);
    mix-blend-mode: luminosity;
  `,
  menuWrapper: () => css`
    display: flex;
  `,
  cornerMenu: () => css`
    display: flex;
    position: absolute;
    top: 6px;
    right: 0;
  `,
  labelWrapper: () => css`
    display: flex;
    flex-direction: column;
  `,
  tag: () => css`
    position: absolute;
    top: 16px;
    right: 0px;
    padding: 4px 6px 0 16px;
    height: 28px;
    background: ${colors.primary};
    border-bottom-left-radius: 14px;
    border-top-left-radius: 14px;
    color: ${colors.white};
    font-size: 12px;
    max-width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    font-weight: ${font.weightSemiBold};
    letter-spacing: 0.5px;
  `,
  loading: () => css`
    justify-content: center;
    display: flex;
    align-items: center;
    height: 100%;
    z-index: 5;
    background: ${setAlphaHsla(0.75, colors.white)};
    border-radius: 12px;
  `,
  presetHoverWrapper: () => css`
    padding: 6px 30px;
  `,
  presetHoverTitle: () => css`
    padding: 6px 10px;
  `,
  presetHoverSummary: () => css`
    font-size: 13px;
    color: ${colors.ui};
    text-align: center;
  `,
  columnList: () => css`
    column-count: 2;
    column-gap: 20px;
    list-style-type: decimal;
    font-size: 13px;
    padding-inline-start: 0;
    margin-top: 6px;
    margin-bottom: 6px;
    > li {
      line-height: 18px;
      padding: 4px 0;
    }
  `,
  pending: () => css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    display: flex;
  `,
  pendingBox: () => css`
    background: ${setAlphaHsla(0.9, colors.white)};
    padding: 10px 14px;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    justify-content: center;
    z-index: 2;
  `,

  pendingTitle: () => css`
    margin: 0 0 2px 0;
    font-size: 14px;
  `,
  pendingName: () => css`
    font-size: 13px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 0 0 4px 0;
    line-height: 16px;
  `,
  sharedBy: () => css`
    font-size: 12px;
    overflow: hidden;
    margin: 0 0 6px 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    justify-content: center;
  `,
  sharedByName: () => css`
    margin-left: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  pendingButtons: () => css`
    display: flex;
    flex-direction: row;
  `,
  footerNotification: () => css`
    margin: 2px 0 0 0;
    padding: 0px;
    display: flex;
    li {
      display: flex;
      border-right: 1px solid ${colors.uiLightest};
      list-style: none;
      width: 26px;
      display: flex;
      justify-content: center;
      height: 16px;
    }
    li:first-child {
      width: 19px;
      justify-content: start;
    }
    li:last-child {
      border-right: none;
    }
  `,
});

export default Slide;
