import { takeLatest, select, put } from 'redux-saga/effects';
import { types as layoutTypes } from 'redux/modules/layout';
import { isEmpty, sessionSelectors } from '@sharkfinesse/sfl-lib';
import { actionCreators as businessPreparationActionCreators } from 'redux/modules/business-preparation';

function* clearSelected(action) {
  const selected = yield select(sessionSelectors.getSelectedBenefits);
  if (!isEmpty(selected)) yield put(businessPreparationActionCreators.updateSelectedBenefits([]));
}

export const effects = [
  takeLatest(
    [
      layoutTypes.SUITE.INSERT,
      layoutTypes.UPDATE,
      layoutTypes.SUITE.UPDATE_ORDER,
      layoutTypes.BENEFIT.INSERT,
      layoutTypes.BENEFIT.DELETE,
    ],
    clearSelected
  ),
];

export default effects;
