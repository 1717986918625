import { css } from 'styled-components';

const RemoveFromOutput = ({ font, colors, scale }) => ({
  removedWrapper: () => css`
    position: relative;
    width: 100%;
  `,
  removed: () => css`
    display: flex;
    flex-direction: column;
    margin: auto;
    position: absolute;
    z-index: 2;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    p {
      color: ${font.color};
      font-size: ${font.sizeNormal};
      font-weight: ${font.weightNormal};
      text-align: center;
    }
  `,

  blurred: () => css`
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
  `,

  centerWrapper: () => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  `,
  undoBox: () => css`
    background: hsla(0, 0%, 50%, 0.1);
    padding: 20px;
    border: 1px solid #e0e0e0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 6px;
  `,
});

export default RemoveFromOutput;
