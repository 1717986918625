// @flow
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Menu, { MenuList } from '../menu';
import MenuItem from '../menu-item';
import Button from '../button';
import { IconDotsVertical } from '../icon/icons';
import { useMedia } from 'react-media';

const MainComponent = ({ makeDraggable, draggable, position, pin }) => {
  const [open, setOpen] = useState(false);

  const updateOpen = (value, e) => {
    e.stopPropagation();
    setOpen(value);
  };

  const set = open => setOpen(open);

  const { small } = useMedia({
    queries: {
      small: '(min-width: 0) and (max-width: 767px)',
    },
  });

  return (
    <Menu
      open={open}
      onVisibilityChange={set}
      referenceComponent={
        <Button size="icon" variant="flat" color="light">
          <IconDotsVertical
            css={`
              transform: rotate(90deg);
            `}
          />
        </Button>
      }
      usePortal={true}
      triggerOnClick={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <MenuList>
        {!small && (
          <MenuItem
            onClick={e => {
              updateOpen(false, e);
              makeDraggable();
            }}
          >
            <FormattedMessage id="drawer.menu.move" defaultMessage="Move" />
          </MenuItem>
        )}
        {(draggable || position === 'right') && (
          <MenuItem
            onClick={e => {
              updateOpen(false, e);
              pin({ direction: 'left' });
            }}
          >
            <FormattedMessage id="drawer.menu.pin.left" defaultMessage="Pin to left" />
          </MenuItem>
        )}
        {(draggable || position === 'left') && (
          <MenuItem
            onClick={e => {
              updateOpen(false, e);
              makeDraggable();
              pin({ direction: 'right' });
            }}
          >
            <FormattedMessage id="drawer.menu.pin.right" defaultMessage="Pin to right" />
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};

export default MainComponent;
