/* @flow */
import {
  append,
  assoc,
  createReducer,
  defaultReviewPeriod,
  normalizeTime,
  omit,
  simpleAction,
  simpleActionMeta,
  simpleReducer,
  without,
  isNil,
  isEmpty,
  map,
  sessionRecurringCostsInitialState,
} from '@sharkfinesse/sfl-lib';

const namespace = (name: string): string => `@@session.recurringCosts/${name}`;

//Actions
export const types = {
  DESCRIPTION: {
    CHANGE: namespace('DESCRIPTION.CHANGE'),
  },
  GROWTH: {
    CHANGE: namespace('GROWTH.CHANGE'),
  },
  RECURRENCE: {
    CHANGE: namespace('RECURRENCE.CHANGE'),
  },
  START: {
    CHANGE: namespace('START.CHANGE'),
  },
  END: {
    CHANGE: namespace('END.CHANGE'),
    DEAL_PERIOD_CHANGE: namespace('END.DEAL_PERIOD_CHANGE'),
  },
  COST: {
    CHANGE: namespace('COST.CHANGE'),
    CHANGE_START: namespace('COST.CHANGE_START'),
  },
  TYPE: {
    CHANGE: namespace('TYPE.CHANGE'),
  },
  ROW: {
    DYNAMIC_ADD: namespace('ROW.DYNAMIC_ADD'),
    DELETE: namespace('ROW.DELETE'),
    DO_DELETE: namespace('ROW.DO_DELETE'),
    DELETE_UNDO: namespace('ROW.DELETE_UNDO'),
    REORDER: namespace('ROW.REORDER'),
    REVERT: namespace('ROW.REVERT'),
  },
  CALCULATE: namespace('CALCULATE'),
  RESET: namespace('RESET'),
  DO_RESET: namespace('DO_RESET'),
  RESET_UNDO: namespace('RESET_UNDO'),
  NOTES: {
    CHANGE: namespace('NOTES.CHANGE'),
  },
  CASHFLOW: {
    SHOW: namespace('CASHFLOW.SHOW'),
    CHANGE: namespace('CASHFLOW.CHANGE'),
  },
  REVERT: namespace('REVERT'),
};

//Reducer

const newCostRow = (generatedId, period) => ({
  id: generatedId,
  description: '',
  start: normalizeTime('months', 1),
  end: normalizeTime('months', period),
  growth: 0,
  cost: null,
  pristine: true,
  recurrence: 'monthly',
  type: '-',
  showCashflow: false,
});

//Reducer
export const initialState = sessionRecurringCostsInitialState;

const reduceDescriptionChange = (state, action) => {
  const cost = state.costs[action.payload.id];

  return {
    ...state,
    costs: {
      ...state.costs,
      [action.payload.id]: {
        ...cost,
        description: action.payload.value,
        pristine: false,
      },
    },
  };
};

const reduceGrowthChange = (state, action) => {
  const cost = state.costs[action.payload.id];
  return {
    ...state,
    costs: {
      ...state.costs,
      [action.payload.id]: {
        ...cost,
        growth: action.payload.value,
        pristine: false,
        cashflowEdited: false,
      },
    },
  };
};

const reduceRecurrenceChange = (state, action) => {
  const cost = state.costs[action.payload.id];
  return {
    ...state,
    costs: {
      ...state.costs,
      [action.payload.id]: {
        ...cost,
        recurrence: action.payload.value,
        pristine: false,
        cashflowEdited: false,
      },
    },
  };
};

const reduceStartChange = (state, action) => {
  const cost = state.costs[action.payload.id];
  return {
    ...state,
    costs: {
      ...state.costs,
      [action.payload.id]: {
        ...cost,
        start: action.payload.value === '' ? '' : normalizeTime('months', action.payload.value),
        pristine: false,
        cashflowEdited: false,
      },
    },
  };
};

const reduceEndChange = (state, action) => {
  const cost = state.costs[action.payload.id];
  return {
    ...state,
    costs: {
      ...state.costs,
      [action.payload.id]: {
        ...cost,
        end: action.payload.value === '' ? '' : normalizeTime('months', action.payload.value),
        pristine: false,
        cashflowEdited: false,
      },
    },
  };
};

const reduceCostChange = (state, action) => {
  return action.payload;
};

const reduceTypeChange = (state, action) => {
  const cost = state.costs[action.payload.id];
  return {
    ...state,
    costs: {
      ...state.costs,
      [action.payload.id]: {
        ...cost,
        type: action.payload.value,
        pristine: false,
      },
    },
  };
};

const reduceCalculate = (state, action) => {
  let omitResult = true;

  if (!isNil(action.payload.costs)) {
    map(cost => {
      if (!isEmpty(cost.cost) && !isNil(cost.cost)) omitResult = false;
    }, action.payload.costs);
  }

  if (omitResult) {
    return { ...state, result: null };
  }

  if (isNil(action.payload.result)) {
    return state;
  }
  return {
    ...state,
    result: action.payload.result,
  };
};

const reduceDynamicAddRow = (state, { payload: { id, reviewPeriod } }) => {
  const costRow = newCostRow(id, reviewPeriod);
  return {
    ...state,
    ids: append(id, state.ids),
    costs: assoc(id, costRow, state.costs),
  };
};

const reduceReorder = simpleReducer('ids');

const reduceDelete = (state, action) => {
  return {
    ...state,
    ids: without([action.payload.id], state.ids),
    costs: omit([action.payload.id], state.costs),
    result: null,
  };
};

const reduceReset = () => ({ ...initialState, notes: '' });

const reduceNotes = (state, action) => {
  return {
    ...state,
    notes: action.payload,
  };
};

const reduceRevert = (state, action) => action.payload;

const reduceRowRevert = (state, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

const reduceShowCashflow = (state, action) => {
  const cost = state.costs[action.payload.id];
  return {
    ...state,
    costs: {
      ...state.costs,
      [action.payload.id]: {
        ...cost,
        showCashflow: !cost.showCashflow,
      },
    },
  };
};

const reduceCashflow = (state, action) => {
  const cost = state.costs[action.payload.id];
  return {
    ...state,
    costs: {
      ...state.costs,
      [action.payload.id]: {
        ...cost,
        cashflow: action.payload.value,
        cashflowEdited: true,
      },
    },
  };
};

export default createReducer(
  {
    [types.DESCRIPTION.CHANGE]: reduceDescriptionChange,
    [types.GROWTH.CHANGE]: reduceGrowthChange,
    [types.RECURRENCE.CHANGE]: reduceRecurrenceChange,
    [types.START.CHANGE]: reduceStartChange,
    [types.END.CHANGE]: reduceEndChange,
    [types.END.DEAL_PERIOD_CHANGE]: reduceEndChange,
    [types.COST.CHANGE]: reduceCostChange,
    [types.TYPE.CHANGE]: reduceTypeChange,
    [types.CALCULATE]: reduceCalculate,
    [types.ROW.DYNAMIC_ADD]: reduceDynamicAddRow,
    [types.ROW.REORDER]: reduceReorder,
    [types.ROW.DELETE]: reduceDelete,
    [types.RESET]: reduceReset,
    [types.NOTES.CHANGE]: reduceNotes,
    [types.CASHFLOW.SHOW]: reduceShowCashflow,
    [types.CASHFLOW.CHANGE]: reduceCashflow,
    [types.REVERT]: reduceRevert,
    [types.ROW.REVERT]: reduceRowRevert,
  },
  initialState
);

//Action Creators
const updateDescription = simpleAction(types.DESCRIPTION.CHANGE);
const updateGrowth = simpleAction(types.GROWTH.CHANGE);
const updateRecurrence = simpleAction(types.RECURRENCE.CHANGE);
const updateStart = simpleAction(types.START.CHANGE);
const updateEnd = simpleAction(types.END.CHANGE);
const updateEndToDealPeriod = simpleActionMeta(types.END.DEAL_PERIOD_CHANGE);
const updateCost = simpleActionMeta(types.COST.CHANGE);
const updateCostStart = simpleAction(types.COST.CHANGE_START);
const updateType = simpleAction(types.TYPE.CHANGE);
const updateReorderRow = simpleAction(types.ROW.REORDER);
const updateAddDynamicRow = simpleActionMeta(types.ROW.DYNAMIC_ADD);
const updateRemoveRow = simpleActionMeta(types.ROW.DELETE);
const updateResult = simpleActionMeta(types.CALCULATE);
const updateReset = simpleActionMeta(types.RESET);
const doReset = simpleAction(types.DO_RESET);
const undoReset = simpleAction(types.RESET_UNDO);
const updateNotes = simpleAction(types.NOTES.CHANGE);
const updateShowCashflow = simpleAction(types.CASHFLOW.SHOW);
const updateCashflow = simpleAction(types.CASHFLOW.CHANGE);
const updateRevert = simpleAction(types.REVERT);
const doDeleteRow = simpleAction(types.ROW.DO_DELETE);
const undoDeleteRow = simpleAction(types.ROW.DELETE_UNDO);
const updateRowRevert = simpleAction(types.ROW.REVERT);

export const actionCreators = {
  updateDescription,
  updateGrowth,
  updateRecurrence,
  updateStart,
  updateEnd,
  updateEndToDealPeriod,
  updateCost,
  updateCostStart,
  updateType,
  updateReorderRow,
  updateAddDynamicRow,
  updateRemoveRow,
  updateResult,
  updateReset,
  updateNotes,
  updateShowCashflow,
  updateCashflow,
  doReset,
  undoReset,
  updateRevert,
  doDeleteRow,
  undoDeleteRow,
  updateRowRevert,
};
