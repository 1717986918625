import ButtonDefault from './button-default';
import { borderColor, backgrounds } from 'polished';
import { lightenHsla, darkenHsla, setAlphaHsla } from '@sharkfinesse/sfl-lib';
import { css } from 'styled-components';

const alpha = 0.7;
const bravo = 10;
const charlie = 20;
const ButtonPrimary = config => ({
  ...ButtonDefault(config),
  background: backgrounds(config.colors.primary),
  fontColor: config.colors.white,
  borderColor: borderColor(darkenHsla(charlie, config.colors.primary)),
  textShadow: setAlphaHsla(alpha, lightenHsla(charlie, config.colors.primary)),
  boxShadow: 'unset',
  hoverBackground: backgrounds(lightenHsla(bravo, config.colors.primary)),
  hoverFontColor: config.colors.white,
  activeCSS: () => css`
    background: ${darkenHsla(5, config.colors.primary)};
  `,
});

export default ButtonPrimary;
