import { css } from 'styled-components';

const Popper = ({ font, colors, scale }) => ({
  referenceContainer: ({ $refernceAlignment }) => css`
    display: flex;
    height: fit-content;
    ${$refernceAlignment === 'left' &&
    css`
      width: fit-content;
    `}
    justify-content: ${$refernceAlignment};
  `,
  box: ({ tipStyle, zIndex = 9051 }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${tipStyle === 'dark' ? colors.uiDark : colors.popperBox};
    border: ${tipStyle === 'dark' ? colors.uiDark : colors.uiLight} 1px solid;
    border-radius: 3px;
    color: ${tipStyle === 'dark' ? colors.white : font.color};
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.26) 0px 1px 0px;
    transition: opacity 0.3s;
    z-index: ${zIndex};
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    &[data-popper-placement*='bottom'] .tooltip-arrow {
      top: 0;
      left: 0;
      margin-top: -10px;
      &::before {
        top: -11px;
        left: -1px;
        border: 11px solid;
        border-color: transparent transparent
          ${tipStyle === 'dark' ? colors.uiDark : colors.uiLight} transparent;
      }
      &::after {
        bottom: 9px;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent
          ${tipStyle === 'dark' ? colors.uiDark : colors.popperBox} transparent;
      }
    }
    &[data-popper-placement*='top'] .tooltip-arrow {
      bottom: 0;
      left: 0;
      margin-bottom: -10px;
      &::before {
        bottom: -11px;
        left: -1px;
        border: 10px solid;
        border-color: ${tipStyle === 'dark' ? colors.uiDark : colors.uiLight} transparent
          transparent transparent;
      }
      &::after {
        top: 9px;
        border-width: 10px 10px 0 9px;
        border-color: ${tipStyle === 'dark' ? colors.uiDark : colors.popperBox} transparent
          transparent transparent;
      }
    }
    &[data-popper-placement*='right'] .tooltip-arrow {
      left: 0;
      margin-left: -10px;
      &::before {
        left: -11px;
        border: 11px solid;
        border-color: transparent ${tipStyle === 'dark' ? colors.uiDark : colors.uiLight}
          transparent transparent;
        top: -1px;
      }
      &::after {
        right: 9px;
        border-width: 10px 10px 10px 0;
        border-color: transparent ${tipStyle === 'dark' ? colors.uiDark : colors.popperBox}
          transparent transparent;
      }
    }
    &[data-popper-placement*='left'] .tooltip-arrow {
      right: 0;
      margin-right: -11px;
      &::before {
        right: -11px;
        border: 11px solid;
        border-color: transparent transparent transparent
          ${tipStyle === 'dark' ? colors.uiDark : colors.uiLight};
        top: -1px;
      }
      &::after {
        left: 8px;
        border-width: 10px 0 10px 10px;
        border-color: transparent transparent transparent
          ${tipStyle === 'dark' ? colors.uiDark : colors.popperBox};
      }
    }
  `,
  arrow: () => css`
    position: absolute;
    width: 20px;
    height: 20px;
    &::before {
      content: '';
      display: block;
      height: 0;
      position: absolute;
      width: 0;
      border-style: solid;
      margin: auto;
    }
    &::after {
      content: '';
      margin: auto;
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
    }
  `,
});

export default Popper;
