import { useCallback } from 'react';
import { MessageModal } from '@sharkfinesse/sfl-ui';
import { useModal } from '@ebay/nice-modal-react';
import { useSendToSupport } from 'redux/firestore/hooks';
import { errorModal } from 'utils';
import { toast } from 'react-toastify';
import { notifyConfig } from 'components/toast/config';
import { FormattedMessage } from 'react-intl';

const useSupport = ({ sessionId }) => {
  const messageModal = useModal(MessageModal);

  const { mutate } = useSendToSupport({
    onSuccess: ({ payload }) => {
      messageModal.hide();
      toast(
        () => (
          <FormattedMessage
            id="support.send.session.toast"
            defaultMessage="Session sent to support"
          />
        ),
        notifyConfig
      );
    },
    onError: error => {
      errorModal({ error: error.message });
      messageModal.hide();
    },
  });

  const sendToSupport = useCallback(() => {
    messageModal
      .show({
        title: (
          <FormattedMessage
            id="support.send.session.modal.label"
            defaultMessage="Send to support"
          />
        ),
      })
      .then(message => {
        mutate({
          sessionId,
          message,
        });
      });
  }, [messageModal, mutate, sessionId]);

  return { sendToSupport };
};

export default useSupport;
