/* @flow */
import { createReducer, simpleAction } from '@sharkfinesse/sfl-lib';
const namespace = (name: string): string => `@@intl/${name}`;

//Actions
export const types = {
  MERGED: namespace('MERGED'),
  UPDATE: namespace('UPDATE'),
};

const reduceUpdate = (state, action) => ({ ...state, ...action.payload });

export default createReducer(
  {
    [types.UPDATE]: reduceUpdate,
  },
  {
    locale: 'en',
    messages: {},
  }
);

const updateMerged = simpleAction(types.MERGED);
const updateIntl = simpleAction(types.UPDATE);

export const actionCreators = {
  updateMerged,
  updateIntl,
};
