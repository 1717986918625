// @flow
import React, { Component } from 'react';
import Button from '../button';
import IconSort from '../icon/icons/icon-sort';
import IconArrowDownCircleOutline from '../icon/icons/icon-arrow-down-circle-outline';
import IconArrowUpCircleOutline from '../icon/icons/icon-arrow-up-circle-outline';
import Menu, { MenuList } from '../menu';
import MenuItem from '../menu-item';

type DefaultProps = {
  color?: string,
  disabled?: boolean,
  popped?: boolean,
  tooltip?: string,
};

type Props = DefaultProps & {
  updateSorting?: Function,
  sorting?: string,
  color?: string,
  fields?: array,
  disabled?: boolean,
  popped?: boolean,
  tooltip?: string,
};

type State = {
  open: Boolean,
};

class SortMenu extends Component<Props, State> {
  state = {
    open: false,
  };

  updateOpen = value => {
    this.setState({ open: value });
  };

  set = open => this.setState({ open });

  sort = field => {
    const { updateSorting, sorting } = this.props;
    this.updateOpen(false);
    updateSorting({
      field: field,
      direction: sorting.direction === 'asc' ? 'desc' : 'asc',
    });
  };

  getIcon = field => {
    const { sorting } = this.props;
    return (
      sorting.field === field &&
      (sorting.direction === 'asc' ? <IconArrowDownCircleOutline /> : <IconArrowUpCircleOutline />)
    );
  };

  render() {
    const { open } = this.state;
    const { fields, color, disabled, tooltip, popped } = this.props;
    return disabled ? (
      <Button size="icon" variant="flat" color={color} disabled={disabled}>
        <IconSort />
      </Button>
    ) : (
      <Menu
        open={open}
        onVisibilityChange={this.set}
        usePortal={!popped}
        referenceComponent={
          <Button size="icon" variant="flat" color={color} tooltip={tooltip}>
            <IconSort />
          </Button>
        }
      >
        <MenuList>
          {fields.map((field, i) => (
            <MenuItem
              key={i}
              onClick={() => {
                this.sort(field.name);
              }}
              iconAfter={this.getIcon(field.name)}
            >
              {field.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    );
  }
}
SortMenu.defaultProps = {
  color: 'light',
  disabled: false,
  tooltip: '',
  popped: false,
};

export default SortMenu;
