"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.payback = exports.normalizePayback = exports.calculatePaybackWeek = exports.calculatePaybackIndex = void 0;

var _fp = require("./fp");

var _cashflow = require("./cashflow");

var _normalization = require("./normalization");

var _math = require("./math");

// prettier-ignore
const calculatePaybackIndex = (0, _fp.pipe)(_cashflow.accumulateCashflow, (0, _fp.ifElse)((0, _fp.pipe)(_fp.last, _math.isNegativeOrZero), (0, _fp.always)(_fp.Maybe.Nothing), (0, _fp.pipe)((0, _fp.findLastIndex)(_math.isNegativeOrZero), _fp.inc, _fp.Maybe.Just)));
exports.calculatePaybackIndex = calculatePaybackIndex;
const weeksInMonth = 4; // prettier-ignore

const calculatePaybackWeek = (0, _fp.converge)((0, _fp.pipe)((0, _fp.update)(0), calculatePaybackIndex, (0, _fp.map)((0, _fp.divide)(_fp.__, weeksInMonth))), [(0, _fp.pipe)((0, _fp.slice)(0), _fp.sum), (0, _fp.pipe)(_fp.nth, (0, _fp.divide)(_fp.__, weeksInMonth), (0, _cashflow.generateCashflow)(weeksInMonth))]); // prettier-ignore

exports.calculatePaybackWeek = calculatePaybackWeek;
const normalizePayback = (0, _fp.pipe)(_fp.Maybe.fromMaybe(0), (0, _normalization.normalizeTime)('months'), (0, _math.increaseByFloatingFixedPointFactor)(1), _fp.Maybe.Just);
exports.normalizePayback = normalizePayback;
const adjustToNearestWeek = (0, _fp.curry)((cashflow, value) => (0, _fp.converge)((0, _fp.pipe)((0, _fp.lift)(_fp.add), normalizePayback), [(0, _fp.map)(_fp.dec), (0, _fp.pipe)(_fp.Maybe.fromMaybe(0), calculatePaybackWeek(_fp.__, cashflow))])(value));

const eqZero = a => _fp.Maybe.isJust(a) && _fp.Maybe.fromMaybe(-1)(a) === 0; // prettier-ignore


const payback = cashflow => (0, _fp.pipe)(calculatePaybackIndex, (0, _fp.cond)([[eqZero, (0, _fp.always)(_fp.Maybe.Just(0))], [_fp.Maybe.isJust, adjustToNearestWeek(cashflow)], [_fp.T, (0, _fp.always)(_fp.Maybe.Nothing)]]))(cashflow);

exports.payback = payback;