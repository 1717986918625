/* @flow */
/* eslint-disable max-len */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M8,8c0-2.2,1.8-4,4-4s4,1.8,4,4s-1.8,4-4,4S8,10.2,8,8 M14,20H4v-2c0-2.2,3.6-4,8-4c1.3,0,2.5,0.1,3.5,0.4
	c-0.2,0.4-0.4,0.8-0.5,1.3C14.4,16.3,14,17.1,14,18V20z"
    />
    <path
      fill="inherit"
      d="M22,17v-0.5c0-1.4-1.1-2.5-2.5-2.5S17,15.1,17,16.5V17c-0.5,0-1,0.5-1,1v4c0,0.5,0.5,1,1,1h5c0.5,0,1-0.5,1-1
	v-4C23,17.5,22.5,17,22,17z M21,17h-3v-0.5c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5V17z"
    />
  </Icon>
);

export default memo(IconComponent);
