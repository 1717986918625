import { css } from 'styled-components';
import { setAlphaHsla } from '@sharkfinesse/sfl-lib';

const Gallery = ({ font, colors, scale }) => ({
  imageWrapper: ({ selected }) => css`
    position: relative;
    overflow: hidden;
    > .media-menu {
      transition: transform 0.2s ease, opacity 0.2s ease;

      opacity: 0;
      z-index: 2;
      transform: translateX(40%);
    }
    img {
      transition: transform 0.5s ease;
    }
    ${selected
      ? css`
          &::after {
            width: 100%;
            height: 100%;
            border: 2px solid ${colors.primary};
            content: '';
            position: absolute;
            left: 0;
            box-shadow: 0 8px 16px -6px rgb(0 0 0 / 0.8);
          }
          &:hover {
            > .media-menu {
              opacity: 1;
              transform: translateX(0%);
            }
          }
        `
      : css`
          cursor: pointer;

          &:hover {
            img {
              transition: transform 0.5s ease;
              transform: scale(1.05);
            }
            > .media-menu {
              opacity: 1;
              transform: translateX(0%);
            }
          }
          &:hover::after {
            width: 100%;
            height: 100%;
            border: 2px solid ${setAlphaHsla(0.7, colors.primary)};
            content: '';
            position: absolute;
            left: 0;
          }
        `}
  `,
  image: () => css`
    background-color: white;
    background-image: linear-gradient(45deg, #e6e6e6 25%, transparent 0),
      linear-gradient(-45deg, #e6e6e6 25%, transparent 0),
      linear-gradient(45deg, transparent 75%, #e6e6e6 0),
      linear-gradient(-45deg, transparent 75%, #e6e6e6 0);
    background-size: 16px 16px;
    background-position: 0 0, 0 8px, 8px -8px, -8px 0;
    background-repeat: repeat;
  `,
  attribution: () => css`
    display: flex;
    justify-content: flex-end;
  `,
  menuWrapper: () => css`
    position: absolute;
    right: 0;
    top: 4px;
  `,
  menuIcon: () => css`
    color: white;
    &:hover {
      color: ${colors.primary};
    }
  `,
});

export default Gallery;
