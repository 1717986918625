/* @flow */
/* eslint-disable max-len */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = ({ imageUrl, ...props }): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M22,8v10c0,1.1-0.9,2-2,2H4c-1.1,0-2-0.9-2-2V6c0-1.1,0.9-2,2-2h6l2,2h8C21.1,6,22,6.9,22,8 M20,8H4v10h16V8z"
    />
    <polyline
      fill="inherit"
      points="10.8,12 13.8,11.8 15,9 16.2,11.8 19.2,12 16.9,14 17.6,17 15,15.5 12.4,17 13.1,14 10.8,12 "
    />

    {imageUrl && (
      <g>
        <rect x="4" y="8" width="16" fill="#ffffff" height="10" />
        <clipPath id="smallFolderImage">
          <rect x="4" y="8" width="16" height="10" />
        </clipPath>
      </g>
    )}

    {imageUrl && (
      <image clipPath="url(#smallFolderImage)" height="100%" width="100%" xlinkHref={imageUrl} />
    )}
  </Icon>
);

export default memo(IconComponent);
