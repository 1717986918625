/* @flow */
import { combineReducers } from 'redux';
import {
  simpleAction,
  mergeDeepRight,
  isNil,
  omit,
  preferencesInitialState,
} from '@sharkfinesse/sfl-lib';
import theme from './preferences/theme';
import language from './preferences/language';
import session from './preferences/session';
import reportTheme from './preferences/reportTheme';
import docxTemplate from './preferences/docxTemplate';
import pptxTemplate from './preferences/pptxTemplate';
import businessPreparation from './preferences/businessPreparation';
import debugging from './preferences/debugging';
import analytics from './preferences/analytics';
import reportSettings from './preferences/reportSettings';
import autoImage from './preferences/auto-image';
import defaultSessionImage from './preferences/default-session-image';
import rsfApp from 'redux/rsf';

const namespace = (name: string): string => `@@preferences/${name}`;

// types
export const types = {
  LOAD: namespace('LOAD'),
  LOADED: namespace('LOADED'),
  SYNC: namespace('SYNC'),
  SYNC_QUESTIONNAIRE: namespace('SYNC_QUESTIONNAIRE'),
  RESET: namespace('RESET'),
  ON_CLOSE: namespace('ON_CLOSE'),
};

export const id = (state = '', action) => {
  return state;
};

const preferences = combineReducers({
  id,
  theme,
  language,
  session,
  reportTheme,
  docxTemplate,
  pptxTemplate,
  businessPreparation,
  debugging,
  analytics,
  reportSettings,
  autoImage,
  defaultSessionImage,
});

export const initialState = preferencesInitialState;

const PreferencesReducer =
  () =>
  (state = initialState, action) => {
    switch (action.type) {
      case types.SYNC:
      case types.SYNC_QUESTIONNAIRE:
        //loaded state from firestore

        if (!action.payload) {
          return state;
        } else {
          const clientId = rsfApp.firebaseApp.options.clientId;
          const remoteClientId = !isNil(action.payload.clientId) ? action.payload.clientId : null;

          if (remoteClientId !== clientId) {
            const newPrefs = preferences(
              mergeDeepRight(initialState, omit(['clientId'], action.payload)),
              action
            );

            return newPrefs;
          } else {
            return state;
          }
        }
      case types.RESET:
        return preferences({}, action);
      default:
        return preferences(state, action);
    }
  };

//Action Creators
const load = simpleAction(types.LOAD);
const sync = simpleAction(types.SYNC);
const syncQuestionnaire = simpleAction(types.SYNC_QUESTIONNAIRE);
const reset = () => ({ type: types.RESET });
const loaded = simpleAction(types.LOADED);
const onClose = simpleAction(types.ON_CLOSE);

export const actionCreators = {
  load,
  sync,
  syncQuestionnaire,
  loaded,
  reset,
  onClose,
};

export default PreferencesReducer();
