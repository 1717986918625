/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

let IconImpactLife = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      d="M4,9V20h7V13h7V9Zm11,1h2v2H15ZM8,10h2v2H8ZM5,10H7v2H5Zm0,3H7v2H5Zm2,6H5V16H7Zm3,0H8V16h2Zm0-4H8V13h2Zm4-3H11V10h3Z"
      fill="currentColor"
    />
    <path
      d="M20,4v8H19V8H3V21H13v1H3a.9.9,0,0,1-1-1V4.1A1.1,1.1,0,0,1,3.1,3H4A2.4,2.4,0,0,1,5,2H6A2.4,2.4,0,0,1,7,3h8a2.4,2.4,0,0,1,1-1h1a2.4,2.4,0,0,1,1,1h1A.9.9,0,0,1,20,4ZM19,7V4H18V5a.9.9,0,0,1-1,1H16a.9.9,0,0,1-1-1V4H7V5A1,1,0,0,1,6,6H5A.9.9,0,0,1,4,5V4H3V7ZM17,5V3H16V5ZM6,5V3H5V5Z"
      fill="currentColor"
    />
    <path
      d="M19,4V7H3V4H4V5A.9.9,0,0,0,5,6H6A1,1,0,0,0,7,5V4h8V5a.9.9,0,0,0,1,1h1a.9.9,0,0,0,1-1V4Z"
      fill="inherit"
    />
    <rect x="5" y="10" width="2" height="2" fill="inherit" />
    <rect x="8" y="10" width="2" height="2" fill="inherit" />
    <polygon points="18 18 18 16 17 16 17 19 18 19 19 19 19 18 18 18" fill="currentColor" />
    <path
      d="M18,15a3,3,0,1,1-3,3,2.9,2.9,0,0,1,3-3m0-1a4,4,0,1,0,4,4,4,4,0,0,0-4-4Z"
      fill="currentColor"
    />
  </Icon>
);

export default memo(IconImpactLife);
