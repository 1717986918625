/* @flow */
import { createGlobalStyle } from 'styled-components';
import sanitizeCss from './css/sanitizeCss';
import TreeStyles from '../components/tree/styles';

const GlobalStyles = createGlobalStyle`

  html {
    font-size: ${(p) => (p.baseUnitSize ? p.baseUnitSize : 4)}px;
    box-sizing: content-box;
    input:focus,
    select:focus,
    textarea:focus,
    button:focus {
        outline: none;
    };
    -webkit-tap-highlight-color: transparent;
    .carousel__slide-focus-ring{
      outline: none !important;
    }
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }
  body {
    margin: ${(p) => (p.bodyMargin ? p.bodyMargin : '0px')};
    font-size: 3.5rem;
    line-height: 1.5;
    overflow: hidden;
    font-family: -apple-system, BlinkMacSystemFont,
      "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans",
      "Droid Sans", "Helvetica Neue", sans-serif;
      -webkit-tap-highlight-color: transparent;
      min-height: 100vh;
      min-height: -webkit-fill-available;
  }
  ${sanitizeCss}
  ${TreeStyles}
`;

export default GlobalStyles;
