/* @flow */
import { connect } from 'react-redux';
import { userSelectors, deviceSelectors } from '@sharkfinesse/sfl-lib';
import UserModal from 'components/user-modal';

const mapStateToProps = (state, props) => ({
  isOnline: deviceSelectors.isOnline(state, props),
  email: userSelectors.getEmail(state, props),
  name: userSelectors.getName(state, props),
});

export default connect(mapStateToProps)(UserModal);
