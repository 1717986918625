"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const initialId = 'initial';
const initialState = {
  costs: {
    [initialId]: {
      id: initialId,
      description: '',
      month: 0,
      pristine: true,
      cost: null,
      type: '-',
      showCashflow: false
    }
  },
  ids: [initialId],
  result: null,
  notes: ''
};
var _default = initialState;
exports.default = _default;