// @flow
/* eslint-disable no-magic-numbers, react/prop-types */
import React from 'react';
import {
  Container,
  Title,
  Body,
  Header,
  ViewMoreContainer,
  Toolbar,
  HeaderWrapper,
  OpenCloseLink,
} from './styled';
import Hyperlink from '../hyperlink';
import { IconChevronDown, IconChevronUp } from '../icon';
import 'styled-components/macro';
import { useMedia } from 'react-media';

const TitlePane = ({
  isOpen,
  title,
  openLabel,
  closeLabel,
  viewMoreLabel,
  viewMoreTo,
  toolbar,
  children,
  visibility,
  openCloseOnClick,
  ...props
}) => {
  const matches = useMedia({
    queries: {
      small: '(min-width: 0) and (max-width: 767px)',
    },
  });
  return (
    <Container visibility={visibility} {...props}>
      <HeaderWrapper>
        <Header>
          <Title>{title}</Title>
          {viewMoreLabel && viewMoreTo && (
            <ViewMoreContainer>
              <Hyperlink to={viewMoreTo} rel="noopener noreferrer">
                {viewMoreLabel}
              </Hyperlink>
            </ViewMoreContainer>
          )}
          {toolbar && <Toolbar>{toolbar}</Toolbar>}
        </Header>
        {openLabel && openCloseOnClick && closeLabel && (
          <OpenCloseLink onClick={openCloseOnClick} rel="noopener noreferrer">
            {isOpen ? (
              <IconChevronUp
                size="height: 20px; width: 20px;"
                css={`
                  margin-right: 2px;
                `}
              />
            ) : (
              <IconChevronDown
                css={`
                  margin-right: 2px;
                `}
                size="height: 20px; width: 20px;"
              />
            )}
            {!matches.small && (isOpen ? closeLabel : openLabel)}
          </OpenCloseLink>
        )}
      </HeaderWrapper>
      {isOpen && <Body>{children}</Body>}
    </Container>
  );
};

export default TitlePane;
