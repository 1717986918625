import { call, all, put, select } from 'redux-saga/effects';
import { actionCreators as evaluatorActionCreators } from 'redux/modules/evaluator';
import { actionCreators as cashflowActionCreators } from 'redux/modules/cashflow';
import {
  doEvaluatorCalculate,
  assoc,
  sum,
  isNotNil,
  filter,
  dropLast,
  unformatFieldSetData,
  cashflowsSelectors,
  evaluatorSelectors,
  sessionDetailsSelectors,
  accountSelectors,
  layoutSelectors,
} from '@sharkfinesse/sfl-lib';
import { unlinkDefaultSessionValues } from './default-session-values';

function* calculate({
  id,
  startMonth,
  endMonth,
  startMonthLinked,
  endMonthLinked,
  formData,
  schema,
  meta,
  growth,
}) {
  const eNo = yield select(layoutSelectors.getBenefitENo, { benefitId: id });
  const [
    adoptionCashflowMods = {},
    riskCashflowMods = {},
    growthCashflowMods = {},
    smooth,
    multiYearValues = {},
    reviewPeriod,
    recurrence,
  ] = yield all([
    select(evaluatorSelectors.getAdoptionCashflowMods, id),
    select(evaluatorSelectors.getRiskCashflowMods, id),
    select(evaluatorSelectors.getGrowthCashflowMods, id),
    select(evaluatorSelectors.getSmooth, id),
    select(evaluatorSelectors.getMultiYearValues, id),
    select(sessionDetailsSelectors.getReviewPeriod),
    select(accountSelectors.getEvaluatorRecurrence, id, eNo),
  ]);

  const calculateObj = doEvaluatorCalculate({
    ...(formData && {
      formData: filter(isNotNil)(unformatFieldSetData(schema, formData)),
    }),
    reviewPeriod,
    endMonth,
    startMonth,
    growth,
    schema,
    adoptionCashflowMods,
    riskCashflowMods,
    growthCashflowMods,
    smooth,
    multiYearValues,
  });

  let { cashflow, ...values } = calculateObj;
  //start Editted Cashflows
  let edittedCashflow = yield select(cashflowsSelectors.getEdittedBenefitCashflow, id);

  if (edittedCashflow) {
    if (edittedCashflow.length > reviewPeriod + 1) {
      const updatedEdittedCashflow = dropLast(
        edittedCashflow.length - (reviewPeriod + 1),
        edittedCashflow
      );

      yield put(
        cashflowActionCreators.updateEvaluatorCashflow({ id, value: updatedEdittedCashflow })
      );
      edittedCashflow = updatedEdittedCashflow;
    }
    values = assoc('result', sum(edittedCashflow) / reviewPeriod, values);
  }

  yield all([
    put(
      evaluatorActionCreators.updateBenefit({
        id,
        startMonth,
        endMonth,
        startMonthLinked,
        endMonthLinked,
        values,
        growth,
        meta,
        recurrence,
      })
    ),
    put(
      cashflowActionCreators.updateEvaluatorResult({
        id,
        calculation_cashflow: cashflow,
        meta,
      })
    ),
  ]);
  yield call(unlinkDefaultSessionValues, { id, schema, formData, meta });
}

export default calculate;
