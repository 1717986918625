import rsfApp from 'redux/rsf';
import { useQuery } from 'react-query';
import { fetchSession, businessCaseSelectors } from '@sharkfinesse/sfl-lib';

const getItems = async ({ id, account, accountState, productState }) => {
  const session = await fetchSession({ firestore: rsfApp.firestore, id, account });

  const result = await businessCaseSelectors.getSessionSummary({
    account: accountState,
    product: productState,
    session: { present: session },
  });
  return { ...result, ...session.session };
};

const useSessionSummary = (props, config) => {
  return useQuery(
    `summary_${props.id}`,
    () => {
      return getItems(props);
    },
    config
  );
};

export default useSessionSummary;
