"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.interceptX = exports.interceptMonthRise = exports.inclinationAngle = void 0;

var _fp = require("./fp");

// prettier-ignore
const interceptMonthRise = (0, _fp.flip)(_fp.subtract); // prettier-ignore

exports.interceptMonthRise = interceptMonthRise;
const inclinationAngle = (0, _fp.curryN)(2, (0, _fp.pipe)(interceptMonthRise, Math.atan)); // prettier-ignore

exports.inclinationAngle = inclinationAngle;
const interceptX = (0, _fp.converge)(_fp.divide, [Math.abs, (0, _fp.pipe)(inclinationAngle, Math.tan)]);
exports.interceptX = interceptX;