/* @flow */
import { simpleAction } from '@sharkfinesse/sfl-lib';
const namespace = (name: string): string => `@@session.costs/${name}`;

//Actions
export const types = {
  DELETE: {
    ALL: namespace('DELETE.ALL'),
  },
};

//Action Creators
const updateDeleteAll = simpleAction(types.DELETE.ALL);

export const actionCreators = {
  updateDeleteAll,
};
