/* eslint-disable prettier/prettier */
import { css } from 'styled-components';

const CircularProgressBar = ({ font, colors, scale }) => ({
  container: ({ $size }) => css`
    display: flex;
    width: ${`${$size}px`};
    height: ${`${$size}px`};
    position: relative;
    justify-content: center;
    align-items: center;
  `,
  svg: () => css`
    display: block;
    max-width: 100%;
    position: absolute;
  `,
  circleBg: () => css`
    fill: none;
  `,
  circle: () => css`
    fill: none;
  `,

  percentWrapper: ({ $color }) => css`
    color: ${$color};
    font-size: 2.3em;
    display: flex;
    flex-direction: row;
  `,
  percentLabel: () => css`
    line-height: 1.1em;
  `,
  percentSymbol: () => css`
    font-size: 60%;
    padding-left: 2px;
  `,
  labelsWrapper: () => css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  stepProgress: () => css`
    font-size: 1.1em;
    color: #c6c6c6;
  `,
});

export default CircularProgressBar;
