import { useMutation } from 'react-query';
import fetchSession from './fetch-session';

const useOfflineSession = config => {
  return useMutation(async body => {
    const response = await fetchSession(body);
    return response;
  }, config);
};

export default useOfflineSession;
