// @flow
import React from 'react';
import { createPortal } from 'react-dom';
import { Arrow, PopperBox, ReferenceContainer } from './styled';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'styled-components/macro';

const Popper = ({
  content,
  usePortal,
  placement = 'bottom',
  delayShow = 500,
  popperProps,
  portalContainer = document.body,
  popperOptions,
  tipStyle = 'light',
  children,
  toolTipContainerProps,
  triggerOnClick,
  refernceAlignment = 'center',
  modifiers = [
    {
      name: 'preventOverflow',
      options: {
        boundary: 'viewport',
      },
    },
    {
      name: 'offset',
      options: {
        offset: [0, 16],
      },
    },
  ],
  zIndex,
  ...props
}) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({ delayShow, placement, ...popperProps }, { modifiers, ...popperOptions });

  const popper = (
    <PopperBox
      ref={setTooltipRef}
      {...getTooltipProps({ className: 'tooltip-container' })}
      tipStyle={tipStyle}
      zIndex={zIndex}
    >
      {content}
      <Arrow {...getArrowProps({ className: 'tooltip-arrow' })} />
    </PopperBox>
  );
  return (
    <>
      <ReferenceContainer
        ref={setTriggerRef}
        $refernceAlignment={refernceAlignment}
        onClick={triggerOnClick}
        {...toolTipContainerProps}
      >
        {children}
      </ReferenceContainer>
      {visible && (usePortal ? createPortal(popper, portalContainer) : popper)}
    </>
  );
};

export default Popper;
