"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = require("../../index");

var _reselect = require("reselect");

var _intl = _interopRequireDefault(require("./intl"));

var _layout = _interopRequireDefault(require("./layout"));

var _evaluator = _interopRequireDefault(require("./evaluator"));

var _fuse = _interopRequireDefault(require("fuse.js"));

var _account = _interopRequireDefault(require("./account"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const getSearchIndex = (0, _reselect.createSelector)([state => state, _layout.default.getSuites, _layout.default.getBenefits, state => state.session.present.benefits.data, _intl.default.getMessages, state => state.account.evaluators], (state, suites, benefits, benefitData, intl, schemas) => {
  const getBenefits = suite => (0, _index.map)(i => ({
    id: i,
    eNo: benefits[i].eNo,
    title: intl[`${benefits[i].eNo}.title.label`],
    suiteName: suite.name,
    customSuiteName: suite === null || suite === void 0 ? void 0 : suite.customName,
    categoryId: suite === null || suite === void 0 ? void 0 : suite.categoryId,
    suiteId: suite.id,
    recurrence: schemas[benefits[i].eNo].schema.recurrence,
    ..._evaluator.default.getProgress(state, i),
    ...benefitData[i]
  }), suite.benefits);

  let benefitIndex = [];
  (0, _index.forEachObjIndexed)(suite => benefitIndex.push(...getBenefits(suite)), suites);
  return benefitIndex;
});
const getSearchIndexFiltered = (0, _reselect.createSelector)([_layout.default.getFilters, getSearchIndex], (filters = [], searchIndex) => {
  if ((0, _index.isEmpty)(filters)) {
    return searchIndex;
  } else {
    return (0, _index.unnest)((0, _index.map)(val => (0, _index.filter)(i => i.status === val.value, searchIndex), filters));
  }
});
const getComplete = (0, _reselect.createSelector)([getSearchIndex], searchIndex => (0, _index.unnest)((0, _index.filter)(i => i.status === 'complete', searchIndex)));
const indexSearchOptions = {
  shouldSort: true,
  includeMatches: true,
  threshold: 0.1,
  tokenize: true,
  matchAllTokens: true,
  location: 0,
  distance: 800,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  findAllMatches: true,
  keys: ['title', 'values.userTitle']
};
const getBenefitsSearched = (0, _reselect.createSelector)([_layout.default.getSearch, getSearchIndexFiltered], (searchTxt, searchIndex) => {
  const fuse = new _fuse.default(searchIndex, indexSearchOptions);
  return searchTxt ? fuse.search(searchTxt.trim()) : searchIndex;
});
const getSearchedIds = (0, _reselect.createSelector)([_layout.default.getSearch, getBenefitsSearched, _layout.default.getFilters], (searchTxt, benefits, filters = []) => searchTxt ? (0, _index.map)((0, _index.path)(['item', 'id']), benefits) : (0, _index.map)((0, _index.prop)('id'), benefits));

function getState(state) {
  return state;
}

const getSuiteSearched = (0, _reselect.createSelector)([getState, _layout.default.getSearch, _layout.default.getSuite, getSearchedIds, _layout.default.getFilters, _layout.default.getBenefits], (state, searchTxt, suite, ids, filters = [], benefits) => {
  const onlyStatusFilters = (0, _index.isEmpty)((0, _index.filter)(f => {
    return f.key;
  }, filters));
  let filteredSuiteBenefits = [];

  if (!onlyStatusFilters) {
    for (let n = 0; n < suite.benefits.length; n++) {
      for (let f = 0; f < filters.length; f++) {
        var _filters$f;

        const x = suite.benefits[n];
        const eNo = benefits[x].eNo;

        if ((_filters$f = filters[f]) !== null && _filters$f !== void 0 && _filters$f.key) {
          if (filters[f].value === _account.default.getBenefitCategory(state, eNo)) {
            filteredSuiteBenefits.push(suite.benefits[n]);
          }
        }
      }
    }
  } else {
    filteredSuiteBenefits = suite.benefits;
  }

  let filtersCategoriesRemoves = [];

  for (let y = 0; y < filters.length; y++) {
    var _filters$y;

    if (!((_filters$y = filters[y]) !== null && _filters$y !== void 0 && _filters$y.key)) {
      filtersCategoriesRemoves.push(filters[y]);
    }
  }

  return searchTxt || !(0, _index.isEmpty)(filtersCategoriesRemoves) ? { ...suite,
    benefits: (0, _index.map)(val => val, (0, _index.filter)(i => {
      return (0, _index.includes)(i, ids);
    }, filteredSuiteBenefits))
  } : { ...suite,
    benefits: filteredSuiteBenefits
  };
});
const selectors = {
  getSuiteSearched,
  getSearchIndex,
  getSearchIndexFiltered,
  getComplete
};
var _default = selectors;
exports.default = _default;