import { takeLatest, takeEvery } from 'redux-saga/effects';

import { initFirestore } from 'redux/rsf';
import { types as questionnaireTypes } from 'redux/modules/questionnaire/questionnaire';
import { types as loginQuestionnaireTypes } from 'redux/modules/login-questionnaire';
import load from './load';
import saveBenefit from './save-benefit';
import updateCommonValue from './common-values';

export const effects = [
  takeLatest(questionnaireTypes.LOAD, load),
  takeEvery(questionnaireTypes.BENEFIT.UPDATE, saveBenefit),
  takeEvery(questionnaireTypes.COMMON_VALUES.UPDATE, updateCommonValue),
  takeLatest(loginQuestionnaireTypes.QUESTIONNAIRE.AUTHENTICATED, initFirestore),
];
export default effects;
