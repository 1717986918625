/* eslint-disable prettier/prettier */
import { css } from 'styled-components';

const Badge = ({ font, colors, scale }) => ({
  base: ({ selected }) => css`
    border: 1px solid ${selected ? colors.primary : colors.uiLight};
    height: 20px;
    background: ${selected ? '#baddf5' : colors.white};
    border-radius: 10px;
    padding: 0 5px;
    position: absolute;
    right: 5px;
    top: -8px;
    z-index: 900;
    line-height: 18px;
    color: ${colors.ui};
  `,
});

export default Badge;
