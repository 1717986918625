"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toNumberOrIdentity = exports.toNumberOr = exports.toNumber = exports.numberOr = exports.maybeString = exports.maybeObject = exports.maybeNumber = exports.maybeNil = exports.isType = exports.isString = exports.isObject = exports.isNumber = exports.isNotNil = void 0;

var _fp = require("./fp");

var _logic = require("./logic");

const isNotNil = (0, _fp.complement)(_fp.isNil); // prettier-ignore

exports.isNotNil = isNotNil;
const isNumber = (0, _fp.allPass)([(0, _fp.pipe)(parseFloat, isNaN, _fp.not), isFinite]);
exports.isNumber = isNumber;
const isType = (0, _fp.useWith)(_fp.call, [_fp.equals, _fp.type]);
exports.isType = isType;
const isString = isType('String'); // prettier-ignore

exports.isString = isString;

const isObject = o => o instanceof Object; // prettier-ignore


exports.isObject = isObject;
const maybeNumber = (0, _logic.maybeType)(isNumber); // prettier-ignore

exports.maybeNumber = maybeNumber;
const maybeObject = (0, _logic.maybeType)(isObject); // prettier-ignore

exports.maybeObject = maybeObject;
const maybeString = (0, _logic.maybeType)(isString);
exports.maybeString = maybeString;

const toNumber = a => Number(a);

exports.toNumber = toNumber;
const toNumberOr = (0, _fp.curry)((a, b) => (0, _fp.pipe)(toNumber, (0, _fp.ifElse)(isNaN, (0, _fp.always)(a), _fp.identity))(b));
exports.toNumberOr = toNumberOr;

const toNumberOrIdentity = a => toNumberOr(a, a);

exports.toNumberOrIdentity = toNumberOrIdentity;
const maybeNil = (0, _fp.ifElse)(isNotNil, _fp.Maybe.Just, (0, _fp.always)(_fp.Maybe.Nothing));
exports.maybeNil = maybeNil;
const numberOr = (0, _fp.curryN)(2, (or, val) => isNumber(val) ? val : or);
exports.numberOr = numberOr;