// @flow
import styled from 'styled-components';
import { animated } from 'react-spring';
import applyTheme from '../../styles/apply-theme';

const notification = applyTheme('notification');
export const Container = notification('container', styled(animated.div));
export const Wrapper = notification('wrapper');
export const LeftContent = notification('leftContent');
export const RightContent = notification('rightContent');
export const IconWrapper = notification('iconWrapper');
export const LabelWrapper = notification('labelWrapper');
export const Label = notification('label');
export const SubLabel = notification('subLabel');
