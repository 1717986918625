import { getTrashedSessions } from 'redux/firestore';
import { useQuery } from 'react-query';
import { getCollectionData } from '@sharkfinesse/sfl-lib';

const getItems = async (props) => {
  const response = await getTrashedSessions(props);
  const sessions = getCollectionData(response);
  return sessions;
};

const useTrashedSessions = (props, config) =>
  useQuery('trashedSessions', () => getItems(props), config);

export default useTrashedSessions;
