/* @flow */
/* eslint-disable max-len */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21,21H4V8h16v13H21V7V4c0-0.2-0.8-1-1-1h-1l0,0c0-0.2-0.8-1-1-1h-1c-0.2,0-1,0.8-1,1l0,0H8l0,0
	c0-0.2-0.8-1-1-1H6C5.8,2,5,2.8,5,3l0,0H4C3.8,3,3,3.8,3,4v3.2V21c0,0.2,0.8,1,1,1h16C20,22,20.6,21.7,21,21z M17,3h1v1v1h-1V4V3z
	 M6,3h1v1v1H6V4V3z M4,4h1v1c0,0.2,0.8,1,1,1h1c0.2,0,1-0.8,1-1V4h8v1c0,0.2,0.8,1,1,1h1c0.2,0,1-0.8,1-1V4h1v3H4V4z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11,9h2v7.9c0,0.4,0,0.7,0.1,0.8c0.1,0.1,0.1,0.2,0.3,0.3S13.7,18,14,18h1v1H9v-1h1c0.3,0,0.6,0,0.7-0.1
	c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.4,0.1-0.7l0-5.9H9v-1C10.2,10,10.7,9.8,11,9z"
    />
  </Icon>
);

export default memo(IconComponent);
