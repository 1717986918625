"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _normalization = require("../../normalization");

var _constants = require("../../constants");

const initialState = {
  dealName: '',
  customerName: '',
  supplierName: '',
  minimumReturn: 0,
  reviewPeriod: (0, _normalization.normalizeTime)('months', _constants.defaultReviewPeriod),
  industry: '',
  notes: '',
  currency: {
    select: '£',
    value: '£',
    position: 'prefix'
  },
  numberFormat: 'commaDot',
  dateFormat: 'DD/MM/YYYY',
  monthlyBenefitChartOrder: {
    field: 'suite',
    direction: 'asc'
  },
  oneTimeBenefitChartOrder: {
    field: 'suite',
    direction: 'asc'
  },
  totalBenefitChartOrder: {
    field: 'suite',
    direction: 'asc'
  },
  recurringCostsChartOrder: {
    field: 'list',
    direction: 'asc'
  },
  nonRecurringCostsChartOrder: {
    field: 'list',
    direction: 'asc'
  },
  media: {},
  defaultStartMonth: (0, _normalization.normalizeTime)('months', _constants.defaultStartMonth)
};
var _default = initialState;
exports.default = _default;