/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon viewBox="0 0 28 28" {...props}>
    <path
      fill="currentColor"
      d="M1.8,0h24.5c1,0,1.8,0.8,1.8,1.8v24.5c0,1-0.8,1.8-1.8,1.8H1.8c-1,0-1.8-0.8-1.8-1.8V1.8C0,0.8,0.8,0,1.8,0z"
    />
    <path fill="#000000" d="M10.7,9.3V4.4h6.6v4.9H10.7z M17.3,12h5.5v9.9H5.2V12h5.5V17h6.6V12z" />
  </Icon>
);

export default memo(IconComponent);
