// @flow
/* eslint-disable no-magic-numbers */
import React, { memo } from 'react';
import { Wrapper } from './styled';

const Avatar = ({ src, name, size = 26, style, border, hideBorder, ...props }) => (
  <Wrapper
    src={src}
    alt={name}
    $border={border}
    $hideBorder={hideBorder}
    {...props}
    style={{
      ...style,
      width: `${size}px`,
      height: `${size}px`,
      minWidth: `${size}px`,
      minHeight: `${size}px`,
    }}
  />
);

export default memo(Avatar);
