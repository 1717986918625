import { css } from 'styled-components';

const Menu = ({ font, colors, scale }) => {
  return {
    list: ({ compact }) => css`
      padding: ${compact ? '0' : '8px 0'};
      margin: 0;
      position: relative;
      list-style: none;
      display: block;
      width: 100%;
    `,
    item: ({ compact }) => css`
       color: ${colors.ui};
        height: 24px;
        overflow: hidden;
        white-space: nowrap;
        padding: ${scale.one} ${compact ? '10px' : scale.one};
        font-size: ${font.sizeNormal}
        text-overflow: ellipsis;
        transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        display: flex;
        position: relative;
        text-align: left;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        margin: 0;
        border: 0;
        outline: none;
        user-select: none;
        border-radius: 0;
        vertical-align: middle;
        list-style: none;
        font-weight: 400;
        &:hover {
          background-color: ${colors.uiLightest};
        }
        &:active {
          background-color: ${colors.uiLight};
        }
      a {
        color: ${colors.ui};
        text-decoration: none;
      }
    `,
    animatedItem: () => css`
      color: ${font.color};
      font-size: 16px;
      height: auto;
      padding: 6px 26px 6px 14px;
      line-height: 20px;
    `,
    tip: () => css`
      color: ${colors.ui};
      font-size: 13px;
      line-height: 18px;
    `,
    iconBefore: () => css`
      display: flex;
      margin-right: ${scale.zero};
    `,
    animatedIconBefore: () => css`
      display: flex;
      margin-right: 10px;
    `,
    iconAfter: () => css`
      display: flex;
      margin-left: ${scale.zero};
    `,
    divider: () => css`
      border-top: 1px solid ${colors.uiLightest};
      height: 1px;
      width: 100%;
    `,

    menuInfo: () => css`
      word-break: break-word;
      max-width: 200px;
      margin: 10px;
      text-align: left;
      line-height: 20px;
    `,
    menuInfoTitle: () => css`
      font-size: 16px;
      font-weight: ${font.weightSemiBold};
      margin-bottom: 8px;
    `,
    menuCreator: () => css`
      font-size: 14px;
      margin-bottom: 8px;
      a {
        color: ${colors.primary};
        cursor: pointer;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    `,
    menuLink: () => css``,
  };
};

export default Menu;
