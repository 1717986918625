/* @flow */
import React from 'react';
import { BgBox, BgBoxCircle1, BgBoxCircle2, Container, Label, Value } from './styled';

type Props = {
  width: String | Number,
  height: String | Number,
  label: String | Number,
  value: String | Number,
  color: String | Number,
};

const ResultBox = ({ width = '32.5%', height = '80px', label, value, ...props }: Props) => {
  return (
    <Container style={{ width, height }}>
      <BgBox {...props}>
        <BgBoxCircle1 />
        <BgBoxCircle2 />
      </BgBox>
      <Label>{label}</Label>
      <Value>{value}</Value>
    </Container>
  );
};

export default ResultBox;
