"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _normalization = require("../../normalization");

var _constants = require("../../constants");

const initialId = 'initial';
const initialState = {
  costs: {
    [initialId]: {
      id: initialId,
      description: '',
      start: (0, _normalization.normalizeTime)('months', 1),
      end: (0, _normalization.normalizeTime)('months', _constants.defaultReviewPeriod),
      growth: 0,
      cost: null,
      pristine: true,
      recurrence: 'monthly',
      type: '-',
      showCashflow: false
    }
  },
  ids: [initialId],
  result: null,
  notes: ''
};
var _default = initialState;
exports.default = _default;