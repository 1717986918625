import firebase from 'firebase/app';
import rsfApp from './../rsf';
import { getDeviceIdLocalStorage } from './device';
import LogRocket from 'logrocket';

export default function presence() {
  const uid = firebase.auth().currentUser.uid;
  const clientId = rsfApp.firebaseApp.options.clientId;
  const deviceId = getDeviceIdLocalStorage();

  const dbUser = uid.replace(/\./g, ',');

  const clientDatabaseRef = rsfApp.database.ref(
    `/devices/${dbUser}/${deviceId}/clients/${clientId}`
  );
  const userStatusDatabaseRef = rsfApp.database.ref(`/devices/${dbUser}/${deviceId}`);
  const userStatusFirestoreRef = rsfApp.firestore.doc(`/devices/${uid}/active/${deviceId}`);

  const isOfflineForDatabase = {
    id: deviceId,
    clientId: clientId,
    lastChanged: firebase.database.ServerValue.TIMESTAMP,
  };

  const isOnlineForDatabase = {
    id: deviceId,
    clientId: clientId,
    lastChanged: firebase.database.ServerValue.TIMESTAMP,
  };
  const isOnlineForClientDatabase = {
    id: clientId,
  };

  const isOnlineForFirestore = {
    id: deviceId,
    state: 'online',
    lastChanged: firebase.firestore.FieldValue.serverTimestamp(),
    clients: firebase.firestore.FieldValue.arrayUnion(clientId),
  };

  const isOfflineForFirestore = {
    id: deviceId,
    state: 'offline',
    lastChanged: firebase.firestore.FieldValue.serverTimestamp(),
  };

  const dbRef = rsfApp.database.ref('.info/connected');
  let timer = null;
  try {
    dbRef.on('value', function (snapshot) {
      if (snapshot.val() === false) {
        timer = setTimeout(() => {
          clientDatabaseRef.remove();
          userStatusFirestoreRef.update(isOfflineForFirestore);
        }, 1000);

        return;
      }
      clearTimeout(timer);

      userStatusDatabaseRef
        .onDisconnect()
        .update(isOfflineForDatabase)
        .then(function () {
          userStatusDatabaseRef.update(isOnlineForDatabase);
          // We'll also add Firestore set here for when we come online.
          userStatusFirestoreRef.update(isOnlineForFirestore);

          clientDatabaseRef.update(isOnlineForClientDatabase);
        });

      clientDatabaseRef.onDisconnect().remove();
    });
  } catch (error) {
    console.log('Error', error);

    LogRocket.captureException(error);
  }
  return dbRef;
}
