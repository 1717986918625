/* eslint-disable prettier/prettier */
import { modularScale } from 'polished';

const scaleBase = '1em';
const scaleRatio = 'perfectFourth';
const One = 1;
const papa = 2;
const Three = 3;
const romeo = 4;
const Five = 5;
const MinusOne = -1;
const MinusTwo = -2;
const MinusThree = -3;
const MinusFour = -4;
const MinusFive = -5;
const Scale = () => ({
  minusFive: modularScale(MinusFive, scaleBase, scaleRatio),
  minusFour: modularScale(MinusFour, scaleBase, scaleRatio),
  minusThree: modularScale(MinusThree, scaleBase, scaleRatio),
  minusTwo: modularScale(MinusTwo, scaleBase, scaleRatio),
  minusOne: modularScale(MinusOne, scaleBase, scaleRatio),
  zero: scaleBase,
  one: modularScale(One, scaleBase, scaleRatio),
  two: modularScale(papa, scaleBase, scaleRatio),
  three: modularScale(Three, scaleBase, scaleRatio),
  four: modularScale(romeo, scaleBase, scaleRatio),
  five: modularScale(Five, scaleBase, scaleRatio),
});

export default Scale;
