import { MessageModal } from '@sharkfinesse/sfl-ui';
import { intlSelectors } from '@sharkfinesse/sfl-lib';
import { useCallback } from 'react';
import UserModal from 'containers/user-modal';
import { useModal } from '@ebay/nice-modal-react';
import { useSendShareCopy } from 'redux/firestore/hooks';
import { errorModal } from 'utils';
import { toast } from 'react-toastify';
import { notifyConfig } from 'components/toast/config';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import SharedToList from 'containers/shared-to-list';

const useShareCopy = ({ sessionId, ...other }) => {
  const userModal = useModal(UserModal);
  const messageModal = useModal(MessageModal);
  const locale = useSelector(intlSelectors.getLocale);

  const { mutate } = useSendShareCopy({
    onSuccess: ({ length }) => {
      messageModal.hide();
      toast(
        () => (
          <FormattedMessage
            id="dealShare.toast.sharedWith"
            defaultMessage="{numberSharedWith,plural,=0{Not Shared}one{Shared with # person}other{Shared with {numberSharedWith} people}}"
            values={{
              numberSharedWith: length,
            }}
          />
        ),
        notifyConfig
      );
    },
    onError: error => {
      errorModal({ error: error.message });
      messageModal.hide();
    },
  });

  const openShareCopy = useCallback(() => {
    userModal
      .show({
        title: <FormattedMessage id="share.copy.modal.title" defaultMessage="Share a copy" />,
        label: (
          <FormattedMessage
            id="dealShare.intro"
            defaultMessage="Add people to share a copy of this session."
          />
        ),
        children: <SharedToList sessionId={sessionId} />,
        sessionId,
        ...other,
      })
      .then(({ emailList }) => {
        userModal.hide();
        if (emailList?.length > 0) {
          messageModal
            .show({
              title: (
                <FormattedMessage
                  id="share.copy.message.modal.title"
                  defaultMessage="Email message"
                />
              ),
            })
            .then(message => {
              mutate({
                locale,
                sessionId,
                message,
                users: emailList,
              });
            });
        }
      });
  }, [userModal, sessionId, messageModal, mutate, locale, other]);

  return { openShareCopy };
};

export default useShareCopy;
