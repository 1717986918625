import { padding } from 'polished';
import ButtonNormal from './button-normal';

const ButtonLarge = config => ({
  ...ButtonNormal(config),
  padding: padding(config.scale.minusTwo, config.scale.zero),
  fontSize: config.font.sizeLarge,
});

export default ButtonLarge;
