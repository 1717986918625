// @flow
import React from 'react';
import { MenuItemWrapper, MenuLabel, Icon } from './styled';

import { split, propEq, find } from '@sharkfinesse/sfl-lib';

export type Props = {
  label?: Element<*> | String,
  icon?: Element<*> | String,
  onClick: Function,
  children: Any,
  path?: Element<*> | String,
  exact: Boolean,
  menuChildren: Array,
};

const MenuItem = ({ icon, children, onClick, path, exact, menuChildren }: Props) => (
  <MenuItemWrapper
    isActive={(match, location) => {
      if (!match) return false;

      if (!match.isExact) {
        const splitPath = split('/')(location.pathname);

        if (splitPath[1] === 'folders')
          if (find(propEq('id', splitPath[2]))(menuChildren)) return false;
      }
      if (exact) {
        if (match.isExact) return true;
        return false;
      }
      return true;
    }}
    to={path}
    onClick={() => {
      if (onClick) onClick();
    }}
  >
    <Icon>{icon}</Icon>
    <MenuLabel>{children}</MenuLabel>
  </MenuItemWrapper>
);

export default MenuItem;
