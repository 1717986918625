import { css } from 'styled-components';

const ButtonGroup = ({ font, colors, scale }) => ({
  base: () => css`
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    button {
      position: relative;
      flex: 1 1 auto;
      &:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: 1px;
      }
      &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:hover,
      &:focus,
      &:active,
      &.active {
        z-index: 2;
      }
    }
  `,
});

export default ButtonGroup;
