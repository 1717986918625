import { useQueries } from 'react-query';
import useQuestionnairesWithStatus from './useQuestionnairesWithStatus';
import { getSessionFile } from 'redux/firestore';
import { groupBy, prop, keys, isEmpty, propEq, filter } from '@sharkfinesse/sfl-lib';

const getItems = async (group, id, props) => {
  const sessionFile = await getSessionFile({ id, account: props.account }).then(snapshot =>
    snapshot.data()
  );

  return {
    submissions: group[id],
    ...sessionFile,
  };
};

const useGroupedQuestionnaires = (props, config) => {
  const { data, isSuccess, isFetching } = useQuestionnairesWithStatus(props, config);

  const group = isSuccess ? groupBy(prop('sessionId'))(data?.submissions) : [];
  const groupKeys = keys(group);

  const querys = useQueries(
    groupKeys.map(id => ({
      queryKey: ['quSession', id],
      queryFn: () => getItems(group, id, props),
      enabled: isSuccess && !isFetching,
      ...config,
    }))
  );

  const isSuccessGrouped =
    !isEmpty(querys) && filter(propEq('status', 'success'), querys).length === querys.length;

  if (isSuccessGrouped) {
    const newData = querys.reduce(
      (accum, item) => {
        if (item.data?.archived) return accum;

        const session = item.data.submissions.reduce(
          (subAccum, submission) => {
            if (submission.type === 'new') {
              return { ...subAccum, newCount: subAccum.newCount + 1 };
            }
            if (submission.type === 'update') {
              return { ...subAccum, updateCount: subAccum.updateCount + 1 };
            }

            return subAccum;
          },
          {
            sessionId: item.data.id,
            sessionName: item.data?.dealName,
            imageUrl: item.data?.imageUrl,
            newCount: 0,
            updateCount: 0,
            submissions: item.data.submissions,
          }
        );

        return {
          submissionCount: accum.submissionCount + session.newCount + session.updateCount,
          sessions: [...accum.sessions, session],
        };
      },
      { submissionCount: 0, sessions: [] }
    );

    return {
      isSuccess: isSuccessGrouped,
      ...newData,
    };
  }
  return { isSuccess: false, submissionCount: 0, sessions: [] };
};

export default useGroupedQuestionnaires;
