/* @flow */
/* eslint-disable max-len */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="inherit"
      d="M13.1,10.9c1.2-1.7,1.2-4.1,0-5.8c0.6-0.4,1.2-0.6,1.9-0.6c1.9,0,3.5,1.6,3.5,3.5
	s-1.6,3.5-3.5,3.5C14.3,11.5,13.6,11.3,13.1,10.9"
    />
    <path
      fill="currentColor"
      d="M9,4.5C7.1,4.5,5.5,6.1,5.5,8s1.6,3.5,3.5,3.5s3.5-1.6,3.5-3.5S10.9,4.5,9,4.5 M9,9.5
	C8.2,9.5,7.5,8.8,7.5,8S8.2,6.5,9,6.5s1.5,0.7,1.5,1.5S9.8,9.5,9,9.5"
    />
    <path
      fill="currentColor"
      d="M9,13.5c-7,0-7,4-7,4v2h14v-2C16,17.5,16,13.5,9,13.5 M4,17.5c0.1-0.7,1.3-2,5-2s4.9,1.2,5,2H4z"
    />
    <path
      fill="inherit"
      d="M15.9,13.5c1.2,1,2,2.4,2.1,4v2h4v-2C22,17.5,22,13.9,15.9,13.5L15.9,13.5z"
    />
  </Icon>
);

export default memo(IconComponent);
