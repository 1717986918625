"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reselect = require("reselect");

var _index = require("../../index");

var _sessionDetails = _interopRequireDefault(require("./session-details"));

var _totalCosts = _interopRequireDefault(require("./total-costs"));

var _benefits = _interopRequireDefault(require("./benefits"));

var _recurringCosts = _interopRequireDefault(require("./recurring-costs"));

var _nonRecurringCosts = _interopRequireDefault(require("./non-recurring-costs"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* eslint-disable no-unused-vars  */
// Selectors
const getTenderPeriod = state => state.session.present.tender.tenderPeriod;

const getStaffEngaged = state => state.session.present.tender.staffEngaged;

const getLoadedCost = state => state.session.present.tender.annualLoadedCostPerPerson;

const getOtherCosts = state => state.session.present.tender.otherCosts;

const getIsDirty = state => state.session.present.tender.isDirty;

const getIsUsed = state => state.session.present.tender.isUsed;

const getIsCollapsed = state => state.session.present.tender.isCollapsed; //const getCalculated = state => state.session.present.tender.calculated;


const getCalculated = (0, _reselect.createSelector)([getTenderPeriod, getStaffEngaged, getLoadedCost, getOtherCosts, _benefits.default.getMonthlyBenefits, _totalCosts.default.getTotalCosts], (tenderPeriod, staffedEngaged, loadedCost, otherCosts, monthlyBenefits, totalCosts) => {
  if ((0, _index.isNil)(tenderPeriod) || (0, _index.isEmpty)(tenderPeriod)) return false;
  if ((0, _index.isNil)(staffedEngaged) || (0, _index.isEmpty)(staffedEngaged)) return false;
  if ((0, _index.isNil)(loadedCost) || (0, _index.isEmpty)(loadedCost)) return false;
  if ((0, _index.isNil)(otherCosts) || (0, _index.isEmpty)(otherCosts)) return false;
  if ((0, _index.isNil)(monthlyBenefits) || (0, _index.isEmpty)(monthlyBenefits)) return false;
  if ((0, _index.isNil)(totalCosts) || (0, _index.isEmpty)(totalCosts)) return false;
  return true;
});
const getLostBenefits = (0, _reselect.createSelector)([getTenderPeriod, getStaffEngaged, getLoadedCost, _benefits.default.getMonthlyBenefits, _recurringCosts.default.getResult, _totalCosts.default.getTotalCosts, getOtherCosts], (tenderPeriod, staffedEngaged, loadedCost, monthlyBenefits, recurringCosts, totalCosts, otherCosts) => {
  const netBenefits = monthlyBenefits - recurringCosts;
  const otherCostsValue = (0, _index.isNil)(otherCosts) || (0, _index.isEmpty)(otherCosts) ? 0 : otherCosts;
  return (0, _index.tenderCost)(tenderPeriod, netBenefits, staffedEngaged, loadedCost, totalCosts) - totalCosts + otherCostsValue;
});
const getTenderCosts = (0, _reselect.createSelector)([getLostBenefits, _totalCosts.default.getTotalCosts], (lostBenefits, totalCosts) => {
  return lostBenefits + totalCosts;
});
const getNetMonthlyBenefits = (0, _reselect.createSelector)([_benefits.default.getMonthlyBenefits, _recurringCosts.default.getResult], (monthlyBenefits, recurringCosts) => monthlyBenefits - recurringCosts);
const getNetMonthlyBenefitsDisplay = (0, _reselect.createSelector)([getNetMonthlyBenefits, _sessionDetails.default.getNumberFormatObj], (monthlyBenefits, numberFormatObj) => (0, _index.formatForDisplay)('money', numberFormatObj, monthlyBenefits));
const getResult = (0, _reselect.createSelector)([getTenderPeriod, getStaffEngaged, getLoadedCost, getOtherCosts, _benefits.default.getMonthlyBenefits, _recurringCosts.default.getResult, _totalCosts.default.getTotalCosts, getNetMonthlyBenefits], (tenderPeriod, staffedEngaged, loadedCost, otherCosts, monthlyBenefits, recurringCosts, totalCosts, netBenefits) => (0, _index.priceImprovementRequired)(tenderPeriod, netBenefits, staffedEngaged, loadedCost, otherCosts, totalCosts));
const getResultDisplay = (0, _reselect.createSelector)([getResult, _sessionDetails.default.getNumberFormatObj], (value, numberFormatObj) => (0, _index.formatForDisplay)('percentage', (0, _index.assoc)('decimalPlaces', 1, numberFormatObj), value));
const getLostBenefitsDisplay = (0, _reselect.createSelector)([getLostBenefits, _sessionDetails.default.getNumberFormatObj], (value, numberFormatObj) => (0, _index.formatForDisplay)('money', numberFormatObj, value));
const getTenderPeriodDisplay = (0, _reselect.createSelector)([getTenderPeriod, _sessionDetails.default.getNumberFormatObj], (value, numberFormatObj) => {
  return (0, _index.formatForDisplay)('number', numberFormatObj, value);
});
const getLostGrossBenefits = (0, _reselect.createSelector)([getTenderPeriod, getStaffEngaged, getLoadedCost, _benefits.default.getMonthlyBenefits, _totalCosts.default.getTotalCosts, getOtherCosts], (tenderPeriod, staffedEngaged, loadedCost, monthlyBenefits, totalCosts, otherCosts) => {
  return (0, _index.tenderCost)(tenderPeriod, monthlyBenefits, staffedEngaged, loadedCost, totalCosts) - totalCosts + otherCosts;
});
const getLostGrossBenefitsDisplay = (0, _reselect.createSelector)([getLostGrossBenefits, _sessionDetails.default.getNumberFormatObj], (value, numberFormatObj) => (0, _index.formatForDisplay)('money', numberFormatObj, value));
const getDisplay = (0, _reselect.createSelector)([_nonRecurringCosts.default.getResult, _recurringCosts.default.getResult], (nonRecurringCosts, recurringCosts) => nonRecurringCosts || recurringCosts ? true : false);
const selectors = {
  getResult,
  getResultDisplay,
  getLostBenefits,
  getLostBenefitsDisplay,
  getTenderPeriodDisplay,
  getStaffEngaged,
  getLoadedCost,
  getOtherCosts,
  getCalculated,
  getLostGrossBenefitsDisplay,
  getIsDirty,
  getIsUsed,
  getIsCollapsed,
  getNetMonthlyBenefits,
  getNetMonthlyBenefitsDisplay,
  getDisplay,
  getTenderCosts
};
var _default = selectors;
exports.default = _default;