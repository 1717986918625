import { path } from '@sharkfinesse/sfl-lib';
import { types as benefitsTypes } from 'redux/modules/benefits';
import { types as layoutTypes } from 'redux/modules/layout';
import { put, call, takeLatest } from 'redux-saga/effects';
import { actionCreators as evaluatorActionCreators } from 'redux/modules/evaluator';
import { actionCreators as cashflowActionCreators } from 'redux/modules/cashflow';
import { actionCreators as defaultSessionValuesActionCreators } from 'redux/modules/default-session-values';
import { types as sessionTypes } from 'redux/modules/session';
import undoCurrent from './benefit/undoCurrent';
import redoCurrent from './benefit/redoCurrent';
import undoToast from 'redux/sagas/toast/undo';

function* deleteAll(action) {
  const meta = path(['meta'], action);
  yield call(undoToast, {
    action,
    undoActions: [
      put(cashflowActionCreators.updateEvaluatorDeleteAll({ meta })),
      put(evaluatorActionCreators.updateReset({ meta })),
      put(defaultSessionValuesActionCreators.updateDeleteAll({ meta })),
    ],
    formattedMessageId: 'benefits.delete-all.toast.message',
    defaultMessage: 'All Benefits Deleted',
  });
}

function* deleteSuiteBenefits(action) {
  const meta = path(['meta'], action);
  yield call(undoToast, {
    action,
    undoActions: [
      put(cashflowActionCreators.updateEvaluatorDeleteAll({ meta })),
      put(evaluatorActionCreators.updateReset({ meta })),
      put(defaultSessionValuesActionCreators.updateDeleteAll({ meta })),
    ],
    formattedMessageId: 'benefits.delete-suite.toast.message',
    defaultMessage: 'Suite Reset',
  });
}

export const effects = [
  takeLatest(benefitsTypes.DELETE.ALL, deleteAll),
  takeLatest(sessionTypes.UNDO, undoCurrent),
  takeLatest(sessionTypes.REDO, redoCurrent),
  takeLatest(layoutTypes.SUITE.DELETE_BENEFITS, deleteSuiteBenefits),
];

export default effects;
