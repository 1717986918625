// @flow
import React from 'react';
import Modal from '../modal';
import Button from '../button';
import { FormattedMessage } from 'react-intl';
import { BodyWrapper } from './styled';
import NiceModal from '@ebay/nice-modal-react';
import useNiceModal from '../modal/useNiceModal';

const ConfirmModal = ({
  title,
  body,
  confirmAction,
  cancelAction,
  hideCancel,
  loading = false,
}) => {
  const { modal, modalProps } = useNiceModal();

  const confirm = () => {
    if (confirmAction) confirmAction();
    else modal.resolve();
  };

  return (
    <Modal
      {...modalProps}
      title={
        title ? title : <FormattedMessage id="modal.confirm.title" defaultMessage="Are you sure?" />
      }
      showHeaderKeyline
      showFooterKeyline
      closeOnOutsideClick={false}
      footer={
        <>
          {!hideCancel && (
            <Button style={{ marginLeft: '10px', minWidth: '60px' }} onClick={modalProps.onClose}>
              <FormattedMessage id="buttons.cancel.label" defaultMessage="Cancel" />
            </Button>
          )}
          <Button
            color="Primary"
            onClick={confirm}
            style={{ marginLeft: '10px', minWidth: '60px' }}
            label={true}
            loading={loading}
          >
            <FormattedMessage id="buttons.ok.label" defaultMessage="OK" />
          </Button>
        </>
      }
    >
      <BodyWrapper>
        {body ? (
          body
        ) : (
          <FormattedMessage
            id="modal.confirm.body"
            defaultMessage="Please confirm you wish to do this."
          />
        )}
      </BodyWrapper>
    </Modal>
  );
};

export default NiceModal.create(ConfirmModal);
