/* @flow */
import {
  createReducer,
  simpleAction,
  simpleReducer,
  sessionDoNothingInitialState,
} from '@sharkfinesse/sfl-lib';
const namespace = (name: string): string => `@@session.doNothing/${name}`;

//Actions
export const types = {
  IS_USED: namespace('IS_USED'),
  IS_COLLAPSED: namespace('IS_COLLAPSED'),
};

//Reducer
export const initialState = sessionDoNothingInitialState;

const reduceIsUsed = simpleReducer('isUsed');
const reduceIsCollapsed = simpleReducer('isCollapsed');

export default createReducer(
  {
    [types.IS_USED]: reduceIsUsed,
    [types.IS_COLLAPSED]: reduceIsCollapsed,
  },
  initialState
);

//Action Creators
const updateIsUsed = simpleAction(types.IS_USED);
const updateIsCollapsed = simpleAction(types.IS_COLLAPSED);

export const actionCreators = {
  updateIsUsed,
  updateIsCollapsed,
};
