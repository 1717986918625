"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.nonRecurringCosts = exports.generateNonRecurringCostCashflow = void 0;

var _fp = require("./fp");

var _cashflow = require("./cashflow");

var _units = require("./units");

var _evaluatorValidate = require("./evaluator-validate");

// prettier-ignore
const generateNonRecurringCostCashflow = (0, _fp.curry)((period, obj) => (0, _cashflow.oneTimeCashflow)(period, (0, _units.secondsToMonths)(obj.month), obj.cost));
exports.generateNonRecurringCostCashflow = generateNonRecurringCostCashflow;

const nonRecurringCosts = (period, costObjNoCashflow) => {
  const addEmptyCashflow = x => {
    return { ...x,
      cashflow: []
    };
  };

  let costObjCosts = (0, _fp.map)(addEmptyCashflow, costObjNoCashflow.costs);
  const costObj = { ...costObjNoCashflow,
    costs: costObjCosts
  };
  const resultLens = (0, _fp.lensProp)('result');
  const cashflowLens = (0, _fp.lensProp)('cashflow');
  const costLens = (0, _fp.lensProp)('cost');
  const monthLens = (0, _fp.lensProp)('month');
  const pristineLens = (0, _fp.lensProp)('pristine');
  const editedLens = (0, _fp.lensProp)('cashflowEdited');

  const setCostCashflow = cost => {
    //If pristine
    if ((0, _fp.view)(pristineLens, cost) === true) {
      return cost;
    }

    const updateCashflow = obj => {
      if (obj.cost === '' || obj.cost === null || obj.cost === undefined || !(0, _evaluatorValidate.validateImpact)((0, _units.secondsToMonths)(obj.month), period)) {
        return (0, _fp.set)(cashflowLens, [])(obj);
      }

      return (0, _fp.set)(cashflowLens, generateNonRecurringCostCashflow(period, obj))(obj);
    }; // If the cost is not pristine and it has an unedited cashflow generate a one time cashflow


    if ((0, _fp.view)(pristineLens, cost) === false && (0, _fp.view)(editedLens, cost) !== true) {
      let month = 0;

      if (cost.month !== '-') {
        month = cost.month;
      }

      const updatedCost = (0, _fp.pipe)((0, _fp.set)(monthLens, month), updateCashflow)(cost);
      return updatedCost;
    } // If edited cashflow return without with calculated cost and no impact month


    return (0, _fp.pipe)((0, _fp.set)(costLens, (0, _fp.sum)((0, _fp.view)(cashflowLens, cost))), (0, _fp.set)(monthLens, '-'))(cost);
  }; // Update costs based on cashflow


  const transform = {
    costs: costs => (0, _fp.map)(setCostCashflow, costs)
  };
  const updateCosts = (0, _fp.pipe)((0, _fp.evolve)(transform))(costObj); // Calculate final result

  const calculateResult = (accum, key) => (0, _fp.add)((0, _fp.sum)(updateCosts.costs[key].cashflow), accum);

  let result = null; // Only if there more than one cost (initial row) should it calculate

  if (costObj.ids.length > 1) {
    result = (0, _fp.reduce)(calculateResult, 0, costObj.ids);
  }

  return (0, _fp.set)(resultLens, result, updateCosts);
};

exports.nonRecurringCosts = nonRecurringCosts;