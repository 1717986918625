"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toDate = exports.getYear = exports.dateInPast = exports.dateInFuture = exports.addToDate = void 0;

var _fp = require("./fp");

var _math = require("./math");

var _constants = require("./constants");

// wrap date constructor function in returned function this will only allow one parameter
const toDate = (0, _fp.constructN)(1, Date); // prettier-ignore

exports.toDate = toDate;
const dateInFuture = (0, _fp.converge)(_fp.lt, [Date.now, (0, _fp.pipe)(_fp.idiot, toDate)]);
exports.dateInFuture = dateInFuture;
const dateInPast = (0, _fp.converge)(_fp.gt, [Date.now, _fp.idiot]); // prettier-ignore
// takes 2 integers adds them and converts to date. Curried

exports.dateInPast = dateInPast;
const addToDate = (0, _fp.curryN)(2, (0, _fp.pipe)(_fp.add, toDate)); // prettier-ignore

exports.addToDate = addToDate;
const getYear = (0, _fp.pipe)(_fp.dec, (0, _math.divideBy)(_constants.monthsPerYear), Math.floor, _fp.inc, a => a > 0 ? a : 1);
exports.getYear = getYear;