import {
  omit,
  without,
  contains,
  map,
  layoutSelectors,
  sessionSelectors,
  layoutDesignerSelectors,
} from '@sharkfinesse/sfl-lib';
import { put, call, select, all } from 'redux-saga/effects';
import { actionCreators as undoredoTypesActionCreators } from 'redux/modules/undoredo';
import { actionCreators as evaluatorActionCreators } from 'redux/modules/evaluator';
import { actionCreators as cashflowActionCreators } from 'redux/modules/cashflow';
import { actionCreators as defaultSessionValuesActionCreators } from 'redux/modules/default-session-values';
import resetSelectedSuite from '../../layoutDesigner/resetSelectedSuite';
import setSelectedSuite from '../../layoutDesigner/setSelectedSuite';

function newCashFlow(state, suite) {
  return {
    ...state.cashflow,
    benefits: omit(suite.benefits, state.cashflow.benefits),
    editted_benefits: omit(suite.benefits, state.cashflow.editted_benefits),
  };
}

function newBenefits(state, suite, deleteCurrent) {
  return {
    ...state.benefits,
    ids: without(suite.benefits, state.benefits.ids),
    data: omit(suite.benefits, state.benefits.data),
    current:
      contains(state.benefits.current, suite.benefits) && deleteCurrent
        ? ''
        : state.benefits.current,
  };
}
function newDefaultSessionValues(state, suite, defaultSessionValues) {
  return {
    ...state.defaultSessionValues,
    linked: map(without(suite.benefits), state.defaultSessionValues.linked),
  };
}

function* deleteSuiteBenefits(action) {
  const { suiteId, deleteCurrent = false } = action.payload;

  const [selectedSuiteId, session, suite] = yield all([
    select(layoutDesignerSelectors.getSelectedSuiteId),
    select(sessionSelectors.getSession),
    select(layoutSelectors.getSuite, { suiteId }),
  ]);

  const [newCashflowState, newBenefitsState, newDefaultSessionValuesState] = yield all([
    call(newCashFlow, session, suite),
    call(newBenefits, session, suite, deleteCurrent),
    call(newDefaultSessionValues, session, suite),
  ]);
  yield put(undoredoTypesActionCreators.updateSuiteBenefitsDeleting());

  yield all([
    put(cashflowActionCreators.updateEvaluatorUpdateAll(newCashflowState)),
    put(evaluatorActionCreators.update(newBenefitsState)),
    put(defaultSessionValuesActionCreators.update({ state: newDefaultSessionValuesState })),
  ]);
  if (selectedSuiteId === suiteId) {
    yield call(resetSelectedSuite);
    yield call(setSelectedSuite);
  }
  yield put(undoredoTypesActionCreators.updateSuiteBenefitsDeletingComplete());
}

export default deleteSuiteBenefits;
