import { css } from 'styled-components';
import { setAlphaHsla } from '@sharkfinesse/sfl-lib';

const V = 0.54;
const Modal = ({ font, colors, scale, device: { max_xs, min_md, lg, min_lg } }) => ({
  iconSecondaryColor: colors.white,
  header: () => css`
    background: ${colors.white};
    border-radius: 3px 3px 0px 0px;
    color: ${font.color};
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    transition: box-shadow 200ms;
    z-index: 1;
  `,
  headerKeyline: () => css`
    box-shadow: 0 1px 0 0 ${colors.uiLight};
    background: ${colors.uiLightest};
    padding: ${scale.zero};
  `,
  positioner: () => css`
    display: flex;
    flex-direction: column;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    right: 0;
    max-width: calc(100% - 16px);
    height: calc(100vh - 16px);
    top: 8px;

    @media ${min_md} {
      max-width: calc(100% - 32px);
      top: 16px;
      height: calc(100vh - 32px);
    }
  `,
  container: () => css`
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08), 0 2px 1px rgba(0, 0, 0, 0.08),
      0 0 20px -6px rgba(0, 0, 0, 0.08);
    max-height: calc(100vh - 16px);
    @media ${min_md} {
      max-height: calc(100vh - 32px);
    }
  `,
  body: ({ showFooterKeyline, showHeaderKeyline, showFooter }) => css`
    overflow: hidden;
    background: ${colors.white};
    color: ${font.color};
    margin-top: ${showHeaderKeyline ? '1px' : 0};
    margin-bottom: ${showFooterKeyline ? '1px' : 0};
    position: relative;
    ${!showFooter &&
    css`
      border-radius: 0px 0px 3px 3px;
    `}
  `,
  footer: ({ showKeyline }) => css`
    background: ${colors.white};
    border-radius: 0px 0px 3px 3px;
    color: ${font.color};
    display: flex;
    justify-content: flex-end;
    ${showKeyline &&
    css`
      box-shadow: 0 -1px 0 0 ${colors.uiLight};
      background: ${colors.white};
      padding: ${scale.zero};
    `}
  `,

  blanket: () => css`
    background-color: ${setAlphaHsla(V, colors.uiDarkest)};
  `,
  bodyWrapper: () => css`
    position: relative;
    z-index: 2;
    padding: 20px 20px 0 20px;
    @media ${min_md} {
      padding: 30px 30px 0 30px;
    }
  `,
  title: () => css`
    align-items: center;
    display: flex;
    font-size: 20px;
    font-style: inherit;
    font-weight: 500;
    line-height: 1;
    margin: 0;
    min-width: 0;
  `,
  titleText: () => css`
    flex: 1 1 auto;
    min-width: 0;
    word-wrap: break-word;
    width: 100%;
  `,
  titleIconWrapper: () => css`
    flex: 0 0 auto;
    margin-right: 0px;
  `,
  bodyHeight: ({ maxHeight }) => css`
    height: calc(100vh - 200px);
    max-height: unset;
    @media ${min_md} {
      max-height: unset;
    }
    @media ${min_lg} {
      max-height: ${maxHeight};
    }
  `,
  fullHeight: () => css`
    height: calc(100vh - 156px);
  `,
  loadingWrapper: () => css`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${setAlphaHsla(0.8, colors.white)};
    z-index: 90;
  `,
  bodyPadding: () => css`
    position: relative;
    z-index: 2;
    padding: 20px 20px 20px 20px;
    @media ${min_md} {
      padding: 30px 30px 30px 30px;
    }
  `,
  footerSpaceBetween: () => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 4px;
  `,
  responsiveFooterSpaceBetween: () => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @media ${max_xs} {
      display: grid;
      grid-row-gap: 8px;
      grid-template-columns: 1fr;
    }
  `,
  maxHeight: () => css`
    @media ${min_lg} {
      max-height: 600px;
    }
  `,
});

export default Modal;
