import { css } from 'styled-components';
import styledBreakpoint from 'styled-components-breakpoint';
import { grid } from 'styled-components-grid';

const HeadlineFigure = ({ font, colors, scale }) => ({
  figuresWrapper: () => css`
    display: flex;
    flex-wrap: wrap;
    margin-top: ${scale.two};
    margin-bottom: ${scale.two};
  `,
  wrapper: size => css`
    display: inline-flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    ${styledBreakpoint('xxs', 'md')`

      padding-top: 15px;
      margin-top: 15px
      margin-bottom: 15px;
      &:first-child {
        border-top: none;
        padding-top: 0;
        margin-top: 0;
        margin-bottom: 16px;
      }
      `}
    ${styledBreakpoint('md')`
      border-left: 1px solid ${colors.uiLight};

      &:first-child {
        border-left: none;
      }
      &:nth-child(1) {
        border-left: none;
        padding-bottom: 35px;
      }
      &:nth-child(3) {
          border-top: 1px solid ${colors.uiLight};
        border-left: none;
        padding-top: 20px;
      }
      &:nth-child(4) {
          border-top: 1px solid ${colors.uiLight};
          padding-top: 20px;
      }
      `}
      ${grid.unit({ size: size })};
  `,
  value: () => css`
    font-size: 37px;
    color: ${colors.primary};

    ${styledBreakpoint('xxs', 'md')`
        font-size: 22px;
      `};
  `,
  label: () => css`
    font-size: 16px;
    font-weight: ${font.weightSemiBold};
    ${styledBreakpoint('xxs', 'md')`
      font-size: 14px;
      `};
  `,
  links: size => css`
    display: flex;
    flex-wrap: wrap;
    font-size: ${font.sizeNormal};
    margin-top: 8px;

    span {
      padding: 0 8px;
      border-left: 1px solid ${colors.uiLight};
      line-height: 16px;
      &:first-child {
        border-left: none;
      }
    }
  `,
});

export default HeadlineFigure;
