/* @flow */
/* eslint-disable id-length, id-match, filenames/match-regex */
/**
 * External dependencies
 */
import React from 'react';
import styled from 'styled-components';

const StyledH1 = styled.h1`
  font-size: ${({ theme, color }) => {
    return theme[`headingH1${color}`].fontSize;
  }};
  font-weight: ${({ theme, color }) => theme[`headingH1${color}`].fontWeight};
  color: ${({ theme, color }) => theme[`headingH1${color}`].fontColor};
  margin: 0;
  line-height: 1.3em;
`;

export type Props = {
  color?: string,
  children?: React.Element<any>,
};

const H1 = ({ children, color, ...props }: Props): React.Element<any> => (
  <StyledH1 color={color} {...props}>
    {children}
  </StyledH1>
);

export default H1;
