/* @flow */
import React from 'react';
import type { Element } from 'react';
import { CheckboxContainer, HiddenCheckbox, Icon, StyledCheckbox, Label } from './styled';

type Props = {
  className: String,
  checked: Boolean,
  indeterminate: Boolean,
  label: String,
  onChange: Boolean,
  round: Boolean,
  color: String,
};

const Checkbox = ({
  className,
  checked = false,
  indeterminate = false,
  label,
  onChange,
  round,
  color,
  disabled,
  ...props
}: Props): Element<any> => (
  <CheckboxContainer className={className} checked={checked} round={round}>
    <HiddenCheckbox checked={checked} onChange={onChange} disabled={disabled} {...props} />
    <StyledCheckbox checked={checked} round={round} indeterminate={indeterminate} color={color}>
      {!indeterminate && (checked || round) && (
        <Icon viewBox="0 0 24 24" round={round} checked={checked}>
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      )}
    </StyledCheckbox>
    {label && (
      <Label
        onClick={() => {
          if (!disabled) onChange();
        }}
      >
        {label}
      </Label>
    )}
  </CheckboxContainer>
);

export default Checkbox;
