import { css } from 'styled-components';
import styledBreakpoint from 'styled-components-breakpoint';

const Preferences = ({ font, colors, scale }) => ({
  tabBody: () => css`
    padding: ${scale.two};
    background: transparent !important;
  `,
  reportPreviewLeftColumn: ({ narrow }) => css`
    display: flex;
    ${styledBreakpoint('xxs')`
    justify-content: center;
    padding-right: 0;
    `};
    ${styledBreakpoint('sm')`
  padding-right: ${narrow ? 0 : '4px'};
  justify-content: ${narrow ? 'center' : 'flex-end'};
  `};
  `,
  reportPreviewRightColumn: ({ narrow }) => css`
    display: flex;
    ${styledBreakpoint('xxs')`
      justify-content: center;
      padding-left: 0;
    `};
    ${styledBreakpoint('sm')`
      padding-left: ${narrow ? 0 : '4px'};
      justify-content: ${narrow ? 'center' : 'flex-start'};
  `};
  `,
  reportPreviewPage: () => css`
    position: relative;
    border: 1px solid rgb(0 0 0 / 0.1);
    margin-bottom: 10px;

    background-color: white;
    border-radius: 1px;
    box-shadow: 0 8px 6px -6px rgb(0 0 0 / 0.2);

    ${styledBreakpoint('xxs')`
      max-width: 180px;
      min-width: unset;
      width: 100%;
    `};
    ${styledBreakpoint('xs')`
      max-width: 180px;
      min-width: 180px;
  `};
  `,
  reportPreviewTextBlock: ({ themeStyle: { color, font, size, bold = false } }) => css`
    margin-top: 0;
    margin-bottom: 8px;
    line-height: 14px;
    color: #${color};
    font-size: ${size / 2.2}px;
    font-weight: ${bold ? 'bold' : 'normal'};
    font-family: ${font};
  `,
  reportPreviewRow: ({ borderTop, borderBottom, border, rowBackground }) => css`
    display: flex;
    display-direction: column;
    border-color: ${border};
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-top-style: ${borderTop ? 'solid' : 'none'};
    border-bottom-style: ${borderBottom ? 'solid' : 'none'};
    background-color: ${rowBackground ? rowBackground : 'white'};
  `,
  reportPreviewCell: ({ borderRight, border }) => css`
    display: flex;
    flex-direction: row;
    height: 22px;
    align-items: center;
    border-color: ${border};
    border-right-width: 1px;
    border-right-style: ${borderRight ? 'solid' : 'none'};
  `,
  reportPreviewHeader: ({ borderRight, border }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
  `,
  reportPreviewLogo: ({ borderRight, border }) => css`
    max-width: 50px;
  `,
  reportPreviewBody: ({ borderRight, border }) => css`
    padding: 6px 20px;
    position: relative;
    padding-bottom: 26px;
  `,
  reportPreviewFooter: ({ borderRight, border }) => css`
    padding: 0 10px;
    position: absolute;
    bottom: -5px;
    display: flex;
  `,
  reportThemeSection: () => css`
    padding-bottom: 10px;
    margin-top: 10px;
    background: ${colors.uiLightest};
  `,
  h2Title: () => css`
    font-size: 18px;
    font-weight: 400;
    margin: 0 0 8px 0;
  `,
});

export default Preferences;
