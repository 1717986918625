/* @flow */
/* eslint-disable max-len */
import React, { memo } from 'react';
import Icon from '../icon';

let IconReorder = (props): React.Element<any> => (
  <Icon {...props}>
    <g fill="currentColor" fillRule="evenodd">
      <rect x="2" y="7" width="20" height="2" />
      <rect x="2" y="11" width="20" height="2" />
      <rect x="2" y="15" width="20" height="2" />
      <polygon points="8 5 12 2 16 5 8 5" />
      <polygon points="16 19 12 22 8 19 16 19" />
    </g>
  </Icon>
);

export default memo(IconReorder);
