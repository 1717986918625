/* @flow */
import {
  simpleAction,
  createReducer,
  simpleReducer,
  persistenceInitialState,
} from '@sharkfinesse/sfl-lib';
const namespace = (name: string): string => `@@persistence/${name}`;

// types
export const types = {
  UNSAVED_CHANGES: namespace('UNSAVED_CHANGES'),
  SERVER_SAVE: {
    REQUEST: namespace('SERVER_SAVE.REQUEST'),
    SUCCESS: namespace('SERVER_SAVE.SUCCESS'),
    FAILURE: namespace('SERVER_SAVE.FAILURE'),
  },
  SERVER_LOAD: {
    REQUEST: namespace('SERVER_LOAD.REQUEST'),
    SUCCESS: namespace('SERVER_LOAD.SUCCESS'),
    FAILURE: namespace('SERVER_LOAD.FAILURE'),
  },
};

//Reducer
const initialState = persistenceInitialState;

const reduceUnsavedChanges = simpleReducer('unsavedChanges');

export default createReducer(
  {
    [types.UNSAVED_CHANGES]: reduceUnsavedChanges,
  },
  initialState
);

//Action Creators
const signalUnsavedChanges = simpleAction(types.UNSAVED_CHANGES);
export const serverSave = {
  request: () => ({ type: types.SERVER_SAVE.REQUEST }),
  success: () => ({ type: types.SERVER_SAVE.SUCCESS }),
  failure: () => {
    return { type: types.SERVER_SAVE.FAILURE };
  },
};
export const serverLoad = {
  request: () => {
    return { type: types.SERVER_LOAD.REQUEST };
  },
  success: simpleAction(types.SERVER_LOAD.SUCCESS),
  failure: () => {
    return { type: types.SERVER_LOAD.FAILURE };
  },
};

export const actionCreators = {
  signalUnsavedChanges,
  serverSave,
  serverLoad,
};
