// @flow
import React, { useEffect, useState } from 'react';
import { getPolicy } from 'redux/firestore';
import ReactMarkdown from 'react-markdown';
import { LoaderRing } from '@sharkfinesse/sfl-ui';

function usePolicy({ id, language, collection }) {
  const [result, setResult] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const policy = await getPolicy({ id, language, collection });

        if (!policy.empty) {
          if (policy.docs.length > 1) {
            policy.forEach(doc => {
              if (doc.id === language) {
                setResult(doc.data());
              }
            });
          } else {
            setResult(policy.docs[0].data());
          }
        }
      } catch (error) {
        setResult({ body: 'Sorry an error has occured.' });
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [id, language, collection]);

  return isLoading ? (
    <div style={{ justifyContent: 'center', display: 'flex' }}>
      <LoaderRing />
    </div>
  ) : (
    <ReactMarkdown>{result.body}</ReactMarkdown>
  );
}

export default usePolicy;
