import { css } from 'styled-components';
import { setAlphaHsla } from '@sharkfinesse/sfl-lib';
import { margin } from 'polished';

const T = 0.8;
const Evaluator = ({ font, colors, scale, device: { max_xxs, min_xxl, ultrawide } }) => ({
  base: () => css`
    position: relative;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 100px;
    height: 100%;
    background: ${colors.uiLightest};
    fieldset {
      min-width: 0;
      padding: 0;
      margin: 0;
      border: 0;
    }
  `,
  section: () => css`
    padding: 0 0 ${scale.minusOne} 0;
  `,
  subSection: () => css`
    padding: ${scale.minusOne} 0 0 0;
  `,
  header: () => css`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 0 0 0;
    background: transparent;
  `,
  headings: () => css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  headingWrapper: () => css`
    display: flex;
    flex-direction: row;
  `,
  headingToolbar: () => css`
    display: flex;
    flex-direction: row;
    margin: 2px;
  `,
  heading: () => css`
    color: ${colors.uiDark};
    line-height: ${font.lineHeightNormal};
    font-size: ${font.sizeLarge};
    font-weight: ${font.weightBold};
    margin: -0.25em 0 0 0;
    padding: ${scale.minusOne} ${scale.minusOne} 0 ${scale.minusOne};
    width: 100%;
  `,
  backButton: {
    background: 'transparent',
    iconSize: '5rem',
    iconColor: colors.uiDark,
    width: '20px',
    weight: '20px',
    margin: margin(0, '18px', 0, 0),
  },
  subHeading: () => css`
    display: flex;
    width: 100%;
    font-size: ${font.sizeNormal};
    font-weight: ${font.weightNormal};
    color: ${setAlphaHsla(T, colors.uiDark)};
    padding: 0 ${scale.minusOne} 0 1em;
    margin-bottom: 0.6em;
  `,
  contents: () => css`
    height: 100%;
    width: 100%;
    padding: ${scale.minusOne};
  `,

  info: () => css`
    h3 {
      margin: 0;
      font-size: ${font.sizeNormal};
      font-weight: ${font.weightSemiBold};
    }
    p {
      margin-top: 0;
    }
  `,
  fieldset: () => css`
    border-top: 1px solid ${colors.uiLight} !important;
    border-bottom: 1px solid ${colors.uiLight} !important;
    padding-bottom: 10px !important;
    margin-bottom: 14px !important;
  `,
  legend: () => css`
    font-family: ${font.family};
    font-size: ${font.sizeNormal};
    font-weight: ${font.weightSemiBold};
    background: ${colors.white};
    padding-right: 10px;
  `,
  notesWrapper: () => css`
    margin-top: ${scale.minusTwo};
  `,
  chartsContainer: () => css`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  chartsWrapper: () => css`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    overflow: hidden;
    row-gap: 15px;
    @media ${max_xxs} {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  `,
  chartContainer: () => css`
    display: flex;
    flex-direction: column;
    height: 250px;
    width: 100%;
  `,
  completeWrapper: () => css`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: center;
  `,
  chartPanel: () => css`
    height: 370px;
    overflow-x: visible;
    max-width: unset;
  `,
  lineChartPanel: () => css`
    margin: 0 !important;
  `,
  drawerColumn: () => css`
    padding: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-column-gap: 20px;
    position: relative;
    width: 100%;

    @media ${min_xxl} {
      grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    }
    @media ${ultrawide} {
      grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
    }
  `,
  arrowIcon: ({ expanded }) => css`
    transition: transform 0.3s;
    transform: ${expanded ? 'none' : 'rotate(180deg)'};
  `,
  chartHeading: () => css`
    font-size: ${font.sizeNormal};
    font-weight: ${font.weightSemiBold};
    color: ${colors.uiDark};
    margin: 0;
    text-align: center;
    margin-bottom: 16px;
  `,
  toggleLabel: () => css`
    font-size: 12px;
    margin-left: 6px;
    font-weight: 400;
  `,
  toggleButton: () => css`
    margin-top: 10px;
    display: flex;
    justify-content: center;
  `,
  drawerContainer: () => css`
    grid-row-start: 1;
    grid-column-start: 1;
    position: relative;
    pointer-events: none;
    height: 100%;
    display: flex;
    flex-direction: revert;
    width: 100%;
    justify-content: flex-end;
    z-index: 3;
  `,
  drawerShadow: () => css`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 16px;
    background: linear-gradient(to left, rgb(0 0 0 / 6%) 0%, rgba(0, 0, 0, 0) 100%);
    z-index: 3;
    pointer-events: none;
  `,
  drawerToggleContainer: () => css`
    display: flex;
    width: 100%;
  `,
  drawerEdge: () => css`
    height: 100%;
    width: 20px;
    margin-left: -20px;
    z-index: 4;
    border-right: 1px solid ${colors.uiLight};
  `,
  evaluatorFormPanel: () => css`
    fieldset {
      min-width: 0;
      padding: 0;
      margin: 0;
      border: 0;
      display: grid;
      grid-row-gap: 8px;
    }
  `,
});

export default Evaluator;
