"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.weeksPerYear = exports.tonsToKgRatio = exports.sqMileToM2Ratio = exports.sqInchToM2Ratio = exports.secondsPerMonth = exports.secondsPerMinute = exports.secondsPerHour = exports.secondsPerFteDay = exports.secondsPerDay = exports.poundsToKgRatio = exports.percentageToDecimalFactor = exports.oneHundredPercent = exports.monthsPerYear = exports.mmscfdToM3PerSecRatio = exports.mmscfdToM3PerHourRatio = exports.mmbtuToJoulesRatio = exports.minutesPerHour = exports.minutesPerFteDay = exports.minReviewPeriod = exports.milesToMetresRatio = exports.maxReviewPeriod = exports.mToFtRatio = exports.litreToM3Ratio = exports.inchesToMetresRatio = exports.hoursPerYear = exports.hoursPerFteYear = exports.hoursPerFteMonth = exports.hoursPerFteDay = exports.hoursPerDay = exports.gallonToM3Ratio = exports.fteDaysPerYear = exports.fteDaysPerWeek = exports.ft3ToM3Ratio = exports.floatingFixedPointFactor = exports.feetToMetresRatio = exports.defaultStartMonth = exports.defaultReviewPeriod = exports.defaultDecimalPlaces = exports.daysPerYear = exports.daysPerWeek = exports.daysPerMonth = exports.carbonEmissionFactorKgPerKwh = exports.base10 = void 0;
const oneHundredPercent = 100;
exports.oneHundredPercent = oneHundredPercent;
const minReviewPeriod = 1;
exports.minReviewPeriod = minReviewPeriod;
const maxReviewPeriod = 360;
exports.maxReviewPeriod = maxReviewPeriod;
const defaultDecimalPlaces = 0;
exports.defaultDecimalPlaces = defaultDecimalPlaces;
const defaultReviewPeriod = 36;
exports.defaultReviewPeriod = defaultReviewPeriod;
const base10 = 10;
exports.base10 = base10;
const floatingFixedPointFactor = 1000000;
exports.floatingFixedPointFactor = floatingFixedPointFactor;
const monthsPerYear = 12;
exports.monthsPerYear = monthsPerYear;
const hoursPerDay = 24;
exports.hoursPerDay = hoursPerDay;
const hoursPerFteDay = 7500000;
exports.hoursPerFteDay = hoursPerFteDay;
const minutesPerHour = 60;
exports.minutesPerHour = minutesPerHour;
const daysPerMonth = 30440000;
exports.daysPerMonth = daysPerMonth;
const daysPerYear = 365;
exports.daysPerYear = daysPerYear;
const daysPerWeek = 7;
exports.daysPerWeek = daysPerWeek;
const secondsPerMinute = 60;
exports.secondsPerMinute = secondsPerMinute;
const fteDaysPerYear = 225000000;
exports.fteDaysPerYear = fteDaysPerYear;
const weeksPerYear = 52;
exports.weeksPerYear = weeksPerYear;
const fteDaysPerWeek = 5;
exports.fteDaysPerWeek = fteDaysPerWeek;
const mToFtRatio = 304800;
exports.mToFtRatio = mToFtRatio;
const secondsPerHour = secondsPerMinute * minutesPerHour;
exports.secondsPerHour = secondsPerHour;
const secondsPerDay = secondsPerHour * hoursPerDay;
exports.secondsPerDay = secondsPerDay;
const secondsPerMonth = secondsPerDay * daysPerMonth / floatingFixedPointFactor;
exports.secondsPerMonth = secondsPerMonth;
const percentageToDecimalFactor = floatingFixedPointFactor * oneHundredPercent;
exports.percentageToDecimalFactor = percentageToDecimalFactor;
const hoursPerFteYear = fteDaysPerYear * hoursPerFteDay / floatingFixedPointFactor;
exports.hoursPerFteYear = hoursPerFteYear;
const hoursPerFteMonth = hoursPerFteYear / monthsPerYear;
exports.hoursPerFteMonth = hoursPerFteMonth;
const secondsPerFteDay = hoursPerFteDay * secondsPerHour;
exports.secondsPerFteDay = secondsPerFteDay;
const minutesPerFteDay = hoursPerFteDay * minutesPerHour;
exports.minutesPerFteDay = minutesPerFteDay;
const carbonEmissionFactorKgPerKwh = 0.527;
exports.carbonEmissionFactorKgPerKwh = carbonEmissionFactorKgPerKwh;
const hoursPerYear = daysPerYear * hoursPerDay;
exports.hoursPerYear = hoursPerYear;
const defaultStartMonth = 1; //

exports.defaultStartMonth = defaultStartMonth;
const gallonToM3Ratio = 3785411784;
exports.gallonToM3Ratio = gallonToM3Ratio;
const litreToM3Ratio = 1000000000;
exports.litreToM3Ratio = litreToM3Ratio;
const tonsToKgRatio = 907184740000000;
exports.tonsToKgRatio = tonsToKgRatio;
const poundsToKgRatio = 453592000000;
exports.poundsToKgRatio = poundsToKgRatio;
const milesToMetresRatio = 1609344000000000;
exports.milesToMetresRatio = milesToMetresRatio;
const feetToMetresRatio = 304800000000;
exports.feetToMetresRatio = feetToMetresRatio;
const inchesToMetresRatio = 25400000000;
exports.inchesToMetresRatio = inchesToMetresRatio;
const sqMileToM2Ratio = 25899881000000000000;
exports.sqMileToM2Ratio = sqMileToM2Ratio;
const sqInchToM2Ratio = 645160000;
exports.sqInchToM2Ratio = sqInchToM2Ratio;
const mmbtuToJoulesRatio = 1055060000000000;
exports.mmbtuToJoulesRatio = mmbtuToJoulesRatio;
const mmscfdToM3PerHourRatio = 1177170000000000;
exports.mmscfdToM3PerHourRatio = mmscfdToM3PerHourRatio;
const mmscfdToM3PerSecRatio = 326991000000;
exports.mmscfdToM3PerSecRatio = mmscfdToM3PerSecRatio;
const ft3ToM3Ratio = 28316800000;
exports.ft3ToM3Ratio = ft3ToM3Ratio;