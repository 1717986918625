/* @flow */
import React, { memo } from 'react';
import styled from 'styled-components';
import Icon from '../icon';
import { prop } from 'styled-tools';

const StyledPath = styled.path`
  fill: ${prop('color')};
`;

type DefaultProps = {
  color: string,
};

export type Props = DefaultProps & {
  color?: string,
  ...other,
};

let IconFolderOpen = ({ color, ...other }: Props): React.Element<any> => (
  <Icon {...other}>
    <StyledPath
      color={color}
      d="M1008.4,374.9c-10.7-12.7-25.8-19.7-42.3-19.7h-41.2v-87.1c0-33.9-27.6-61.5-61.5-61.5H462.9L380.3,91H61.6
	C27.6,91,0,118.6,0,152.6V874l0.1,16.5h2.2c2,7,5.3,13.6,9.8,19.5C23.3,924.6,40.3,933,58.8,933h739.4c25.9,0,49.3-17.3,56.2-41.3
	L1024,426.6v-5.9C1024,403.1,1018.5,386.8,1008.4,374.9z M955.8,421.1L793.2,867.1H69.1l162.6-446.1H955.8z M170.6,396.6L66,681.9
	V157h280.4l82.6,115.5h430v82.7H226.7c-3.4,0-6.6,0.3-9.8,0.8C194.6,359.8,176.5,375.7,170.6,396.6z"
    />
  </Icon>
);

IconFolderOpen.defaultProps = {
  color: 'white',
};

export default memo(IconFolderOpen);
