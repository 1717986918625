import { types as sessionDetailsTypes } from 'redux/modules/session-details';
import { types as benefitTypes } from 'redux/modules/evaluator';
import { types as nonRecurringCostsTypes } from 'redux/modules/non-recurring-costs';
import { types as recurringCostsTypes } from 'redux/modules/recurring-costs';
import { types as preferencesLanguageTypes } from 'redux/modules/preferences/language';
import { types as preferencesBusinessPreparationTypes } from 'redux/modules/preferences/businessPreparation';
import { types as preferencesAnalyticsTypes } from 'redux/modules/preferences/analytics';
import { types as preferencesDebuggingTypes } from 'redux/modules/preferences/debugging';
import { types as businessCaseTypes } from 'redux/modules/business-case';
import { types as layoutTypes } from 'redux/modules/layout';
import { types as businessPreparationTypes } from 'redux/modules/business-preparation';
import { types as preferencesThemeTypes } from 'redux/modules/preferences/theme';
import { types as preferencesSessionDetailsTypes } from 'redux/modules/preferences/session';
import { types as preferencesReportThemeTypes } from 'redux/modules/preferences/reportTheme';
import { types as preferencesDocxTemplateTypes } from 'redux/modules/preferences/docxTemplate';
import { types as preferencesPptxTemplateTypes } from 'redux/modules/preferences/pptxTemplate';
import { types as settingsBenefitsTypes } from 'redux/modules/settings/benefits';
import { types as settingsSessionsTypes } from 'redux/modules/settings/sessions';
import { types as settingsUiTypes } from 'redux/modules/settings/ui';
import { types as sessionTypes } from 'redux/modules/session';
import { types as suiteListTypes } from 'redux/modules/suite-list';
import { types as cashflowTypes } from 'redux/modules/cashflow';
import { types as defaultSessionValuesTypes } from 'redux/modules/default-session-values';
import { types as delayTypes } from 'redux/modules/delay';
import { types as tenderTypes } from 'redux/modules/tender';
import { types as doNothingTypes } from 'redux/modules/do-nothing';
import { types as preferencesAutoImageTypes } from 'redux/modules/preferences/auto-image';
import { types as preferencesDefaultSessionImageTypes } from 'redux/modules/preferences/default-session-image';
import { types as salesforcePluginTypes } from 'redux/modules/plugins/salesforce-session';
import { types as sessionQuestionnaireTypes } from 'redux/modules/questionnaire';
import { types as questionnaireTypes } from 'redux/modules/questionnaire/questionnaire';

export const Whitelist = {
  [sessionTypes.UNDO]: null,
  [sessionTypes.REDO]: null,
  [sessionTypes.SAVE]: null,
  [sessionDetailsTypes.DEAL_NAME.UPDATE]: 'dealName',
  [sessionDetailsTypes.CUSTOMER_NAME.UPDATE]: 'customerName',
  [sessionDetailsTypes.SUPPLIER_NAME.UPDATE]: 'supplierName',
  [sessionDetailsTypes.MINIMUM_RETURN.UPDATE]: 'minimumReturn',
  [sessionDetailsTypes.REVIEW_PERIOD.UPDATE]: 'reviewPeriod',
  [sessionDetailsTypes.INDUSTRY.UPDATE]: 'industry',
  [sessionDetailsTypes.NOTES.UPDATE]: 'notes',
  [sessionDetailsTypes.CURRENCY.UPDATE]: 'currency',
  [sessionDetailsTypes.NUMBER_FORMAT.UPDATE]: 'numberFormat',
  [sessionDetailsTypes.DATE_FORMAT.UPDATE]: 'dateFormat',
  [sessionDetailsTypes.MONTHLY_BENEFIT_CHART_ORDER.UPDATE]: 'monthlyBenefitChartOrder',
  [sessionDetailsTypes.ONE_TIME_BENEFIT_CHART_ORDER.UPDATE]: 'oneTimeBenefitChartOrder',
  [sessionDetailsTypes.TOTAL_BENEFIT_CHART_ORDER.UPDATE]: 'totalBenefitChartOrder',
  [sessionDetailsTypes.RECURRING_COSTS_CHART_ORDER.UPDATE]: 'recurringCostsChartOrder',
  [sessionDetailsTypes.NON_RECURRING_COSTS_CHART_ORDER.UPDATE]: 'nonRecurringCostsChartOrder',
  [sessionDetailsTypes.SESSION_MEDIA.UPDATE]: 'media',
  [sessionDetailsTypes.SESSION_MEDIA.UPDATE_POSITION]: 'media.position',
  [sessionDetailsTypes.DEFAULT_START_MONTH.COMPLETE]: null,
  [benefitTypes.SUBHEADING.UPDATE]: 'subHeading',
  [benefitTypes.NOTES.UPDATE]: 'notes',
  [benefitTypes.PRIVATE_NOTES.UPDATE]: 'privateNotes',
  [benefitTypes.CURRENT.UPDATE]: 'current',
  [benefitTypes.USER_TITLE]: 'userTitle',
  [benefitTypes.STARTMONTH_LINKED_FLAG.UPDATE]: 'startMonthLinkedFlag',
  [benefitTypes.ENDMONTH_LINKED_FLAG.UPDATE]: 'endMonthLinkedFlag',
  [benefitTypes.SHOW_MODIFIERS.UPDATE]: 'showModifiers',
  [defaultSessionValuesTypes.DELETE.EVAL]: null,
  [defaultSessionValuesTypes.CALCULATE]: null,
  [defaultSessionValuesTypes.PRISTINE_CHANGE]: null,
  [defaultSessionValuesTypes.LINK_INPUT_COMPLETE]: null,
  [defaultSessionValuesTypes.UNLINK_INPUT]: null,
  [defaultSessionValuesTypes.CHANGE]: null,
  [defaultSessionValuesTypes.UPDATE]: null,
  [defaultSessionValuesTypes.DELETE.ALL]: null,
  [defaultSessionValuesTypes.UPDATE_COMPLETE]: null,
  [layoutTypes.BENEFIT.INSERTING_COMPLETE]: null,
  [layoutTypes.BENEFIT.DUPLICATING_COMPLETE]: null,
  [layoutTypes.BENEFIT.DO_DELETE]: null,
  [layoutTypes.BENEFIT.UNDO_DELETE]: null,
  [layoutTypes.BENEFIT.UPDATE_ORDER]: null,
  [layoutTypes.SUITE.UPDATE_ORDER]: null,
  [layoutTypes.SUITE.UPDATE_CUSTOM_NAME]: null,
  [layoutTypes.SUITE.INSERT]: null,
  [layoutTypes.SUITE.DUPLICATING_COMPLETE]: null,
  [layoutTypes.SUITE.DELETE_COMPLETE]: null,
  [layoutTypes.SEARCH]: null,
  [layoutTypes.FILTERS]: null,
  [suiteListTypes.VISIBLE]: null,
  [cashflowTypes.EVALUATOR.INSERT]: null,
  [cashflowTypes.COSTS.RESET]: null,
  [cashflowTypes.COSTS.RECURRING.CALCULATE]: null,
  [cashflowTypes.COSTS.NONRECURRING.CALCULATE]: null,
  [nonRecurringCostsTypes.DESCRIPTION.CHANGE]: null,
  [nonRecurringCostsTypes.TYPE.CHANGE]: null,
  [nonRecurringCostsTypes.CALCULATE]: null,
  [nonRecurringCostsTypes.CASHFLOW.SHOW]: 'showCashflow',
  [nonRecurringCostsTypes.ROW.DYNAMIC_ADD]: null,
  [nonRecurringCostsTypes.ROW.REORDER]: 'ids',
  [nonRecurringCostsTypes.NOTES.CHANGE]: 'notes',
  [recurringCostsTypes.DESCRIPTION.CHANGE]: null,
  [recurringCostsTypes.TYPE.CHANGE]: null,
  [recurringCostsTypes.CALCULATE]: null,
  [recurringCostsTypes.CASHFLOW.SHOW]: 'showCashflow',
  [recurringCostsTypes.ROW.DYNAMIC_ADD]: null,
  [recurringCostsTypes.ROW.REORDER]: 'ids',
  [recurringCostsTypes.NOTES.CHANGE]: 'notes',
  [recurringCostsTypes.END.DEAL_PERIOD_CHANGE]: null,
  [salesforcePluginTypes.ACCOUNT.UPDATE]: null,
  [salesforcePluginTypes.ACCOUNT_NAME.UPDATE]: null,
  [salesforcePluginTypes.OPPORTUNITY.UPDATE]: null,
  [salesforcePluginTypes.OPPORTUNITY_NAME.UPDATE]: null,
  [salesforcePluginTypes.OPPORTUNITY_OBJECT_ID.UPDATE]: null,
  [salesforcePluginTypes.RESET]: null,
  [sessionQuestionnaireTypes.BENEFITS.ADD]: null,
  [sessionQuestionnaireTypes.BENEFITS.REMOVE]: null,
  [sessionQuestionnaireTypes.SHOW_NOTES]: null,
  [sessionQuestionnaireTypes.SHOW_VALUES]: null,
  [sessionQuestionnaireTypes.SHOW_INFO]: null,
  [sessionQuestionnaireTypes.NOTIFY]: null,
  [sessionQuestionnaireTypes.EXPIRY]: null,
  [sessionQuestionnaireTypes.MESSAGE]: null,
  [sessionQuestionnaireTypes.COMMON_NOTES]: null,
  [sessionQuestionnaireTypes.EMAILS]: null,
  [sessionQuestionnaireTypes.LANGUAGE]: null,
  [questionnaireTypes.BENEFIT_DATA.SUBMITTED]: null,
  [questionnaireTypes.DEFAULT_SESSION_VALUES.SUBMITTED]: null,
  [questionnaireTypes.SUBMIT.UPDATE]: null,
  [questionnaireTypes.TERMS_ACCEPTED.SUBMIT]: null,
  [preferencesSessionDetailsTypes.CURRENCY.UPDATE]: null,
  [preferencesSessionDetailsTypes.NUMBER_FORMAT.UPDATE]: null,
  [preferencesSessionDetailsTypes.DATE_FORMAT.UPDATE]: null,
  [preferencesSessionDetailsTypes.DEFAULT_START_MONTH.UPDATE]: null,
  [preferencesThemeTypes.THEME.UPDATE]: null,
  [preferencesLanguageTypes.LANGUAGE.UPDATE]: null,
  [preferencesBusinessPreparationTypes.BEHIND_CALCS.VISIBLE]: null,
  [preferencesBusinessPreparationTypes.VALUES.VISIBLE]: null,
  [preferencesBusinessPreparationTypes.NOTES.VISIBLE]: null,
  [preferencesDocxTemplateTypes.UPDATE]: null,
  [preferencesPptxTemplateTypes.UPDATE]: null,
  [preferencesAnalyticsTypes.ENABLED]: null,
  [preferencesDebuggingTypes.ENABLED]: null,
  [preferencesAutoImageTypes.ENABLED]: null,
  [preferencesReportThemeTypes.UPDATE]: null,
  [preferencesDefaultSessionImageTypes.SESSION_MEDIA.UPDATE]: null,
  [preferencesDefaultSessionImageTypes.SESSION_MEDIA.UPDATE_POSITION]: null,
  [businessPreparationTypes.SELECTED_BENEFITS.UPDATE]: null,
  [settingsBenefitsTypes.FILTERS.VISIBLE]: null,
  [settingsUiTypes.RECENT_SESSIONS.VISIBLE]: null,
  [settingsUiTypes.SHARED_SESSIONS.VISIBLE]: null,
  [settingsUiTypes.HOME_HERO.VISIBLE]: null,
  [settingsSessionsTypes.OFFLINE.ADD]: null,
  [settingsSessionsTypes.OFFLINE.REMOVE]: null,
  [businessCaseTypes.NPV.UPDATE]: null,
  [businessCaseTypes.IRR.UPDATE]: null,
  [businessCaseTypes.PAYBACK.UPDATE]: null,
  [businessCaseTypes.NON_RECURRING_COSTS.UPDATE]: null,
  [businessCaseTypes.RECURRING_COSTS.UPDATE]: null,
  [businessCaseTypes.BENEFITS.UPDATE]: null,
  [businessCaseTypes.TENDER.UPDATE]: null,
  [businessCaseTypes.DONOTHING.UPDATE]: null,
  [businessCaseTypes.DELAY.UPDATE]: null,
  [businessCaseTypes.NPV_CASHFLOW.UPDATE]: null,
  [businessCaseTypes.NPV_CHART.UPDATE]: null,
  [businessCaseTypes.IRR_CASHFLOW.UPDATE]: null,
  [businessCaseTypes.IRR_CHART.UPDATE]: null,
  [businessCaseTypes.PAYBACK_CHART.UPDATE]: null,
  [businessCaseTypes.NON_RECURRING_COSTS_CHART.UPDATE]: null,
  [businessCaseTypes.RECURRING_COSTS_CHART.UPDATE]: null,
  [businessCaseTypes.BENEFITS_MONTHLY_CHART.UPDATE]: null,
  [businessCaseTypes.BENEFITS_OTB_CHART.UPDATE]: null,
  [businessCaseTypes.CASH_ONLY_SUMMARY.UPDATE]: null,
  [businessCaseTypes.TENDER_CHART.UPDATE]: null,
  [businessCaseTypes.DELAY_CHART.UPDATE]: null,
  [businessCaseTypes.BENEFITS_MONTHLY.UPDATE]: null,
  [businessCaseTypes.BENEFITS_ONETIME.UPDATE]: null,
  [businessCaseTypes.COSTS.UPDATE]: null,
  [businessCaseTypes.ROI.UPDATE]: null,
  [businessCaseTypes.COST_OF.UPDATE]: null,
  [businessCaseTypes.PREFERENCE.UPDATE]: null,
  [businessCaseTypes.CASHFLOWS.UPDATE]: null,
  [businessCaseTypes.CASHFLOWS_ROI.UPDATE]: null,
  [businessCaseTypes.CHARTING.UPDATE]: null,
  [businessCaseTypes.CHARTING_BENEFITS.UPDATE]: null,
  [businessCaseTypes.CHARTING_COSTS.UPDATE]: null,
  [businessCaseTypes.CHARTING_ROI.UPDATE]: null,
  [businessCaseTypes.CHARTING_COST_OF.UPDATE]: null,
  [businessCaseTypes.COVERPAGE.UPDATE]: null,
  [delayTypes.DELAY_PERIOD.UPDATE]: null,
  [tenderTypes.TENDER_PERIOD.UPDATE]: null,
  [tenderTypes.STAFF_ENGAGED.UPDATE]: null,
  [tenderTypes.ANNUAL_LOADED.UPDATE]: null,
  [tenderTypes.OTHER_COSTS.UPDATE]: null,
  [tenderTypes.IS_USED]: null,
  [tenderTypes.IS_DIRTY]: null,
  [tenderTypes.IS_COLLAPSED]: null,
  [tenderTypes.DELETE_ALL]: null,
  [delayTypes.IS_USED]: null,
  [delayTypes.IS_DIRTY]: null,
  [delayTypes.IS_COLLAPSED]: null,
  [delayTypes.DELETE_ALL]: null,
  [doNothingTypes.IS_USED]: null,
  [doNothingTypes.IS_COLLAPSED]: null,
  [benefitTypes.MULTI_YEAR_VALUES.UPDATE]: 'MULTI_YEAR_VALUES.UPDATE',
};

export function getPeristenceField(type) {
  return Whitelist[type] || null;
}
export default Whitelist;
