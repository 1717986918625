"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

const getToken = state => state.plugins.salesforce.token;

const getInstanceUrl = state => state.plugins.salesforce.instanceUrl;

const getOutputLongform = state => state.plugins.salesforce.outputLongform;

const getOutputPpt = state => state.plugins.salesforce.outputPpt;

const getOutputXlsx = state => state.plugins.salesforce.outputXlsx;

const selectors = {
  getToken,
  getInstanceUrl,
  getOutputLongform,
  getOutputPpt,
  getOutputXlsx
};
var _default = selectors;
exports.default = _default;