/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon viewBox="0 0 58 58" {...props}>
    <circle fill="inherit" opacity="0.8" cx="29" cy="29" r="29" />
    <path
      fill="currentColor"
      d="M29,2c14.9,0,27,12.1,27,27S43.9,56,29,56S2,43.9,2,29S14.1,2,29,2 M29,0C13,0,0,13,0,29s13,29,29,29s29-13,29-29S45,0,29,0
	L29,0z"
    />

    <path
      fill="currentColor"
      d="M41.8,21H22.6c-1.8,0-3.2,1.4-3.2,3.2v16c0,1.8,1.4,3.2,3.2,3.2h19.2c1.8,0,3.2-1.4,3.2-3.2v-16C45,22.4,43.6,21,41.8,21z
		 M41.8,40.2H22.6v-16h19.2V40.2z"
    />
    <path
      fill="currentColor"
      d="M35.4,14.6H16.2c-1.8,0-3.2,1.4-3.2,3.2v16c0,1.8,1.4,3.2,3.2,3.2V17.8h22.4C38.6,16,37.2,14.6,35.4,14.6z"
    />
  </Icon>
);

export default memo(IconComponent);
