import { css } from 'styled-components';

const TooltipError = ({ font, colors, scale }) => ({
  base: () => css`
    padding: 5px 8px 0 5px;
    color: ${colors.invalid};
  `,
  label: () => css`
    float: right;
    padding-left: 5px;
  `,
  contentContainer: () => css`
    margin: 6px 12px;
  `,
});

export default TooltipError;
