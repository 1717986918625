import { useQuery } from 'react-query';
import { getFirebaseEnvironment } from 'environment';
const environment = getFirebaseEnvironment();

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

const getConfig = async ({ account, sessionId }) => {
  const response = await fetch(
    `${environment.functionsRoot}public/questionnaire/config?account=${account}&sessionId=${sessionId}`
  );
  const result = await response.json();
  if (!response.ok) {
    throw new Error(result.error);
  }

  return result;
};

const useQuestionnaireConfig = (props, config) =>
  useQuery('questionnaireConfig', () => getConfig(props), {
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: true,
    staleTime: twentyFourHoursInMs,
  });

export default useQuestionnaireConfig;
