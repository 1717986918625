"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.importQuestionnaire = exports.fetchSession = void 0;

var _index = require("../../index");

var _utils = require("./utils");

var _initialState = require("../initial-state");

const actionList = {
  details: _initialState.sessionDetailsInitialState,
  layout: _initialState.sessionLayoutInitialState,
  suiteList: _initialState.sessionSuiteListInitialState,
  businessCase: _initialState.sessionBusinessCaseInitialState,
  businessPreparation: _initialState.sessionBusinessPreparationInitialState,
  benefits: _initialState.sessionBenefitsInitialState,
  nonRecurringCosts: _initialState.sessionNonRecurringCostsInitialState,
  recurringCosts: _initialState.sessionRecurringCostsInitialState,
  defaultSessionValues: _initialState.sessionDefaultSessionValuesInitialState,
  benefitsCashflow: null,
  editted_benefitsCashflow: null,
  editted_nonRecurringCostsCashflow: null,
  editted_recurringCostsCashflow: null,
  nonRecurringCostsCashflow: null,
  recurringCostsCashflow: null,
  tender: _initialState.sessionTenderInitialState,
  delay: _initialState.sessionDelayInitialState,
  doNothing: _initialState.sessionDoNothingInitialState,
  plugins: _initialState.pluginsInitialState,
  questionnaire: _initialState.sessionQuestionnaireInitialState
};

const fetchSession = async ({
  firestore,
  id,
  account,
  path = `accounts/${account}/sessions/${id}`
}) => {
  const [sessionFile, sessionFiles] = await Promise.all([(0, _utils.getDoc)({
    firestore,
    path
  }), (0, _utils.getCollection)({
    firestore,
    path: `${path}/file`
  })]);
  const session = {
    session: sessionFile.data(),
    cashflow: {}
  };
  sessionFiles.forEach(doc => {
    if ((0, _index.includes)(doc.id, ['benefitsCashflow', 'nonRecurringCostsCashflow', 'recurringCostsCashflow', 'editted_benefitsCashflow', 'editted_nonRecurringCostsCashflow', 'editted_recurringCostsCashflow'])) {
      return session.cashflow = { ...session.cashflow,
        ...doc.data()
      };
    } else {
      if (actionList[doc.id]) {
        return session[doc.id] = { ...actionList[doc.id],
          ...doc.data()
        };
      }

      return session[doc.id] = doc.data();
    }
  });
  return session;
};

exports.fetchSession = fetchSession;

const importQuestionnaire = async ({
  overwrite = true,
  benefitData,
  defaultSessionValues,
  session
}) => {
  var _newDefaultSessionVal;

  const merge = overwrite ? (a, b) => {
    const aKeys = (0, _index.keys)(a);
    const newA = (0, _index.reduce)((accum, id) => {
      var _b$id;

      if ((_b$id = b[id]) !== null && _b$id !== void 0 && _b$id.multiYearValues) {
        if ((0, _index.isEmpty)(b[id].multiYearValues)) {
          return { ...accum,
            [id]: { ...(0, _index.omit)(['multiYearValues'], a[id])
            }
          };
        } else {
          return { ...accum,
            [id]: { ...a[id],
              multiYearValues: multiYearValues
            }
          };
        }
      }

      return { ...accum,
        [id]: a[id]
      };
    }, {})(aKeys);
    return (0, _index.mergeDeepRight)(newA, b);
  } : _index.mergeDeepLeft;
  let newDefaultSessionValues = defaultSessionValues;
  let updatedDefaultValues = null;
  let updatedBenefitData = null;
  let multiYearValues;
  const benefitKeys = (0, _index.keys)(benefitData);
  const removedKeys = (0, _index.reduce)((accum, id) => {
    if (!session.layout.benefits[id]) return (0, _index.append)(id, accum);
    return accum;
  }, [])(benefitKeys);
  const benefits = removedKeys ? (0, _index.omit)(removedKeys, benefitData) : benefitData;

  if (removedKeys && defaultSessionValues) {
    const linkedKeysToRemove = [];
    const linkedFields = (0, _index.mapObjIndexed)((fields, key, obj) => {
      const newFields = (0, _index.without)(removedKeys, fields);

      if ((0, _index.isEmpty)(newFields)) {
        linkedKeysToRemove.push(key);
      }

      return newFields;
    })(defaultSessionValues.linked);
    newDefaultSessionValues = {
      data: (0, _index.omit)(linkedKeysToRemove, defaultSessionValues.data),
      schema: (0, _index.omit)(linkedKeysToRemove, defaultSessionValues.schema),
      linked: (0, _index.omit)(linkedKeysToRemove, linkedFields)
    };
  }

  if ((_newDefaultSessionVal = newDefaultSessionValues) !== null && _newDefaultSessionVal !== void 0 && _newDefaultSessionVal.linked) {
    if (!(0, _index.isEmpty)(newDefaultSessionValues.linked)) {
      updatedDefaultValues = merge(session.defaultSessionValues, newDefaultSessionValues);
    }
  }

  if (!(0, _index.isEmpty)(benefits)) {
    var _session$benefits3, _session$benefits4;

    const newBenefits = (0, _index.mapObjIndexed)((item, key, obj) => {
      var _session$benefits, _session$benefits$dat, _session$benefits$dat2, _session$benefits2, _session$benefits2$da, _session$benefits2$da2;

      if (session !== null && session !== void 0 && (_session$benefits = session.benefits) !== null && _session$benefits !== void 0 && (_session$benefits$dat = _session$benefits.data) !== null && _session$benefits$dat !== void 0 && (_session$benefits$dat2 = _session$benefits$dat[key]) !== null && _session$benefits$dat2 !== void 0 && _session$benefits$dat2.multiYearValues) {
        const multiYearKeys = (0, _index.keys)(session.benefits.data[key].multiYearValues);
        multiYearValues = (0, _index.reduce)((accum, id) => {
          var _item$values, _item$values2;

          if (!(item !== null && item !== void 0 && (_item$values = item.values) !== null && _item$values !== void 0 && _item$values[id]) && !overwrite && !(0, _index.isEmpty)(session.benefits.data[key].multiYearValues[id])) {
            return { ...accum,
              [id]: session.benefits.data[key].multiYearValues[id]
            };
          } else if (item !== null && item !== void 0 && (_item$values2 = item.values) !== null && _item$values2 !== void 0 && _item$values2[id] && overwrite) {
            return accum;
          }

          return accum;
        }, {})(multiYearKeys);
      }

      if (!(session !== null && session !== void 0 && (_session$benefits2 = session.benefits) !== null && _session$benefits2 !== void 0 && (_session$benefits2$da = _session$benefits2.data) !== null && _session$benefits2$da !== void 0 && (_session$benefits2$da2 = _session$benefits2$da[key]) !== null && _session$benefits2$da2 !== void 0 && _session$benefits2$da2.startMonth)) {
        var _session$details;

        return { ...item,
          id: key,
          startMonth: (_session$details = session.details) !== null && _session$details !== void 0 && _session$details.defaultStartMonth ? session.details.defaultStartMonth : 2630016,
          startMonthLinked: true,
          endMonth: session.details.reviewPeriod,
          endMonthLinked: true,
          growth: 0,
          recurrence: 'monthly',
          ...(multiYearValues && {
            multiYearValues
          })
        };
      }

      return { ...item,
        ...(multiYearValues && {
          multiYearValues
        })
      };
    })(benefits);
    const newIds = (0, _index.keys)(newBenefits);
    const ids = session !== null && session !== void 0 && (_session$benefits3 = session.benefits) !== null && _session$benefits3 !== void 0 && _session$benefits3.ids ? (0, _index.uniq)([...newIds, ...session.benefits.ids]) : newIds;
    const mergedData = session !== null && session !== void 0 && (_session$benefits4 = session.benefits) !== null && _session$benefits4 !== void 0 && _session$benefits4.data ? merge(session.benefits.data, newBenefits) : newBenefits;
    updatedBenefitData = { ...session.benefits,
      ids,
      data: mergedData
    };
  }

  return {
    updatedDefaultValues,
    updatedBenefitData
  };
};

exports.importQuestionnaire = importQuestionnaire;