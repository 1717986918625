"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sortCaseInsensitive = void 0;

var _fp = require("./fp");

const sortCaseInsensitive = ({
  data,
  direction,
  field
}) => {
  const sortPropCaseInsensitive = (0, _fp.sortBy)((0, _fp.compose)(_fp.toLower, (0, _fp.prop)(field)));
  const sorted = sortPropCaseInsensitive(data);
  return direction === 'asc' ? sorted : (0, _fp.reverse)(sorted);
};

exports.sortCaseInsensitive = sortCaseInsensitive;