// @flow
import styled from 'styled-components';

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 4px;
`;
export const BodyWrapper = styled.div`
  padding: 0 20px;
`;
