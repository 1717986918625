"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.zeroFillUnfilledFreestlye = exports.zeroFillUnfilled = exports.unformatFieldSetData = exports.scopeHasInputData = exports.removePostErrorFieldCalculatedKeys = exports.removeCalculatedKeys = exports.preProcess = exports.postProcess = exports.normalizeUnits = exports.isUnfilled = exports.getPostErrorFieldCalculatedFieldIds = exports.getMultiYears = exports.getFieldSets = exports.getFieldId = exports.getExpressionKeys = exports.getExpressionByKey = exports.getErrorObject = exports.formatFieldSetData = exports.findUnfilled = exports.evaluateSchema = exports.evaluateExpressions = exports.evaluateExpressionObject = exports.evaluateAll = exports.doEvaluatorCalculate = exports.dissocUnfilled = exports.calcSubs = exports.calcResult = exports.calcAverage = exports.averageResult = exports.adjustUnits = void 0;

var _fp = require("./fp");

var _normalization = require("./normalization");

var _objects = require("./objects");

var _constants = require("./constants");

var _mathjs = require("./mathjs");

var _type = require("./type");

var _cashflow = require("./cashflow");

const generateCalcObject = (schema, scope) => ({
  schema,
  scope,
  unfilled: []
});

const averageResultExpression = 'result * ( endMonth - startMonth + 1) / appraisalPeriod';
const expressionsLens = (0, _fp.lensPath)(['schema', 'expressions']);
const unfilledLens = (0, _fp.lensProp)('unfilled');
const unrequiredLens = (0, _fp.lensPath)(['schema', 'unrequired']);
const scopeLens = (0, _fp.lensProp)('scope');
const expressionsView = (0, _fp.view)(expressionsLens);
const unfilledView = (0, _fp.view)(unfilledLens);
const unrequiredView = (0, _fp.view)(unrequiredLens);
const scopeView = (0, _fp.view)(scopeLens); // prettier-ignore

const getFieldId = (0, _fp.pipe)((0, _fp.split)('.'), _fp.last); // prettier-ignore

exports.getFieldId = getFieldId;
const averageResult = (0, _objects.callAndSet)('result', (0, _mathjs.evaluateExpression)(averageResultExpression)); // prettier-ignore

exports.averageResult = averageResult;
const evaluateExpressionObject = // eslint-disable-next-line
(0, _fp.useWith)((0, _fp.flip)(_fp.map), [_fp.idiot, (0, _fp.flip)((0, _mathjs.evaluateExpression)(_fp.placeholder))]); // prettier-ignore

exports.evaluateExpressionObject = evaluateExpressionObject;
const evaluateAllIterator = (0, _fp.converge)((0, _fp.flip)(_fp.merge), [(0, _fp.tryCatch)(_fp.call, (0, _fp.always)({})), (0, _fp.nthArg)(1)]); // prettier-ignore

const evaluateAll = // eslint-disable-next-line
(0, _fp.useWith)((0, _fp.flip)((0, _fp.reduce)((0, _fp.flip)(evaluateAllIterator))), [(0, _fp.map)(evaluateExpressionObject)]); // prettier-ignore

exports.evaluateAll = evaluateAll;
const zeroFillUnfilled = (0, _fp.converge)((0, _fp.reduce)((0, _fp.flip)(_objects.zeroFillProp)), [scopeView, unfilledView]); // prettier-ignore

exports.zeroFillUnfilled = zeroFillUnfilled;
const isUnfilled = (0, _fp.flip)(_objects.hasNot); // prettier-ignore

exports.isUnfilled = isUnfilled;
const findUnfilled = (0, _fp.converge)(_fp.filter, [(0, _fp.pipe)(scopeView, isUnfilled), unrequiredView]); // prettier-ignore

exports.findUnfilled = findUnfilled;
const getExpressionKeys = (0, _fp.pipe)((0, _fp.map)(_fp.keysIn), _fp.transpose, _fp.head); // prettier-ignore

exports.getExpressionKeys = getExpressionKeys;

const getPostErrorFieldCalculatedFieldIds = (expressionKeys, order, errors) => {
  const errorIds = (0, _fp.map)((0, _fp.pipe)((0, _fp.prop)('stack'), (0, _fp.split)(':'), (0, _fp.nth)(0)), errors);
  const errorOrderPositions = (0, _fp.map)((0, _fp.pipe)(_fp.equals, (0, _fp.findIndex)(_fp.placeholder, order)), errorIds);
  const firstErrorOrderPosition = (0, _fp.reduce)(_fp.min, Infinity, errorOrderPositions);
  const postErrorFieldOrder = (0, _fp.drop)(firstErrorOrderPosition, order);
  return (0, _fp.filter)((0, _fp.contains)(_fp.placeholder, expressionKeys), postErrorFieldOrder);
}; // prettier-ignore


exports.getPostErrorFieldCalculatedFieldIds = getPostErrorFieldCalculatedFieldIds;

const removePostErrorFieldCalculatedKeys = o => (0, _fp.reduce)((0, _fp.flip)(_fp.dissoc), scopeView(o), getPostErrorFieldCalculatedFieldIds(getExpressionKeys(expressionsView(o)), o.order, o.errors)); // prettier-ignore


exports.removePostErrorFieldCalculatedKeys = removePostErrorFieldCalculatedKeys;
const removeCalculatedKeys = (0, _fp.converge)((0, _fp.reduce)((0, _fp.flip)(_fp.dissoc)), [(0, _fp.pipe)(scopeView), (0, _fp.pipe)(expressionsView, getExpressionKeys)]);
exports.removeCalculatedKeys = removeCalculatedKeys;

const getMeasurement = (schema, key) => schema.properties[key].measurement;

const getUnit = (schema, key) => schema.properties[key].unit; // prettier-ignore


const adjustUnit = (schema, key, value) => (0, _fp.has)(key, schema.properties) ? (0, _normalization.adjustToDisplayUnit)(getMeasurement(schema, key), getUnit(schema, key), value) : value; // prettier-ignore


const normalize = (schema, key, value) => (0, _fp.has)(key, schema.properties) ? (0, _normalization.normalizeUnit)(getMeasurement(schema, key), getUnit(schema, key), value) : value; // prettier-ignore


const adjustUnits = o => (0, _fp.mapObjIndexed)((v, k) => adjustUnit(o.schema, k, v), o.scope); // prettier-ignore


exports.adjustUnits = adjustUnits;

const normalizeUnits = o => (0, _fp.mapObjIndexed)((v, k) => normalize(o.schema, k, v), o.scope); // prettier-ignore


exports.normalizeUnits = normalizeUnits;

const zeroFillUnfilledFreestlye = obj => (0, _fp.pipe)((0, _fp.filter)((0, _fp.prop)('freestyle')), _fp.keys, (0, _fp.reduce)((0, _fp.flip)((0, _fp.assoc)(_fp.placeholder, {})), {}), (0, _fp.merge)(_fp.placeholder, obj.scope))(obj.schema.properties); // prettier-ignore


exports.zeroFillUnfilledFreestlye = zeroFillUnfilledFreestlye;

const preProcess = (schema, scope) => (0, _fp.pipe)(generateCalcObject, (0, _objects.callAndSet)('unfilled', findUnfilled), (0, _objects.callAndSet)('scope', zeroFillUnfilled), (0, _objects.callAndSet)('scope', zeroFillUnfilledFreestlye), (0, _objects.callAndSet)('scope', removeCalculatedKeys), (0, _objects.callAndSet)('scope', adjustUnits))(schema, scope); // prettier-ignore


exports.preProcess = preProcess;
const evaluateExpressions = (0, _fp.converge)(evaluateAll, [expressionsView, scopeView]); // prettier-ignore

exports.evaluateExpressions = evaluateExpressions;
const dissocUnfilled = (0, _fp.converge)((0, _fp.reduce)((0, _fp.flip)(_fp.dissoc)), [scopeView, unfilledView]); // prettier-ignore

exports.dissocUnfilled = dissocUnfilled;
const postProcess = (0, _fp.pipe)((0, _objects.callAndSet)('scope', normalizeUnits), dissocUnfilled); // prettier-ignore

exports.postProcess = postProcess;
const evaluateSchema = (0, _fp.curry)((0, _fp.pipe)(preProcess, (0, _objects.callAndSet)('scope', evaluateExpressions), postProcess)); // prettier-ignore

exports.evaluateSchema = evaluateSchema;
const getExpressionByKey = (0, _fp.curry)((0, _fp.pipe)((0, _fp.cardinal_)(_fp.find)(_fp.has), _fp.values, _fp.head));
exports.getExpressionByKey = getExpressionByKey;
const hasFreestyleData = (0, _fp.allPass)([_type.isObject, (0, _fp.propSatisfies)(_type.isNumber, 'value')]); // prettier-ignore

const keyHasData = (0, _fp.anyPass)([_type.isNumber, hasFreestyleData]); //complement(anyPass([isNil, propSatisfies(isNumber, 'value'), isEmpty]));
//((key: string) => (scope: Object) => Boolean) =  complement(anyPass([isNil, propSatisfies(isNumber, 'value'), isEmpty]));
// prettier-ignore

const keysWithData = (k, s) => (0, _fp.filter)((0, _fp.pipe)((0, _fp.prop)(_fp.placeholder, s), keyHasData), k); // prettier-ignore


const scopeHasInputData = (0, _fp.curryN)(2, (0, _fp.pipe)(keysWithData, _fp.length, (0, _fp.gt)(_fp.placeholder, 0)));
exports.scopeHasInputData = scopeHasInputData;

const calcResult = obj => (0, _objects.callAndSet)('result', (0, _fp.pipe)((0, _fp.props)(['appraisalPeriod', 'cashflow']), (0, _fp.apply)(_cashflow.averageCashflow)))(obj);
/* eslint-disable fp/no-let, fp/no-mutation */


exports.calcResult = calcResult;

const doEvaluatorCalculate = ({
  reviewPeriod,
  endMonth,
  startMonth,
  growth,
  schema,
  formData,
  adoptionCashflowMods,
  riskCashflowMods,
  growthCashflowMods,
  smooth,
  multiYearValues
}) => {
  const appraisalPeriod = reviewPeriod === '' ? _constants.defaultReviewPeriod : reviewPeriod;
  const growthFormatted = growth === '' ? 0 : growth;
  const endMonthFormatted = endMonth === '' || endMonth > appraisalPeriod || endMonth === 0 ? appraisalPeriod : endMonth;
  let newStartMonth = '';

  if (schema.recurrence === 'once') {
    newStartMonth = startMonth === 0 || startMonth === '' ? 0 : startMonth;
  } else {
    newStartMonth = startMonth === 0 || startMonth === '' ? 1 : startMonth;
  }

  const startMonthFormatted = newStartMonth > endMonthFormatted ? endMonthFormatted : newStartMonth;
  const addDurationData = (0, _fp.merge)(_fp.placeholder, {
    startMonth: startMonthFormatted,
    endMonth: endMonthFormatted,
    appraisalPeriod,
    growth: growthFormatted
  });
  const multiKeys = (0, _fp.keys)((0, _fp.filter)((0, _fp.has)('multiYear'), schema.properties));
  const multiKeysValues = (0, _fp.pickAll)(multiKeys, formData);
  const genMissingSubs = (0, _fp.reduce)((a, b) => (0, _fp.has)(b, a) ? a : (0, _fp.assoc)(b, (0, _fp.repeat)(multiKeysValues[b] || 0, (0, _cashflow.monthToYear)(appraisalPeriod)), a), multiYearValues, multiKeys);
  const zeroFill = (0, _fp.map)(a => (0, _fp.length)(a) < (0, _cashflow.monthToYear)(appraisalPeriod) ? (0, _fp.concat)(a, (0, _fp.repeat)((0, _fp.last)(a) || 0, (0, _cashflow.monthToYear)(appraisalPeriod) - (0, _fp.length)(a))) : a, genMissingSubs);
  const addMultiYearValues = (0, _fp.merge)(_fp.placeholder, {
    multiYearValues: zeroFill
  });
  const removeDurationData = (0, _fp.omit)(['startMonth', 'endMonth', 'appraisalPeriod', 'growth']);
  const removeMultiYearValues = (0, _fp.omit)(['multiYearValues']);
  const calculatedKeys = getExpressionKeys(schema.expressions);
  const sanitizedScope = (0, _fp.omit)(calculatedKeys, formData);
  const hasData = scopeHasInputData((0, _fp.keys)(schema.properties), sanitizedScope); //  eslint-disable-next-line

  const applyCashflowMods = (0, _fp.curryN)(2, (mods, obj) => {
    if (mods && obj.cashflow) {
      //cashflowModifiers
      const applyMods = (0, _fp.isEmpty)(mods) ? a => a : (0, _cashflow.cashflowModifiers)(mods);
      const modded = (0, _objects.callAndSet)('cashflow', (0, _fp.pipe)((0, _fp.prop)('cashflow'), applyMods))(obj);
      const output = calcResult(modded);
      return output;
    } else {
      return obj;
    }
  });

  const smoothCf = obj => {
    if (smooth && obj.cashflow) {
      return (0, _objects.callAndSet)('cashflow', (0, _fp.pipe)((0, _fp.prop)('cashflow'), (0, _cashflow.smooth)(2, 7)))(obj);
    } else {
      return obj;
    }

    return obj;
  };

  const calculate = (0, _fp.pipe)(addDurationData, addMultiYearValues, evaluateSchema(schema), applyCashflowMods([adoptionCashflowMods, riskCashflowMods, growthCashflowMods]), smoothCf, removeMultiYearValues, removeDurationData);
  return hasData ? calculate(formData) : sanitizedScope;
};

exports.doEvaluatorCalculate = doEvaluatorCalculate;
const getFieldSets = (0, _fp.pipe)((0, _fp.prop)('properties'), (0, _fp.filter)((0, _fp.propEq)('type', 'array')), (0, _fp.pluck)('items'), (0, _fp.map)((0, _fp.pluck)('id')), (0, _fp.map)((0, _fp.map)((0, _fp.pipe)((0, _fp.split)('.'), (0, _fp.nth)(1)))));
/*eslint-disable no-var, fp/no-nil, fp/no-mutation, fp/no-mutating-methods, fp/no-unused-expression, fp/no-loops */

exports.getFieldSets = getFieldSets;

const formatFieldSetData = (schema, data) => {
  const fieldSets = getFieldSets(schema);
  data = (0, _fp.reduce)((0, _fp.flip)((0, _fp.assoc)(_fp.placeholder, [])), data, (0, _fp.keys)(fieldSets));

  for (var obj in fieldSets) {
    for (var i = 0; i < fieldSets[obj].length; i++) {
      data[obj].push(data[fieldSets[obj][i]]);
      data = (0, _fp.dissoc)(fieldSets[obj][i], data);
    }
  }

  return data;
};

exports.formatFieldSetData = formatFieldSetData;

const unformatFieldSetData = (schema, data) => {
  const fieldSets = getFieldSets(schema);

  for (var obj in fieldSets) {
    for (var i = 0; i < fieldSets[obj].length; i++) {
      if (data[obj]) {
        data = (0, _fp.assoc)(fieldSets[obj][i], data[obj][i], data);
      }
    }

    data = (0, _fp.dissoc)(obj, data);
  }

  return data;
};

exports.unformatFieldSetData = unformatFieldSetData;

const getErrorObject = errors => (0, _fp.fromPairs)((0, _fp.map)((0, _fp.pipe)((0, _fp.prop)('stack'), (0, _fp.split)(':'), (0, _fp.map)(_fp.trim)), errors));

exports.getErrorObject = getErrorObject;

const calcSubs = (period, total) => {
  const fullYears = Math.floor(period / _constants.monthsPerYear);
  const remainderMon = period - fullYears * _constants.monthsPerYear;
  let subs = (0, _fp.repeat)(total, fullYears);
  if (remainderMon) subs = (0, _fp.append)(total / _constants.monthsPerYear * remainderMon, subs);
  return subs;
};

exports.calcSubs = calcSubs;

const calcAverage = (period, subs) => {
  const remainder = (0, _cashflow.monthToFullYearRemainder)(period);
  const average = ((0, _fp.reduce)((a, b) => (0, _fp.add)(b * _constants.monthsPerYear, a), 0, remainder ? (0, _fp.init)(subs) : subs) + remainder * (0, _fp.last)(subs)) / period;
  return average;
};

exports.calcAverage = calcAverage;

const getMultiYears = ({
  multiYearValues,
  fieldId,
  value,
  reviewPeriod,
  startMonth,
  endMonth
}) => (0, _cashflow.zeroFillUndefinedYears)(multiYearValues === null || multiYearValues === void 0 ? void 0 : multiYearValues[fieldId], multiYearValues !== null && multiYearValues !== void 0 && multiYearValues[fieldId] ? (0, _fp.last)(multiYearValues === null || multiYearValues === void 0 ? void 0 : multiYearValues[fieldId]) : value, reviewPeriod, startMonth, endMonth) || (0, _fp.repeat)(value, (0, _cashflow.monthToYear)(endMonth));

exports.getMultiYears = getMultiYears;