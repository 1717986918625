"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

const getBrowser = state => state.browser;

const selectors = {
  getBrowser
};
var _default = selectors;
exports.default = _default;