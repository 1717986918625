import { Component } from 'react';
import LogRocket from 'logrocket';

class ErrorBoundaryGlobal extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // log error with log rocket
    console.log('sfl-info', info);
    console.log('sfl-error', error);
    LogRocket.captureException(error, {
      extra: {
        // additional arbitrary data associated with the event
        componentStack: info.componentStack,
      },
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            fontFamily: 'Arial, Helvetica, sans-serif',
          }}
        >
          <svg
            version="1.2"
            baseProfile="tiny"
            x="0px"
            y="0px"
            width="225px"
            height="31px"
            viewBox="0 0 225 31"
            overflow="visible"
          >
            <path
              fill-rule="evenodd"
              fill="#3DB1FF"
              d="M24.2,0.1l0.2,0.1c-0.1,1.4-1.5,2.7-2.1,3.7l-2,4.1c-3.3,5.6-8.2,9.4-12.1,14.3
	l-3.6,4.2L3.1,28c-0.8,1.1-1.8,2.1-3,2.9L0,30.8c1-1.4,2.1-2.6,3.2-3.8l6.1-8.2C13.9,12.2,17.3,4.5,24.2,0.1z"
            />
            <path
              fill-rule="evenodd"
              fill="#3DB1FF"
              d="M33.2,0.1l0.3,0.1c-0.2,1.7-2.2,3.6-2.9,5.2c-3.5,7.7-9.8,12.2-14.6,18.4
	c-1.5,1.8-3.1,3.6-4.8,5.2c-0.6,0.6-1.2,1.6-2,1.8v0c0.8-1.1,1.6-2.2,2.6-3.1l5.6-7.3c2.9-4.2,5.6-8.6,8.5-12.7
	c1.3-2,2.8-3.9,4.5-5.5C31.2,1.5,32.1,0.9,33.2,0.1z"
            />
            <path
              fill-rule="evenodd"
              fill="#3DB1FF"
              d="M41.4,0.1h0.2v0.1c0.2,0.7-0.7,1.6-1,2.1c-1.2,1.8-2.1,4.1-3.3,6
	c-3.3,5.5-8.1,9.2-12,14.1c-1.8,2.3-3.8,4.4-5.8,6.4l-2.1,2h-0.2l0.1-0.2c0.5-1.2,1.8-2.2,2.6-3.2l4.8-6.3c3.1-4.3,5.9-8.9,8.9-13.2
	c1.5-2.3,3.3-4.4,5.3-6.3C39.8,1.2,40.7,0.7,41.4,0.1z"
            />
            <path
              fill="#474747"
              d="M52.4,19.2c0.2,0.5,0.6,0.8,1,0.9c0.6,0.2,1.3,0.2,1.9,0.2c0.6,0,1.2,0,1.8-0.2c0.4-0.1,0.5-0.3,0.5-0.6
	c0-0.3-0.2-0.5-0.4-0.6c-0.5-0.2-1.1-0.3-1.6-0.4l-2.6-0.3c-2.6-0.2-5.1-0.7-7.6-1.6c-1.4-0.4-2.3-1.6-2.4-3c0-1.5,1-2.6,3.1-3.4
	C49.1,9.3,52,8.9,55,9.1c1.7,0,3.4,0.1,5.1,0.3c1.2,0.2,2.5,0.5,3.6,0.9c0.8,0.3,1.6,0.8,2.2,1.4c0.5,0.5,0.9,1.1,1.1,1.7L57.5,14
	c-0.3-0.8-1.1-1.2-2.6-1.2c-0.5,0-1.1,0-1.6,0.2c-0.4,0.2-0.5,0.4-0.5,0.6c0,0.3,0.2,0.4,0.5,0.6c0.6,0.2,1.2,0.3,1.8,0.4l2.6,0.3
	c1.8,0.2,3.4,0.4,4.6,0.7c1,0.2,2,0.5,3,0.9c0.7,0.3,1.2,0.7,1.7,1.3c0.4,0.5,0.5,1.1,0.5,1.8c0,1.5-1,2.7-2.9,3.6
	c-2.9,1-6,1.4-9.1,1.3c-1.5,0-3-0.1-4.5-0.2c-1.4-0.1-2.7-0.4-4-0.8c-1.1-0.3-2.1-0.8-3-1.6c-0.7-0.6-1.2-1.5-1.3-2.5L52.4,19.2z"
            />
            <path
              fill="#474747"
              d="M69.1,4.4h10.4v6.3c0.9-0.5,1.9-0.9,2.9-1.2c1.3-0.3,2.7-0.5,4-0.5c2.1-0.1,4.3,0.4,6.2,1.3
	c1.4,0.7,2.3,2.2,2.2,3.8v9.7H84.5v-8.6c0-0.4-0.2-0.8-0.5-1.1c-0.5-0.2-1-0.4-1.5-0.3c-0.5,0-1,0-1.5,0.2c-0.4,0.1-0.7,0.3-1,0.5
	c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1,0.2-0.1,0.4-0.1,0.6v8.3H69.1V4.4z"
            />
            <path
              fill="#474747"
              d="M112.7,23.9v-1.4c-1,0.6-2.2,1-3.3,1.3c-1.3,0.3-2.7,0.5-4.1,0.4c-1.1,0-2.1-0.1-3.2-0.2
	c-0.9-0.1-1.8-0.4-2.7-0.8c-0.7-0.3-1.4-0.8-2-1.3c-0.5-0.5-0.8-1.2-0.7-2c0-0.9,0.4-1.8,1.1-2.4c0.9-0.7,1.9-1.2,2.9-1.5
	c1.3-0.4,2.6-0.6,3.9-0.8c1.4-0.1,2.9-0.2,4.7-0.3h2.9v-0.5c0.1-0.5-0.2-0.9-0.6-1.2c-0.5-0.3-1.1-0.4-1.7-0.4
	c-0.6,0-1.2,0.1-1.8,0.3c-0.4,0.2-0.7,0.5-0.8,0.9l-9.2-0.4c0.6-1.7,1.9-3.1,3.7-3.6c2.7-0.9,5.6-1.3,8.4-1.2c1.9,0,3.7,0.2,5.5,0.6
	c1.3,0.2,2.5,0.6,3.7,1.2c0.8,0.4,1.5,1.1,2,1.9c0.4,0.8,0.6,1.6,0.6,2.5v8.7L112.7,23.9z M112.4,18.1l-2.7,0.1
	c-0.9,0-1.8,0.1-2.6,0.4c-0.6,0.2-0.8,0.5-0.8,0.9c0,0.4,0.2,0.7,0.6,0.8c0.5,0.2,1.1,0.3,1.7,0.3c1,0,1.9-0.2,2.8-0.6
	c0.6-0.2,1.1-0.9,1.1-1.5V18.1z"
            />
            <path
              fill="#474747"
              d="M125.1,9.5h10.1v1.6c2.1-1.3,4.5-2,7-1.8c0.5,0,1,0,1.5,0l1.2,0.1l-1.1,4.8c-0.4,0-0.8-0.1-1.2-0.1
	s-1,0-1.7,0c-0.6,0-1.2,0-1.8,0.1c-0.7,0-1.3,0.2-1.9,0.4c-1,0.2-1.6,1.1-1.7,2.1v7.3h-10.3L125.1,9.5z"
            />
            <path
              fill="#474747"
              d="M158.2,17.9l-2.4,1.3v4.7h-9.9V4.4h9.9v8.9l6.6-3.8h11.5l-8.8,4.7l9.5,9.6h-10.8L158.2,17.9z"
            />
            <path fill="#8E8E8E" d="M179.9,16.6v6.8h-0.6V9.1h6.3v0.7h-5.7v6h5.4v0.7H179.9z" />
            <path
              fill="#8E8E8E"
              d="M186.8,11V9.1h0.6V11H186.8z M186.8,23.4V13.1h0.6v10.3L186.8,23.4z"
            />
            <path
              fill="#8E8E8E"
              d="M194.6,23.4v-6.6c0.1-0.8-0.1-1.7-0.5-2.4c-0.3-0.5-0.9-0.8-1.6-0.8c-0.9,0-1.7,0.5-2.2,1.2
	c-0.3,0.3-0.5,0.7-0.6,1.1c-0.1,0.5-0.2,1-0.2,1.5v6.2h-0.6V13.1h0.6v1.8c0.5-1.2,1.7-2,3-2.1c0.8,0,1.5,0.3,1.9,1
	c0.5,0.8,0.7,1.8,0.7,2.8v6.8L194.6,23.4z"
            />
            <path
              fill="#8E8E8E"
              d="M203,20.9c-0.1,0.8-0.4,1.6-1,2.1c-0.5,0.5-1.3,0.8-2,0.8c-1,0-2-0.5-2.6-1.4c-0.7-1.2-1-2.6-0.9-4
	c-0.1-1.4,0.2-2.8,0.9-4.1c0.5-0.9,1.5-1.5,2.6-1.5c1,0,1.9,0.5,2.4,1.3c0.6,1.2,0.9,2.5,0.8,3.8v0.5h-6.1c-0.1,1.2,0.2,2.4,0.8,3.4
	c0.5,0.8,1.3,1.2,2.2,1.2c0.6,0,1.2-0.2,1.6-0.6c0.4-0.4,0.7-1,0.8-1.6L203,20.9z M202.6,17.9c0.1-1.1-0.2-2.2-0.7-3.2
	c-0.4-0.7-1.1-1.1-2-1.1c-0.8,0-1.6,0.4-2.1,1.1c-0.6,1-0.9,2.1-0.8,3.2L202.6,17.9z"
            />
            <path
              fill="#8E8E8E"
              d="M209.6,15.5c-0.1-0.6-0.4-1.1-0.8-1.6c-0.4-0.3-1-0.5-1.6-0.4c-1.5,0-2.3,0.7-2.3,2.1c0,0.5,0.1,1,0.4,1.3
	c0.4,0.4,0.9,0.6,1.4,0.7l1,0.2c0.7,0.1,1.4,0.5,1.9,1c0.4,0.5,0.6,1.2,0.6,1.9c0.1,0.8-0.2,1.6-0.7,2.2c-0.6,0.5-1.4,0.8-2.2,0.8
	c-0.8,0-1.6-0.2-2.3-0.8c-0.6-0.6-0.9-1.4-0.9-2.3h0.5c0,0.7,0.3,1.3,0.8,1.8c0.5,0.4,1.2,0.6,1.8,0.6c0.6,0.1,1.3-0.1,1.8-0.6
	c0.4-0.5,0.6-1.1,0.6-1.7c0-0.5-0.1-1-0.5-1.4c-0.4-0.4-1-0.7-1.5-0.7l-0.8-0.2c-0.8-0.1-1.5-0.4-2-0.9c-0.4-0.5-0.6-1.1-0.6-1.8
	c0-1.6,1.4-2.9,3-2.9c0.6,0,1.3,0.2,1.8,0.6c0.5,0.5,0.9,1.2,0.9,1.9L209.6,15.5z"
            />
            <path
              fill="#8E8E8E"
              d="M216.7,15.5c-0.1-0.6-0.4-1.1-0.8-1.6c-0.4-0.3-1-0.5-1.5-0.4c-1.5,0-2.3,0.7-2.3,2.1c0,0.5,0.1,1,0.4,1.3
	c0.4,0.4,0.9,0.6,1.5,0.7l1,0.2c0.7,0.1,1.4,0.5,1.9,1c0.4,0.5,0.6,1.2,0.6,1.9c0.1,0.8-0.2,1.6-0.7,2.2c-0.6,0.5-1.4,0.8-2.2,0.8
	c-0.8,0-1.6-0.2-2.2-0.8c-0.6-0.6-0.9-1.4-0.9-2.3h0.5c0,0.7,0.3,1.3,0.8,1.8c0.5,0.4,1.2,0.6,1.8,0.6c0.6,0.1,1.3-0.1,1.8-0.6
	c0.4-0.5,0.6-1.1,0.6-1.7c0-0.5-0.1-1-0.5-1.5c-0.4-0.4-1-0.7-1.5-0.7l-0.8-0.2c-0.7-0.1-1.4-0.4-1.9-0.9c-0.4-0.5-0.6-1.1-0.6-1.8
	c-0.1-0.8,0.2-1.6,0.7-2.1c0.6-0.5,1.3-0.8,2.1-0.7c0.7,0,1.4,0.2,1.9,0.6c0.5,0.5,0.9,1.2,1,1.9L216.7,15.5z"
            />
            <path
              fill="#8E8E8E"
              d="M224.8,20.9c-0.1,0.8-0.4,1.5-1,2.1c-0.5,0.5-1.3,0.8-2,0.8c-1,0-2-0.5-2.6-1.4c-0.7-1.2-1-2.6-0.9-4
	c-0.1-1.4,0.2-2.8,0.9-4.1c0.5-0.9,1.5-1.5,2.6-1.5c1,0,1.9,0.5,2.4,1.3c0.6,1.2,0.9,2.5,0.8,3.8v0.5h-6.1c-0.1,1.2,0.2,2.4,0.8,3.4
	c0.5,0.8,1.3,1.2,2.2,1.2c0.6,0,1.2-0.2,1.6-0.6c0.4-0.4,0.7-1,0.8-1.6L224.8,20.9z M224.4,17.9c0.1-1.1-0.1-2.2-0.6-3.2
	c-0.4-0.7-1.2-1.1-2-1.1c-0.8,0-1.6,0.4-2,1.1c-0.6,1-0.9,2.1-0.8,3.2L224.4,17.9z"
            />
          </svg>

          <p>
            An unrecoverable error has occurred, please accept our apologies. We are aware of the
            issue and will get you back up and running as soon as we can.
          </p>
          <p>
            In the meantime, if you have any questions, please{' '}
            <a href="https://sharkfinesse.com/contact-us">contact us</a>
          </p>
          <p>
            <button
              style={{
                backgroundColor: '#3db1ff',
                color: '#ffffff',
                borderRadius: '50px',
                border: 'none',
                padding: '10px 5px',
                fontSize: '14px',
                fontWeight: '600',
                height: '35px',
                width: '150px',
                textAlign: 'center',
                cursor: 'pointer',
              }}
              onClick={() => {
                window.location.reload();
              }}
            >
              Reload Page
            </button>
          </p>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundaryGlobal;
