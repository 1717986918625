import { layoutSelectors } from '@sharkfinesse/sfl-lib';
import { put, select, call } from 'redux-saga/effects';
import { actionCreators as evaluatorActionCreators } from 'redux/modules/evaluator';
import { goToCurrent } from './goToCurrent';

function* goToNextOrPrevious(benefitId, storeCurrent = true, goTo = true) {
  const selectedId = yield select(layoutSelectors.getNextOrPreviousBenefit, { benefitId });

  if (goTo) yield call(goToCurrent, { id: selectedId });
  if (storeCurrent) yield put(evaluatorActionCreators.updateCurrentBenefit(selectedId));
  return selectedId;
}

export default goToNextOrPrevious;
