import { useMutation } from 'react-query';
import fetchAuth from 'utils/fetchAuth';

const useSendShareCopy = config => {
  return useMutation(async body => {
    const users =
      body?.users.length > 0
        ? body.users.map((user, i) => ({ email: user.value, name: user.name, id: user.id }))
        : null;

    const response = await fetchAuth({
      url: 'session/share/copy',
      config: {
        method: 'POST',
        body: JSON.stringify({ ...body, users }),
      },
    });
    return response;
  }, config);
};

export default useSendShareCopy;
