"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

const getIntl = state => state.intl;

const getLocale = state => state.intl.locale;

const getMessages = state => state.intl.messages;

const selectors = {
  getIntl,
  getLocale,
  getMessages
};
var _default = selectors;
exports.default = _default;