import { useQuery } from 'react-query';
import { getCollectionData } from '@sharkfinesse/sfl-lib';
import rsfApp from 'redux/rsf';

const getFeature = () =>
  rsfApp.firestore.collection(`features`).orderBy('dateAdded', 'desc').limit(1).get();

const getItems = async (props) => {
  const response = await getFeature();
  const features = getCollectionData(response);
  return features;
};

const useLatestFeature = (props, config) => useQuery('features', () => getItems(props), config);

export default useLatestFeature;
