"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.calculateTransparentColor = calculateTransparentColor;
exports.lighten = exports.hex2Rgba = void 0;

var _fp = require("./fp");

var _color = _interopRequireDefault(require("color"));

var _hexRgb = _interopRequireDefault(require("hex-rgb"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* eslint-disable fp/no-let, fp/no-mutation, fp/no-unused-expression */
// prettier-ignore
const hex2Rgba = (0, _fp.curry)((alpha, hex) => 'rgba(' + (0, _hexRgb.default)(hex).red + ',' + (0, _hexRgb.default)(hex).green + ',' + (0, _hexRgb.default)(hex).blue + ',' + alpha + ')');
exports.hex2Rgba = hex2Rgba;
const lighten = (0, _fp.curry)((factor, hex) => (0, _color.default)(hex).lighten(factor).hex());
exports.lighten = lighten;

function calculateTransparentColor(foregroundColor, backgroundColor, opacity) {
  if (opacity < 0.0 || opacity > 1.0) {
    console.log('assertion, opacity should be between 0 and 1');
  }

  opacity = opacity * 1.0; // to make it float

  let foregroundRGB = colorHexToRGB(foregroundColor);
  let backgroundRGB = colorHexToRGB(backgroundColor);
  let finalRed = Math.round(backgroundRGB.r * (1 - opacity) + foregroundRGB.r * opacity);
  let finalGreen = Math.round(backgroundRGB.g * (1 - opacity) + foregroundRGB.g * opacity);
  let finalBlue = Math.round(backgroundRGB.b * (1 - opacity) + foregroundRGB.b * opacity);
  return colorRGBToHex(finalRed, finalGreen, finalBlue);
}

const COLOR_REGEX = /^#([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})$/;

function colorHexToRGB(htmlColor) {
  let arrRGB = htmlColor.match(COLOR_REGEX);

  if (arrRGB == null) {
    console.log('Invalid color passed, the color should be in the html format. Example: #ff0033');
  }

  let red = parseInt(arrRGB[1], 16);
  let green = parseInt(arrRGB[2], 16);
  let blue = parseInt(arrRGB[3], 16);
  return {
    r: red,
    g: green,
    b: blue
  };
}

function colorRGBToHex(red, green, blue) {
  if (red < 0 || red > 255 || green < 0 || green > 255 || blue < 0 || blue > 255) {
    console.log('Invalid color value passed. Should be between 0 and 255.');
  }

  let hexRed = formatHex(red.toString(16));
  let hexGreen = formatHex(green.toString(16));
  let hexBlue = formatHex(blue.toString(16));
  return '#' + hexRed + hexGreen + hexBlue;
}

function formatHex(value) {
  value = value + '';

  if (value.length === 1) {
    return '0' + value;
  }

  return value;
}