/* @flow */
import React from 'react';
import { TickLine, TickLabel } from './styled';

type Props = {
  tick: Object,
  count: Number,
  format: Function,
};

const Tick = ({ tick, count, format = d => d }: Props) => (
  <div>
    <TickLine tick={tick} />
    <TickLabel tick={tick} count={count}>
      {format(tick.value)}
    </TickLabel>
  </div>
);

export default Tick;
