/* @flow */
import { simpleAction, sessionOwnerInitialState } from '@sharkfinesse/sfl-lib';
const namespace = (name: string): string => `@@session.owner/${name}`;

//Actions
export const types = {
  UPDATE: namespace('UPDATE'),
};

//Reducer
const initialState = sessionOwnerInitialState;

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE:
      return action.payload;
    default:
      return state;
  }
};

//Action Creators
const updateOwner = simpleAction(types.UPDATE);

export const actionCreators = {
  updateOwner,
};

export default reducer;
