/* @flow */
import {
  append,
  createReducer,
  equals,
  map,
  removeUnlinkedIds,
  reject,
  simpleAction,
  without,
  simpleActionMeta,
  sessionDefaultSessionValuesInitialState,
  omit,
  isEmpty,
} from '@sharkfinesse/sfl-lib';
const namespace = (name: string): string => `@@session.defaultSessionValues/${name}`;

// types
export const types = {
  PRISTINE_CHANGE: namespace('PRISTINE_CHANGE'),
  CHANGE: namespace('CHANGE'),
  CHANGE_ALL: namespace('CHANGE_ALL'),
  CALCULATE: namespace('CALCULATE'),
  LINK_INPUT: namespace('LINK_INPUT'),
  LINK_INPUT_COMPLETE: namespace('LINK_INPUT_COMPLETE'),
  UNLINK_INPUT: namespace('UNLINK_INPUT'),
  DELETE: {
    EVAL: namespace('DELETE.EVAL'),
    ALL: namespace('DELETE.ALL'),
  },
  INFO: {
    SHOW: namespace('INFO.SHOW'),
    HIDE: namespace('INFO.HIDE'),
  },
  UPDATE: namespace('UPDATE'),
  UPDATE_COMPLETE: namespace('UPDATE_COMPLETE'),
  SYNC: namespace('SYNC'),
};

export const initialState = sessionDefaultSessionValuesInitialState;

const reducePristineChange = (state, action) => {
  const { value, defaultSessionValueId, evalUid, decimals, measurement, unit, multiYearValues } =
    action.payload;
  const { data, linked, schema, multiYearData } = state;
  return {
    ...state,
    data: {
      ...data,
      [defaultSessionValueId]: value,
    },
    ...(multiYearValues && {
      multiYearData: {
        ...multiYearData,
        [defaultSessionValueId]: multiYearValues,
      },
    }),
    linked: {
      ...linked,
      [defaultSessionValueId]: [evalUid],
    },
    schema: {
      ...schema,
      [defaultSessionValueId]: {
        decimals,
        measurement,
        defaultSessionValueId,
        ...(multiYearValues && { multiYear: true }),
        ...(unit && { unit }),
      },
    },
  };
};

const reduceChange = (state, { payload: { value, defaultSessionValueId, multiYearValues } }) => ({
  ...state,
  data: {
    ...state.data,
    [defaultSessionValueId]: value,
  },
  ...(multiYearValues && {
    multiYearData: {
      ...state.multiYearData,
      [defaultSessionValueId]: multiYearValues,

      ...omit([defaultSessionValueId], state.multiYearData),
      ...(!isEmpty(multiYearValues) && { [defaultSessionValueId]: multiYearValues }),
    },
  }),
});

const reduceCalculate = (state, action) => {
  const { evalUid, unlinkedIds, linkedIds } = action.payload;
  const { linked } = state;
  const newLinks = {
    ...linked,
    ...linkedIds,
  };
  return {
    ...state,
    linked: removeUnlinkedIds(evalUid, unlinkedIds, newLinks),
  };
};

const reduceLinkInput = (state, action) => {
  const { defaultSessionValueId, evalUid } = action.payload;
  const { linked } = state;
  return {
    ...state,
    linked: {
      ...linked,
      [defaultSessionValueId]: append(evalUid, linked[defaultSessionValueId]),
    },
  };
};

const reduceDeleteEval = (state, action) => {
  return {
    ...state,
    linked: map(reject(equals(action.values.evalUid)), state.linked),
  };
};

const reduceDeleteAll = (state, action) => {
  return {
    ...initialState,
  };
};

const reduceShowInfo = (state, action) => {
  return {
    ...state,
    showInfo: state.showInfo !== false ? true : false,
  };
};

const reduceHideInfo = (state, action) => {
  return {
    ...state,
    showInfo: false,
  };
};

const reduceUnlinkInput = (state, action) => {
  const { defaultSessionValueId, evalUid } = action.payload;
  const { linked } = state;
  return {
    ...state,
    linked: {
      ...linked,
      [defaultSessionValueId]: without(evalUid, linked[defaultSessionValueId]),
    },
  };
};

const reduceUpdate = (state, action) => {
  return action.payload.state;
};

const reduceSync = (state, action) => ({
  ...state,
  ...action.payload.data,
});

export default createReducer(
  {
    [types.PRISTINE_CHANGE]: reducePristineChange,
    [types.CHANGE]: reduceChange,
    [types.CHANGE_ALL]: reduceChange,
    [types.CALCULATE]: reduceCalculate,
    [types.LINK_INPUT]: reduceLinkInput,
    [types.UNLINK_INPUT]: reduceUnlinkInput,
    [types.DELETE.EVAL]: reduceDeleteEval,
    [types.DELETE.ALL]: reduceDeleteAll,
    [types.INFO.SHOW]: reduceShowInfo,
    [types.INFO.HIDE]: reduceHideInfo,
    [types.UPDATE]: reduceUpdate,
    [types.SYNC]: reduceSync,
  },
  initialState
);

//Action Creators
const updatePristineChange = simpleAction(types.PRISTINE_CHANGE);

const updateChange = simpleActionMeta(types.CHANGE);
const updateChangeAll = simpleActionMeta(types.CHANGE_ALL);

const updateCalculate = simpleActionMeta(types.CALCULATE);

const updateDelete = (evalUid, linked) => {
  const values = {
    evalUid,
    linked,
  };
  return {
    type: types.DELETE.EVAL,
    values,
  };
};

const updateLinkInput = simpleAction(types.LINK_INPUT);
const updateUnlinkInput = simpleAction(types.UNLINK_INPUT);

const updateShowInfo = simpleAction(types.INFO.SHOW);
const updateHideInfo = () => ({ type: types.INFO.HIDE });
const updateDeleteAll = simpleActionMeta(types.DELETE.ALL);
const update = simpleAction(types.UPDATE);
const updateComplete = simpleAction(types.UPDATE_COMPLETE);
const updateLinkInputComplete = simpleAction(types.LINK_INPUT_COMPLETE);
const sync = simpleActionMeta(types.SYNC);

export const actionCreators = {
  updateCalculate,
  updateChange,
  updateChangeAll,
  updateDelete,
  updateDeleteAll,
  updateHideInfo,
  updateLinkInput,
  updatePristineChange,
  updateShowInfo,
  updateUnlinkInput,
  update,
  updateComplete,
  updateLinkInputComplete,
  sync,
};
