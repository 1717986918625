/* @flow */
import React, { useState, forwardRef } from 'react';
import type { Element } from 'react';
import 'styled-components/macro';
import Button from '../button';
import { IconAnimated } from '../icon/icons';

type Props = {
  icon?: Element<*> | String,
  iconPosition: 'right' | 'left',
  ...other,
};

const AnimatedIconButton = ({ icon, iconPosition = 'right', children, ...other }: Props, ref) => {
  const [playing, setPlaying] = useState('stopped');
  const handleLottieMouseLeave = () => {
    setPlaying('stopped');
  };
  const handleLottieMouseEnter = () => {
    setPlaying('playing');
  };

  return (
    <Button
      onMouseEnter={handleLottieMouseEnter}
      onMouseLeave={handleLottieMouseLeave}
      buttonRef={ref}
      {...other}
    >
      {iconPosition === 'right' && children}
      <div
        css={`
          ${iconPosition === 'left' && 'margin-right: 8px'}
          ${iconPosition === 'right' && 'margin-left: 8px'}
        `}
      >
        <IconAnimated
          playingState={playing}
          speed={1}
          direction={1}
          primaryColor="inherit"
          size="width: 16px; height: 16px;"
          animationData={icon}
          css={`
            display: flex;
          `}
        />
      </div>
      {iconPosition === 'left' && children}
    </Button>
  );
};

export default forwardRef(AnimatedIconButton);
