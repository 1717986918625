"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.nthArgs = void 0;

var _fp = require("./fp");

const nthArgsCurryCount = 2;
const nthArgs = (0, _fp.curryN)(nthArgsCurryCount, (0, _fp.pipe)(_fp.slice, _fp.unapply));
exports.nthArgs = nthArgs;