/* @flow */
import styled from 'styled-components';
import { WIDTH_ENUM } from './width';
import { animated } from 'react-spring';
import applyTheme from '../../styles/apply-theme';

export const modalWidth = ({
  widthName,
  widthValue,
}: {
  widthName?: string,
  widthValue?: string | number,
}) => {
  if (typeof widthValue === 'number') {
    return `${widthValue}px`;
  }

  if (widthName === 'fullscreen') return `${WIDTH_ENUM.widths[widthName]}`;

  return widthName ? `${WIDTH_ENUM.widths[widthName]}px` : widthValue || 'auto';
};
export const modalHeight = ({ heightValue }: { heightValue?: string | number }) => {
  if (typeof heightValue === 'number') {
    return `${heightValue}px`;
  }

  return heightValue || 'auto';
};

const theme = applyTheme('modal');
export const Blanket = theme('blanket', styled(animated.div));
export const ModalBody = theme('body');
export const ModalContainer = theme('container', styled(animated.div));
export const ModalFooter = theme('footer');
export const ModalBodyHeight = theme('bodyHeight');
export const ModalBodyFullHeight = theme('fullHeight');
export const LoadingWrapper = theme('loadingWrapper');
export const BodyWrapper = theme('bodyPadding');
export const FooterSpaceBetween = theme('footerSpaceBetween');
export const ResponsiveFooterSpaceBetween = theme('responsiveFooterSpaceBetween');
export const ModalBodyFullHeightMax = theme(['fullHeight', 'maxHeight']);
export const Positioner = theme(
  'positioner',
  styled.div.attrs(props => ({ style: { width: modalWidth(props) } }))
);
