import {
  reduce,
  map,
  filter,
  propEq,
  keys,
  path,
  uniq,
  isEmpty,
  omit,
  prop,
  questionnaireSelectors,
  isNil,
} from '@sharkfinesse/sfl-lib';
import { select, put, all } from 'redux-saga/effects';
import { actionCreators as questionnaireActionCreators } from 'redux/modules/questionnaire/questionnaire';

export function* update(action) {
  const { id, measurement, decimals, value, unit, multiYear } = action.payload;
  const [
    layout,
    benefitData,
    submittedBenefitData,
    defaultSessionValues,
    submittedDefaultSessionValues,
  ] = yield all([
    select(questionnaireSelectors.getLayout),
    select(questionnaireSelectors.getBenefitData),
    select(questionnaireSelectors.getSubmittedBenefitData),
    select(questionnaireSelectors.getDefaultSessionValues),
    select(questionnaireSelectors.getSubmittedDefaultSessionValues),
    select(questionnaireSelectors.getShowValuesConfig),
  ]);

  const benefitsToUpdate = [];
  map(suite => {
    map(benefit => {
      const propertyId = filter(propEq('defaultSessionValueId', id), benefit.schema.properties);

      if (!isEmpty(propertyId)) {
        const key = keys(propertyId)[0];

        const currentDefaultValue = submittedDefaultSessionValues?.data?.[id]
          ? submittedDefaultSessionValues?.data[id]
          : defaultSessionValues?.data?.[id];
        const currentBenefitValue = submittedBenefitData?.[benefit.id]?.values?.[key]
          ? submittedBenefitData?.[benefit.id]?.values?.[key]
          : benefitData?.[benefit.id]?.values?.[key];

        if (currentDefaultValue === currentBenefitValue || isNil(currentBenefitValue))
          benefitsToUpdate.push({ id: benefit.id, key });
      }
    })(suite.benefits);
  })(layout);

  const linkedIds = submittedDefaultSessionValues?.linked?.[id]
    ? submittedDefaultSessionValues?.linked?.[id]
    : [];
  const shouldDelete = isEmpty(value);
  const newDefaultSessionValues = {
    ...submittedDefaultSessionValues,
    schema: {
      ...omit([id], submittedDefaultSessionValues?.schema),
      ...(!shouldDelete && {
        [id]: {
          decimals,
          measurement,
          ...(unit && { unit }),
          ...(multiYear && { multiYear }),
        },
      }),
    },
    linked: {
      ...omit([id], submittedDefaultSessionValues?.linked),
      ...(!shouldDelete && {
        [id]: uniq([...linkedIds, ...map(benefit => path(['id'], benefit))(benefitsToUpdate)]),
      }),
    },
    data: {
      ...omit([id], submittedDefaultSessionValues?.data),
      ...(!shouldDelete && { [id]: value }),
    },
  };

  const ids = map(prop('id'))(benefitsToUpdate);

  const updatedBenefits = {
    ...omit(ids, submittedBenefitData),
    ...reduce((obj, benefit) => {
      const { id: benefitId, key } = benefit;

      const valuesObj = {
        ...omit([key], submittedBenefitData?.[benefitId]?.values),
        ...(!shouldDelete && { [key]: value }),
      };
      const benefitObject = {
        ...omit(['values'], submittedBenefitData?.[benefitId]),
        ...(!isEmpty(valuesObj) && { values: valuesObj }),
      };

      return {
        ...omit([benefitId], obj),
        ...((benefitObject?.notes || benefitObject?.values) && {
          [benefitId]: benefitObject,
        }),
      };
    }, {})(benefitsToUpdate),
  };

  yield all([
    put(questionnaireActionCreators.submitBenefitData(updatedBenefits)),
    put(
      questionnaireActionCreators.submitDefaultSessionValues(
        isEmpty(newDefaultSessionValues.data) ? {} : newDefaultSessionValues
      )
    ),
  ]);
}

export default update;
