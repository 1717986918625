/* @flow */
/* eslint-disable max-len */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <rect x="2" y="3" fill="none" width="20" height="18" />
    <path
      fill="currentColor"
      d="M20,3C20,3,20,3,20,3H4C2.9,3,2,3.9,2,5c0,0,0,0,0,0v14c0,1.1,0.9,2,2,2c0,0,0,0,0,0h16c1.1,0,2-0.9,2-2
	c0,0,0,0,0,0V5C22,3.9,21.1,3,20,3z M20,19H4V5h16V19z"
    />
    <rect x="6" y="15" fill="currentColor" width="12" height="2" />
    <rect x="6" y="7" fill="inherit" width="4" height="6" />
    <rect x="12" y="7" fill="currentColor" width="6" height="2" />
    <rect x="12" y="11" fill="currentColor" width="6" height="2" />
  </Icon>
);

export default memo(IconComponent);
