import { css } from 'styled-components';
import styledBreakpoint from 'styled-components-breakpoint';

export const Header = ({ font, colors, scale }) => ({
  base: () => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 50px;
    background: ${colors.white};
  `,
  h1: ({ headerIcon }) => css`
    margin: 0 8px;
    color: ${colors.uiDarkest};
    font-weight: ${font.weightSemiBold};
    font-size: ${font.sizeLarge};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: center;
  `,
  iconWrapper: () => css`
    ${styledBreakpoint('xxs')`
    margin-left: 1px;
  `};
    ${styledBreakpoint('sm')`
    margin-left: ${scale.minusOne};
  `};
  `,
  iconColor: colors.white,
  menuIconColor: colors.ui,
  iconBackgroundColor: `background: ${colors.primary}`,
  iconVariant: 'light',
  dividerVariant: 'light',
  animatedIcons: {
    primary: colors.uiDarkest,
    secondary: colors.primary,
  },
  toolbar: () => css`
    background: ${colors.white};
  `,

  titleWrapper: () => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
  `,
  headingWrapper: () => css`
    display: flex;
    flex-direction: row;
    overflow: hidden;
  `,
});

export default Header;
