import { useQuery } from 'react-query';
import firebase from 'firebase/app';
import { avatarUrl, loginSelectors } from '@sharkfinesse/sfl-lib';
import { useSelector } from 'react-redux';

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
const getAvatar = async ({ uid, email, size, user }) => {
  const gravatarUrl = avatarUrl({
    email,
    size: size * 4,
  });

  try {
    if (uid === user.uid) {
      if (user.photoURL) {
        if (!user.photoURL.includes('gravatar.com')) return { type: 'native', src: user.photoURL };
      }
      const gravatar = await fetch(gravatarUrl);
      if (gravatar.ok) return { type: 'gravatar', src: gravatarUrl };
      return null;
    }
  } catch (error) {
    return null;
  }

  try {
    const avatarUrl = await firebase.storage().ref(`avatar/${uid}/avatar.jpeg`).getDownloadURL();
    return { type: 'app', src: avatarUrl };
  } catch (error) {
    const gravatar = await fetch(gravatarUrl);

    if (gravatar.ok) return { type: 'gravatar', src: gravatarUrl };
    return null;
  }
};

const useAvatar = ({ uid, email, size = 26 }, config) => {
  const user = useSelector(loginSelectors.getUser);

  return useQuery(`avatar_${uid}`, () => getAvatar({ uid, email, size, user }), {
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: 0,
    staleTime: twentyFourHoursInMs,
  });
};

export default useAvatar;
