"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const initialState = {
  loading: false,
  loggedIn: null,
  user: null,
  complete: true
};
var _default = initialState;
exports.default = _default;