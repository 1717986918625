import { put, all, call } from 'redux-saga/effects';
import { actionCreators as appActionCreators } from 'redux/modules/app';
import waitFor from '../wait-for';
import { history } from '../../../index';
import { persistenceSelectors } from '@sharkfinesse/sfl-lib';

export function* leave(action) {
  yield all([
    put(appActionCreators.updateLoadingText('Saving, please wait...')),
    put(appActionCreators.updateLoadingActive(true)),
  ]);

  yield call(waitFor, state => persistenceSelectors.getUnsavedChanges(state) !== true);
  yield call([history, history.push], {
    pathname: action.payload.url,
  });
  yield all([
    put(appActionCreators.updateLoadingText('')),
    put(appActionCreators.updateLoadingActive(false)),
  ]);
}

export default leave;
