"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.recurringCosts = exports.generateQuarterlyRecurringCostCashflow = exports.generateMonthlyRecurringCostCashflow = exports.generateAnnualRecurringCostCashflow = void 0;

var _fp = require("./fp");

var _cashflow = require("./cashflow");

var _units = require("./units");

var _evaluatorValidate = require("./evaluator-validate");

// prettier-ignore
const generateMonthlyRecurringCostCashflow = (0, _fp.curry)((period, obj) => (0, _cashflow.monthlyCashflowWithStepGrowth)(period, obj.growth, (0, _units.secondsToMonths)(obj.start), (0, _units.secondsToMonths)(obj.end), obj.cost));
exports.generateMonthlyRecurringCostCashflow = generateMonthlyRecurringCostCashflow;
const generateQuarterlyRecurringCostCashflow = (0, _fp.curry)((period, obj) => (0, _cashflow.quarterlyCashflowWithStepGrowth)(period, obj.growth, (0, _units.secondsToMonths)(obj.start), (0, _units.secondsToMonths)(obj.end), obj.cost));
exports.generateQuarterlyRecurringCostCashflow = generateQuarterlyRecurringCostCashflow;
const generateAnnualRecurringCostCashflow = (0, _fp.curry)((period, obj) => (0, _cashflow.annualCashflowWithStepGrowth)(period, obj.growth, (0, _units.secondsToMonths)(obj.start), (0, _units.secondsToMonths)(obj.end), obj.cost));
exports.generateAnnualRecurringCostCashflow = generateAnnualRecurringCostCashflow;

const recurringCosts = (period, costObjNoCashflow) => {
  const addEmptyCashflow = x => {
    return { ...x,
      cashflow: []
    };
  };

  let costObjCosts = (0, _fp.map)(addEmptyCashflow, costObjNoCashflow.costs);
  const costObj = { ...costObjNoCashflow,
    costs: costObjCosts
  };
  const resultLens = (0, _fp.lensProp)('result');
  const cashflowLens = (0, _fp.lensProp)('cashflow');
  const costLens = (0, _fp.lensProp)('cost');
  const startLens = (0, _fp.lensProp)('start');
  const endLens = (0, _fp.lensProp)('end');
  const growthLens = (0, _fp.lensProp)('growth');
  const pristineLens = (0, _fp.lensProp)('pristine');
  const editedLens = (0, _fp.lensProp)('cashflowEdited');

  const setCostCashflow = cost => {
    //If pristine
    if ((0, _fp.view)(pristineLens, cost) === true) {
      return cost;
    }

    const updateCashflow = obj => {
      if (obj.cost === '' || obj.cost === null || obj.cost === undefined || !(0, _evaluatorValidate.validateStart)((0, _units.secondsToMonths)(obj.start), (0, _units.secondsToMonths)(obj.end)) || !(0, _evaluatorValidate.validateEnd)((0, _units.secondsToMonths)(obj.start), (0, _units.secondsToMonths)(obj.end), period)) {
        return (0, _fp.set)(cashflowLens, [])(obj);
      }

      let cashflow = [];

      if (obj.recurrence === 'yearly') {
        cashflow = generateAnnualRecurringCostCashflow(period, obj);
      } else if (obj.recurrence === 'quarterly') {
        cashflow = generateQuarterlyRecurringCostCashflow(period, obj);
      } else {
        cashflow = generateMonthlyRecurringCostCashflow(period, obj);
      }

      return (0, _fp.set)(cashflowLens, cashflow)(obj);
    }; // If the cost is not pristine and it has an unedited cashflow generate a one time cashflow


    if ((0, _fp.view)(pristineLens, cost) === false && (0, _fp.view)(editedLens, cost) !== true) {
      let start = 1;
      let end = period;
      let growth = 0;

      if (cost.start !== '-') {
        start = cost.start;
      }

      if (cost.end !== '-') {
        end = cost.end;
      }

      if (cost.growth !== '-') {
        growth = cost.growth;
      }

      const updatedCost = (0, _fp.pipe)((0, _fp.set)(startLens, start), (0, _fp.set)(endLens, end), (0, _fp.set)(growthLens, growth), updateCashflow)(cost);
      return updatedCost;
    } // If edited cashflow return without with calculated cost and no impact month


    return (0, _fp.pipe)((0, _fp.set)(costLens, (0, _fp.sum)((0, _fp.view)(cashflowLens, cost))), (0, _fp.set)(startLens, '-'), (0, _fp.set)(endLens, '-'), (0, _fp.set)(growthLens, '-'))(cost);
  }; // Update costs based on cashflow


  const transform = {
    costs: costs => (0, _fp.map)(setCostCashflow, costs)
  };
  const updateCosts = (0, _fp.evolve)(transform, costObj); // Caclulte final result

  const calculateResult = (accum, key) => (0, _fp.add)((0, _fp.sum)(updateCosts.costs[key].cashflow), accum);

  let result = null; // Only if there more than one cost should it calculate

  if (costObj.ids.length > 1) {
    result = result = (0, _fp.divide)((0, _fp.reduce)(calculateResult, 0, costObj.ids), period);
  }

  return (0, _fp.set)(resultLens, result, updateCosts);
};

exports.recurringCosts = recurringCosts;