import { css } from 'styled-components';

const LinkButton = ({ font, colors, scale }) => ({
  base: () => css`
    background: none !important;
    color: ${colors.primary};
    border: none;
    padding: 0 !important;
    font: inherit;
    &:hover {
      text-decoration: underline;
    }
    cursor: pointer;
  `,
});

export default LinkButton;
