import { css } from 'styled-components';
import { setAlphaHsla } from '@sharkfinesse/sfl-lib';

export const ListPanel = ({
  font,
  colors,
  scale,
  device: { min_xxs, min_xs, min_sm, min_md },
}) => ({
  headerImageContainer: () => css`
    position: relative;
    width: 100%;
    height: 80px;
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(to bottom, transparent 0%, hsl(0deg 0% 0% / 40%) 90%);
    }
  `,
  headerImage: () => css`
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    pointer-events: none;
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
  `,
  headerContent: () => css`
    position: absolute;
    display: flex;
    color: white;
    height: 80px;
    align-items: flex-end;
    padding-bottom: 6px;
    width: 100%;
    padding-left: 10px;
  `,
  headerLabelsContainer: () => css`
    display: flex;
    flex-direction: column;
  `,
  headerCategory: () => css`
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    margin: 0;
    font-weight: ${font.weightNormal};
    letter-spacing: 1px;
  `,
  headerTitle: () => css`
    font-size: 26px;
    line-height: 32px;
    margin: 0;
    font-weight: ${font.weightSemiBold};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  `,
  bodyWrapper: () => css`
    padding: 0;
    margin: 10px;
    list-style: none;
  `,
  itemRow: () => css`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    transition: background 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    &:hover {
      background: ${setAlphaHsla(0.2, colors.primary)};
      border-radius: 4px;
      border: 0;
    }
  `,
  itemLabelContainer: () => css`
    display: flex;
    flex-direction: column;
  `,
  itemLabel: () => css`
    color: ${font.color};
    font-size: 14px;
    font-weight: ${font.weightSemiBold};
  `,
  itemSubLabel: () => css`
    color: ${colors.ui};
    font-size: 12px;
    font-weight: ${font.weightNormal};
  `,
  itemIcon: () => css`
    width: 22px;
    height: 22px;
    color: ${font.color};
  `,
  pill: () => css`
    padding: 1px 6px;
    background-color: ${colors.primary};
    border-radius: 20px;
    font-size: 11px;
    color: ${colors.white};
    margin-left: 8px;
  `,
  panel: () => css`
    padding: 0px;
    margin-bottom: 18px;
    border-radius: 12px;
  `,
  notificationContainer: () => css`
    padding: 10px;
    @media ${min_sm} {
      padding: 20px;
    }
    @media ${min_md} {
      padding: 20px 34px 0 34px;
    }
    width: 100%;
  `,
});

export default ListPanel;
