"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reselect = require("reselect");

var _session = _interopRequireDefault(require("./session"));

var _evaluator = _interopRequireDefault(require("./evaluator"));

var _index = require("../../index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* eslint-disable no-unused-vars  */
const getDefaultSessionValues = state => {
  return state.session.present.defaultSessionValues;
};

const getDefaultSessionValuesData = state => {
  return state.session.present.defaultSessionValues.data;
};

const getDefaultSessionValuesMultiYearData = state => {
  return state.session.present.defaultSessionValues.multiYearData;
};

const getDefaultSessionValuesSchema = state => {
  return state.session.present.defaultSessionValues.schema;
};

const getDefaultSessionValuesLinked = state => {
  return state.session.present.defaultSessionValues.linked;
};

const getShowInfo = state => {
  return state.session.present.defaultSessionValues.showInfo;
};

const getLinked = (state, id) => {
  return state.session.present.defaultSessionValues.linked[id];
};

const getMultiYearData = (state, id) => {
  var _state$session$presen, _state$session$presen2, _state$session$presen3;

  return (_state$session$presen = state.session.present) === null || _state$session$presen === void 0 ? void 0 : (_state$session$presen2 = _state$session$presen.defaultSessionValues) === null || _state$session$presen2 === void 0 ? void 0 : (_state$session$presen3 = _state$session$presen2.multiYearData) === null || _state$session$presen3 === void 0 ? void 0 : _state$session$presen3[id];
};

const getHasDefaultSessionValues = (0, _reselect.createSelector)([getDefaultSessionValuesLinked], defaultValuesLinked => (0, _index.isNotEmpty)(defaultValuesLinked));
const selectors = {
  getDefaultSessionValues,
  getDefaultSessionValuesData,
  getDefaultSessionValuesMultiYearData,
  getDefaultSessionValuesSchema,
  getDefaultSessionValuesLinked,
  getShowInfo,
  getHasDefaultSessionValues,
  getLinked,
  getMultiYearData
};
var _default = selectors;
exports.default = _default;