// @flow
/* eslint-disable no-magic-numbers, react/prop-types */
import React, { useContext } from 'react';
import { useWindowSize } from 'hooks';
import {
  SlideBox,
  SlideFooter,
  Positioner,
  HoverContent,
  Toolbar,
  ToolbarContent,
  FooterContent,
} from './styled';
import { useTransition } from 'react-spring';
import * as easings from 'd3-ease';
import SlideBody from './slide-body';
import { SlideContext } from './slide';

const SlideCardHovered = ({
  imageUrl,
  iconUrl,
  icon,
  slideCardRef,
  setHovered,
  hovered,
  animating,
  setAnimating,
  onCheckChange,
  checked,
  tag,
  checkBoxVisibility,
  bounds,
  hideFooterBorder,
  ...other
}) => {
  const { toolbarItems, hoverContent, hoverFooter } = useContext(SlideContext);
  const currentImageHeight = slideCardRef && slideCardRef?.current?.children[0]?.offsetHeight;
  const currentFooterHeight = slideCardRef && slideCardRef?.current?.children[1]?.offsetHeight;
  const { windowHeight, windowWidth } = useWindowSize();
  const currentPos = slideCardRef && slideCardRef.current.getBoundingClientRect();
  const { width, height, top, left } = currentPos;
  const slideMargin = 4;
  const zoomImageHeight = 120;
  const zoomWidth = 480;
  const zoomHeight = toolbarItems ? 410 : hoverFooter ? 416 : 370;
  const newLeft = left - (zoomWidth / 2 - width / 2);
  const newTop = top - (zoomHeight / 2 - height / 2);
  const footerHeight = zoomHeight - zoomImageHeight;
  let zoomLeft = newLeft <= 0 ? slideMargin : newLeft;
  let zoomTop = newTop <= 0 ? 10 : newTop;

  if (bounds?.current) {
    const boundsPos = bounds.current.getBoundingClientRect();

    if (zoomLeft + zoomWidth > boundsPos.x + boundsPos.width) {
      zoomLeft = boundsPos.x + boundsPos.width - zoomWidth - slideMargin - 10;
    }
    if (zoomLeft < boundsPos.x) {
      zoomLeft = boundsPos.x + 14;
    }
  } else {
    if (zoomLeft + zoomWidth > windowWidth) {
      zoomLeft = windowWidth - zoomWidth - slideMargin;
    }
  }

  if (zoomTop + zoomHeight > windowHeight) {
    zoomTop = windowHeight - zoomHeight - 10;
  }

  const transition = useTransition(hovered, {
    from: { t: 0 },
    enter: { t: 1 },
    leave: { t: 0 },
    config: {
      duration: 300,
      easing: easings.easeCubicOut,
    },
    onRest: () => {
      setAnimating(false);
    },
  });

  const fadeIn = useTransition(hovered, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 200,
  });

  return transition(
    ({ t }, item) =>
      item && (
        <Positioner
          style={{
            width: t.to({ output: [`${width}px`, `${zoomWidth}px`] }),
            height: t.to({ output: [`${height}px`, `${zoomHeight}px`] }),
            top: t.to({ output: [top, zoomTop] }),
            left: t.to({ output: [left, zoomLeft] }),
          }}
          {...other}
        >
          <SlideBox
            style={{
              boxShadow: t.to({
                output: ['rgba(0, 0, 0, 0.16) 0px 0px 0px', 'rgba(0, 0, 0, 0.16) 0px 6px 12px'],
              }),
            }}
            $hovered
          >
            <SlideBody
              imageUrl={imageUrl}
              iconUrl={iconUrl}
              icon={icon}
              onCheckChange={onCheckChange}
              checked={checked}
              checkBoxVisibility={hovered ? checkBoxVisibility : 'hidden'}
              style={{
                height: t.to({ output: [currentImageHeight, zoomImageHeight] }),
              }}
              tag={tag}
            />
            <SlideFooter
              $hideFooterBorder={hideFooterBorder}
              style={{
                height: t.to({ output: [currentFooterHeight, footerHeight] }),
              }}
            >
              <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
                {fadeIn((props, hoverItem) => (
                  <HoverContent style={hovered ? props : { opacity: 0 }}>
                    {hoverContent}
                  </HoverContent>
                ))}
                {toolbarItems && (
                  <Toolbar>
                    {fadeIn((props, item) => (
                      <ToolbarContent style={hovered ? props : { opacity: 0 }}>
                        {toolbarItems}
                      </ToolbarContent>
                    ))}
                  </Toolbar>
                )}
                {hoverFooter && (
                  <Toolbar>
                    {fadeIn((props, item) => (
                      <FooterContent style={hovered ? props : { opacity: 0 }}>
                        {hoverFooter}
                      </FooterContent>
                    ))}
                  </Toolbar>
                )}
              </div>
            </SlideFooter>
          </SlideBox>
        </Positioner>
      )
  );
};

export default SlideCardHovered;
