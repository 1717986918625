// @flow

import React, { useState } from 'react';
import styled from 'styled-components';
import { useTransition, animated } from 'react-spring';

export const Container = styled(animated.div)`
  position: absolute;
  color: white;
  top: 0;
  right: 0;
  padding: 0px 3px 4px 6px;
  line-height: 13px;
  font-size: 13px;
  pointer-events: auto;
  border-bottom-left-radius: 8px;
  letter-spacing: 1px;
`;

const Avatar = ({ initials, name, color, zIndex, xPos }) => {
  const [toggle, setToggle] = useState(false);
  const transitions = useTransition(toggle, {
    from: { position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    reverse: toggle,
  });

  return transitions(({ opacity }, item) =>
    item ? (
      <Container
        onMouseLeave={() => setToggle(false)}
        onMouseEnter={() => setToggle(true)}
        style={{
          zIndex: 100,
          backgroundColor: `${color}`,
          paddingRight: `${xPos}px`,
          opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }),
        }}
      >
        {name}
      </Container>
    ) : (
      <Container
        onMouseLeave={() => setToggle(false)}
        onMouseEnter={() => setToggle(true)}
        style={{
          zIndex: zIndex,
          right: xPos,
          backgroundColor: `${color}`,
          opacity: opacity.to({ range: [1.0, 0.0], output: [1, 0] }),
        }}
      >
        {initials}
      </Container>
    )
  );
};

export default Avatar;
