/* eslint-disable prettier/prettier */
import Colors from './colors';
import Scale from './scale';

const Font = () => {
  const colors = Colors();
  const scale = Scale();
  return {
    color: colors.uiDarkest,
    family:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;',
    size: '16px',
    sizeSmall: scale.minusOne,
    sizeNormal: scale.zero,
    sizeLarge: scale.one,
    weightLight: 300,
    weightNormal: 400,
    weightSemiBold: 600,
    weightBold: 700,
    lineHeightSmall: 1,
    lineHeightNormal: 1.5,
    lineHeightLarge: 2,
  };
};

export default Font;
