import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { types as sessionDetailsTypes } from 'redux/modules/session-details';
import { actionCreators as recurringCostsActionCreators } from 'redux/modules/recurring-costs';
import { actionCreators as cashflowActionCreators } from 'redux/modules/cashflow';
import { actionCreators as sessionDetailsActionCreators } from 'redux/modules/session-details';
import { actionCreators as evaluatorActionCreators } from 'redux/modules/evaluator';
import { actionCreators as defaultSessionValuesActionCreators } from 'redux/modules/default-session-values';
import {
  benefitsSelectors,
  evaluatorSelectors,
  map,
  nonRecurringCosts,
  nonRecurringCostsSelectors,
  recurringCostsSelectors,
  sessionDetailsSelectors,
  defaultSessionValuesSelectors,
  keys,
  concat,
  last,
  length,
  max,
  monthToYear,
  repeat,
  slice,
  sum,
} from '@sharkfinesse/sfl-lib';

import calculateEvaluator from './evaluator/calculateEvaluator';

function* updateRecurringCostEnd({ oldPeriod, endMonth: value, id, meta }) {
  const endMonth = yield select(recurringCostsSelectors.getCostEnd, { id });
  if (oldPeriod === endMonth) {
    yield put(recurringCostsActionCreators.updateEndToDealPeriod({ id: id, value, meta }));
  }
}

function* updateNonRecurringCosts({ meta }) {
  const [reviewPeriod, currentCosts] = yield all([
    select(sessionDetailsSelectors.getReviewPeriod),
    select(nonRecurringCostsSelectors.getNonRecurringCosts),
  ]);
  const result = yield call(nonRecurringCosts, reviewPeriod, currentCosts);
  yield put(cashflowActionCreators.updateNonRecurringCostsResult({ ...result, meta }));
}
function* updateEvaluatorEnd({ endMonth, meta, benefitId }) {
  const [endMonthLinked, oldEndMonth, multiYearValues, multiYearDefaultSessionValues] = yield all([
    select(evaluatorSelectors.getEndMonthLinked, benefitId),
    select(evaluatorSelectors.getEvaluatorEndMonth, benefitId),
    select(evaluatorSelectors.getMultiYearValues, benefitId),
    select(defaultSessionValuesSelectors.getDefaultSessionValuesMultiYearData, benefitId),
  ]);
  yield call(calculateEvaluator, {
    id: benefitId,
    actionType: 'updateEndMonth',
    endMonth: endMonthLinked ? endMonth : oldEndMonth,
    meta,
  });
  yield all(
    map(
      fieldId =>
        put(
          evaluatorActionCreators.updateAllMultiYearValues({
            id: benefitId,
            fieldId,
            multiYearValues: concat(
              multiYearValues[fieldId],
              repeat(
                last(multiYearValues[fieldId]),
                max(0, monthToYear(endMonth) - length(multiYearValues[fieldId]))
              )
            ),
            average:
              sum(
                slice(
                  0,
                  monthToYear(endMonth),
                  concat(
                    multiYearValues[fieldId],
                    repeat(
                      last(multiYearValues[fieldId]),
                      max(0, monthToYear(endMonth) - length(multiYearValues[fieldId]))
                    )
                  )
                )
              ) / monthToYear(endMonth),
            meta,
          })
        ),
      keys(multiYearValues)
    )
  );

  yield all(
    map(
      fieldId =>
        put(
          defaultSessionValuesActionCreators.updateChangeAll({
            defaultSessionValueId: fieldId,
            value:
              sum(
                slice(
                  0,
                  monthToYear(endMonth),
                  concat(
                    multiYearDefaultSessionValues[fieldId],
                    repeat(
                      last(multiYearDefaultSessionValues[fieldId]),
                      max(0, monthToYear(endMonth) - length(multiYearDefaultSessionValues[fieldId]))
                    )
                  )
                )
              ) / monthToYear(endMonth),
            meta,
            multiYearValues: concat(
              multiYearDefaultSessionValues[fieldId],
              repeat(
                last(multiYearDefaultSessionValues[fieldId]),
                max(0, monthToYear(endMonth) - length(multiYearDefaultSessionValues[fieldId]))
              )
            ),
          })
        ),
      keys(multiYearDefaultSessionValues)
    )
  );
}

function* updateEvaluatorStart({ startMonth, meta, benefitId }) {
  const startMonthLinked = yield select(evaluatorSelectors.getStartMonthLinked, benefitId);
  if (startMonthLinked) {
    yield call(calculateEvaluator, {
      id: benefitId,
      actionType: 'updateStartMonth',
      startMonth,
      meta,
    });
  }
}

function* updateEndMonths({ meta, payload: { previous: oldPeriod, current: endMonth } }) {
  const [recurringCostIds, benefitIds] = yield all([
    select(recurringCostsSelectors.getIds),
    select(benefitsSelectors.getIds),
  ]);

  yield put(cashflowActionCreators.updateEdittedPeriodChange({ endMonth, meta }));
  yield call(updateNonRecurringCosts, { endMonth, meta });
  yield all(
    map(
      id =>
        call(updateRecurringCostEnd, {
          oldPeriod,
          endMonth,
          id,
          meta,
        }),
      recurringCostIds
    )
  );
  yield all(map(id => call(updateEvaluatorEnd, { endMonth, benefitId: id, meta }), benefitIds));
}

function* updateDefaultStartMonths({ meta, defaultStartMonth }) {
  const benefitIds = yield select(benefitsSelectors.getIds);

  yield all(
    map(
      id =>
        call(updateEvaluatorStart, {
          startMonth: defaultStartMonth,
          meta,
          benefitId: id,
        }),
      benefitIds
    )
  );
}
function* updateDefaultStartMonth({ meta, payload: { current: endMonth } }) {
  //if new period is less than the default start month then reduce it
  const defaultStartMonth = yield select(sessionDetailsSelectors.getDefaultStartMonthForDisplay);

  if (defaultStartMonth > endMonth)
    yield put(
      sessionDetailsActionCreators.updateDefaultStartMonth({ defaultStartMonth: endMonth, meta })
    );
}

export const effects = [
  takeLatest(sessionDetailsTypes.REVIEW_PERIOD.UPDATE, updateEndMonths),
  takeLatest(sessionDetailsTypes.REVIEW_PERIOD.UPDATE, updateDefaultStartMonth),
  takeLatest(sessionDetailsTypes.DEFAULT_START_MONTH.UPDATE, updateDefaultStartMonths),
];

export default effects;
