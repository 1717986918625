import { css } from 'styled-components';
import { setAlphaHsla } from '@sharkfinesse/sfl-lib';

const Tree = ({ font, colors, scale }) => ({
  base: () => css`

  .bordered.rc-tree li {
  
    margin: 0;
    list-style: none;
    white-space: nowrap;
    outline: 0;
    background: rgb(255 255 255 / 0.1);
    border-top: 1px solid ${colors.uiLight};
  }
  .bordered.rc-tree li .rc-tree-node-content-wrapper{
    margin: 0;
  }

  .bordered.rc-tree-node-content-wrapper.bordered.rc-tree-node-content-wrapper-open, 
  .bordered.rc-tree-node-content-wrapper.bordered.rc-tree-node-content-wrapper-close,
  .bordered.rc-tree-node-content-wrapper.bordered.rc-tree-node-content-wrapper-normal,
  .bordered.rc-tree li span.rc-tree-switcher.rc-tree-switcher_open,
  .bordered.rc-tree li span.rc-tree-switcher.rc-tree-switcher_close{
      padding: 6px 0;
      height: 36px
    }
.bordered.rc-tree-child-tree.bordered.rc-tree-child-tree-open{
   
      .bordered.rc-tree-switcher.bordered.rc-tree-switcher_close{
        margin-left: -5px
      }
}

  
    .rc-tree {
        margin: 0;
        padding: 0px;
      }
      .rc-tree li {
        padding: 0;
        margin: 0;
        list-style: none;
        white-space: nowrap;
        outline: 0;
      }
      .rc-tree li .draggable {
        color: #333;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        user-select: none;
        /* Required to make elements draggable in old WebKit */
        -khtml-user-drag: element;
        -webkit-user-drag: element;
      }
      .rc-tree li.drag-over > .draggable {
        background-color: #316ac5;
        color: white;
        border: 1px #316ac5 solid;
        opacity: 0.8;
      }
      .rc-tree li.drag-over-gap-top > .draggable {
        border-top: 2px blue solid;
      }
      .rc-tree li.drag-over-gap-bottom > .draggable {
        border-bottom: 2px blue solid;
      }
      .rc-tree li.filter-node > .rc-tree-node-content-wrapper {
        color: #a60000 !important;
        font-weight: bold !important;
      }
      .rc-tree li ul {
        margin: 0;
        padding: 0 0 0 41px;
      }
      .rc-tree li .rc-tree-node-content-wrapper {
        max-width: calc(100% - 50px);
        display: inline-flex;
        padding: 1px 3px 0 0;
        margin: 0;
        cursor: pointer;
        /*height: 17px;*/
        text-decoration: none;
        vertical-align: top;
        width: 100%;
      }
      .rc-tree li span.rc-tree-switcher,
      .rc-tree li span.rc-tree-checkbox,
      .rc-tree li span.rc-tree-iconEle {
        line-height: 18px;
        margin-right: 0;
        /* width: 24px;
        height: 24px; */
        display: inline-flex;
        vertical-align: middle;
        border: 0 none;
        cursor: pointer;
        outline: none;
        background-color: transparent;
        background-repeat: no-repeat;
        background-attachment: scroll;
      }
      .rc-tree li span.rc-tree-switcher.rc-tree-icon__customize,
      .rc-tree li span.rc-tree-checkbox.rc-tree-icon__customize,
      .rc-tree li span.rc-tree-iconEle.rc-tree-icon__customize {
        background-image: none;
        margin-right: 8px;
      }
      .rc-tree-iconEle{
        vertical-align: middle;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 22px;
      }
      .rc-tree-title{
        display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100%);
      }
  
      .rc-tree li span.rc-tree-icon_loading {
        margin-right: 2px;
        vertical-align: top;
          no-repeat scroll 0 0 transparent;
      }
      .rc-tree li span.rc-tree-switcher.rc-tree-switcher-noop {
        cursor: auto;
      }
      .rc-tree li span.rc-tree-switcher.rc-tree-switcher_open {
        background-position: -93px -56px;
      }
      .rc-tree li span.rc-tree-switcher.rc-tree-switcher_close {
        background-position: -75px -56px;
      }
      .rc-tree li span.rc-tree-checkbox {
        width: 13px;
        height: 13px;
        margin: 0 3px;
        background-position: 0 0;
      }
      .rc-tree li span.rc-tree-checkbox-checked {
        background-position: -14px 0;
      }
      .rc-tree li span.rc-tree-checkbox-indeterminate {
        background-position: -14px -28px;
      }
      .rc-tree li span.rc-tree-checkbox-disabled {
        background-position: 0 -56px;
      }
      .rc-tree li span.rc-tree-checkbox.rc-tree-checkbox-checked.rc-tree-checkbox-disabled {
        background-position: -14px -56px;
      }
      .rc-tree li span.rc-tree-checkbox.rc-tree-checkbox-indeterminate.rc-tree-checkbox-disabled {
        position: relative;
        background: #ccc;
        border-radius: 3px;
      }
      .rc-tree
        li
        span.rc-tree-checkbox.rc-tree-checkbox-indeterminate.rc-tree-checkbox-disabled::after {
        content: ' ';
        -webkit-transform: scale(1);
        transform: scale(1);
        position: absolute;
        left: 3px;
        top: 5px;
        width: 5px;
        height: 0;
        border: 2px solid #fff;
        border-top: 0;
        border-left: 0;
      }
      .rc-tree:not(.rc-tree-show-line) .rc-tree-switcher-noop {
        background: none;
      }
      .rc-tree.rc-tree-show-line li:not(:last-child) > ul {
          0 0 repeat-y;
      }
      .rc-tree.rc-tree-show-line li:not(:last-child) > .rc-tree-switcher-noop {
        background-position: -56px -18px;
      }
      .rc-tree.rc-tree-show-line li:last-child > .rc-tree-switcher-noop {
        background-position: -56px -36px;
      }
      .rc-tree-child-tree {
        display: block;
      }
      .rc-tree-child-tree-open {
        display: block;
      }
      .rc-tree-treenode-disabled > span:not(.rc-tree-switcher),
      .rc-tree-treenode-disabled > a,
      .rc-tree-treenode-disabled > a span {
        color: #767676;
        cursor: not-allowed;
      }
      .rc-tree-node-selected {
        background-color: #ffe6b0;
        border: 1px #ffb951 solid;
        opacity: 0.8;
      }
      .rc-tree-icon__open {
        margin-right: 2px;
        background-position: -110px -16px;
        vertical-align: top;
      }
      .rc-tree-icon__close {
        margin-right: 2px;
        background-position: -110px 0;
        vertical-align: top;
      }
      .rc-tree-icon__docu {
        margin-right: 2px;
        background-position: -110px -32px;
        vertical-align: top;
      }
      .rc-tree-icon__customize {
        margin-right: 2px;
        vertical-align: top;
      }
  
      .rc-tree li span.rc-tree-checkbox {
        display: none;
      }
      .rc-tree-child-tree {
        display: block;
      }
  
      .rc-tree-node-motion {
        display: block;
        transition: all 0.3s;
        overflow-y: hidden;
      }
  
      .rc-tree-node-motion-enter,
      .rc-tree-node-motion-leave-active {
        height: 0;
      }
      .rc-tree-treenode-switcher-open:hover, 
      .rc-tree-treenode-switcher-close:hover{
          background-color: ${setAlphaHsla(0.2, colors.primary)} !important;
  
      }
      .rc-tree-treenode-switcher-close{
        display: block;
      }
      .rc-tree-child-tree .rc-tree-treenode-switcher-close{
        padding-left: 5px
      }
      .rc-tree-child-tree-open .rc-tree-node-content-wrapper.rc-tree-node-content-wrapper-normal{
        height: 24px
      }
    `,
  toolBar: () => css`
    display: flex;
    margin-bottom: 10px;
    padding: 2px 0px;
  `,
  treeContainer: () => css`
    border: 1px solid ${colors.uiLight};
    border-radius: 3px;
  `,
  wrapper: () => css`
    padding: 0px 0px 0px 0px;
  `,
  deselectLinkWrapper: () => css`
    margin-left: 20px;
  `,
  treeHeader: () => css`
    background: rgb(0 0 0 / 0.1);
    padding: 6px 8px;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
  `,
});

export default Tree;
