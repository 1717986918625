import rsfApp from 'redux/rsf';

function getPolicy({ collection = 'privacy', id, language }) {
  const policy = rsfApp.firestore
    .collection(collection)
    .doc(id)
    .collection('policies')
    .where('id', 'in', ['en', language])
    .get();
  return policy;
}

export default getPolicy;
