/* @flow */
import React from 'react';
import styled from 'styled-components';
import getSize from './size';

export const IconWrapper = styled.span`
  ${getSize} color: ${(p) => p.primaryColor || 'currentColor'};
  display: inline-block;
  fill: ${(p) => p.secondaryColor || 'transparent'};
  line-height: 1;

  > svg {
    ${getSize} max-height: 100%;
    max-width: 100%;
    overflow: hidden;
    vertical-align: bottom;
  }
  stop {
    stop-color: currentColor;
  }
`;

export type IconProps = {
  children: React.Element<any> | string,
  size?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge',
  viewBox?: string,
  titleAccess?: string,
  primaryColor?: string,
  secondaryColor?: string,
  onClick?: () => mixed,
  tooltip?: Boolean,
};

const Icon = ({
  children,
  size = 'medium',
  primaryColor,
  secondaryColor,
  onClick,
  viewBox = '0 0 24 24',
  titleAccess,
  ...props
}: IconProps): React.Element<any> => {
  return (
    <IconWrapper
      onClick={onClick}
      size={size}
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
      id="icon-wrapper"
      {...props}
    >
      <svg
        focusable="false"
        viewBox={viewBox}
        aria-hidden={titleAccess ? 'false' : 'true'}
        xmlns="http://www.w3.org/2000/svg"
        id="icon-svg"
      >
        {titleAccess ? <title>{titleAccess}</title> : null}
        {children}
      </svg>
    </IconWrapper>
  );
};

export default Icon;
