import { select, call, all } from 'redux-saga/effects';
import rsfApp from 'redux/rsf';
import {
  mergeDeepLeft,
  isNil,
  isEmpty,
  sessionSelectors,
  preferencesSelectors,
  layoutSelectors,
  userSelectors,
  deviceSelectors,
} from '@sharkfinesse/sfl-lib';
import LogRocket from 'logrocket';
import { initialState as detailsInitialState } from 'redux/modules/session-details';
import { initialState as businessCaseInitialState } from 'redux/modules/business-case';
import { initialState as benefitsInitialState } from 'redux/modules/evaluator';
import { initialState as nonRecurringCostsInitialState } from 'redux/modules/non-recurring-costs';
import { initialState as recurringCostsInitialState } from 'redux/modules/recurring-costs';
import { initialState as businessPreparationInitialState } from 'redux/modules/business-preparation';
import { getNewSessionRef, getFileRef, sessionDoc_Set } from '../persistence/backend/session.js';
import { initialState as defaultSessionValuesInitialState } from 'redux/modules/default-session-values';
import { initialState as tenderInitialState } from 'redux/modules/tender';
import { initialState as doNothingInitialState } from 'redux/modules/do-nothing';
import { initialState as delayInitialState } from 'redux/modules/delay';
import { initialState as questionnaireInitialState } from 'redux/modules/questionnaire';

function* create({
  action: {
    template = false,
    session,
    sessionData,
    details,
    suiteList,
    layout,
    businessCase,
    businessPreparation,
    benefits,
    benefitsCashflow,
    nonRecurringCostsCashflow,
    recurringCostsCashflow,
    editted_benefitsCashflow,
    editted_nonRecurringCostsCashflow,
    editted_recurringCostsCashflow,
    nonRecurringCosts,
    recurringCosts,
    defaultSessionValues,
    delay,
    tender,
    doNothing,
    plugins,
    questionnaire,
  },
  folder,
  account,
  presetSuiteList,
}) {
  const date = new Date();
  const [
    user,
    state,
    preferencesSession = {},
    selectSuiteList,
    product,
    onlineState,
    reportSettings = {},
    autoImageEnabled = false,
    defaultSessionMedia,
    email,
    name,
  ] = yield all([
    select(userSelectors.getId),
    select(sessionSelectors.getSession),
    select(preferencesSelectors.getSession),
    select(layoutSelectors.getSuiteSelectList),
    select(userSelectors.getProduct),
    select(deviceSelectors.getState),
    select(preferencesSelectors.getReportSettings),
    select(preferencesSelectors.getAutoImageEnabled),
    select(preferencesSelectors.getDefaultSessionMedia),
    select(userSelectors.getEmail),
    select(userSelectors.getName),
  ]);

  const sessionRef = getNewSessionRef({ account });
  const id = sessionRef.id;
  const fileRef = getFileRef({ sessionRef });

  const visible =
    !isEmpty(presetSuiteList) && !isNil(presetSuiteList)
      ? [...presetSuiteList]
      : [{ label: 'Select all', value: '*' }, ...selectSuiteList];

  const activeSuite = { visible };

  const newSession = {
    owner: user,
    created: date,
    updated: date,
    updatedBy: user,
    updatedByName: name,
    updatedByEmail: email,
    updateAction: 'create',
    archived: false,
    account,
    product,
    id,
  };
  if (folder) {
    newSession.folder = folder;
  }
  if (session) {
    if (session.imageUrl) {
      if (!template) {
        newSession.imageUrl = session.imageUrl;
        if (session.imageId) newSession.imageId = session.imageId;
      } else {
        details.media = {};
      }
    }
    if (session.dealName) {
      newSession.dealName = template ? session.dealName : `${session.dealName} copy`;
      details.dealName = newSession.dealName;
    }
  } else if (autoImageEnabled) {
    detailsInitialState.media = { fetch: true };
  } else if (!isNil(defaultSessionMedia?.url)) {
    detailsInitialState.media = {
      src: defaultSessionMedia.src,
      url: defaultSessionMedia.url,
      id: defaultSessionMedia.id,
      type: 'photo',
      ...(defaultSessionMedia.position && { position: defaultSessionMedia.position }),
    };
    newSession.imageUrl = defaultSessionMedia.src;
    newSession.imageId = defaultSessionMedia.id;
  } else {
    detailsInitialState.media = {};
  }
  try {
    const firestore = rsfApp.firestore;
    const batch = yield call([firestore, firestore.batch]);

    yield all([
      call([batch, batch.set], sessionRef, newSession),
      sessionDoc_Set(
        'details',
        details ? details : mergeDeepLeft(preferencesSession.details, detailsInitialState),
        fileRef,
        batch
      ),
      sessionDoc_Set('suiteList', suiteList ? suiteList : activeSuite, fileRef, batch),
      sessionDoc_Set('layout', layout ? layout : state.layout, fileRef, batch),
      sessionDoc_Set(
        'businessCase',
        businessCase ? businessCase : mergeDeepLeft(reportSettings, businessCaseInitialState),
        fileRef,
        batch
      ),
      sessionDoc_Set(
        'businessPreparation',
        businessPreparation ? businessPreparation : businessPreparationInitialState,
        fileRef,
        batch
      ),
      sessionDoc_Set('benefits', benefits ? benefits : benefitsInitialState, fileRef, batch),
      sessionDoc_Set(
        'benefitsCashflow',
        benefitsCashflow ? benefitsCashflow : { benefits: {} },
        fileRef,
        batch
      ),
      sessionDoc_Set(
        'nonRecurringCostsCashflow',
        nonRecurringCostsCashflow ? nonRecurringCostsCashflow : { nonRecurringCosts: {} },
        fileRef,
        batch
      ),
      sessionDoc_Set(
        'recurringCostsCashflow',
        recurringCostsCashflow ? recurringCostsCashflow : { recurringCosts: {} },
        fileRef,
        batch
      ),
      sessionDoc_Set(
        'nonRecurringCosts',
        nonRecurringCosts ? nonRecurringCosts : nonRecurringCostsInitialState,
        fileRef,
        batch
      ),
      sessionDoc_Set(
        'recurringCosts',
        recurringCosts ? recurringCosts : recurringCostsInitialState,
        fileRef,
        batch
      ),
      sessionDoc_Set(
        'defaultSessionValues',
        defaultSessionValues ? defaultSessionValues : defaultSessionValuesInitialState,
        fileRef,
        batch
      ),
      sessionDoc_Set(
        'editted_benefitsCashflow',
        editted_benefitsCashflow ? editted_benefitsCashflow : { editted_benefits: {} },
        fileRef,
        batch
      ),
      sessionDoc_Set(
        'editted_nonRecurringCostsCashflow',
        editted_nonRecurringCostsCashflow
          ? editted_nonRecurringCostsCashflow
          : { editted_nonRecurringCosts: {} },
        fileRef,
        batch
      ),
      sessionDoc_Set(
        'editted_recurringCostsCashflow',
        editted_recurringCostsCashflow
          ? editted_recurringCostsCashflow
          : { editted_recurringCosts: {} },
        fileRef,
        batch
      ),
      sessionDoc_Set('delay', delay ? delay : delayInitialState, fileRef, batch),
      sessionDoc_Set('tender', tender ? tender : tenderInitialState, fileRef, batch),
      sessionDoc_Set('doNothing', doNothing ? doNothing : doNothingInitialState, fileRef, batch),
      sessionDoc_Set('plugins', plugins ? plugins : {}, fileRef, batch),
      sessionDoc_Set(
        'questionnaire',
        questionnaire ? questionnaire : questionnaireInitialState,
        fileRef,
        batch
      ),
    ]);

    if (onlineState === 'offline') {
      batch.commit();
    } else {
      yield call([batch, batch.commit]);
    }
  } catch (error) {
    LogRocket.captureException(error);
    yield console.log('batch failed: ', error);
  }

  return id;
}

export default create;
