import { map, mergeAll } from '@sharkfinesse/sfl-lib';
import { call, all } from 'redux-saga/effects';
import { duplicateBenefitObject } from '../benefit/duplicateBenefit';

function* duplicateBenefits(benefits) {
  const newBenefits = yield all(map(id => call(duplicateBenefitObject, id), benefits));
  return mergeAll(newBenefits);
}

export default duplicateBenefits;
