import { padding } from 'polished';

const ButtonNormal = ({ font, scale }) => ({
  padding: padding(scale.minusThree, scale.zero),
  borderRadius: '25px',
  lineHeight: 1.7,
  fontSize: font.sizeNormal,
});

export default ButtonNormal;
