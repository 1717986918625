const juliet = 5;
const oscar = 12;
const quebec = 3;
const tango = 9;
const yankee = 7;
const zulu = 6;
const LayoutBenefits = () => ({
  suiteSize: { md: quebec / oscar, lg: juliet / oscar, xl: zulu / oscar },
  evaluatorSize: { md: tango / oscar, lg: yankee / oscar, xl: zulu / oscar },
});

export default LayoutBenefits;
