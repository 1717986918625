/* @flow */
import React, { useState } from 'react';
import type { Element } from 'react';
import { useTheme } from 'styled-components';
import { IconAnimated } from '../icon/icons';
import { Tab } from '../tabs';
import { useMedia } from 'react-media';

type Props = {
  label?: Element<*> | String,
  icon?: Element<*> | String,
  ...other,
};

const AnimatedIconTab = ({ label, icon, children, ...other }: Props) => {
  const [playing, setPlaying] = useState('stopped');

  const handleLottieMouseLeave = () => {
    setPlaying('stopped');
  };
  const handleLottieMouseEnter = () => {
    setPlaying('playing');
  };

  const theme = useTheme();

  const matches = useMedia({
    queries: {
      small: '(min-width: 0) and (max-width: 575px)',
    },
  });

  const iconSize = matches.small ? 'width: 40px; height: 40px;' : 'width: 60px; height: 60px;';

  return (
    <Tab
      label={label}
      onMouseEnter={handleLottieMouseEnter}
      onMouseLeave={handleLottieMouseLeave}
      icon={
        <IconAnimated
          playingState={playing}
          speed={1}
          direction={1}
          primaryColor={theme.animatedIcons.primary}
          secondaryColor={theme.animatedIcons.secondary}
          size={iconSize}
          animationData={icon}
        />
      }
      {...other}
    >
      {children}
    </Tab>
  );
};

export default AnimatedIconTab;
