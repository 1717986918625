/* eslint-disable jsx-a11y/aria-role, react/prop-types */
import * as React from 'react';
import { IconChevronLeft, IconChevronRight, IconChevronUp, IconChevronDown } from '../icon';
import { ScrollButton } from './styled';

const TabScrollButton = React.forwardRef(function TabScrollButton(
  { direction, orientation, disabled, ...other },
  ref
) {
  return (
    <ScrollButton
      vertical={orientation === 'vertical'}
      direction={direction}
      disabled={disabled}
      ref={ref}
      role={null}
      tabIndex={null}
      {...other}
    >
      {orientation === 'horizontal' &&
        (direction === 'left' ? (
          <IconChevronLeft size="small" />
        ) : (
          <IconChevronRight size="small" />
        ))}
      {orientation === 'vertical' &&
        (direction === 'left' ? <IconChevronUp size="small" /> : <IconChevronDown size="small" />)}
    </ScrollButton>
  );
});

export default TabScrollButton;
