import styled from 'styled-components';
import applyTheme from '../../styles/apply-theme';

const theme = applyTheme('radioButton');

export const Container = theme('container');
export const Outline = theme('outline');
export const Selected = theme('selected');
export const Label = theme('label');
export const HiddenRadioButton = theme('hidden', styled.input.attrs({ type: 'radio' }));
