/* @flow */
import { simpleAction, createReducer, deviceInitialState } from '@sharkfinesse/sfl-lib';

const namespace = (name: string): string => `@@device/${name}`;

// types
export const types = {
  SYNC: namespace('SYNC'),
  UPDATE: namespace('UPDATE'),
  SYNC_FAILED: namespace('SYNC_FAILED'),
  DEBOUNCE_SYNC: namespace('DEBOUNCE_SYNC'),
};

const initialState = deviceInitialState;

const reduceUpdate = (state, action) => ({
  ...state,
  ...action.payload,
  pending: false,
});

export default createReducer(
  {
    [types.UPDATE]: reduceUpdate,
  },
  initialState
);

//Action Creators
const sync = simpleAction(types.SYNC);
const syncFailed = simpleAction(types.SYNC_FAILED);
const update = simpleAction(types.UPDATE);
const debounceSync = simpleAction(types.DEBOUNCE_SYNC);
export const actionCreators = {
  sync,
  syncFailed,
  update,
  debounceSync,
};
