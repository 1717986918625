import {
  path,
  isEmpty,
  isNil,
  length,
  keys,
  find,
  equals,
  sessionDetailsSelectors,
  cashflowsSelectors,
  layoutSelectors,
  accountSelectors,
  totalCostsSelectors,
} from '@sharkfinesse/sfl-lib';
import { createMiddleware } from 'redux-beacon';
import { LOCATION_CHANGE } from 'connected-react-router';
import GoogleAnalytics from '@redux-beacon/google-analytics';
import debounceEvent from '@redux-beacon/debounce-event';
import { types as sessionDetailsTypes } from 'redux/modules/session-details';
import { types as benefitTypes } from 'redux/modules/evaluator';
import { types as nonRecurringCostsTypes } from 'redux/modules/non-recurring-costs';
import { types as recurringCostsTypes } from 'redux/modules/recurring-costs';
import { types as cashflowTypes } from 'redux/modules/cashflow';
import { types as preferencesLanguageTypes } from 'redux/modules/preferences/language';
import { types as preferencesThemeTypes } from 'redux/modules/preferences/theme';
import { types as preferencesSessionDetailsTypes } from 'redux/modules/preferences/session';
import { types as sessionTypes } from 'redux/modules/session';
import { types as reportsTypes } from 'redux/modules/reports';

const ga = GoogleAnalytics();

const isGaDisabled = state => {
  const enabled = path(['preferences', 'analytics', 'enabled'], state);
  if (enabled) {
    const ga = path(['abilities', 'ga'], state);
    return !isNil(ga) ? find(equals('disabled'))(ga) : null;
  } else {
    return null;
  }
};
const pageView = (action, prevState, nextState) => {
  const account = path(['user', 'account'], nextState);
  const product = path(['user', 'product'], nextState);
  const userId = path(['user', 'encryptedUserId'], nextState);

  const hitType =
    !isNil(isGaDisabled(nextState)) || isNil(account) || isNil(product) || isNil(userId)
      ? null
      : 'pageview';

  return {
    hitType,
    page: `${action.payload.location.pathname}${action.payload.location.search}`,
    location: window.location.href,
    title: document.title,
    dimension1: account,
    dimension2: product,
    dimension3: userId,
    userId,
  };
};

const trackEvent = ({
  action,
  prevState,
  nextState,
  eventCategory,
  eventAction,
  eventLabel,
  eventValue,
}) => {
  const account = path(['user', 'account'], nextState);
  const product = path(['user', 'product'], nextState);
  const userId = path(['user', 'encryptedUserId'], nextState);
  const firebaseEnvironment = localStorage.getItem('firebaseEnvironment');
  const hitType =
    !isNil(isGaDisabled(nextState)) ||
    isNil(account) ||
    isNil(product) ||
    isNil(userId) ||
    !isNil(firebaseEnvironment)
      ? null
      : 'event';

  return {
    hitType,
    eventCategory,
    eventAction,
    eventLabel,
    eventValue,
    dimension1: account,
    dimension2: product,
    dimension3: userId,
    userId,
  };
};

const trackSessionDetailsEvent = ({ action, prevState, nextState, eventAction, eventValue = 1 }) =>
  trackEvent({
    action,
    prevState,
    nextState,
    eventCategory: 'Session Details',
    eventAction,
    eventLabel: !isNil(action) ? action.payload : undefined,
    eventValue,
  });

const trackBenefitEvent = ({ action, prevState, nextState, eventAction, eventValue = 1 }) => {
  const eNo = layoutSelectors.getBenefitENo(nextState, {
    benefitId: !isNil(action.payload)
      ? action.payload.id
      : !isNil(action.payload.benefitId)
      ? action.payload.benefitId
      : action.payload.id,
  });

  const eventLabel = accountSelectors.getEvaluatorTitle(nextState, eNo);
  return trackEvent({
    action,
    prevState,
    nextState,
    eventCategory: 'Benefits',
    eventAction,
    eventLabel,
    eventValue,
  });
};

const trackCostEvent = ({ action, prevState, nextState, eventAction, eventValue = 1 }) =>
  trackEvent({
    action,
    prevState,
    nextState,
    eventCategory: 'Costs',
    eventAction,
    eventValue,
  });
const trackOutputsEvent = ({
  action,
  prevState,
  nextState,
  eventAction,
  eventLabel,
  eventValue = 1,
}) =>
  trackEvent({
    action,
    prevState,
    nextState,
    eventCategory: 'Outputs',
    eventLabel,
    eventAction,
    eventValue,
  });
const trackPreferencesEvent = ({
  action,
  prevState,
  nextState,
  eventAction,
  eventLabel,
  eventValue = 1,
}) =>
  trackEvent({
    action,
    prevState,
    nextState,
    eventCategory: 'Preferences',
    eventAction,
    eventLabel,
    eventValue,
  });
const trackSessionEvent = ({
  action,
  prevState,
  nextState,
  eventAction,
  eventLabel,
  eventValue = 1,
}) =>
  trackEvent({
    action,
    prevState,
    nextState,
    eventCategory: 'Session',
    eventAction,
    eventLabel,
    eventValue,
  });

const eventsMap = {
  [LOCATION_CHANGE]: debounceEvent(1000, pageView),
  [sessionDetailsTypes.DEAL_NAME.UPDATE]: debounceEvent(1000, (action, prevState, nextState) =>
    trackSessionDetailsEvent({ action, prevState, nextState, eventAction: 'Update Deal Name' })
  ),
  [sessionDetailsTypes.CUSTOMER_NAME.UPDATE]: debounceEvent(1000, (action, prevState, nextState) =>
    trackSessionDetailsEvent({ action, prevState, nextState, eventAction: 'Update Customer Name' })
  ),
  [sessionDetailsTypes.SUPPLIER_NAME.UPDATE]: debounceEvent(1000, (action, prevState, nextState) =>
    trackSessionDetailsEvent({ action, prevState, nextState, eventAction: 'Update Supplier Name' })
  ),
  [sessionDetailsTypes.MINIMUM_RETURN.UPDATE]: debounceEvent(1000, (action, prevState, nextState) =>
    trackSessionDetailsEvent({ action, prevState, nextState, eventAction: 'Update Minimum Return' })
  ),
  [sessionDetailsTypes.REVIEW_PERIOD.UPDATE]: debounceEvent(1000, (action, prevState, nextState) =>
    trackSessionDetailsEvent({ action, prevState, nextState, eventAction: 'Update Review Period' })
  ),
  [sessionDetailsTypes.DEFAULT_START_MONTH.UPDATE]: debounceEvent(
    1000,
    (action, prevState, nextState) =>
      trackSessionDetailsEvent({
        action,
        prevState,
        nextState,
        eventAction: 'Update Default Start Month',
      })
  ),
  [sessionDetailsTypes.INDUSTRY.UPDATE]: debounceEvent(1000, (action, prevState, nextState) => {
    const prevIndustry = sessionDetailsSelectors.getIndustry(prevState);
    const nextIndustry = sessionDetailsSelectors.getIndustry(nextState);
    let eventAction = 'Add Indusrty';
    if (!isEmpty(prevIndustry)) {
      if (isEmpty(nextIndustry)) {
        eventAction = 'Delete indusrty';
      } else {
        eventAction = 'Update indusrty';
      }
    }
    return trackSessionDetailsEvent({ action, prevState, nextState, eventAction });
  }),
  [sessionDetailsTypes.CURRENCY.UPDATE]: p =>
    trackSessionDetailsEvent({ ...p, eventAction: 'Update Currency' }),
  [sessionDetailsTypes.NUMBER_FORMAT.UPDATE]: p =>
    trackSessionDetailsEvent({ ...p, eventAction: 'Update Number Format' }),
  [sessionDetailsTypes.DATE_FORMAT.UPDATE]: p =>
    trackSessionDetailsEvent({ ...p, eventAction: 'Update Date Format' }),
  [cashflowTypes.EVALUATOR.CALCULATE]: (action, prevState, nextState) => {
    const prevCashflow = cashflowsSelectors.getBenefitDataById(prevState, action.payload.id);
    const nextCashflow = action.payload.calculation_cashflow;

    if (isEmpty(prevCashflow) && !isNil(nextCashflow)) {
      return trackBenefitEvent({ action, prevState, nextState, eventAction: 'Add Benefit' });
    } else if (!isEmpty(prevCashflow) && isNil(nextCashflow)) {
      return trackBenefitEvent({ action, prevState, nextState, eventAction: 'Remove Benefit' });
    } else {
      return null;
    }
  },
  [benefitTypes.DELETE]: (action, prevState, nextState) =>
    trackBenefitEvent({ action, prevState, nextState, eventAction: 'Reset Benefit' }),

  [benefitTypes.NOTES.UPDATE]: debounceEvent(1000, (action, prevState, nextState) =>
    trackBenefitEvent({ action, prevState, nextState, eventAction: 'Update Notes' })
  ),
  [benefitTypes.PRIVATE_NOTES.UPDATE]: debounceEvent(1000, (action, prevState, nextState) =>
    trackBenefitEvent({ action, prevState, nextState, eventAction: 'Update Private Notes' })
  ),
  [benefitTypes.SUBHEADING.UPDATE]: debounceEvent(1000, (action, prevState, nextState) =>
    trackBenefitEvent({ action, prevState, nextState, eventAction: 'Update Sub-heading' })
  ),
  [cashflowTypes.COSTS.RECURRING.CALCULATE]: (action, prevState, nextState) => {
    const prevCosts = length(keys(cashflowsSelectors.getCashflowRecurringCosts(prevState)));
    const nextCosts = length(keys(cashflowsSelectors.getCashflowRecurringCosts(nextState)));
    if (prevCosts > nextCosts) {
      return trackCostEvent({ action, prevState, nextState, eventAction: 'Remove Recurring Cost' });
    } else if (nextCosts > prevCosts) {
      return trackCostEvent({ action, prevState, nextState, eventAction: 'Add Recurring Cost' });
    }
    return null;
  },
  [cashflowTypes.COSTS.NONRECURRING.CALCULATE]: (action, prevState, nextState) => {
    const prevCosts = length(keys(cashflowsSelectors.getCashflowNonRecurringCosts(prevState)));
    const nextCosts = length(keys(cashflowsSelectors.getCashflowNonRecurringCosts(nextState)));
    if (prevCosts > nextCosts) {
      return trackCostEvent({
        action,
        prevState,
        nextState,
        eventAction: 'Remove Non-Recurring Cost',
      });
    } else if (nextCosts > prevCosts) {
      return trackCostEvent({
        action,
        prevState,
        nextState,
        eventAction: 'Add Non-Recurring Cost',
      });
    }
    return null;
  },
  [nonRecurringCostsTypes.RESET]: (action, prevState, nextState) =>
    trackCostEvent({
      action,
      prevState,
      nextState,
      eventAction: 'Reset Non-Recurring Cost',
    }),
  [recurringCostsTypes.RESET]: (action, prevState, nextState) =>
    trackCostEvent({
      action,
      prevState,
      nextState,
      eventAction: 'Reset Recurring Cost',
    }),
  [nonRecurringCostsTypes.NOTES.CHANGE]: debounceEvent(1000, (action, prevState, nextState) =>
    trackCostEvent({
      action,
      prevState,
      nextState,
      eventAction: 'Update Non-Recurring Cost Notes',
    })
  ),
  [recurringCostsTypes.NOTES.CHANGE]: debounceEvent(1000, (action, prevState, nextState) =>
    trackCostEvent({
      action,
      prevState,
      nextState,
      eventAction: 'Update Recurring Cost Notes',
    })
  ),
  [nonRecurringCostsTypes.MONTH.CHANGE]: debounceEvent(1000, (action, prevState, nextState) =>
    trackCostEvent({
      action,
      prevState,
      nextState,
      eventAction: 'Update Non-Recurring Cost Impact Month',
    })
  ),
  [nonRecurringCostsTypes.TYPE.CHANGE]: (action, prevState, nextState) =>
    trackCostEvent({
      action,
      prevState,
      nextState,
      eventAction: 'Update Non-Recurring Cost Type',
    }),
  [recurringCostsTypes.TYPE.CHANGE]: (action, prevState, nextState) =>
    trackCostEvent({
      action,
      prevState,
      nextState,
      eventAction: 'Update Recurring Cost Type',
    }),
  [recurringCostsTypes.START.CHANGE]: debounceEvent(1000, (action, prevState, nextState) =>
    trackCostEvent({
      action,
      prevState,
      nextState,
      eventAction: 'Update Recurring Cost Start Month',
    })
  ),
  [recurringCostsTypes.END.CHANGE]: debounceEvent(1000, (action, prevState, nextState) =>
    trackCostEvent({
      action,
      prevState,
      nextState,
      eventAction: 'Update Recurring Cost End Month',
    })
  ),
  [recurringCostsTypes.GROWTH.CHANGE]: debounceEvent(1000, (action, prevState, nextState) =>
    trackCostEvent({
      action,
      prevState,
      nextState,
      eventAction: 'Update Recurring Cost Growth',
    })
  ),
  [recurringCostsTypes.RECURRENCE.CHANGE]: (action, prevState, nextState) =>
    trackCostEvent({
      action,
      prevState,
      nextState,
      eventAction: 'Update Recurring Cost Recurrence',
    }),
  [reportsTypes.SHORT.GENERATE]: (action, prevState, nextState) => {
    return trackOutputsEvent({
      action,
      prevState,
      nextState,
      eventAction: 'Generate Short Form Report',
    });
  },

  [reportsTypes.LONG.GENERATE]: (action, prevState, nextState) => {
    return trackOutputsEvent({
      action,
      prevState,
      nextState,
      eventAction: 'Generate Long Form Report',
    });
  },
  [reportsTypes.XLSX_SINGLE.GENERATE]: (action, prevState, nextState) => {
    return trackOutputsEvent({
      action,
      prevState,
      nextState,
      eventAction: 'Generate Session Spreadsheet',
    });
  },
  [reportsTypes.PPTX_SINGLE.GENERATE]: (action, prevState, nextState) => {
    return trackOutputsEvent({
      action,
      prevState,
      nextState,
      eventAction: 'Generate Session Presentation',
    });
  },
  [reportsTypes.COMPARE_DOC.GENERATE]: (action, prevState, nextState) =>
    trackOutputsEvent({ action, prevState, nextState, eventAction: 'Generate Comparison Report' }),
  [reportsTypes.COMPARE_XLSX.GENERATE]: (action, prevState, nextState) =>
    trackOutputsEvent({
      action,
      prevState,
      nextState,
      eventAction: 'Generate Comparison Spreadsheet',
    }),
  [reportsTypes.PREP.GENERATE]: (action, prevState, nextState) =>
    trackOutputsEvent({
      action,
      prevState,
      nextState,
      eventAction: 'Generate Business Case Preparation Report',
    }),
  [preferencesLanguageTypes.LANGUAGE.UPDATE]: (action, prevState, nextState) =>
    trackPreferencesEvent({
      action,
      prevState,
      nextState,
      eventAction: 'Change language',
      eventLabel: action.payload.label,
    }),
  [preferencesLanguageTypes.LANGUAGE.UPDATE]: (action, prevState, nextState) =>
    trackPreferencesEvent({
      action,
      prevState,
      nextState,
      eventAction: 'Change language',
      eventLabel: action.payload.label,
    }),
  [preferencesThemeTypes.THEME.UPDATE]: (action, prevState, nextState) =>
    trackPreferencesEvent({
      action,
      prevState,
      nextState,
      eventAction: 'Change theme',
      eventLabel: action.payload.label,
    }),
  [preferencesSessionDetailsTypes.CURRENCY.UPDATE]: (action, prevState, nextState) =>
    trackPreferencesEvent({
      action,
      prevState,
      nextState,
      eventAction: 'Change currency',
      eventLabel: action.payload.value,
    }),
  [preferencesSessionDetailsTypes.NUMBER_FORMAT.UPDATE]: (action, prevState, nextState) =>
    trackPreferencesEvent({
      action,
      prevState,
      nextState,
      eventAction: 'Change number format',
      eventLabel: action.payload.label,
    }),
  [preferencesSessionDetailsTypes.DATE_FORMAT.UPDATE]: (action, prevState, nextState) =>
    trackPreferencesEvent({
      action,
      prevState,
      nextState,
      eventAction: 'Change date format',
      eventLabel: action.payload.label,
    }),
  [preferencesSessionDetailsTypes.DEFAULT_START_MONTH.UPDATE]: (action, prevState, nextState) => {
    return trackPreferencesEvent({
      action,
      prevState,
      nextState,
      eventAction: 'Change default start month',
      eventLabel: action.payload.defaultStartMonth,
    });
  },
  [sessionTypes.INIT]: (action, prevState, nextState) => {
    const eventAction = isNil(action.payload.sessionId) ? 'Create session' : 'Open session';
    const eventLabel = isNil(action.payload.sessionId) ? 'Blank' : null;

    return trackSessionEvent({
      action,
      prevState,
      nextState,
      eventAction,
      eventLabel,
    });
  },
  [sessionTypes.DUPLICATE]: (action, prevState, nextState) => {
    const eventLabel = action.payload.template ? 'Sample' : 'Duplicate';

    return trackSessionEvent({
      action,
      prevState,
      nextState,
      eventAction: 'Create session',
      eventLabel,
    });
  },
};

export default createMiddleware(eventsMap, ga);
