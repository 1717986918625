"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.yearlyToWorkingHours = exports.weeksToSeconds = exports.tonsToKg = exports.sqMileToM2 = exports.sqInchToM2 = exports.secondsToWeeks = exports.secondsToMonths = exports.secondsToMinutes = exports.secondsToHours = exports.secondsToFteDays = exports.secondsToDays = exports.poundsToKg = exports.monthsToSeconds = exports.mmscfdToM3PerSec = exports.mmbtuToJoules = exports.minutesToSeconds = exports.minutesToHours = exports.milesToMetres = exports.metresToMiles = exports.metresToInches = exports.metresToFeet = exports.m3ToLitres = exports.m3ToGallons = exports.m3ToFt3 = exports.m3PerSecToMmscfd = exports.m2ToMile2 = exports.m2ToInch2 = exports.litreToM3 = exports.kgToTons = exports.kgToPounds = exports.joulesToMmbtu = exports.inchesToMetres = exports.hoursToSeconds = exports.generateUnitsObject = exports.gallonToM3 = exports.fteDaysToSeconds = exports.ft3ToM3 = exports.feetToMetres = exports.daysToSeconds = void 0;

var _constants = require("./constants");

var _fp = require("./fp");

var _type = require("./type");

var _math = require("./math");

const generateUnitsObject = (0, _fp.pipe)((0, _fp.prop)('properties'), (0, _fp.pluck)('unit'), (0, _fp.filter)(_type.isNotNil));
exports.generateUnitsObject = generateUnitsObject;
const yearlyToWorkingHours = (0, _math.divideBy)(_constants.hoursPerFteYear);
exports.yearlyToWorkingHours = yearlyToWorkingHours;
const minutesToHours = (0, _math.divideBy)(_constants.minutesPerHour); // prettier-ignore

exports.minutesToHours = minutesToHours;
const fteDaysToSeconds = (0, _fp.pipe)((0, _fp.multiply)(_constants.secondsPerFteDay), (0, _math.decreaseByFloatingFixedPointFactor)(1)); // prettier-ignore

exports.fteDaysToSeconds = fteDaysToSeconds;
const daysToSeconds = (0, _fp.multiply)(_constants.secondsPerDay); // prettier-ignore

exports.daysToSeconds = daysToSeconds;
const minutesToSeconds = (0, _fp.multiply)(_constants.secondsPerMinute); // prettier-ignore

exports.minutesToSeconds = minutesToSeconds;
const hoursToSeconds = (0, _fp.multiply)(_constants.secondsPerHour); // prettier-ignore

exports.hoursToSeconds = hoursToSeconds;
const weeksToSeconds = (0, _fp.pipe)((0, _fp.multiply)(_constants.secondsPerDay), (0, _fp.multiply)(_constants.daysPerWeek)); // prettier-ignore

exports.weeksToSeconds = weeksToSeconds;
const monthsToSeconds = (0, _fp.multiply)(_constants.secondsPerMonth); // prettier-ignore

exports.monthsToSeconds = monthsToSeconds;
const secondsToFteDays = (0, _fp.pipe)((0, _math.divideBy)(_constants.secondsPerFteDay), (0, _math.increaseByFloatingFixedPointFactor)(1)); // prettier-ignore

exports.secondsToFteDays = secondsToFteDays;
const secondsToMinutes = (0, _math.divideBy)(_constants.secondsPerMinute); // prettier-ignore

exports.secondsToMinutes = secondsToMinutes;
const secondsToHours = (0, _math.divideBy)(_constants.secondsPerHour); // prettier-ignores

exports.secondsToHours = secondsToHours;
const secondsToDays = (0, _math.divideBy)(_constants.secondsPerDay); // prettier-ignore

exports.secondsToDays = secondsToDays;
const secondsToWeeks = (0, _fp.pipe)((0, _math.divideBy)(_constants.daysPerWeek), (0, _math.divideBy)(_constants.secondsPerDay)); // prettier-ignore

exports.secondsToWeeks = secondsToWeeks;
const secondsToMonths = (0, _math.divideBy)(_constants.secondsPerMonth);
exports.secondsToMonths = secondsToMonths;
const normalizedConversion = (0, _fp.curry)((ratio, value) => (0, _fp.pipe)(_fp.multiply, (0, _fp.divide)(_fp.__, (0, _math.normalizingFloatingFixedPointFactor)(2)), Math.round)(ratio, value));
const adjustUnit = (0, _fp.curry)((ratio, value) => (0, _fp.pipe)((0, _fp.multiply)((0, _math.normalizingFloatingFixedPointFactor)(4)), (0, _fp.divide)(_fp.__, ratio), (0, _fp.divide)(_fp.__, (0, _math.normalizingFloatingFixedPointFactor)(2)), Math.round)(value));
const gallonToM3 = normalizedConversion(_constants.gallonToM3Ratio);
exports.gallonToM3 = gallonToM3;
const litreToM3 = normalizedConversion(_constants.litreToM3Ratio);
exports.litreToM3 = litreToM3;
const tonsToKg = normalizedConversion(_constants.tonsToKgRatio);
exports.tonsToKg = tonsToKg;
const poundsToKg = normalizedConversion(_constants.poundsToKgRatio);
exports.poundsToKg = poundsToKg;
const milesToMetres = normalizedConversion(_constants.milesToMetresRatio);
exports.milesToMetres = milesToMetres;
const feetToMetres = normalizedConversion(_constants.feetToMetresRatio);
exports.feetToMetres = feetToMetres;
const inchesToMetres = normalizedConversion(_constants.inchesToMetresRatio);
exports.inchesToMetres = inchesToMetres;
const sqMileToM2 = normalizedConversion(_constants.sqMileToM2Ratio);
exports.sqMileToM2 = sqMileToM2;
const sqInchToM2 = normalizedConversion(_constants.sqInchToM2Ratio);
exports.sqInchToM2 = sqInchToM2;
const mmbtuToJoules = normalizedConversion(_constants.mmbtuToJoulesRatio);
exports.mmbtuToJoules = mmbtuToJoules;
const mmscfdToM3PerSec = normalizedConversion(_constants.mmscfdToM3PerSecRatio);
exports.mmscfdToM3PerSec = mmscfdToM3PerSec;
const ft3ToM3 = normalizedConversion(_constants.ft3ToM3Ratio);
exports.ft3ToM3 = ft3ToM3;
const m3ToGallons = adjustUnit(_constants.gallonToM3Ratio);
exports.m3ToGallons = m3ToGallons;
const m3ToLitres = adjustUnit(_constants.litreToM3Ratio);
exports.m3ToLitres = m3ToLitres;
const kgToTons = adjustUnit(_constants.tonsToKgRatio);
exports.kgToTons = kgToTons;
const kgToPounds = adjustUnit(_constants.poundsToKgRatio);
exports.kgToPounds = kgToPounds;
const metresToMiles = adjustUnit(_constants.milesToMetresRatio);
exports.metresToMiles = metresToMiles;
const metresToFeet = adjustUnit(_constants.feetToMetresRatio);
exports.metresToFeet = metresToFeet;
const metresToInches = adjustUnit(_constants.inchesToMetresRatio);
exports.metresToInches = metresToInches;
const m2ToMile2 = adjustUnit(_constants.sqMileToM2Ratio);
exports.m2ToMile2 = m2ToMile2;
const joulesToMmbtu = adjustUnit(_constants.mmbtuToJoulesRatio);
exports.joulesToMmbtu = joulesToMmbtu;
const m3PerSecToMmscfd = adjustUnit(_constants.mmscfdToM3PerSecRatio);
exports.m3PerSecToMmscfd = m3PerSecToMmscfd;
const m3ToFt3 = adjustUnit(_constants.ft3ToM3Ratio);
exports.m3ToFt3 = m3ToFt3;
const m2ToInch2 = adjustUnit(_constants.sqInchToM2Ratio);
exports.m2ToInch2 = m2ToInch2;