/* flow */
import { combineReducers } from 'redux';
import intl from 'redux/modules/language';
import login from 'redux/modules/login';
import auth0 from 'redux/modules/auth0';
import app from 'redux/modules/app';
import browser from 'redux/modules/browser';
import product from 'redux/modules/product';
import session from 'redux/modules/session';
import persistence from 'redux/modules/persistence';
import preferences from 'redux/modules/preferences';
import settings from 'redux/modules/settings';
import account from 'redux/modules/account';
import user from 'redux/modules/user/user';
import layoutDesigner from 'redux/modules/layoutDesigner';
import serviceWorker from 'redux/modules/service-worker';
import abilities from 'redux/modules/abilities';
import compare from 'redux/modules/compare';
import device from 'redux/modules/device';
import plugins from 'redux/modules/plugins/plugins-root';
import questionnaire from 'redux/modules/questionnaire/questionnaire';
import tracking from 'redux/modules/tracking';
import { connectRouter } from 'connected-react-router';

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    serviceWorker,
    login,
    auth0,
    user,
    app,
    intl,
    browser,
    persistence,
    product,
    session,
    preferences,
    settings,
    account,
    layoutDesigner,
    abilities,
    compare,
    device,
    plugins,
    questionnaire,
    tracking,
  });
export default rootReducer;
