// @flow
import React from 'react';
import { FlexRow } from '../general-styles';
import { IconHelp } from '../icon';
import { useTheme } from 'styled-components';
import { HelpItemWrapper, HelpItemLabel } from './styled';

const HelpItem = ({ onClick, label, ...props }) => {
  const theme = useTheme();
  return (
    <HelpItemWrapper onClick={onClick}>
      <FlexRow>
        <IconHelp primaryColor={theme.colors.primary} secondaryColor={theme.icons.inverseColor} />
        <HelpItemLabel>{label}</HelpItemLabel>
      </FlexRow>
    </HelpItemWrapper>
  );
};

export default HelpItem;
