"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

const getAccount = state => state.account;

const getVersion = state => state.account.version;

const getPreferences = state => state.account.preferences;

const getCustomerNumber = state => state.account.customerNo;

const getCategoryTypes = state => state.account.categoryTypes;

const getCategories = state => state.account.categories;

const getEvaluators = state => state.account.evaluators;

const getEvaluator = (state, eNo) => state.account.evaluators[eNo];

const getEvaluatorTitle = (state, eNo) => {
  var _state$account$evalua;

  return (_state$account$evalua = state.account.evaluators[eNo]) === null || _state$account$evalua === void 0 ? void 0 : _state$account$evalua.title;
};

const getEvaluatorSchema = (state, props) => {
  var _state$account$evalua2;

  return (_state$account$evalua2 = state.account.evaluators[props.eNo]) === null || _state$account$evalua2 === void 0 ? void 0 : _state$account$evalua2.schema;
};

const getEvaluatorSchemaProperties = (state, eNo) => {
  var _state$account$evalua3;

  return (_state$account$evalua3 = state.account.evaluators[eNo]) === null || _state$account$evalua3 === void 0 ? void 0 : _state$account$evalua3.schema.properties;
};

const getEvaluatorSchemaId = (state, eNo) => {
  var _state$account$evalua4;

  return (_state$account$evalua4 = state.account.evaluators[eNo]) === null || _state$account$evalua4 === void 0 ? void 0 : _state$account$evalua4.schema.id;
};

const getEvaluatorStatic = (state, eNo) => {
  var _state$account$evalua5;

  return (_state$account$evalua5 = state.account.evaluators[eNo]) === null || _state$account$evalua5 === void 0 ? void 0 : _state$account$evalua5.schema.static;
};

const getEvaluatorRecurrence = (state, benefitId, eNo) => {
  var _state$account$evalua6;

  return (_state$account$evalua6 = state.account.evaluators[eNo]) === null || _state$account$evalua6 === void 0 ? void 0 : _state$account$evalua6.schema.recurrence;
};

const getEvaluatorUiSchema = (state, eNo) => {
  var _state$account$evalua7;

  return (_state$account$evalua7 = state.account.evaluators[eNo]) === null || _state$account$evalua7 === void 0 ? void 0 : _state$account$evalua7.uiSchema;
};

const getEvaluatorUiSchemaOrder = (state, eNo) => {
  var _state$account$evalua8;

  return (_state$account$evalua8 = state.account.evaluators[eNo]) === null || _state$account$evalua8 === void 0 ? void 0 : _state$account$evalua8.uiSchema['ui:order'];
};

const getCategoryType = (state, categoryName) => state.account.categoryTypes[categoryName];

const getLocales = state => state.account.locales;

const getLocale = (state, locale) => state.account.locales[locale];

const getLogos = state => state.account.logos;

const getLogo = (state, logo) => state.account.logos[logo];

const getLogoBase64 = (state, logo) => state.account.logos[logo].base64;

const getLogoUrl = (state, logo) => state.account.logos[logo].url;

const getBenefitCategory = (state, eNo) => {
  var _state$account$evalua9;

  return (_state$account$evalua9 = state.account.evaluators[eNo]) === null || _state$account$evalua9 === void 0 ? void 0 : _state$account$evalua9.schema.benefitCategory;
};

const getCoverpages = state => state.account.coverpages;

const getCoverpage = (state, coverpage) => state.account.coverpages[coverpage];

const getCoverpageBase64 = (state, coverpage) => state.account.coverpages[coverpage].base64;

const getCoverpageUrl = (state, coverpage) => state.account.coverpages[coverpage].url;

const selectors = {
  getAccount,
  getVersion,
  getPreferences,
  getCustomerNumber,
  getCategoryTypes,
  getCategoryType,
  getCategories,
  getEvaluator,
  getEvaluators,
  getEvaluatorTitle,
  getEvaluatorSchema,
  getEvaluatorSchemaProperties,
  getEvaluatorRecurrence,
  getEvaluatorUiSchema,
  getEvaluatorUiSchemaOrder,
  getEvaluatorSchemaId,
  getEvaluatorStatic,
  getLocales,
  getLocale,
  getLogos,
  getLogo,
  getLogoBase64,
  getLogoUrl,
  getBenefitCategory,
  getCoverpages,
  getCoverpage,
  getCoverpageBase64,
  getCoverpageUrl
};
var _default = selectors;
exports.default = _default;