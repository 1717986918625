import { css } from 'styled-components';

const DealIndicator = ({ font, colors, scale }) => ({
  positioner: ({ status }) => css`
    position: absolute;
    left: calc(50% - 13px);
    z-index: 500;
    top: 2px;
    transition: opacity 0.5s ease-in-out;
    opacity: ${status === 'hide' ? 0 : 1};
  `,
  wrapper: () => css`
    display: flex;
    width: 26px;
    justify-content: space-around;
    background: ${colors.uiLightest};
    padding: 2px;
    border-radius: 4px;
  `,
  indicatorWrapper: () => css`
    position: relative;
    width: 6px;
    height: 6px;
  `,
  indicatorBorder: () => css`
    position: absolute;
    border-radius: 3px;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 4;
    border: 1px solid ${colors.uiLight};
  `,
  indicatorColor: ({ status, indicatorColor }) => css`
    position: absolute;
    border-radius: 3px;
    top: 1px;
    bottom: 1px;
    right: 1px;
    left: 1px;
    z-index: 2;
    transition: opacity 0.25s ease-in-out;
    opacity: ${status ? 1 : 0};
    background: ${indicatorColor === '-1'
      ? '#df3f3b'
      : indicatorColor === '0'
      ? '#ffa41d'
      : '#74d359'};
  `,
  indicatorBackground: () => css`
    position: absolute;
    border-radius: 3px;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    background: ${colors.white};
  `,
});

export default DealIndicator;
