import { css } from 'styled-components';

const Styles = ({ font, colors, scale, device: { min_sm, min_md, max_md } }) => ({
  container: () => css`
    display: flex;
    width: 100%;
  `,
  wrapper: ({ onClick, $variant }) => css`
    display: flex;
    flex-direction: row;
    border-radius: 6px;
    box-shadow: 0 0 0 2px ${$variant === 'warning' ? colors.warning : colors.primary};
    background: ${colors.white};
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    width: 100%;
    ${onClick &&
    css`
      cursor: pointer;
      transition: box-shadow 200ms;
      &:hover {
        box-shadow: 0 0 0 2px ${colors.primary}, 0 2px 5px 2px rgb(0 0 0 / 20%);
        .label {
          text-decoration: underline;
        }
      }
    `}
  `,
  leftContent: () => css`
    display: flex;
  `,
  rightContent: () => css`
    display: flex;
  `,
  iconWrapper: () => css`
    display: flex;
    justify-content: center;
    color: ${colors.primary};
    width: 36px;
    margin-right: 12px;
    align-items: center;
  `,
  labelWrapper: () => css`
    display: flex;
    flex-direction: column;
  `,

  label: ({ $variant }) => css`
    display: flex;
    font-size: 14px;
    font-weight: ${font.weightSemiBold};
    color: ${$variant === 'warning' ? colors.warning : colors.primary};
    line-height: 16px;
  `,
  subLabel: () => css`
    display: flex;
    font-size: 13px;
    font-weight: ${font.weightNormal};
    color: ${colors.ui};
    line-height: 15px;
  `,
  iconContainer: () => css`
    width: 16px;
    height: 16px;
    color: ${colors.ui};
  `,
});

export default Styles;
