/* @flow */
/* eslint-disable max-len */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M22,9.2l-7.2-0.6L12,2L9.2,8.6L2,9.2L7.4,14l-1.6,7l6.2-3.7l6.2,3.7l-1.6-7L22,9.2z"
    />
  </Icon>
);

export default memo(IconComponent);
