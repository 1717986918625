"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.generateIrrObject = generateIrrObject;
exports.normalizeResult = exports.isRentalIrrCalculable = exports.isPositiveIrrCalculable = exports.isIrrUnderMillionCalculable = exports.isIrrUnderMillion = exports.isIrrFinite = exports.isIrrCalculable = exports.isCashflowPositve = exports.irrWithDcf = exports.irr = exports.generateRentalCashflow = void 0;

var _fp = require("./fp");

var _npv = require("./npv");

var _cashflow = require("./cashflow");

var _objects = require("./objects");

var _math = require("./math");

var _constants = require("./constants");

const oneMillion = 1000000;
const precision = 9;

function generateIrrObject(period, nonRecurringCosts, recurringCosts, oneTimeBenefits, monthlyBenefits, minimumReturnRate) {
  return {
    period,
    nonRecurringCosts,
    recurringCosts,
    oneTimeBenefits,
    monthlyBenefits,
    minimumReturnRate
  };
}

;
const generateIrrIterationObject = (0, _fp.curry)((rentalMode, netCashflow) => ({
  convergenceCount: 0,
  inc: 10000000,
  netCashflow,
  npv: 1,
  cashflow: [],
  precision: 1,
  previousRateMagnitude: 1,
  rate: 10,
  startRate: 0,
  iterations: 0,
  mag: 1,
  rentalMode
}));

function generateIrrResultObject(obj) {
  return {
    cashflow: obj.cashflow,
    irr: obj.rate,
    rentalMode: obj.rentalMode
  };
}

; // prettier-ignore

const generateRentalCashflow = data => (0, _cashflow.generateCashflowFromMixed)(data.period, (0, _cashflow.generateMonthZeroCashflow)(data.period, (0, _npv.npv)(data.minimumReturnRate, (0, _cashflow.idiotOrGenerateMonthZeroCashflow)(data.period, data.nonRecurringCosts))), (0, _cashflow.idiotOrGenerateMonthZeroCashflow)(data.period, data.oneTimeBenefits), (0, _cashflow.idiotOrGenerateCashflow)(data.period, data.monthlyBenefits), (0, _cashflow.generateMonthZeroCashflow)(data.period, (0, _npv.npv)(data.minimumReturnRate, (0, _cashflow.idiotOrGenerateCashflow)(data.period, data.recurringCosts)))); // prettier-ignore


exports.generateRentalCashflow = generateRentalCashflow;
const isIrrFinite = (0, _fp.pipe)((0, _npv.npv)(Number.MAX_VALUE), (0, _fp.lt)(_fp.placeholder, 0)); // prettier-ignore

exports.isIrrFinite = isIrrFinite;
const isIrrUnderMillion = (0, _fp.pipe)((0, _npv.npv)(oneMillion * _constants.floatingFixedPointFactor), (0, _fp.lt)(_fp.placeholder, 0)); // prettier-ignore

exports.isIrrUnderMillion = isIrrUnderMillion;
const isCashflowPositve = (0, _fp.pipe)(_cashflow.sumCashflow, (0, _fp.gte)(_fp.placeholder, 0)); // prettier-ignore

exports.isCashflowPositve = isCashflowPositve;
const isPositiveIrrCalculable = (0, _fp.allPass)([isCashflowPositve, isIrrFinite]); // prettier-ignore

exports.isPositiveIrrCalculable = isPositiveIrrCalculable;
const isIrrUnderMillionCalculable = (0, _fp.allPass)([isCashflowPositve, isIrrUnderMillion]); // prettier-ignore

exports.isIrrUnderMillionCalculable = isIrrUnderMillionCalculable;
const isIrrCalculable = (0, _fp.pipe)(_cashflow.generateNetCashflow, isIrrUnderMillionCalculable); // prettier-ignore

exports.isIrrCalculable = isIrrCalculable;
const isRentalIrrCalculable = (0, _fp.pipe)(generateRentalCashflow, isPositiveIrrCalculable);
exports.isRentalIrrCalculable = isRentalIrrCalculable;
const isNpvNegative = (0, _fp.propSatisfies)((0, _fp.lt)(_fp.placeholder, 0), 'npv'); // prettier-ignore

const normalizeResult = (0, _fp.evolve)({
  rate: (0, _fp.pipe)(Math.trunc, (0, _math.significand)(precision))
}); // prettier-ignore

exports.normalizeResult = normalizeResult;
const incRate = (0, _objects.callAndSet)('rate', (0, _fp.pipe)((0, _fp.props)(['rate', 'inc']), _fp.sum)); // prettier-ignore

const applyNpv = (0, _objects.callAndMergeKeys)(['npv', 'cashflow'], (0, _fp.pipe)((0, _fp.props)(['rate', 'netCashflow']), (0, _fp.apply)(_npv.npvWithDcf)));
const incMag = (0, _fp.evolve)({
  mag: _fp.inc
});
const initialObj = (0, _fp.curry)((mag, obj) => (0, _fp.merge)(obj, {
  npv: 1,
  rate: (0, _math.pow10)(mag),
  mag: (0, _fp.dec)(mag),
  inc: (0, _math.pow10)((0, _fp.dec)(mag))
}));

const resetObj = obj => (0, _fp.merge)(obj, {
  npv: 1,
  rate: obj.rate - obj.inc,
  mag: (0, _fp.dec)(obj.mag),
  inc: (0, _math.pow10)((0, _fp.dec)(obj.mag))
}); // prettier-ignore


const findIrrMagTransform = (0, _fp.pipe)(incMag, a => (0, _fp.assoc)('rate', (0, _math.pow10)(a.mag), a), applyNpv); // prettier-ignore

const findIrrMag = (0, _fp.pipe)((0, _fp.until)(isNpvNegative, findIrrMagTransform), (0, _fp.prop)('rate'), _math.toExponential, _math.getExponentAsString, _fp.dec);
const untilNpvNegative = (0, _fp.until)(isNpvNegative, (0, _fp.pipe)(incRate, applyNpv)); // prettier-ignore

const reduceToPrecision = (0, _fp.reduce)((0, _fp.pipe)(untilNpvNegative, resetObj), _fp.placeholder, (0, _fp.repeat)(0, precision)); // prettier-ignore

const findNpvVsRateXIntercept = obj => (0, _fp.pipe)(findIrrMag, initialObj(_fp.placeholder, obj), reduceToPrecision, untilNpvNegative)(obj); // prettier-ignore


const postProcess = (0, _fp.pipe)(normalizeResult, generateIrrResultObject, _fp.Maybe.Just); // prettier-ignore

const irrWithDcf = (0, _fp.cond)([[isIrrCalculable, (0, _fp.pipe)(_cashflow.generateNetCashflow, generateIrrIterationObject(false), findNpvVsRateXIntercept, postProcess)], [isRentalIrrCalculable, (0, _fp.pipe)(generateRentalCashflow, generateIrrIterationObject(true), findNpvVsRateXIntercept, postProcess)], [_fp.T, (0, _fp.always)(_fp.Maybe.Nothing)]]); // prettier-ignore

exports.irrWithDcf = irrWithDcf;
const irr = (0, _fp.pipe)(irrWithDcf, (0, _fp.ifElse)(_fp.Maybe.isJust, (0, _fp.pipe)(_fp.Maybe.fromMaybe({}), (0, _fp.prop)('irr'), _fp.Maybe.Just), (0, _fp.always)(_fp.Maybe.Nothing)));
exports.irr = irr;