// @flow
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import NiceModal from '@ebay/nice-modal-react';
import EmailSelect from 'containers/email-select';
import {
  Modal,
  Button,
  useNiceModal,
  ModalBodyHeight,
  Scroll,
  RelativeWrapper,
  PaddedColumn,
  AnimatedIconButton,
} from '@sharkfinesse/sfl-ui';
import { isNil } from '@sharkfinesse/sfl-lib';
import arrowRight from 'animated-icons/arrow-right.json';
import { useSessionDetails } from 'redux/firestore/hooks';

const UserModal = ({
  title,
  maxAllowed = 20,
  byProductOnly,
  creatable,
  label,
  children,
  loadDetails = false,
  sessionId,
  account,
  excludeEditors,
}) => {
  const [emailList, setEmailList] = useState();
  const [usersToExclude, setUsersToExclude] = useState([]);
  const { modal, modalProps } = useNiceModal();
  const enabled = sessionId && account ? true : false;
  const { data, isLoading } = useSessionDetails({ id: sessionId, account }, { enabled });

  useEffect(() => {
    if (!isLoading && excludeEditors && data?.editors) {
      setUsersToExclude(data?.editors);
    }
  }, [isLoading, data]);

  const confirm = () => {
    modal.resolve({ emailList });
  };

  const changeEmails = emails => {
    if (emails == null || emails.length <= maxAllowed) {
      setEmailList(emails);
    }
  };

  return (
    <Modal
      {...modalProps}
      title={title}
      showHeaderKeyline
      showFooterKeyline
      closeOnOutsideClick={false}
      footer={
        <>
          <Button style={{ marginLeft: '10px', minWidth: '60px' }} onClick={modalProps.onClose}>
            <FormattedMessage id="buttons.cancel.label" defaultMessage="Cancel" />
          </Button>

          <AnimatedIconButton
            icon={arrowRight}
            color="Primary"
            onClick={confirm}
            style={{ marginLeft: '10px' }}
            disabled={isNil(emailList) || emailList?.length <= 0}
          >
            <div
              css={`
                margin-left: 8px;
              `}
            >
              <FormattedMessage id="buttons.next.label" defaultMessage="Next" />
            </div>
          </AnimatedIconButton>
        </>
      }
    >
      <ModalBodyHeight maxHeight="410px">
        <RelativeWrapper>
          <Scroll noScrollX={true}>
            <PaddedColumn>
              {label}

              {emailList != null && emailList.length === maxAllowed && (
                <>
                  <br />
                  <FormattedMessage
                    id="dealShare.shareModal.limit"
                    defaultMessage="You have reached the maximum you can share to at a time ({maxAllowed})."
                    values={{ maxAllowed }}
                  />
                </>
              )}
              <EmailSelect
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                onChange={changeEmails}
                byProductOnly={byProductOnly}
                disabled={true}
                selectedOptions={emailList}
                maxOptions={2}
                value={emailList}
                setValue={setEmailList}
                creatable={creatable}
                usersToExclude={usersToExclude}
              />
              {children}
            </PaddedColumn>
          </Scroll>
        </RelativeWrapper>
      </ModalBodyHeight>
    </Modal>
  );
};

export default NiceModal.create(UserModal);
