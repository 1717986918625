// @flow
import React from 'react';
import type { Element } from 'react';
import { Button, ButtonLabel } from './styled';

type Props = {
  label: String,
  children: Element<*> | String,
};

const ExpandIconButton = ({ children, label, ...props }: Props): React.Element<any> => {
  return (
    <Button {...props}>
      {children}
      <ButtonLabel>{label}</ButtonLabel>
    </Button>
  );
};

export default React.memo(ExpandIconButton);
