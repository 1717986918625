"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = require("../../index");

var _reselect = require("reselect");

var _session = _interopRequireDefault(require("./session"));

var _sessionDetails = _interopRequireDefault(require("./session-details"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* eslint-disable no-unused-vars  */
// Selectors
const getRecurringCosts = state => state.session.present.recurringCosts;

const getIds = state => state.session.present.recurringCosts.ids;

const getCosts = state => state.session.present.recurringCosts.costs;

const getCostsCashflowOriginal = state => state.session.present.cashflow.recurringCosts;

const getCost = (state, props) => state.session.present.recurringCosts.costs[props.id] || {};

const getCostDescription = (state, props) => state.session.present.recurringCosts.costs[props.id].description;

const getCostRecurrence = (state, props) => state.session.present.recurringCosts.costs[props.id].recurrence;

const getCostType = (state, props) => state.session.present.recurringCosts.costs[props.id].type;

const getCostValue = (state, props) => state.session.present.recurringCosts.costs[props.id].cost;

const getCostCashflow = (state, props) => state.session.present.cashflow.recurringCosts[props.id];

const getEdittedCostCashflow = (state, props) => state.session.present.cashflow.editted_recurringCosts[props.id];

const getEdittedCostCashflowTotal = (state, props) => {
  const edittedCashflow = state.session.present.cashflow.editted_recurringCosts[props.id];

  if (edittedCashflow) {
    const revPeriod = state.session.present.details.reviewPeriod;
    return (0, _index.sum)(edittedCashflow) / (0, _index.adjustTimeUnit)('months', revPeriod);
  }

  return null;
};

const getEdittedCostsCashflow = (state, props) => state.session.present.cashflow.editted_recurringCosts;

const getPeriod = state => state.session.present.recurringCosts.period;

const getNotes = state => state.session.present.recurringCosts.notes;

const getResult = state => state.session.present.recurringCosts.result;

const getCostsCashflow = (0, _reselect.createSelector)([getCostsCashflowOriginal, getEdittedCostsCashflow], (original, editted) => {
  return (0, _index.mergeRight)(original, editted);
});
const getQuantity = (0, _reselect.createSelector)([getCostsCashflow], cashflows => {
  return (0, _index.length)((0, _index.keys)(cashflows));
});

const getShowCashflow = (state, props) => state.session.present.recurringCosts.costs[props.id].showCashflow;

const getCostGrowth = (0, _reselect.createSelector)([getCost], data => data.growth === '' ? 0 : data.growth);
const getCostGrowthForDisplay = (0, _reselect.createSelector)([getCost], data => data.growth === '' ? '' : data.growth);
const getCostStart = (0, _reselect.createSelector)([getCost], data => data.start === '' ? (0, _index.adjustTimeUnit)('months', 1) : (0, _index.adjustTimeUnit)('months', data.start));
const getCostStartForDisplay = (0, _reselect.createSelector)([getCost], data => data.start === '' ? '' : (0, _index.adjustTimeUnit)('months', data.start));
const getCostEnd = (0, _reselect.createSelector)([getCost, _sessionDetails.default.getReviewPeriod], (data, reviewPeriod) => data.end === '' ? (0, _index.adjustTimeUnit)('months', reviewPeriod) : (0, _index.adjustTimeUnit)('months', data.end));
const getCostEndForDisplay = (0, _reselect.createSelector)([getCost], data => data.end === '' ? '' : (0, _index.adjustTimeUnit)('months', data.end));
const getResultDisplay = (0, _reselect.createSelector)([getResult, _sessionDetails.default.getNumberFormatObj], (value, numberFormatObj) => (0, _index.formatForDisplay)('money', numberFormatObj, value));
const getCostsCashflowData = (0, _reselect.createSelector)([getCostsCashflow, getIds], (cashflows, ids) => {
  const chartData = id => {
    if (cashflows[id]) {
      return cashflows[id];
    } else {
      return [];
    }
  };

  const costsArray = (0, _index.map)(chartData, ids);
  return (0, _index.apply)(_index.sumCashflows, costsArray);
});
const getChartData = (0, _reselect.createSelector)([getCosts, getIds, getEdittedCostsCashflow, _sessionDetails.default.getReviewPeriod], (costs, ids, edittedCostsCashflow = {}, reviewPeriod) => {
  const chartData = id => {
    let cost = costs[id].cost;

    if (edittedCostsCashflow[id]) {
      cost = (0, _index.sum)(edittedCostsCashflow[id]) / reviewPeriod;
    }

    const costObj = {
      description: costs[id].description,
      cost
    };
    return costObj;
  };

  const removePristineRows = (0, _index.reject)(x => costs[x].pristine === true, ids);
  return (0, _index.reverse)((0, _index.map)(chartData, removePristineRows));
});
const getChartDataReport = (0, _reselect.createSelector)([getCosts, getIds, getEdittedCostsCashflow, _sessionDetails.default.getReviewPeriod, _sessionDetails.default.getRecurringCostsChartOrder], (costs, ids, edittedCostsCashflow = {}, reviewPeriod, chartOrder) => {
  const chartData = id => {
    let cost = costs[id].cost;

    if (edittedCostsCashflow[id]) {
      cost = (0, _index.sum)(edittedCostsCashflow[id]) / reviewPeriod;
    }

    const costObj = {
      description: costs[id].description,
      cost
    };
    return costObj;
  };

  const removePristineRows = (0, _index.reject)(x => costs[x].pristine === true, ids);
  let chartDataArray = (0, _index.map)(chartData, removePristineRows);
  const chartLimit = 20;

  if (chartDataArray.length > chartLimit) {
    const chartDataArrayOthers = (0, _index.pipe)((0, _index.sortBy)((0, _index.prop)('cost')), _index.reverse, (0, _index.splitAt)(chartLimit))(chartDataArray);

    const others = (x, y) => {
      return (0, _index.add)(x, y.cost);
    };

    const othersTotal = (0, _index.reduce)(others, 0, chartDataArrayOthers[1]);
    chartDataArrayOthers[0].push({
      description: 'others',
      cost: othersTotal
    });
    chartDataArray = chartDataArrayOthers[0];
  }

  chartDataArray = (0, _index.reverse)(chartDataArray);

  if (chartOrder) {
    // Default order
    if (chartOrder.field === 'list' && chartOrder.direction === 'asc') {
      return chartDataArray;
    }

    if (chartOrder.field === 'list' && chartOrder.direction === 'desc') {
      return (0, _index.reverse)(chartDataArray);
    } // Cost Value


    if (chartOrder.field === 'cost' && chartOrder.direction === 'asc') {
      return (0, _index.sortBy)((0, _index.prop)('cost'))(chartDataArray);
    }

    if (chartOrder.field === 'cost' && chartOrder.direction === 'desc') {
      return (0, _index.reverse)((0, _index.sortBy)((0, _index.prop)('cost'))(chartDataArray));
    } // Alphabetical


    if (chartOrder.field === 'name' && chartOrder.direction === 'asc') {
      return (0, _index.sortBy)((0, _index.prop)('description'))(chartDataArray);
    }

    if (chartOrder.field === 'name' && chartOrder.direction === 'desc') {
      return (0, _index.reverse)((0, _index.sortBy)((0, _index.prop)('description'))(chartDataArray));
    }
  }

  return chartDataArray;
});
const getTotalCosts = (0, _reselect.createSelector)([getCostsCashflow, getIds], (cashflow, ids) => {
  const costsArray = id => {
    if (cashflow[id]) {
      return (0, _index.reduce)(_index.add, 0, cashflow[id]);
    } else {
      return 0;
    }
  };

  return (0, _index.reduce)(_index.add, 0, (0, _index.map)(costsArray, ids));
});
const getEditMode = (0, _reselect.createSelector)([getCost], data => {
  let editMode = false;

  if (data.editMode === true) {
    editMode = true;
  }

  return editMode;
});
const getCashflow = (0, _reselect.createSelector)([(state, props) => props, getCostCashflow, getEdittedCostCashflow], (props, cashflow, edittedCashflow) => {
  if (!(0, _index.isNil)(edittedCashflow)) return edittedCashflow;
  if (!(0, _index.isNil)(cashflow)) return cashflow;
  return [];
});

const getIsCashflowEdited = (state, props) => state.session.present.cashflow.editted_recurringCosts[props.id] ? true : false;

const selectors = {
  getRecurringCosts,
  getCosts,
  getCost,
  getCostDescription,
  getCostGrowth,
  getCostGrowthForDisplay,
  getCostRecurrence,
  getCostType,
  getCostValue,
  getCostStart,
  getCostStartForDisplay,
  getCostEnd,
  getCostEndForDisplay,
  getCostCashflow,
  getIds,
  getPeriod,
  getNotes,
  getResult,
  getResultDisplay,
  getCostsCashflowData,
  getChartData,
  getChartDataReport,
  getQuantity,
  getTotalCosts,
  getShowCashflow,
  getEditMode,
  getEdittedCostCashflow,
  getEdittedCostCashflowTotal,
  getCashflow,
  getIsCashflowEdited
};
var _default = selectors;
exports.default = _default;