"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reselect = require("reselect");

var _index = require("../../index");

var _sessionDetails = _interopRequireDefault(require("./session-details"));

var _benefits = _interopRequireDefault(require("./benefits"));

var _recurringCosts = _interopRequireDefault(require("./recurring-costs"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* eslint-disable no-unused-vars  */
// Selectors
const getDelayPeriod = state => state.session.present.delay.delayPeriod;

const getIsDirty = state => state.session.present.delay.isDirty;

const getIsUsed = state => state.session.present.delay.isUsed;

const getIsCollapsed = state => state.session.present.delay.isCollapsed;

const getNetMonthlyBenefits = (0, _reselect.createSelector)([_benefits.default.getMonthlyBenefits, _recurringCosts.default.getResult], (monthlyBenefits, recurringCosts) => {
  return monthlyBenefits - recurringCosts;
});
const getNetMonthlyBenefitsResult = (0, _reselect.createSelector)([getNetMonthlyBenefits, _sessionDetails.default.getNumberFormatObj], (value, numberFormatObj) => (0, _index.formatForDisplay)('money', numberFormatObj, value));
const getResult = (0, _reselect.createSelector)([getNetMonthlyBenefits, getDelayPeriod], (netBenefits, delayPeriod) => {
  return (0, _index.costOfDelay)(delayPeriod, netBenefits);
});
const getResultDisplay = (0, _reselect.createSelector)([getResult, _sessionDetails.default.getNumberFormatObj], (value, numberFormatObj) => (0, _index.formatForDisplay)('money', numberFormatObj, value));
const getDelayPeriodDisplay = (0, _reselect.createSelector)([getDelayPeriod, _sessionDetails.default.getNumberFormatObj], (value, numberFormatObj) => {
  return (0, _index.formatForDisplay)('number', numberFormatObj, value);
});
const getCashflowChartData = (0, _reselect.createSelector)([getDelayPeriodDisplay, getNetMonthlyBenefits], (delayPeriod, netMonthlyBenefit) => {
  let chartArray = [];

  for (let x = 0; x < Number(delayPeriod) + 1; x++) {
    const delayCost = netMonthlyBenefit * x;
    chartArray.push([x, delayCost]);
  }

  return chartArray;
});
const getDisplay = (0, _reselect.createSelector)([getNetMonthlyBenefits], netMonthlyBenefits => netMonthlyBenefits ? true : false);
const getCalculated = (0, _reselect.createSelector)([getDelayPeriod], delayPeriod => (0, _index.isNil)(delayPeriod) || (0, _index.isEmpty)(delayPeriod) ? false : true);
const selectors = {
  getResult,
  getResultDisplay,
  getNetMonthlyBenefitsResult,
  getDelayPeriod,
  getIsDirty,
  getIsUsed,
  getIsCollapsed,
  getCashflowChartData,
  getDelayPeriodDisplay,
  getDisplay,
  getCalculated
};
var _default = selectors;
exports.default = _default;