import { useModal } from '@ebay/nice-modal-react';

const useNiceModal = () => {
  const modal = useModal();

  return {
    modal,
    modalProps: {
      isOpen: modal.visible,
      onClose: () => modal.hide(),
      onRest: () => {
        modal.resolveHide();
        !modal.keepMounted && modal.remove();
      },
    },
  };
};

export default useNiceModal;
