/* @flow */
import {
  simpleAction,
  createReducer,
  normalizeTime,
  clamp,
  minReviewPeriod,
  maxReviewPeriod,
  preferencesSessionInitialState,
} from '@sharkfinesse/sfl-lib';
const namespace = (name: string): string => `@@preferences.session/${name}`;

//Actions
export const types = {
  CURRENCY: { UPDATE: namespace('CURRENCY.UPDATE') },
  NUMBER_FORMAT: { UPDATE: namespace('NUMBER_FORMAT.UPDATE') },
  DATE_FORMAT: { UPDATE: namespace('DATE_FORMAT.UPDATE') },
  DEFAULT_START_MONTH: { UPDATE: namespace('DEFAULT_START_MONTH.UPDATE') },
};

//Reducer
export const initialState = preferencesSessionInitialState;

const reduceCurrency = (state, action) => ({
  ...state,
  details: {
    ...state.details,
    currency: {
      ...state.details.currency,
      ...action.payload,
    },
  },
});

const reduceNumberFormat = (state, action) => ({
  ...state,
  details: {
    ...state.details,
    numberFormat: action.payload.value,
  },
});

const reduceDateFormat = (state, action) => ({
  ...state,
  details: {
    ...state.details,
    dateFormat: action.payload.value,
  },
});

const reduceDefaultStartMonth = (state, { payload: { defaultStartMonth } }) => ({
  ...state,
  details: {
    ...state.details,
    defaultStartMonth: normalizeTime(
      'months',
      clamp(minReviewPeriod, maxReviewPeriod, defaultStartMonth)
    ),
  },
});

export default createReducer(
  {
    [types.CURRENCY.UPDATE]: reduceCurrency,
    [types.NUMBER_FORMAT.UPDATE]: reduceNumberFormat,
    [types.DATE_FORMAT.UPDATE]: reduceDateFormat,
    [types.DEFAULT_START_MONTH.UPDATE]: reduceDefaultStartMonth,
  },
  initialState
);

//Action Creators
const updateCurrency = simpleAction(types.CURRENCY.UPDATE);
const updateNumberFormat = simpleAction(types.NUMBER_FORMAT.UPDATE);
const updateDateFormat = simpleAction(types.DATE_FORMAT.UPDATE);
const updateDefaultStartMonth = simpleAction(types.DEFAULT_START_MONTH.UPDATE);

export const actionCreators = {
  updateCurrency,
  updateNumberFormat,
  updateDateFormat,
  updateDefaultStartMonth,
};
