"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setAlphaHsla = exports.saturateHsla = exports.rotateHsla = exports.lightenHsla = exports.hsla = exports.desaturateHsla = exports.darkenHsla = void 0;

var _fp = require("./fp");

const percentCap = 100;
const clampPercent = (0, _fp.clamp)(0, percentCap);
const degreeCap = 360;
const clampDegrees = (0, _fp.clamp)(0, degreeCap);
const alphaCap = 1;
const clampAlpha = (0, _fp.clamp)(0, alphaCap);
const hsla = (0, _fp.useWith)((0, _fp.pipe)((0, _fp.unapply)(_fp.flatten), (0, _fp.prepend)('hsla('), (0, _fp.join)('')), [(0, _fp.pipe)(clampDegrees, String, (0, _fp.append)(', ')), (0, _fp.pipe)(clampPercent, String, (0, _fp.append)('%, ')), (0, _fp.pipe)(clampPercent, String, (0, _fp.append)('%, ')), (0, _fp.pipe)(clampAlpha, String, (0, _fp.append)(')'))]);
exports.hsla = hsla;
const hslaValuesLength = 5;
const hslaValues = (0, _fp.pipe)((0, _fp.match)(/^hsla\((\d+), (\d+)%, (\d+)%, (\d+\.?\d*)\)$/), (0, _fp.slice)(1, hslaValuesLength), (0, _fp.map)(Number));
const hslaHueIndex = 0;
const hslaHue = (0, _fp.lensIndex)(hslaHueIndex);
const hslaSaturationIndex = 1;
const hslaSaturation = (0, _fp.lensIndex)(hslaSaturationIndex);
const hslaLightnessIndex = 2;
const hslaLightness = (0, _fp.lensIndex)(hslaLightnessIndex);
const hslaAlphaIndex = 3;
const hslaAlpha = (0, _fp.lensIndex)(hslaAlphaIndex);
const flipCalculation = (0, _fp.useWith)(_fp.placeholder, [_fp.negate, _fp.idiot]);
const rotateHsla = (0, _fp.useWith)((0, _fp.pipe)(_fp.call, (0, _fp.apply)(hsla)), [(0, _fp.pipe)(_fp.add, (0, _fp.over)(hslaHue)), hslaValues]);
exports.rotateHsla = rotateHsla;
const saturateHsla = (0, _fp.useWith)((0, _fp.pipe)(_fp.call, (0, _fp.apply)(hsla)), [(0, _fp.pipe)(_fp.add, (0, _fp.over)(hslaSaturation)), hslaValues]);
exports.saturateHsla = saturateHsla;
const desaturateHsla = flipCalculation(saturateHsla);
exports.desaturateHsla = desaturateHsla;
const lightenHsla = (0, _fp.useWith)((0, _fp.pipe)(_fp.call, (0, _fp.apply)(hsla)), [(0, _fp.pipe)(_fp.add, (0, _fp.over)(hslaLightness)), hslaValues]);
exports.lightenHsla = lightenHsla;
const darkenHsla = flipCalculation(lightenHsla);
exports.darkenHsla = darkenHsla;
const setAlphaHsla = (0, _fp.useWith)((0, _fp.pipe)(_fp.call, (0, _fp.apply)(hsla)), [(0, _fp.set)(hslaAlpha), hslaValues]);
exports.setAlphaHsla = setAlphaHsla;