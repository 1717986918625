import { has } from '@sharkfinesse/sfl-lib';
export const sizes = {
  xsmall: '12px',
  small: '16px',
  medium: '24px',
  large: '32px',
  xlarge: '48px',
  xxlarge: '64px',
};

const getSize = props => {
  if (props.size) {
    return has(props.size, sizes)
      ? `height: ${sizes[props.size]}; width: ${sizes[props.size]};`
      : props.size;
  }
  return null;
};

export default getSize;
