import { css } from 'styled-components';
import { lightenHsla } from '@sharkfinesse/sfl-lib';

const bravo = 10;
const U = 15;
const InputConfirm = ({ font, colors, scale }) => ({
  base: (src) => css`
    width: 100%;
    height: 31px;
    display: flex;
    flex-direction: row;
    flex-wrap: 'nowrap';
    flex: 1;
    border-top: 1px solid ${colors.uiLight};
    min-height: 31px;
    align-items: center;
  `,
  button: (src) => css`
    min-height: 30px;
    height: 30px;
    align-items: center;
    width: 50%;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${lightenHsla(bravo, colors.uiLight)};
    color: ${colors.uiDarkest};
    cursor: pointer;
    &:focus {
      outline: 2px solid ${colors.primary};
      z-index: 10;
    }
    &:hover {
      background-color: ${lightenHsla(U, colors.uiLight)};
    }
  `,
  confirm: (src) => css`
    border-left: 1px solid ${colors.uiLight};
    border-bottom-right-radius: 2px;
  `,
  cancel: (src) => css`
    border-bottom-left-radius: 2px;
  `,
});

export default InputConfirm;
