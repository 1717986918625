/* @flow */
import { simpleAction, createReducer, preferencesThemeInitialState } from '@sharkfinesse/sfl-lib';
const namespace = (name: string): string => `@@preferences.theme/${name}`;

//Actions
export const types = {
  THEME: {
    UPDATE: namespace('THEME.UPDATE'),
  },
};

//Reducer
export const initialState = preferencesThemeInitialState;

const reduceTheme = (state, action) => ({
  ...state,
  ...action.payload,
});

export default createReducer(
  {
    [types.THEME.UPDATE]: reduceTheme,
  },
  initialState
);

//Action Creators
const updateTheme = simpleAction(types.THEME.UPDATE);

export const actionCreators = {
  updateTheme,
};
