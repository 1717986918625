import { path } from '@sharkfinesse/sfl-lib';
import { types } from 'redux/modules/costs';
import { put, call, takeLatest } from 'redux-saga/effects';
import { actionCreators as nonRecurringCostsActionCreators } from 'redux/modules/non-recurring-costs';
import { actionCreators as recurringCostsActionCreators } from 'redux/modules/recurring-costs';
import { actionCreators as cashflowActionCreators } from 'redux/modules/cashflow';

import undoToast from 'redux/sagas/toast/undo';

function* deleteAll(action) {
  const meta = path(['meta'], action);
  yield call(undoToast, {
    action,
    undoActions: [
      put(recurringCostsActionCreators.updateReset({ meta })),
      put(nonRecurringCostsActionCreators.updateReset({ meta })),
      put(cashflowActionCreators.updateCostsReset({ meta })),
    ],
    formattedMessageId: 'costs.delete-all.toast.message',
    defaultMessage: 'All Costs Deleted',
  });
}

export const effects = [takeLatest(types.DELETE.ALL, deleteAll)];

export default effects;
