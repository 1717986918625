"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mockRjsfErrors = exports.hasErrorMessage = exports.getErrorMessage = exports.filterErrors = exports.evaluateError = void 0;
exports.updateErrors = updateErrors;
exports.validateStart = exports.validateImpact = exports.validateEnd = exports.validate = void 0;

var _fp = require("./fp");

var _mathjs = require("./mathjs");

var _evaluator = require("./evaluator");

// prettier-ignore
const evaluateError = (0, _fp.curryN)(2, (scope, conditional) => (0, _fp.tryCatch)((0, _mathjs.evaluateExpression)(conditional.expression), _fp.F)(scope)); // prettier-ignore

exports.evaluateError = evaluateError;
const filterErrors = (0, _fp.cardinal_)(_fp.filter)(evaluateError);
exports.filterErrors = filterErrors;

function updateErrors(intl, errors, fileredErrorConditionals) {
  (0, _fp.map)(a => errors[a.id].addError(a.message), fileredErrorConditionals); // eslint-disable-line fp/no-unused-expression

  return errors;
}

;
const validate = (0, _fp.curry)((intl, schema, evalProperties, formData, errors) => updateErrors(intl, errors, filterErrors(schema.errors, (0, _fp.merge)((0, _evaluator.adjustUnits)({
  schema: schema,
  scope: formData
}), evalProperties))));
exports.validate = validate;
const numberTypeError = (0, _fp.equals)('should be number');
const dropErrors = (0, _fp.dropWhile)((0, _fp.allPass)([numberTypeError])); // prettier-ignore

const hasErrorMessage = (0, _fp.ifElse)(_fp.isNil, _fp.F, (0, _fp.pipe)(dropErrors, _fp.isEmpty, _fp.not)); // prettier-ignore

exports.hasErrorMessage = hasErrorMessage;
const getErrorMessage = (0, _fp.ifElse)(hasErrorMessage, (0, _fp.pipe)(_fp.head, _fp.Maybe.Just), (0, _fp.always)(_fp.Maybe.Nothing)); // prettier-ignore

exports.getErrorMessage = getErrorMessage;
const validateStart = (0, _fp.allPass)([_fp.lte, (0, _fp.gt)(_fp.placeholder, 0)]); // prettier-ignore

exports.validateStart = validateStart;
const validateEnd = (0, _fp.curry)((start, end, appraisalPeriod) => (0, _fp.allPass)([(0, _fp.gte)(_fp.placeholder, start), (0, _fp.lte)(_fp.placeholder, appraisalPeriod)])(end)); // prettier-ignore

exports.validateEnd = validateEnd;
const validateImpact = _fp.lte; // prettier-ignore

exports.validateImpact = validateImpact;
const mockRjsfErrors = (0, _fp.map)(a => ({
  stack: a.id + ':' + a.message
}));
exports.mockRjsfErrors = mockRjsfErrors;