/* @flow */
import { connect } from 'react-redux';
import { userSelectors } from '@sharkfinesse/sfl-lib';
import EmailSelect from 'components/email-select';

const mapStateToProps = (state, props) => ({
  account: userSelectors.getAccount(state, props),
  product: userSelectors.getProduct(state, props),
  email: userSelectors.getEmail(state, props),
});

export default connect(mapStateToProps)(EmailSelect);
