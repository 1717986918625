import { useTheme } from 'styled-components';
const Component = ({
  Element,
  size = 120,
  lineWeight = 6,
  speed = 1.75,
  color,
  variant = 'dark',
  ...props
}) => {
  const theme = useTheme();
  const lineColor = color
    ? color
    : variant === 'dark'
    ? theme?.loader?.color
    : theme?.loader?.lightColor;
  return <Element size={size} lineWeight={lineWeight} speed={speed} color={lineColor} {...props} />;
};

export default Component;
