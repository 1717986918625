import styled from 'styled-components';

export const RailOuter = styled.div`
  ${({ theme }) => theme.slider.railOuter()};
`;
export const RailInner = styled.div`
  ${({ theme }) => theme.slider.railInner()};
`;
export const StyledHandle = styled.div`
  ${({ theme, ...props }) => theme.slider.handle({ ...props })};
`;
export const StyledTrack = styled.div`
  ${({ theme, ...props }) => theme.slider.track({ ...props })};
`;
export const TickLine = styled.div`
  ${({ theme, ...props }) => theme.slider.tickLine({ ...props })};
`;
export const TickLabel = styled.div`
  ${({ theme, ...props }) => theme.slider.tickLabel({ ...props })};
`;
