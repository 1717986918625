/* @flow */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { map } from 'styled-components-breakpoint';

const StyledTableHead = styled.div`
  ${({ themeKey, theme }) =>
    css`
      ${theme[themeKey].headBackground ? theme[themeKey].headBackground : 'background: white;'};
      ${theme[themeKey].headPadding ? theme[themeKey].headPadding : 'padding: 0;'};
      word-break: break-word;
      line-height: 14px;
      ${theme[themeKey].headerBorderWidth}
      ${theme[themeKey].headerBorderColor}
      ${theme[themeKey].headerBorderStyle}
    `};

  ${({ theme, hide }) =>
    map(hide, v => `display: ${v === true ? 'none' : 'block'};`, theme.breakpoints)};
`;

type DefaultProps = {
  hide?: boolean,
};

type Props = DefaultProps & {
  themeKey?: string,
  children?: Node,
  hide?: Object,
  ...other,
};

class TableHead extends Component<Props, void> {
  static defaultProps = {
    hide: { xxs: false },
  };

  getChildContext() {
    return {
      sfluiTableHeader: {
        head: true,
      },
    };
  }

  render() {
    const { themeKey: themeKeyProp, children, hide, ...other } = this.props;

    const { sfluiTable } = this.context;

    let themeKey = themeKeyProp;

    if (sfluiTable) {
      if (typeof themeKey === 'undefined') {
        themeKey = sfluiTable.themeKey;
      }
    }

    return (
      <StyledTableHead themeKey={themeKey} hide={hide} {...other}>
        {children}
      </StyledTableHead>
    );
  }
}

TableHead.contextTypes = {
  sfluiTable: PropTypes.object,
};

TableHead.childContextTypes = {
  sfluiTable: PropTypes.object,
  sfluiTableHeader: PropTypes.object,
};

export default TableHead;
