"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reselect = require("reselect");

var _index = require("../../index");

var _sessionDetails = _interopRequireDefault(require("./session-details"));

var _npvCalculator = _interopRequireDefault(require("./npv-calculator"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* eslint-disable no-unused-vars  */
// Selectors
const getResultDisplay = (0, _reselect.createSelector)([_npvCalculator.default.getResult, _sessionDetails.default.getNumberFormatObj], (value, numberFormatObj) => (0, _index.formatForDisplay)('money', numberFormatObj, value));
const getResult = (0, _reselect.createSelector)([_npvCalculator.default.getResult], value => value);
const getDisplay = (0, _reselect.createSelector)([_npvCalculator.default.getResult], npv => Math.sign(npv) === 1 ? true : false);

const getIsUsed = state => state.session.present.doNothing.isUsed;

const getIsCollapsed = state => state.session.present.doNothing.isCollapsed;

const selectors = {
  getResultDisplay,
  getResult,
  getDisplay,
  getIsUsed,
  getIsCollapsed
};
var _default = selectors;
exports.default = _default;