import { lazy } from 'react';

function lazyWithRetry(component, retries, interval) {
  return lazy(() => retry(component, retries, interval));
}

function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            window.location.reload();
            return;
          }
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export default lazyWithRetry;
