/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon viewBox="0 0 58 58" {...props}>
    <g>
      <circle fill="inherit" opacity="0.8" cx="29" cy="29" r="28" />

      <g>
        <path
          fill="currentColor"
          d="M29,2c14.9,0,27,12.1,27,27S43.9,56,29,56S2,43.9,2,29S14.1,2,29,2 M29,0C13,0,0,13,0,29s13,29,29,29
			s29-13,29-29S45,0,29,0L29,0z"
        />
      </g>
    </g>
    <polygon
      fill="currentColor"
      points="41,27 31,27 31,17 27,17 27,27 17,27 17,31 27,31 27,41 31,41 31,31 41,31 "
    />
    <polygon
      fill="currentColor"
      points="41,27 31,27 31,17 27,17 27,27 17,27 17,31 27,31 27,41 31,41 31,31 41,31 "
    />
  </Icon>
);

export default memo(IconComponent);
