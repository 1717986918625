// @flow
/* eslint-disable no-magic-numbers, react/prop-types */
import React from 'react';
import { CarouselProvider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { keys, pickBy, equals } from '@sharkfinesse/sfl-lib';
import { CarouselStyleWrapper } from './styled';
import Slider from './slider';
import { useMedia } from 'react-media';
import queries from './queries';
import Skeleton from '../skeleton';

const visibleSlides = {
  xxs: 1,
  xs: 1,
  sm: 2,
  md: 2,
  lg: 2,
  xl: 3,
  xxl: 4,
  xxxl: 5,
  ultrawide: 8,
};

const getSkeletons = (length) => {
  const skeletons = [];
  for (let i = 0; i < length; i += 1) {
    skeletons.push(<Skeleton color="white" borderRadius="12px" key={i} id={i} />);
  }
  return skeletons;
};

const Carousel = ({ slides, innerClassName, slideSpacing = '14px', isLoading }) => {
  const matches = useMedia({ queries });
  const activeMatches = keys(pickBy(equals(true), matches));
  const skeletons = getSkeletons(visibleSlides[activeMatches[0]]);

  return (
    <CarouselStyleWrapper slideSpacing={slideSpacing}>
      <CarouselProvider
        naturalSlideWidth={300}
        naturalSlideHeight={160}
        totalSlides={isLoading ? visibleSlides[activeMatches[0]] : slides.length}
        visibleSlides={visibleSlides[activeMatches[0]]}
        step={visibleSlides[activeMatches[0]]}
        dragEnabled={false}
      >
        <Slider
          slides={isLoading ? skeletons : slides}
          innerClassName={innerClassName}
          slideSpacing={slideSpacing}
          isLoading={isLoading}
        />
      </CarouselProvider>
    </CarouselStyleWrapper>
  );
};

export default Carousel;
