/* @flow */
import React from 'react';
import styled from 'styled-components';

export const ModalHeader = styled.div`
  ${({ theme }) => theme.modal.header()};
  ${({ theme, showKeyline }) => (showKeyline ? theme.modal.headerKeyline({ showKeyline }) : null)};
`;

export const Title = styled.h4`
  ${({ theme }) => theme.modal.title()};
`;

export const TitleText = styled.span`
  ${({ theme }) => theme.modal.titleText()};
`;

export const TitleIconWrapper = styled.span`
  ${({ theme }) => theme.modal.titleIconWrapper()};
`;

type Props = {
  showKeyline: boolean,
  title: string,
  children?: Element<any>,
};

const Header = ({ showKeyline, title, children, ...props }: Props) => (
  <ModalHeader showKeyline={showKeyline}>
    <Title>
      <TitleText>{title}</TitleText>
    </Title>
    <TitleIconWrapper>{children}</TitleIconWrapper>
  </ModalHeader>
);

export default Header;
