// @flow
import styled from 'styled-components';
import applyTheme from '../../styles/apply-theme';
import { animated } from 'react-spring';

const gallery = applyTheme('gallery');

export const Img = gallery('image', styled(animated.img));
export const Attribution = gallery('attribution');
export const MenuWrapper = gallery('menuWrapper');
export const MenuIcon = gallery('menuIcon');

const menu = applyTheme('menu');
export const MenuInfo = menu('menuInfo');
export const MenuInfoTitle = menu('menuInfoTitle');
export const MenuCreator = menu('menuCreator');
export const MenuLink = menu('menuLink');

export const ImgWrapper = gallery(
  'imageWrapper',
  styled.div.attrs((props) => ({
    style: {
      width: `${props.width}px`,
      height: `${props.height}px`,
    },
  }))
);
