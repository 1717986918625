import { useMutation } from 'react-query';
import fetchAuth from 'utils/fetchAuth';

const useSendToSupport = config => {
  return useMutation(async body => {
    const response = await fetchAuth({
      url: 'session/support/send',
      config: {
        method: 'POST',
        body: JSON.stringify(body),
      },
    });
    return response;
  }, config);
};

export default useSendToSupport;
