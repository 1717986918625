/* @flow */
import { connect } from 'react-redux';
import { preferencesSelectors } from '@sharkfinesse/sfl-lib';
import CurrencySelect from 'components/preferences/privacy';
import { actionCreators as preferencesDebuggingActionCreators } from 'redux/modules/preferences/debugging';
import { actionCreators as preferencesAnalyticsActionCreators } from 'redux/modules/preferences/analytics';

const mapStateToProps = (state, props) => ({
  debuggingEnabled: preferencesSelectors.getDebuggingEnabled(state, props),
  analyticsEnabled: preferencesSelectors.getAnalyticsEnabled(state, props),
});

const mapDispatchToProps = {
  updateDebuggingEnabled: preferencesDebuggingActionCreators.updateEnabled,
  updateAnalyticsEnabled: preferencesAnalyticsActionCreators.updateEnabled,
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrencySelect);
