"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

// Selectors
const getAccount = state => state.session.present.plugins.salesforce.account;

const getAccountName = state => state.session.present.plugins.salesforce.accountName;

const getOpportunity = state => state.session.present.plugins.salesforce.opportunity;

const getOpportunityName = state => state.session.present.plugins.salesforce.opportunityName;

const getOpportunityObjectId = state => state.session.present.plugins.salesforce.opportunityObjectId;

const getOpportunitySyncDate = state => state.session.present.plugins.salesforce.opportunitySyncDate;

const selectors = {
  getAccount,
  getAccountName,
  getOpportunity,
  getOpportunityName,
  getOpportunityObjectId,
  getOpportunitySyncDate
};
var _default = selectors;
exports.default = _default;