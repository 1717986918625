import React from 'react';
import LogRocket from 'logrocket';
import ModalInfo from 'components/modal/modal-info';
import NiceModal from '@ebay/nice-modal-react';
import { FormattedMessage } from 'react-intl';

const errorModal = ({ error }) => {
  LogRocket.captureException(error);

  NiceModal.show(ModalInfo, {
    title: (
      <FormattedMessage
        id="app.state.offline.notAvailable.modal.title"
        defaultMessage="We're sorry..."
      />
    ),
    body: (
      <FormattedMessage
        id="error.generic"
        defaultMessage="Sorry, an error has occurred with the following:{breakingLine}{breakingLine}{error}{breakingLine}{breakingLine}Please try again."
        values={{ error, breakingLine: <br /> }}
      />
    ),
  });
};

export default errorModal;
