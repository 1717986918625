/* @flow */
import React from 'react';
import { LabelStyle } from './styled';

type Props = {
  children: Any,
};

const HotkeyLabel = ({ children }: Props) => <LabelStyle>{children}</LabelStyle>;

export default HotkeyLabel;
