import styled, { css } from 'styled-components';
import { is, split, length } from '@sharkfinesse/sfl-lib';

const applyTheme = path => (func, styledType) => {
  const styledItem = styledType ? styledType : styled.div;
  return styledItem`
  ${({ theme, ...props }) => {
    if (is(String, func)) {
      return theme[path][func]({ ...props });
    } else if (is(Array, func)) {
      let styles = '';
      for (let i = 0; i < func.length; i++) {
        const item = func[i];
        const itemPath = split('.', item);

        if (length(itemPath) > 1) {
          styles = css`
            ${styles}
            ${theme[itemPath[0]][itemPath[1]]({ ...props })}
          `;
        } else {
          styles = css`
            ${styles}
            ${theme[path][itemPath[0]]({ ...props })}
          `;
        }
      }
      return styles;
    }
  }};
  `;
};

export default applyTheme;
