import { padding } from 'polished';

const SavingMessage = ({ font, colors, scale }) => ({
  fontColor: font.color,
  fontWeight: font.weightNormal,
  fontSize: font.sizeNormal,
  padding: padding(scale.zero),
});

export default SavingMessage;
