import React, { Component, Fragment } from 'react';
import LogRocket from 'logrocket';
import { withRouter } from 'react-router-dom';
import ModalInfo from 'components/modal/modal-info';
import ReactMarkdown from 'react-markdown';
import { injectIntl, FormattedMessage } from 'react-intl';
import NiceModal from '@ebay/nice-modal-react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    const { intl, history, errorCode } = this.props;
    // Display fallback UI
    this.setState({ hasError: true });
    // log error with log rocket
    console.log('info', info);
    console.log('error', error);
    LogRocket.captureException(error, {
      extra: {
        // additional arbitrary data associated with the event
        componentStack: info.componentStack,
      },
    });
    //window.location.reload();
    history.push(`/`);
    NiceModal.show(ModalInfo, {
      title: (
        <FormattedMessage
          id="app.state.offline.notAvailable.modal.title"
          defaultMessage="We're sorry..."
        />
      ),
      body: (
        <Fragment>
          <FormattedMessage
            id="error.general"
            defaultMessage="Sorry, an error has occurred. Please try again."
          />
          <ReactMarkdown>
            {intl.formatMessage(
              {
                id: `error.contactUs`,
                defaultMessage: `If you would like to discuss this further please [contact us](https://sharkfinesse.com/contact-us "Our contact details") quoting the reference: **{errorCode}**`,
              },
              { errorCode }
            )}
          </ReactMarkdown>
        </Fragment>
      ),
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return;
    }

    return this.props.children;
  }
}
ErrorBoundary.defaultProps = { errorCode: 'S100' };

export default withRouter(injectIntl(ErrorBoundary));
