/* @flow */
/* eslint-disable no-confusing-arrow, no-nested-ternary  */
import React from 'react';
import styled from 'styled-components';
import Popper from '../popper';
import { path } from '@sharkfinesse/sfl-lib';

const ContentWrapper = styled.div`
  margin: 2px 8px;
`;

const Tooltip = ({
  tooltip,
  tooltipConfig,
  toolTipContainerProps,
  children,
  tooltipProps,
  tipStyle = 'dark',
  ...props
}) => (
  <Popper
    content={<ContentWrapper>{tooltip}</ContentWrapper>}
    config={tooltipConfig}
    delayShow={500}
    placement={tooltipConfig?.placement}
    usePortal={tooltipConfig?.usePortal}
    portalContainer={tooltipConfig?.portalContainer}
    tipStyle={tipStyle}
    modifiers={path(['modifiers'], tooltipConfig)}
    toolTipContainerProps={toolTipContainerProps}
    zIndex={9050}
    popperProps={tooltipProps}
    {...props}
  >
    {children}
  </Popper>
);

export default Tooltip;
