"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.trailingZeros = exports.trailingDecimalZeros = exports.toExponential = exports.significand = exports.sanitizeNumberStr = exports.safeAdjustExponent = exports.round = exports.removeTrailingZeros = exports.reductionRate = exports.precision = exports.pow10 = exports.pow = exports.percentageToDecimal = exports.normalizingFloatingFixedPointFactor = exports.magnitude = exports.isNegativeOrZero = exports.isExponential = exports.increaseByFloatingFixedPointFactor = exports.growthRate = exports.getExponentAsString = exports.getExponent = exports.getDecimalPartAsString = exports.getDecimalPart = exports.getBase = exports.divideBy = exports.decreaseByFloatingFixedPointFactor = exports.decimalToPercent = exports.complementaryPercentage = exports.adjustSiPrefix = exports.addTrailingZeros = void 0;

var _lodash = _interopRequireDefault(require("lodash.round"));

var _fp = require("./fp");

var _constants = require("./constants");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const siPrefixes = {
  femto: -18,
  pico: -12,
  nano: -9,
  micro: -6,
  milli: -3,
  one: 0,
  kilo: 3,
  mega: 6,
  giga: 9,
  tera: 12,
  peta: 15,
  exa: 18,
  zetta: 21,
  yotta: 24
}; // prettier-ignore

const round = (0, _fp.curryN)(2, (0, _fp.flip)(_lodash.default)); // prettier-ignore

exports.round = round;
const pow = (0, _fp.curryN)(2, (0, _fp.flip)(Math.pow)); // prettier-ignore

exports.pow = pow;
const divideBy = (0, _fp.flip)(_fp.divide); // Percents expressed application wide to (6dp)

exports.divideBy = divideBy;
const percentageToDecimal = (0, _fp.divide)(_fp.__, _constants.percentageToDecimalFactor);
exports.percentageToDecimal = percentageToDecimal;
const decimalToPercent = (0, _fp.multiply)(_constants.percentageToDecimalFactor);
exports.decimalToPercent = decimalToPercent;
const isNegativeOrZero = (0, _fp.lte)(_fp.__, 0); // prettier-ignore

exports.isNegativeOrZero = isNegativeOrZero;
const increaseByFloatingFixedPointFactor = (0, _fp.goldfinch)(_fp.multiply)(pow(_fp.__, _constants.floatingFixedPointFactor)); // prettier-ignore

exports.increaseByFloatingFixedPointFactor = increaseByFloatingFixedPointFactor;
const normalizingFloatingFixedPointFactor = pow(_fp.__, _constants.floatingFixedPointFactor); // prettier-ignore

exports.normalizingFloatingFixedPointFactor = normalizingFloatingFixedPointFactor;
const complementaryPercentage = (0, _fp.subtract)(_constants.percentageToDecimalFactor); // prettier-ignore

exports.complementaryPercentage = complementaryPercentage;
const growthRate = (0, _fp.pipe)((0, _fp.add)(1), divideBy(Math.LN10), divideBy(_constants.monthsPerYear)); // prettier-ignore

exports.growthRate = growthRate;
const reductionRate = (0, _fp.pipe)((0, _fp.subtract)(1), divideBy(Math.LN10), divideBy(_constants.monthsPerYear));
exports.reductionRate = reductionRate;
const floatingPointAdjustment = 0.000000001; // prettier-ignore

const magnitude = (0, _fp.pipe)(Math.log, divideBy(Math.LN10), (0, _fp.add)(floatingPointAdjustment), Math.floor);
exports.magnitude = magnitude;
const factorMagnitude = magnitude(_constants.floatingFixedPointFactor); // prettier-ignore

const decreaseByFloatingFixedPointFactor = (0, _fp.curryN)(2, (pow, value) => (0, _fp.pipe)((0, _fp.multiply)(factorMagnitude), (0, _fp.repeat)(10), // eslint-disable-line no-magic-numbers
(0, _fp.reduce)(_fp.divide, value), round(12) // eslint-disable-line no-magic-numbers
)(pow)); // prettier-ignore

exports.decreaseByFloatingFixedPointFactor = decreaseByFloatingFixedPointFactor;
const precision = (0, _fp.curry)((precision, value) => parseFloat(value.toPrecision(precision))); // prettier-ignore

exports.precision = precision;
const significand = (0, _fp.curry)((significand, value) => value - value % pow((0, _fp.inc)(magnitude(value)) - significand, _constants.base10)); // prettier-ignore

exports.significand = significand;
const getDecimalPart = (0, _fp.curry)((decimalPlaces, value) => parseFloat((value % 1).toFixed(decimalPlaces))); // eslint-disable-line
// prettier-ignore

exports.getDecimalPart = getDecimalPart;
const getDecimalPartAsString = (0, _fp.curry)((decSep, value) => value.toString().split(decSep)[1] || ''); // prettier-ignore

exports.getDecimalPartAsString = getDecimalPartAsString;

const trailingZeros = a => (a.toString().match(/\.?0+$/g) || '').toString().length; // prettier-ignore


exports.trailingZeros = trailingZeros;
const trailingDecimalZeros = (0, _fp.pipe)(getDecimalPartAsString, trailingZeros); // prettier-ignore

exports.trailingDecimalZeros = trailingDecimalZeros;
const adjustSiPrefix = (0, _fp.curry)((from, to, value) => (0, _fp.ifElse)(isExponential, safeAdjustExponent(siPrefixes[from] - siPrefixes[to]), (0, _fp.multiply)(_constants.base10 ** (siPrefixes[from] - siPrefixes[to])))(value)); // prettier-ignore

exports.adjustSiPrefix = adjustSiPrefix;

const generateTrailingZeroStr = a => (0, _fp.reduce)(a => a + '0', '', (0, _fp.repeat)(0, a)); // prettier-ignore


const addTrailingZeros = (0, _fp.curry)((decimalSeparatorSymbol, zeros, val) => (0, _fp.cond)([[(0, _fp.includes)(decimalSeparatorSymbol), a => a + generateTrailingZeroStr(zeros)], [a => zeros > 0, a => a + decimalSeparatorSymbol + generateTrailingZeroStr(zeros)], [_fp.T, _fp.identity]])(val));
exports.addTrailingZeros = addTrailingZeros;

const toExponential = a => a.toExponential();

exports.toExponential = toExponential;
const pow10 = pow(_fp.__, 10);
exports.pow10 = pow10;
const getExponentAsString = (0, _fp.pipe)((0, _fp.split)('e'), _fp.last, Number);
exports.getExponentAsString = getExponentAsString;
const isExponential = (0, _fp.pipe)(_fp.toString, (0, _fp.split)('e'), _fp.length, (0, _fp.gt)(_fp.__, 1));
exports.isExponential = isExponential;
const getBase = (0, _fp.pipe)(_fp.toString, (0, _fp.split)('e'), _fp.head, Number);
exports.getBase = getBase;
const getExponent = (0, _fp.pipe)(_fp.toString, (0, _fp.split)('e'), _fp.last, Number);
exports.getExponent = getExponent;
const safeAdjustExponent = (0, _fp.curry)((adj, exp) => Number(getBase(exp).toString() + 'e' + (getExponent(exp) + adj).toString()));
exports.safeAdjustExponent = safeAdjustExponent;

const sanitizeNumberStr = (curSym, val) => (0, _fp.pipe)((0, _fp.replace)(/%/g, ''), (0, _fp.replace)(/\$/g, ''), (0, _fp.replace)(new RegExp(curSym, 'g'), ''))(val);

exports.sanitizeNumberStr = sanitizeNumberStr;

const removeTrailingZeros = ({
  value,
  decimalPlaces,
  decimalSeparatorSymbol
}) => {
  const split = value.split(decimalSeparatorSymbol);
  if ((split === null || split === void 0 ? void 0 : split[1]) === (0, _fp.join)('', (0, _fp.repeat)(0, decimalPlaces))) return split[0];
  return value;
};

exports.removeTrailingZeros = removeTrailingZeros;