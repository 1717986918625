/* @flow */
/* eslint-disable no-confusing-arrow, no-nested-ternary  */

import React from 'react';
import styled from 'styled-components';

export const Style = styled.div`
  ${p => p.theme.headings.subHeading()};
`;

export type Props = {
  children?: React.Element<any>,
};

const SubHeading = ({ children }: Props): React.Element<any> => <Style>{children}</Style>;

export default SubHeading;
