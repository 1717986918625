/* @flow */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import useAvatar from './useAvatar';
import { Avatar, DefaultAvatar, LoaderRing } from '@sharkfinesse/sfl-ui';
import { hslGenerator, isNil } from '@sharkfinesse/sfl-lib';
import { useTheme } from 'styled-components';
import { LoadingWrapper } from './styled';

const Component = ({
  uid,
  email,
  size,
  name,
  loading,
  color = hslGenerator({ name: email }),
  hideBorder,
  ...props
}) => {
  const theme = useTheme();

  const { data, isLoading } = useAvatar({
    uid,
    email,
    size,
  });

  return (
    <div>
      {!isLoading && !isNil(data) ? (
        <Avatar
          src={data.src}
          name={name}
          email={email}
          size={size}
          color={color}
          hideBorder={hideBorder}
          {...props}
        />
      ) : (
        <DefaultAvatar
          hideBorder={hideBorder}
          name={isLoading ? false : name}
          size={size}
          color={isLoading ? theme.colors.uiLightest : color}
          {...props}
        />
      )}
      {loading && (
        <LoadingWrapper>
          <LoaderRing />
        </LoadingWrapper>
      )}
    </div>
  );
};

export default Component;
