/* @flow */
import React from 'react';
import styled from 'styled-components';
import getSize from '../../size';
import { Lottie } from '@crello/react-lottie';

export const IconWrapper = styled.div`
  .animatedIconPrimaryStroke {
    stroke: currentColor !important;
  }
  .animatedIconPrimaryFill {
    fill: currentColor !important;
  }
  .animatedIconSecondaryStroke {
    stroke: ${({ secondaryColor }) => secondaryColor} !important;
  }
  .animatedIconSecondaryFill {
    fill: ${({ secondaryColor }) => secondaryColor} !important;
  }

  display: flex;
  ${getSize} color: ${p => p.primaryColor || 'currentColor'} !important;
`;

export type IconProps = {
  children: React.Element<any> | string,
  size?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge',
  primaryColor?: string,
  secondaryColor?: string,
  onClick?: () => mixed,
  animationData: String,
  config: Object,
};

const Icon = ({
  children,
  size = 'medium',
  primaryColor,
  secondaryColor,
  onClick,
  animationData,
  config = {},
  wrapperProps,
  ...props
}: IconProps): React.Element<any> => {
  return (
    <IconWrapper
      onClick={onClick}
      size={size}
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
      {...wrapperProps}
    >
      <Lottie
        {...props}
        config={{ autoplay: false, loop: false, animationData, ...config }}
      ></Lottie>
    </IconWrapper>
  );
};

export default Icon;
