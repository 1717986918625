// @flow
/* eslint-disable react/prop-types */
import React from 'react';

import { Base } from './styled';

const Pill = ({ children, ...props }) => {
  return <Base>{children}</Base>;
};

export default Pill;
