import applyTheme from '../../styles/apply-theme';

const resultBox = applyTheme('resultBox');

export const Container = resultBox('container');
export const Label = resultBox('label');
export const Value = resultBox('value');
export const BgBox = resultBox('bgBox');
export const BgBoxCircle1 = resultBox('bgBoxCircle1');
export const BgBoxCircle2 = resultBox('bgBoxCircle2');
