/* @flow */
/* eslint-disable react/display-name */
import React, { Fragment } from 'react';
import type { Element } from 'react';
import styled from 'styled-components';
import RemoveFromOutput from '../remove-from-output';

const Wrapper = styled.div`
  ${p => p.theme.headlineFigure.wrapper(p.size)};
`;
const Value = styled.div`
  ${p => p.theme.headlineFigure.value(p.removed)};
`;
const Label = styled.div`
  ${p => p.theme.headlineFigure.label(p.removed)};
`;
const Links = styled.div`
  ${p => p.theme.headlineFigure.links(p.size, p.removed)};
`;

export const HeadlineFigureWrapper = styled.div`
  ${p => p.theme.headlineFigure.figuresWrapper()};
`;

type DefaultProps = {
  labelPosition: 'top' | 'bottom',
  removed: boolean | Element<any>,
};

export type Props = DefaultProps & {
  size: Object | String,
  value: String | Element<any>,
  label: String | Element<any>,
  children: String | Element<any>,
  links: Element<any>,
  labelPosition: 'top' | 'bottom',
  removed: Boolean | Element<any>,
  removedMessage: String | Element<any>,
  updateInBusinessCase: Function,
  inBusinessCase: Boolean,
};

const HeadlineFigure = ({
  size,
  value,
  label,
  labelPosition,
  links,
  children,
  removed,
  removedMessage,
  updateInBusinessCase,
  inBusinessCase,
}: Props): Element<any> => {
  const renderContent = () => (
    <Fragment>
      {label && labelPosition === 'top' && <Label>{label}</Label>}
      {value && <Value>{value}</Value>}
      {label && labelPosition === 'bottom' && <Label>{label}</Label>}
      {links && <Links size={size}>{links}</Links>}
      {children}
    </Fragment>
  );

  return (
    <Wrapper size={size}>
      {removedMessage && (
        <RemoveFromOutput
          updateInBusinessCase={updateInBusinessCase}
          inBusinessCase={inBusinessCase}
          message={removedMessage}
          showUndo
          showRemove
        >
          {renderContent()}
        </RemoveFromOutput>
      )}

      {!removedMessage && renderContent()}
    </Wrapper>
  );
};

HeadlineFigure.defaultProps = {
  labelPosition: 'bottom',
  removed: false,
};

export default HeadlineFigure;
