import { all, call, put, select, takeLatest, take } from 'redux-saga/effects';
import { types as defaultSessionValuesTypes } from 'redux/modules/default-session-values';
import { actionCreators as defaultSessionValuesActionCreators } from 'redux/modules/default-session-values';
import { types as evaluatorValuesTypes } from 'redux/modules/evaluator';
import { map, defaultSessionValuesSelectors } from '@sharkfinesse/sfl-lib';
import calculateEvaluator from './evaluator/calculateEvaluator';

function* updateValues({
  payload: { defaultSessionValueId, value, actionType = 'defaultSessionValue' },
  meta,
}) {
  const linkedBenefitIds = yield select(
    defaultSessionValuesSelectors.getDefaultSessionValuesLinked
  );

  yield all(
    map(
      id =>
        all([
          call(calculateEvaluator, {
            value,
            defaultSessionValueId,
            id,
            meta,
            actionType,
          }),
          take(defaultSessionValuesTypes.CALCULATE),
        ]),
      linkedBenefitIds[defaultSessionValueId]
    )
  );

  yield put(defaultSessionValuesActionCreators.updateComplete());
}

function* updateDelete(action) {
  const linked = yield select(defaultSessionValuesSelectors.getDefaultSessionValuesLinked);
  yield put(defaultSessionValuesActionCreators.updateDelete(action.payload.id, linked));
}

function* showDefaultSessionValuesInfo(action) {
  yield put(defaultSessionValuesActionCreators.updateShowInfo(true));
}

export const effects = [
  takeLatest(defaultSessionValuesTypes.PRISTINE_CHANGE, showDefaultSessionValuesInfo),
  takeLatest(defaultSessionValuesTypes.CHANGE, updateValues),
  takeLatest(evaluatorValuesTypes.DELETE, updateDelete),
];

export default effects;
