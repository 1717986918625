import { lightenHsla, darkenHsla, isNil } from '@sharkfinesse/sfl-lib';
import { css } from 'styled-components';
import styledBreakpoint from 'styled-components-breakpoint';

export const Panel = ({ font, colors, scale }) => ({
  base: ({ border, margin, maxWidth, width }) => css`
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    font-size: ${font.sizeNormal};
    color: ${font.color};
    background: ${colors.white};
    ${!isNil(border)
      ? css`
          border-radius: 3px;
          border: 1px solid;
          border-color: ${lightenHsla(8, colors.uiLight)} ${colors.uiLight}
            ${darkenHsla(8, colors.uiLight)} ${colors.uiLight};
        `
      : css`
          border: none;
        `};
    ${!isNil(margin)
      ? css`
          margin: margin;
        `
      : css`
          ${styledBreakpoint('xxs')`
      margin: 0 0 ${scale.minusFour} 0;
    `};
          ${styledBreakpoint('xs')`
      margin: 0 0 ${scale.minusThree} 0;
    `};
          ${styledBreakpoint('md')`
      margin: 0 0 ${scale.two} 0;
    `};
        `};
    max-width: ${!isNil(maxWidth) ? maxWidth : '1300px'};
    overflow-x: hidden;
    ${!isNil(width)
      ? css`
          width: ${width};
        `
      : css`
          width: 100%;
        `}
  `,
  header: ({ collapsible, isOpen }) => css`
    position: relative;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    width: 100%;
    ${collapsible &&
    !isOpen &&
    css`
      transition: background 0.3s;

      &:hover {
        background: ${lightenHsla(33, colors.primary)};
      }
      cursor: pointer;
    `}
  `,
  panelHeaderContainer: ({ headerIcon }) => css`
    flex-wrap: nowrap;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch;

    padding: ${scale.zero} ${scale.zero} ${scale.zero} ${headerIcon ? '4px' : scale.zero};
  `,
  headerContainer: () => css`
    display: flex;
    align-items: center;
    flex: 0 1 auto;
    align-self: auto;
    width: 100%;
  `,
  toolbarContainer: () => css`
    flex: 1 1 auto;
    align-self: flex-start;
    justify-content: flex-end;
    display: flex;
    height: 30px;
    align-items: center;
  `,
  iconWrapper: () => css`
    align-items: center;
    margin-left: 8px;
    display: flex;
  `,
  panelBody: ({ hasHeading }) => css`
    padding: ${!isNil(hasHeading) ? 0 : scale.zero} ${scale.zero} ${scale.zero} ${scale.zero};
  `,
  iconSecondaryColor: colors.white,
  headerImageContainer: () => css`
    position: relative;
    width: 100%;
    height: 80px;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(to bottom, transparent 0%, hsl(0deg 0% 0% / 60%) 90%);
    }
  `,
  headerImage: () => css`
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    pointer-events: none;
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
  `,
});

export default Panel;
