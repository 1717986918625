import { margin, backgrounds } from 'polished';

const DividerNav = ({ font, colors, scale }) => ({
  thickness: '1px',
  background: backgrounds(colors.uiLightest),
  backgroundDark: backgrounds('#4c4c4c'),
  margin: margin(scale.minusOne, 0),
});

export default DividerNav;
