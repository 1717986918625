/* eslint-disable prettier/prettier */
import { darkenHsla } from '@sharkfinesse/sfl-lib';

const L = 50;
const Logo = ({ font, colors, scale }) => ({
  topBlockColor: colors.primary,
  bottomBlockColor: darkenHsla(L, colors.primary),
});

export default Logo;
