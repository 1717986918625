import { lightenHsla, darkenHsla, setAlphaHsla } from '@sharkfinesse/sfl-lib';
import { borderStyle, borderColor, borderWidth, backgrounds } from 'polished';
import { css } from 'styled-components';

const alpha = 0.7;
const bravo = 10;
const charlie = 20;
const ButtonDefault = ({ font, colors }) => ({
  background: backgrounds(colors.uiLight),
  fontWeight: font.weightSemiBold,
  fontColor: colors.uiDarkest,
  textTransform: 'none',
  letterSpacing: '1px',
  borderStyle: borderStyle('none'),
  borderWidth: borderWidth('1px'),
  borderColor: borderColor(darkenHsla(charlie, colors.uiLight)),
  textShadow: setAlphaHsla(alpha, lightenHsla(charlie, colors.uiLight)),
  boxShadow: 'unset',
  hoverBackground: backgrounds(lightenHsla(bravo, colors.uiLight)),
  hoverBorderStyle: borderStyle('none'),
  hoverBorderWidth: borderWidth('1px'),
  hoverBorderColor: borderColor(darkenHsla(charlie, colors.uiLight)),
  activeBoxShadow: 'unset',
  focusBorderWidth: '2px',
  focusBorderColor: colors.primary,
  hoverFontColor: colors.uiDarkest,
  activeCSS: () => css`
    background: ${darkenHsla(5, colors.uiLight)};
  `,
});

export default ButtonDefault;
