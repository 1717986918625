// @flow
import applyTheme from '../../styles/apply-theme';
import styled from 'styled-components';

const theme = applyTheme('form');

export const Base = theme('base', styled.input);
export const Container = theme('container');
export const Wrapper = theme('wrapper');
export const InputColumn = theme('inputColumn');
export const InputWrapper = theme('inputWrapper');
