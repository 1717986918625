import { takeLatest } from 'redux-saga/effects';
import { types as sessionTypes } from 'redux/modules/session';
import loadSession from './load';
import initSession from './initialise';
import openSession from './open';
import compareSessions from '../compare/compare';
import leave from './leave';
import reCalculate from './re-calculate';
import importSession from './import';

export const effects = [
  takeLatest(sessionTypes.LOAD, loadSession),
  takeLatest(sessionTypes.LEAVE, leave),
  takeLatest(sessionTypes.CREATE, initSession),
  takeLatest(sessionTypes.OPEN, openSession),
  takeLatest(sessionTypes.COMPARE, compareSessions),
  takeLatest(sessionTypes.RECALCULATE, reCalculate),
  takeLatest(sessionTypes.IMPORT, importSession),
];
export default effects;
