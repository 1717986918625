import { getSessions, getSession, getFolderSessions, getEditorSessions } from 'redux/firestore';
import { useQuery } from 'react-query';
import { getCollectionData, reject, isNil } from '@sharkfinesse/sfl-lib';

const getOfflineCollectionData = async collection => {
  const sessions = Promise.all(
    collection.docs.map(async doc => {
      if (doc.exists === true) {
        const data = doc.data();
        const session = await getSession({ id: doc.id, account: data.account, source: 'cache' });
        if (session.empty) return null;
        return { id: doc.id, ...data };
      } else {
        return null;
      }
    })
  );
  return sessions;
};
const getItems = async props => {
  const response = props.editor
    ? await getEditorSessions(props)
    : props.folderId
    ? await getFolderSessions(props)
    : await getSessions(props);

  if (props.isOnline) {
    return getCollectionData(response);
  } else {
    const offlineSessions = await getOfflineCollectionData(response);
    return reject(isNil, offlineSessions);
  }
};

const useSessions = (props, config) =>
  useQuery(
    ['sessions', { folder: props.folderId, editor: props?.editor }],
    () => getItems(props),
    config
  );

export default useSessions;
