/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

const IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M19.1,18.5c-0.3-1-1.2-1.7-2.3-1.7h-1.2v-3.6c0-0.7-0.5-1.2-1.2-1.2H7.2V9.6h2.4c0.7,0,1.2-0.5,1.2-1.2V6h2.4
	c1.3,0,2.4-1.1,2.4-2.4V3.1c3.5,1.4,6,4.9,6,8.9C21.6,14.5,20.6,16.8,19.1,18.5 M10.8,21.5c-4.7-0.6-8.4-4.6-8.4-9.5
	c0-0.7,0.1-1.5,0.3-2.1l5.7,5.7v1.2c0,1.3,1.1,2.4,2.4,2.4 M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z"
    />
  </Icon>
);

export default memo(IconComponent);
