// @flow
/* eslint-disable no-magic-numbers, react/prop-types */
import React, { useContext } from 'react';
import { SlideBox, SlideFooter, Loading } from './styled';
import SlideBody from './slide-body';
import LoaderRing from '../loaders/ring';
import { SlideContext } from './slide';

const SlideCard = ({
  imageUrl,
  iconUrl,
  icon,
  slideCardRef,
  slideBodyProps,
  onCheckChange,
  onCheckClick,
  checked,
  tag,
  checkBoxVisibility,
  loading,
  disabled,
  overlay,
  blur,
  ...other
}) => {
  const { footer, hideFooterBorder } = useContext(SlideContext);
  return (
    <SlideBox ref={slideCardRef} disabled={disabled} checked={checked} {...other}>
      {!loading && (
        <>
          <SlideBody
            imageUrl={imageUrl}
            iconUrl={iconUrl}
            icon={icon}
            onCheckChange={onCheckChange}
            onCheckClick={onCheckClick}
            checked={checked}
            tag={tag}
            checkBoxVisibility={checkBoxVisibility}
            {...slideBodyProps}
          />
          <div style={{ display: 'flex' }}>
            <SlideFooter $hideFooterBorder={hideFooterBorder}>{footer}</SlideFooter>
          </div>
        </>
      )}
      {overlay}
      {loading && (
        <Loading>
          <LoaderRing />
        </Loading>
      )}
    </SlideBox>
  );
};

export default SlideCard;
