"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reselect = require("reselect");

const getSessions = state => state.sessions;

const getAll = state => state.sessions.all || [];

const getFetching = state => state.sessions.fetching;

const getSorting = state => state.sessions.sorting;

const getFilter = state => state.sessions.filter;

const getFiltered = (0, _reselect.createSelector)([getFilter, getAll], (filter, sessions) => {
  return !filter ? sessions : sessions.filter(m => {
    let dealNameLowerCase = null;

    if (m.dealName) {
      dealNameLowerCase = m.dealName.toLowerCase();
    } else {
      return false;
    }

    const filterLowerCase = filter.toLowerCase();
    return dealNameLowerCase.includes(filterLowerCase);
  });
});
const getAllSorted = (0, _reselect.createSelector)([getSorting, getFiltered], (sorting, sessions) => {
  let sortDesc = (a, b) => a.updated.seconds - b.updated.seconds;

  let sortAsc = (a, b) => b.updated.seconds - a.updated.seconds;

  if (sorting.field === 'dealName') {
    sortAsc = (a, b) => {
      const dealNameA = a.dealName ? a.dealName.toLowerCase() : '';
      const dealNameB = b.dealName ? b.dealName.toLowerCase() : '';
      return dealNameA < dealNameB ? -1 : dealNameA > dealNameB ? 1 : 0;
    };

    sortDesc = (a, b) => {
      const dealNameA = a.dealName ? a.dealName.toLowerCase() : '';
      const dealNameB = b.dealName ? b.dealName.toLowerCase() : '';
      return dealNameA < dealNameB ? 1 : dealNameA > dealNameB ? -1 : 0;
    };
  }

  return sessions.sort(sorting.direction === 'asc' ? sortAsc : sortDesc);
});
const selectors = {
  getSessions,
  getAll,
  getFetching,
  getSorting,
  getAllSorted,
  getFilter,
  getFiltered
};
var _default = selectors;
exports.default = _default;