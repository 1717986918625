import { css } from 'styled-components';
import { setAlphaHsla } from '@sharkfinesse/sfl-lib';

import styledBreakpoint from 'styled-components-breakpoint';

const Carousel = ({ font, colors, scale }) => ({
  carouselStyleWrapper: ({ slideSpacing }) => css`
    .carouselTray {
      display: inline-block;
      //padding-right: 68px;
    }
    .slidePadding {
      padding-top: 1px;
      padding-right: ${slideSpacing};
    }
  `,
  sliderContainer: () => css`
    position: relative;
    .sliderBtn {
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 51px;
      width: 51px;
      border-radius: 25px;
      //background: ${setAlphaHsla(0.85, colors.white)};
      background: ${colors.white};
      position: absolute;
      border: 1px solid ${colors.uiLight};
      top: 50%;
      transform: translateY(-28px);
      transition: all 0.3s ease-in-out;
      ${styledBreakpoint('xxs')`
      height: 40px;
      width: 40px;
      &.right {
        right: -4px;
      }
      &.left {
        left: -4px;
      }
      `}
      ${styledBreakpoint('xs')`
      height: 40px;
      width: 40px;
      `}
      ${styledBreakpoint('sm')`
      height: 40px;
      width: 40px;
      `}
      ${styledBreakpoint('md')`
      height: 46px;
      width: 46px;
      &.right {
        right: -6px;
      }
      &.left {
        left: -6px;
      }
      `}
      ${styledBreakpoint('lg')`
      height: 51px;
      width: 51px;
      &.right {
        right: -12px;
      }
      &.left {
        left: -12px;
      }
      `}
      &:hover {
        border-color: ${colors.primary};
        color: ${colors.primary};
      }
      &:disabled {
        opacity: 0;
      }
    }
  `,
});

export default Carousel;
