/* @flow */
import React, { useState } from 'react';
import type { Element } from 'react';
import {
  Container,
  Label,
  Body,
  Wrapper,
  ExpandButton,
  ChevronIcon,
  LeftContent,
  RightContent,
} from './styled';
import useMeasure from '../utils/useMeasure';
import { useSpring } from 'react-spring';
import { IconChevronRight } from '../icon';
import Button from '../button';
import { FormattedMessage } from 'react-intl';
import * as easings from 'd3-ease';
import Checkbox from '../checkbox/';

type Props = {
  label?: Element<*> | String,
  ...other,
};

const ExpandingListItem = ({
  label,
  children,
  selected,
  indeterminate,
  small,
  rightContent,
  onClick,
  ...other
}: Props) => {
  const [isOpen, setOpen] = useState(false);
  const [showChildren, setShowChildren] = useState(false);
  const [bind, { height: viewHeight }] = useMeasure();

  const rotateProps = useSpring({
    transform: `rotate(${isOpen ? 90 : 0}deg)`,
    config: { duration: 350, easing: easings.easeCubic },
  });

  const heightProps = useSpring({
    height: isOpen ? viewHeight : 0,
    onRest: () => {
      isOpen ? setShowChildren(true) : setShowChildren(false);
    },
  });

  const updateOpen = () => {
    setOpen(!isOpen);
    if (isOpen) {
      setShowChildren(true);
    }
  };

  return (
    <Container isOpen={isOpen} selected={selected || indeterminate} small={small}>
      <Wrapper
        small={small}
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
      >
        <LeftContent>
          {children && (
            <ExpandButton style={rotateProps}>
              <Button
                size="icon"
                variant="flat"
                color="light"
                tooltip={
                  isOpen ? (
                    <FormattedMessage id="panel.collapse.tooltip" defaultMessage="Collapse" />
                  ) : (
                    <FormattedMessage id="panel.expand.tooltip" defaultMessage="Expand" />
                  )
                }
                tooltipConfig={{
                  usePortal: true,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  updateOpen();
                }}
              >
                <ChevronIcon>
                  <IconChevronRight size="width: 28px; height: 28px;" />
                </ChevronIcon>
              </Button>
            </ExpandButton>
          )}
          <Checkbox
            checked={selected}
            round
            indeterminate={indeterminate}
            onChange={(e) => {
              onClick();
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
          <Label small={small}>{label}</Label>
        </LeftContent>
        {rightContent && (
          <RightContent
            onClick={(e) => {
              e.stopPropagation();
              updateOpen();
            }}
          >
            {rightContent}
          </RightContent>
        )}
      </Wrapper>
      {children && (
        <Body style={heightProps}>
          <div {...bind}>{(showChildren || isOpen) && children}</div>
        </Body>
      )}
    </Container>
  );
};

export default ExpandingListItem;
