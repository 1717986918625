/* @flow */
/* eslint-disable max-len */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19,4H5C3.9,4,3,4.9,3,6v12c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V6C21,4.9,20.1,4,19,4z M19,18H5V6h14V18z"
    />
    <polygon
      fill="currentColor"
      fillRule="evenodd"
      points="7.8,14.8 10.6,12 7.8,9.2 9.2,7.8 12,10.6 14.8,7.8 16.2,9.2 13.4,12 16.2,14.8 14.8,16.2 12,13.4 9.2,16.2 "
    />
  </Icon>
);

export default memo(IconComponent);
