// @flow
import styled from 'styled-components';
import applyTheme from '../../styles/apply-theme';
import { NavLink } from 'react-router-dom';

const mainMenu = applyTheme('mainMenu');
export const Aside = mainMenu('aside', styled.aside);
export const MenuContainer = mainMenu('menuContainer');
export const MenuItemWrapper = mainMenu('menuItemWrapper', styled(NavLink));
export const MenuLabel = mainMenu('menuLabel');
export const Heading = mainMenu('heading');
export const Icon = mainMenu('icon');
