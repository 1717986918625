/* @flow */
import React, { memo } from 'react';
import styled, { css } from 'styled-components';

type DefaultProps = {
  themeKey?: string,
  orientation?: string,
};

export type Props = DefaultProps & {
  themeKey?: string,
  orientation?: 'horizontal' | 'vertical',
};

const StyledDivider = styled.div`
  ${p => css`
    ${
      p.orientation === 'vertical'
        ? css`
            width: ${p.theme[p.themeKey].thickness};
          `
        : css`
            height: ${p.theme[p.themeKey].thickness};
          `
    }
    
        ${p.theme[p.themeKey].background}
        ${p.theme[p.themeKey].margin}
  `};
  ${({ color, theme, themeKey }) => {
    if (color === 'dark') return theme[themeKey].backgroundDark;
  }};
  display: flex;
  align-self: stretch;
`;

let Divider = ({ themeKey, orientation, ...other }: Props): React.Element<any> => (
  <StyledDivider themeKey={themeKey} orientation={orientation} {...other} />
);

Divider.defaultProps = {
  themeKey: 'dividerNav',
  orientation: 'vertical',
};

export default memo(Divider);
