// @flow
import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import { IconChevronLeft } from '../icon';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
  align-self: stretch;
  flex-direction: row;
  display: flex;
  z-index: 1;
`;
const StyledButton = styled.div`
  align-self: stretch;
  flex-direction: row;
  display: flex;
  cursor: pointer;
`;
const GoUpContainer = styled.div`
  ${prop('background')};
  ${prop('margins')};
  width: ${prop('width')};
  min-height: ${prop('height')};
  height: 100%;
  display: flex;
`;
const IconWrapper = styled.div`
  display: flex;
  align-self: center;
  text-align: center;
  margin: 0 auto;
`;

type DefaultProps = {
  background: string,
  iconSize: string,
  iconColor: string,
  secondaryIconColor: string,
  width: string,
  height: string,
  margins: string | Object,
  icon: Element<*> | string,
};

export type Props = DefaultProps & {
  theme: Object,
  url?: string,
  background?: string,
  iconSize?: string,
  iconColor?: string,
  secondaryIconColor?: string,
  width: string,
  height: string,
  margins: string | Object,
  icon?: Element<*> | string,
  onClick: Function,
};

const GoUp = ({
  url,
  background,
  iconSize,
  iconColor,
  secondaryIconColor,
  width,
  height,
  margins,
  onClick,
  icon: Icon,
}: Props): React.Element<any> => {
  const getButton = () => (
    <GoUpContainer background={background} width={width} height={height} margins={margins}>
      <IconWrapper>
        <Icon size={iconSize} primaryColor={iconColor} secondaryColor={secondaryIconColor} />
      </IconWrapper>
    </GoUpContainer>
  );
  return onClick ? (
    <StyledButton onClick={onClick}>{getButton()}</StyledButton>
  ) : (
    <StyledLink to={url}>{getButton()}</StyledLink>
  );
};

GoUp.defaultProps = {
  icon: IconChevronLeft,
  url: '/',
  iconColor: 'black',
  secondaryIconColor: 'blue',
  iconSize: 'large',
  background: 'background: white',
  width: '66px',
  height: '50px',
  margins: 'margin: 0',
};

export default GoUp;
