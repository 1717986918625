import styled from 'styled-components';
import Checkbox from '../checkbox';

export const StyledCheckbox = styled(Checkbox)`
  margin-top: -3px;
  margin-right: 10px;
`;

export const ValueWrapper = styled.div`
  .dummy-input-wrapper {
    line-height: 0;
    & > :not(input) {
      display: none;
    }
  }
`;
