/* @flow */
/* eslint-disable max-len */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M11.5,2.5c5.5,0,10,3.6,10,8c0,0.6-0.1,1.1-0.2,1.7c-0.6-0.3-1.2-0.5-1.9-0.6
	c0.1-0.3,0.1-0.7,0.1-1.1c0-3.3-3.6-6-8-6s-8,2.7-8,6s3.6,6,8,6l1.1,0l-0.1,1l0.1,1l-1.1,0c-1.2,0-2.4-0.2-3.5-0.5
	c-1.8,1.5-4.1,2.4-6.5,2.5c2.3-2.3,2.8-3.9,2.8-4.5c-1.7-1.3-2.7-3.3-2.8-5.5C1.5,6.1,6,2.5,11.5,2.5"
    />
    <path fill="inherit" d="M17.5,13.5h2v3h3v2h-3v3h-2v-3h-3v-2h3V13.5z" />
  </Icon>
);

export default memo(IconComponent);
