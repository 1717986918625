/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

let IconProfiler = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M21,3H3C2.3,3,2,3.8,2,4.4V20c0,0.7,0.3,1,1,1h18c0.7,0,1-0.3,1-1V4C22,3.4,21.7,3,21,3z M7,20H3v-2
	h4V20z M7,17H3v-2h4V17z M7,14H3v-2h4V14z M7,11H3V9h4V11z M7,8H3V6h4V8z M12,20H8v-2h4V20z M12,17H8v-2h4V17z M12,14H8v-2h4V14z
	 M12,11H8V9h4V11z M12,8H8V6h4V8z M17,20h-4v-2h4V20z M17,17h-4v-2h4V17z M17,14h-4v-2h4V14z M17,11h-4V9h4V11z M17,8h-4V6h4V8z
	 M21,20h-3v-2h3V20z M21,17h-3v-2h3V17z M21,14h-3v-2h3V14z M21,11h-3V9h3V11z M21,8h-3V6h3V8z"
    />
    <rect x="3" y="6" fill="inherit" width="4" height="2" />
    <rect x="8" y="6" fill="inherit" width="4" height="2" />
    <rect x="18" y="6" fill="inherit" width="3" height="2" />
    <rect x="13" y="6" fill="inherit" width="4" height="2" />
    <rect x="3" y="9" fill="inherit" width="4" height="2" />
    <rect x="8" y="9" fill="inherit" width="4" height="2" />
    <rect x="13" y="9" fill="inherit" width="4" height="2" />
    <rect x="3" y="12" fill="inherit" width="4" height="2" />
    <rect x="8" y="12" fill="inherit" width="4" height="2" />
    <rect x="3" y="15" fill="inherit" width="4" height="2" />
  </Icon>
);

export default memo(IconProfiler);
