import rsfApp from 'redux/rsf';
import { call } from 'redux-saga/effects';

export const getSessionRef = ({ account, id }) =>
  rsfApp.firestore.collection('accounts').doc(account).collection('sessions').doc(id);

export const getNewSessionRef = ({ account, id }) =>
  rsfApp.firestore.collection('accounts').doc(account).collection('sessions').doc();

export const getFileRef = ({ sessionRef }) => sessionRef.collection('file');

export const sessionDoc_Update = (fileRef, batch, session, doc) => {
  return call([batch, batch.update], fileRef.doc(doc), {
    ...session[doc],
  });
};

export const sessionDoc_FieldsUpdate = (fileRef, batch, doc, fields) => {
  return call([batch, batch.update], fileRef.doc(doc), {
    ...fields,
  });
};
export const sessionDoc_FieldUpdate = (fileRef, batch, doc, field, payload) => {
  return call([batch, batch.update], fileRef.doc(doc), {
    [field]: payload,
  });
};
export const sessionDoc_Set = (doc, state, fileRef, batch) => {
  return call([batch, batch.set], fileRef.doc(doc), state);
};

export function* sessionDoc_get(doc, fileRef, defaultState = {}) {
  try {
    const sessionDoc = yield call(rsfApp.rsf.firestore.getDocument, fileRef.doc(doc));
    if (sessionDoc.exists) {
      return sessionDoc.data();
    } else {
      return defaultState;
    }
  } catch (error) {
    return defaultState;
  }
}
