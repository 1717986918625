/* @flow */
import React from 'react';
import { StyledTrack } from './styled';

type Props = {
  source: Object,
  target: Object,
  disabled: Boolean,
  getTrackProps: Function,
};

const Track = ({ source, target, getTrackProps, disabled = false }: Props) => (
  <StyledTrack target={target} source={source} disabled={disabled} {...getTrackProps()} />
);

export default Track;
