/* @flow */
import {
  simpleAction,
  createReducer,
  preferencesDocxTemplateInitialState,
} from '@sharkfinesse/sfl-lib';
const namespace = (name: string): string => `@@preferences.docxTemplate/${name}`;

//Actions
export const types = {
  UPDATE: namespace('UPDATE'),
};

//Reducer
export const initialState = preferencesDocxTemplateInitialState;

const reduceDocxTemplate = (state, action) => ({
  ...state,
  ...action.payload,
});

export default createReducer(
  {
    [types.UPDATE]: reduceDocxTemplate,
  },
  initialState
);

//Action Creators
const updateDocxTemplate = simpleAction(types.UPDATE);

export const actionCreators = {
  updateDocxTemplate,
};
