// @flow weak
/* eslint-disable no-magic-numbers */
import React from 'react';
import OutputBase from '../output-base';
import FormLabel from '../form-label';
import { TextFieldContainer, Column, Row } from './styled';
const OutputField = ({
  id,
  label,
  layout,
  labelAlign,
  outputAlign = 'center',
  outputProps,
  containerProps,
  labelProps,
  ...other
}) => {
  return (
    <TextFieldContainer as={layout === 'column' ? Column : Row} {...containerProps}>
      {label && (
        <FormLabel align={labelAlign} bold {...labelProps}>
          {label}
        </FormLabel>
      )}
      <OutputBase align={outputAlign} {...outputProps} {...other} />
    </TextFieldContainer>
  );
};

export default OutputField;
