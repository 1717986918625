/* eslint-disable no-magic-numbers */
import {
  Colors,
  Font,
  Scale,
  ButtonPrimary,
  ButtonSmall,
  ButtonNormal,
  ButtonLarge,
  ButtonDefault,
  Images,
  Panel,
  Modal,
  Sidebar,
  breakpoints,
  Device,
  Pill,
} from './core';
import BaseTheme from './base';
import { hsla, lightenHsla, darkenHsla, merge } from '@sharkfinesse/sfl-lib';
import { borderWidth, backgrounds } from 'polished';
import { css } from 'styled-components';

const baseAlpha = 1;

//colors
const grays = hsla(0, 0, 40, baseAlpha);
const uiBase = grays;

//*******************************
// config
//******************************
const colors = Colors();
colors.primaryHex = '#7EB843';
colors.primary = hsla(90, 47, 49, baseAlpha);
colors.secondary = hsla(29, 92, 47, baseAlpha);

colors.uiDarkest = darkenHsla(8, uiBase);
colors.uiDark = darkenHsla(2, uiBase);
colors.ui = uiBase;
colors.uiLight = lightenHsla(30, uiBase);
colors.uiLightest = lightenHsla(50, uiBase);
colors.popperBox = hsla(0, 0, 100, baseAlpha);
colors.gradientsCls = css`
  ${colors.gradientsCls}
  .gradient1 {
    background: #56c6a5;
  }
  .gradient2 {
    background: #ac84b3;
  }
  .gradient4 {
    background: #ac84b3;
  }
  .gradient12 {
    background: #7eb843;
  }
  .gradient8 {
    background: #7eb843;
  }
  .gradient9 {
    background: #56c6a5;
  }
  .gradient11 {
    background: #f08eaf;
  }
  .gradient13 {
    background: #f08eaf;
  }
  .gradient14 {
    background: #7eb843;
  }
  .gradient15 {
    background: #ac84b3;
  }
`;

const scale = Scale();

const font = Font();
font.size = '16px';
font.color = hsla(203, 7, 52, baseAlpha);
font.sizeSmall = scale.minusOne;
font.sizeNormal = scale.zero;
font.sizeLarge = scale.one;

const config = {
  colors,
  scale,
  font,
  breakpoints: breakpoints,
  device: Device,
};

//*******************************
// overrides
//******************************

const chartTheme = 'aqua';

const buttonSmall = ButtonSmall(config);
buttonSmall.borderRadius = '2em';

const buttonNormal = ButtonNormal(config);
buttonNormal.borderRadius = '2em';

const buttonLarge = ButtonLarge(config);
buttonLarge.borderRadius = '3em';

const buttonDefault = ButtonDefault(config);
buttonDefault.background = backgrounds(colors.secondary);
buttonDefault.fontColor = colors.white;
buttonDefault.borderWidth = borderWidth('0px');
buttonDefault.textShadow = 'none';
buttonDefault.boxShadow = 'none';
buttonDefault.hoverBackground = backgrounds('#7C878E');
buttonDefault.hoverBorderWidth = borderWidth('0px');
buttonDefault.activeBoxShadow = 'none';
buttonDefault.focusBorderWidth = '0';

const buttonPrimary = ButtonPrimary(config);
buttonPrimary.borderWidth = borderWidth('0px');
buttonPrimary.textShadow = 'none';
buttonPrimary.boxShadow = 'none';
buttonPrimary.fontColor = colors.black;
buttonPrimary.hoverBackground = backgrounds('##5c8229');
buttonPrimary.hoverBorderWidth = borderWidth('0px');
buttonPrimary.activeBoxShadow = 'none';
buttonPrimary.focusBorderWidth = '0';

const panelBase = Panel(config);
const panel = merge(panelBase, {
  base: () => css`
    ${panelBase.base}
    border-radius: 0;
    border-width: 0;
  `,
  header: () => css`
    ${panelBase.header}
    border-width: 0;
  `,
  panelBody: () => css`
    ${panelBase.panelBody}
    padding: ${scale.zero};
  `,
});

const images = Images(config);
images.benefits = {
  leftBubble: {
    background: '#a2ba74',
    highlight: '#bbd884',
    lines: '#485b28',
    border: '#485b28',
  },
  rightBubble: {
    background: '#5c942e',
    highlight: '#78aa4f',
    lines: '#d3d3d3',
    border: '#485b28',
  },
};
images.costs = {
  bag: {
    background: '#ffb236',
    highlight: '#ffc567',
    cord: '#dc6914',
    label: {
      background: '#FFFFFF',
      highlight: '#EFEFEF',
    },
  },
  money: {
    stack: '#a2ba74',
    note: {
      outer: '#a2ba74',
      inner: '#bbd884',
      middleCircle: {
        background: '#eceeea',
        highlight: '#dbdeda',
      },
      leftCircle: '#eceeea',
      rightCircle: '#eceeea',
    },
  },
  coin: {
    stack: {
      background: '#ed8b43',
      highlight: '#dc6914',
    },
    background: '#ed8b43',
  },
  border: '#01404a',
};
images.reports = {
  border: '#485b28',
  background: '#F9F9F9',
  highlight: '#EFEFEF',
  squareOne: '#78aa4f',
  squareTwo: '#5c942e',
  lines: '#C1C1c1',
  corner: '#C1C1c1',
};
images.spreadsheets = {
  border: '#485b28',
  background: '#F7F9FC',
  highlight: '#EAEDF4',
  titleBar: '#5c942e',
  titleBarButtons: '#78aa4f',
  labelCells: '#78aa4f',
  mainCells: '#dddddd',
  headerCells: '#7f7f7f',
  toolbarBackground: '#d7deed',
  headingBarBackground: '#c7cfe2',
  toolBarButton1: '#c7cfe2',
  toolBarButton2: '#ed8b43',
  toolBarButton3: '#b4e1fa',
  toolBarButton4: '#eff2fa',
};
images.presentations = {
  border: '#485b28',
  background: '#bbd884',
  highlight: '#a2ba74',
  topBottomBarBackground: '#a3adaf',
  topBottomBarHighlight: '#949b9b',
  labels: '#ffffff',
  pieSegment1: '#e6740a',
  pieSegment2: '#e7edec',
  pole: '#ededed',
};

images.interactive = {
  border: '#485b28',
  circles: '#ed8b43',
  circlesHighlight: '#dc6914',
  bubble: '#bbd884',
  bubbleText: '#485b28',
  bubbleHighlight: '#a2ba74',
  hand: '#ffece4',
  handHighlight: '#e8d0c8',
};

const modalBase = Modal(config);
const modal = merge(modalBase, {
  header: () => css`
    ${modalBase.header}
    border-radius: 0;
  `,
  body: ({ showFooterKeyline, showHeaderKeyline, showFooter }) => css`
    ${modalBase.body({ showFooterKeyline, showHeaderKeyline, showFooter })}
    ${!showFooter &&
    css`
      border-radius: 0;
    `}
  `,
  footer: () => css`
    ${modalBase.footer}
    border-radius: 0;
  `,
});

const sidebarBase = Sidebar({
  ...config,
  drawerColor: '#454545',
  navbarColor: '#1a1a1a',
  toggleColor: '#696969 ',
});

const sidebar = merge(sidebarBase, {});

const pillBase = Pill(config);
const pill = merge(pillBase, {
  base: () => css`
    ${pillBase.base()}
    background: #ac84b3;
  `,
});

const overrides = {
  chartTheme,
  buttonSmall,
  buttonNormal,
  buttonLarge,
  buttonDefault,
  buttonPrimary,
  panel,
  images,
  modal,
  sidebar,
  pill,
};

let theme = BaseTheme('Lime', config, overrides);

export default theme;
