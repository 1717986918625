import { useMutation } from 'react-query';
import rsfApp from 'redux/rsf';

const updateArchived = async ({ checked, account, archived = true, immediate }) => {
  const batch = rsfApp.firestore.batch();
  const sessionsCollectionRef = rsfApp.firestore
    .collection('accounts')
    .doc(account)
    .collection('sessions');
  checked.forEach(sessionId => {
    var sessionRef = sessionsCollectionRef.doc(sessionId);
    batch.update(sessionRef, { archived });
  });

  if (immediate) {
    batch.commit();
  } else {
    await batch.commit();
  }

  return;
};

const useTrashSessions = ({ checked, account, archived, immediate }, config) => {
  return useMutation(updateArchived, config);
};

export default useTrashSessions;
