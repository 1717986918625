/* @flow */
import React from 'react';
import { ElementHighlight } from '@sharkfinesse/sfl-ui';

const UserPosition = ({ id, isOnline, activeIds, children, ...props }) =>
  isOnline ? (
    <ElementHighlight users={activeIds[id]} {...props}>
      {children}
    </ElementHighlight>
  ) : (
    children
  );

export default UserPosition;
