// @flow weak
/* eslint-disable no-magic-numbers */
import React from 'react';
import useFormControl from '../form-control/useFormControl';
import { Label } from './styled';

const FormLabel = ({ label, align = 'left', bold, children, ...other }) => {
  const formControl = useFormControl();

  return (
    <Label
      $inverse={formControl?.inverse}
      $align={align}
      htmlFor={formControl?.id}
      $bold={bold}
      {...other}
    >
      {children}
      {formControl?.required && <span aria-hidden> &thinsp;{'*'}</span>}
    </Label>
  );
};

export default FormLabel;
