/* @flow */
import {
  simpleAction,
  createReducer,
  simpleReducer,
  preferencesBusinessPreparationInitialState,
} from '@sharkfinesse/sfl-lib';
const namespace = (name: string): string => `@@preferences.businessPreparation/${name}`;

//Actions
export const types = {
  NOTES: {
    VISIBLE: namespace('NOTES.VISIBLE'),
  },
  VALUES: {
    VISIBLE: namespace('VALUES.VISIBLE'),
  },
  BEHIND_CALCS: {
    VISIBLE: namespace('BEHIND_CALCS.VISIBLE'),
  },
};

//Reducer
export const initialState = preferencesBusinessPreparationInitialState;

const reduceNotesVisible = simpleReducer('notesVisible');
const reduceBenefitValuesVisible = simpleReducer('benefitValuesVisible');
const reduceBehindCalcsVisible = simpleReducer('behindCalcsVisible');

export default createReducer(
  {
    [types.NOTES.VISIBLE]: reduceNotesVisible,
    [types.VALUES.VISIBLE]: reduceBenefitValuesVisible,
    [types.BEHIND_CALCS.VISIBLE]: reduceBehindCalcsVisible,
  },
  initialState
);

//Action Creators
const updateNotesVisible = simpleAction(types.NOTES.VISIBLE);
const updateBenefitValuesVisible = simpleAction(types.VALUES.VISIBLE);
const updateBehindCalcsVisible = simpleAction(types.BEHIND_CALCS.VISIBLE);

export const actionCreators = {
  updateNotesVisible,
  updateBenefitValuesVisible,
  updateBehindCalcsVisible,
};
