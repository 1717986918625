/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M12.4,13.9L2.4,0.5C1.9,0,1.1-0.2,0.5,0.3C0,0.7-0.2,1.6,0.3,2.1l9.4,12.5L0.3,27.2
	c-0.5,0.6-0.3,1.4,0.2,1.9s1.4,0.3,1.9-0.2c0,0,0,0,0,0l10-13.3C12.8,15,12.8,14.3,12.4,13.9z"
    />
  </Icon>
);

export default memo(IconComponent);
