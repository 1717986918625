import { select, call, all, take, cancelled, takeLatest, put } from 'redux-saga/effects';
import rsfApp from 'redux/rsf';
import {
  isNil,
  split,
  map,
  sessionSelectors,
  userSelectors,
  routerSelectors,
  getInitials,
  hslGenerator,
} from '@sharkfinesse/sfl-lib';
import {
  types as trackingTypes,
  actionCreators as trackingsActionCreators,
} from 'redux/modules/tracking';
import { getDeviceIdLocalStorage } from '../device';
import { errorModal } from 'utils';
import { actionCreators as appActionCreators } from 'redux/modules/app';
import { history } from 'index';

export function* sync({ sessionId }) {
  const channel = rsfApp.rsf.firestore.channel(
    rsfApp.firestore.collection(`/track/${sessionId}/active/`).orderBy('updated', 'desc'),
    'collection'
  );
  try {
    const userId = yield select(userSelectors.getId);
    while (true) {
      const state = yield take(channel);
      const docs = state.docChanges();

      if (!isNil(docs)) {
        yield all(
          map(document => {
            const id = document.doc.id;
            const data = document.doc.data();
            if (id !== userId) {
              if (document.type === 'removed') {
                return put(trackingsActionCreators.removeActive({ id }));
              }

              return put(
                trackingsActionCreators.addActive({
                  id,
                  data: { color: hslGenerator({ name: data.email }), ...data },
                })
              );
            }
            return null;
          }, docs)
        );
      }
    }
  } catch (error) {
    console.log('Collaboration sync error', error);
    errorModal({ error: error.message });
    yield all([
      put(appActionCreators.updateSessionFetching(false)),
      put(appActionCreators.updateLoadingActive(false)),
      call([history, history.push], { pathname: '/' }),
    ]);
  } finally {
    if (yield cancelled()) channel.close();
  }
}

export function* deleteTrack({ sessionId }) {
  const userId = yield select(userSelectors.getId);

  yield call(rsfApp.rsf.firestore.deleteDocument, `track/${sessionId}/active/${userId}`);
  yield put(trackingsActionCreators.reset());
}

function* track({ payload }) {
  const clientId = rsfApp.firebaseApp.options.clientId;
  const [userId, name, email, sessionId, pathname] = yield all([
    select(userSelectors.getId),
    select(userSelectors.getName),
    select(userSelectors.getEmail),
    select(sessionSelectors.getSessionId),
    select(routerSelectors.getPathname),
  ]);

  if (sessionId && userId) {
    const deviceId = getDeviceIdLocalStorage();
    const dbUser = userId.replace(/\./g, ',');
    const dbRef = rsfApp.database.ref(`/devices/${dbUser}/${deviceId}`);
    const pathArray = split('/', pathname);
    const page = pathArray.length > 3 ? pathArray[3] : 'summary';
    const initials = getInitials(name).toUpperCase();
    yield all([
      call(rsfApp.rsf.firestore.setDocument, `track/${sessionId}/active/${userId}`, {
        name,
        email,
        clientId,
        pathname,
        page,
        initials,
        ...payload,
        updated: new Date(),
      }),
      call([dbRef, dbRef.update], { sessionId }),
    ]);
  }
}

export const effects = [takeLatest(trackingTypes.TRACK, track)];

export default effects;
