/* @flow */
import { connect } from 'react-redux';
import { appSelectors, intlSelectors } from '@sharkfinesse/sfl-lib';
import App from 'components/app';

const mapStateToProps = (state, props) => ({
  loading: appSelectors.getLoading(state, props),
  loadingText: appSelectors.getLoadingText(state, props),
  loadingImmediate: appSelectors.getLoadingImmediate(state, props),
  locale: intlSelectors.getLocale(state, props),
  messages: intlSelectors.getMessages(state, props),
});

export default connect(mapStateToProps)(App);
