import breakpoints from './breakpoints';
const device = {
  toastMobile: `(max-width: ${breakpoints.toastMobile}px)`,
  xxs: `(min-width: ${breakpoints.xxs}px) and (max-width: ${breakpoints.xs - 1}px)`,
  xs: `(min-width: ${breakpoints.xs}px) and (max-width: ${breakpoints.sm - 1}px)`,
  sm: `(min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.md - 1}px)`,
  md: `(min-width: ${breakpoints.md}px) and (max-width: ${breakpoints.lg - 1}px)`,
  lg: `(min-width: ${breakpoints.lg}px) and (max-width: ${breakpoints.xl - 1}px)`,
  xl: `(min-width: ${breakpoints.xl}px) and (max-width: ${breakpoints.xxl - 1}px)`,
  xxl: `(min-width: ${breakpoints.xxl}px) and (max-width: ${breakpoints.xxxl - 1}px)`,
  xxxl: `(min-width: ${breakpoints.xxxl}px) and (max-width: ${breakpoints.ultrawide - 1}px)`,
  ultrawide: `(min-width: ${breakpoints.ultrawide}px) and (max-width: 6000px)`,
  min_xxs: `(min-width: ${breakpoints.xxs}px)`,
  min_xs: `(min-width: ${breakpoints.xs}px)`,
  min_sm: `(min-width: ${breakpoints.sm}px)`,
  min_md: `(min-width: ${breakpoints.md}px)`,
  min_lg: `(min-width: ${breakpoints.lg}px)`,
  min_xl: `(min-width: ${breakpoints.xl}px)`,
  min_xxl: `(min-width: ${breakpoints.xxl}px)`,
  min_xxxl: `(min-width: ${breakpoints.xxxl}px)`,
  max_xxs: `(max-width: ${breakpoints.xs - 1}px)`,
  max_xs: `(max-width: ${breakpoints.sm - 1}px)`,
  max_sm: `(max-width: ${breakpoints.md - 1}px)`,
  max_md: `(max-width: ${breakpoints.lg - 1}px)`,
  max_lg: `(max-width: ${breakpoints.xl - 1}px)`,
  max_xl: `(max-width: ${breakpoints.xxl - 1}px)`,
  max_xxl: `(max-width: ${breakpoints.xxxl - 1}px)`,
};

export default device;
