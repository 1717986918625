/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

const IconComponent = props => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M23.8,24.4H12.9c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h10.9c0.4,0,0.8-0.3,0.8-0.8S24.2,24.4,23.8,24.4z"
    />
    <path
      fill="currentColor"
      d="M23.8,20.4H12.9c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h10.9c0.4,0,0.8-0.3,0.8-0.8S24.2,20.4,23.8,20.4z"
    />
    <path
      fill="currentColor"
      d="M23.8,16.4H12.9c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h10.9c0.4,0,0.8-0.3,0.8-0.8S24.2,16.4,23.8,16.4z"
    />
    <path
      fill="currentColor"
      d="M27.7,11.4c0-0.1-0.1-0.2-0.2-0.2c0,0,0,0,0,0l-2.8-2.8c0-0.1,0-0.1,0-0.1V3.1c0-0.4-0.3-0.8-0.8-0.8H7
		c-0.4,0-0.8,0.3-0.8,0.8v25c0,0.4,0.3,0.7,0.7,0.8l2.3,0v2c0,0.4,0.3,0.8,0.8,0.8h17c0.4,0,0.8-0.3,0.8-0.8V11.7
		C27.8,11.6,27.7,11.5,27.7,11.4z M21.8,7.6l3.4,3.3h-3.4V7.6z M7.7,27.4V3.9h15.5V7l-1.7-1.7c0,0,0,0,0,0l0,0c0,0-0.1,0-0.1-0.1
		c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.2-0.1-0.3-0.1H10c-0.4,0-0.8,0.3-0.8,0.8v21.5L7.7,27.4z M10.8,30.1V6.6h9.5v5.1
		c0,0.4,0.3,0.8,0.8,0.8h5.2v17.6H10.8z"
    />
  </Icon>
);

export default memo(IconComponent);
