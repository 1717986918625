import { css } from 'styled-components';

const Sections = ({ font, colors, scale }) => ({
  base: () => css`
    border: 1px solid ${colors.uiLight};
    border-radius: 2px;
    padding: 10px;
  `,
});

export default Sections;
