"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reselect = require("reselect");

var _index = require("../../index");

/* eslint-disable no-unused-vars  */
const getActive = state => state.tracking.active;

const getColors = state => state.tracking.colors;

const sortDesc = (a, b) => b.updated.seconds - a.updated.seconds;

const sortUpdated = (0, _index.sort)(sortDesc);
const getActiveIds = (0, _reselect.createSelector)([getActive], active => {
  const activeKeys = {};
  const pages = (0, _index.mapObjIndexed)(({
    page,
    evalUid,
    fieldId,
    name,
    email,
    initials,
    color,
    updated
  }, userId) => {
    const user = {
      userId,
      name,
      email,
      initials,
      color,
      updated
    };
    activeKeys[page] = activeKeys[page] ? (0, _index.pipe)(_index.append, _index.uniq)(user, activeKeys[page]) : [user];

    if (fieldId && !evalUid) {
      activeKeys[fieldId] = activeKeys[fieldId] ? (0, _index.pipe)(_index.append, _index.uniq, sortUpdated)(user, activeKeys[fieldId]) : [user];
    }

    if (evalUid) {
      activeKeys[evalUid] = activeKeys[evalUid] ? (0, _index.pipe)(_index.append, _index.uniq, sortUpdated)(user, activeKeys[evalUid]) : [user];
    }

    if (fieldId) {
      const benefitFieldKey = `${evalUid}_${fieldId}`;
      activeKeys[benefitFieldKey] = activeKeys[benefitFieldKey] ? (0, _index.pipe)(_index.append, _index.uniq, sortUpdated)(user, activeKeys[benefitFieldKey]) : [user];
    }
  }, active);
  return activeKeys;
});
const selectors = {
  getActive,
  getActiveIds,
  getColors
};
var _default = selectors;
exports.default = _default;