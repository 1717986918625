"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reselect = require("reselect");

var _normalization = require("../../normalization");

var _constants = require("../../constants");

const getSession = state => state.session.present;

const getOwner = state => state.session.present.owner;

const getSessionId = state => state.session.present.id;

const getSessionDetails = state => state.session.present.details;

const getDealName = state => state.session.present.details.dealName;

const getCustomerName = state => state.session.present.details.customerName;

const getSupplierName = state => state.session.present.details.supplierName;

const getIndustry = state => state.session.present.details.industry;

const getMinimumReturn = state => state.session.present.details.minimumReturn;

const getNotes = state => state.session.present.details.notes;

const getNpv = state => state.session.present.npv;

const getIrr = state => state.session.present.irr;

const getPayback = state => state.session.present.payback;

const getRouter = state => state.session.present.router;

const getLocation = state => state.session.present.router.location;

const getPathname = state => state.session.present.router.location.pathname;

const getPast = state => state.session.past;

const getFuture = state => state.session.future;

const getGroup = state => state.session.group;

const getSelectedBenefits = state => state.session.present.businessPreparation.selectedBenefits;

const getBusinessPreparation = state => state.session.present.businessPreparation;

const getSharedTo = state => {
  var _state$session$presen;

  return (_state$session$presen = state.session.present) === null || _state$session$presen === void 0 ? void 0 : _state$session$presen.sharedTo;
};

const getUpdated = state => {
  var _state$session$presen2;

  return (_state$session$presen2 = state.session.present) === null || _state$session$presen2 === void 0 ? void 0 : _state$session$presen2.updated;
};

const getReviewPeriod = (0, _reselect.createSelector)([getSessionDetails], details => {
  return details.reviewPeriod === '' ? _constants.defaultReviewPeriod : (0, _normalization.adjustTimeUnit)('months', details.reviewPeriod);
});

const getLoaded = state => state.session.present.loaded;

const selectors = {
  getSession,
  getSessionId,
  getSessionDetails,
  getDealName,
  getCustomerName,
  getSupplierName,
  getIndustry,
  getMinimumReturn,
  getNotes,
  getReviewPeriod,
  getNpv,
  getIrr,
  getPayback,
  getRouter,
  getLocation,
  getPathname,
  getPast,
  getFuture,
  getGroup,
  getSelectedBenefits,
  getBusinessPreparation,
  getLoaded,
  getSharedTo,
  getOwner
};
var _default = selectors;
exports.default = _default;