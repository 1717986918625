import { css } from 'styled-components';
import { setAlphaHsla } from '@sharkfinesse/sfl-lib';

const Toast = ({ font, colors, scale, device: { min_md } }) => ({
  base: () => css`
    background-color: ${setAlphaHsla(0.9, colors.uiDarkest)};
    border: none;
    border-radius: 3px;
    font-weight: 400;
    display: flex;
    min-height: 40px;
  `,
  body: () => css`
    display: flex;
    color: ${colors.white};
    margin: 8px;
  `,
  savingContainer: () => css`
    top: 0;
    min-height: 12px;
    height: 34px;
    padding: 0 12px;
    text-align: center;
    border: none;
    border-radius: 0px;
    background-color: ${colors.white};
    color: ${font.color};
    justify-content: center;
    box-shadow: none;

    @media ${min_md} {
      border-radius: 3px;
      top: -6px;
      margin: 0 auto;
      width: fit-content;
    }
  `,

  progressBar: () => css`
    background: ${colors.primary};
  `,
  closeDefault: () => css`
    color: ${colors.white};
    line-height: 10px;
  `,
});

export default Toast;
