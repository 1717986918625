import { getSessionQuestionnaires } from 'redux/firestore';
import { useQuery } from 'react-query';
import { getCollectionData } from '@sharkfinesse/sfl-lib';

const getItems = async props => {
  const response = await getSessionQuestionnaires(props);
  const data = getCollectionData(response);
  return data;
};

const useQuestionnaire = (props, config) =>
  useQuery(['questionnaires', props.sessionId], () => getItems(props), config);

export default useQuestionnaire;
