// @flow weak
/* eslint-disable no-magic-numbers */
import React from 'react';
import FormControl from '../form-control';
import InputBase from '../input-base';
import FormLabel from '../form-label';
import { TextFieldContainer, Column, Row, HelpText } from './styled';
import Tooltip from './tooltip';

const TextField = ({
  id,
  invalid,
  inverse,
  required,
  inputProps,
  label,
  labelAlign,
  layout,
  selected,
  inputAlign,
  helpTextStyle,
  helpTextLabel,
  disabled,
  fullWidth,
  rounded,
  value,
  expanded,
  autosize,
  multiline,
  labelProps,
  labelContainerProps,
  ...other
}) => {
  const InputElement = <InputBase align={inputAlign} {...inputProps} value={value} {...other} />;
  return (
    <FormControl
      id={id}
      invalid={invalid}
      inverse={inverse}
      required={required}
      selected={selected}
      disabled={disabled}
      fullWidth={fullWidth}
      expanded={expanded}
      autosize={autosize}
      multiline={multiline}
      rounded={rounded}
    >
      <TextFieldContainer
        as={layout === 'column' ? Column : Row}
        $fullWidth={fullWidth}
        {...labelContainerProps}
      >
        {label && (
          <FormLabel align={labelAlign} {...labelProps}>
            {label}
          </FormLabel>
        )}
        {helpTextStyle?.type === 'tooltip' && helpTextLabel ? (
          <Tooltip
            usePortal={helpTextStyle?.config?.usePortal}
            placement={helpTextStyle?.config?.placement}
            portalContainer={helpTextStyle?.config?.portalContainer}
            label={helpTextLabel}
          >
            {InputElement}
          </Tooltip>
        ) : (
          InputElement
        )}
      </TextFieldContainer>

      {helpTextLabel && helpTextStyle?.type !== 'tooltip' && (
        <HelpText $invalid={invalid}>{helpTextLabel}</HelpText>
      )}
    </FormControl>
  );
};

export default TextField;
