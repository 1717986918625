/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon viewBox="0 0 28 28" {...props}>
    <path
      fill="currentColor"
      d="M1.5,0h25.1C27.3,0,28,0.7,28,1.5v25.1c0,0.8-0.7,1.5-1.5,1.5H1.5C0.7,28,0,27.3,0,26.5V1.5
	C0,0.7,0.7,0,1.5,0z"
    />
    <path
      fill="#FFFFFF"
      d="M9,6.3c-1.5,0-2.8,0.6-3.8,1.6c-1,1-1.5,2.3-1.6,3.9v9.9h2.1v-4.4H9c1.5,0,2.8-0.6,3.8-1.6
	c1-1,1.5-2.3,1.6-3.9c0-1.6-0.6-2.9-1.6-3.9C11.8,6.9,10.5,6.3,9,6.3L9,6.3z M5.8,15.1v-3.3c0-0.9,0.3-1.7,0.9-2.3
	c0.6-0.6,1.4-0.9,2.3-1c0.9,0,1.7,0.3,2.3,1c0.6,0.6,0.9,1.4,0.9,2.3c0,1-0.3,1.7-0.9,2.4c-0.6,0.6-1.4,0.9-2.3,1H5.8z"
    />
    <path
      fill="#FFFFFF"
      d="M18.7,13.2L18.7,13.2l3.1,4.1h2.6l-4-5.7l3.6-5.4h-2.6L18.7,10h-0.1l-2.5-3.8h-2.6l3.6,5.4
	l-4,5.7h2.6L18.7,13.2z"
    />
  </Icon>
);

export default memo(IconComponent);
