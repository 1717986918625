// @flow
import React, { useState } from 'react';
import { Dragger, DraggerWrapper } from './styled';
import { TextField } from '../../components/';

export type Props = {
  keyValue?: String | Number,
  cellValue?: String | Number,
  numberFormatObj: Object,
  onChange: Function,
  onMouseDown: Function,
  onMouseOver: Function,
  onMouseUp: Function,
  onMouseMove: Function,
  selecting: Boolean,
  selected: Boolean,
};

const CellInput = ({
  Component = TextField,
  value,
  numberFormatObj,
  onChange,
  onMouseDown,
  onMouseOver,
  onMouseUp,
  onMouseMove,
  selecting,
  selected,
  month,
  year,
  autoFocus,
  onFocus,
  onBlur,
  ...other
}: Props): React.Element<any> => {
  const [focused, setFocused] = useState(false);
  return (
    <>
      <Component
        layout="column"
        type="tel"
        value={value}
        onChange={onChange}
        numberFormatObj={numberFormatObj}
        onFocus={() => {
          onFocus && onFocus();
          setFocused(true);
        }}
        onBlur={() => {
          onBlur && onBlur();
          if (!selecting) setFocused(false);
        }}
        onMouseOver={e => {
          e.preventDefault();
          if (selecting) {
            onMouseOver(e, month, year);
          }
        }}
        selected={selected}
        autoFocus={autoFocus}
        highlightProps={{ style: { width: '100%' } }}
        {...other}
      />

      {focused && (
        <DraggerWrapper
          id={`${month}-${year}`}
          onMouseDown={e => {
            e.preventDefault();
            setFocused(true);
            onMouseDown(e, month, year);
          }}
          onTouchStart={e => {
            e.preventDefault();
            setFocused(true);
            onMouseDown(e, month, year);
          }}
        >
          <Dragger />
        </DraggerWrapper>
      )}
    </>
  );
};

export default CellInput;
