// @flow
import React from 'react';
import { StyledMenuItem, IconBefore, IconAfter } from './styled';
import { NavLink } from 'react-router-dom';
import { FlexRowFullWidth } from '../general-styles';

const MenuItem = ({ iconBefore, iconAfter, children, iconBeforeStyle, to, ...props }) => (
  <StyledMenuItem {...props}>
    <FlexRowFullWidth as={to ? NavLink : 'div'} to={to}>
      {iconBefore && <IconBefore style={iconBeforeStyle}>{iconBefore}</IconBefore>}
      {children}
      {iconAfter && <IconAfter>{iconAfter}</IconAfter>}
    </FlexRowFullWidth>
  </StyledMenuItem>
);

export default MenuItem;
