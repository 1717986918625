import { put, all, call, takeLatest, select } from 'redux-saga/effects';
import {
  merge,
  isNil,
  preferencesSelectors,
  fetchAccountLocale,
  userSelectors,
} from '@sharkfinesse/sfl-lib';
import { types as preferencesTypes } from 'redux/modules/preferences';
import { actionCreators as languageActionCreators } from 'redux/modules/language';
import rsfApp from '../rsf';
import moment from 'moment';

const getLanguages = () => import('locales/languages');

const getMessages = locale => import(`locales/${locale}`);

const getMomentLocale = locale => (locale !== 'en' ? import(`moment/locale/${locale}`) : true);

function* updateLocale(action) {
  const { firestore } = rsfApp;
  const [account, localeVal] = yield all([
    select(userSelectors.getAccount),
    select(preferencesSelectors.getLanguageValue),
  ]);

  const locale = isNil(localeVal) ? 'en' : localeVal;

  const [evalLocales, messages, languages] = yield all([
    account
      ? call(fetchAccountLocale, {
          firestore,
          account,
          locale,
        })
      : {},
    call(getMessages, locale),
    call(getLanguages),
    call(getMomentLocale, locale),
  ]);

  yield call([moment, moment.locale], locale);
  const curentLocale = {
    locale,
    messages: evalLocales
      ? merge(merge(languages, messages.default), evalLocales)
      : merge(languages, messages.default),
  };
  yield put(languageActionCreators.updateIntl(curentLocale));
  yield put(languageActionCreators.updateMerged());
}

export const effects = [takeLatest(preferencesTypes.SYNC, updateLocale)];

export default effects;
