/* eslint-disable prettier/prettier */
const bravo = 10;
const S = 9;
const Columns = ({ scale }) => ({
  default: {
    left: {
      size: { md: 1 / 2 },
    },
    right: {
      size: { md: 1 / 2 },
      padding: { xxs: 0, md: `0 0 0 ${scale.zero}` },
    },
  },
  benefits: {
    left: {
      size: { lg: 1 / 2 },
    },
    right: {
      size: { lg: 1 / 2 },
      padding: { xxs: 0, xl: `0 0 0 ${scale.zero}` },
    },
    drawerSize: { md: S / bravo },
  },
});

export default Columns;
