"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchUser = void 0;

var _utils = require("./utils");

const fetchUser = async ({
  firestore,
  id
}) => {
  const result = await (0, _utils.getDoc)({
    firestore,
    path: `users/${id}`
  });
  return result.data();
};

exports.fetchUser = fetchUser;