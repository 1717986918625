/* @flow */
import {
  simpleAction,
  createReducer,
  without,
  append,
  settingsSessionsInitialState,
} from '@sharkfinesse/sfl-lib';
const namespace = (name: string): string => `@@settings.sessions/${name}`;

//Actions
export const types = {
  OFFLINE: {
    ADD: namespace('OFFLINE.ADD'),
    REMOVE: namespace('OFFLINE.REMOVE'),
  },
};

//Reducer
export const initialState = settingsSessionsInitialState;
const reduceOfflineAdd = (state, action) => {
  return {
    ...state,
    offline: append(action.payload, state.offline),
  };
};
const reduceOfflineRemove = (state, action) => {
  return {
    ...state,
    offline: without(action.payload, state.offline),
  };
};

export default createReducer(
  {
    [types.OFFLINE.ADD]: reduceOfflineAdd,
    [types.OFFLINE.REMOVE]: reduceOfflineRemove,
  },
  initialState
);

//Action Creators
const addOfflineSession = simpleAction(types.OFFLINE.ADD);
const removeOfflineSession = simpleAction(types.OFFLINE.REMOVE);

export const actionCreators = {
  addOfflineSession,
  removeOfflineSession,
};
