"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCashflows = exports.getCashflowRecurringCostsOrignal = exports.getCashflowRecurringCostsEditted = exports.getCashflowNonRecurringCostsOriginal = exports.getCashflowNonRecurringCostsEditted = exports.getBenefitsCashflowStandard = exports.getBenefitsCashflowEditted = exports.getBenefitDataById = exports.getBenefitCashflowEdittedById = exports.default = void 0;

var _reselect = require("reselect");

var _index = require("../../index");

var _sessionDetails = _interopRequireDefault(require("./session-details"));

var _benefits = _interopRequireDefault(require("./benefits"));

var _evaluator = _interopRequireDefault(require("./evaluator"));

var _intl = _interopRequireDefault(require("./intl"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const getCashflows = state => state.session.present.cashflow;

exports.getCashflows = getCashflows;

const getBenefitsCashflowStandard = state => state.session.present.cashflow.benefits;

exports.getBenefitsCashflowStandard = getBenefitsCashflowStandard;

const getBenefitsCashflowEditted = state => state.session.present.cashflow.editted_benefits;

exports.getBenefitsCashflowEditted = getBenefitsCashflowEditted;

const getBenefitDataById = (state, benefitId) => state.session.present.cashflow.benefits[benefitId] || [];

exports.getBenefitDataById = getBenefitDataById;

const getBenefitCashflowEdittedById = (state, benefitId) => state.session.present.cashflow.editted_benefits[benefitId] || [];

exports.getBenefitCashflowEdittedById = getBenefitCashflowEdittedById;

const getCashflowRecurringCostsOrignal = state => state.session.present.cashflow.recurringCosts;

exports.getCashflowRecurringCostsOrignal = getCashflowRecurringCostsOrignal;

const getCashflowNonRecurringCostsOriginal = state => state.session.present.cashflow.nonRecurringCosts;

exports.getCashflowNonRecurringCostsOriginal = getCashflowNonRecurringCostsOriginal;

const getCashflowRecurringCostsEditted = state => state.session.present.cashflow.editted_recurringCosts;

exports.getCashflowRecurringCostsEditted = getCashflowRecurringCostsEditted;

const getCashflowNonRecurringCostsEditted = state => state.session.present.cashflow.editted_nonRecurringCosts;

exports.getCashflowNonRecurringCostsEditted = getCashflowNonRecurringCostsEditted;

const getCashflowNonRecurringCostsById = (state, id) => state.session.present.cashflow.nonRecurringCosts[id];

const getCashflowNonRecurringCostsEdittedById = (state, id) => state.session.present.cashflow.editted_nonRecurringCosts[id];

const getCashflowRecurringCostsById = (state, id) => state.session.present.cashflow.recurringCosts[id];

const getCashflowRecurringCostsEdittedById = (state, id) => state.session.present.cashflow.editted_recurringCosts[id];

const getCashflowRecurringCosts = (0, _reselect.createSelector)([getCashflowRecurringCostsOrignal, getCashflowRecurringCostsEditted], (original, editted) => {
  return (0, _index.mergeRight)(original, editted);
});
const getCashflowNonRecurringCosts = (0, _reselect.createSelector)([getCashflowNonRecurringCostsOriginal, getCashflowNonRecurringCostsEditted], (original, editted) => {
  return (0, _index.mergeRight)(original, editted);
});
const calculateCosts = (0, _index.pipe)((0, _index.apply)(_index.sumCashflows), _index.negateCashflow);
const getBenefitsCashflow = (0, _reselect.createSelector)([getBenefitsCashflowStandard, getBenefitsCashflowEditted], (standard, editted) => {
  return (0, _index.mergeRight)(standard, editted);
});
const calculateBenefits = (0, _index.pipe)((0, _index.apply)(_index.sumCashflows));
const getBlankCashflow = (0, _reselect.createSelector)([_sessionDetails.default.getReviewPeriod], _index.generateBlankCashflow);
const getNonRecurringCostsCashflow = (0, _reselect.createSelector)([getCashflowNonRecurringCosts, getCashflowNonRecurringCostsEditted], (cashflowNonRecurringCosts, cashflowNonRecurringCostsEditted) => {
  const mergedCashflow = (0, _index.mergeRight)(cashflowNonRecurringCosts, cashflowNonRecurringCostsEditted);
  return (0, _index.values)(mergedCashflow);
});
const getTotalNonRecurringCostsCashflow = (0, _reselect.createSelector)([getNonRecurringCostsCashflow], nonRecurringCostsCashflow => {
  return calculateCosts(nonRecurringCostsCashflow);
});
const getRecurringCostsCashflow = (0, _reselect.createSelector)([getCashflowRecurringCosts, getCashflowRecurringCostsEditted], (cashflowRecurringCosts, cashflowRecurringCostsEditted) => {
  const mergedCashflow = (0, _index.mergeRight)(cashflowRecurringCosts, cashflowRecurringCostsEditted);
  return (0, _index.values)(mergedCashflow);
});
const getTotalRecurringCostsCashflow = (0, _reselect.createSelector)([getRecurringCostsCashflow], recurringCostsCashflow => {
  return calculateCosts(recurringCostsCashflow);
});
const getTotalCostsCashflow = (0, _reselect.createSelector)([getTotalNonRecurringCostsCashflow, getTotalRecurringCostsCashflow], _index.sumCashflows);
const oneTimeBenefitsCashflow = (0, _reselect.createSelector)([_benefits.default.getCompletedOneTimeData, getBenefitsCashflow], (completedOneTimeData, benefitCashflows) => {
  return (0, _index.map)(x => benefitCashflows[x], completedOneTimeData);
});
const getOneTimeBenefitsCashflow = (0, _reselect.createSelector)(oneTimeBenefitsCashflow, cashflow => cashflow);
const getTotalOneTimeBenefitsCashflow = (0, _reselect.createSelector)([getOneTimeBenefitsCashflow], oneTimeBenefitsCashflow => {
  return calculateBenefits(oneTimeBenefitsCashflow);
});
const monthlyBenefitsCashflow = (0, _reselect.createSelector)([_benefits.default.getCompletedMonthlyData, getBenefitsCashflow], (completedMonthlyData, benefitCashflows) => {
  return (0, _index.map)(x => benefitCashflows[x], completedMonthlyData);
});
const getMonthlyBenefitsCashflow = (0, _reselect.createSelector)([monthlyBenefitsCashflow], cashflow => {
  return cashflow;
});
const getTotalMonthlyBenefitsCashflow = (0, _reselect.createSelector)([getMonthlyBenefitsCashflow], monthlyBenefitsCashflow => {
  return calculateBenefits(monthlyBenefitsCashflow);
});
const getTotalBenefitsCashflow = (0, _reselect.createSelector)([getTotalOneTimeBenefitsCashflow, getTotalMonthlyBenefitsCashflow], (totalOneTimeBenefitsCashflow, totalMonthlyBenefitsCashflow) => {
  return (0, _index.sumCashflows)(totalOneTimeBenefitsCashflow, totalMonthlyBenefitsCashflow);
});
const getNetCashflow = (0, _reselect.createSelector)([getBlankCashflow, getTotalCostsCashflow, getTotalBenefitsCashflow], (blankCashflow, totalCostsCashflow, totalBenefitsCashflow) => {
  return (0, _index.sumCashflows)(blankCashflow, totalCostsCashflow, totalBenefitsCashflow);
});
const getCumulativeNetCashflow = (0, _reselect.createSelector)([getNetCashflow], netCashflow => {
  return (0, _index.accumulateCashflow)(netCashflow);
});

const getBenefitCashflow = (state, benefitId) => state.session.present.cashflow.benefits[benefitId];

const getEdittedBenefitCashflow = (state, benefitId) => state.session.present.cashflow.editted_benefits[benefitId];

const getEvaluatorCashflow = (0, _reselect.createSelector)([getBenefitCashflow, getEdittedBenefitCashflow, _sessionDetails.default.getReviewPeriod], (cashflow, edittedCashflow, reviewPeriod) => {
  if (!(0, _index.isNil)(edittedCashflow)) return (0, _index.slice)(0, (0, _index.inc)(reviewPeriod), edittedCashflow);
  if (!(0, _index.isNil)(cashflow)) return (0, _index.slice)(0, (0, _index.inc)(reviewPeriod), cashflow);
  return [];
});

const getIsBenefitCashflowEdited = (state, benefitId) => state.session.present.cashflow.editted_benefits[benefitId] ? true : false;

const getCurrentBenefitValue = (0, _reselect.createSelector)([getBenefitCashflow, getEdittedBenefitCashflow], (cashflow, edittedCashflow) => (!(0, _index.isNil)(cashflow) || !(0, _index.isNil)(edittedCashflow)) && Math.round((0, _index.sum)((0, _index.isNil)(edittedCashflow) ? cashflow : edittedCashflow) / (0, _index.normalizingFloatingFixedPointFactor)(1)));
const getTotalBenefitValue = (0, _reselect.createSelector)([getBenefitCashflow, getEdittedBenefitCashflow, _benefits.default.getTotalBenefits], (cashflow, edittedCashflow, totalBenefits) => (!(0, _index.isNil)(cashflow) || !(0, _index.isNil)(edittedCashflow)) && Math.round((totalBenefits - (0, _index.sum)((0, _index.isNil)(edittedCashflow) ? cashflow : edittedCashflow)) / (0, _index.normalizingFloatingFixedPointFactor)(1)));
const getAllBenefitValues = (0, _reselect.createSelector)([getCashflows, _benefits.default.getOrderedBenefits, _intl.default.getMessages], (allBenefitsCashflows, orderedBenefits, intl) => !(0, _index.isNil)(allBenefitsCashflows) && !(0, _index.isNil)(orderedBenefits) ? orderedBenefits.map(({
  id,
  eNo,
  values
}) => {
  var _allBenefitsCashflows, _allBenefitsCashflows2;

  if (allBenefitsCashflows !== null && allBenefitsCashflows !== void 0 && (_allBenefitsCashflows = allBenefitsCashflows.editted_benefits) !== null && _allBenefitsCashflows !== void 0 && _allBenefitsCashflows[id] || allBenefitsCashflows !== null && allBenefitsCashflows !== void 0 && (_allBenefitsCashflows2 = allBenefitsCashflows.benefits) !== null && _allBenefitsCashflows2 !== void 0 && _allBenefitsCashflows2[id]) {
    return {
      name: id,
      value: Math.round((0, _index.sum)(allBenefitsCashflows.editted_benefits[id] ? allBenefitsCashflows.editted_benefits[id] : allBenefitsCashflows.benefits[id]) / (0, _index.normalizingFloatingFixedPointFactor)(1)),
      label: values.userTitle ? values.userTitle : intl[`${eNo}.title.label`]
    };
  } else {
    return {};
  }
}) : []);
const getCurrentBenefitValues = (0, _reselect.createSelector)([getCurrentBenefitValue, _evaluator.default.getUserTitle, _benefits.default.getCurrentBenefit, getTotalBenefitValue, _intl.default.getMessages], (currentBenefitValue, userTitle, currentBenefit, totalBenefitValue, intl) => !(0, _index.isNil)(currentBenefitValue) && !(0, _index.isNil)(totalBenefitValue) && !(0, _index.isNil)(currentBenefit) ? [{
  value: currentBenefitValue,
  name: intl['benefits.charts.pie.currentBenefit.label'],
  label: userTitle ? userTitle : intl[`${currentBenefit.eNo}.title.label`] || ''
}, {
  value: totalBenefitValue,
  name: intl['pptx.benefits.chart.remaining'],
  label: intl['pptx.benefits.chart.remaining']
}] : []);
const getBenefitLineChartData = (0, _reselect.createSelector)([getBenefitCashflow, getEdittedBenefitCashflow], (cashflow, edittedCashflow) => (0, _index.isNil)(edittedCashflow) && !(0, _index.isNil)(cashflow) ? cashflow.map((m, index) => [index, m]) : !(0, _index.isNil)(edittedCashflow) ? edittedCashflow.map((m, index) => [index, m]) : []);
const getAllBenefitLineChartData = (0, _reselect.createSelector)([getTotalMonthlyBenefitsCashflow], cashflow => cashflow.map((m, index) => [index, m]));
const selectors = {
  getCashflows,
  getBlankCashflow,
  getNetCashflow,
  getTotalBenefitsCashflow,
  getTotalCostsCashflow,
  getTotalMonthlyBenefitsCashflow,
  getTotalNonRecurringCostsCashflow,
  getTotalOneTimeBenefitsCashflow,
  getTotalRecurringCostsCashflow,
  getBenefitCashflow,
  getNonRecurringCostsCashflow,
  getRecurringCostsCashflow,
  getCashflowNonRecurringCosts,
  getCashflowRecurringCosts,
  getBenefitDataById,
  getBenefitCashflowEdittedById,
  getBenefitsCashflow,
  getCumulativeNetCashflow,
  getEdittedBenefitCashflow,
  getBenefitsCashflowEditted,
  getCashflowNonRecurringCostsEditted,
  getCashflowRecurringCostsEditted,
  getCurrentBenefitValue,
  getTotalBenefitValue,
  getAllBenefitValues,
  getCurrentBenefitValues,
  getBenefitLineChartData,
  getAllBenefitLineChartData,
  getEvaluatorCashflow,
  getIsBenefitCashflowEdited,
  getCashflowNonRecurringCostsById,
  getCashflowNonRecurringCostsEdittedById,
  getCashflowRecurringCostsById,
  getCashflowRecurringCostsEdittedById
};
var _default = selectors;
exports.default = _default;