/* @flow */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MultiSelect } from '@sharkfinesse/sfl-ui';
import { validateEmail, isNil, findByValue } from '@sharkfinesse/sfl-lib';
import ModalInfo from 'components/modal/modal-info';
import { useModal } from '@ebay/nice-modal-react';
import useSelectUsers from './useSelectUsers';
import createOption from './create-option';

const filterUsers = async ({ users, search }) => {
  const resolvedUsers = await users;
  return resolvedUsers.filter(i => i.label.toLowerCase().includes(search.toLowerCase()));
};

const EmailSelect = ({
  account,
  product,
  byProductOnly,
  createByProductOnly,
  email,
  creatable,
  setValue,
  value,
  usersToExclude,
  ...props
}: Props) => {
  const { users, isLoading } = useSelectUsers({
    account,
    product,
    byProductOnly,
    email,
    usersToExclude,
  });

  const infoModal = useModal(ModalInfo);

  const onCreateOption = async createEmail => {
    const user = findByValue(createEmail)(users);
    if (user) {
      const newOption = createOption({ ...user, email: user.value });

      if (isNil(value)) {
        setValue([newOption]);
      } else {
        setValue([...value, newOption]);
      }
      return true;
    }

    infoModal.show({
      title: (
        <FormattedMessage
          id="dealShare.user.select.notFoundModal.title"
          defaultMessage="User does not exist..."
        />
      ),
      body: (
        <FormattedMessage
          id="dealShare.user.select.notFoundModal.body"
          defaultMessage="Sorry, we were unable to find a user with that email address. Please try again."
        />
      ),
    });

    return false;
  };

  return (
    <MultiSelect
      labelVisible={false}
      useChipLabel
      hideDropdown
      async
      loadOptions={search => filterUsers({ users: creatable ? ' ' : users, search })}
      openMenuOnClick={false}
      hideSelectedOptions={true}
      isSearchable={true}
      noOptionsMessage={() =>
        creatable ? null : (
          <FormattedMessage id="dealShare.user.select.noOptions" defaultMessage="No users match" />
        )
      }
      formatCreateLabel={inputValue => (
        <FormattedMessage
          id="dealShare.user.select.createLabel"
          defaultMessage="Send to {inputValue}"
          values={{ inputValue }}
        />
      )}
      placeholder={
        creatable ? (
          <FormattedMessage
            id="dealShare.user.select.placeholderCreatable"
            defaultMessage="Enter email addresses"
          />
        ) : (
          <FormattedMessage
            id="dealShare.user.select.placeholder"
            defaultMessage="Enter names or email addresses"
          />
        )
      }
      onCreateOption={onCreateOption}
      creatable={creatable}
      isValidNewOption={inputValue => validateEmail(inputValue.toLowerCase())}
      isLoading={isLoading}
      value={value}
      {...props}
    />
  );
};

export default EmailSelect;
