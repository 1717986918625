import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as StateProvider } from 'react-redux';
import { UserAgentProvider } from '@quentin-sommer/react-useragent';
import { createBrowserHistory as createHistory } from 'history';
import configureStore from './redux/store';
import * as serviceWorker from './serviceWorkerRegistration';
import ErrorBoundaryGlobal from 'components/error-boundary';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { AbilityPermissionContext } from './ability/ability-context';
import abilityPermission from './ability/abilityPermission';
import { HelmetProvider } from 'react-helmet-async';
import { Auth0Provider } from '@auth0/auth0-react';
import getEnvironment from './environment';
import ThemeProvider from 'containers/theme/theme-provider';
import App from 'containers/app';

const environment = getEnvironment();

if (
  process.env.REACT_APP_MODE !== 'production' &&
  process.env.REACT_APP_MODE !== 'staging' &&
  process.env.REACT_APP_MODE !== 'devapp' &&
  process.env.REACT_APP_MODE !== 'bjones'
) {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

export const history = createHistory();
export const { persistor, store } = configureStore(history);

serviceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', event => {
        console.log('event.target.state ', event.target.state);
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});

const onRedirectCallback = appState => {
  if (appState?.returnPath) {
    history.replace(appState?.returnPath);
  }
};

ReactDOM.render(
  <Auth0Provider
    domain={environment.auth.clientDomain}
    clientId={environment.auth.clientId}
    redirectUri={window.location.origin}
    audience={environment.auth.audience}
    onRedirectCallback={onRedirectCallback}
  >
    <ErrorBoundaryGlobal>
      <StateProvider store={store}>
        <ThemeProvider>
          <PersistGate persistor={persistor}>
            <UserAgentProvider ua={window.navigator.userAgent}>
              <AbilityPermissionContext.Provider value={abilityPermission}>
                <HelmetProvider>
                  <App history={history} />
                </HelmetProvider>
              </AbilityPermissionContext.Provider>
            </UserAgentProvider>
          </PersistGate>
        </ThemeProvider>
      </StateProvider>
    </ErrorBoundaryGlobal>
  </Auth0Provider>,
  document.getElementById('root')
);

export default store;
