/* @flow */
/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';
import HelpContext from './context';

const Component = ({ children, ...props }) => {
  const [currentID, setCurrentID] = useState();
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [title, setTitle] = useState();
  const table = useMemo(
    () => ({ currentID, setCurrentID, breadcrumb, setBreadcrumb, title, setTitle }),
    [currentID, setCurrentID, breadcrumb, setBreadcrumb, title, setTitle]
  );

  return <HelpContext.Provider value={table}>{children}</HelpContext.Provider>;
};

export default Component;
