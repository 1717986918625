"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

const getReports = state => state.session.present.reports;

const getLong = state => state.session.present.reports.long;

const getLongGenerating = state => state.session.present.reports.long.generating;

const getLongSalesforceGenerating = state => state.session.present.reports.long_salesforce.generating;

const getCompareGenerating = state => {
  if (state.session.present.reports.compare_doc) {
    return state.session.present.reports.compare_doc.generating;
  } else {
    return false;
  }
};

const getCompareXlsxGenerating = state => {
  if (state.session.present.reports.compare_xlsx) {
    return state.session.present.reports.compare_xlsx.generating;
  } else {
    return false;
  }
};

const selectors = {
  getReports,
  getLong,
  getLongGenerating,
  getCompareGenerating,
  getCompareXlsxGenerating,
  getLongSalesforceGenerating
};
var _default = selectors;
exports.default = _default;