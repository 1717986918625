import React from 'react';
import { ToggleWrapper, ToggleArrow } from './styled'; //
import { IconToggleTab, IconToggleChevron } from '../icon';

const ToggleButton = ({ expanded, onClick, ...props }) => (
  <ToggleWrapper onClick={onClick} {...props}>
    <IconToggleTab />
    <ToggleArrow expanded={expanded}>
      <IconToggleChevron />
    </ToggleArrow>
  </ToggleWrapper>
);

export default ToggleButton;
