// @flow
import React from 'react';
import {
  Section,
  BodyTitle,
  FlexRow,
  ListWithDivider,
  SemiBold,
  ConfirmModal,
  Hyperlink,
  LoaderRing,
  FlexColumn,
} from '@sharkfinesse/sfl-ui';
import { reverse, findById, without, isEmpty } from '@sharkfinesse/sfl-lib';
import { FormattedMessage } from 'react-intl';
import { useModal } from '@ebay/nice-modal-react';
import { useSessionDetails, useUsers, useDeleteEditor } from 'redux/firestore';
import { errorModal } from 'utils';
import { useQueryClient } from 'react-query';
import { ListRow } from './styled';
import Avatar from 'components/avatar';

const EditorList = ({ sessionId, account, product, byProductOnly }) => {
  const confirmRemove = useModal(ConfirmModal);
  const { data, isLoading } = useSessionDetails({ id: sessionId, account });
  const queryClient = useQueryClient();

  const { mutate: deleteEditor } = useDeleteEditor(
    {},
    {
      onSuccess: id => {
        queryClient.setQueryData(`session_details_${sessionId}`, {
          ...data,
          editors: without([id], data.editors),
        });
      },
      onError: error => {
        errorModal({ error: error.message });
      },
    }
  );

  const { data: users, isLoading: isLoadingUsers } = useUsers({
    account,
    product: byProductOnly ? product : null,
  });

  return isLoading || isLoadingUsers ? (
    <div style={{ justifyContent: 'center', display: 'flex' }}>
      <LoaderRing />
    </div>
  ) : data?.editors && !isEmpty(data?.editors) ? (
    <Section style={{ marginTop: '20px' }}>
      <BodyTitle as="h2">
        <FormattedMessage id="share.editorList.title" defaultMessage="Existing editors" />
      </BodyTitle>
      <ListWithDivider>
        {reverse(data.editors).map((id, key) => {
          const { name, email } = findById(id)(users);
          return (
            <ListRow key={key} as="li">
              <FlexRow style={{ alignSelf: 'center', wordBreak: 'break-all' }} as="span">
                <Avatar
                  name={name}
                  email={email}
                  uid={id}
                  size={40}
                  style={{ marginRight: '14px' }}
                />
                <div>
                  <FlexColumn>
                    <SemiBold as="span">{name}</SemiBold>
                    {email}
                    <Hyperlink
                      onClick={() => {
                        confirmRemove
                          .show({
                            title: (
                              <FormattedMessage
                                id="share.editorList.remove.modal.title"
                                defaultMessage="Remove editor"
                              />
                            ),
                            body: (
                              <FormattedMessage
                                id="share.editorList.remove.modal.body"
                                defaultMessage="Are you sure you wish to remove <{email}> from editors?"
                                values={{
                                  email,
                                }}
                              />
                            ),
                          })
                          .then(() => {
                            deleteEditor({ account, sessionId, id });
                            confirmRemove.hide();
                          });
                      }}
                    >
                      <FormattedMessage id="share.editorList.remove.link" defaultMessage="Remove" />
                    </Hyperlink>
                  </FlexColumn>
                </div>
              </FlexRow>
            </ListRow>
          );
        })}
      </ListWithDivider>
    </Section>
  ) : null;
};

export default EditorList;
