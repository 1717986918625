/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

let IconLogo = (props): React.Element<any> => (
  <Icon viewBox="0 0 24 17" {...props}>
    <path
      fill="currentColor"
      d="M14.08,1c0.04,0.02,0.07,0.04,0.11,0.05c-0.03,0.69-0.72,1.29-1.02,1.78
	c-0.33,0.67-0.66,1.35-1,2.02c-1.61,2.74-3.96,4.56-5.86,6.96c-0.57,0.68-1.15,1.37-1.72,2.05c-0.24,0.23-0.48,0.47-0.73,0.7
	C3.48,15.04,2.93,15.69,2.41,16c-0.02-0.02-0.04-0.04-0.05-0.05c0.43-0.7,1.07-1.24,1.56-1.89c0.99-1.32,1.97-2.64,2.96-3.97
	C9.12,6.89,10.78,3.14,14.08,1L14.08,1z"
    />
    <path
      fill="currentColor"
      d="M18.3,1c0.04,0.02,0.09,0.04,0.13,0.05c-0.09,0.81-1.09,1.77-1.43,2.54
	c-1.67,3.78-4.72,5.95-7.1,8.98c-0.72,0.92-1.53,1.72-2.34,2.54c-0.27,0.27-0.59,0.79-1,0.89c0-0.01,0-0.02,0-0.03
	c0.32-0.6,0.86-1.02,1.26-1.54c0.91-1.19,1.81-2.37,2.72-3.56c1.42-2.01,2.7-4.16,4.11-6.15C15.33,3.8,16,2.76,16.88,2.05
	C17.34,1.68,17.82,1.37,18.3,1L18.3,1z"
    />
    <path
      fill="currentColor"
      d="M22.52,1c0.04,0,0.07,0,0.11,0c0,0.02,0,0.04,0,0.05c0.11,0.31-0.35,0.79-0.48,1
	c-0.6,0.9-1.02,1.98-1.59,2.94c-1.59,2.69-3.96,4.5-5.84,6.88C13.84,12.99,12.9,14,11.9,15c-0.34,0.33-0.68,0.67-1.02,1
	c-0.03,0-0.05,0-0.08,0c0.02-0.03,0.04-0.05,0.05-0.08c0.24-0.6,0.88-1.05,1.26-1.54c0.78-1.02,1.56-2.03,2.34-3.05
	c1.49-2.1,2.85-4.36,4.33-6.45c0.76-1.07,1.54-2.23,2.55-3.05C21.72,1.53,22.13,1.3,22.52,1L22.52,1z"
    />
  </Icon>
);

export default memo(IconLogo);
