import { setAlphaHsla } from '@sharkfinesse/sfl-lib';
const SelectControl = ({ font, colors }) => ({
  borderRadius: '3px',
  padding: '0px 0px',
  borderColor: colors.uiLight,
  borderWidth: 1,
  borderStyle: ({ inverse }) => (inverse ? 'none' : 'solid'),
  background: ({ inverse }) => (inverse ? setAlphaHsla(0.6, colors.white) : colors.white),
  //hover
  boxShadowColorHover: ({ inverse }) => (inverse ? 'none' : colors.uiLight),
  //focus
  borderColorFocused: colors.primary,
  borderStyleFocused: ({ inverse }) => (inverse ? 'none' : 'solid'),
  backgroundFocused: colors.white,
  indicatorSeparatorColor: ({ inverse }) =>
    inverse ? setAlphaHsla(0.25, colors.uiDarkest) : 'hsl(0,0%,80%)',
});

export default SelectControl;
