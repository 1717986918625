/* @flow */
/* eslint-disable max-len */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M10.8,13.2c0.4,0.3,0.4,0.9,0,1.2c-0.3,0.3-0.9,0.3-1.2,0c-1.7-1.7-1.7-4.4,0-6.1l0,0l3.1-3.1c1.7-1.7,4.4-1.7,6.1,0
	c1.7,1.7,1.7,4.4,0,6.1l-1.3,1.3c0-0.7-0.1-1.4-0.3-2.1l0.4-0.4c1-1,1-2.7,0-3.7c-1-1-2.7-1-3.7,0l-3.1,3.1
	C9.8,10.6,9.8,12.2,10.8,13.2 M13.2,9.6c0.3-0.3,0.9-0.3,1.2,0c1.7,1.7,1.7,4.4,0,6.1l0,0l-3.1,3.1c-1.7,1.7-4.4,1.7-6.1,0
	c-1.7-1.7-1.7-4.4,0-6.1l1.3-1.3c0,0.7,0.1,1.4,0.3,2.1l-0.4,0.4c-1,1-1,2.7,0,3.7c1,1,2.7,1,3.7,0l3.1-3.1c1-1,1-2.7,0-3.7
	C12.9,10.4,12.9,9.9,13.2,9.6z"
    />
  </Icon>
);

IconComponent = memo(IconComponent);

export default IconComponent;
