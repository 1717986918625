import {
  generateShortId,
  append,
  insert,
  isNil,
  indexOf,
  layoutSelectors,
  suiteListSelectors,
} from '@sharkfinesse/sfl-lib';
import { put, call, select, all } from 'redux-saga/effects';
import { actionCreators as layoutActionCreators } from 'redux/modules/layout';
import { actionCreators as layoutDesignerActionCreators } from 'redux/modules/layoutDesigner';
import { actionCreators as suiteListActionCreators } from 'redux/modules/suite-list';

export function addSuiteToLayout(state, action) {
  const { position } = action.payload;
  const id = generateShortId();
  return {
    id,
    layout: {
      ...state,
      suites: {
        ...state.suites,
        [id]: {
          benefits: [],
          id,
          customName: '',
        },
      },
      suitesOrder: isNil(position)
        ? append(id, state.suitesOrder)
        : insert(position, id, state.suitesOrder),
    },
  };
}
function* addSuite(action) {
  const [layout, visibleSuites, suitesOrder] = yield all([
    select(layoutSelectors.getLayout),
    select(suiteListSelectors.getVisibleList),
    select(layoutSelectors.getSuitesOrder),
  ]);

  action.payload.position = action?.payload?.suiteId
    ? indexOf(action.payload.suiteId, suitesOrder)
    : null;

  const newLayout = yield call(addSuiteToLayout, layout, action);
  yield put(layoutActionCreators.insertingSuite());
  yield all([
    put(layoutActionCreators.insertSuite(newLayout.layout)),
    put(suiteListActionCreators.updateVisible(append({ value: newLayout.id }, visibleSuites))),
  ]);
  yield put(layoutDesignerActionCreators.updateSelectedSuite(newLayout.id));
  yield put(layoutActionCreators.insertingSuiteComplete());
}

export default addSuite;
