import { useMutation } from 'react-query';
import rsfApp from 'redux/rsf';
import { filter, propEq } from '@sharkfinesse/sfl-lib';

const getItems = (type, items, immediate) => filter(propEq('type', type), items);

const moveFolders = async ({
  collectionRef,
  itemsToMove,
  folder,
  keyToUpdate = 'folder',
  immediate,
}) => {
  const batch = rsfApp.firestore.batch();
  itemsToMove.forEach(({ id }) => {
    //dont update if moving into self
    if (folder !== id) {
      const docRef = collectionRef.doc(id);
      batch.update(docRef, { [keyToUpdate]: folder });
    }
  });

  if (immediate) {
    batch.commit();
  } else {
    await batch.commit();
  }
};

const move = async ({ items, folder, immediate, account }) => {
  const sessionsToMove = getItems('session', items);
  const foldersToMove = getItems('folder', items);

  if (sessionsToMove.length > 0) {
    await moveFolders({
      collectionRef: rsfApp.firestore.collection(`accounts/${account}/sessions`),
      itemsToMove: sessionsToMove,
      folder,
      immediate,
    });
  }
  if (foldersToMove.length > 0) {
    await moveFolders({
      collectionRef: rsfApp.firestore.collection(`accounts/${account}/folders`),
      itemsToMove: foldersToMove,
      folder,
      immediate,
      keyToUpdate: 'parent',
    });
  }

  return;
};

const useMove = ({ items, moveTo, immediate, account }, config) => {
  return useMutation(move, config);
};

export default useMove;
