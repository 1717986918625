import firebase from 'firebase/app';
import rsfApp from 'redux/rsf';
import { errorModal } from 'utils';
import { getURLBlob, generateShortId, getFileExtension } from '@sharkfinesse/sfl-lib';

export const saveMedia = async ({ userId, account, product, media }) => {
  const docRef = await rsfApp.firestore
    .collection('accounts')
    .doc(account)
    .collection('media')
    .doc();
  const id = docRef.id;

  await docRef
    .set({
      ...media,
      id,
      owner: userId,
      product,
      dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
    })
    .catch(function(error) {
      errorModal({ error: error.message });
    });

  return { id };
};

export const uploadMedia = async ({ media, account, blob, fileNamePrefix = '' }) => {
  return rsfApp.storage
    .ref(`/accounts/${account}/media/${fileNamePrefix}${media.fileName}`)
    .put(blob)
    .then(function(snapshot) {
      return snapshot.ref.getDownloadURL();
    })
    .then(url => url)
    .catch(error => {
      errorModal({ error: error.message });
    });
};

export const uploadImage = async ({ url, account, media, fileNamePrefix }) => {
  const blob = await getURLBlob(url);

  const mediaUrl = await uploadMedia({
    account,
    media,
    blob,
    fileNamePrefix,
  });

  return mediaUrl;
};

export const getMedia = async ({ userId, account, product, resourceOwner, page }) => {
  if (page === 0) {
    return rsfApp.firestore
      .collection('accounts')
      .doc(account)
      .collection('media')
      .where('owner', 'in', [userId, resourceOwner])
      .orderBy('dateCreated', 'desc')
      .limit(20)
      .get();
  }
  return rsfApp.firestore
    .collection('accounts')
    .doc(account)
    .collection('media')
    .where('owner', 'in', [userId, resourceOwner])
    .orderBy('dateCreated', 'desc')
    .startAfter(page)
    .limit(20)
    .get();
};

export const uploadFile = async ({ file, account, setProgress }) => {
  const uploadTask = rsfApp.storage
    .ref(`/accounts/${account}/media/${generateShortId()}.${getFileExtension(file.name)}`)
    .put(file, {
      contentType: file.type,
    });

  // Listen for state changes, errors, and completion of the upload.
  let imageUrl = '';
  await new Promise((resolve, reject) => {
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      function(snapshot) {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      function(error) {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors

        reject(error);
      },
      function() {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
          imageUrl = downloadURL;
          resolve();
        });
      }
    );
  });

  return imageUrl;
};

export const deleteMedia = async ({ account, id }) => {
  return rsfApp.firestore
    .collection('accounts')
    .doc(account)
    .collection('media')
    .doc(id)
    .delete()
    .catch(function(error) {
      errorModal({ error: error.message });
    });
};
