"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.questionnaireStatus = exports.getQuestionnaireSubmissions = void 0;

const getQuestionnaireSubmissions = ({
  firestore,
  account,
  owner,
  sessionId
}) => firestore.collection(`accounts/${account}/questionnaires`).where('owner', '==', owner).where('sessionId', '==', sessionId).get();

exports.getQuestionnaireSubmissions = getQuestionnaireSubmissions;

const questionnaireStatus = data => data.reduce((accum, item) => {
  const originDate = new Date(null);
  const created = item.sessionCreated || originDate;
  const imported = item.imported || originDate;
  if (!item.sessionCreated && !item.imported) return { ...accum,
    submissionCount: accum.submissionCount + 1,
    newCount: accum.newCount + 1,
    submissions: [...accum.submissions, { ...item,
      type: 'new'
    }]
  };
  if (item.updated > created && item.updated > imported) return { ...accum,
    submissionCount: accum.submissionCount + 1,
    updateCount: accum.updateCount + 1,
    submissions: [...accum.submissions, { ...item,
      type: 'update'
    }]
  };
  return { ...accum,
    submissions: [...accum.submissions, { ...item,
      type: 'existing'
    }]
  };
}, {
  submissionCount: 0,
  newCount: 0,
  updateCount: 0,
  submissions: []
});

exports.questionnaireStatus = questionnaireStatus;