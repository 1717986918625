"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reselect = require("reselect");

var _account = _interopRequireDefault(require("./account"));

var _product = _interopRequireDefault(require("./product"));

var _index = require("../../index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const getPreferences = state => state.preferences;

const getTheme = state => state.preferences.theme;

const getLanguage = state => state.preferences.language;

const getLanguageValue = state => state.preferences.language.value;

const getProduct = state => state.preferences.product;

const getAccount = state => state.preferences.account;

const getSession = state => state.preferences.session;

const getSessionDetails = state => state.preferences.session.details;

const getCurrency = state => state.preferences.session.details.currency;

const getNumberFormat = state => state.preferences.session.details.numberFormat;

const getDateFormat = state => state.preferences.session.details.dateFormat;

const getReportTheme = state => state.preferences.reportTheme;

const getReportThemeValue = state => state.preferences.reportTheme.value;

const getDocxTemplate = state => state.preferences.docxTemplate;

const getDocxTemplateValue = state => state.preferences.docxTemplate.value;

const getPptxTemplate = state => state.preferences.pptxTemplate;

const getPptxTemplateValue = state => state.preferences.pptxTemplate.value;

const getNotesVisible = state => state.preferences.businessPreparation.notesVisible;

const getBenefitValuesVisible = state => state.preferences.businessPreparation.benefitValuesVisible;

const getBehindCalcsVisible = state => state.preferences.businessPreparation.behindCalcsVisible;

const getDebuggingEnabled = state => state.preferences.debugging.enabled;

const getAnalyticsEnabled = state => state.preferences.analytics.enabled;

const getReportSettings = state => state.preferences.reportSettings;

const getAutoImageEnabled = state => state.preferences.autoImage.enabled;

const getAutoImageKeyword = state => state.preferences.autoImage.keyword;

const getDefaultSessionMedia = state => state.preferences.defaultSessionImage.media;

const getDefaultSessionMediaPosition = state => state.preferences.defaultSessionImage.media.position;

const getDefaultStartMonth = state => state.preferences.session.details.defaultStartMonth;

const getDefaultStartMonthForDisplay = (0, _reselect.createSelector)([getSessionDetails], details => {
  return details.defaultStartMonth === '' ? '' : (0, _index.adjustTimeUnit)('months', details.defaultStartMonth);
});
const smallestEmptyPng = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII=';
const getLogo = (0, _reselect.createSelector)([_product.default.getLogos, _account.default.getLogos], (productLogos, logos) => {
  var _logos$default;

  return !(0, _index.isEmpty)(productLogos) ? productLogos === null || productLogos === void 0 ? void 0 : productLogos.default.base64 : (0, _index.isEmpty)(logos) ? smallestEmptyPng : logos === null || logos === void 0 ? void 0 : (_logos$default = logos.default) === null || _logos$default === void 0 ? void 0 : _logos$default.base64;
});
const getCoverpages = (0, _reselect.createSelector)([_product.default.getCoverpages, _account.default.getCoverpages], (productCoverpages, coverpages) => !(0, _index.isEmpty)(productCoverpages) ? productCoverpages.default.base64 : (0, _index.isEmpty)(coverpages) ? smallestEmptyPng : coverpages.default.base64);
const getPreferencesTree = (0, _reselect.createSelector)([getReportSettings], reportSettings => {
  const initialState = [];

  if (reportSettings.benefits && reportSettings.benefitsMonthly && reportSettings.benefitsOnetime) {
    initialState.push('0-1');
  }

  if (reportSettings.benefitsMonthly) {
    initialState.push('0-1-0');
  }

  if (reportSettings.benefitsOnetime) {
    initialState.push('0-1-1');
  }

  if (reportSettings.costs && reportSettings.nonRecurringCosts && reportSettings.recurringCosts) {
    initialState.push('0-2');
  }

  if (reportSettings.nonRecurringCosts) {
    initialState.push('0-2-0');
  }

  if (reportSettings.recurringCosts) {
    initialState.push('0-2-1');
  }

  if (reportSettings.roi && reportSettings.npv && reportSettings.irr && reportSettings.payback) {
    initialState.push('0-3');
  }

  if (reportSettings.npv) {
    initialState.push('0-3-0');
  }

  if (reportSettings.irr) {
    initialState.push('0-3-1');
  }

  if (reportSettings.payback) {
    initialState.push('0-3-2');
  }

  if (reportSettings.costOf && reportSettings.tender && reportSettings.doNothing && reportSettings.delay) {
    initialState.push('0-4');
  }

  if (reportSettings.tender) {
    initialState.push('0-4-0');
  }

  if (reportSettings.doNothing) {
    initialState.push('0-4-1');
  }

  if (reportSettings.delay) {
    initialState.push('0-4-2');
  }

  if (reportSettings.cashOnlySummary) {
    initialState.push('0-5');
  }

  return initialState;
});
const getPreferencesTreeQuick = (0, _reselect.createSelector)([getReportSettings], reportSettings => {
  const initialState = [];

  if (reportSettings.cashflows && reportSettings.cashflowsRoi && reportSettings.npvCashflow && reportSettings.irrCashflow) {
    initialState.push('0-6');
  }

  if (reportSettings.cashflowsRoi && reportSettings.npvCashflow && reportSettings.irrCashflow) {
    initialState.push('0-6-0');
  }

  if (reportSettings.npvCashflow) {
    initialState.push('0-6-0-0');
  }

  if (reportSettings.irrCashflow) {
    initialState.push('0-6-0-1');
  }

  if (reportSettings.charting && reportSettings.chartingBenefits && reportSettings.chartingBenefits && reportSettings.benefitsMonthlyChart && reportSettings.benefitsOnetimeChart && reportSettings.chartingCosts && reportSettings.nonRecurringCostsChart && reportSettings.recurringCostsChart && reportSettings.chartingRoi && reportSettings.npvChart && reportSettings.irrChart && reportSettings.paybackChart && reportSettings.chartingCostOf && reportSettings.tenderChart && reportSettings.delayChart) {
    initialState.push('0-7');
  }

  if (reportSettings.chartingBenefits && reportSettings.benefitsMonthlyChart && reportSettings.benefitsOnetimeChart) {
    initialState.push('0-7-0');
  }

  if (reportSettings.benefitsMonthlyChart) {
    initialState.push('0-7-0-0');
  }

  if (reportSettings.benefitsOnetimeChart) {
    initialState.push('0-7-0-1');
  }

  if (reportSettings.chartingCosts && reportSettings.nonRecurringCostsChart && reportSettings.recurringCostsChart) {
    initialState.push('0-7-1');
  }

  if (reportSettings.nonRecurringCostsChart) {
    initialState.push('0-7-1-0');
  }

  if (reportSettings.recurringCostsChart) {
    initialState.push('0-7-1-1');
  }

  if (reportSettings.chartingRoi && reportSettings.npvChart && reportSettings.irrChart && reportSettings.paybackChart) {
    initialState.push('0-7-2');
  }

  if (reportSettings.npvChart) {
    initialState.push('0-7-2-0');
  }

  if (reportSettings.irrChart) {
    initialState.push('0-7-2-1');
  }

  if (reportSettings.paybackChart) {
    initialState.push('0-7-2-2');
  }

  if (reportSettings.chartingCostOf && reportSettings.tenderChart && reportSettings.delayChart) {
    initialState.push('0-7-3');
  }

  if (reportSettings.tenderChart) {
    initialState.push('0-7-3-0');
  }

  if (reportSettings.delayChart) {
    initialState.push('0-7-3-1');
  }

  if (reportSettings.coverPage) {
    initialState.push('0-8');
  }

  return initialState;
});
const selectors = {
  getPreferences,
  getTheme,
  getLanguage,
  getLanguageValue,
  getProduct,
  getAccount,
  getSession,
  getSessionDetails,
  getCurrency,
  getNumberFormat,
  getDateFormat,
  getReportTheme,
  getReportThemeValue,
  getNotesVisible,
  getBenefitValuesVisible,
  getBehindCalcsVisible,
  getDebuggingEnabled,
  getAnalyticsEnabled,
  getReportSettings,
  getPreferencesTree,
  getPreferencesTreeQuick,
  getLogo,
  getAutoImageEnabled,
  getAutoImageKeyword,
  getDefaultSessionMedia,
  getDefaultSessionMediaPosition,
  getCoverpages,
  getDefaultStartMonth,
  getDefaultStartMonthForDisplay,
  getDocxTemplate,
  getDocxTemplateValue,
  getPptxTemplate,
  getPptxTemplateValue
};
var _default = selectors;
exports.default = _default;