import { css } from 'styled-components';

const Hyperlink = ({ font, colors, scale }) => ({
  base: () => css`
    color: ${colors.primary};
    cursor: pointer;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  `,
});

export default Hyperlink;
