// @flow
import React, { useEffect, cloneElement, useContext } from 'react';
import 'styled-components/macro';
import { Rnd } from 'react-rnd';
import useWindowSize from 'hooks/useWindowSize';
import { PinRight, PinLeft } from './styled';
import { DrawerContext } from './drawer';

const Component = ({ isExpanded, children }) => {
  const {
    position,
    setPosition,
    draggable,
    setDraggable,
    width,
    setWidth,
    height,
    setHeight,
    x,
    setX,
    y,
    setY,
    showRightPin,
    setShowRightPin,
    showLeftPin,
    setShowLeftPin,
  } = useContext(DrawerContext);
  const { windowWidth, windowHeight } = useWindowSize();

  useEffect(() => {
    if (!draggable) {
      const newWidth = width > windowWidth ? windowWidth - 20 : width;
      const newX = windowWidth - newWidth;
      setWidth(newWidth);
      setX(position === 'right' ? newX : 0);
    } else if (x + width > windowWidth) {
      setX(position === 'right' ? windowWidth - width : 0);
    } //eslint-disable-next-line
  }, [windowWidth]);

  useEffect(() => {
    if (!draggable) {
      setHeight(windowHeight);
    } else if (y + height > windowHeight) {
      setY(windowHeight - height);
    } //eslint-disable-next-line
  }, [windowHeight]);

  const makeDraggable = () => {
    setY(y + 25);
    setX(position === 'right' ? x - 25 : x + 25);
    setHeight(height - 50);
    setDraggable(true);
  };

  const pin = ({ direction }) => {
    setHeight(windowHeight);
    setDraggable(false);
    setY(0);
    setX(direction === 'right' ? windowWidth - width : 0);
    setPosition(direction);
  };

  return (
    <>
      {showRightPin && <PinRight />}
      {showLeftPin && <PinLeft />}
      <Rnd
        size={{ width, height }}
        position={{ x, y }}
        bounds="window"
        maxWidth={windowWidth}
        maxHeight={windowHeight}
        minHeight="150px"
        minWidth="150px"
        disableDragging={!draggable}
        style={{
          zIndex: 9000,
          pointerEvents: isExpanded ? 'auto' : 'none',
        }}
        onDrag={({ clientX }, { x, y, node, deltaX, lastX }) => {
          if (x + width >= windowWidth - 20) {
            setShowRightPin(true);
          } else {
            setShowRightPin(false);
          }
          if (x < 20) {
            setShowLeftPin(true);
          } else {
            setShowLeftPin(false);
          }
        }}
        onDragStop={({ clientX }, { x, y, node, deltaX, lastX }) => {
          if (x + width >= windowWidth - 20) {
            pin({ direction: 'right' });
          } else if (x < 20) {
            pin({ direction: 'left' });
          } else {
            setY(y);
            setX(x);
          }
          setShowRightPin(false);
          setShowLeftPin(false);
        }}
        onResizeStop={(e, direction, ref, delta, { x, y }) => {
          setWidth(parseInt(ref.style.width, 10));
          setHeight(ref.style.height);
          setX(ref.style.height);
          setX(x);
          setY(y);
        }}
      >
        {cloneElement(children, {
          draggable,
          makeDraggable,
          position,
          setPosition,
          setDraggable,
          pin,
        })}
      </Rnd>
    </>
  );
};

export default Component;
