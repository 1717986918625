// @flow
import { connect } from 'react-redux';
import { deviceSelectors, trackingSelectors } from '@sharkfinesse/sfl-lib';
import UserPosition from 'components/user-position';

const mapStateToProps = (state, props) => ({
  isOnline: deviceSelectors.isOnline(state, props),
  activeIds: trackingSelectors.getActiveIds(state, props),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserPosition);
