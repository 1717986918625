import ButtonNormal from './button-normal';
import { padding } from 'polished';

const ButtonSmall = config => ({
  ...ButtonNormal(config),
  padding: padding(config.scale.minusThree, '10px'),
  lineHeight: config.font.lineHeightSmall,
  fontSize: config.font.sizeSmall,
});

export default ButtonSmall;
