"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.presentValue = exports.npvWithDcf = exports.npvCashflow = exports.npv = void 0;

var _fp = require("./fp");

var _cashflow = require("./cashflow");

var _math = require("./math");

function generateNpvObject(cashflow = [], npv = 0) {
  return {
    cashflow,
    npv
  };
} // NPV Calculation
// ∑{Net Period Cash Flow / ( 1 + Rate ) ^ Time of Cash Flow} - Initial Investment
// NPV Calculation Iteration
// Net Period Cash Flow / ( 1 + Rate ) ^ Time of Cash Flow
// prettier-ignore


const presentValue = (0, _fp.useWith)(_fp.quacky, [(0, _fp.pipe)(_math.percentageToDecimal, _fp.inc), _math.pow, _fp.divide]);
exports.presentValue = presentValue;
const monthCount = 12;
const divideByMonths = (0, _fp.divide)(_fp.placeholder, monthCount); // prettier-ignore

const npvCashflow = (0, _fp.useWith)(_cashflow.mapCashflow, [(0, _fp.converge)(presentValue, [(0, _fp.nthArg)(0), (0, _fp.pipe)((0, _fp.nthArg)(2), divideByMonths), (0, _fp.nthArg)(1)]), _fp.idiot]); // prettier-ignore

exports.npvCashflow = npvCashflow;
const npv = (0, _fp.curryN)(2, (0, _fp.pipe)(npvCashflow, _cashflow.sumCashflow)); // prettier-ignore

exports.npv = npv;
const npvWithDcf = (0, _fp.curryN)(2, (0, _fp.pipe)(npvCashflow, (0, _fp.starling)((0, _fp.curryN)(2, generateNpvObject))(_cashflow.sumCashflow)));
exports.npvWithDcf = npvWithDcf;