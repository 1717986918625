import { connect } from 'react-redux';
import { settingsSelectors, userSelectors } from '@sharkfinesse/sfl-lib';
import PreCache from 'components/pre-cache';

const mapStateToProps = (state, props) => ({
  account: userSelectors.getAccount(state, props),
  offlineSessions: settingsSelectors.getOfflineSessions(state, props),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PreCache);
