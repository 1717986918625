// @flow
import React, { Component } from 'react';
import Button from '../button';
import IconFilter from '../icon/icons/icon-filter';
import IconChecked from '../icon/icons/icon-checked';
import Menu, { MenuList } from '../menu';
import MenuItem from '../menu-item';
import { isNil } from '@sharkfinesse/sfl-lib';

type DefaultProps = {
  color?: string,
  disabled?: boolean,
  tooltip?: string,
  usePortal: boolean,
};

type Props = DefaultProps & {
  updateFilters?: Function,
  filters?: string,
  color?: string,
  fields?: array,
  disabled?: boolean,
  tooltip?: string,
  popped: boolean,
};

type State = {
  open: Boolean,
};

class FilterMenu extends Component<Props, State> {
  state = {
    open: false,
  };

  updateOpen = value => {
    this.setState({ open: value });
  };

  set = open => this.setState({ open });

  filter = (value, key) => {
    const { updateFilters, filters } = this.props;
    this.updateOpen(false);
    let filterValue = null;
    if (!isNil(filters)) {
      if (filters.key !== key || filters.value !== value) {
        filterValue = {
          key,
          value,
        };
      }
    } else {
      filterValue = {
        key,
        value,
      };
    }

    updateFilters(filterValue);
  };

  getIcon = field => {
    const { filters } = this.props;
    if (!isNil(filters)) return filters.value === field ? <IconChecked /> : null;
    return null;
  };

  render() {
    const { open } = this.state;
    const { fields, color, disabled, tooltip, popped } = this.props;

    return disabled ? (
      <Button size="icon" variant="flat" color={color} disabled={disabled}>
        <IconFilter />
      </Button>
    ) : (
      <Menu
        open={open}
        onVisibilityChange={this.set}
        usePortal={!popped}
        referenceComponent={
          <Button size="icon" variant="flat" color={color} tooltip={tooltip}>
            <IconFilter />
          </Button>
        }
      >
        <MenuList>
          {fields.map((field, i) => (
            <MenuItem
              key={i}
              iconAfter={this.getIcon(field.value)}
              onClick={() => {
                this.filter(field.value, field.key);
              }}
            >
              {field.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    );
  }
}
FilterMenu.defaultProps = {
  color: 'light',
  disabled: false,
  tooltip: '',
  popped: false,
};

export default FilterMenu;
