"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = require("../../index");

var _reselect = require("reselect");

var _session = _interopRequireDefault(require("./session"));

var _layout = _interopRequireDefault(require("./layout"));

var _account = _interopRequireDefault(require("./account"));

var _recurringCosts = _interopRequireDefault(require("./recurring-costs"));

var _nonRecurringCosts = _interopRequireDefault(require("./non-recurring-costs"));

var _sessionDetails = _interopRequireDefault(require("./session-details"));

var _benefitsSearch = _interopRequireDefault(require("./benefits-search"));

var _evaluator = _interopRequireDefault(require("./evaluator"));

var _intl = _interopRequireDefault(require("./intl"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* eslint-disable no-unused-vars  */
const getBenefitsNode = state => {
  var _state$session$presen;

  return (_state$session$presen = state.session.present) === null || _state$session$presen === void 0 ? void 0 : _state$session$presen.benefits;
};

const getBenefits = state => {
  var _state$session$presen2, _state$session$presen3;

  return (_state$session$presen2 = state.session.present) === null || _state$session$presen2 === void 0 ? void 0 : (_state$session$presen3 = _state$session$presen2.benefits) === null || _state$session$presen3 === void 0 ? void 0 : _state$session$presen3.data;
};

const getBenefitsCashflow = state => state.session.present.cashflow.benefits;

const getEdittedBenefitsCashflow = state => state.session.present.cashflow.editted_benefits;

const getIds = state => state.session.present.benefits.ids;

const getCompletedOneTime = (completedBenefits, layoutBenefits, accountEvaluators) => {
  const isOnce = x => {
    const eNo = layoutBenefits[x] && layoutBenefits[x].eNo;

    if (!(0, _index.isNil)(eNo) && accountEvaluators[eNo].schema.recurrence === 'once') {
      return true;
    } else {
      return false;
    }
  };

  return (0, _index.isEmpty)(layoutBenefits) ? [] : (0, _index.filter)(isOnce, completedBenefits);
};

const getCompletedMonthly = (completedBenefits, layoutBenefits, accountEvaluators) => {
  const isMonthly = x => {
    const eNo = layoutBenefits[x] && layoutBenefits[x].eNo;

    if (!(0, _index.isNil)(eNo) && accountEvaluators[eNo].schema.recurrence === 'once') {
      return false;
    } else {
      return true;
    }
  };

  return (0, _index.isEmpty)(layoutBenefits) ? [] : (0, _index.filter)(isMonthly, completedBenefits);
};

const getCompletedBenefits = (0, _reselect.createSelector)([getBenefits], benefits => {
  const hasResult = benefit => !(0, _index.isNil)(benefit.result);

  return (0, _index.keys)((0, _index.filter)(hasResult, benefits));
});
const getCompletedBenefitCashflows = (0, _reselect.createSelector)([getBenefitsCashflow], benefitCashflows => {
  const completed = (0, _index.keys)(benefitCashflows);
  return completed;
});
const getMonthlyBenefits = (0, _reselect.createSelector)([_session.default.getReviewPeriod, getCompletedBenefitCashflows, getBenefitsCashflow, getEdittedBenefitsCashflow, _layout.default.getBenefits, _account.default.getEvaluators], (period, completedBenefits, benefitsCashflow, edittedBenefitsCashflow, layoutBenefits, accountEvaluators) => {
  const mergedBenefitsCashflow = (0, _index.mergeRight)(benefitsCashflow, edittedBenefitsCashflow);
  return (0, _index.reduce)((a, v) => a + (0, _index.sum)(mergedBenefitsCashflow[v]), 0, getCompletedMonthly(completedBenefits, layoutBenefits, accountEvaluators)) / period;
});
const getMonthlyBenefitsDisplay = (0, _reselect.createSelector)([getMonthlyBenefits, _sessionDetails.default.getNumberFormatObj], (monthlyBenefits, numberFormatObj) => {
  return (0, _index.formatForDisplay)('money', numberFormatObj, monthlyBenefits);
});
const getOneTimeBenefits = (0, _reselect.createSelector)([getCompletedBenefitCashflows, getBenefitsCashflow, getEdittedBenefitsCashflow, _layout.default.getBenefits, _account.default.getEvaluators], (completedBenefits, benefitsCashflow, edittedBenefitsCashflow, layoutBenefits, accountEvaluators) => {
  const mergedBenefitsCashflow = (0, _index.mergeRight)(benefitsCashflow, edittedBenefitsCashflow);
  return (0, _index.reduce)((a, v) => a + (0, _index.sum)(mergedBenefitsCashflow[v]), 0, getCompletedOneTime(completedBenefits, layoutBenefits, accountEvaluators));
});
const getOneTimeBenefitsDisplay = (0, _reselect.createSelector)([getOneTimeBenefits, _sessionDetails.default.getNumberFormatObj], (value, numberFormatObj) => {
  if (value === 0) {
    return null;
  } else {
    return (0, _index.formatForDisplay)('money', numberFormatObj, value);
  }
});
const getMonthlyQuantity = (0, _reselect.createSelector)([getCompletedBenefits, _layout.default.getBenefits, _account.default.getEvaluators], (completedBenefits, layoutBenefits, accountEvaluators) => {
  return getCompletedMonthly(completedBenefits, layoutBenefits, accountEvaluators).length;
});
const getOneTimeQuantity = (0, _reselect.createSelector)([getCompletedBenefits, _layout.default.getBenefits, _account.default.getEvaluators], (completedBenefits, layoutBenefits, accountEvaluators) => {
  return getCompletedOneTime(completedBenefits, layoutBenefits, accountEvaluators).length;
});
const getTotalQuantity = (0, _reselect.createSelector)([getMonthlyQuantity, getOneTimeQuantity], (monthlyQuantity, oneTimeQuantity) => {
  return monthlyQuantity + oneTimeQuantity;
});
const getMonthlyCashflowData = (0, _reselect.createSelector)([getCompletedBenefitCashflows, getBenefitsCashflow, getEdittedBenefitsCashflow], (completedBenefits, benefitsCashflow, edittedBenefitsCashflow) => {
  const mergedBenefitsCashflow = (0, _index.mergeRight)(benefitsCashflow, edittedBenefitsCashflow);
  return (0, _index.pipe)((0, _index.reduce)((a, v) => (0, _index.append)(mergedBenefitsCashflow[v], a), []), _index.transpose, (0, _index.map)(_index.sum))(completedBenefits);
});
const getPeakBenefits = (0, _reselect.createSelector)([getMonthlyCashflowData], monthlyCashflow => {
  const getPeak = (0, _index.reduce)(_index.max, 0);
  return getPeak(monthlyCashflow);
});
const getPeakBenefitsDisplay = (0, _reselect.createSelector)([getPeakBenefits, _sessionDetails.default.getNumberFormatObj], (value, numberFormatObj) => {
  return (0, _index.formatForDisplay)('money', numberFormatObj, value);
});

const getSuites = (state, props) => state.session.present.layout.suites;

const getSuiteOrder = (state, props) => state.session.present.layout.suitesOrder;

const getLayoutBenefits = (state, props) => state.session.present.layout.benefits;

const getOrderedSuiteAndBenefits = (0, _reselect.createSelector)([getSuites, getSuiteOrder, getLayoutBenefits], (suites, suiteOrder, layoutBenefits) => {
  let benefitOrder = [];

  for (let i = 0; i < suiteOrder.length; i++) {
    const suite = suiteOrder[i];
    benefitOrder.push(suites[suite].benefits);
  }

  return (0, _index.flatten)(benefitOrder);
});

const getTitle = (benefit, eNo, intl) => {
  var _benefit$values;

  let title = benefit !== null && benefit !== void 0 && (_benefit$values = benefit.values) !== null && _benefit$values !== void 0 && _benefit$values.userTitle ? benefit.values.userTitle : intl[`${eNo}.title.label`];

  if (benefit !== null && benefit !== void 0 && benefit.subHeading) {
    title += ' - ' + benefit.subHeading;
  }

  return title;
};

const getChartDataReport = (0, _reselect.createSelector)([getCompletedBenefits, getBenefits, _layout.default.getBenefits, _account.default.getEvaluators, getOrderedSuiteAndBenefits, _sessionDetails.default.getMonBenChartOrder, _intl.default.getMessages], (ids, benefits, layoutBenefits, accountEvaluators, orderedSuiteAndBenefits, chartOrder, intl) => {
  const chartData = id => {
    const eNo = layoutBenefits[id] && layoutBenefits[id].eNo;

    if (!(0, _index.isNil)(eNo)) {
      return {
        id,
        description: getTitle(benefits[id], eNo, intl),
        cost: benefits[id].result
      };
    } else {
      return {};
    }
  };

  let chartDataArray = (0, _index.map)(chartData, getCompletedMonthly(ids, layoutBenefits, accountEvaluators));
  const chartLimit = 20; // If number of benefits is greater than 20 the group into others

  if (chartDataArray.length > chartLimit) {
    const chartDataArrayOthers = (0, _index.pipe)((0, _index.sortBy)((0, _index.prop)('cost')), _index.reverse, (0, _index.splitAt)(chartLimit))(chartDataArray);

    const others = (x, y) => {
      return (0, _index.add)(x, y.cost);
    };

    const othersTotal = (0, _index.reduce)(others, 0, chartDataArrayOthers[1]);
    chartDataArrayOthers[0].push({
      description: 'others',
      cost: othersTotal,
      custom: false,
      subheading: undefined
    });
    chartDataArray = chartDataArrayOthers[0];
  } else {
    // Order the same as the summary table
    let orderedChartDataArray = [];

    for (let y = 0; y < orderedSuiteAndBenefits.length; y++) {
      for (let t = 0; t < chartDataArray.length; t++) {
        if (chartDataArray[t].id === orderedSuiteAndBenefits[y]) {
          orderedChartDataArray.push(chartDataArray[t]);
        }
      }
    }

    chartDataArray = (0, _index.reverse)(orderedChartDataArray);

    if (chartOrder) {
      // Default order
      if (chartOrder.field === 'suite' && chartOrder.direction === 'asc') {
        return chartDataArray;
      }

      if (chartOrder.field === 'suite' && chartOrder.direction === 'desc') {
        return (0, _index.reverse)(chartDataArray);
      } // Benefit Value


      if (chartOrder.field === 'benefit' && chartOrder.direction === 'asc') {
        return (0, _index.sortBy)((0, _index.prop)('cost'))(chartDataArray);
      }

      if (chartOrder.field === 'benefit' && chartOrder.direction === 'desc') {
        return (0, _index.reverse)((0, _index.sortBy)((0, _index.prop)('cost'))(chartDataArray));
      } // Alphabetical


      if (chartOrder.field === 'name' && chartOrder.direction === 'asc') {
        return (0, _index.sortBy)((0, _index.prop)('description'))(chartDataArray);
      }

      if (chartOrder.field === 'name' && chartOrder.direction === 'desc') {
        return (0, _index.reverse)((0, _index.sortBy)((0, _index.prop)('description'))(chartDataArray));
      }
    }
  }

  return chartDataArray;
});
const getOneTimeChartDataReport = (0, _reselect.createSelector)([getCompletedBenefits, getBenefits, _layout.default.getBenefits, _account.default.getEvaluators, getOrderedSuiteAndBenefits, _sessionDetails.default.getOTBChartOrder, _intl.default.getMessages], (ids, benefits, layoutBenefits, accountEvaluators, orderedSuiteAndBenefits, chartOrder, intl) => {
  const chartData = id => {
    const benefitObj = {
      id,
      description: getTitle(benefits[id], layoutBenefits[id].eNo, intl),
      cost: benefits[id].result
    };
    return benefitObj;
  };

  let chartDataArray = (0, _index.map)(chartData, getCompletedOneTime(ids, layoutBenefits, accountEvaluators)); // If number of benefits is greater than 20 the group into others

  const chartLimit = 20;

  if (chartDataArray.length > chartLimit) {
    const chartDataArrayOthers = (0, _index.pipe)((0, _index.sortBy)((0, _index.prop)('cost')), _index.reverse, (0, _index.splitAt)(chartLimit))(chartDataArray);

    const others = (x, y) => {
      return (0, _index.add)(x, y.cost);
    };

    const othersTotal = (0, _index.reduce)(others, 0, chartDataArrayOthers[1]);
    chartDataArrayOthers[0].push({
      description: 'others',
      cost: othersTotal,
      custom: 'other'
    });
    chartDataArray = chartDataArrayOthers[0];
  } else {
    let orderedChartDataArray = [];

    for (let y = 0; y < orderedSuiteAndBenefits.length; y++) {
      for (let t = 0; t < chartDataArray.length; t++) {
        if (chartDataArray[t].id === orderedSuiteAndBenefits[y]) {
          orderedChartDataArray.push(chartDataArray[t]);
        }
      }
    }

    chartDataArray = (0, _index.reverse)(orderedChartDataArray);

    if (chartOrder) {
      // Default order
      if (chartOrder.field === 'suite' && chartOrder.direction === 'asc') {
        return chartDataArray;
      }

      if (chartOrder.field === 'suite' && chartOrder.direction === 'desc') {
        return (0, _index.reverse)(chartDataArray);
      } // Benefit Value


      if (chartOrder.field === 'benefit' && chartOrder.direction === 'asc') {
        return (0, _index.sortBy)((0, _index.prop)('cost'))(chartDataArray);
      }

      if (chartOrder.field === 'benefit' && chartOrder.direction === 'desc') {
        return (0, _index.reverse)((0, _index.sortBy)((0, _index.prop)('cost'))(chartDataArray));
      } // Alphabetical


      if (chartOrder.field === 'name' && chartOrder.direction === 'asc') {
        return (0, _index.sortBy)((0, _index.prop)('description'))(chartDataArray);
      }

      if (chartOrder.field === 'name' && chartOrder.direction === 'desc') {
        return (0, _index.reverse)((0, _index.sortBy)((0, _index.prop)('description'))(chartDataArray));
      }
    } else {
      return chartDataArray;
    }
  }

  return chartDataArray;
});
const getTotalChartData = (0, _reselect.createSelector)([getCompletedBenefits, getBenefits, _layout.default.getBenefits, getOrderedSuiteAndBenefits, _sessionDetails.default.getTotalBenefitsChartOrder, _intl.default.getMessages, getBenefitsCashflow, getEdittedBenefitsCashflow], (ids, benefits, layoutBenefits, orderedSuiteAndBenefits, chartOrder, intl, benefitsCashflow, edittedBenefitsCashflow) => {
  const calculateCost = id => {
    const mergedBenefitsCashflow = (0, _index.mergeRight)(benefitsCashflow, edittedBenefitsCashflow);
    return (0, _index.sum)(mergedBenefitsCashflow[id]);
  };

  const chartData = id => {
    const benefitObj = {
      id,
      description: getTitle(benefits[id], layoutBenefits[id].eNo, intl),
      cost: calculateCost(id)
    };
    return benefitObj;
  };

  let chartDataArray = (0, _index.map)(chartData, ids);
  let orderedChartDataArray = [];

  for (let y = 0; y < orderedSuiteAndBenefits.length; y++) {
    for (let t = 0; t < chartDataArray.length; t++) {
      if (chartDataArray[t].id === orderedSuiteAndBenefits[y]) {
        orderedChartDataArray.push(chartDataArray[t]);
      }
    }
  }

  chartDataArray = (0, _index.reverse)(orderedChartDataArray); // Default order

  if (chartOrder.field === 'suite' && chartOrder.direction === 'asc') {
    return chartDataArray;
  }

  if (chartOrder.field === 'suite' && chartOrder.direction === 'desc') {
    return (0, _index.reverse)(chartDataArray);
  } // Benefit Value


  if (chartOrder.field === 'benefit' && chartOrder.direction === 'asc') {
    return (0, _index.sortBy)((0, _index.prop)('cost'))(chartDataArray);
  }

  if (chartOrder.field === 'benefit' && chartOrder.direction === 'desc') {
    return (0, _index.reverse)((0, _index.sortBy)((0, _index.prop)('cost'))(chartDataArray));
  } // Alphabetical


  if (chartOrder.field === 'name' && chartOrder.direction === 'asc') {
    return (0, _index.sortBy)((0, _index.prop)('description'))(chartDataArray);
  }

  if (chartOrder.field === 'name' && chartOrder.direction === 'desc') {
    return (0, _index.reverse)((0, _index.sortBy)((0, _index.prop)('description'))(chartDataArray));
  }

  return chartDataArray;
});
const getTotalBenefits = (0, _reselect.createSelector)([getMonthlyBenefits, getOneTimeBenefits, _session.default.getReviewPeriod], (monthlyBenefits, oneTimeBenefits, period) => {
  return monthlyBenefits * period + oneTimeBenefits;
});
const getTotalBenefitsDisplay = (0, _reselect.createSelector)([getTotalBenefits, _sessionDetails.default.getNumberFormatObj], (totalBenefits, numberFormatObj) => {
  return (0, _index.formatForDisplay)('money', numberFormatObj, totalBenefits);
});
const getNetBenefits = (0, _reselect.createSelector)([getTotalBenefits, _recurringCosts.default.getTotalCosts, _nonRecurringCosts.default.getTotalCosts], (totalBenefits, totalRecCosts, totalNonRecCosts) => {
  return (0, _index.subtract)(totalBenefits, (0, _index.add)(totalRecCosts, totalNonRecCosts));
});
const getNetBenefitsDisplay = (0, _reselect.createSelector)([getNetBenefits, _sessionDetails.default.getNumberFormatObj], (netBenefits, numberFormatObj) => {
  return (0, _index.formatForDisplay)('money', numberFormatObj, netBenefits);
});
const getCompletedOneTimeData = (0, _reselect.createSelector)([getBenefitsCashflow, getEdittedBenefitsCashflow, getCompletedBenefits, _layout.default.getBenefits, _account.default.getEvaluators], (benefitsCashflow, edittedBenefitsCashflow, completedBenefits, layoutBenefits, accountEvaluators) => {
  const mergedBenefitsCashflow = (0, _index.mergeRight)(benefitsCashflow, edittedBenefitsCashflow);
  const isOneTime = (0, _index.pipe)((0, _index.contains)(_index.__, getCompletedOneTime(completedBenefits, layoutBenefits, accountEvaluators)));
  return (0, _index.filter)(isOneTime, (0, _index.keys)(mergedBenefitsCashflow));
});
const getCompletedMonthlyData = (0, _reselect.createSelector)([getBenefitsCashflow, getEdittedBenefitsCashflow, getCompletedBenefits, _layout.default.getBenefits, _account.default.getEvaluators], (benefitsCashflow, edittedBenefitsCashflow, completedBenefits, layoutBenefits, accountEvaluators) => {
  const mergedBenefitsCashflow = (0, _index.mergeRight)(benefitsCashflow, edittedBenefitsCashflow);
  const isMonthly = (0, _index.pipe)((0, _index.contains)(_index.__, getCompletedMonthly(completedBenefits, layoutBenefits, accountEvaluators)));
  return (0, _index.filter)(isMonthly, (0, _index.keys)(mergedBenefitsCashflow));
});
const getShowBenefits = (0, _reselect.createSelector)([getCompletedBenefits], completedBenefits => completedBenefits.length > 0 ? true : false);
const getAnyStartMonthsUnlinked = (0, _reselect.createSelector)([getIds, getBenefits], (ids, benefits) => {
  let anyUnlinked = false;

  for (let x = 0; x < ids.length; x++) {
    if (benefits[ids[x]].startMonthLinked === false) {
      anyUnlinked = true;
      break;
    }
  }

  return anyUnlinked;
});
const getAnyEndMonthsUnlinked = (0, _reselect.createSelector)([getIds, getBenefits], (ids, benefits) => {
  let anyUnlinked = false;

  for (let x = 0; x < ids.length; x++) {
    if (benefits[ids[x]].endMonthLinked === false) {
      anyUnlinked = true;
      break;
    }
  }

  return anyUnlinked;
});
const getCurrentBenefit = (0, _reselect.createSelector)([(state, props) => (0, _index.is)(Object, props) ? props.benefitId : props, _benefitsSearch.default.getComplete], (benefitId, benefits) => (0, _index.find)((0, _index.propEq)('id', benefitId))(benefits));
const getOtherBenefit = (0, _reselect.createSelector)([_benefitsSearch.default.getComplete, getCurrentBenefit], (benefits, currentBenefit) => (0, _index.without)([currentBenefit], benefits));
const getOrderedBenefits = (0, _reselect.createSelector)([(state, props) => props.benefitId, getOtherBenefit, getCurrentBenefit], (benefitId, otherBenefits, currentBenefit) => (0, _index.isNil)(currentBenefit) ? null : (0, _index.prepend)(currentBenefit, otherBenefits));
const selectors = {
  getBenefitsNode,
  getBenefits,
  getIds,
  getCompletedBenefits,
  getCompletedBenefitCashflows,
  getMonthlyBenefits,
  getMonthlyBenefitsDisplay,
  getOneTimeBenefits,
  getOneTimeBenefitsDisplay,
  getPeakBenefits,
  getPeakBenefitsDisplay,
  getMonthlyQuantity,
  getOneTimeQuantity,
  getTotalQuantity,
  getTotalChartData,
  getMonthlyCashflowData,
  getTotalBenefits,
  getTotalBenefitsDisplay,
  getNetBenefits,
  getNetBenefitsDisplay,
  getCompletedOneTimeData,
  getCompletedMonthlyData,
  getChartDataReport,
  getOneTimeChartDataReport,
  getShowBenefits,
  getAnyStartMonthsUnlinked,
  getAnyEndMonthsUnlinked,
  getCurrentBenefit,
  getOtherBenefit,
  getOrderedBenefits
};
var _default = selectors;
exports.default = _default;