import { useMutation } from 'react-query';
import fetchAuth from 'utils/fetchAuth';
import firebase from 'firebase/app';

const useUpdateUser = config => {
  return useMutation(async body => {
    const response = await fetchAuth({
      url: 'user/put',
      config: {
        method: 'PUT',
        body: JSON.stringify(body),
      },
    });

    await firebase.auth().currentUser.updateProfile({ displayName: response.payload.name });
    return response;
  }, config);
};

export default useUpdateUser;
