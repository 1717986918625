import styled from 'styled-components';
import applyTheme from '../../styles/apply-theme';

export const PopperBox = styled.div`
  ${({ theme, tipStyle, margin, zIndex }) => theme.popper.box({ tipStyle, margin, zIndex })};
  ${props => props.popperStyle};
`;

const popper = applyTheme('popper');

export const Arrow = popper('arrow');
export const ReferenceContainer = popper('referenceContainer');

// export const Arrow = styled.div`
//   ${(p) => p.theme.popper.referenceContainer(p.tipStyle)};
// `;
