/* @flow */
import { combineReducers } from 'redux';
import { simpleAction, pluginsInitialState } from '@sharkfinesse/sfl-lib';
import salesforce from 'redux/modules/plugins/salesforce-root';

const namespace = (name: string): string => `@@plugins/${name}`;

// types
export const types = {
  LOAD: namespace('LOAD'),
  LOADED: namespace('LOADED'),
  SYNC: namespace('SYNC'),
};

const plugins = combineReducers({
  salesforce,
});

export const initialState = pluginsInitialState;

const PluginsReducer =
  () =>
  (state = initialState, action) => {
    switch (action.type) {
      case types.SYNC:
        //loaded state from firestore
        if (!action.payload) {
          return state;
        } else {
          return plugins(action.payload, action);
        }
      default:
        return plugins(state, action);
    }
  };

//Action Creators
const load = simpleAction(types.LOAD);
const sync = simpleAction(types.SYNC);
const loaded = simpleAction(types.LOADED);

export const actionCreators = {
  load,
  sync,
  loaded,
};

export default PluginsReducer();
