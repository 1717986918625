"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.costOfDelay = void 0;

var _fp = require("./fp");

var _math = require("./math");

const costOfDelay = (0, _fp.pipe)(_fp.multiply, (0, _math.decreaseByFloatingFixedPointFactor)(1));
exports.costOfDelay = costOfDelay;