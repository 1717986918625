import {
  generateShortId,
  append,
  insert,
  isNil,
  layoutSelectors,
  layoutDesignerSelectors,
} from '@sharkfinesse/sfl-lib';
import { put, call, select, all } from 'redux-saga/effects';
import { actionCreators as layoutActionCreators } from 'redux/modules/layout';
import { actionCreators as evaluatorActionCreators } from 'redux/modules/evaluator';
import { goToCurrent } from '../../benefit/goToCurrent';

export function addBenefitToLayout(state, action) {
  const { benefitId, position, suiteId } = action.payload;

  const id = benefitId ? benefitId : generateShortId();

  return {
    id: id,
    layout: {
      ...state,
      benefits: {
        ...state.benefits,
        [id]: {
          id,
          eNo: action.payload.eNo,
        },
      },
      suites: {
        ...state.suites,
        [suiteId]: {
          ...state.suites[suiteId],
          benefits: isNil(position)
            ? append(id, state.suites[suiteId].benefits)
            : insert(position, id, state.suites[suiteId].benefits),
        },
      },
    },
  };
}
function* addBenefit(action) {
  const suiteId = isNil(action.payload.suiteId)
    ? yield select(layoutDesignerSelectors.getSelectedSuiteId)
    : action.payload.suiteId;
  yield put(layoutActionCreators.insertingBenefit());
  const layout = yield select(layoutSelectors.getLayout);
  const newLayout = yield call(addBenefitToLayout, layout, {
    ...action,
    payload: { ...action.payload, suiteId },
  });
  yield put(layoutActionCreators.insertBenefit({ layout: newLayout.layout, id: newLayout.id }));
  if (action.payload.push) {
    yield all([
      put(evaluatorActionCreators.updateCurrentBenefit(newLayout.id)),
      call(goToCurrent, { id: newLayout.id }),
    ]);
  }
  yield put(layoutActionCreators.insertingBenefitComplete({ id: newLayout.id }));
}

export default addBenefit;
