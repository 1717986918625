/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

let IconNotes = (props): React.Element<any> => (
  <Icon {...props}>
    <rect x="6" y="9" fill="inherit" width="6" height="1" />
    <rect x="6" y="11" fill="inherit" width="10" height="1" />
    <rect x="6" y="13" fill="inherit" width="4" height="1" />
    <rect x="11" y="13" fill="inherit" width="1" height="1" />
    <rect x="13" y="15" fill="inherit" width="1" height="1" />
    <rect x="13" y="13" fill="inherit" width="2" height="1" />
    <rect x="6" y="15" fill="inherit" width="6" height="1" />
    <rect x="6" y="17" fill="inherit" width="9" height="1" />
    <path
      fill="currentColor"
      d="M19,5l-1-1h-2l-1-1h-1l-1-1h-3L9,3H8L7,4H5L4,5v16l1,1h5h3h5l1-1 M8,4h2V3h3v1h2v1H8V4z M18,21h-5h-3H5V5h2l1,1h7l1-1h2"
    />
  </Icon>
);

export default memo(IconNotes);
