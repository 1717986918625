/* @flow */
/* eslint-disable no-confusing-arrow, no-nested-ternary  */

import React from 'react';
import styled from 'styled-components';
import Tooltip from '../tooltip';
import { NavLink } from 'react-router-dom';

const StyledLink = styled.div`
  ${p => p.theme.hyperlink.base()};
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`;

const Hyperlink = ({
  tooltip,
  children,
  to,
  onClick,
  href,
  tooltipConfig,
  toolTipContainerProps,
  tooltipProps,
  delayShow,
  ...props
}) => {
  let hyperlinkComponent = <StyledLink onClick={onClick}>{children}</StyledLink>;

  if (to) {
    hyperlinkComponent = <StyledNavLink to={to}>{hyperlinkComponent}</StyledNavLink>;
  }
  if (href) {
    hyperlinkComponent = (
      <StyledLink as="a" href={href} {...props}>
        {children}
      </StyledLink>
    );
  }

  if (tooltip) {
    hyperlinkComponent = (
      <Tooltip
        tooltip={tooltip}
        tooltipConfig={tooltipConfig}
        delayShow={delayShow}
        toolTipContainerProps={toolTipContainerProps}
        tooltipProps={tooltipProps}
      >
        {hyperlinkComponent}
      </Tooltip>
    );
  }

  return <span {...props}>{hyperlinkComponent}</span>;
};

export default Hyperlink;
