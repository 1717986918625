/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { useSpring } from 'react-spring';
import {
  SVG,
  CircleBg,
  Circle,
  PercentLabel,
  PercentSymbol,
  PercentWrapper,
  LabelsWrapper,
  StepProgress,
  Container,
} from './styled';

const ProgressBar = ({
  size = 140,
  progress,
  strokeWidth = 10,
  fontColor = '#333333',
  backgroundColor = '#EBEBEB',
  highlightColor,
  current,
  total,
}) => {
  const [offset, setOffset] = useState(0);
  const circleRef = useRef(null);

  const parseProgress = parseInt(progress);

  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;
  const viewBox = `0 0 ${size} ${size}`;

  useEffect(() => {
    const progressOffset = ((100 - parseProgress) / 100) * circumference;
    setOffset(progressOffset);

    //circleRef.current.style = 'transition: stroke-dashoffset 850ms ease-in-out';
  }, [setOffset, parseProgress, circumference, offset]);

  const percentProps = useSpring({
    parseProgress,
    config: {
      duration: 500,
    },
  });
  const barProps = useSpring({
    offset,
  });

  return (
    <Container $size={size}>
      <SVG width={size} height={size} viewBox={viewBox}>
        <CircleBg
          stroke={backgroundColor}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth - 2}
        />
        <Circle
          ref={circleRef}
          stroke={highlightColor}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={barProps.offset.to((offset) => offset)}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
        />
      </SVG>
      <LabelsWrapper>
        <PercentWrapper>
          <PercentLabel>
            {percentProps.parseProgress.to((parseProgress) => Math.floor(parseProgress))}
          </PercentLabel>
          <PercentSymbol>%</PercentSymbol>
        </PercentWrapper>
        <StepProgress>
          {current}/{total}
        </StepProgress>
      </LabelsWrapper>
    </Container>
  );
};

export default ProgressBar;
