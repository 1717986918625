"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const initialState = {
  benefits: true,
  benefitsMonthly: true,
  benefitsOnetime: true,
  costs: true,
  nonRecurringCosts: true,
  recurringCosts: true,
  roi: true,
  npv: true,
  irr: true,
  payback: true,
  costOf: true,
  tender: true,
  doNothing: true,
  delay: true,
  cashOnlySummary: true,
  cashflows: true,
  cashflowsRoi: true,
  npvCashflow: true,
  irrCashflow: true,
  charting: true,
  chartingBenefits: true,
  benefitsMonthlyChart: true,
  benefitsOnetimeChart: true,
  chartingCosts: true,
  nonRecurringCostsChart: true,
  recurringCostsChart: true,
  chartingRoi: true,
  npvChart: true,
  irrChart: true,
  paybackChart: true,
  chartingCostOf: true,
  tenderChart: true,
  delayChart: true,
  coverPage: true
};
var _default = initialState;
exports.default = _default;