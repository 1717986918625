// @flow
import styled from 'styled-components';
import { animated } from 'react-spring';
import applyTheme from '../../styles/apply-theme';

const tabs = applyTheme('tabs');
export const ContentContainer = tabs('contentContainer', styled(animated.div));
export const TabWrapper = tabs('tabWrapper');
export const TabPanel = tabs('tabPanel');
export const ContentWrapper = tabs('contentWrapper');
export const Component = tabs('tabs');
export const Scroller = tabs('scroller');
export const TabList = tabs('tabList');
export const ScrollButton = tabs('scrollButton');
export const Tab = tabs('tab', styled.button);
export const TabLabel = tabs('tabLabel', styled.span);
export const VerticalWrapper = tabs('verticalWrapper');
export const VerticalTabs = tabs('verticalTabs');
export const VerticalContent = tabs('verticalContent');
export const Indicator = tabs('indicator', styled.span);
