"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reselect = require("reselect");

var _reReselect = _interopRequireDefault(require("re-reselect"));

var _index = require("../../index");

var _sessionDetails = _interopRequireDefault(require("./session-details"));

var _layout = _interopRequireDefault(require("./layout"));

var _account = _interopRequireDefault(require("./account"));

var _intl = _interopRequireDefault(require("./intl"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* eslint-disable no-unused-vars  */
const getBenefits = state => {
  var _state$session$presen;

  return (_state$session$presen = state.session.present.questionnaire) === null || _state$session$presen === void 0 ? void 0 : _state$session$presen.benefits;
};

const getShowNotes = (state, props) => props !== null && props !== void 0 && props.showNotes ? true : state.session.present.questionnaire.showNotes;

const getShowValues = (state, props) => props !== null && props !== void 0 && props.showValues ? true : state.session.present.questionnaire.showValues;

const getShowInfo = state => state.session.present.questionnaire.showInfo;

const getNotify = state => state.session.present.questionnaire.notify;

const getExpiry = state => state.session.present.questionnaire.expiry;

const getMessage = state => state.session.present.questionnaire.message;

const getCommonNotes = state => state.session.present.questionnaire.commonNotes;

const getEmails = state => state.session.present.questionnaire.emails;

const getLanguage = state => state.session.present.questionnaire.language;

const getNumberFormatObj = state => {
  var _state$questionnaire;

  return state === null || state === void 0 ? void 0 : (_state$questionnaire = state.questionnaire) === null || _state$questionnaire === void 0 ? void 0 : _state$questionnaire.numberFormatObj;
};

const getLayout = state => {
  var _state$questionnaire2;

  return state === null || state === void 0 ? void 0 : (_state$questionnaire2 = state.questionnaire) === null || _state$questionnaire2 === void 0 ? void 0 : _state$questionnaire2.layout;
};

const getLoaded = state => {
  var _state$questionnaire3;

  return state === null || state === void 0 ? void 0 : (_state$questionnaire3 = state.questionnaire) === null || _state$questionnaire3 === void 0 ? void 0 : _state$questionnaire3.loaded;
};

const getBenefitData = state => {
  var _state$questionnaire4;

  return state === null || state === void 0 ? void 0 : (_state$questionnaire4 = state.questionnaire) === null || _state$questionnaire4 === void 0 ? void 0 : _state$questionnaire4.benefitData;
};

const getSubmittedBenefitData = state => {
  var _state$questionnaire5;

  return state === null || state === void 0 ? void 0 : (_state$questionnaire5 = state.questionnaire) === null || _state$questionnaire5 === void 0 ? void 0 : _state$questionnaire5.submittedBenefitData;
};

const getSubmittedBenefit = (state, {
  benefitId
}) => (0, _index.path)(['questionnaire', 'submittedBenefitData', benefitId], state);

const getSuiteId = (state, props) => props.suiteId;

const getBenefitId = (state, props) => props.benefitId;

const getSuite = (0, _reselect.createSelector)([getSuiteId, getLayout], (suiteId, layout) => layout ? (0, _index.filter)((0, _index.propEq)('id', suiteId), layout)[0] : null);
const getBenefit = (0, _reselect.createSelector)([getBenefitId, getSuite], (benefitId, suite) => suite ? (0, _index.filter)((0, _index.propEq)('id', benefitId), suite.benefits)[0] : null);
const getNext = (0, _reselect.createSelector)([getSuite, getBenefit, getLayout], (suite, benefit, layout) => {
  if (suite && benefit && layout) {
    if (suite.benefits.length === benefit.index + 1) {
      if (layout.length === suite.index + 1) {
        return 'end';
      }

      const nextSuite = layout[suite.index + 1];
      return {
        suiteId: nextSuite.id,
        benefitId: nextSuite.benefits[0].id
      };
    }

    return {
      suiteId: suite.id,
      benefitId: suite.benefits[benefit.index + 1].id
    };
  }

  return null;
});
const getPrevious = (0, _reselect.createSelector)([getSuite, getBenefit, getLayout], (suite, benefit, layout) => {
  if (suite && benefit && layout) {
    if (benefit.index === 0) {
      if (suite.index === 0) {
        return 'start';
      }

      const prevSuite = layout[suite.index - 1];
      return {
        suiteId: prevSuite.id,
        benefitId: prevSuite.benefits[prevSuite.benefits.length - 1].id
      };
    }

    return {
      suiteId: suite.id,
      benefitId: suite.benefits[benefit.index - 1].id
    };
  }

  return null;
});

const removeWildcard = suite => {
  if (suite.value !== '*') return suite;
};

const isSuiteEmpty = suite => {
  if (!(0, _index.isEmpty)(suite.benefits)) return suite;
};

const getEvaluatorData = (state, benefitId) => (0, _index.path)(['session', 'present', 'benefits', 'data', benefitId], state);

const getVisibleList = (state, props) => state.session.present.suiteList.visible || [];

const getShowAllSuites = (state, props) => props === null || props === void 0 ? void 0 : props.allSuites;

const getQuestionnaire = (0, _reselect.createSelector)([state => state, _sessionDetails.default.getNumberFormatObj, getBenefits, getVisibleList, _account.default.getEvaluators, getShowValues, getShowNotes, _layout.default.getSuiteSelectList, getShowAllSuites], (state, numberFormatObj, selectedData, visibleList, evaluators, showValues, showNotes, suiteSelectList, showAllSuites) => {
  const suitesToMap = showAllSuites ? suiteSelectList : (0, _index.filter)(removeWildcard)(visibleList);

  const isBenefitSelected = benefitId => {
    if ((0, _index.includes)(benefitId, selectedData)) return benefitId;
  };

  const benefitsData = {};
  const defaultSessionValueIds = [];
  const mapIndexed = (0, _index.addIndex)(_index.map);

  const addIndexToObj = (val, idx) => ({
    index: idx,
    ...val
  });

  const newLayout = (0, _index.pipe)((0, _index.map)(({
    value: id
  }, i) => {
    const {
      customName,
      name,
      categoryId,
      benefits
    } = { ..._layout.default.getSuite(state, {
        suiteId: id
      })
    };
    return {
      id,
      name,
      customName,
      categoryId,
      benefits: (0, _index.pipe)((0, _index.filter)(isBenefitSelected), (0, _index.reduce)((accum, benefitId) => {
        if ((0, _index.includes)(benefitId, selectedData)) {
          var _layout$schema;

          const eNo = _layout.default.getBenefitENo(state, {
            benefitId
          });

          const benefitData = getEvaluatorData(state, benefitId);
          if (benefitData) benefitsData[benefitId] = benefitData;
          const {
            values,
            subHeading,
            notes
          } = { ...benefitData
          };
          const {
            schema,
            uiSchema
          } = { ...evaluators[eNo]
          };
          const inputList = Object.keys(uiSchema).reduce((keyArray, key) => {
            const schemaItem = uiSchema[key];
            const widget = schemaItem['ui:widget'];

            if (widget && (0, _index.contains)('Input', widget)) {
              if (showValues === 'hide') {
                if ((0, _index.isNil)(values === null || values === void 0 ? void 0 : values[key])) {
                  keyArray.push(key);
                }
              } else {
                keyArray.push(key);
              }
            }

            return keyArray;
          }, []);
          if ((0, _index.isEmpty)(inputList) && showValues === 'hide' && showNotes === 'hide') return accum;
          const uiSchemaOrder = uiSchema['ui:order'].filter(x => inputList.includes(x));
          const layout = {
            id: benefitId,
            ...(subHeading && {
              subHeading
            }),
            schema: { ...schema,
              properties: (0, _index.pick)(inputList, schema.properties)
            },
            uiSchema: { ...(0, _index.pick)([...inputList], uiSchema),
              'ui:order': uiSchemaOrder
            },
            inputList,
            eNo,
            ...(values && {
              values
            }),
            uiSchemaOrder,
            ...(notes && (showNotes === true || showNotes === 'hide') && {
              notes
            })
          };

          if (layout !== null && layout !== void 0 && (_layout$schema = layout.schema) !== null && _layout$schema !== void 0 && _layout$schema.properties) {
            (0, _index.map)(field => {
              if (field !== null && field !== void 0 && field.defaultSessionValueId) {
                const {
                  defaultSessionValueId: id,
                  decimals,
                  measurement,
                  unit,
                  multiYear
                } = field;
                defaultSessionValueIds.push({
                  measurement,
                  decimals,
                  id,
                  unit,
                  multiYear
                });
              }
            })(layout.schema.properties);
          }

          return (0, _index.append)(layout, accum);
        } else {
          return accum;
        }
      }, []), mapIndexed(addIndexToObj))(benefits)
    };
  }), (0, _index.filter)(isSuiteEmpty), mapIndexed(addIndexToObj))(suitesToMap);
  return {
    newLayout,
    benefitsData,
    defaultSessionValueIds: (0, _index.uniqBy)((0, _index.prop)('id'), defaultSessionValueIds),
    numberFormatObj
  };
});

const getId = state => {
  var _state$questionnaire6;

  return state === null || state === void 0 ? void 0 : (_state$questionnaire6 = state.questionnaire) === null || _state$questionnaire6 === void 0 ? void 0 : _state$questionnaire6.id;
};

const getDefaultSessionValues = state => {
  var _state$questionnaire7;

  return state === null || state === void 0 ? void 0 : (_state$questionnaire7 = state.questionnaire) === null || _state$questionnaire7 === void 0 ? void 0 : _state$questionnaire7.defaultSessionValues;
};

const getSubmittedDefaultSessionValues = state => {
  var _state$questionnaire8;

  return state === null || state === void 0 ? void 0 : (_state$questionnaire8 = state.questionnaire) === null || _state$questionnaire8 === void 0 ? void 0 : _state$questionnaire8.submittedDefaultSessionValues;
};

const getDefaultSessionValuesSchema = state => {
  var _state$questionnaire9;

  return state === null || state === void 0 ? void 0 : (_state$questionnaire9 = state.questionnaire) === null || _state$questionnaire9 === void 0 ? void 0 : _state$questionnaire9.defaultSessionValuesSchema;
};

const getShowNotesConfig = state => {
  var _state$questionnaire10, _state$questionnaire11;

  return state === null || state === void 0 ? void 0 : (_state$questionnaire10 = state.questionnaire) === null || _state$questionnaire10 === void 0 ? void 0 : (_state$questionnaire11 = _state$questionnaire10.config) === null || _state$questionnaire11 === void 0 ? void 0 : _state$questionnaire11.showNotes;
};

const getShowValuesConfig = state => {
  var _state$questionnaire12, _state$questionnaire13;

  return state === null || state === void 0 ? void 0 : (_state$questionnaire12 = state.questionnaire) === null || _state$questionnaire12 === void 0 ? void 0 : (_state$questionnaire13 = _state$questionnaire12.config) === null || _state$questionnaire13 === void 0 ? void 0 : _state$questionnaire13.showValues;
};

const getShowInfoConfig = state => {
  var _state$questionnaire14, _state$questionnaire15;

  return state === null || state === void 0 ? void 0 : (_state$questionnaire14 = state.questionnaire) === null || _state$questionnaire14 === void 0 ? void 0 : (_state$questionnaire15 = _state$questionnaire14.config) === null || _state$questionnaire15 === void 0 ? void 0 : _state$questionnaire15.showInfo;
};

const getExpiryConfig = state => {
  var _state$questionnaire16, _state$questionnaire17;

  return state === null || state === void 0 ? void 0 : (_state$questionnaire16 = state.questionnaire) === null || _state$questionnaire16 === void 0 ? void 0 : (_state$questionnaire17 = _state$questionnaire16.config) === null || _state$questionnaire17 === void 0 ? void 0 : _state$questionnaire17.expiry;
};

const getCommonNotesConfig = state => {
  var _state$questionnaire18, _state$questionnaire19;

  return state === null || state === void 0 ? void 0 : (_state$questionnaire18 = state.questionnaire) === null || _state$questionnaire18 === void 0 ? void 0 : (_state$questionnaire19 = _state$questionnaire18.config) === null || _state$questionnaire19 === void 0 ? void 0 : _state$questionnaire19.commonNotes;
};

const getName = state => {
  var _state$questionnaire20, _state$questionnaire21;

  return state === null || state === void 0 ? void 0 : (_state$questionnaire20 = state.questionnaire) === null || _state$questionnaire20 === void 0 ? void 0 : (_state$questionnaire21 = _state$questionnaire20.config) === null || _state$questionnaire21 === void 0 ? void 0 : _state$questionnaire21.name;
};

const getCustomer = state => {
  var _state$questionnaire22, _state$questionnaire23;

  return state === null || state === void 0 ? void 0 : (_state$questionnaire22 = state.questionnaire) === null || _state$questionnaire22 === void 0 ? void 0 : (_state$questionnaire23 = _state$questionnaire22.config) === null || _state$questionnaire23 === void 0 ? void 0 : _state$questionnaire23.customer;
};

const getMessageConfig = state => {
  var _state$questionnaire24, _state$questionnaire25;

  return state === null || state === void 0 ? void 0 : (_state$questionnaire24 = state.questionnaire) === null || _state$questionnaire24 === void 0 ? void 0 : (_state$questionnaire25 = _state$questionnaire24.config) === null || _state$questionnaire25 === void 0 ? void 0 : _state$questionnaire25.message;
};

const getPolicyId = state => {
  var _state$questionnaire26, _state$questionnaire27;

  return state === null || state === void 0 ? void 0 : (_state$questionnaire26 = state.questionnaire) === null || _state$questionnaire26 === void 0 ? void 0 : (_state$questionnaire27 = _state$questionnaire26.config) === null || _state$questionnaire27 === void 0 ? void 0 : _state$questionnaire27.policyId;
};

const getSubmitted = state => {
  var _state$questionnaire28;

  return state === null || state === void 0 ? void 0 : (_state$questionnaire28 = state.questionnaire) === null || _state$questionnaire28 === void 0 ? void 0 : _state$questionnaire28.submitted;
};

const getUpdated = state => {
  var _state$questionnaire29;

  return state === null || state === void 0 ? void 0 : (_state$questionnaire29 = state.questionnaire) === null || _state$questionnaire29 === void 0 ? void 0 : _state$questionnaire29.updated;
};

const getTermsAccepted = state => {
  var _state$questionnaire30;

  return state === null || state === void 0 ? void 0 : (_state$questionnaire30 = state.questionnaire) === null || _state$questionnaire30 === void 0 ? void 0 : _state$questionnaire30.termsAccepted;
};

const getSessionQuestionnaireLayout = (0, _reselect.createSelector)([state => state, getVisibleList], (state, visibleSuites) => {
  if (getVisibleList.length > 0 && _layout.default.getSuites.length > 0) {
    const removeWildcard = suite => {
      if (suite.value !== '*') return suite;
    };

    const suitesToMap = (0, _index.filter)(removeWildcard)(visibleSuites);
    return suitesToMap.map(({
      value: id
    }, i) => getQuestionnaireSuite(state, id));
  }
});
const getQuestionnaireSuite = (0, _reReselect.default)([state => state, (state, id) => id, _layout.default.getSuites, _intl.default.getMessages, _layout.default.getBenefits, _layout.default.getBenefitsData, getBenefits], (state, id, suites, intl, allBenefits, benefitsData, selectedData) => {
  const {
    customName,
    name,
    categoryId,
    benefits
  } = { ...suites[id]
  };
  const benefitIds = [];
  const selectedIds = [];
  return {
    id,
    name: customName ? customName : !(0, _index.isNil)(intl[`category.${categoryId}.label`]) ? intl[`category.${categoryId}.label`] : name,
    benefits: benefits === null || benefits === void 0 ? void 0 : benefits.map((benefitId, i) => {
      const {
        eNo
      } = allBenefits[benefitId];
      const {
        values,
        subHeading,
        notes
      } = { ...benefitsData[benefitId]
      };
      const selected = (0, _index.includes)(benefitId, selectedData);
      if (selected) selectedIds.push(benefitId);
      benefitIds.push(benefitId);
      return {
        id: benefitId,
        name: values !== null && values !== void 0 && values.userTitle ? values.userTitle : intl[`${eNo}.title.label`],
        subHeading,
        selected,
        ...(values && {
          values
        }),
        ...(notes && {
          notes
        })
      };
    }),
    benefitIds,
    selectedIds
  };
})((state, id) => id);
const getUsedBenefits = (0, _reselect.createSelector)([getLayout, getSubmittedBenefitData], (layout, benefitData) => {
  return layout.reduce((suiteArray, {
    id: currentSuite,
    name,
    categoryId,
    customName,
    benefits,
    index
  }, i) => {
    const suiteBenefits = benefits.reduce((benefitsArray, {
      id: currentBenefit,
      name,
      subHeading,
      schema,
      eNo,
      uiSchema,
      uiSchemaOrder,
      index,
      values,
      inputList
    }) => {
      if (benefitData !== null && benefitData !== void 0 && benefitData[currentBenefit]) {
        var _benefitData$currentB, _benefitData$currentB2, _benefitData$currentB3;

        if (benefitData !== null && benefitData !== void 0 && (_benefitData$currentB = benefitData[currentBenefit]) !== null && _benefitData$currentB !== void 0 && _benefitData$currentB.values) {
          const result = (0, _index.keys)(benefitData[currentBenefit].values).filter(x => inputList.includes(x));
          if ((0, _index.isEmpty)(result)) return benefitsArray;
        }

        return [...benefitsArray, {
          benefitId: currentBenefit,
          name,
          subHeading,
          notes: benefitData === null || benefitData === void 0 ? void 0 : (_benefitData$currentB2 = benefitData[currentBenefit]) === null || _benefitData$currentB2 === void 0 ? void 0 : _benefitData$currentB2.notes,
          values: benefitData === null || benefitData === void 0 ? void 0 : (_benefitData$currentB3 = benefitData[currentBenefit]) === null || _benefitData$currentB3 === void 0 ? void 0 : _benefitData$currentB3.values,
          userTitle: values === null || values === void 0 ? void 0 : values.userTitle,
          schema,
          uiSchema,
          uiSchemaOrder,
          eNo
        }];
      }

      return benefitsArray;
    }, []);

    if (suiteBenefits.length > 0) {
      return [...suiteArray, {
        suiteId: currentSuite,
        name,
        benefits: suiteBenefits,
        categoryId,
        customName
      }];
    }

    return suiteArray;
  }, []);
});
const selectors = {
  getBenefits,
  getShowNotes,
  getShowValues,
  getShowInfo,
  getNotify,
  getExpiry,
  getMessage,
  getCommonNotes,
  getLanguage,
  getEmails,
  getNumberFormatObj,
  getLayout,
  getLoaded,
  getSuite,
  getBenefit,
  getBenefitData,
  getSubmittedBenefitData,
  getSubmittedBenefit,
  getId,
  getNext,
  getPrevious,
  getDefaultSessionValues,
  getSubmittedDefaultSessionValues,
  getDefaultSessionValuesSchema,
  getShowNotesConfig,
  getShowValuesConfig,
  getShowInfoConfig,
  getExpiryConfig,
  getCommonNotesConfig,
  getName,
  getCustomer,
  getMessageConfig,
  getPolicyId,
  getSubmitted,
  getUpdated,
  getTermsAccepted,
  getQuestionnaire,
  getQuestionnaireSuite,
  getSessionQuestionnaireLayout,
  getVisibleList,
  getUsedBenefits
};
var _default = selectors;
exports.default = _default;