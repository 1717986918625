import { select, put } from 'redux-saga/effects';
import { actionCreators as layoutDesignerActionCreators } from 'redux/modules/layoutDesigner';
import { isEmpty, layoutDesignerSelectors, layoutSelectors } from '@sharkfinesse/sfl-lib';

function* setSelectedSuite() {
  const suiteId = yield select(layoutDesignerSelectors.getSelectedSuiteId);
  if (isEmpty(suiteId)) {
    const suiteOrder = yield select(layoutSelectors.getSuitesOrder);
    yield put(layoutDesignerActionCreators.updateSelectedSuite(suiteOrder[0]));
  }
}

export default setSelectedSuite;
