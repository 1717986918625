// @flow
import React, { useRef, useState } from 'react';
import Search from '../search';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import { useInfiniteQuery } from 'react-query';
import JustifiedGrid from 'react-justified-grid';
import LoaderRing from '../loaders/ring';
import { isEmpty } from '@sharkfinesse/sfl-lib';
import { Link as SidebarLink } from '../sidebar';
import { FormattedMessage, useIntl } from 'react-intl';
import Scroll from '../scroll';
import Image from './image';
import { Attribution } from './styled';

import 'styled-components/macro';

type Props = {
  fetchItems: Function,
  cacheKey: String,
  convertSchema: Function,
  searchPlaceholder: Element<*> | String,
  onClick: Function,
  showSearch: Boolean,
  attributionText: String,
  attributionUrl: String,
  deleteFunction: Function,
  userId: String,
};

const Gallery = ({
  fetchItems,
  cacheKey,
  convertSchema,
  searchPlaceholder,
  onClick,
  showSearch,
  attributionText,
  attributionUrl,
  deleteFunction,
  userId,
}: Props) => {
  const [query, setQuery] = useState('');
  const [selected, setSelected] = useState();
  const intl = useIntl();

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery(
    [cacheKey, { query }],
    ({ pageParam }) => {
      return fetchItems({ query, pageParam });
    },
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.nextPage;
      },
      refetchOnWindowFocus: false,
    }
  );
  const loadMoreButtonRef = useRef();
  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  const images = [];
  if (data) {
    data.pages.map(group => {
      return group.items.map(item => {
        return convertSchema ? images.push(convertSchema(item)) : images.push(item);
      });
    });
  }

  return (
    <>
      {showSearch && (
        <div
          css={`
            margin-right: 12px;
            margin-bottom: 12px;
          `}
        >
          <Attribution>
            <SidebarLink href={attributionUrl} target="_blank" rel="noopener noreferrer">
              {attributionText}
            </SidebarLink>
          </Attribution>
          <Search
            placeholder={
              searchPlaceholder
                ? searchPlaceholder
                : intl.formatMessage({
                    id: 'gallery.search.placehoder',
                    defaultMessage: 'Search',
                  })
            }
            searchValue={query}
            onSearch={search => {
              setQuery(search);
            }}
          />
        </div>
      )}
      <Scroll noScrollX>
        <div style={{ marginRight: '12px' }}>
          <JustifiedGrid
            gutter={6}
            images={images}
            maxRowHeight={150}
            rows={undefined}
            showIncompleteRow={true}
            style={{}}
          >
            {processedImages => {
              return (
                <>
                  {processedImages.map((image, i) => {
                    const { src, width, height, left, top, originalData } = image;

                    return (
                      width &&
                      height &&
                      src && (
                        <div
                          key={i}
                          style={{
                            position: 'absolute',
                            left: left,
                            top: top,
                          }}
                        >
                          <Image
                            src={src}
                            width={width}
                            height={height}
                            alt="img"
                            onSelect={() => {
                              setSelected(`${cacheKey}_${i}`);
                              onClick(originalData);
                            }}
                            imageId={`${cacheKey}_${i}`}
                            selected={selected}
                            originalData={originalData}
                            deleteFunction={deleteFunction}
                            userId={userId}
                          />
                        </div>
                      )
                    );
                  })}
                </>
              );
            }}
          </JustifiedGrid>
        </div>

        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            minHeight: hasNextPage ? '100px' : 0,
            maxHeight: hasNextPage ? '100px' : 0,
          }}
          ref={loadMoreButtonRef}
        >
          {isFetchingNextPage || isFetching ? (
            <LoaderRing />
          ) : (
            !isEmpty(query) &&
            images.length === 0 && (
              <FormattedMessage id="gallery.search.noresults" defaultMessage="No results" />
            )
          )}
        </div>
      </Scroll>
    </>
  );
};

export default Gallery;
