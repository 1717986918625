/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M16,0H8C6.9,0,6,0.9,6,2v16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V6L16,0 M20,18H8V2h7v5h5V18 M4,4v18h16v2H4
	c-1.1,0-2-0.9-2-2V4H4z"
    />
    <polyline
      fill="currentColor"
      points="15,11 15,8 13,8 13,11 10,11 10,13 13,13 13,16 15,16 15,13 18,13 18,11 "
    />
  </Icon>
);

export default memo(IconComponent);
