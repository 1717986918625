import { css } from 'styled-components';

const RichText = ({ font, colors, scale }) => ({
  base: () => css`
    h3 {
      margin: 0px;
      font-size: 18px;
      padding: 0 0 ${scale.zero} 0;
      color: ${font.color};
      font-weight: ${font.weightNormal};
    }
    p {
      color: ${font.color};
      font-weight: ${font.weightNormal};
      font-size: ${font.sizeNormal};
      margin-top: 0;
      margin-bottom: ${scale.zero};
    }
    a {
      color: ${colors.primary};
      cursor: pointer;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  `,
});

export default RichText;
