/* @flow */
import {
  simpleAction,
  createReducer,
  simpleReducer,
  mergeRight,
  sessionBusinessCaseInitialState,
} from '@sharkfinesse/sfl-lib';
const namespace = (name: string): string => `@@session.businessCase/${name}`;

//Actions
export const types = {
  NPV: {
    UPDATE: namespace('NPV.UPDATE'),
  },
  IRR: {
    UPDATE: namespace('IRR.UPDATE'),
  },
  PAYBACK: {
    UPDATE: namespace('PAYBACK.UPDATE'),
  },
  NON_RECURRING_COSTS: {
    UPDATE: namespace('NON_RECURRING_COSTS.UPDATE'),
  },
  RECURRING_COSTS: {
    UPDATE: namespace('RECURRING_COSTS.UPDATE'),
  },
  BENEFITS: {
    UPDATE: namespace('BENEFITS.UPDATE'),
  },
  REPORTS: {
    LONG: {
      GENERATE: namespace('REPORTS.LONG.GENERATE'),
    },
  },
  DELAY: {
    UPDATE: namespace('DELAY.UPDATE'),
  },
  DONOTHING: {
    UPDATE: namespace('DONOTHING.UPDATE'),
  },
  TENDER: {
    UPDATE: namespace('TENDER.UPDATE'),
  },
  NPV_CASHFLOW: {
    UPDATE: namespace('NPV_CASHFLOW.UPDATE'),
  },
  NPV_CHART: {
    UPDATE: namespace('NPV_CHART.UPDATE'),
  },
  IRR_CASHFLOW: {
    UPDATE: namespace('IRR_CASHFLOW.UPDATE'),
  },
  IRR_CHART: {
    UPDATE: namespace('IRR_CHART.UPDATE'),
  },
  PAYBACK_CHART: {
    UPDATE: namespace('PAYBACK_CHART.UPDATE'),
  },
  NON_RECURRING_COSTS_CHART: {
    UPDATE: namespace('NON_RECURRING_COSTS_CHART.UPDATE'),
  },
  RECURRING_COSTS_CHART: {
    UPDATE: namespace('RECURRING_COSTS_CHART.UPDATE'),
  },
  BENEFITS_MONTHLY_CHART: {
    UPDATE: namespace('BENEFITS_MONTHLY_CHART.UPDATE'),
  },
  BENEFITS_OTB_CHART: {
    UPDATE: namespace('BENEFITS_OTB_CHART.UPDATE'),
  },
  CASH_ONLY_SUMMARY: {
    UPDATE: namespace('CASH_ONLY_SUMMARY.UPDATE'),
  },
  TENDER_CHART: {
    UPDATE: namespace('TENDER_CHART.UPDATE'),
  },
  DELAY_CHART: {
    UPDATE: namespace('DELAY_CHART.UPDATE'),
  },
  BENEFITS_MONTHLY: {
    UPDATE: namespace('BENEFITS_MONTHLY.UPDATE'),
  },
  BENEFITS_ONETIME: {
    UPDATE: namespace('BENEFITS_ONETIME.UPDATE'),
  },
  COSTS: {
    UPDATE: namespace('COSTS.UPDATE'),
  },
  ROI: {
    UPDATE: namespace('ROI.UPDATE'),
  },
  COST_OF: {
    UPDATE: namespace('COST_OF.UPDATE'),
  },
  PREFERENCE: {
    UPDATE: namespace('PREFERENCE.UPDATE'),
  },
  CASHFLOWS: {
    UPDATE: namespace('CASHFLOWS.UPDATE'),
  },
  CASHFLOWS_ROI: {
    UPDATE: namespace('CASHFLOWS_ROI.UPDATE'),
  },
  CHARTING: {
    UPDATE: namespace('CHARTING.UPDATE'),
  },
  CHARTING_BENEFITS: {
    UPDATE: namespace('CHARTING_BENEFITS.UPDATE'),
  },
  CHARTING_COSTS: {
    UPDATE: namespace('CHARTING_COSTS.UPDATE'),
  },
  CHARTING_ROI: {
    UPDATE: namespace('CHARTING_ROI.UPDATE'),
  },
  CHARTING_COST_OF: {
    UPDATE: namespace('CHARTING_COST_OF.UPDATE'),
  },
  COVERPAGE: {
    UPDATE: namespace('COVERPAGE.UPDATE'),
  },
};

//Reducer
export const initialState = sessionBusinessCaseInitialState;

const reduceNpv = simpleReducer('npv');
const reduceIrr = simpleReducer('irr');
const reducePayback = simpleReducer('payback');
const reduceNonRecurringCosts = simpleReducer('nonRecurringCosts');
const reduceRecurringCosts = simpleReducer('recurringCosts');
const reduceBenefits = simpleReducer('benefits');
const reduceDelay = simpleReducer('delay');
const reduceDoNothing = simpleReducer('doNothing');
const reduceTender = simpleReducer('tender');
const reduceNpvCashflow = simpleReducer('npvCashflow');
const reduceNpvChart = simpleReducer('npvChart');
const reduceIrrCashflow = simpleReducer('irrCashflow');
const reduceIrrChart = simpleReducer('irrChart');
const reducePaybackChart = simpleReducer('paybackChart');
const reduceNonRecurringCostsChart = simpleReducer('nonRecurringCostsChart');
const reduceRecurringCostsChart = simpleReducer('recurringCostsChart');
const reduceBenefitsMonthlyChart = simpleReducer('benefitsMonthlyChart');
const reduceBenefitsOnetimeChart = simpleReducer('benefitsOnetimeChart');
const reduceCashOnlySummary = simpleReducer('cashOnlySummary');
const reduceTenderChart = simpleReducer('tenderChart');
const reduceDelayChart = simpleReducer('delayChart');
const reduceBenefitsMonthly = simpleReducer('benefitsMonthly');
const reduceBenefitsOnetime = simpleReducer('benefitsOnetime');
const reduceCosts = simpleReducer('costs');
const reduceRoi = simpleReducer('roi');
const reduceCostOf = simpleReducer('costOf');
const reduceCashflows = simpleReducer('cashflows');
const reduceCashflowsRoi = simpleReducer('cashflowsRoi');
const reduceCharting = simpleReducer('charting');
const reduceChartingBenefits = simpleReducer('chartingBenefits');
const reduceChartingCosts = simpleReducer('chartingCosts');
const reduceChartingRoi = simpleReducer('chartingRoi');
const reduceChartingCostOf = simpleReducer('chartingCostOf');
const reduceCoverPage = simpleReducer('coverPage');
const reducePreferences = (state, action) => {
  return mergeRight(state, action.payload);
};

export default createReducer(
  {
    [types.NPV.UPDATE]: reduceNpv,
    [types.IRR.UPDATE]: reduceIrr,
    [types.PAYBACK.UPDATE]: reducePayback,
    [types.NON_RECURRING_COSTS.UPDATE]: reduceNonRecurringCosts,
    [types.RECURRING_COSTS.UPDATE]: reduceRecurringCosts,
    [types.BENEFITS.UPDATE]: reduceBenefits,
    [types.DELAY.UPDATE]: reduceDelay,
    [types.DONOTHING.UPDATE]: reduceDoNothing,
    [types.TENDER.UPDATE]: reduceTender,
    [types.NPV_CASHFLOW.UPDATE]: reduceNpvCashflow,
    [types.NPV_CHART.UPDATE]: reduceNpvChart,
    [types.IRR_CASHFLOW.UPDATE]: reduceIrrCashflow,
    [types.IRR_CHART.UPDATE]: reduceIrrChart,
    [types.PAYBACK_CHART.UPDATE]: reducePaybackChart,
    [types.NON_RECURRING_COSTS_CHART.UPDATE]: reduceNonRecurringCostsChart,
    [types.RECURRING_COSTS_CHART.UPDATE]: reduceRecurringCostsChart,
    [types.BENEFITS_MONTHLY_CHART.UPDATE]: reduceBenefitsMonthlyChart,
    [types.BENEFITS_OTB_CHART.UPDATE]: reduceBenefitsOnetimeChart,
    [types.CASH_ONLY_SUMMARY.UPDATE]: reduceCashOnlySummary,
    [types.TENDER_CHART.UPDATE]: reduceTenderChart,
    [types.DELAY_CHART.UPDATE]: reduceDelayChart,
    [types.BENEFITS_MONTHLY.UPDATE]: reduceBenefitsMonthly,
    [types.BENEFITS_ONETIME.UPDATE]: reduceBenefitsOnetime,
    [types.COSTS.UPDATE]: reduceCosts,
    [types.ROI.UPDATE]: reduceRoi,
    [types.COST_OF.UPDATE]: reduceCostOf,
    [types.PREFERENCE.UPDATE]: reducePreferences,
    [types.CASHFLOWS.UPDATE]: reduceCashflows,
    [types.CASHFLOWS_ROI.UPDATE]: reduceCashflowsRoi,
    [types.CHARTING.UPDATE]: reduceCharting,
    [types.CHARTING_BENEFITS.UPDATE]: reduceChartingBenefits,
    [types.CHARTING_COSTS.UPDATE]: reduceChartingCosts,
    [types.CHARTING_ROI.UPDATE]: reduceChartingRoi,
    [types.CHARTING_COST_OF.UPDATE]: reduceChartingCostOf,
    [types.COVERPAGE.UPDATE]: reduceCoverPage,
  },
  initialState
);

//Action Creators
const updateNpv = simpleAction(types.NPV.UPDATE);
const updateIrr = simpleAction(types.IRR.UPDATE);
const updatePayback = simpleAction(types.PAYBACK.UPDATE);
const updateNonRecurringCosts = simpleAction(types.NON_RECURRING_COSTS.UPDATE);
const updateRecurringCosts = simpleAction(types.RECURRING_COSTS.UPDATE);
const updateBenefits = simpleAction(types.BENEFITS.UPDATE);
const generateLongReport = simpleAction(types.REPORTS.LONG.GENERATE);
const updateDelay = simpleAction(types.DELAY.UPDATE);
const updateDoNothing = simpleAction(types.DONOTHING.UPDATE);
const updateTender = simpleAction(types.TENDER.UPDATE);
const updateNpvCashflow = simpleAction(types.NPV_CASHFLOW.UPDATE);
const updateNpvChart = simpleAction(types.NPV_CHART.UPDATE);
const updateIrrCashflow = simpleAction(types.IRR_CASHFLOW.UPDATE);
const updateIrrChart = simpleAction(types.IRR_CHART.UPDATE);
const updatePaybackChart = simpleAction(types.PAYBACK_CHART.UPDATE);
const updateNonRecurringCostsChart = simpleAction(types.NON_RECURRING_COSTS_CHART.UPDATE);
const updateRecurringCostsChart = simpleAction(types.RECURRING_COSTS_CHART.UPDATE);
const updateBenefitsMonthlyChart = simpleAction(types.BENEFITS_MONTHLY_CHART.UPDATE);
const updateBenefitsOnetimeChart = simpleAction(types.BENEFITS_OTB_CHART.UPDATE);
const updateCashOnlySummary = simpleAction(types.CASH_ONLY_SUMMARY.UPDATE);
const updateTenderChart = simpleAction(types.TENDER_CHART.UPDATE);
const updateDelayChart = simpleAction(types.DELAY_CHART.UPDATE);
const updateBenefitsMonthly = simpleAction(types.BENEFITS_MONTHLY.UPDATE);
const updateBenefitsOnetime = simpleAction(types.BENEFITS_ONETIME.UPDATE);
const updateCosts = simpleAction(types.COSTS.UPDATE);
const updateRoi = simpleAction(types.ROI.UPDATE);
const updateCostOf = simpleAction(types.COST_OF.UPDATE);
const updateFromPreferences = simpleAction(types.PREFERENCE.UPDATE);
const updateCashflows = simpleAction(types.CASHFLOWS.UPDATE);
const updateCashflowsRoi = simpleAction(types.CASHFLOWS_ROI.UPDATE);
const updateCharting = simpleAction(types.CHARTING.UPDATE);
const updateChartingBenefits = simpleAction(types.CHARTING_BENEFITS.UPDATE);
const updateChartingCosts = simpleAction(types.CHARTING_COSTS.UPDATE);
const updateChartingRoi = simpleAction(types.CHARTING_ROI.UPDATE);
const updateChartingCostOf = simpleAction(types.CHARTING_COST_OF.UPDATE);
const updateCoverPage = simpleAction(types.COVERPAGE.UPDATE);

export const actionCreators = {
  updateNpv,
  updateIrr,
  updatePayback,
  updateNonRecurringCosts,
  updateRecurringCosts,
  updateBenefits,
  generateLongReport,
  updateDelay,
  updateDoNothing,
  updateTender,
  updateNpvCashflow,
  updateNpvChart,
  updateIrrCashflow,
  updateIrrChart,
  updatePaybackChart,
  updateNonRecurringCostsChart,
  updateRecurringCostsChart,
  updateBenefitsMonthlyChart,
  updateBenefitsOnetimeChart,
  updateCashOnlySummary,
  updateTenderChart,
  updateDelayChart,
  updateBenefitsMonthly,
  updateBenefitsOnetime,
  updateCosts,
  updateRoi,
  updateCostOf,
  updateFromPreferences,
  updateCashflows,
  updateCashflowsRoi,
  updateCharting,
  updateChartingBenefits,
  updateChartingCosts,
  updateChartingRoi,
  updateChartingCostOf,
  updateCoverPage,
};
