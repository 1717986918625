import { useModal } from '@ebay/nice-modal-react';

const useDrawer = () => {
  const drawer = useModal();

  return {
    drawer,
    drawerProps: {
      isExpanded: drawer.visible,
      close: () => {
        drawer.hide();
      },
      remove: () => {
        drawer.remove();
      },
      onRest: () => {
        drawer.resolveHide();
        !drawer.keepMounted && drawer.remove();
      },
    },
  };
};

export default useDrawer;
