"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _normalization = require("../../normalization");

var _constants = require("../../constants");

const initialState = {
  session: {
    details: {
      currency: {
        select: '$',
        value: '$',
        position: 'prefix'
      },
      numberFormat: 'commaDot',
      dateFormat: 'DD/MM/YYYY',
      defaultStartMonth: (0, _normalization.normalizeTime)('months', _constants.defaultStartMonth)
    }
  }
};
var _default = initialState;
exports.default = _default;