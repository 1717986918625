"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reselect = require("reselect");

var _npvCalculator = _interopRequireDefault(require("./npv-calculator"));

var _totalCosts = _interopRequireDefault(require("./total-costs"));

var _benefits = _interopRequireDefault(require("./benefits"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Selectors
const getStatus = (0, _reselect.createSelector)([_npvCalculator.default.getResultDisplayExcel, _totalCosts.default.getCostsDislayExcel, _benefits.default.getShowBenefits, _totalCosts.default.getCostsQuantityDisplay], (npv, totalCost, benefits, costs) => {
  if (costs > 0 || benefits) {
    const percNPV = npv / totalCost * 100;
    return percNPV >= 10 ? 1 : percNPV >= -5 ? 0 : -1;
  } else {
    return 'hide';
  }
});
const selectors = {
  getStatus
};
var _default = selectors;
exports.default = _default;