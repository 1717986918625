// @flow
import styled from 'styled-components';
import { animated } from 'react-spring';
import applyTheme from '../../styles/apply-theme';

const progressMenu = applyTheme('progressMenu');
export const ProgressMenuList = progressMenu('ul', styled.ul);
export const Container = progressMenu('container', styled.li);
export const Wrapper = progressMenu('wrapper');
export const Label = progressMenu('label');
export const ChevronIcon = progressMenu('chevronIcon');
export const Body = progressMenu('body', styled(animated.div));
export const Icon = progressMenu('icon');
