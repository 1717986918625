/* @flow */
import React, { memo } from 'react';
import { EmptySvg } from './styled';

export type Props = {
  width: string | number,
  height: string | number,
};

let Image = ({ width, height }: Props): React.Element<any> => (
  <EmptySvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 918.1 765.5">
    <linearGradient
      id="SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1="397.745"
      y1="50.75"
      x2="397.745"
      y2="681.89"
      gradientTransform="matrix(1 0 0 -1 0 768)"
    >
      <stop offset="0" stopColor="#808080" stopOpacity="0.25" />
      <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
      <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
    </linearGradient>
    <rect x="162.9" y="86.1" opacity="0.5" fill="url(#SVGID_1_)" width="469.7" height="631.1" />
    <rect
      x="204.5"
      y="142.4"
      opacity="0.7"
      fill="#6C63FF"
      enableBackground="new    "
      width="156.6"
      height="4.9"
    />
    <linearGradient
      id="SVGID_2_"
      gradientUnits="userSpaceOnUse"
      x1="288.885"
      y1="389.56"
      x2="288.885"
      y2="558.35"
      gradientTransform="matrix(1 0 0 -1 0 768)"
    >
      <stop offset="0" stopColor="#808080" stopOpacity="0.25" />
      <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
      <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
    </linearGradient>
    <rect x="205.7" y="209.6" opacity="0.5" fill="url(#SVGID_2_)" width="166.3" height="168.8" />
    <rect x="206.8" y="213.3" fill="#F5F5F5" width="163.7" height="161.5" />
    <rect
      id="_x36_89f37f3-cc41-448d-b172-63ec123bc985"
      x="213.6"
      y="224.3"
      fill="#FFFFFF"
      width="150.6"
      height="101.5"
    />
    <g>
      <defs>
        <rect id="SVGID_3_" x="213.6" y="224.3" width="150.6" height="101.5" />
      </defs>
      <clipPath id="SVGID_4_">
        <use xlinkHref="#SVGID_3_" overflow="visible" />
      </clipPath>
      <g clipPath="url(#SVGID_4_)">
        <polygon
          opacity="0.4"
          fill="#6C63FF"
          enableBackground="new    "
          points="200.8,322.2 238.7,263.5 254.6,289.1 288.9,253.7 
			318.2,289.1 343.9,236.6 410,341.8 195.9,334.4 		"
        />
        <circle
          opacity="0.4"
          fill="#6C63FF"
          enableBackground="new    "
          cx="230.2"
          cy="239"
          r="11"
        />
      </g>
    </g>
    <linearGradient
      id="SVGID_5_"
      gradientUnits="userSpaceOnUse"
      x1="288.885"
      y1="173.07"
      x2="288.885"
      y2="341.86"
      gradientTransform="matrix(1 0 0 -1 0 768)"
    >
      <stop offset="0" stopColor="#808080" stopOpacity="0.25" />
      <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
      <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
    </linearGradient>
    <rect x="205.7" y="426.1" fill="url(#SVGID_5_)" width="166.3" height="168.8" />
    <rect x="206.8" y="429.8" fill="#F5F5F5" width="163.7" height="161.5" />
    <rect
      id="b429ac3a-f8a8-4171-bcce-5b6c002c0714"
      x="213.6"
      y="440.8"
      fill="#FFFFFF"
      width="150.6"
      height="101.5"
    />
    <g>
      <defs>
        <rect id="SVGID_6_" x="213.6" y="440.8" width="150.6" height="101.5" />
      </defs>
      <clipPath id="SVGID_7_">
        <use xlinkHref="#SVGID_6_" overflow="visible" />
      </clipPath>
      <g clipPath="url(#SVGID_7_)">
        <polygon
          opacity="0.4"
          fill="#BDBDBD"
          enableBackground="new    "
          points="200.8,538.7 238.7,480 254.6,505.6 288.9,470.2 
			318.2,505.6 343.9,453 410,558.2 195.9,550.9 		"
        />
        <circle
          opacity="0.4"
          fill="#BDBDBD"
          enableBackground="new    "
          cx="230.2"
          cy="455.5"
          r="11"
        />
      </g>
    </g>
    <linearGradient
      id="SVGID_8_"
      gradientUnits="userSpaceOnUse"
      x1="692.392"
      y1="108.1117"
      x2="692.392"
      y2="739.2516"
      gradientTransform="matrix(0.9808 0.1949 0.1949 -0.9808 -237.9835 690.8124)"
    >
      <stop offset="0" stopColor="#808080" stopOpacity="0.25" />
      <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
      <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
    </linearGradient>
    <polygon
      opacity="0.5"
      fill="url(#SVGID_8_)"
      points="231.9,674 354.9,54.9 815.6,146.5 692.5,765.5 "
    />
    <rect
      x="218.4"
      y="180.1"
      transform="matrix(0.1949 -0.9808 0.9808 0.1949 21.2798 841.0829)"
      fill="#FFFFFF"
      width="609.1"
      height="455"
    />
    <rect
      x="541.6"
      y="-29"
      transform="matrix(0.1949 -0.9808 0.9808 0.1949 308.1286 640.2012)"
      fill="#E0E0E0"
      width="4.9"
      height="322.9"
    />
    <rect
      x="451.5"
      y="424.4"
      transform="matrix(0.1949 -0.9808 0.9808 0.1949 -209.2214 916.9045)"
      fill="#E0E0E0"
      width="4.9"
      height="322.9"
    />
    <rect
      x="410"
      y="481.6"
      transform="matrix(0.1949 -0.9808 0.9808 0.1949 -262.6626 892.6852)"
      fill="#E0E0E0"
      width="4.9"
      height="249.5"
    />
    <rect
      x="327.7"
      y="572.2"
      transform="matrix(0.1949 -0.9808 0.9808 0.1949 -340.9779 821.9335)"
      opacity="0.7"
      fill="#6C63FF"
      enableBackground="new    "
      width="4.9"
      height="93"
    />
    <rect
      x="456.9"
      y="63.6"
      transform="matrix(0.1949 -0.9808 0.9808 0.1949 228.3291 566.7239)"
      opacity="0.7"
      fill="#6C63FF"
      enableBackground="new    "
      width="4.9"
      height="161.5"
    />
    <rect
      x="515"
      y="38.9"
      transform="matrix(0.1949 -0.9808 0.9808 0.1949 235.6559 656.0964)"
      fill="#E0E0E0"
      width="4.9"
      height="291.1"
    />
    <rect
      x="401.6"
      y="155.2"
      transform="matrix(0.1949 -0.9808 0.9808 0.1949 138.2963 549.7603)"
      opacity="0.7"
      fill="#3AD29F"
      enableBackground="new    "
      width="4.9"
      height="70.9"
    />
    <rect
      x="519.7"
      y="81.3"
      transform="matrix(0.1949 -0.9808 0.9808 0.1949 182.2118 707.5515)"
      fill="#E0E0E0"
      width="4.9"
      height="322.9"
    />
    <linearGradient
      id="SVGID_9_"
      gradientUnits="userSpaceOnUse"
      x1="691.767"
      y1="314.8117"
      x2="691.767"
      y2="481.1617"
      gradientTransform="matrix(0.9808 0.1949 0.1949 -0.9808 -237.9823 690.8051)"
    >
      <stop offset="0" stopColor="#808080" stopOpacity="0.25" />
      <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
      <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
    </linearGradient>
    <polygon fill="url(#SVGID_9_)" points="359.7,488.6 392.1,325.5 676.5,382 644,545.1 " />
    <rect
      x="436.9"
      y="291.6"
      transform="matrix(0.1949 -0.9808 0.9808 0.1949 -9.1124 858.2927)"
      fill="#FFFFFF"
      width="162.7"
      height="286.2"
    />
    <rect
      x="514.2"
      y="108.9"
      transform="matrix(0.1949 -0.9808 0.9808 0.1949 150.7344 724.3882)"
      fill="#E0E0E0"
      width="4.9"
      height="322.9"
    />
    <rect
      x="365.4"
      y="398.4"
      transform="matrix(0.1949 -0.9808 0.9808 0.1949 -66.2003 751.5381)"
      fill="#6C63FF"
      width="118.6"
      height="35.5"
    />
    <rect
      x="443.5"
      y="430"
      transform="matrix(0.1949 -0.9808 0.9808 0.1949 -50.0432 834.4227)"
      fill="#3AD29F"
      width="79.5"
      height="35.5"
    />
    <rect
      x="520.7"
      y="460.3"
      transform="matrix(0.1949 -0.9808 0.9808 0.1949 -32.4988 916.5632)"
      fill="#F55F44"
      width="42.8"
      height="35.5"
    />
    <rect
      x="565.3"
      y="451.2"
      transform="matrix(0.1949 -0.9808 0.9808 0.1949 30.1938 974.5579)"
      fill="#FDD835"
      width="86.8"
      height="35.5"
    />
    <polygon
      opacity="0.5"
      fill="#47E6B1"
      points="140.9,653.6 132.3,653.6 132.3,645.1 128.7,645.1 128.7,653.6 120.1,653.6 
	120.1,657.3 128.7,657.3 128.7,665.9 132.3,665.9 132.3,657.3 140.9,657.3 "
    />
    <path
      opacity="0.5"
      fill="#4D8AF0"
      enableBackground="new    "
      d="M558.5,8.3c-2.1-0.9-3.2-3.2-2.5-5.4c0.1-0.2,0.1-0.3,0.1-0.5
	l0,0c0-1.2-0.9-2.3-2.2-2.3c-0.7,0-1.4,0.3-1.8,0.8l0,0c-0.1,0.1-0.2,0.3-0.3,0.4c-0.9,2.1-3.2,3.2-5.4,2.5
	c-0.2-0.1-0.3-0.1-0.5-0.1l0,0c-1.2,0-2.3,0.9-2.3,2.2c0,0.7,0.3,1.4,0.8,1.8l0,0c0.1,0.1,0.3,0.2,0.4,0.3c2.1,0.9,3.2,3.2,2.5,5.4
	c-0.1,0.2-0.1,0.3-0.1,0.5l0,0c0,1.2,0.9,2.3,2.2,2.3c0.7,0,1.4-0.3,1.8-0.8l0,0c0.1-0.1,0.2-0.3,0.3-0.4c0.9-2.1,3.2-3.2,5.4-2.5
	c0.2,0.1,0.3,0.1,0.5,0.1l0,0c1.2,0,2.3-0.9,2.3-2.2c0-0.7-0.3-1.4-0.8-1.8l0,0C558.8,8.5,558.7,8.4,558.5,8.3z"
    />
    <path
      opacity="0.5"
      fill="#FDD835"
      enableBackground="new    "
      d="M145.4,112.2c-2.1-0.9-3.2-3.2-2.5-5.4c0.1-0.2,0.1-0.3,0.1-0.5
	l0,0c0-1.2-0.9-2.3-2.2-2.3c-0.7,0-1.4,0.3-1.8,0.8l0,0c-0.1,0.1-0.2,0.3-0.3,0.4c-0.9,2.1-3.2,3.2-5.4,2.5
	c-0.2-0.1-0.3-0.1-0.5-0.1l0,0c-1.2,0-2.3,0.9-2.3,2.2c0,0.7,0.3,1.4,0.8,1.8l0,0c0.1,0.1,0.3,0.2,0.4,0.3c2.1,0.9,3.2,3.2,2.5,5.4
	c-0.1,0.2-0.1,0.3-0.1,0.5l0,0c0,1.2,0.9,2.3,2.2,2.3c0.7,0,1.4-0.3,1.8-0.8l0,0c0.1-0.1,0.2-0.3,0.3-0.4c0.9-2.1,3.2-3.2,5.4-2.5
	c0.2,0.1,0.3,0.1,0.5,0.1l0,0c1.2,0,2.3-0.9,2.3-2.2c0-0.7-0.3-1.4-0.8-1.8l0,0C145.7,112.3,145.6,112.2,145.4,112.2z"
    />
    <path
      opacity="0.5"
      fill="#FDD835"
      enableBackground="new    "
      d="M112.7,417.2c-2.1-0.9-3.2-3.2-2.5-5.4c0.1-0.2,0.1-0.3,0.1-0.5
	l0,0c0-1.2-0.9-2.3-2.2-2.3c-0.7,0-1.4,0.3-1.8,0.8l0,0c-0.1,0.1-0.2,0.3-0.3,0.4c-0.9,2.1-3.2,3.2-5.4,2.5
	c-0.2-0.1-0.3-0.1-0.5-0.1l0,0c-1.2,0-2.3,0.9-2.3,2.2c0,0.7,0.3,1.4,0.8,1.8l0,0c0.1,0.1,0.3,0.2,0.4,0.3c2.1,0.9,3.2,3.2,2.5,5.4
	c-0.1,0.2-0.1,0.3-0.1,0.5l0,0c0,1.2,0.9,2.3,2.2,2.3c0.7,0,1.4-0.3,1.8-0.8l0,0c0.1-0.1,0.2-0.3,0.3-0.4c0.9-2.1,3.2-3.2,5.4-2.5
	c0.2,0.1,0.3,0.1,0.5,0.1l0,0c1.2,0,2.3-0.9,2.3-2.2c0-0.7-0.3-1.4-0.8-1.8l0,0C113,417.4,112.9,417.3,112.7,417.2z"
    />
    <circle opacity="0.5" fill="#F55F44" enableBackground="new    " cx="337.8" cy="28.6" r="7.3" />
    <circle opacity="0.5" fill="#F55F44" enableBackground="new    " cx="7.3" cy="317.5" r="7.3" />
    <circle opacity="0.5" fill="#F55F44" enableBackground="new    " cx="863.8" cy="120.4" r="7.3" />
    <circle opacity="0.5" fill="#4D8AF0" enableBackground="new    " cx="822.2" cy="339.3" r="7.3" />
    <circle opacity="0.5" fill="#47E6B1" enableBackground="new    " cx="37.7" cy="145.2" r="7.3" />
    <circle opacity="0.5" fill="#47E6B1" enableBackground="new    " cx="853.3" cy="647.3" r="7.3" />
    <circle opacity="0.5" fill="#47E6B1" enableBackground="new    " cx="910.8" cy="431" r="7.3" />
  </EmptySvg>
);

export default memo(Image);
