import {
  generateShortId,
  keys,
  findIndex,
  equals,
  isEmpty,
  mapObjIndexed,
  layoutSelectors,
  evaluatorSelectors,
  defaultSessionValuesSelectors,
  cashflowsSelectors,
} from '@sharkfinesse/sfl-lib';
import { put, call, select, all } from 'redux-saga/effects';
import { actionCreators as layoutActionCreators } from 'redux/modules/layout';
import { actionCreators as evaluatorActionCreators } from 'redux/modules/evaluator';
import { actionCreators as cashflowActionCreators } from 'redux/modules/cashflow';
import { addBenefitToLayout } from './addBenefit';
import { actionCreators as defaultSessionValuesActionCreators } from 'redux/modules/default-session-values';
import { actionCreators as appActionCreators } from 'redux/modules/app';

export function* duplicateBenefitObject(benefitId) {
  const id = generateShortId();
  const [eNo, benefitData, defaultSessionValues] = yield all([
    select(layoutSelectors.getBenefitENo, { benefitId }),
    select(evaluatorSelectors.getBenefitData, benefitId),
    select(defaultSessionValuesSelectors.getDefaultSessionValues, benefitId),
  ]);

  let linkedIds = [];
  yield all(
    mapObjIndexed((num, key, obj) => {
      const index = findIndex(equals(benefitId))(num);
      if (index !== -1) {
        linkedIds.push(key);
        return put(
          defaultSessionValuesActionCreators.updateLinkInput({
            defaultSessionValueId: key,
            evalUid: id,
          })
        );
      }
      return null;
    }, defaultSessionValues.linked)
  );

  const data = benefitData || {};
  if (!isEmpty(data)) {
    yield put(evaluatorActionCreators.updateInsertData({ id, data: { ...data, id } }));

    const benefits = yield select(cashflowsSelectors.getBenefitDataById, benefitId);
    const editted_benefits = yield select(
      cashflowsSelectors.getBenefitCashflowEdittedById,
      benefitId
    ) || [];
    if (!isEmpty(benefits))
      yield put(cashflowActionCreators.updateEvaluatorInsert({ id, benefits, editted_benefits }));
  }
  return { [id]: { eNo, id, linkedIds } };
}

function* duplicateBenefit(action) {
  const { benefitId, suiteId } = action.payload;
  yield put(appActionCreators.updatePersistState(false));
  yield put(layoutActionCreators.duplicatingBenefit({ benefitId }));
  const [suiteBenefits, layout] = yield all([
    select(layoutSelectors.getSuiteBenefits, { suiteId }),
    select(layoutSelectors.getLayout),
  ]);

  const position = findIndex(equals(benefitId))(suiteBenefits) + 1;

  const newBenefit = yield call(duplicateBenefitObject, benefitId);
  const newBenefitId = keys(newBenefit)[0];

  const newLayout = yield call(addBenefitToLayout, layout, {
    payload: {
      suiteId,
      benefitId: newBenefitId,
      position,
      eNo: newBenefit[newBenefitId].eNo,
    },
  });
  yield put(
    layoutActionCreators.insertBenefit({
      layout: newLayout.layout,
      id: newBenefitId,
    })
  );
  yield put(appActionCreators.updatePersistState(true));
  yield put(
    layoutActionCreators.duplicatingBenefitComplete({
      benefitId,
      id: newBenefitId,
      linkedIds: newBenefit[newBenefitId].linkedIds,
    })
  );
  if (action.payload.duplicateEmpty) {
    yield put(
      evaluatorActionCreators.updateStartDelete({ benefitId: newBenefitId, duplicateEmpty: true })
    );
  }
}

export default duplicateBenefit;
