import { put, select, all, call, take, takeLatest } from 'redux-saga/effects';
import { actionCreators as appActionCreators } from 'redux/modules/app';
import rsfApp from 'redux/rsf';
import { types as preferencesTypes } from 'redux/modules/preferences';
import { types as userTypes } from 'redux/modules/user/user';
import firebase from 'firebase/app';
import {
  getOfflineEnabled,
  getOfflineEnabledModal,
  isNil,
  userSelectors,
  loginSelectors,
} from '@sharkfinesse/sfl-lib';
import NiceModal from '@ebay/nice-modal-react';
import Compliance from 'containers/compliance';

export function* acceptPrivacy(action) {
  const { privacyId } = action.payload;
  const userId = yield select(loginSelectors.getUID);
  yield all([
    call(rsfApp.rsf.firestore.updateDocument, `/users/${userId}`, {
      privacyId,
    }),
    call(saveAcceptance, { id: privacyId, type: 'privacy' }),
  ]);
}
export function* acceptTerms(action) {
  const { termsId } = action.payload;
  const userId = yield select(loginSelectors.getUID);
  yield all([
    call(rsfApp.rsf.firestore.updateDocument, `/users/${userId}`, {
      termsId,
    }),
    call(saveAcceptance, { id: termsId, type: 'terms' }),
  ]);
}

export function* saveAcceptance({ id, type }) {
  const userId = yield select(loginSelectors.getUID);
  yield call(rsfApp.rsf.firestore.setDocument, `/users/${userId}/policyAcceptance/${id}`, {
    id,
    type,
    date: firebase.firestore.FieldValue.serverTimestamp(),
  });
}
export function* runCompliance() {
  yield all([take(preferencesTypes.LOADED), take(userTypes.LOADED)]);
  let privacyId = null;
  let termsId = null;
  let showCompliance = false;
  const [product, userPrivacyId, userTermsId] = yield all([
    select(userSelectors.getProduct),
    select(userSelectors.getPrivacyId),
    select(userSelectors.getTermsId),
  ]);

  const privacyDocProduct = yield call(
    rsfApp.rsf.firestore.getCollection,
    rsfApp.firestore
      .collection(`/privacy`)
      .where('product', '==', product)
      .orderBy('dateAdded', 'desc')
      .limit(1)
  );

  if (!privacyDocProduct.empty) {
    privacyId = privacyDocProduct.docs[0].id;
  } else {
    const privacyDoc = yield call(
      rsfApp.rsf.firestore.getCollection,
      rsfApp.firestore.collection(`/privacy`).orderBy('dateAdded', 'desc').limit(1)
    );
    if (!privacyDoc.empty) privacyId = privacyDoc.docs[0].id;
  }

  if (userPrivacyId !== privacyId) {
    yield put(appActionCreators.updatePolicyId(privacyId));
    showCompliance = true;
  }

  const termsDoc = yield call(
    rsfApp.rsf.firestore.getCollection,
    rsfApp.firestore
      .collection(`/terms`)
      .where('product', '==', product)
      .orderBy('dateAdded', 'desc')
      .limit(1)
  );
  if (!termsDoc.empty) termsId = termsDoc.docs[0].id;
  if (!isNil(termsId)) {
    if (userTermsId !== termsId) {
      yield put(appActionCreators.updateTermsId(termsId));
      showCompliance = true;
    }
  }
  if (!getOfflineEnabled() && !getOfflineEnabledModal()) {
    showCompliance = true;
  }

  if (showCompliance) {
    NiceModal.show(Compliance);
  }
}

export const effects = [
  takeLatest(userTypes.ACCEPT_PRIVACY, acceptPrivacy),
  takeLatest(userTypes.ACCEPT_TERMS, acceptTerms),
];
export default effects;
