"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reselect = require("reselect");

var _index = require("../../index");

var _sessionDetails = _interopRequireDefault(require("./session-details"));

var _charts = _interopRequireDefault(require("./charts"));

var _cashflows = _interopRequireDefault(require("./cashflows"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* eslint-disable no-unused-vars  */
// Selectors
const getResultObj = (0, _reselect.createSelector)([_sessionDetails.default.getMinimumReturn, _cashflows.default.getNetCashflow], (minimumReturn, netCashflow) => {
  return (0, _index.npvWithDcf)(minimumReturn, netCashflow);
});
const getResult = (0, _reselect.createSelector)([getResultObj], resultObj => {
  return resultObj.npv;
});
const getCashflow = (0, _reselect.createSelector)([getResultObj], resultObj => {
  return resultObj.cashflow;
});
const getYearlyCashflowSummed = (0, _reselect.createSelector)([getCashflow], cashflow => {
  return (0, _index.pipe)((0, _index.drop)(1), (0, _index.splitEvery)(12), (0, _index.map)(_index.sum))(cashflow);
});
const getYearlyCashflowSummedDisplay = (0, _reselect.createSelector)([getYearlyCashflowSummed, _sessionDetails.default.getNumberFormatObj], (yearlySummed, numberFormatObj) => {
  const addYrAndFormatting = (value, idx) => {
    return {
      year: (0, _index.inc)(idx),
      value: (0, _index.formatForDisplay)('number', numberFormatObj, value)
    };
  };

  return (0, _index.mapIndexed)(addYrAndFormatting, yearlySummed);
});
const getCashflowDisplay = (0, _reselect.createSelector)([getCashflow, _sessionDetails.default.getReviewPeriod, _sessionDetails.default.getNumberFormatObj], (cashflow = [], reviewPeriod, numberFormatObj) => {
  const cashflowlength = (0, _index.ifElse)((0, _index.lt)(_index.placeholder, 60), (0, _index.always)((0, _index.subtract)(60, reviewPeriod)), (0, _index.always)(reviewPeriod));
  return (0, _index.pipe)((0, _index.concat)(_index.placeholder, (0, _index.repeat)(0, cashflowlength(reviewPeriod))), (0, _index.map)(x => (0, _index.formatForDisplay)('number', numberFormatObj, x)))(cashflow);
});
const getCashflowSum = (0, _reselect.createSelector)([getCashflow], (cashflow = []) => {
  return (0, _index.sumCashflow)(cashflow);
});
const getCashflowSumDisplay = (0, _reselect.createSelector)([getCashflowSum, _sessionDetails.default.getNumberFormatObj], (value, numberFormatObj) => {
  return (0, _index.formatForDisplay)('number', numberFormatObj, value);
});
const getCumulativeCashflow = (0, _reselect.createSelector)([getCashflow], cashflow => {
  return (0, _index.accumulateCashflow)(cashflow);
});
const getResultDisplay = (0, _reselect.createSelector)([getResult, _sessionDetails.default.getNumberFormatObj], (value, numberFormatObj) => {
  return (0, _index.formatForDisplay)('money', numberFormatObj, value);
});
const getResultDisplayExcel = (0, _reselect.createSelector)([getResult, _sessionDetails.default.getNumberFormatObj], (value, numberFormatObj) => (0, _index.formatForExcel)('money', numberFormatObj, value));
const getCumulativeCashflowChartData = (0, _reselect.createSelector)([getCumulativeCashflow, _charts.default.getAddChartMonths], (cashflow, addChartMonths) => {
  return addChartMonths(cashflow);
});
const getCashflowChartData = (0, _reselect.createSelector)([getCashflow, _charts.default.getAddChartMonths], (cashflow, addChartMonths) => {
  return addChartMonths(cashflow);
});
const getTotalCashPosition = (0, _reselect.createSelector)([getCumulativeCashflowChartData], cashflow => {
  return (0, _index.pipe)(_index.last, _index.last)(cashflow);
});
const getTotalCashPositionDisplay = (0, _reselect.createSelector)([getTotalCashPosition, _sessionDetails.default.getNumberFormatObj], (value, numberFormatObj) => {
  return (0, _index.formatForDisplay)('money', numberFormatObj, value);
});
const selectors = {
  getResultObj,
  getCashflow,
  getYearlyCashflowSummed,
  getYearlyCashflowSummedDisplay,
  getCashflowDisplay,
  getCashflowSum,
  getCashflowSumDisplay,
  getCumulativeCashflow,
  getResult,
  getResultDisplay,
  getResultDisplayExcel,
  getCumulativeCashflowChartData,
  getCashflowChartData,
  getTotalCashPosition,
  getTotalCashPositionDisplay
};
var _default = selectors;
exports.default = _default;