import styledBreakpoint from 'styled-components-breakpoint';
import { css } from 'styled-components';
import { uiBase } from '../colors';
import { lightenHsla } from '@sharkfinesse/sfl-lib';

const Page = ({ font, colors, scale }) => ({
  main: () => css`
    position: relative;
    width: 100vw;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-height: 168px;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
  `,
  section: () => css`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    height: calc((var(--vh, 1vh) * 100) - 50px);
  `,
  padding: ({ padding = true, topPadding }) => css`
    ${padding &&
    css`
      ${styledBreakpoint('xxs')`
      padding: ${scale.minusFour} ${scale.minusFour} 0 ${scale.minusFour};
    `};
      ${styledBreakpoint('xs')`
      padding: ${scale.minusThree} ${scale.minusThree} 0 ${scale.minusThree};
    `};
      ${styledBreakpoint('md')`
      padding: ${topPadding ? scale.minusOne : scale.two} ${scale.zero} 0 ${scale.zero};
    `};
      ${styledBreakpoint('lg')`
      padding: ${topPadding ? scale.minusOne : scale.two} ${scale.two} 0 ${scale.two};
    `};
    `}
  `,
  paddingWrapper: () => css`
    width: 100%;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
  `,
  wrapper: () => css`
    width: 100%;
    display: flex;
    flex: 1 1 0%;
    height: 100%;
    position: absolute;
    flex-direction: column;
  `,
  body: () => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;
    width: 100%;
    position: relative;
  `,
  textPage: () => css`
    max-width: 1000px;
    background-color: ${colors.white};
    padding: ${scale.three} ${scale.five};
    margin-bottom: ${scale.zero};
    border-radius: 10px;
  `,
  mainSection: ({ floatingMenu }) => css`
    position: relative;
    height: 100%;
    flex-grow: 1;
    border-top: 1px solid ${colors.uiLight};
    background: ${colors.uiLightest};
  `,
  betaMessage: () => css`
    color: red;
    background: black;
    padding: 0 5px;
    transform: rotate(-10deg);
    display: inline-block;
    margin: 5px;
  `,
  h2: () => css`
    margin-top: 14px;
    margin-bottom: 14px;
    font-weight: ${font.weightSemiBold};
    color: ${font.color};
    font-size: 28px;
  `,
  h3: () => css`
    margin-top: 34px;
    margin-bottom: 10px;
    font-weight: ${font.weightSemiBold};
    color: ${font.color};
    font-size: 22px;
  `,
  h2Button: ({ empty }) => css`
    margin-top: 14px;
    margin-bottom: 10px;
    color: ${empty ? lightenHsla(70, uiBase) : font.color};
    font-size: 28px;
    border: 0;
    border-bottom: 1px dashed transparent;
    cursor: text;
    font-weight: 600;
    font-family: ${font.family};
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover,
    &:focus {
      border-bottom: 1px dashed ${font.color};
    }
  `,
  h2Input: () => css`
    margin-top: 15px;
    margin-bottom: 10px;
    height: 42px;
    background: transparent;
    padding: 0;
    max-width: 100%;
    font-size: 28px;
    border: 0;
    border-bottom: 1px dashed transparent;
    font-weight: 600;
    font-family: ${font.family};
    color: ${font.color};
    ::placeholder {
      color: ${lightenHsla(70, uiBase)};
    }
    &:hover,
    &:focus {
      border-bottom: 1px dashed ${font.color};
    }
  `,
  flexColumn: () => css`
    display: flex;
    flex-direction: column;
  `,
  flexRow: () => css`
    display: flex;
    flex-direction: row;
  `,
  semiBold: () => css`
    font-weight: ${font.weightSemiBold};
  `,
});

export default Page;
