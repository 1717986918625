// @flow
import React from 'react';
import { SelectField } from '@sharkfinesse/sfl-ui';
import { FormattedMessage } from 'react-intl';

const getThemeLabel = option => (
  <FormattedMessage id={`theme.select.${option.value}`} defaultMessage={option.label} />
);

type Props = {
  selectedTheme: Object,
  changeTheme: Function,
};

const ThemeSelect = ({ selectedTheme, changeTheme, ...props }: Props) => (
  <SelectField
    layout="column"
    label={<FormattedMessage id="theme.select.label" defaultMessage="Select a theme" />}
    onChange={changeTheme}
    value={selectedTheme}
    options={[
      { value: 'Light', label: 'Light' },
      { value: 'Dark', label: 'Dark' },
      { value: 'Aqua', label: 'Aqua' },
      { value: 'Lime', label: 'Lime' },
      { value: 'Dynamic', label: 'Dynamic' },
    ]}
    getOptionLabel={getThemeLabel}
    {...props}
  />
);

export default ThemeSelect;
