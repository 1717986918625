"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setPreferences = exports.mergePreferences = exports.fetchPreferences = void 0;

var _utils = require("./utils");

var _index = require("../../index");

const fetchPreferences = async ({
  firestore,
  userId
}) => {
  const prefs = await (0, _utils.getDoc)({
    firestore,
    path: `/preferences/${userId}`
  });
  return prefs.data();
};

exports.fetchPreferences = fetchPreferences;

const mergePreferences = async ({
  preferences,
  preferencesInitialState,
  accountPreferences,
  productPreferences
}) => {
  const accountProductPrefs = (0, _index.mergeDeepRight)(accountPreferences, productPreferences);
  const mergedPrefs = !(0, _index.isNil)(preferences) ? (0, _index.mergeDeepRight)(accountProductPrefs, (0, _index.mergeDeepRight)(preferencesInitialState, preferences)) : (0, _index.mergeDeepRight)(preferencesInitialState, accountProductPrefs);
  return mergedPrefs;
};

exports.mergePreferences = mergePreferences;

const setPreferences = async ({
  firestore,
  userId,
  preferences
}) => {
  try {
    await (0, _utils.setDoc)({
      firestore,
      path: `/preferences/${userId}`,
      props: preferences
    });
    return;
  } catch (error) {
    return error;
  }
};

exports.setPreferences = setPreferences;