/* @flow */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTable = styled.div`
  ${({ theme, themeKey }) => theme[`${themeKey}`].borderStyle};
  ${({ theme, themeKey }) => theme[`${themeKey}`].borderWidth};
  ${({ theme, themeKey }) => theme[`${themeKey}`].borderColor};
  color: ${({ theme, themeKey }) => theme[`${themeKey}`].fontColor};
`;

type DefaultProps = {
  themeKey?: string,
};
export type Props = DefaultProps & {
  children?: Node,
  ...other,
};

type State = {};

class Table extends Component<DefaultProps, Props, State> {
  static defaultProps = {
    themeKey: 'costs',
    columnKey: 'nonRecurringCostsColumns',
  };
  static childContextTypes = {
    sfluiTable: PropTypes.object.isRequired,
  };

  getChildContext() {
    const { themeKey, columnKey } = this.props;

    return {
      sfluiTable: {
        themeKey,
        columnKey,
      },
    };
  }

  render() {
    const { themeKey, columnKey, children, ...other } = this.props;

    return (
      <StyledTable themeKey={themeKey} columnKey={columnKey} {...other}>
        {children}
      </StyledTable>
    );
  }
}
export default Table;
