/* @flow */
import React from 'react';
import * as Headings from './headings/';

export type Props = {
  children?: React.Element<any>,
  color?: 'Default' | 'Suite',
  type: string,
};

const Heading = ({ children, type, color = 'Default' }: Props): React.Element<any> => {
  const HeadingType = Headings[type];
  return (
    <HeadingType color={color}>
      {children}
    </HeadingType>
  );
};

export default Heading;
