import { css } from 'styled-components';

const Styles = ({ font, colors, scale }) => ({
  tabWrapper: ({ orientation }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    height: 100%;
    flex: 1 1 0%;
  `,
  tabPanel: () => css`
    position: relative;
    width: 100%;
    height: 100%;
    flex: 1 1 0%;
  `,
  contentContainer: () => css`
    width: 100%;
    height: 100%;
  `,
  tabs: ({ orientation }) => css`
    overflow: hidden;
    min-height: 48px;
    -webkit-overflow-scrolling: touch; // Add iOS momentum scrolling.
    display: flex;
    flex-direction: ${orientation === 'vertical' ? 'column' : 'row'};
    width: 100%;
  `,
  scroller: ({ scrollable, visibleScrollbar, vertical }) => css`
    position: relative;
    display: inline-block;
    flex: 1 1 auto;
    white-space: nowrap;
    ${!scrollable &&
    css`
      overflow-x: hidden;
      width: 100%;
    `}
    ${scrollable &&
    !visibleScrollbar &&
    css`
      scrollbar-width: none; // Firefox
      &::-webkit-scrollbar: {
        display: none; // Safari + Chrome
      }
    `}
   ${scrollable &&
    !vertical &&
    css`
      overflow-x: auto;
      overflow-y: hidden;
    `}
          ${scrollable &&
    vertical &&
    css`
      overflow-x: hidden;
      overflow-y: auto;
    `}
  `,
  tabList: ({ centered, vertical, scrollable, showBorder }) => css`
    display: flex;
    ${vertical &&
    css`
      flex-direction: column;
    `}
    ${centered &&
    !scrollable &&
    css`
      justify-content: center;
    `}
    ${showBorder &&
    css`
      border-bottom: 1px solid ${colors.uiLightest};
    `}
  `,
  scrollButton: ({ disabled, vertical, direction }) => {
    return css`
      width: 40px;
      flex-shrink: 0;

      background-color: transparent;
      border-radius: 0;
      align-items: center;
      display: flex;
      justify-content: center;
      color: ${font.color};
      ${disabled
        ? css`
            cursor: not-allowed;
            opacity: 0.2;
          `
        : css`
            &:hover {
              cursor: pointer;
              opacity: 1;
              color: ${colors.primary};
            }
          `}
      ${vertical &&
      css`
        width: 100%;
        height: 40px;
        span: {
          transform: rotate(90deg);
        }
      `}
    `;
  },
  tab: ({ disabled, fullWidth, wrapped, label, selected, icon, inverse }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0;
    color: ${inverse ? colors.uiLight : font.color};
    max-width: 264px;
    min-width: 72px;
    position: relative;
    min-height: 48px;
    flex-shrink: 0;
    padding: 6px 12px;
    overflow: hidden;
    white-space: normal;
    text-align: center;
    opacity: 1;
    ${selected &&
    css`
      color: ${inverse ? colors.white : colors.primary};
      opacity: 1;
    `};
    &.disabled {
      opacity: 0.2;
    }
    ${!disabled &&
    css`
      cursor: ${selected ? 'arrow' : 'pointer'};
      &:hover {
        color: ${inverse ? colors.white : colors.primary};
      }
    `}
    ${label &&
    icon &&
    css`
      min-height: 72px;
      padding-top: 9px;
    `}
    ${fullWidth &&
    css`
      flex-shrink: 1;
      flex-grow: 1;
      flex-basis: 0;
      max-width: none;
    `}
        ${wrapped &&
    css`
      font-size: 14px;
      line-height: 1.5;
    `}
  `,
  tabLabel: ({ selected, inverse, icon }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    letter-spacing: 0.7px;
    opacity: 0.7;
    line-height: 16px;
    ${selected &&
    css`
      text-shadow: 1px 0 ${inverse ? colors.white : colors.primary};
      opacity: 1;
    `};
    ${icon &&
    css`
      padding: 0px 0px 4px 0px;
    `}
  `,
  indicator: ({ orientation, inverse }) => css`
    position: absolute;
    height: 2px;
    bottom: 0;
    width: 100%;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: ${inverse ? colors.white : colors.primary};
    ${orientation === 'vertical' &&
    css`
      height: 100%;
      width: 2px;
      right: 0;
    `};
  `,
  verticalWrapper: ({ selected }) => css`
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100%;
    flex: 1 1 0%;
  `,
  verticalTabs: ({ selected }) => css`
    width: 250px;
    border-right: 1px solid ${colors.uiLight};
    height: 100%;
  `,
  verticalContent: ({ selected }) => css`
    position: relative;
    height: 100%;
    flex: 1 1 0%;
  `,
});

export default Styles;
