/* @flow */
import {
  createReducer,
  simpleAction,
  simpleReducer,
  pluginsSalesforceInitialState,
} from '@sharkfinesse/sfl-lib';

const namespace = (name: string): string => `@@plugins.salesforce/${name}`;

//Types
export const types = {
  LOGIN: {
    SUCCESS: namespace('LOGIN.SUCCESS'),
    ERROR: namespace('LOGIN.ERROR'),
  },
  LOGOUT: {
    SUCCESS: namespace('LOGOUT.SUCCESS'),
  },
  OUTPUT: {
    LONGFORM: namespace('OUTPUT.LONGFORM'),
    LONGFORM_CLEAR: namespace('OUTPUT.LONGFORM_CLEAR'),
    PPT: namespace('OUTPUT.PPT'),
    PPT_CLEAR: namespace('OUTPUT.PPT_CLEAR'),
    XLSX: namespace('OUTPUT.XLSX'),
    XLSX_CLEAR: namespace('OUTPUT.XLSX_CLEAR'),
  },
};

//Reducer
const initialState = pluginsSalesforceInitialState;

const reduceLoginSuccess = (state, action) => {
  return {
    ...state,
    token: action.payload.token,
    instanceUrl: action.payload.instanceUrl,
  };
};

const reduceOutputLongform = simpleReducer('outputLongform');

const reduceOutputLongformClear = simpleReducer('outputLongform');

const reduceOutputPpt = simpleReducer('outputPpt');

const reduceOutputPptClear = simpleReducer('outputPpt');

const reduceOutputXlsx = simpleReducer('outputXlsx');

const reduceOutputXlsxClear = simpleReducer('outputXlsx');

const reduceInitialState = () => initialState;

export default createReducer(
  {
    [types.LOGIN.SUCCESS]: reduceLoginSuccess,
    [types.LOGIN.ERROR]: reduceInitialState,
    [types.LOGOUT.SUCCESS]: reduceInitialState,
    [types.OUTPUT.LONGFORM]: reduceOutputLongform,
    [types.OUTPUT.LONGFORM_CLEAR]: reduceOutputLongformClear,
    [types.OUTPUT.PPT]: reduceOutputPpt,
    [types.OUTPUT.PPT_CLEAR]: reduceOutputPptClear,
    [types.OUTPUT.XLSX]: reduceOutputXlsx,
    [types.OUTPUT.XLSX_CLEAR]: reduceOutputXlsxClear,
  },
  initialState
);

//Action Creators
const loginSuccess = simpleAction(types.LOGIN.SUCCESS);
const loginError = simpleAction(types.LOGIN.ERROR);
const logoutSuccess = simpleAction(types.LOGOUT.SUCCESS);
const outputLongform = simpleAction(types.OUTPUT.LONGFORM);
const outputLongformClear = simpleAction(types.OUTPUT.LONGFORM_CLEAR);
const outputPpt = simpleAction(types.OUTPUT.PPT);
const outputPptClear = simpleAction(types.OUTPUT.PPT_CLEAR);
const outputXlsx = simpleAction(types.OUTPUT.XLSX);
const outputXlsxClear = simpleAction(types.OUTPUT.XLSX_CLEAR);

export const actionCreators = {
  loginSuccess,
  loginError,
  logoutSuccess,
  outputLongform,
  outputLongformClear,
  outputPpt,
  outputPptClear,
  outputXlsx,
  outputXlsxClear,
};
