/* @flow */
import styled from 'styled-components';
import applyTheme from '../../styles/apply-theme';

const menu = applyTheme('menu');
export const StyledMenuItem = menu('item', styled.li);
export const IconBefore = menu('iconBefore', styled.span);
export const IconAfter = menu('iconAfter', styled.span);
export const StyledAnimtaedMenuItem = menu(['item', 'animatedItem'], styled.li);
export const AnimatedIconBefore = menu(['iconBefore', 'animatedIconBefore'], styled.span);
