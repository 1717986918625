/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

const IconComponent = props => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M15,28.7c-0.1,0-5.1-1.2-7.7-5.4c-1.7-2.6-2.1-5.9-1.2-9.7l1,1.7c0.1,0.2,0.4,0.4,0.7,0.4c0.1,0,0.3,0,0.4-0.1
		c0.4-0.2,0.5-0.7,0.3-1l-1.9-3.3c-0.2-0.4-0.7-0.5-1-0.3c0,0,0,0,0,0c0,0,0,0,0,0l-3.3,1.9c-0.4,0.2-0.5,0.7-0.3,1
		c0.1,0.2,0.4,0.4,0.7,0.4c0.1,0,0.3,0,0.4-0.1l1.7-1C3.6,17.5,4.1,21.1,6,24.1c3,4.8,8.5,6,8.7,6.1c0.1,0,0.1,0,0.2,0
		c0.3,0,0.7-0.2,0.7-0.6C15.6,29.2,15.4,28.8,15,28.7z"
    />
    <path
      fill="currentColor"
      d="M29.6,12.3c-0.1-0.4-0.6-0.6-1-0.5c-0.4,0.1-0.6,0.6-0.5,1c0.2,0.5,3.8,11.6-8.3,15.7l1.1-1.8c0.2-0.4,0.1-0.8-0.3-1
		c-0.4-0.2-0.8-0.1-1,0.3l-1.9,3.3c-0.2,0.3-0.1,0.8,0.2,1c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0l3.2,2c0.1,0.1,0.3,0.1,0.4,0.1
		c0.3,0,0.5-0.1,0.6-0.4c0.2-0.4,0.1-0.8-0.3-1l-1.6-1C33.8,25.3,29.6,12.4,29.6,12.3z"
    />
    <path
      fill="currentColor"
      d="M25.7,9c0,0,0.1,0.1,0.1,0.1l-2.2,0c-0.4,0-0.7,0.3-0.7,0.8c0,0.4,0.3,0.7,0.8,0.7c0,0,0,0,0,0l3.8,0
		c0.4,0,0.7-0.3,0.7-0.8c0,0,0,0,0,0s0,0,0,0l0-3.8c0-0.4-0.3-0.7-0.8-0.7c0,0,0,0,0,0c-0.4,0-0.7,0.3-0.7,0.8l0,1.8
		c-3.2-2.9-6.7-4.3-10.2-4C10.8,4.1,7.1,8.4,7,8.6C6.7,8.9,6.7,9.3,7,9.6c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4-0.1,0.6-0.3
		c0,0,3.5-4,8.4-4.3C19.6,5.1,22.7,6.3,25.7,9z M26.6,9.1L26.6,9.1C26.6,9.1,26.6,9,26.6,9.1L26.6,9.1z"
    />
  </Icon>
);

export default memo(IconComponent);
