"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = require("../../index");

var _reselect = require("reselect");

var _benefits = _interopRequireDefault(require("./benefits"));

var _recurringCosts = _interopRequireDefault(require("./recurring-costs"));

var _nonRecurringCosts = _interopRequireDefault(require("./non-recurring-costs"));

var _sessionDetails = _interopRequireDefault(require("./session-details"));

var _intl = _interopRequireDefault(require("./intl"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const getSimpleRoi = (0, _reselect.createSelector)([_benefits.default.getTotalBenefits, _recurringCosts.default.getTotalCosts, _nonRecurringCosts.default.getTotalCosts], (totalBenefits, recTotalCosts, nonRecTotalCosts) => {
  return (0, _index.calculateSimpleRoi)(totalBenefits, recTotalCosts, nonRecTotalCosts);
});
const getSimpleRoiDisplay = (0, _reselect.createSelector)([getSimpleRoi, _sessionDetails.default.getNumberFormatObj], (simpleRoi, numberFormatObj) => {
  return (0, _index.formatForDisplay)('percentage', numberFormatObj, simpleRoi);
});
const getSimpleRoiDisplayExcel = (0, _reselect.createSelector)([getSimpleRoi, _sessionDetails.default.getNumberFormatObj], (simpleRoi, numberFormatObj) => {
  return (0, _index.formatForExcel)('percentage', { ...numberFormatObj,
    decimalPlaces: 1
  }, simpleRoi);
});
const getSimpleRoiChartData = (0, _reselect.createSelector)([_benefits.default.getTotalBenefits, _nonRecurringCosts.default.getTotalCosts, _recurringCosts.default.getTotalCosts, _intl.default.getMessages], (totalBens, nonRecCosts, recCosts, intl) => {
  return [{
    id: 1,
    description: intl['roi.summary.simpleRoi.totalBenefits'],
    cost: totalBens
  }, {
    id: 2,
    description: intl['roi.summary.simpleRoi.totalCosts'],
    cost: nonRecCosts + recCosts
  }];
});
const selectors = {
  getSimpleRoi,
  getSimpleRoiDisplay,
  getSimpleRoiDisplayExcel,
  getSimpleRoiChartData
};
var _default = selectors;
exports.default = _default;