/* @flow */
import React, { Component } from 'react';
import type { Element } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { prop } from 'styled-tools';

type DefaultProps = {
  themeKey?: string,
  hideInkBar?: boolean,
};
export type Props = DefaultProps & {
  children?: Element<*> | string,
  themeKey?: string,
  hideInkBar?: boolean,
  ...other,
};

const StyledNavBar = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  /*overflow-x: auto;*/
  width: 100%;
  ${({ themeKey }) => css`
    font-size: ${prop(`theme[${themeKey}].fontSize`)};
    ${prop(`theme[${themeKey}].background`)};
    ${prop(`theme[${themeKey}].borderStyle`)};
    ${prop(`theme[${themeKey}].borderWidth`)};
    ${prop(`theme[${themeKey}].borderColor`)};
    ${prop(`theme[${themeKey}].padding`)};

    border-radius: ${prop(`theme[${themeKey}].borderRadius`)}
      ${prop(`theme[${themeKey}].borderRadius`)};
    li:first-child {
      a {
        border-radius: ${prop(`theme[${themeKey}].borderRadius`)} 0 0
          ${prop(`theme[${themeKey}].borderRadius`)};
      }
    }
    li:last-child {
      a {
        border-radius: 0 ${prop(`theme[${themeKey}].borderRadius`)}
          ${prop(`theme[${themeKey}].borderRadius`)} 0;
      }
    }
  `};
`;

type State = {};

class NavBar extends Component<DefaultProps, Props, State> {
  static defaultProps = {
    themeKey: 'navBar',
    hideInkBar: false,
  };

  static childContextTypes = {
    sfluiNavBar: PropTypes.object.isRequired,
  };

  getChildContext() {
    const { themeKey, hideInkBar } = this.props;

    return {
      sfluiNavBar: {
        themeKey,
        hideInkBar,
      },
    };
  }

  render() {
    const { themeKey, hideInkBar, children, ...other } = this.props;

    return (
      <StyledNavBar themeKey={themeKey} hideInkBar={hideInkBar} {...other}>
        {children}
      </StyledNavBar>
    );
  }
}

export default NavBar;
