import { takeEvery, takeLatest } from 'redux-saga/effects';
import { types as evaluatorTypes } from 'redux/modules/evaluator';
import { types as defaultSessionValuesTypes } from 'redux/modules/default-session-values';
import { types as cashflowTypes } from 'redux/modules/cashflow';
import resetEvaluator from './reset';
import { linkDefaultSessionValues } from './default-session-values';
import evaluatorError from './error';
import submit from './submit';

export const effects = [
  takeLatest(
    [
      evaluatorTypes.SUBMIT,
      evaluatorTypes.CASHFLOW.MODIFIER.GROWTH.UPDATE,
      evaluatorTypes.CASHFLOW.MODIFIER.ADOPTION.UPDATE,
      evaluatorTypes.CASHFLOW.MODIFIER.RISK.UPDATE,
      evaluatorTypes.CASHFLOW.MODIFIER.RESET,
      evaluatorTypes.CASHFLOW.SMOOTH,
      cashflowTypes.EVALUATOR.UPDATE,
      cashflowTypes.EVALUATOR.REVERT_EDIT,
    ],
    submit
  ),
  takeEvery(evaluatorTypes.START_DELETE, resetEvaluator),
  takeLatest(evaluatorTypes.ERROR, evaluatorError),
  takeEvery(defaultSessionValuesTypes.LINK_INPUT, linkDefaultSessionValues),
];

export default effects;
