/* @flow */
import React from 'react';
import PropTypes from 'prop-types';
import type { Element } from 'react';
import styled from 'styled-components';
import { grid } from 'styled-components-grid';
import { map } from 'styled-components-breakpoint';

const StyledTableRow = styled.div`
  height: 100%;
  ${({ theme, themeKey }) =>
    grid({
      wrap: theme[`${themeKey}`].wrapBreakpoints,
      valign: 'center',
    })};

  ${({ theme, themeKey, collapsed }) =>
    map(
      collapsed,
      c =>
        `padding: ${
          c === true ? theme[`${themeKey}`].collapsedRowPadding : theme[`${themeKey}`].rowPadding
        };
        `,
      theme.breakpoints
    )};
`;
type DefaultProps = {
  collapsed?: string | Object,
};
type Props = DefaultProps & {
  themeKey?: string,
  children?: Node,
  collapsed?: Object,
  style?: Object,
  ...other,
};

const TableRow = (
  { themeKey: themeKeyProp, children, collapsed, style }: Props,
  context
): Element<any> => {
  const { sfluiTable } = context;

  let themeKey = themeKeyProp;

  if (sfluiTable) {
    if (typeof themeKey === 'undefined') {
      themeKey = sfluiTable.themeKey;
    }
  }
  return (
    <StyledTableRow themeKey={themeKey} collapsed={collapsed} style={style}>
      {children}
    </StyledTableRow>
  );
};

TableRow.defaultProps = {
  collapsed: { xxs: false },
};
TableRow.contextTypes = {
  sfluiTable: PropTypes.object,
};

export default TableRow;
