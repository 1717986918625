/* @flow */
import {
  createReducer,
  simpleAction,
  simpleReducer,
  settingsUiInitialState,
} from '@sharkfinesse/sfl-lib';
const namespace = (name: string): string => `@@settings.ui/${name}`;

//Actions
export const types = {
  SHARED_SESSIONS: { VISIBLE: namespace('SHARED_SESSIONS.VISIBLE') },
  RECENT_SESSIONS: { VISIBLE: namespace('RECENT_SESSIONS.VISIBLE') },
  HOME_HERO: { VISIBLE: namespace('HOME_HERO.VISIBLE') },
};

//Reducer
export const initialState = settingsUiInitialState;

const reduceSharedSessionsVisible = simpleReducer('ssv');
const reduceRecentSessionsVisible = simpleReducer('rsv');
const reduceHomeHeroVisible = simpleReducer('hhv');

export default createReducer(
  {
    [types.SHARED_SESSIONS.VISIBLE]: reduceSharedSessionsVisible,
    [types.RECENT_SESSIONS.VISIBLE]: reduceRecentSessionsVisible,
    [types.HOME_HERO.VISIBLE]: reduceHomeHeroVisible,
  },
  initialState
);

//Action Creators
const updateSharedSessionsVisible = simpleAction(types.SHARED_SESSIONS.VISIBLE);
const updateRecentSessionsVisible = simpleAction(types.RECENT_SESSIONS.VISIBLE);
const updateHomeHeroVisible = simpleAction(types.HOME_HERO.VISIBLE);

export const actionCreators = {
  updateSharedSessionsVisible,
  updateRecentSessionsVisible,
  updateHomeHeroVisible,
};
