/* eslint-disable no-magic-numbers */
import { borderWidth } from 'polished';

const NonRecurringCostsColumns = () => ({
  delete: {
    size: { md: 1.8 / 20, lg: 1.5 / 20, xxl: 1 / 20 },
    head: {
      borderWidth: borderWidth('0px'),
    },
    //add borderWidth here for row cell borders
  },
  description: {
    size: { md: 9.2 / 20, lg: 9.5 / 20, xxl: 12 / 20 },
    head: {
      borderWidth: borderWidth('0px', '1px', '0px', '0px'),
    },
  },
  impactmonth: {
    size: { md: 2 / 20, lg: 3 / 20, xxl: 2 / 20 },
    head: {
      borderWidth: borderWidth('0px', '1px', '0px', '0px'),
    },
  },
  type: {
    size: { md: 3 / 20, lg: 2 / 20 },
    head: {
      borderWidth: borderWidth('0px', '1px', '0px', '0px'),
    },
  },
  cost: {
    size: { md: 4 / 20, xxl: 3 / 20 },
    head: {
      borderWidth: borderWidth('0px'),
    },
  },
});

export default NonRecurringCostsColumns;
