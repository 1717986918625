import { css } from 'styled-components';

const AddButton = ({ font, colors, scale }) => ({
  button: (disabled) => css`
    transition: border 0.2s ease-in-out, color 0.2s ease-in-out, background 0.2s ease-in-out;
    border: 2px dashed ${colors.uiLight};
    border-radius: 3px;
    width: 100%;
    font-size: 16px;
    padding: 10px;
    color: ${colors.ui};
    cursor: ${disabled ? 'auto' : 'pointer'};
    ${disabled
      ? null
      : css`
          &:hover {
            border-color: ${colors.primary};
            color: ${colors.primary};
          }
        `}

    display: flex;
    flex-direction: row;
    justify-content: center;
  `,
  icon: () => css`
    display: flex;
  `,
  label: () => css`
    line-height: 24px;
    margin-left: 8px;
    display: flex;
  `,
});

export default AddButton;
