"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.wordsToTrain = exports.ucFirst = exports.asciiCamelToWords = exports.asciiCamelToTrain = void 0;

var _fp = require("./fp");

const asciiCamelToWords = (0, _fp.match)(/([A-Z][a-z]+)/g);
exports.asciiCamelToWords = asciiCamelToWords;
const ucFirst = (0, _fp.pipe)(_fp.toLower, (0, _fp.adjust)(0, _fp.toUpper), (0, _fp.join)(''));
exports.ucFirst = ucFirst;
const wordsToTrain = (0, _fp.pipe)((0, _fp.map)(ucFirst), (0, _fp.join)('-'));
exports.wordsToTrain = wordsToTrain;
const asciiCamelToTrain = (0, _fp.pipe)(asciiCamelToWords, wordsToTrain);
exports.asciiCamelToTrain = asciiCamelToTrain;