// @flow
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Menu, { MenuList } from '../menu';
import MenuItem from '../menu-item';
import Button from '../button';
import { IconDotsVerticalSmall, IconTrash } from '../icon';
import { MenuWrapper, MenuIcon, MenuInfo as StyledMenuInfo } from './styled';
import MenuInfo from './menu-info';
import { MenuDivider } from '../menu';

type Props = {
  originalData: Object,
  deleteFunction: Function,
  userId: String,
};

const MenuComponent = ({
  deleteFunction,
  originalData,
  userId,
  ...other
}: Props): React.Element<any> => {
  const [open, setOpen] = useState(false);

  const updateOpen = (value, e) => {
    e.stopPropagation();
    setOpen(value);
  };

  const set = open => setOpen(open);
  const { creator, pageURL, id } = originalData;

  return (
    <>
      {(creator || deleteFunction || pageURL) && (
        <MenuWrapper {...other}>
          <Menu
            open={open}
            onVisibilityChange={set}
            referenceComponent={
              <Button size="icon" variant="flat" color="light">
                <MenuIcon>
                  <IconDotsVerticalSmall />
                </MenuIcon>
              </Button>
            }
            usePortal={true}
            triggerOnClick={e => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <MenuInfo data={originalData} />

            {deleteFunction && (
              <>
                <MenuDivider />
                {userId === originalData.owner ? (
                  <MenuList>
                    <MenuItem
                      onClick={e => {
                        updateOpen(false, e);
                        deleteFunction({ id });
                      }}
                      iconBefore={<IconTrash />}
                    >
                      <FormattedMessage id="gallery.menu.delete" defaultMessage="Delete" />
                    </MenuItem>
                  </MenuList>
                ) : (
                  <StyledMenuInfo>
                    <FormattedMessage
                      id="gallery.menu.noDelete"
                      defaultMessage="Created by your administrator and cannot be deleted"
                    />
                  </StyledMenuInfo>
                )}
              </>
            )}
          </Menu>
        </MenuWrapper>
      )}
    </>
  );
};

export default MenuComponent;
