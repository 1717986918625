// @flow
import React, { useState, Children, isValidElement, cloneElement } from 'react';
import Tabs from './tabs';
import TabContent from './tab-content';
import { VerticalWrapper, VerticalTabs, VerticalContent } from './styled';
import 'styled-components/macro';

export type Props = {
  items: Array,
  variant: String,
  tabProps: Object,
  tabPosition: Number,
  onTabChange: Function,
  orientation: String,
  inverse: Boolean,
};

const TabsPanel = ({
  tabPosition,
  onTabChange,
  children,
  variant = 'scrollable',
  tabProps,
  orientation = 'horizontal',
  inverse,
  contentProps,
  tabsProps,
  hideTabs,
}: Props) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const currentTab = tabPosition ? tabPosition : value;
  const handleTabChange = onTabChange ? onTabChange : handleChange;

  const getTabs = Children.map(children, child => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        label: child?.props?.label,
        inverse,
        icon: child?.props?.icon,
        children: null,
        ...tabProps,
      });
    }
    return;
  });

  return (
    <>
      {orientation === 'horizontal' ? (
        <>
          {!hideTabs && (
            <Tabs
              value={currentTab}
              variant={variant}
              scrollButtons="auto"
              onChange={handleTabChange}
              inverse={inverse}
              {...tabsProps}
            >
              {getTabs}
            </Tabs>
          )}
          <TabContent value={currentTab} {...contentProps}>
            {children}
          </TabContent>
        </>
      ) : (
        <VerticalWrapper>
          <VerticalTabs>
            {!hideTabs && (
              <Tabs
                value={currentTab}
                variant={variant}
                scrollButtons="auto"
                orientation="vertical"
                onChange={handleTabChange}
                inverse={inverse}
                css={`
                  height: 100%;
                `}
                {...tabsProps}
              >
                {getTabs}
              </Tabs>
            )}
          </VerticalTabs>
          <VerticalContent>
            <TabContent value={currentTab} orientation="vertical" {...contentProps}>
              {children}
            </TabContent>
          </VerticalContent>
        </VerticalWrapper>
      )}
    </>
  );
};

export default TabsPanel;
