/* @flow */
import {
  simpleAction,
  createReducer,
  simpleReducer,
  layoutDesignerInitialState,
} from '@sharkfinesse/sfl-lib';

const namespace = (name: string): string => `@@layoutDesigner/${name}`;

// types
export const types = {
  SHOW_PARENT: namespace('SHOW_PARENT'),
  ROUTE: namespace('ROUTE'),
  SUITE: {
    SELECTED: namespace('SUITE.SELECTED'),
  },
  UNLOAD: namespace('UNLOAD'),
};

const reduceSelectedSuite = simpleReducer('selectedSuiteId');
const reduceShowParent = simpleReducer('showParent');
const reduceRoute = simpleReducer('route');

const reduceUnload = () => layoutDesignerInitialState;

export default createReducer(
  {
    [types.SUITE.SELECTED]: reduceSelectedSuite,
    [types.SHOW_PARENT]: reduceShowParent,
    [types.ROUTE]: reduceRoute,
    [types.UNLOAD]: reduceUnload,
  },
  layoutDesignerInitialState
);

//Action Creators

const updateSelectedSuite = simpleAction(types.SUITE.SELECTED);
const updateDragging = simpleAction(types.DRAGGING);
const updateShowParent = simpleAction(types.SHOW_PARENT);
const updateRoute = simpleAction(types.ROUTE);
const updateUnload = simpleAction(types.UNLOAD);

export const actionCreators = {
  updateSelectedSuite,
  updateDragging,
  updateShowParent,
  updateRoute,
  updateUnload,
};
