/* @flow */
import {
  createReducer,
  dropLast,
  filter,
  flatten,
  isEmpty,
  isNil,
  map,
  omit,
  pipe,
  prepend,
  repeat,
  simpleAction,
  simpleActionMeta,
  sessionCashflowInitialState,
} from '@sharkfinesse/sfl-lib';

const namespace = (name: string): string => `@@session.cashflow/${name}`;

//Actions
export const types = {
  COSTS: {
    NONRECURRING: {
      CALCULATE: namespace('COSTS.NONRECURRING.CALCULATE'),
      RESET: namespace('COSTS.NONRECURRING.RESET'),
      REVERT: namespace('COSTS.NONRECURRING.REVERT'),
      REMOVEROW: namespace('COSTS.NONRECURRING.REMOVEROW'),
      DELETE_ALL: namespace('COSTS.NONRECURRING.DELETE_ALL'),
      UPDATE: namespace('COSTS.NONRECURRING.UPDATE'),
      REVERT_EDIT: namespace('COSTS.NONRECURRING.REVERT_EDIT'),
    },
    RECURRING: {
      CALCULATE: namespace('COSTS.RECURRING.CALCULATE'),
      RESET: namespace('COSTS.RECURRING.RESET'),
      REVERT: namespace('COSTS.RECURRING.REVERT'),
      REMOVEROW: namespace('COSTS.RECURRING.REMOVEROW'),
      DELETE_ALL: namespace('COSTS.RECURRING.DELETE_ALL'),
      UPDATE: namespace('COSTS.RECURRING.UPDATE'),
      REVERT_EDIT: namespace('COSTS.RECURRING.REVERT_EDIT'),
    },
    RESET: namespace('COSTS.RESET'),
    REVERT: namespace('COSTS.REVERT'),
  },
  EVALUATOR: {
    CALCULATE: namespace('EVALUATOR.CALCULATE'),
    RESET: namespace('EVALUATOR.RESET'),
    REVERT: namespace('EVALUATOR.REVERT'),
    REVERT_ALL: namespace('EVALUATOR.REVERT_ALL'),
    DELETE_ALL: namespace('EVALUATOR.DELETE_ALL'),
    INSERT: namespace('EVALUATOR.INSERT'),
    UPDATE: namespace('EVALUATOR.UPDATE'),
    REVERT_EDIT: namespace('EVALUATOR.REVERT_EDIT'),
    UPDATE_ALL: namespace('EVALUATOR.UPDATE_ALL'),
  },
  UPDATE: namespace('UPDATE'),
  PERIOD_UPDATE: namespace('PERIOD_UPDATE'),
};

//Reducer
export const initialState = sessionCashflowInitialState;

const reduceCostsNonRecurringCalculate = (state, action) => {
  const removeEmpty = cost => {
    if (!isEmpty(cost)) {
      return cost;
    }
  };

  return {
    ...state,
    nonRecurringCosts: pipe(
      map(cost => cost.cashflow),
      filter(removeEmpty)
    )(action.payload.costs),
  };
};

const reduceCostsNonRecurringReset = (state, action) => {
  return {
    ...state,
    nonRecurringCosts: {},
    editted_nonRecurringCosts: {},
  };
};

const reduceCostsNonRecurringRevert = (state, action) => {
  return {
    ...state,
    nonRecurringCosts: action.payload,
  };
};

const reduceCostsNonRecurringRemoveRow = (state, action) => {
  return {
    ...state,
    nonRecurringCosts: omit([action.payload.id], state.nonRecurringCosts),
    editted_nonRecurringCosts: omit([action.payload.id], state.editted_nonRecurringCosts),
  };
};

const reduceCostsNonRecurringDeleteAll = (state, action) => {
  return {
    ...state,
    nonRecurringCosts: {},
  };
};

const reduceCostsRecurringCalculate = (state, action) => {
  const removeEmpty = cost => {
    if (!isEmpty(cost)) {
      return cost;
    }
  };

  return {
    ...state,
    recurringCosts: pipe(
      map(cost => cost.cashflow),
      filter(removeEmpty)
    )(action.payload.costs),
  };
};

const reduceCostsRecurringReset = (state, action) => {
  return {
    ...state,
    recurringCosts: {},
    editted_recurringCosts: {},
  };
};

const reduceCostsRecurringRevert = (state, action) => {
  return {
    ...state,
    recurringCosts: action.payload,
  };
};

const reduceCostsRecurringRemoveRow = (state, action) => {
  return {
    ...state,
    recurringCosts: omit([action.payload.id], state.recurringCosts),
    editted_recurringCosts: omit([action.payload.id], state.editted_recurringCosts),
  };
};

const reduceCostsRecurringDeleteAll = (state, action) => {
  return {
    ...state,
    recurringCosts: {},
  };
};

const reduceEvaluatorCalculate = (state, action) => {
  if (isNil(action.payload.calculation_cashflow)) {
    return {
      ...state,
      benefits: {
        ...omit([action.payload.id], state.benefits),
      },
    };
  }

  return {
    ...state,
    benefits: {
      ...state.benefits,
      [action.payload.id]: action.payload.calculation_cashflow,
    },
  };
};

const reduceEvaluatorReset = (state, action) => {
  return {
    ...state,
    benefits: omit([action.payload.id], state.benefits),
    editted_benefits: omit([action.payload.id], state.editted_benefits),
  };
};

const reduceEvaluatorRevert = (state, action) => {
  return {
    ...state,
    benefits: {
      ...state.benefits,
      [action.payload.id]: action.payload.data,
    },
  };
};
const reduceEvaluatorRevertAll = (state, action) => {
  return {
    ...state,
    benefits: action.payload,
  };
};

const reduceEvaluatorDeleteAll = (state, action) => {
  return {
    ...state,
    benefits: {},
    editted_benefits: {},
  };
};

const reduceCostsReset = (state, actions) => {
  return {
    ...state,
    nonRecurringCosts: {},
    recurringCosts: {},
    editted_recurringCosts: {},
    editted_nonRecurringCosts: {},
  };
};

const reduceCostsRevert = (state, actions) => {
  return {
    ...state,
    nonRecurringCosts: actions.payload.previousNonRecurringCashflow,
    recurringCosts: actions.payload.previousRecurringCashflow,
  };
};

const reduceEvaluatorInsert = (state, action) => {
  let result = {
    ...state,
    benefits: {
      ...state.benefits,
      [action.payload.id]: action.payload.benefits,
    },
  };

  if (!isEmpty(action.payload.editted_benefits)) {
    result = {
      ...result,
      editted_benefits: {
        ...state.editted_benefits,
        [action.payload.id]: action.payload.editted_benefits,
      },
    };
  }
  return result;
};

const reduceUpdate = (state, action) => {
  return action.payload;
};

const reduceCostsNonRecurringUpdate = (state, action) => ({
  ...state,
  editted_nonRecurringCosts: {
    ...state.editted_nonRecurringCosts,
    [action.payload.id]: action.payload.value,
  },
});

const reduceCostsRecurringUpdate = (state, action) => ({
  ...state,
  editted_recurringCosts: {
    ...state.editted_recurringCosts,
    [action.payload.id]: action.payload.value,
  },
});

const reduceEvaluatorUpdate = (state, action) => {
  return {
    ...state,
    editted_benefits: {
      ...state.editted_benefits,
      [action.payload.id]: action.payload.value,
    },
  };
};

const reduceCostsNonRecurringRevertEdit = (state, action) => {
  return {
    ...state,
    editted_nonRecurringCosts: omit([action.payload.id], state.editted_nonRecurringCosts),
  };
};

const reduceCostsRecurringRevertEdit = (state, action) => {
  return {
    ...state,
    editted_recurringCosts: omit([action.payload.id], state.editted_recurringCosts),
  };
};

const reduceEvaluatorRevertEdit = (state, action) => {
  return {
    ...state,
    editted_benefits: omit([action.payload.id], state.editted_benefits),
  };
};

const reducePeriodUpdate = (state, action) => {
  function addTrailingZeros(x) {
    if (x.length <= action.payload.endMonth) {
      let y = repeat(0, action.payload.endMonth - x.length + 1);
      return pipe(prepend, flatten)(x, y);
    } else if (x.length > action.payload.endMonth) {
      return dropLast(x.length - action.payload.endMonth - 1, x);
    }
    return x;
  }

  let editted_nonRecurringCosts = isEmpty(state.editted_nonRecurringCosts)
    ? {}
    : map(addTrailingZeros, state.editted_nonRecurringCosts);
  let editted_recurringCosts = isEmpty(state.editted_recurringCosts)
    ? {}
    : map(addTrailingZeros, state.editted_recurringCosts);
  let editted_benefits = isEmpty(state.editted_benefits)
    ? {}
    : map(addTrailingZeros, state.editted_benefits);

  return {
    ...state,
    editted_nonRecurringCosts,
    editted_recurringCosts,
    editted_benefits,
  };
};

export default createReducer(
  {
    [types.COSTS.NONRECURRING.CALCULATE]: reduceCostsNonRecurringCalculate,
    [types.COSTS.NONRECURRING.RESET]: reduceCostsNonRecurringReset,
    [types.COSTS.NONRECURRING.REVERT]: reduceCostsNonRecurringRevert,
    [types.COSTS.NONRECURRING.REMOVEROW]: reduceCostsNonRecurringRemoveRow,
    [types.COSTS.NONRECURRING.DELETE_ALL]: reduceCostsNonRecurringDeleteAll,
    [types.COSTS.RECURRING.CALCULATE]: reduceCostsRecurringCalculate,
    [types.COSTS.RECURRING.RESET]: reduceCostsRecurringReset,
    [types.COSTS.RECURRING.REVERT]: reduceCostsRecurringRevert,
    [types.COSTS.RECURRING.REMOVEROW]: reduceCostsRecurringRemoveRow,
    [types.COSTS.RECURRING.DELETE_ALL]: reduceCostsRecurringDeleteAll,
    [types.EVALUATOR.CALCULATE]: reduceEvaluatorCalculate,
    [types.EVALUATOR.RESET]: reduceEvaluatorReset,
    [types.EVALUATOR.REVERT]: reduceEvaluatorRevert,
    [types.EVALUATOR.REVERT_ALL]: reduceEvaluatorRevertAll,
    [types.EVALUATOR.DELETE_ALL]: reduceEvaluatorDeleteAll,
    [types.EVALUATOR.INSERT]: reduceEvaluatorInsert,
    [types.EVALUATOR.UPDATE_ALL]: reduceUpdate,
    [types.COSTS.RESET]: reduceCostsReset,
    [types.COSTS.REVERT]: reduceCostsRevert,
    [types.UPDATE]: reduceUpdate,
    [types.COSTS.NONRECURRING.UPDATE]: reduceCostsNonRecurringUpdate,
    [types.COSTS.RECURRING.UPDATE]: reduceCostsRecurringUpdate,
    [types.EVALUATOR.UPDATE]: reduceEvaluatorUpdate,
    [types.COSTS.NONRECURRING.REVERT_EDIT]: reduceCostsNonRecurringRevertEdit,
    [types.COSTS.RECURRING.REVERT_EDIT]: reduceCostsRecurringRevertEdit,
    [types.EVALUATOR.REVERT_EDIT]: reduceEvaluatorRevertEdit,
    [types.PERIOD_UPDATE]: reducePeriodUpdate,
  },
  initialState
);

//Action Creators

const updateEvaluatorResult = simpleActionMeta(types.EVALUATOR.CALCULATE);

const updateEvaluatorReset = simpleActionMeta(types.EVALUATOR.RESET);

const updateEvaluatorRevert = simpleAction(types.EVALUATOR.REVERT);

const updateAllEvaluatorRevert = simpleAction(types.EVALUATOR.REVERT_ALL);

const updateNonRecurringCostsResult = simpleActionMeta(types.COSTS.NONRECURRING.CALCULATE);

const updateNonRecurringCostsReset = simpleAction(types.COSTS.NONRECURRING.RESET);

const updateNonRecurringCostsRevert = simpleAction(types.COSTS.NONRECURRING.REVERT);

const updateNonRecurringCostsRemoveRow = simpleAction(types.COSTS.NONRECURRING.REMOVEROW);

const updateNonRecurringCostsDeleteAll = simpleAction(types.COSTS.NONRECURRING.DELETE_ALL);

const updateRecurringCostsResult = simpleActionMeta(types.COSTS.RECURRING.CALCULATE);

const updateRecurringCostsReset = simpleAction(types.COSTS.RECURRING.RESET);

const updateRecurringCostsRevert = simpleAction(types.COSTS.RECURRING.REVERT);

const updateRecurringCostsRemoveRow = simpleAction(types.COSTS.RECURRING.REMOVEROW);

const updateRecurringCostsDeleteAll = simpleAction(types.COSTS.RECURRING.DELETE_ALL);

const updateEvaluatorDeleteAll = simpleActionMeta(types.EVALUATOR.DELETE_ALL);

const updateEvaluatorUpdateAll = simpleActionMeta(types.EVALUATOR.UPDATE_ALL);

const updateCostsReset = simpleActionMeta(types.COSTS.RESET);

const updateCostsRevert = simpleAction(types.COSTS.REVERT);

const updateEvaluatorInsert = simpleAction(types.EVALUATOR.INSERT);

const update = simpleAction(types.UPDATE);

const updateNonRecurringCashflow = simpleAction(types.COSTS.NONRECURRING.UPDATE);
const updateRecurringCashflow = simpleAction(types.COSTS.RECURRING.UPDATE);
const updateEvaluatorCashflow = simpleAction(types.EVALUATOR.UPDATE);

const updateNonRecurringCostsRevertEdittedCashflow = simpleAction(
  types.COSTS.NONRECURRING.REVERT_EDIT
);

const updateRecurringCostsRevertEdittedCashflow = simpleAction(types.COSTS.RECURRING.REVERT_EDIT);

const updateEvaluatorRevertEdittedCashflow = simpleAction(types.EVALUATOR.REVERT_EDIT);

const updateEdittedPeriodChange = simpleActionMeta(types.PERIOD_UPDATE);

export const actionCreators = {
  updateNonRecurringCostsResult,
  updateNonRecurringCostsReset,
  updateNonRecurringCostsRevert,
  updateNonRecurringCostsRemoveRow,
  updateNonRecurringCostsDeleteAll,
  updateRecurringCostsResult,
  updateRecurringCostsReset,
  updateRecurringCostsRevert,
  updateRecurringCostsRemoveRow,
  updateRecurringCostsDeleteAll,
  updateEvaluatorResult,
  updateEvaluatorReset,
  updateEvaluatorRevert,
  updateEvaluatorDeleteAll,
  updateEvaluatorUpdateAll,
  updateAllEvaluatorRevert,
  updateCostsReset,
  updateCostsRevert,
  updateEvaluatorInsert,
  update,
  updateNonRecurringCashflow,
  updateRecurringCashflow,
  updateNonRecurringCostsRevertEdittedCashflow,
  updateRecurringCostsRevertEdittedCashflow,
  updateEvaluatorCashflow,
  updateEvaluatorRevertEdittedCashflow,
  updateEdittedPeriodChange,
};
