"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

const getServiceWorker = state => state.serviceWorker;

const getUpdated = state => state.serviceWorker.updated;

const selectors = {
  getServiceWorker,
  getUpdated
};
var _default = selectors;
exports.default = _default;