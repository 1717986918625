import { select, call, put, all } from 'redux-saga/effects';
import { actionCreators as userActionCreators } from 'redux/modules/user/user';
import rsfApp from '../rsf';
import { history } from '../../index';
import tea from 'mta-tea';
import { isNil, loginSelectors, userSelectors } from '@sharkfinesse/sfl-lib';
import { persistor } from '../../index';
import reloadApp from 'redux/sagas/utils/reloadApp';

function* rootSaga() {
  const [userId, currentProduct] = yield all([
    select(loginSelectors.getUID),
    select(userSelectors.getProduct),
  ]);
  const user = yield call(rsfApp.rsf.firestore.getDocument, `/users/${userId}`);

  if (user.exists) {
    const userData = user.data();

    if (!isNil(currentProduct) && currentProduct !== userData.product) {
      yield call([persistor, persistor.purge]);
      yield call(reloadApp);
    }

    const key = process.env.REACT_APP_LOG_ROCKET_KEY;
    const encryptedUserId = yield call([tea, tea.encode], userId, key);

    yield put(userActionCreators.loaded({ encryptedUserId, ...userData }));
  } else {
    yield call([history, history.push], { pathname: '/logout' });
  }
}

export default rootSaga;
