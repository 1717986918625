import { select, fork, call, takeLatest } from 'redux-saga/effects';
import { setUpdateCache, loginSelectors } from '@sharkfinesse/sfl-lib';
import {
  actionCreators as preferencesActionCreators,
  types as preferencesTypes,
} from '../modules/preferences';
import rsfApp, { clearPersistence } from '../rsf';
import reloadApp from 'redux/sagas/utils/reloadApp';

const preferencesTransformer = preferences => {
  if (!preferences.metadata.hasPendingWrites) {
    return preferences.data();
  } else {
    return false;
  }
};

export function* syncPreferences() {
  const user = yield select(loginSelectors.getUID);

  yield fork(rsfApp.rsf.firestore.syncDocument, `/preferences/${user}`, {
    successActionCreator: preferencesActionCreators.sync,
    transform: preferencesTransformer,
  });
}

export function* onClose({ payload: { previousOfflineEnabled, offlineEnabled } }) {
  if (previousOfflineEnabled && !offlineEnabled) {
    //disabling offline
    yield call(clearPersistence);
    yield call(reloadApp);
    // yield call(reloadApp);
  } else if (!previousOfflineEnabled && offlineEnabled) {
    //enabling offline

    yield call(setUpdateCache, 'true');
    yield call(reloadApp);
  }
}

export const effects = [takeLatest(preferencesTypes.ON_CLOSE, onClose)];

export default effects;
