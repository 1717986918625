// @flow
import React, { useState } from 'react';
import { withTheme } from 'styled-components';
import { IconAnimated } from '../icon';
import {
  MenuItemWrapper,
  MenuLabelTitle,
  MenuLabelWrapper,
  MenuItemIcon,
  MenuLabelSubtitle,
} from './styled';
import ProIcon from '../pro-icon';
import { PinTopRight } from '../general-styles';

export type Props = {
  label?: Element<*> | String,
  title?: Element<*> | String,
  icon?: Element<*> | String,
  theme: Object,
  onClick: Function,
  selected: Boolean,
  subtitle?: Element<*> | String,
  iconLeft: Boolean,
};

const MenuItem = ({
  icon,
  label,
  theme,
  onClick,
  selected,
  title,
  subtitle,
  iconLeft,
  proIcon,
  ...props
}: Props) => {
  const [playing, setPlaying] = useState('stopped');
  return (
    <MenuItemWrapper
      iconLeft={iconLeft}
      onMouseEnter={() => {
        icon && setPlaying('playing');
      }}
      onMouseLeave={() => {
        icon && setPlaying('stopped');
      }}
      onClick={onClick}
      {...props}
    >
      {proIcon && (
        <PinTopRight>
          <ProIcon />
        </PinTopRight>
      )}
      {icon && (
        <MenuItemIcon iconLeft={iconLeft}>
          <IconAnimated
            playingState={playing}
            speed={1}
            direction={1}
            primaryColor={theme.sidebar.menuItemIconColor}
            secondaryColor={theme.sidebar.menuItemIconColor}
            size="width: 52px; height: 52px;"
            animationData={icon}
          />
        </MenuItemIcon>
      )}
      <MenuLabelWrapper iconLeft={iconLeft}>
        <MenuLabelTitle iconLeft={iconLeft}>{title}</MenuLabelTitle>
        {subtitle && <MenuLabelSubtitle>{subtitle}</MenuLabelSubtitle>}
      </MenuLabelWrapper>
    </MenuItemWrapper>
  );
};

export default withTheme(MenuItem);
