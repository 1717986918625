import { css } from 'styled-components';

const Checkbox = ({ font, colors, scale }) => ({
  base: ({ checked, round, indeterminate, color }) => {
    const mainColor = color ? color : colors.primary;
    return css`
      display: inline-block;
      min-width: ${round ? '22px' : '16px'};
      min-height: ${round ? '22px' : '16px'};
      max-width: ${round ? '22px' : '16px'};
      max-height: ${round ? '22px' : '16px'};
      border-radius: ${round ? '11px' : '3px'};
      background: ${checked || indeterminate ? mainColor : colors.white};

      ${indeterminate
        ? css`
            box-shadow: inset 0 0 0 2px ${mainColor}, inset 0 0 0 4px ${colors.white};
          `
        : round && !checked
        ? css`
            box-shadow: 0 0 0 1px ${colors.uiLight};
          `
        : css`
            box-shadow: inset 0 0 0 2px ${mainColor};
          `}
    `;
  },

  checkboxContainer: () => css`
    display: flex;
    vertical-align: middle;
    line-height: 0.2;
    align-items: center;
  `,
  icon: ({ round, checked }) => css`
    fill: none;
    stroke: ${checked ? colors.white : round ? colors.uiLight : 'none'};
    stroke-width: 2px;
    margin: ${round ? '1px' : 0};
    ${!round &&
    !checked &&
    css`
      visibility: hidden;
    `}
  `,
  hiddenCheckbox: () => css`
    border: 0;
    opacity: 0;
    clippath: inset(50%);
    height: 20px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 20px;
  `,

  label: () => css`
    margin-left: 8px;
    padding-bottom: 1px;
    line-height: 14px;
  `,
});

export default Checkbox;
