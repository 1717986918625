import { types } from 'redux/modules/tender';
import { put, call, takeLatest } from 'redux-saga/effects';
import { actionCreators as tenderActionCreators } from 'redux/modules/tender';

import undoToast from 'redux/sagas/toast/undo';

function* deleteAll(action) {
  const { meta } = action;
  yield call(undoToast, {
    action,
    undoActions: [put(tenderActionCreators.updateDeleteAll({ meta }))],
    formattedMessageId: 'tender.delete.all.toast.message',
    defaultMessage: 'Cost of Tender Deleted',
  });
}

export const effects = [takeLatest(types.TRIGGER_DELETE, deleteAll)];

export default effects;
