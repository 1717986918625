import { connect } from 'react-redux';
import { userSelectors } from '@sharkfinesse/sfl-lib';
import SharedToList from 'components/shared-to-list/shared-to-list';

const mapStateToProps = (state, props) => ({
  account: userSelectors.getAccount(state, props),
  userId: userSelectors.getId(state, props),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SharedToList);
