// @flow
import React from 'react';
import type { Element } from 'react';
import styled, { css } from 'styled-components';
import { map } from 'styled-components-breakpoint';
import { grid } from 'styled-components-grid';
import { prop, ifProp } from 'styled-tools';

const ColumnGridUnit = styled.div`
  ${({ theme, size, padding, margin }) => css`
    ${ifProp(
      'size',
      grid.unit({
        size: size,
      })
    )};
    ${ifProp('padding', map(padding, c => `padding: ${c};`, theme.breakpoints))};
    ${ifProp('margin', map(margin, c => `margin: ${c};`, theme.breakpoints))};
  `};
  ${() => prop('styles')};
`;
type DefaultProps = {
  size: Object,
};

export type Props = DefaultProps & {
  children?: Element<*> | string,
  DrawerProps?: Object,
  size?: Object,
  padding?: Object,
  marign?: Object,
  styles?: Any,
};

const Column = ({ children, DrawerProps, styles, ...other }: Props): React.Element<any> => (
  <ColumnGridUnit styles={styles} {...other}>
    {children}
  </ColumnGridUnit>
);

export default Column;
