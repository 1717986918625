// @flow
import React from 'react';
import moment from 'moment';
import {
  Button,
  Section,
  BodyTitle,
  FlexRow,
  FlexColumn,
  ListWithDivider,
  SpaceBetweenRow,
  IconEmailFast,
  LoaderRing,
  SemiBold,
} from '@sharkfinesse/sfl-ui';
import { reverse } from '@sharkfinesse/sfl-lib';
import { FormattedMessage } from 'react-intl';
import { useModal } from '@ebay/nice-modal-react';
import ModalInfo from 'components/modal/modal-info';
import { useSessionDetails } from 'redux/firestore/hooks';
import Avatar from 'components/avatar';

const SharedToList = ({ sessionId, account, userId }) => {
  const { data, isLoading } = useSessionDetails({ id: sessionId, account });
  const infoModal = useModal(ModalInfo);

  return isLoading ? (
    <div style={{ justifyContent: 'center', display: 'flex' }}>
      <LoaderRing />
    </div>
  ) : data?.sharedTo && data.owner === userId ? (
    <Section style={{ marginTop: '20px' }}>
      <BodyTitle as="h2">
        <FormattedMessage id="share.copy.history.title" defaultMessage="History" />
      </BodyTitle>
      <ListWithDivider>
        {reverse(data.sharedTo).map(({ message, date, users }, key) => (
          <SpaceBetweenRow key={key} as="li" style={{ wordBreak: 'break-all' }}>
            <FlexColumn style={{ alignSelf: 'center', width: '100%' }}>
              <SpaceBetweenRow style={{ alignItems: 'center' }}>
                <SemiBold as="span">{date && moment(date.toDate()).format('LLL')}</SemiBold>
                {message && (
                  <Button
                    size="icon"
                    variant="flat"
                    color="light"
                    tooltipConfig={{
                      usePortal: true,
                    }}
                    onClick={() => {
                      infoModal.show({
                        title: (
                          <FormattedMessage
                            id="share.copy.history.message.modal.title"
                            defaultMessage="Message sent"
                          />
                        ),
                        body: message,
                      });
                    }}
                    tooltip={
                      <FormattedMessage
                        id="share.copy.history.message.modal.tooltip"
                        defaultMessage="Show message sent"
                      />
                    }
                  >
                    <IconEmailFast />
                  </Button>
                )}
              </SpaceBetweenRow>

              {users.map(({ name, email, id }, key) => (
                <FlexRow
                  key={key}
                  style={{ marginLeft: '8px', marginTop: '12px', lineHeight: '18px' }}
                >
                  <Avatar
                    uid={id}
                    name={name}
                    email={email}
                    size={26}
                    style={{ marginRight: '10px' }}
                  />
                  <FlexColumn>
                    <SemiBold as="span">{name}</SemiBold>
                    <span>{email}</span>
                  </FlexColumn>
                </FlexRow>
              ))}
            </FlexColumn>
          </SpaceBetweenRow>
        ))}
      </ListWithDivider>
    </Section>
  ) : null;
};

export default SharedToList;
