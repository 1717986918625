import { call, delay } from 'redux-saga/effects';

function* retryFetch(request, props, attempts = 2) {
  for (let i = 0; i < attempts + 1; i++) {
    try {
      const apiResponse = yield call(request, props);
      return apiResponse;
    } catch (err) {
      console.log('err', err);
      console.log('props', props);
      if (i < attempts) {
        yield delay(2000);
      }
    }
  }
  // attempts failed after 5 attempts
  throw new Error('Fetch request failed');
}

export default retryFetch;
