// @flow
import React from 'react';
import Button from '../button';
import { IconCrown } from '../icon';
import { FormattedMessage } from 'react-intl';
import { ProIcon } from './styled';

const Comp = ({
  disabled,
  saveStarred,
  tooltip = <FormattedMessage id="features.pro.icon.tooltip" defaultMessage="Pro feature" />,
  ...props
}) => {
  return (
    <Button
      size="icon"
      variant="flat"
      color="light"
      tooltip={tooltip}
      tooltipConfig={{
        usePortal: true,
      }}
      {...props}
    >
      <ProIcon $disabled={disabled}>
        <IconCrown size="width: 12px; height: 12px;" viewBox="0 0 12 12" />
      </ProIcon>
    </Button>
  );
};

export default Comp;
