import { css } from 'styled-components';
import { setAlphaHsla } from '@sharkfinesse/sfl-lib';

export const ListPanel = ({ font, colors, scale, device: { min_xxs, min_xs, min_md } }) => ({
  wrapper: () => css`
    grid-row-start: 1;
    grid-column-start: 1;
    position: relative;
    pointer-events: none;
    height: 100%;
    width: 100%;
    z-index: 3;
  `,
  container: () => css`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    pointer-events: none;
  `,
  background: ({ $draggable, $position, $background }) => css`
    display: flex;
    height: 100%;
    width: 100%;
    background: ${$background === '2' ? colors.white : colors.uiLightest};
    box-shadow: ${$position === 'right' ? '-1px' : '1px'} 0px 0px 0px hsl(0deg 0% 0% / 30%);
    pointer-events: auto;
    overflow: hidden;
    ${$draggable &&
    css`
      box-shadow: 0 0 0 1px rgb(128 128 128 / 35%), 0px 1px 15px 2px rgb(0 0 0 / 25%);
      border-radius: 4px;
    `}
  `,
  contents: () => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  `,
  shadow: ({ expanded }) => css`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 16px;
    background: linear-gradient(to left, rgb(0 0 0 / 6%) 0%, rgba(0, 0, 0, 0) 100%);
    opacity: ${expanded ? 1 : 0};
    z-index: 3;
    pointer-events: none;
    transition: opacity 0.1s;
    transition-delay: ${expanded ? '0s' : '0.2s'};
  `,
  dropShadow: ({ expanded, $position }) => css`
    ${$position === 'right'
      ? css`
          left: -18px;
          background: linear-gradient(to left, rgb(0 0 0 / 12%) 0%, rgba(0, 0, 0, 0) 100%);
        `
      : css`
          right: -18px;
          background: linear-gradient(to right, rgb(0 0 0 / 12%) 0%, rgba(0, 0, 0, 0) 100%);
        `}
    width: 18px;
    z-index: 1;
  `,
  toggleArrow: ({ expanded }) => css`
    display: flex;
    position: absolute;
    top: calc(50% - 6px);
    color: ${colors.uiDark};
    width: 12px;
    height: 12px;
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    ${!expanded
      ? css`
          transform: rotate(180deg);
          left: 3px;
        `
      : null}
  `,
  toggleWrapper: () => css`
    pointer-events: auto;
    transform: rotate(180deg);
    filter: ${`drop-shadow(1px 0px 0px ${colors.uiLight})`};
    display: flex;
    justify-content: center;
    width: 20px;
    color: ${colors.uiLightest};
    cursor: pointer;
    display: flex;
    height: 118px;
    align-self: center;
    margin-right: -2px;
    z-index: 2;
  `,

  pin: () => css`
    position: absolute;
    width: 8px;
    top: 0;
    bottom: 0;
    background: ${setAlphaHsla(0.75, colors.secondary)};
    right: 0;
    z-index: 9001;
}
  `,
  pinRight: () => css`
    right: 0;
  `,
  pinLeft: () => css`
    left: 0;
  `,
  title: () => css`
    color: ${font.color};
    font-size: 20px;
    font-weight: 600;
    margin: 0;
  `,
  header: () => css`
    display: flex;
    flex-direction: row;
    padding: 16px 16px 0 16px;
    justify-content: space-between;
    align-items: center;
  `,
});

export default ListPanel;
