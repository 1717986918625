"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setUpdateCache = exports.setOfflineEnabledModal = exports.setOfflineEnabled = exports.getUpdateCache = exports.getOfflineEnabledModal = exports.getOfflineEnabled = void 0;

/* eslint-disable fp/no-mutation  */
const getOfflineEnabled = () => JSON.parse(localStorage.getItem('offline'));

exports.getOfflineEnabled = getOfflineEnabled;

const getOfflineEnabledModal = () => JSON.parse(localStorage.getItem('offlineModal'));

exports.getOfflineEnabledModal = getOfflineEnabledModal;

const getUpdateCache = () => JSON.parse(localStorage.getItem('updateCache'));

exports.getUpdateCache = getUpdateCache;

const setOfflineEnabled = value => localStorage.setItem('offline', value);

exports.setOfflineEnabled = setOfflineEnabled;

const setOfflineEnabledModal = value => localStorage.setItem('offlineModal', value);

exports.setOfflineEnabledModal = setOfflineEnabledModal;

const setUpdateCache = value => localStorage.setItem('updateCache', value);

exports.setUpdateCache = setUpdateCache;