/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

const IconComponent = props => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M27.3,6.7c-5.7-5.7-14.9-5.7-20.5,0c-5.7,5.7-5.7,14.9,0,20.5c2.8,2.8,6.5,4.2,10.3,4.2
		c3.7,0,7.4-1.4,10.3-4.2c0.1-0.1,0.1-0.1,0.2-0.2v1.1c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8V25c0-0.4-0.3-0.8-0.8-0.8h-3.2
		c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h1.7c-0.1,0.1-0.3,0.3-0.4,0.4c-5.1,5.1-13.3,5.1-18.4,0c-5.1-5.1-5.1-13.3,0-18.4
		c5.1-5.1,13.3-5.1,18.4,0c2.5,2.5,3.9,5.9,3.8,9.4c0,0.4,0.3,0.8,0.7,0.8c0,0,0,0,0,0c0.4,0,0.7-0.3,0.8-0.7
		C31.6,13.3,30.1,9.5,27.3,6.7z"
    />
    <path
      fill="currentColor"
      d="M17.7,7.3c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8v9H10c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h7
		c0.4,0,0.8-0.3,0.8-0.8c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1V7.3z"
    />
  </Icon>
);

export default memo(IconComponent);
