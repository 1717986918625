import { css } from 'styled-components';
import styledBreakpoint from 'styled-components-breakpoint';
import { lightenHsla } from '@sharkfinesse/sfl-lib';

export const Styles = ({ font, colors, scale }) => ({
  calculatorWrapper: () => css`
    width: 100%;
  `,
  descriptionWrapper: () => css`
    width: 100%;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    ${styledBreakpoint('xxs')`
        margin-bottom: 20px;
  `};
    ${styledBreakpoint('xs')`
    margin-bottom: 20px;
  `};
    ${styledBreakpoint('sm')`
    margin-bottom: 0px;

  `};
  `,
  value: () => css`
    font-size: 34px;
    color: ${colors.primary};

    ${styledBreakpoint('xxs', 'md')`
      font-size: 22px;
    `};
    text-align: center;
  `,
  label: () => css`
    font-size: 14px;
    font-weight: ${font.weightSemiBold};
    ${styledBreakpoint('xxs', 'md')`
    font-size: 14px;
    `};
    text-align: center;
  `,
  resultPages: () => css`
    display: flex;
    flex-direction: column;
    align-self: center;
  `,
  animatedGraphic: () => css`
    margin-top: -6%;
    width: 70%;
    height: 70%;
    align-self: center;
    ${styledBreakpoint('xxs')`
    width: 90%;
    height: 90%;
    margin-top: 0;
  `};
    ${styledBreakpoint('xs')`
    width: 90%;
    height: 90%;
    margin-top: 0;
  `};
    ${styledBreakpoint('sm')`
    width: 90%;
    height: 90%;
    margin-top: 0;
  `};
    ${styledBreakpoint('md')`  
    width: 90%;
    height: 100%;
  `};
    /* filter: hue-rotate(90deg); */
    .graphicMainFill {
      fill: ${colors.primary};
    }
  `,
  lostValueWrapper: () => css`
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px 0px;
    background: ${lightenHsla(4, colors.uiLightest)};
    border-radius: 6px;
    padding: 6px 40px;
    align-self: center;
    ${styledBreakpoint('xxs')`
        margin-bottom: 6px;
        width: 100%;
  `};
    ${styledBreakpoint('xs')`
    margin-bottom: 6px;
        width: 100%;
  `};
    ${styledBreakpoint('sm')`
    margin-right: 3px;
        width: 100%;

  `};
    ${styledBreakpoint('md')`
  margin-bottom: 6px;
  margin-right: 3px;
  width: unset;
  `};
  `,
  centerAlign: () => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
  `,
});

export default Styles;
