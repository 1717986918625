import { css } from 'styled-components';
import { lightenHsla } from '@sharkfinesse/sfl-lib';

const styles = ({ font, colors, scale }) => ({
  detailsContainer: () => css`
    background-color: ${lightenHsla(3, colors.uiLightest)};
    padding: 6px 20px;
    border-bottom: 1px solid ${colors.uiLight};
    font-size: 12px;
    display: flex;
    flex-direction: column;
  `,
  detailsList: () => css`
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      padding: 4px 0;
    }
  `,
});

export default styles;
