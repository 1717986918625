/* @flow */
import {
  createReducer,
  simpleAction,
  simpleReducer,
  sessionDelayInitialState,
} from '@sharkfinesse/sfl-lib';
const namespace = (name: string): string => `@@session.delay/${name}`;

//Actions
export const types = {
  DELAY_PERIOD: { UPDATE: namespace('DELAY_PERIOD.UPDATE') },
  IS_DIRTY: namespace('IS_DIRTY'),
  IS_USED: namespace('IS_USED'),
  IS_COLLAPSED: namespace('IS_COLLAPSED'),
  DELETE_ALL: namespace('DELETE_ALL'),
  TRIGGER_DELETE: namespace('TRIGGER_DELETE'),
};

//Reducer
export const initialState = sessionDelayInitialState;

const reduceDelayPeriod = simpleReducer('delayPeriod');
const reduceIsDirty = simpleReducer('isDirty');
const reduceIsUsed = simpleReducer('isUsed');
const reduceIsCollapsed = simpleReducer('isCollapsed');
const reduceDeleteAll = (state, action) => {
  return { ...initialState, isCollapsed: state.isCollapsed };
};

export default createReducer(
  {
    [types.DELAY_PERIOD.UPDATE]: reduceDelayPeriod,
    [types.IS_DIRTY]: reduceIsDirty,
    [types.IS_USED]: reduceIsUsed,
    [types.IS_COLLAPSED]: reduceIsCollapsed,
    [types.DELETE_ALL]: reduceDeleteAll,
  },
  initialState
);

//Action Creators
const updateDelayPeriod = simpleAction(types.DELAY_PERIOD.UPDATE);
const updateIsDirty = simpleAction(types.IS_DIRTY);
const updateIsUsed = simpleAction(types.IS_USED);
const updateIsCollapsed = simpleAction(types.IS_COLLAPSED);
const updateDeleteAll = simpleAction(types.DELETE_ALL);
const updateTriggerDelete = simpleAction(types.TRIGGER_DELETE);

export const actionCreators = {
  updateDelayPeriod,
  updateIsDirty,
  updateIsUsed,
  updateIsCollapsed,
  updateDeleteAll,
  updateTriggerDelete,
};
