import {
  generateShortId,
  keys,
  append,
  findIndex,
  equals,
  insert,
  layoutSelectors,
  suiteListSelectors,
} from '@sharkfinesse/sfl-lib';
import { put, call, select, all } from 'redux-saga/effects';
import { actionCreators as layoutActionCreators } from 'redux/modules/layout';
import { actionCreators as layoutDesignerActionCreators } from 'redux/modules/layoutDesigner';
import duplicateBenefits from './duplicateBenefits';
import { actionCreators as suiteListActionCreators } from 'redux/modules/suite-list';
import { actionCreators as appActionCreators } from 'redux/modules/app';

function* newSuite(suite, layout, action) {
  const id = generateShortId();
  const position = findIndex(equals(suite.id))(layout.suitesOrder);
  const duplicatedBenefits = yield duplicateBenefits(suite.benefits);
  return {
    id,
    layout: {
      ...layout,
      benefits: {
        ...layout.benefits,
        ...duplicatedBenefits,
      },
      suites: {
        ...layout.suites,
        [id]: {
          ...suite,
          benefits: keys(duplicatedBenefits),
          id,
        },
      },
      suitesOrder: insert(position + 1, id, layout.suitesOrder),
    },
  };
}
function* duplicateSuite(action) {
  const { suiteId } = action.payload;
  yield put(appActionCreators.updatePersistState(false));
  yield put(layoutActionCreators.duplicatingSuite({ suiteId }));
  const [suite, layout, visibleSuites] = yield all([
    select(layoutSelectors.getSuite, { suiteId }),
    select(layoutSelectors.getLayout),
    select(suiteListSelectors.getVisibleList),
  ]);
  const layoutWithNewSuite = yield call(newSuite, suite, layout);
  yield all([
    put(layoutActionCreators.insertSuite(layoutWithNewSuite.layout)),
    put(
      suiteListActionCreators.updateVisible(append({ value: layoutWithNewSuite.id }, visibleSuites))
    ),
  ]);
  yield put(layoutDesignerActionCreators.updateSelectedSuite(layoutWithNewSuite.id));
  yield put(appActionCreators.updatePersistState(true));
  yield put(layoutActionCreators.duplicatingSuiteComplete({ suiteId }));
}

export default duplicateSuite;
