// @flow
import React, { memo } from 'react';
import { IconChevronDown, IconChevronRight } from '../icon';

type Props = {
  children?: Element<*> | string,
  isLeaf: Boolean,
  expanded: Boolean,
};

const SwitcherIcon = ({ children, isLeaf, expanded }: Props) => {
  if (!children) return null;
  if (children.length === 0) return null;
  // is Leaf
  if (isLeaf) {
    if (expanded) {
      return <IconChevronRight />;
    }
    return <IconChevronDown />;
  }

  return expanded ? <IconChevronDown /> : <IconChevronRight />;
};

export default memo(SwitcherIcon);
