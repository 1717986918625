"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reselect = require("reselect");

var _layout = _interopRequireDefault(require("./layout"));

var _sessionDetails = _interopRequireDefault(require("./session-details"));

var _index = require("../../index");

var _reReselect = _interopRequireDefault(require("re-reselect"));

var _account = _interopRequireDefault(require("./account"));

var _utils = require("@rjsf/core/lib/utils");

var _defaultSessionValues = _interopRequireDefault(require("./default-session-values"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* eslint-disable no-unused-vars  */
// Selectors
const getBenefits = state => state.session.present.benefits;

const getBenefitData = (state, benefitId) => {
  var _state$session$presen, _state$session$presen2, _state$session$presen3;

  return (_state$session$presen = state.session.present) === null || _state$session$presen === void 0 ? void 0 : (_state$session$presen2 = _state$session$presen.benefits) === null || _state$session$presen2 === void 0 ? void 0 : (_state$session$presen3 = _state$session$presen2.data) === null || _state$session$presen3 === void 0 ? void 0 : _state$session$presen3[benefitId];
};

const getStatus = (state, benefitId) => {
  var _state$session$presen4, _state$session$presen5, _state$session$presen6, _state$session$presen7;

  return (_state$session$presen4 = state.session.present) === null || _state$session$presen4 === void 0 ? void 0 : (_state$session$presen5 = _state$session$presen4.benefits) === null || _state$session$presen5 === void 0 ? void 0 : (_state$session$presen6 = _state$session$presen5.data) === null || _state$session$presen6 === void 0 ? void 0 : (_state$session$presen7 = _state$session$presen6[benefitId]) === null || _state$session$presen7 === void 0 ? void 0 : _state$session$presen7.status;
};

const getBenefitSubHeading = (state, benefitId) => {
  var _state$session$presen8, _state$session$presen9, _state$session$presen10, _state$session$presen11;

  return (_state$session$presen8 = state.session.present) === null || _state$session$presen8 === void 0 ? void 0 : (_state$session$presen9 = _state$session$presen8.benefits) === null || _state$session$presen9 === void 0 ? void 0 : (_state$session$presen10 = _state$session$presen9.data) === null || _state$session$presen10 === void 0 ? void 0 : (_state$session$presen11 = _state$session$presen10[benefitId]) === null || _state$session$presen11 === void 0 ? void 0 : _state$session$presen11.subHeading;
};

const getBenefitValues = (state, {
  benefitId
}) => {
  var _state$session$presen12, _state$session$presen13, _state$session$presen14, _state$session$presen15;

  return (_state$session$presen12 = state.session.present) === null || _state$session$presen12 === void 0 ? void 0 : (_state$session$presen13 = _state$session$presen12.benefits) === null || _state$session$presen13 === void 0 ? void 0 : (_state$session$presen14 = _state$session$presen13.data) === null || _state$session$presen14 === void 0 ? void 0 : (_state$session$presen15 = _state$session$presen14[benefitId]) === null || _state$session$presen15 === void 0 ? void 0 : _state$session$presen15.values;
};

const getBenefitValue = (state, benefitId, valueId) => {
  var _state$session$presen16, _state$session$presen17, _state$session$presen18, _state$session$presen19, _state$session$presen20;

  return (_state$session$presen16 = state.session.present) === null || _state$session$presen16 === void 0 ? void 0 : (_state$session$presen17 = _state$session$presen16.benefits) === null || _state$session$presen17 === void 0 ? void 0 : (_state$session$presen18 = _state$session$presen17.data) === null || _state$session$presen18 === void 0 ? void 0 : (_state$session$presen19 = _state$session$presen18[benefitId]) === null || _state$session$presen19 === void 0 ? void 0 : (_state$session$presen20 = _state$session$presen19.values) === null || _state$session$presen20 === void 0 ? void 0 : _state$session$presen20[valueId];
};

const getBenefitResult = (state, benefitId) => {
  var _state$session$presen21, _state$session$presen22, _state$session$presen23, _state$session$presen24;

  return (_state$session$presen21 = state.session.present) === null || _state$session$presen21 === void 0 ? void 0 : (_state$session$presen22 = _state$session$presen21.benefits) === null || _state$session$presen22 === void 0 ? void 0 : (_state$session$presen23 = _state$session$presen22.data) === null || _state$session$presen23 === void 0 ? void 0 : (_state$session$presen24 = _state$session$presen23[benefitId]) === null || _state$session$presen24 === void 0 ? void 0 : _state$session$presen24.result;
};

const getBenefitNotes = (state, benefitId) => {
  var _state$session$presen25, _state$session$presen26, _state$session$presen27, _state$session$presen28;

  return (_state$session$presen25 = state.session.present) === null || _state$session$presen25 === void 0 ? void 0 : (_state$session$presen26 = _state$session$presen25.benefits) === null || _state$session$presen26 === void 0 ? void 0 : (_state$session$presen27 = _state$session$presen26.data) === null || _state$session$presen27 === void 0 ? void 0 : (_state$session$presen28 = _state$session$presen27[benefitId]) === null || _state$session$presen28 === void 0 ? void 0 : _state$session$presen28.notes;
};

const getBenefitPrivateNotes = (state, benefitId) => {
  var _state$session$presen29, _state$session$presen30, _state$session$presen31, _state$session$presen32;

  return (_state$session$presen29 = state.session.present) === null || _state$session$presen29 === void 0 ? void 0 : (_state$session$presen30 = _state$session$presen29.benefits) === null || _state$session$presen30 === void 0 ? void 0 : (_state$session$presen31 = _state$session$presen30.data) === null || _state$session$presen31 === void 0 ? void 0 : (_state$session$presen32 = _state$session$presen31[benefitId]) === null || _state$session$presen32 === void 0 ? void 0 : _state$session$presen32.privateNotes;
};

const getUnits = (state, benefitId) => {
  var _state$session$presen33, _state$session$presen34, _state$session$presen35, _state$session$presen36;

  return (_state$session$presen33 = state.session.present) === null || _state$session$presen33 === void 0 ? void 0 : (_state$session$presen34 = _state$session$presen33.benefits) === null || _state$session$presen34 === void 0 ? void 0 : (_state$session$presen35 = _state$session$presen34.data) === null || _state$session$presen35 === void 0 ? void 0 : (_state$session$presen36 = _state$session$presen35[benefitId]) === null || _state$session$presen36 === void 0 ? void 0 : _state$session$presen36.units;
};

const getBenefitsCurrent = state => state.session.present.benefits.current;

const getEndMonth = (state, benefitId) => {
  var _state$session$presen37, _state$session$presen38, _state$session$presen39, _state$session$presen40;

  return (_state$session$presen37 = state.session.present) === null || _state$session$presen37 === void 0 ? void 0 : (_state$session$presen38 = _state$session$presen37.benefits) === null || _state$session$presen38 === void 0 ? void 0 : (_state$session$presen39 = _state$session$presen38.data) === null || _state$session$presen39 === void 0 ? void 0 : (_state$session$presen40 = _state$session$presen39[benefitId]) === null || _state$session$presen40 === void 0 ? void 0 : _state$session$presen40.endMonth;
};

const getStartMonth = (state, benefitId) => {
  var _state$session$presen41, _state$session$presen42, _state$session$presen43, _state$session$presen44;

  return (_state$session$presen41 = state.session.present) === null || _state$session$presen41 === void 0 ? void 0 : (_state$session$presen42 = _state$session$presen41.benefits) === null || _state$session$presen42 === void 0 ? void 0 : (_state$session$presen43 = _state$session$presen42.data) === null || _state$session$presen43 === void 0 ? void 0 : (_state$session$presen44 = _state$session$presen43[benefitId]) === null || _state$session$presen44 === void 0 ? void 0 : _state$session$presen44.startMonth;
};

const getStartMLinked = (state, benefitId) => {
  var _state$session$presen45, _state$session$presen46, _state$session$presen47, _state$session$presen48;

  return (_state$session$presen45 = state.session.present) === null || _state$session$presen45 === void 0 ? void 0 : (_state$session$presen46 = _state$session$presen45.benefits) === null || _state$session$presen46 === void 0 ? void 0 : (_state$session$presen47 = _state$session$presen46.data) === null || _state$session$presen47 === void 0 ? void 0 : (_state$session$presen48 = _state$session$presen47[benefitId]) === null || _state$session$presen48 === void 0 ? void 0 : _state$session$presen48.startMonthLinked;
};

const getEndMLinked = (state, benefitId) => {
  var _state$session$presen49, _state$session$presen50, _state$session$presen51, _state$session$presen52;

  return (_state$session$presen49 = state.session.present) === null || _state$session$presen49 === void 0 ? void 0 : (_state$session$presen50 = _state$session$presen49.benefits) === null || _state$session$presen50 === void 0 ? void 0 : (_state$session$presen51 = _state$session$presen50.data) === null || _state$session$presen51 === void 0 ? void 0 : (_state$session$presen52 = _state$session$presen51[benefitId]) === null || _state$session$presen52 === void 0 ? void 0 : _state$session$presen52.endMonthLinked;
};

const getStartMonthLinked = (0, _reselect.createSelector)([getStartMLinked, getStartMonth], (startMonthLinked, startMonth) => {
  if (!(0, _index.isNil)(startMonthLinked)) return startMonthLinked;
  return startMonth ? false : true;
});
const getEvaluatorStartMonth = (0, _reselect.createSelector)([getStartMonth, _account.default.getEvaluatorRecurrence, getStartMonthLinked, _sessionDetails.default.getDefaultStartMonthForDisplay], (startMonth, recurrence, linked, defaultStartMonthForDisplay) => {
  if ((0, _index.isEmpty)(startMonth)) return startMonth;
  if (recurrence === 'once') return (0, _index.isNil)(startMonth) || startMonth === 0 ? 0 : startMonth ? (0, _index.adjustTimeUnit)('months', startMonth) : '';
  if (linked) return defaultStartMonthForDisplay;
  return (0, _index.isNil)(startMonth) ? 1 : startMonth === 0 ? 0 : startMonth ? (0, _index.adjustTimeUnit)('months', startMonth) : '';
});
const getEndMonthLinked = (0, _reselect.createSelector)([getEndMLinked], endMonthLinked => !(0, _index.isNil)(endMonthLinked) ? endMonthLinked : true);
const getEvaluatorEndMonth = (0, _reselect.createSelector)([getEndMonth, _sessionDetails.default.getReviewPeriod], (endMonth, reviewPeriod) => {
  if ((0, _index.isEmpty)(endMonth)) return endMonth;
  return (0, _index.isNil)(endMonth) ? reviewPeriod : endMonth ? (0, _index.adjustTimeUnit)('months', endMonth) : '';
});
const getGrowth = (0, _reselect.createSelector)([getBenefitData], (data = {}) => data.growth === '' ? 0 : data.growth);
const getGrowthForDisplay = (0, _reselect.createSelector)([getBenefitData], (data = {}) => (0, _index.isNil)(data.growth) ? 0 : data.growth);
const getCurrent = (0, _reselect.createSelector)([getBenefitsCurrent, _layout.default.getFirstBenefit], (benefitsCurrent, firstBenefit) => {
  if (benefitsCurrent === '') {
    return firstBenefit;
  } else {
    return benefitsCurrent;
  }
});
const getEvaluatorUiSchema = (0, _reselect.createSelector)([state => state, _layout.default.getBenefitENoById], (state, eNo) => state.account.evaluators[eNo].uiSchema);
const getProgress = (0, _reReselect.default)([getEvaluatorUiSchema, getBenefitData], (uiSchema, benefitData) => (0, _index.getProgressData)(uiSchema, benefitData))((state, benefitId) => benefitId);

const getProgressCache = () => {
  return getProgress.cache;
};

const clearProgressCache = () => {
  getProgress.clearCache();
};

const getRecomputations = () => {
  getProgress.recomputations();
};

const getPristine = (0, _reselect.createSelector)([getBenefitValues], benefitValues => {
  return (0, _index.isEmpty)(benefitValues) || (0, _index.isNil)(benefitValues) ? true : false;
});

const getBenefitIdProp = (state, props) => props.benefitId;

const getSchema = (0, _reselect.createSelector)([_account.default.getEvaluatorSchema, getBenefitIdProp], (evaluatorSchema, benefitId) => ({ ...evaluatorSchema,
  id: benefitId,
  benefitId
}));
const getFormData = (0, _reselect.createSelector)([_account.default.getEvaluatorSchema, getSchema, getBenefitValues], (evaluatorSchema, schema, benefitValues) => (0, _utils.getDefaultFormState)(evaluatorSchema, (0, _index.formatFieldSetData)(schema, benefitValues)));
const getBenefitLinkedFields = (0, _reselect.createSelector)([_defaultSessionValues.default.getDefaultSessionValuesLinked, getSchema, getBenefitIdProp], (linkedValues, schema, benefitUid) => (0, _index.getBenefitLinkedFieldIds)(linkedValues, schema, benefitUid));
const getBenefitUnlinkedFields = (0, _reselect.createSelector)([_defaultSessionValues.default.getDefaultSessionValuesLinked, getSchema, getBenefitIdProp], (linkedValues, schema, benefitUid) => (0, _index.getBenefitUnlinkedFieldIds)(linkedValues, schema, benefitUid));

const getErrors = (state, benefitId) => {
  var _state$session$presen53, _state$session$presen54, _state$session$presen55, _state$session$presen56;

  return (_state$session$presen53 = state.session.present) === null || _state$session$presen53 === void 0 ? void 0 : (_state$session$presen54 = _state$session$presen53.benefits) === null || _state$session$presen54 === void 0 ? void 0 : (_state$session$presen55 = _state$session$presen54.data) === null || _state$session$presen55 === void 0 ? void 0 : (_state$session$presen56 = _state$session$presen55[benefitId]) === null || _state$session$presen56 === void 0 ? void 0 : _state$session$presen56.errors;
};

const getStartMonthLinkedFlag = state => state.session.present.benefits.startMonthLinkedFlag;

const getEndMonthLinkedFlag = state => state.session.present.benefits.endMonthLinkedFlag;

const getUserTitle = (state, {
  benefitId
}) => {
  var _state$session$presen57, _state$session$presen58, _state$session$presen59, _state$session$presen60, _state$session$presen61;

  return ((_state$session$presen57 = state.session.present) === null || _state$session$presen57 === void 0 ? void 0 : (_state$session$presen58 = _state$session$presen57.benefits) === null || _state$session$presen58 === void 0 ? void 0 : (_state$session$presen59 = _state$session$presen58.data) === null || _state$session$presen59 === void 0 ? void 0 : (_state$session$presen60 = _state$session$presen59[benefitId]) === null || _state$session$presen60 === void 0 ? void 0 : (_state$session$presen61 = _state$session$presen60.values) === null || _state$session$presen61 === void 0 ? void 0 : _state$session$presen61.userTitle) || false;
};

const getGrowthCashflowMods = (state, benefitId) => {
  var _state$session$presen62, _state$session$presen63, _state$session$presen64, _state$session$presen65;

  return (_state$session$presen62 = state.session.present) === null || _state$session$presen62 === void 0 ? void 0 : (_state$session$presen63 = _state$session$presen62.benefits) === null || _state$session$presen63 === void 0 ? void 0 : (_state$session$presen64 = _state$session$presen63.data) === null || _state$session$presen64 === void 0 ? void 0 : (_state$session$presen65 = _state$session$presen64[benefitId]) === null || _state$session$presen65 === void 0 ? void 0 : _state$session$presen65.growthCashflowMods;
};

const getAdoptionCashflowMods = (state, benefitId) => {
  var _state$session$presen66, _state$session$presen67, _state$session$presen68, _state$session$presen69;

  return (_state$session$presen66 = state.session.present) === null || _state$session$presen66 === void 0 ? void 0 : (_state$session$presen67 = _state$session$presen66.benefits) === null || _state$session$presen67 === void 0 ? void 0 : (_state$session$presen68 = _state$session$presen67.data) === null || _state$session$presen68 === void 0 ? void 0 : (_state$session$presen69 = _state$session$presen68[benefitId]) === null || _state$session$presen69 === void 0 ? void 0 : _state$session$presen69.adoptionCashflowMods;
};

const getRiskCashflowMods = (state, benefitId) => {
  var _state$session$presen70, _state$session$presen71, _state$session$presen72, _state$session$presen73;

  return (_state$session$presen70 = state.session.present) === null || _state$session$presen70 === void 0 ? void 0 : (_state$session$presen71 = _state$session$presen70.benefits) === null || _state$session$presen71 === void 0 ? void 0 : (_state$session$presen72 = _state$session$presen71.data) === null || _state$session$presen72 === void 0 ? void 0 : (_state$session$presen73 = _state$session$presen72[benefitId]) === null || _state$session$presen73 === void 0 ? void 0 : _state$session$presen73.riskCashflowMods;
};

const getSmooth = (state, benefitId) => {
  var _state$session$presen74, _state$session$presen75, _state$session$presen76, _state$session$presen77;

  return (_state$session$presen74 = state.session.present) === null || _state$session$presen74 === void 0 ? void 0 : (_state$session$presen75 = _state$session$presen74.benefits) === null || _state$session$presen75 === void 0 ? void 0 : (_state$session$presen76 = _state$session$presen75.data) === null || _state$session$presen76 === void 0 ? void 0 : (_state$session$presen77 = _state$session$presen76[benefitId]) === null || _state$session$presen77 === void 0 ? void 0 : _state$session$presen77.smooth;
};

const getShowModifiers = state => state.session.present.benefits.showModifiers;

const isModded = (0, _reselect.createSelector)([getAdoptionCashflowMods, getRiskCashflowMods, getGrowthCashflowMods], (adoptionCashflowMods, riskCashflowMods, growthCashflowMods) => {
  return !(0, _index.isNil)(adoptionCashflowMods) && !(0, _index.isEmpty)(adoptionCashflowMods) || !(0, _index.isNil)(riskCashflowMods) && !(0, _index.isEmpty)(riskCashflowMods) || !(0, _index.isNil)(growthCashflowMods) && !(0, _index.isEmpty)(growthCashflowMods);
});
const getMultiYearValues = (0, _reselect.createSelector)([getBenefitData], (data = {}) => data.multiYearValues || {});
const selectors = {
  getBenefits,
  getBenefitData,
  getStatus,
  getBenefitSubHeading,
  getBenefitResult,
  getBenefitValues,
  getBenefitValue,
  getBenefitNotes,
  getBenefitPrivateNotes,
  getEvaluatorUiSchema,
  getStartMonth,
  getEvaluatorStartMonth,
  getEndMonth,
  getEvaluatorEndMonth,
  getGrowth,
  getGrowthForDisplay,
  getCurrent,
  getUnits,
  getProgress,
  getProgressCache,
  clearProgressCache,
  getRecomputations,
  getPristine,
  getSchema,
  getFormData,
  getBenefitLinkedFields,
  getBenefitUnlinkedFields,
  getErrors,
  getStartMonthLinked,
  getStartMonthLinkedFlag,
  getEndMonthLinked,
  getEndMonthLinkedFlag,
  getUserTitle,
  getGrowthCashflowMods,
  getAdoptionCashflowMods,
  getRiskCashflowMods,
  getSmooth,
  getShowModifiers,
  isModded,
  getMultiYearValues
};
var _default = selectors;
exports.default = _default;