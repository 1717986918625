/* @flow */
/* eslint-disable max-len */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M4,20L4,20c5.5-0.5,7.3-4.3,8.9-7.8C14.4,8.8,15.8,6,20,6V4c-5.4,0-7.3,3.9-8.9,7.3C9.6,14.7,8.2,17.6,4,18V2H2v20h20v-2H4z
	"
    />
  </Icon>
);

export default memo(IconComponent);
