import React, { Element } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button, FlexRow } from '@sharkfinesse/sfl-ui';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

const MessageWrapper = styled.div`
  flex: 1 1 auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

type Props = {
  id: string,
  message: string | Element<*>,
  undo: Function,
};

export const toastConfig = () => ({
  hideProgressBar: false,
  position: 'bottom-center',
  pauseOnHover: true,
  draggable: true,
  autoClose: 14000,
  closeButton: true,
  draggablePercent: 80,
  className: 'toast',
  bodyClassName: 'toast-body',
  progressClassName: 'progress-bar',
});

const Undo = ({ id, undo, message, dispatch }: Props) => (
  <FlexRow>
    <MessageWrapper>{message}</MessageWrapper>
    <Button
      size="default"
      variant="flat"
      color="dark"
      onClick={() => {
        dispatch(undo(id));
        toast.dismiss(id);
      }}
    >
      <FormattedMessage id="buttons.undo.label" defaultMessage="Undo" />
    </Button>
  </FlexRow>
);

export default connect()(Undo);
