/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

const IconComponent = props => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M18.8,30.9h-3.6c-0.4,0-0.8-0.3-0.8-0.8v-1.8c-0.3-0.1-0.7-0.2-1-0.3l-0.9,1.5c-0.1,0.2-0.3,0.3-0.5,0.3
	c-0.2,0-0.4,0-0.6-0.1l-3.1-1.8c-0.4-0.2-0.5-0.7-0.3-1l0.9-1.5c-0.1-0.1-0.2-0.2-0.4-0.3c-0.1-0.1-0.2-0.2-0.4-0.4l-1.5,0.9
	c-0.2,0.1-0.4,0.1-0.6,0.1c-0.2-0.1-0.4-0.2-0.5-0.3l-1.8-3.1c-0.2-0.4-0.1-0.8,0.3-1l1.5-0.9c-0.1-0.3-0.2-0.6-0.3-1H3.8
	c-0.4,0-0.8-0.3-0.8-0.8v-3.6c0-0.4,0.3-0.8,0.8-0.8h1.8c0.1-0.3,0.2-0.7,0.3-1l-1.5-0.9C4.2,12.5,4,12.3,4,12.2
	c-0.1-0.2,0-0.4,0.1-0.6l1.8-3.1c0.2-0.4,0.7-0.5,1-0.3l1.5,0.9C8.5,9,8.6,8.9,8.7,8.7C8.9,8.6,9,8.5,9.1,8.4L8.2,6.9
	C8,6.5,8.1,6,8.5,5.8l3.1-1.8C11.8,4,12,3.9,12.2,4c0.2,0.1,0.4,0.2,0.5,0.3l0.9,1.5c0.3-0.1,0.6-0.2,1-0.3V3.8
	c0-0.4,0.3-0.8,0.8-0.8h3.6c0.4,0,0.8,0.3,0.8,0.8v1.8c0.3,0.1,0.7,0.2,1,0.3l0.9-1.5c0.2-0.4,0.7-0.5,1-0.3l3.1,1.8
	c0.2,0.1,0.3,0.3,0.3,0.5c0.1,0.2,0,0.4-0.1,0.6l-0.9,1.5c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.4,0.4l1.5-0.9
	c0.4-0.2,0.8-0.1,1,0.3l1.8,3.1c0.2,0.4,0.1,0.8-0.3,1l-1.5,0.9c0.1,0.3,0.2,0.6,0.3,1h1.8c0.4,0,0.8,0.3,0.8,0.8v3.6
	c0,0.4-0.3,0.8-0.8,0.8h-1.8c-0.1,0.3-0.2,0.7-0.3,1l1.5,0.9c0.4,0.2,0.5,0.7,0.3,1l-1.8,3.1c-0.1,0.2-0.3,0.3-0.5,0.3
	c-0.2,0-0.4,0-0.6-0.1l-1.5-0.9c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1,0.1-0.2,0.2-0.4,0.4l0.9,1.5c0.1,0.2,0.1,0.4,0.1,0.6
	s-0.2,0.4-0.3,0.5l-3.1,1.8c-0.4,0.2-0.8,0.1-1-0.3l-0.9-1.5c-0.3,0.1-0.6,0.2-1,0.3v1.8C19.5,30.6,19.2,30.9,18.8,30.9z M16,29.4
	H18v-1.6c0-0.4,0.3-0.7,0.6-0.7c0.7-0.1,1.3-0.3,1.9-0.5c0.3-0.1,0.7,0,0.9,0.3l0.8,1.4l1.8-1l-0.8-1.4c-0.2-0.3-0.1-0.7,0.2-1
	c0.3-0.2,0.5-0.4,0.7-0.7c0.2-0.2,0.5-0.5,0.7-0.7c0.2-0.3,0.6-0.4,1-0.2l1.4,0.8l1-1.8l-1.4-0.8c-0.3-0.2-0.5-0.6-0.3-0.9
	c0.2-0.6,0.4-1.3,0.5-1.9c0.1-0.4,0.4-0.6,0.7-0.6h1.6V16h-1.6c-0.4,0-0.7-0.3-0.7-0.6c-0.1-0.7-0.3-1.3-0.5-1.9
	c-0.1-0.3,0-0.7,0.3-0.9l1.4-0.8l-1-1.8l-1.4,0.8c-0.3,0.2-0.7,0.1-1-0.2c-0.2-0.3-0.4-0.5-0.7-0.7c-0.2-0.2-0.5-0.5-0.7-0.7
	c-0.3-0.2-0.4-0.6-0.2-1l0.8-1.4l-1.8-1l-0.8,1.4c-0.2,0.3-0.6,0.5-0.9,0.3C20,7.2,19.3,7.1,18.7,7C18.3,6.9,18,6.6,18,6.2V4.6H16
	v1.6c0,0.4-0.3,0.7-0.6,0.7c-0.7,0.1-1.3,0.3-1.9,0.5c-0.3,0.1-0.7,0-0.9-0.3l-0.8-1.4l-1.8,1l0.8,1.4c0.2,0.3,0.1,0.7-0.2,1
	C10.3,9.3,10,9.6,9.8,9.8c-0.2,0.2-0.5,0.5-0.7,0.7c-0.2,0.3-0.6,0.4-1,0.2L6.8,9.9l-1,1.8l1.4,0.8c0.3,0.2,0.5,0.6,0.3,0.9
	C7.2,14,7.1,14.7,7,15.3C6.9,15.7,6.6,16,6.2,16H4.6V18h1.6c0.4,0,0.7,0.3,0.7,0.6c0.1,0.7,0.3,1.3,0.5,1.9c0.1,0.3,0,0.7-0.3,0.9
	l-1.4,0.8l1,1.8l1.4-0.8c0.3-0.2,0.7-0.1,1,0.2c0.2,0.3,0.4,0.5,0.7,0.7c0.2,0.2,0.5,0.5,0.7,0.7c0.3,0.2,0.4,0.6,0.2,1l-0.8,1.4
	l1.8,1l0.8-1.4c0.2-0.3,0.6-0.5,0.9-0.3c0.6,0.2,1.3,0.4,1.9,0.5c0.4,0.1,0.6,0.4,0.6,0.7V29.4z"
    />
    <path
      fill="currentColor"
      d="M17,23.4c-1.7,0-3.3-0.7-4.5-1.9c-1.2-1.2-1.9-2.8-1.9-4.5s0.7-3.3,1.9-4.5c1.2-1.2,2.8-1.9,4.5-1.9
	s3.3,0.7,4.5,1.9c1.2,1.2,1.9,2.8,1.9,4.5s-0.7,3.3-1.9,4.5l0,0l0,0l0,0C20.3,22.7,18.7,23.4,17,23.4z M17,12.1
	c-1.3,0-2.5,0.5-3.5,1.4s-1.4,2.2-1.4,3.5s0.5,2.5,1.4,3.5c0.9,0.9,2.2,1.4,3.5,1.4s2.5-0.5,3.5-1.4l0,0c0.9-0.9,1.4-2.2,1.4-3.5
	s-0.5-2.5-1.4-3.5C19.5,12.6,18.3,12.1,17,12.1z"
    />
  </Icon>
);

export default memo(IconComponent);
