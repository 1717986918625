import { createResponsiveStateReducer } from 'redux-responsive';
const browser = createResponsiveStateReducer({
  xxs: 0,
  xs: 380,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
});
export default browser;
