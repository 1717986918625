/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

type IconProps = {
  tooltip?: Boolean,
};

let IconInfo = (props: IconProps): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M12,20c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8S16.4,20,12,20 M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2"
    />
    <rect x="11" y="7" fill={props.tooltip ? 'currentColor' : 'inherit'} width="2" height="2" />
    <rect x="11" y="11" fill={props.tooltip ? 'currentColor' : 'inherit'} width="2" height="6" />
  </Icon>
);
export default memo(IconInfo);
