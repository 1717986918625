import { css } from 'styled-components';

const Styles = ({ font, colors, scale }) => ({
  buttonLabel: () => css`
    max-width: 0;
    transition: max-width 0.4s, opacity 0.4s;
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    overflow: hidden;
    opacity: 0;
  `,
  button: () => css`
    cursor: pointer;
    border: 1px solid ${colors.uiLight};
    background: ${colors.white};
    display: flex;
    align-items: center;
    min-height: 30px;
    border-radius: 24px;
    font-size: 14px;
    color: ${colors.ui};
    &:hover {
      border: 1px solid ${colors.primary};
      color: ${colors.primary};
    }
  `,
});

export default Styles;
