"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  blackbird: true,
  bluebird: true,
  bunting: true,
  cardinal_: true,
  cardinalstar: true,
  cardinalstarstar: true,
  eagle: true,
  finchstar: true,
  finchstarstar: true,
  goldfinch: true,
  idiot: true,
  jalt: true,
  jalt_: true,
  kestrel: true,
  quacky: true,
  robin: true,
  robinstar: true,
  robinstarstar: true,
  starling: true,
  starling_: true,
  warbler: true,
  warblerstar: true,
  reduceWithCall: true,
  placeholder: true,
  Either: true,
  Maybe: true
};
Object.defineProperty(exports, "Either", {
  enumerable: true,
  get: function () {
    return _sanctuary.Either;
  }
});
Object.defineProperty(exports, "Maybe", {
  enumerable: true,
  get: function () {
    return _sanctuary.Maybe;
  }
});
exports.kestrel = exports.jalt_ = exports.jalt = exports.idiot = exports.goldfinch = exports.finchstarstar = exports.finchstar = exports.eagle = exports.cardinalstarstar = exports.cardinalstar = exports.cardinal_ = exports.bunting = exports.bluebird = exports.blackbird = void 0;
Object.defineProperty(exports, "placeholder", {
  enumerable: true,
  get: function () {
    return _ramda.__;
  }
});
exports.warblerstar = exports.warbler = exports.starling_ = exports.starling = exports.robinstarstar = exports.robinstar = exports.robin = exports.reduceWithCall = exports.quacky = void 0;

var _ramda = require("ramda");

Object.keys(_ramda).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _ramda[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _ramda[key];
    }
  });
});

var _sanctuary = require("sanctuary");

//fantasy-combinators
const identity = a => a;

const substitution = f => g => x => f(x)(g(x));

const compose = f => g => x => f(g(x));

const constant = a => b => a; //fantasy-birds


const blackbird = (0, _ramda.curry)((f, g, x, y) => f(g(x)(y)));
exports.blackbird = blackbird;
const bluebird = (0, _ramda.curry)(compose);
exports.bluebird = bluebird;
const bunting = (0, _ramda.curry)((f, g, x, y, z) => f(g(x)(y)(z)));
exports.bunting = bunting;
const cardinal_ = (0, _ramda.curry)((f, g, x, y) => f(g(y))(x));
exports.cardinal_ = cardinal_;
const cardinalstar = (0, _ramda.curry)((f, x, y, z) => f(x)(z)(y));
exports.cardinalstar = cardinalstar;
const cardinalstarstar = (0, _ramda.curry)((f, s, t, u, v) => f(s)(t)(v)(u));
exports.cardinalstarstar = cardinalstarstar;
const eagle = (0, _ramda.curry)((f, x, g, y, z) => f(x)(g(y)(z)));
exports.eagle = eagle;
const finchstar = (0, _ramda.curry)((f, x, y, z) => f(z)(y)(x));
exports.finchstar = finchstar;
const finchstarstar = (0, _ramda.curry)((f, s, t, u, v) => f(s)(v)(u)(t));
exports.finchstarstar = finchstarstar;
const goldfinch = (0, _ramda.curry)((f, g, x, y) => f(y)(g(x)));
exports.goldfinch = goldfinch;
const idiot = (0, _ramda.curry)(identity);
exports.idiot = idiot;
const jalt = (0, _ramda.curry)((f, x, y) => f(x));
exports.jalt = jalt;
const jalt_ = (0, _ramda.curry)((f, x, y, z) => f(x)(y));
exports.jalt_ = jalt_;
const kestrel = (0, _ramda.curry)(constant);
exports.kestrel = kestrel;
const quacky = (0, _ramda.curry)((x, f, g) => g(f(x)));
exports.quacky = quacky;
const robin = (0, _ramda.curry)((x, f, y) => f(y)(x));
exports.robin = robin;
const robinstar = (0, _ramda.curry)((f, x, y, z) => f(y)(z)(x));
exports.robinstar = robinstar;
const robinstarstar = (0, _ramda.curry)((f, s, t, u, v) => f(s)(u)(v)(t));
exports.robinstarstar = robinstarstar;
const starling = (0, _ramda.curry)(substitution);
exports.starling = starling;
const starling_ = (0, _ramda.curry)((f, g, h, x) => f(g(x))(h(x)));
exports.starling_ = starling_;
const warbler = (0, _ramda.curry)((f, x) => f(x)(x));
exports.warbler = warbler;
const warblerstar = (0, _ramda.curry)((f, x, y) => f(x)(y)(y));
/* eslint-disable fp/no-mutation */

exports.warblerstar = warblerstar;
_sanctuary.Either.Left = _sanctuary.Left;
_sanctuary.Either.Right = _sanctuary.Right;
_sanctuary.Either.isLeft = _sanctuary.isLeft;
_sanctuary.Either.isRight = _sanctuary.isRight;
_sanctuary.Either.fold = _sanctuary.either;
_sanctuary.Maybe.fromMaybe = _sanctuary.fromMaybe;
_sanctuary.Maybe.Nothing = _sanctuary.Nothing;
_sanctuary.Maybe.Just = _sanctuary.Just;
_sanctuary.Maybe.isNothing = _sanctuary.isNothing;
_sanctuary.Maybe.isJust = _sanctuary.isJust;
_sanctuary.Maybe.fold = (0, _ramda.useWith)((0, _ramda.uncurryN)(2, (0, _ramda.ifElse)(_sanctuary.isNothing)), [idiot, (0, _ramda.partial)(_ramda.pipe, [(0, _ramda.prop)('value')]), idiot]);
/* eslint-disable */

const reduceWithCall = (a, ...rest) => (0, _ramda.pipe)((0, _ramda.reduce)((0, _ramda.flip)(_ramda.call)), _ramda.unapply)(a)(...rest);
/* eslint-enable */


exports.reduceWithCall = reduceWithCall;