// @flow
import React, { useContext } from 'react';
import NavItem from './nav-item';
import UserPosition from 'containers/user-position';
import { turnOffMainAxsis, insetOffset, insetSameSize } from '../popper';
import { SideBarAPI } from './sidebar';

const Item = ({ pageId, order, ...props }: Props) => {
  const { currentItem, expanded } = useContext(SideBarAPI);
  const tab = <NavItem selected={expanded && currentItem === order} {...props} />;
  return pageId ? (
    <UserPosition id={pageId} modifiers={[turnOffMainAxsis, insetOffset, insetSameSize]}>
      {tab}
    </UserPosition>
  ) : (
    tab
  );
};

export default Item;
