// @flow
import applyTheme from '../../styles/apply-theme';
import styled from 'styled-components';
import Button from '../button';
import { animated } from 'react-spring';

const selectToolbar = applyTheme('selectToolbar');

export const Container = selectToolbar('container', styled(animated.div));
export const SelectToolbarButton = selectToolbar('button', styled(Button));
export const Left = selectToolbar('left');
export const Center = selectToolbar('center');
export const Right = selectToolbar('right');
export const Wrapper = selectToolbar('wrapper');
export const Number = selectToolbar('number');
export const NumberLabel = selectToolbar('numberLabel');
export const NumberWrapper = selectToolbar('numberWrapper');
export const SelectedLabel = selectToolbar('selectedLabel');
export const SelectLink = selectToolbar('selectLink');
