import {
  length,
  evaluatorSelectors,
  sessionSelectors,
  routerSelectors,
} from '@sharkfinesse/sfl-lib';
import { select, all, call } from 'redux-saga/effects';
import { history } from '../../../index';

function* undoCurrent() {
  const [futureBenefitId, benefitId, sessionId, pathname] = yield all([
    select(sessionSelectors.getFuture),
    select(evaluatorSelectors.getCurrent),
    select(sessionSelectors.getSessionId),
    select(routerSelectors.getPathname),
  ]);

  const futureBenefit =
    length(futureBenefitId) === 0 ? 'empty' : futureBenefitId[0].benefits.current;

  if (futureBenefit !== benefitId) {
    if (pathname.indexOf('/benefits/b/') !== -1) {
      yield call([history, history.push], {
        pathname: `/s/${sessionId}/benefits/b/${benefitId}/calculator`,
      });
    }
  }
}

export default undoCurrent;
