"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

const getAuth0 = state => state.auth0;

const getAuth0AccessToken = state => state.auth0.accessToken;

const getAuth0IsAuthenticated = state => state.auth0.isAuthenticated;

const getAuth0Profile = state => state.auth0.profile;

const getFirebase = state => state.firebase;

const getFirebaseToken = state => state.firebase.token.data.firebaseToken;

const getFirebaseProfile = state => state.firebase.profile;

const getFirebaseProfileUID = state => state.firebase.profile.uid;

const selectors = {
  getAuth0,
  getAuth0AccessToken,
  getAuth0IsAuthenticated,
  getAuth0Profile,
  getFirebase,
  getFirebaseToken,
  getFirebaseProfile,
  getFirebaseProfileUID
};
var _default = selectors;
exports.default = _default;