/* @flow */
import {
  append,
  createReducer,
  dissoc,
  isNil,
  normalizeTime,
  omit,
  pipe,
  reject,
  removePostErrorFieldCalculatedKeys,
  simpleAction,
  uniq,
  without,
  simpleActionMeta,
  getErrorObject,
  unformatFieldSetData,
  sessionBenefitsInitialState,
  isEmpty,
} from '@sharkfinesse/sfl-lib';

const namespace = (name: string): string => `@@session.benefits/${name}`;

//Actions
export const types = {
  SUBMIT: namespace('SUBMIT'),
  ERROR: namespace('ERROR'),
  RESET: namespace('RESET'),
  BENEFIT: {
    UPDATE: namespace('BENEFIT.UPDATE'),
    UPDATE_VALUE: namespace('BENEFIT.UPDATE_VALUE'),
  },

  NOTES: {
    UPDATE: namespace('NOTES.UPDATE'),
  },
  PRIVATE_NOTES: {
    UPDATE: namespace('PRIVATE_NOTES.UPDATE'),
  },
  SUBHEADING: {
    UPDATE: namespace('SUBHEADING.UPDATE'),
  },
  CURRENT: {
    UPDATE: namespace('CURRENT.UPDATE'),
  },
  MULTI_YEAR_VALUES: {
    UPDATE: namespace('MULTI_YEAR_VALUES.UPDATE'),
    UPDATE_ALL: namespace('MULTI_YEAR_VALUES.UPDATE_ALL'),
  },
  REVERT: namespace('REVERT'),
  START_DELETE: namespace('START_DELETE'),
  DELETE: namespace('DELETE'),
  START_UNDO_DELETE: namespace('START_UNDO_DELETE'),
  UNDO_DELETE: namespace('UNDO_DELETE'),
  SAVE: namespace('SAVE'),
  INSERT_DATA: namespace('INSERT_DATA'),
  USER_TITLE: namespace('USER_TITLE'),
  UPDATE: namespace('UPDATE'),
  SYNC_DATA: namespace('SYNC_DATA'),
  UPDATE_DEFAULT_SESSION_VALUE: namespace('UPDATE_DEFAULT_SESSION_VALUE'),
  LINK_INPUT: namespace('LINK_INPUT'),
  CASHFLOW: {
    SHOW: namespace('CASHFLOW.SHOW'),
    CHANGE: namespace('CASHFLOW.CHANGE'),
    MODIFIER: {
      GROWTH: { UPDATE: namespace('CASHFLOW.MODIFIER.GROWTH.UPDATE') },
      ADOPTION: { UPDATE: namespace('CASHFLOW.MODIFIER.ADOPTION.UPDATE') },
      RISK: { UPDATE: namespace('CASHFLOW.MODIFIER.RISK.UPDATE') },
      RESET: namespace('CASHFLOW.MODIFIER.RESET'),
    },
    SMOOTH: namespace('CASHFLOW.SMOOTH'),
  },
  STARTMONTH_LINKED_FLAG: {
    UPDATE: namespace('STARTMONTH_LINKED_FLAG.UPDATE'),
  },
  ENDMONTH_LINKED_FLAG: {
    UPDATE: namespace('ENDMONTH_LINKED_FLAG.UPDATE'),
  },
  SHOW_MODIFIERS: {
    UPDATE: namespace('SHOW_MODIFIERS.UPDATE'),
  },
};

//Reducer

export const initialState = sessionBenefitsInitialState;

const reduceBenefit = (
  state,
  {
    payload: {
      id,
      startMonth,
      endMonth,
      startMonthLinked = true,
      endMonthLinked = true,
      growth = 0,
      values = {},
      recurrence = 'monthly',
      schema,
      errors,
      formData,
      uiSchemaOrder,
    },
  }
) => {
  const santisedState = pipe(dissoc('status'), dissoc('result'), dissoc('errors'))(state.data[id]);

  let newState = {
    values,
    ...(!isNil(values.result) && {
      result: values.result,
    }),
  };

  if (errors) {
    const scope = unformatFieldSetData(schema, formData);

    newState = {
      values: reject(
        isNil,
        removePostErrorFieldCalculatedKeys({
          schema,
          scope,
          errors,
          order: uiSchemaOrder,
        })
      ),
      errors: getErrorObject(errors),
      status: 'error',
    };
  }

  return {
    ...state,
    data: {
      ...state.data,
      [id]: {
        ...santisedState,
        id,
        startMonth: isEmpty(startMonth) ? startMonth : normalizeTime('months', startMonth),
        startMonthLinked,
        endMonth: isEmpty(endMonth) ? endMonth : normalizeTime('months', endMonth),
        endMonthLinked,
        growth,
        recurrence,
        ...newState,
      },
    },
    ids: pipe(append, uniq)(id, state.ids),
  };
};

const reduceUserTitle = (state, { payload: { id, userTitle } }) => {
  const { data, ids } = state;
  const values = data[id]?.values ? data[id].values : {};

  return {
    ...state,
    data: {
      ...data,
      [id]: {
        ...data[id],
        values: {
          ...values,
          userTitle: userTitle,
        },
      },
    },
    ids: pipe(append, uniq)(id, ids),
  };
};

const reduceNotesChange = (state, action) => {
  const { id, notes } = action.payload;

  return {
    ...state,
    data: {
      ...state.data,
      [id]: {
        ...state.data[id],
        id,
        notes,
      },
    },
    ids: pipe(append, uniq)(id, state.ids),
  };
};

const reducePrivateNotesChange = (state, action) => {
  const { id, privateNotes } = action.payload;
  return {
    ...state,
    data: {
      ...state.data,
      [id]: {
        ...state.data[id],
        id,
        privateNotes,
      },
    },
    ids: pipe(append, uniq)(id, state.ids),
  };
};

const reduceSubHeadingChange = (state, action) => {
  const { id, subHeading } = action.payload;
  return {
    ...state,
    data: {
      ...state.data,
      [id]: {
        ...state.data[id],
        id,
        subHeading,
      },
    },
    ids: pipe(append, uniq)(id, state.ids),
  };
};

const reduceCurrentChange = (state, action) => {
  return {
    ...state,
    current: action.payload,
  };
};

const reduceReset = state => ({ ...initialState, current: state.current });
const reduceRevert = (state, action) => action.payload;

const reduceDelete = (state, action) => {
  return {
    ...state,
    ids: without([action.payload.id], state.ids),
    data: omit([action.payload.id], state.data),
    current:
      state.current === action.payload.id && action.payload.deleteCurrent ? '' : state.current,
  };
};

const insertData = (state, action) => {
  return {
    ...state,
    data: {
      ...state.data,
      [action.payload.id]: action.payload.data,
    },
    ids: pipe(append, uniq)(action.payload.id, state.ids),
  };
};

const reduceUpdate = (state, action) => {
  return action.payload;
};

const reduceSync = (state, action) => ({
  ...state,
  ...action.payload.data,
});

const reduceUpdateDefaultSessionValue = (state, action) => {
  const { id, fieldId, value, multiYearValues } = action.payload;
  return {
    ...state,
    data: {
      ...state.data,
      [id]: {
        ...state.data[id],
        values: {
          ...state.data[id].values,
          [fieldId]: value,
        },
        ...(multiYearValues && {
          multiYearValues: {
            ...state.data[id].multiYearValues,
            [fieldId]: multiYearValues,
          },
        }),
      },
    },
  };
};

const reduceLinkInput = (state, action) => {
  const { evalUid, fieldId, value, multiYearValues } = action.payload;
  return {
    ...state,
    data: {
      ...state.data,
      [evalUid]: {
        ...state.data[evalUid],
        values: {
          ...(state.data[evalUid] && state.data[evalUid].values),
          [fieldId]: value,
        },
        ...(multiYearValues && {
          multiYearValues: {
            ...(state.data[evalUid] && state.data[evalUid].multiYearValues),
            [fieldId]: multiYearValues,
          },
        }),
      },
    },
  };
};

const reduceCashflowMod = (
  state,
  { payload: { adoptionCashflowMods, growthCashflowMods, riskCashflowMods, id } }
) => ({
  ...state,
  data: {
    ...state.data,
    [id]: {
      ...state.data[id],
      ...(adoptionCashflowMods && { adoptionCashflowMods }),
      ...(growthCashflowMods && { growthCashflowMods }),
      ...(riskCashflowMods && { riskCashflowMods }),
    },
  },
  ids: pipe(append, uniq)(id, state.ids),
});

const reduceStartMonthLinkedFlag = (state, action) => {
  return {
    ...state,
    startMonthLinkedFlag: true,
  };
};

const reduceEndMonthLinkedFlag = (state, action) => {
  return {
    ...state,
    endMonthLinkedFlag: true,
  };
};

const reduceSmooth = (state, { payload: { id, smooth } }) => ({
  ...state,
  data: {
    ...state.data,
    [id]: {
      ...state.data[id],
      smooth: !state.data[id].smooth,
    },
  },
  ids: pipe(append, uniq)(id, state.ids),
});

const reduceShowModifiers = (state, { payload }) => ({
  ...state,
  showModifiers: payload,
});

const reduceMultiYearValues = (state, action) => {
  const { id, fieldId, multiYearValues, average } = action.payload;

  return {
    ...state,
    data: {
      ...state.data,
      [id]: {
        ...state.data[id],
        values: {
          ...state.data[id]?.values,
          [fieldId]: average,
        },
        multiYearValues: {
          ...omit([fieldId], state.data[id]?.multiYearValues),
          ...(!isEmpty(multiYearValues) && { [fieldId]: multiYearValues }),
        },
      },
    },
    ids: pipe(append, uniq)(id, state.ids),
  };
};

const reduceUpdateValue = (state, action) => {
  let { id, valueId, value, type } = action.payload;
  const { data, ids } = state;
  const values = data[id]?.values ? data[id].values : {};
  if (type === 'freestyle_label') {
    value = { ...values[valueId], label: value };
  }
  if (type === 'freestyle_input') {
    value = { ...values[valueId], value };
  }
  console.log('action.payload', action.payload);
  return {
    ...state,
    data: {
      ...data,
      [id]: {
        id,
        ...data[id],
        values: {
          ...values,
          [valueId]: value,
        },
      },
    },
    ids: [...ids, id],
  };
};

export default createReducer(
  {
    [types.ERROR]: reduceBenefit,
    [types.NOTES.UPDATE]: reduceNotesChange,
    [types.PRIVATE_NOTES.UPDATE]: reducePrivateNotesChange,
    [types.SUBHEADING.UPDATE]: reduceSubHeadingChange,
    [types.CURRENT.UPDATE]: reduceCurrentChange,
    [types.RESET]: reduceReset,
    [types.REVERT]: reduceRevert,
    [types.DELETE]: reduceDelete,
    [types.INSERT_DATA]: insertData,
    [types.USER_TITLE]: reduceUserTitle,
    [types.UPDATE]: reduceUpdate,
    [types.SYNC_DATA]: reduceSync,
    [types.UPDATE_DEFAULT_SESSION_VALUE]: reduceUpdateDefaultSessionValue,
    [types.LINK_INPUT]: reduceLinkInput,
    [types.STARTMONTH_LINKED_FLAG.UPDATE]: reduceStartMonthLinkedFlag,
    [types.ENDMONTH_LINKED_FLAG.UPDATE]: reduceEndMonthLinkedFlag,
    [types.CASHFLOW.MODIFIER.GROWTH.UPDATE]: reduceCashflowMod,
    [types.CASHFLOW.MODIFIER.ADOPTION.UPDATE]: reduceCashflowMod,
    [types.CASHFLOW.MODIFIER.RISK.UPDATE]: reduceCashflowMod,
    [types.CASHFLOW.MODIFIER.RESET]: reduceCashflowMod,
    [types.CASHFLOW.SMOOTH]: reduceSmooth,
    [types.SHOW_MODIFIERS.UPDATE]: reduceShowModifiers,
    [types.MULTI_YEAR_VALUES.UPDATE]: reduceMultiYearValues,
    [types.MULTI_YEAR_VALUES.UPDATE_ALL]: reduceMultiYearValues,
    [types.BENEFIT.UPDATE_VALUE]: reduceUpdateValue,
    [types.BENEFIT.UPDATE]: reduceBenefit,
  },
  initialState
);

const updateBenefit = simpleActionMeta(types.BENEFIT.UPDATE);

const updateNotes = simpleActionMeta(types.NOTES.UPDATE);
const updatePrivateNotes = simpleActionMeta(types.PRIVATE_NOTES.UPDATE);
const updateSubHeading = simpleActionMeta(types.SUBHEADING.UPDATE);
const updateCurrentBenefit = simpleAction(types.CURRENT.UPDATE);
const updateError = simpleActionMeta(types.ERROR);
const updateDefaultSessionValue = simpleAction(types.UPDATE_DEFAULT_SESSION_VALUE);
const updateLinkInput = simpleActionMeta(types.LINK_INPUT);
const updateReset = simpleActionMeta(types.RESET);
const updateStartDelete = simpleAction(types.START_DELETE);
const updateDelete = simpleActionMeta(types.DELETE);
const updateSave = () => ({ type: types.SAVE });
const updateInsertData = simpleAction(types.INSERT_DATA);
const updateUserTitle = simpleAction(types.USER_TITLE);
const update = simpleAction(types.UPDATE);
const syncData = simpleActionMeta(types.SYNC_DATA);
const updateAdoptionCashflowMods = simpleAction(types.CASHFLOW.MODIFIER.ADOPTION.UPDATE);
const updateRiskCashflowMods = simpleAction(types.CASHFLOW.MODIFIER.RISK.UPDATE);
const updateGrowthCashflowMods = simpleAction(types.CASHFLOW.MODIFIER.GROWTH.UPDATE);

const updateStartMonthLinkedFlag = simpleAction(types.STARTMONTH_LINKED_FLAG.UPDATE);
const updateEndMonthLinkedFlag = simpleAction(types.ENDMONTH_LINKED_FLAG.UPDATE);
const updateResetCashflowMods = simpleAction(types.CASHFLOW.MODIFIER.RESET);
const updateSmooth = simpleAction(types.CASHFLOW.SMOOTH);
const updateShowModifiers = simpleAction(types.SHOW_MODIFIERS.UPDATE);
const updateMultiYearValues = simpleActionMeta(types.MULTI_YEAR_VALUES.UPDATE);
const updateAllMultiYearValues = simpleActionMeta(types.MULTI_YEAR_VALUES.UPDATE_ALL);
const updateValue = simpleActionMeta(types.BENEFIT.UPDATE_VALUE);
const submit = simpleAction(types.SUBMIT);

export const actionCreators = {
  updateCurrentBenefit,
  updateDelete,
  updateError,
  updateNotes,
  updatePrivateNotes,
  updateReset,
  updateSave,
  updateStartDelete,
  updateSubHeading,
  updateInsertData,
  updateUserTitle,
  updateDefaultSessionValue,
  update,
  syncData,
  updateLinkInput,
  updateStartMonthLinkedFlag,
  updateEndMonthLinkedFlag,
  updateGrowthCashflowMods,
  updateAdoptionCashflowMods,
  updateRiskCashflowMods,
  updateResetCashflowMods,
  updateSmooth,
  updateShowModifiers,
  updateMultiYearValues,
  updateAllMultiYearValues,
  updateValue,
  submit,
  updateBenefit,
};
