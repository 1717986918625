/* @flow */
import React, { memo } from 'react';
import styled from 'styled-components';
import Icon from '../icon';
import { prop } from 'styled-tools';

const StyledPath = styled.path`
  fill: ${prop('theme.colors.warning')};
`;

let IconWarning = (props): React.Element<any> => (
  <Icon {...props}>
    <g fill="none" fillRule="evenodd">
      <StyledPath d="M13 14H11V10H13M13 18H11V16H13M1 21H23L12 2L1 21Z" />
      <path
        fill="#FFF"
        d={`M554.4 654h-79.8L441 289h147zm-41 36.5c45.7 0 68.6 25 68.6 75.2 0 50.5-23 75.8-68.7 75.8-46
        0-68.8-25.3-68.8-75.8 0-50 23-75.2 68.8-75.2z
      `}
      />
    </g>
  </Icon>
);

export default memo(IconWarning);
