// @flow
import applyTheme from '../../styles/apply-theme';
import styled from 'styled-components';
import { animated } from 'react-spring';
import CheckboxComponent from '../checkbox/';

const slide = applyTheme('slide');

//export const SlideBox = slide('slideBox', styled(animated.div));
export const SlideContainer = slide('slideContainer');
export const SlideFooter = slide('slideFooter', styled(animated.div));
export const SlideBody = slide('slideBody', styled(animated.div));
export const SlideIcon = slide('slideIcon', styled.img);
export const SlideImage = slide('slideImage', styled.img);
export const SlideGraphics = slide('slideGraphics');
export const FooterCenter = slide('footerCenter');
export const Footer = slide('footer');
export const FooterLabel = slide('footerLabel');
export const FooterEmptyLabel = slide('footerEmptyLabel');
export const FooterDate = slide('footerDate');
export const Positioner = slide('positioner', styled(animated.div));
export const HoverContent = slide('hoverContent', styled(animated.div));
export const DateModified = slide('dateModified');
export const Toolbar = slide('toolbar');
export const ToolbarContent = slide('toolbarContent', styled(animated.div));
export const FooterContent = slide('footerContent', styled(animated.div));
export const EmptySvg = slide('emptySvg', styled.svg);
export const CornerMenu = slide('cornerMenu');
export const Checkbox = styled(CheckboxComponent)`
  position: absolute;
  left: 6px;
  top: 6px;
  visibility: ${({ visibility }) => (visibility ? visibility : 'hidden')};
`;
export const SlideBox = slide('slideBox', styled(animated.div));
export const Tag = slide('tag');
export const Loading = slide('loading');
export const ToolbarDivider = slide('toolbarDivider');
