// @flow
/* eslint-disable no-magic-numbers, react/prop-types */
import React, { useContext, useState, useEffect } from 'react';
import { Slider, Slide, ButtonBack, ButtonNext, CarouselContext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { SliderContainer } from './styled';
import { IconArrowScrollRight, IconArrowScrollLeft } from '../icon';

const getNextDisabled = state => {
  const { currentSlide, totalSlides, visibleSlides, infinite } = state;
  return currentSlide >= totalSlides - visibleSlides && !infinite ? true : false;
};

const getBackDisabled = state => {
  const { currentSlide, infinite } = state;
  return currentSlide === 0 && !infinite ? true : false;
};

const SliderComponent = ({ slides, innerClassName = 'slidePadding', slideSpacing, isLoading }) => {
  const carouselContext = useContext(CarouselContext);

  const [isNextDisabled, setIsNextDisabled] = useState(getNextDisabled(carouselContext.state));
  const [isBackDisabled, setIsBackDisabled] = useState(getBackDisabled(carouselContext.state));

  useEffect(() => {
    function onChange() {
      setIsNextDisabled(getNextDisabled(carouselContext.state));
      setIsBackDisabled(getBackDisabled(carouselContext.state));
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext, isNextDisabled]);

  return (
    <SliderContainer>
      <Slider
        classNameTray="carouselTray"
        style={{
          paddingLeft: slideSpacing,
        }}
      >
        {slides.map((slide, i) => (
          <Slide key={i} index={i} innerClassName={innerClassName}>
            {slide}
          </Slide>
        ))}
      </Slider>
      <ButtonBack className="sliderBtn left">
        <IconArrowScrollLeft size="width: 13px; height: 20px;" viewBox="0 0 13 30" />
      </ButtonBack>
      <ButtonNext className="sliderBtn right">
        <IconArrowScrollRight size="width: 13px; height: 20px;" viewBox="0 0 13 30" />
      </ButtonNext>
    </SliderContainer>
  );
};

export default SliderComponent;
