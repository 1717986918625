const SelectDropdownIndicator = ({ font, colors }) => ({
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: '4px',
  paddingRight: '4px',
  color: ({ inverse }) => (inverse ? colors.uiDarkest : colors.uiLight),
  colorHover: colors.primary,
  colorFocused: colors.uiDarkest,
});

export default SelectDropdownIndicator;
