import {
  omit,
  without,
  contains,
  map,
  layoutSelectors,
  sessionSelectors,
  layoutDesignerSelectors,
  suiteListSelectors,
  isNil,
} from '@sharkfinesse/sfl-lib';
import { put, call, select, all } from 'redux-saga/effects';
import { actionCreators as undoredoTypesActionCreators } from 'redux/modules/undoredo';
import { actionCreators as layoutActionCreators } from 'redux/modules/layout';
import { actionCreators as evaluatorActionCreators } from 'redux/modules/evaluator';
import { actionCreators as cashflowActionCreators } from 'redux/modules/cashflow';
import { actionCreators as defaultSessionValuesActionCreators } from 'redux/modules/default-session-values';
import resetSelectedSuite from '../../layoutDesigner/resetSelectedSuite';
import setSelectedSuite from '../../layoutDesigner/setSelectedSuite';
import { actionCreators as suiteListActionCreators } from 'redux/modules/suite-list';
import { actionCreators as appActionCreators } from 'redux/modules/app';

function newCashFlow(state, suite) {
  return {
    ...state.cashflow,
    benefits: omit(suite.benefits, state.cashflow.benefits),
    editted_benefits: omit(suite.benefits, state.cashflow.editted_benefits),
  };
}
function newLayout(state, id, suite) {
  return {
    ...state.layout,
    benefits: omit(suite.benefits, state.layout.benefits),
    suites: omit([id], state.layout.suites),
    suitesOrder: without([id], state.layout.suitesOrder),
  };
}
function newBenefits(state, suite, deleteCurrent, layout) {
  const nextId = layoutSelectors.getNextOrPreviousBenefit(
    {
      session: {
        present: {
          ...state,
          layout,
        },
      },
    },
    {
      benefitId: state.benefits.current,
    }
  );

  return {
    ...state.benefits,
    ids: without(suite.benefits, state.benefits.ids),
    data: omit(suite.benefits, state.benefits.data),
    current:
      contains(state.benefits.current, suite.benefits) && deleteCurrent
        ? isNil(nextId)
          ? ''
          : nextId
        : state.benefits.current,
  };
}
function newDefaultSessionValues(state, suite, defaultSessionValues) {
  return {
    ...state.defaultSessionValues,
    linked: map(without(suite.benefits), state.defaultSessionValues.linked),
  };
}

function* deleteSuite(action) {
  const { suiteId, deleteCurrent = false } = action.payload;
  yield put(appActionCreators.updatePersistState(false));
  const [selectedSuiteId, session, suite, visibleSuites] = yield all([
    select(layoutDesignerSelectors.getSelectedSuiteId),
    select(sessionSelectors.getSession),
    select(layoutSelectors.getSuite, { suiteId }),
    select(suiteListSelectors.getVisibleList),
  ]);

  const [newCashflowState, newLayoutState, newDefaultSessionValuesState] = yield all([
    call(newCashFlow, session, suite),
    call(newLayout, session, suiteId, suite),
    call(newDefaultSessionValues, session, suite),
  ]);

  const newBenefitsState = yield call(newBenefits, session, suite, deleteCurrent, newLayoutState);

  yield put(undoredoTypesActionCreators.updateSuiteDeleting());

  yield all([
    put(cashflowActionCreators.updateEvaluatorUpdateAll(newCashflowState)),
    put(evaluatorActionCreators.update(newBenefitsState)),
    put(suiteListActionCreators.updateVisible(visibleSuites.filter(obj => obj.value !== suiteId))),
    put(layoutActionCreators.update(newLayoutState)),
    put(
      defaultSessionValuesActionCreators.update({
        state: newDefaultSessionValuesState,
        saveLayout: true,
      })
    ),
  ]);
  if (selectedSuiteId === suiteId) {
    yield call(resetSelectedSuite);
    yield call(setSelectedSuite);
  }
  yield put(appActionCreators.updatePersistState(true));
  yield all([
    put(undoredoTypesActionCreators.updateSuiteDeletingComplete()),
    put(layoutActionCreators.deleteSuiteComplete()),
  ]);
}

export default deleteSuite;
