/* @flow */
import { simpleAction, dissoc, abilitiesInitialState } from '@sharkfinesse/sfl-lib';

const namespace = (name: string): string => `@@abilities/${name}`;

// types
export const types = {
  LOAD: namespace('LOAD'),
  LOADED: namespace('LOADED'),
  SYNC: namespace('SYNC'),
};

export const initialState = abilitiesInitialState;
const AbilitiesReducer =
  () =>
  (state = initialState, action) => {
    switch (action.type) {
      case types.SYNC:
        //loaded state from firestore
        if (!action.payload) {
          return state;
        } else {
          const abilities = dissoc('id', action.payload);
          return abilities;
        }
      default:
        return state;
    }
  };

//Action Creators
const load = simpleAction(types.LOAD);
const sync = simpleAction(types.SYNC);
const loaded = simpleAction(types.LOADED);

export const actionCreators = {
  load,
  sync,
  loaded,
};

export default AbilitiesReducer();
