import { call, all, put, select } from 'redux-saga/effects';
import { actionCreators as evaluatorActionCreators } from 'redux/modules/evaluator';
import { actionCreators as defaultSessionValuesActionCreators } from 'redux/modules/default-session-values';
import {
  isNil,
  evaluatorSelectors,
  filterErrors,
  mockRjsfErrors,
  isNotEmpty,
  adjustUnits,
  sessionDetailsSelectors,
  defaultSessionValuesSelectors,
  layoutSelectors,
  accountSelectors,
  updateYearlyVal,
  calcAverage,
  calcSubs,
  map,
  Maybe,
  getLinkedItemId,
  getMultiYears,
  merge,
  validateEnd,
  validateImpact,
  validateStart,
  isEmpty,
  omit,
} from '@sharkfinesse/sfl-lib';
import { FormattedMessage } from 'react-intl';
import ModalInfo from 'components/modal/modal-info';
import NiceModal from '@ebay/nice-modal-react';
import calculate from './calculate';

function* caluclateEvaluator({
  id,
  meta,
  showErrorModal,
  formData,
  fieldId,
  average,
  subFieldId,
  value,
  actionType,
  defaultSessionValueId,
  valueId,
  showDefaultStartMonth,
  showDefaultEndMonth,
  startMonth: startMonthProp,
  endMonth: endMonthProp,
  startMonthLinked: startMonthLinkedProp,
  endMonthLinked: endMonthLinkedProp,
  growth: growthProp,
  type,
}) {
  const eNo = yield select(layoutSelectors.getBenefitENo, { benefitId: id });

  let startMonth =
    !isNil(startMonthProp) || isEmpty(startMonthProp)
      ? startMonthProp
      : yield select(evaluatorSelectors.getEvaluatorStartMonth, id, eNo);

  let endMonth =
    !isNil(endMonthProp) || isEmpty(endMonthProp)
      ? endMonthProp
      : yield select(evaluatorSelectors.getEvaluatorEndMonth, id);

  let startMonthLinked = !isNil(startMonthLinkedProp)
    ? startMonthLinkedProp
    : yield select(evaluatorSelectors.getStartMonthLinked, id);
  let endMonthLinked = !isNil(endMonthLinkedProp)
    ? endMonthLinkedProp
    : yield select(evaluatorSelectors.getEndMonthLinked, id);
  const growth = !isNil(growthProp) ? growthProp : yield select(evaluatorSelectors.getGrowth, id);

  let [
    schema,
    uiSchemaOrder,
    reviewPeriod,
    benefitValues,
    currentMultiYearValues,
    defaultMultiYearValues,
    recurrence,
  ] = yield all([
    select(evaluatorSelectors.getSchema, { eNo, benefitId: id }),
    select(accountSelectors.getEvaluatorUiSchemaOrder, eNo),
    select(sessionDetailsSelectors.getReviewPeriod),
    select(evaluatorSelectors.getBenefitValues, { benefitId: id }),
    select(evaluatorSelectors.getMultiYearValues, id),
    select(defaultSessionValuesSelectors.getMultiYearData, fieldId || defaultSessionValueId),
    select(accountSelectors.getEvaluatorRecurrence, id, eNo),
  ]);

  const multiYearValues =
    actionType === 'defaultSessionValuesMultiYear'
      ? defaultMultiYearValues
      : currentMultiYearValues;

  let data = formData ?? benefitValues ?? {};
  let newMultiYearValues = multiYearValues;

  switch (actionType) {
    case 'multiYear':
    case 'defaultSessionValuesMultiYear':
      const years = getMultiYears({
        multiYearValues,
        fieldId,
        value,
        reviewPeriod,
        startMonth,
        endMonth,
      });
      if (!isNil(subFieldId)) {
        if (isNil(average)) average = years[subFieldId];
        const multi =
          actionType === 'defaultSessionValuesMultiYear'
            ? multiYearValues
            : multiYearValues?.[fieldId];

        newMultiYearValues = updateYearlyVal(
          subFieldId,
          average,
          multi || map(a => (a === undefined ? 0 : a), years),
          reviewPeriod,
          startMonth,
          endMonth
        );

        average = calcAverage(reviewPeriod, newMultiYearValues);
        data = { ...data, [fieldId]: average };
      } else {
        if (!isEmpty(average) && !isNil(average)) {
          newMultiYearValues = calcSubs(reviewPeriod, average);
          data = { ...data, [fieldId]: average };
        } else {
          data = { ...omit([fieldId], data) };
          newMultiYearValues = [];
        }
      }

      break;

    default:
      break;
  }

  switch (actionType) {
    case 'multiYear':
      yield put(
        evaluatorActionCreators.updateMultiYearValues({
          id,
          multiYearValues: newMultiYearValues,
          meta,
          fieldId,
          average,
        })
      );
      break;
    case 'defaultSessionValuesMultiYear':
      yield put(
        defaultSessionValuesActionCreators.updateChange({
          defaultSessionValueId: fieldId,
          meta,
          value: average,
          multiYearValues: newMultiYearValues,
        })
      );

      return;

    case 'defaultSessionValue':
      fieldId = Maybe.fromMaybe('')(getLinkedItemId(defaultSessionValueId, schema.properties));

      yield put(
        evaluatorActionCreators.updateDefaultSessionValue({
          fieldId,
          id,
          value,
          ...(defaultMultiYearValues && { multiYearValues: defaultMultiYearValues }),
        })
      );
      data = yield select(evaluatorSelectors.getBenefitValues, { benefitId: id });

      break;
    case 'updateVaLue':
      yield put(
        evaluatorActionCreators.updateValue({
          id,
          value,
          valueId,
          type,
          meta,
        })
      );

      data = yield select(evaluatorSelectors.getBenefitValues, { benefitId: id });
      break;
    case 'updateStartMonth':
      if (recurrence === 'once') {
        endMonth = startMonth;
        endMonthLinked = false;
      }

      if (recurrence !== 'once') showDefaultStartMonth && showDefaultStartMonth(startMonth);

      break;
    case 'updateEndMonth':
      showDefaultEndMonth && showDefaultEndMonth(endMonth);

      break;

    default:
      break;
  }

  const errors = filterErrors(
    schema.errors,
    merge(adjustUnits({ schema, scope: data }), {
      startMonth,
      endMonth,
      reviewPeriod,
    })
  );

  const insertIf = (condition, ...elements) => (condition ? elements : []);

  const mockErrors = [
    ...mockRjsfErrors(errors),
    ...insertIf(isEmpty(startMonth), { stack: 'startMonthEmpty:true' }),
    ...insertIf(isEmpty(endMonth), { stack: 'endMonthEmpty:true' }),
    ...insertIf(recurrence !== 'once' && startMonth === 0, { stack: 'startMonthZero:true' }),
    ...insertIf(!validateStart(startMonth, endMonth) && recurrence !== 'once', {
      stack: 'startMonthInvalid:true',
    }),
    ...insertIf(!validateEnd(startMonth, endMonth, reviewPeriod), {
      stack: 'endMonthInvalid:true',
    }),
    ...insertIf(!validateImpact(startMonth, reviewPeriod), {
      stack: 'startMonthReviewInvalid:true',
    }),
  ];

  if (isNotEmpty(mockErrors)) {
    yield put(
      evaluatorActionCreators.updateError({
        id,
        schema: {
          ...schema,
          id,
        },
        formData: data,
        errors: mockErrors,
        uiSchemaOrder,
        startMonth,
        endMonth,
        startMonthLinked,
        endMonthLinked,
        growth,
        recurrence,
        meta,
      })
    );

    if (showErrorModal) {
      NiceModal.show(ModalInfo, {
        title: (
          <FormattedMessage
            id="session.error.hasErrors.modal.title"
            defaultMessage="Benefit errors"
          />
        ),
        body: (
          <FormattedMessage
            id="session.error.hasErrors.modal.body"
            defaultMessage="One or more benefits in this session have errors. Please check them and correct accordingly."
          />
        ),
      });
    }
  } else {
    yield call(calculate, {
      id,
      formData: data,
      startMonth,
      endMonth,
      startMonthLinked,
      endMonthLinked,
      growth,
      schema,
      meta,
    });
  }
}

export default caluclateEvaluator;
