// @flow
import React from 'react';
import Popper from '../popper';
export type DefaultProps = {
  usePortal: Boolean,
};
export type Props = DefaultProps & {
  referenceComponent?: Function,
  children: Element<any> | string,
  open: Boolean,
  onVisibilityChange: noop,
  usePortal: Boolean,
  triggerOnClick: Function,
};

const Menu = ({
  referenceComponent,
  children,
  open,
  onVisibilityChange,
  usePortal,
  triggerOnClick,
  as,
  ...props
}: Props) => (
  <Popper
    trigger="click"
    popperProps={{
      visible: open,
      delayShow: 0,
      trigger: 'click',
      onVisibleChange: onVisibilityChange,
    }}
    content={children}
    usePortal={usePortal}
    triggerOnClick={triggerOnClick}
    toolTipContainerProps={{ as }}
    {...props}
  >
    {referenceComponent}
  </Popper>
);

Menu.defaultProps = {
  usePortal: false,
};

export default Menu;
