/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

let IconGrowth = (props): React.Element<any> => (
  <Icon {...props}>
    <rect x="5" y="19" width="1" height="4" fill="inherit" />
    <rect x="9" y="16" width="1" height="7" fill="inherit" />
    <rect x="13" y="17" width="1" height="6" fill="inherit" />
    <rect x="17" y="13" width="1" height="10" fill="inherit" />
    <path
      d="M19,22V13a.9.9,0,0,0-1-1H17a.9.9,0,0,0-1,1v9H15V17a.9.9,0,0,0-1-1H13a.9.9,0,0,0-1,1v5H11V16a.9.9,0,0,0-1-1H9a.9.9,0,0,0-1,1v6H7V18.8A.9.9,0,0,0,6,18H5a.9.9,0,0,0-1,.8V22H2v1H5V19H6v4H9V16h1v7h3V17h1v6h3V13h1V23h3V22Z"
      fill="currentColor"
    />
    <path
      d="M3,16a.9.9,0,0,1-.7-.3,1,1,0,0,1,0-1.4l9-9a1.1,1.1,0,0,1,.8-.3,1,1,0,0,1,.7.4l2.3,3.1,5.2-5.2a1,1,0,0,1,1.4,1.4l-6,6a1.1,1.1,0,0,1-.8.3,1,1,0,0,1-.7-.4L11.9,7.5,3.7,15.7A.9.9,0,0,1,3,16Z"
      fill="inherit"
    />
  </Icon>
);

export default memo(IconGrowth);
