"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toFixed = exports.roundWithFloatingFixedPoint = void 0;

var _constants = require("./constants");

var _fp = require("./fp");

var _math = require("./math");

// prettier-ignore
const roundWithFloatingFixedPoint = (0, _fp.pipe)((0, _math.divideBy)(_constants.floatingFixedPointFactor), Math.round, (0, _fp.multiply)(_constants.floatingFixedPointFactor)); // prettier-ignore

exports.roundWithFloatingFixedPoint = roundWithFloatingFixedPoint;
const toFixed = (0, _fp.curryN)(2, (decimalPlaces, value) => parseFloat(value.toFixed(decimalPlaces)));
exports.toFixed = toFixed;