const SelectOption = ({ font, colors }) => ({
  backgroundSelected: colors.primary,
  backgroundFocused: colors.uiLightest,
  backgroundDisabled: null,
  color: font.color,
  colorSelected: colors.white,
  colorDisabled: colors.uiLightest,
  hightlightBorder: `1px solid ${colors.uiLightest}`,
  fontWeight: font.weightNormal,
  hightlightFontWeight: font.weightSemiBold,
});

export default SelectOption;
