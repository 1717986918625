/* @flow */
/* eslint-disable no-magic-numbers */

import React, { Element, useState, Fragment, memo } from 'react';
import { withTheme } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import useMeasure from '../utils/useMeasure';
import { useSpring, animated } from 'react-spring';
import {
  StyledPanel,
  PanelHeader,
  PanelHeaderContainer,
  HeaderContainer,
  ToolbarContainer,
  PanelBody,
  IconWrapper,
  NotAnimatedBody,
} from './styled';
import { IconAnimated, IconChevronRight } from '../icon';
import Button from '../button';
import ToolbarDivider from '../toolbar/divider';
import * as easings from 'd3-ease';

export type Props = {
  headerComponent: Any,
  headerContainer: Any,
  bodyComponent: Any,
  header: Element<*> | String,
  children: Element<*> | String,
  toolbar: Element<*> | String,
  footer: Element<*> | String,
  border: Boolean,
  margin: String | Number,
  maxWidth: String | Number,
  width: String | Number,
  collapsibleProps: Object,
  collapsible: Boolean,
  headerIcon: any,
  theme: Object,
  onCollapseClick: Function,
  defaultOpen: Boolean,
  headerIconProps: Object,
};

const Panel = ({
  headerComponent: HeaderComponent = PanelHeader,
  headerContainer: HeaderContainerComponent = PanelHeaderContainer,
  bodyComponent: BodyComponent = PanelBody,
  toolbar,
  header,
  children,
  footer,
  border = true,
  margin,
  maxWidth,
  width,
  defaultOpen = true,
  collapsible = false,
  headerIcon,
  theme,
  onCollapseClick,
  headerIconProps,
  ...props
}: Props): Element<any> => {
  const [isOpen, setOpen] = useState(defaultOpen);
  const [showChildren, setShowChildren] = useState(defaultOpen);
  const [bind, { height: viewHeight }] = useMeasure();

  const [playing, setPlaying] = useState('stopped');

  const rotateProps = useSpring({
    transform: `rotate(${isOpen ? 90 : 0}deg)`,
    config: { duration: 350, easing: easings.easeCubic },
    immediate: !collapsible,
  });
  const heightProps = useSpring({
    height: isOpen ? viewHeight : 0,
    immediate: !collapsible,
    onRest: () => {
      isOpen ? setShowChildren(true) : setShowChildren(false);
    },
  });
  const updateOpen = () => {
    if (onCollapseClick) onCollapseClick();
    setOpen(!isOpen);
    if (isOpen) {
      setShowChildren(true);
    }
  };
  const handleLottieMouseLeave = () => {
    setPlaying('stopped');
  };
  const handleLottieMouseEnter = () => {
    setPlaying('playing');
  };

  const BodyComp = collapsible ? animated.div : NotAnimatedBody;

  return (
    <StyledPanel
      border={border}
      {...props}
      margin={margin}
      maxWidth={maxWidth}
      width={width}
      onMouseEnter={handleLottieMouseEnter}
      onMouseLeave={handleLottieMouseLeave}
      style={{ overflow: 'hidden' }}
    >
      {(header || toolbar) && (
        <HeaderComponent
          collapsible={collapsible}
          isOpen={isOpen}
          onClick={() => collapsible && updateOpen()}
        >
          {headerIcon && (
            <IconWrapper>
              <IconAnimated
                playingState={playing}
                speed={1}
                direction={1}
                primaryColor={theme.animatedIcons.primary}
                secondaryColor={theme.animatedIcons.secondary}
                size="width: 50px; height: 50px;"
                animationData={headerIcon}
                {...headerIconProps}
              />
            </IconWrapper>
          )}
          <HeaderContainerComponent headerIcon={headerIcon}>
            {header && <HeaderContainer>{header}</HeaderContainer>}

            <ToolbarContainer>
              {toolbar}
              {collapsible && (
                <Fragment>
                  {toolbar && <ToolbarDivider />}
                  <animated.div style={rotateProps}>
                    <Button
                      size="icon"
                      variant="flat"
                      color="light"
                      tooltip={
                        isOpen ? (
                          <FormattedMessage id="panel.collapse.tooltip" defaultMessage="Collapse" />
                        ) : (
                          <FormattedMessage id="panel.expand.tooltip" defaultMessage="Expand" />
                        )
                      }
                      tooltipConfig={{
                        usePortal: true,
                      }}
                      onClick={updateOpen}
                    >
                      <IconChevronRight />
                    </Button>
                  </animated.div>
                </Fragment>
              )}
            </ToolbarContainer>
          </HeaderContainerComponent>
        </HeaderComponent>
      )}
      {children && (
        <BodyComp style={heightProps}>
          <div {...bind}>
            <BodyComponent hasHeading={header || toolbar}>
              {(showChildren || isOpen) && children}
            </BodyComponent>
          </div>
        </BodyComp>
      )}
      {footer}
    </StyledPanel>
  );
};
export default withTheme(memo(Panel));
