import React from 'react';
import { useTransition } from 'react-spring';
import { Container } from './styled';

const Component = ({ children, isLoading, background, zIndex, immediate = false }) => {
  const transitions = useTransition(isLoading, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    reverse: isLoading,
    immediate,
  });
  return transitions(
    (styles, item) =>
      item && (
        <Container
          style={{ ...(background && { background }), ...(zIndex && { zIndex }), ...styles }}
        >
          {children}
        </Container>
      )
  );
};

export default Component;
