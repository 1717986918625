// @flow
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Button from '../button';
import { IconStarOutline, IconStarSolid } from '../icon';
import { FormattedMessage } from 'react-intl';
import { StarredIcon } from './styled';

const StarToggle = ({
  isStarred = false,
  saveStarred,
  unstarTooltip = <FormattedMessage id="star.tooltip.unstar" defaultMessage="Unstar" />,
  starTooltip = <FormattedMessage id="star.tooltip.star" defaultMessage="Star" />,
  ...props
}) => {
  const [starred, setStarred] = useState(isStarred);

  useEffect(
    () => {
      setStarred(isStarred);
    },
    [isStarred] // Only call effect if debounced name changes
  );

  return (
    <Button
      size="icon"
      variant="flat"
      color="light"
      tooltip={starred ? unstarTooltip : starTooltip}
      onClick={() => {
        const newStarred = !starred;
        setStarred(newStarred);
        saveStarred(newStarred);
      }}
      {...props}
    >
      {starred ? (
        <StarredIcon>
          <IconStarSolid />
        </StarredIcon>
      ) : (
        <IconStarOutline />
      )}
    </Button>
  );
};

export default StarToggle;
