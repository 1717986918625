/* @flow */
/* eslint-disable max-len */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path fill="inherit" d="M17,12.5v3h-3v2h3v3h2v-3h3v-2h-3v-3H17z" />
    <path
      fill="currentColor"
      d="M2,3.5v14c0,1.1,0.9,2,2,2h8.8c-1.1-2-1.1-4.4,0.2-6.3v-1.7h1.7c1-0.7,2.1-1,3.3-1
	c1.5,0,2.9,0.5,4,1.5V3.5H2z M11,17.5H4v-6h7V17.5z M20,9.5H4v-3h16V9.5z"
    />
  </Icon>
);

export default memo(IconComponent);
