/* @flow */
/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { Row } from './styled';
import TableContext from './context';
import TableContext2 from './context2';

const Component = ({ children, ...props }) => {
  const { useRowSelect } = useContext(TableContext);
  const { variant, numberOfChildren } = useContext(TableContext2);

  return (
    <Row
      $variant={variant}
      $numberOfChildren={numberOfChildren}
      $useRowSelect={useRowSelect && variant === 'body'}
      {...props}
    >
      {children}
    </Row>
  );
};

export default Component;
