"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toBuffer = exports.bufferToFormat = void 0;

var _fp = require("./fp");

// returns a function which encodes the first parameter to toString
// when that function is called it invokes toString on the given argument
// Buffer(1).toString('hex') ~~ bufferToFormat('hex')(Buffer(1))
const bufferToFormat = (0, _fp.invoker)(1, 'toString');
exports.bufferToFormat = bufferToFormat;
const toBuffer = (0, _fp.flip)((0, _fp.constructN)(2, Buffer));
exports.toBuffer = toBuffer;