/* eslint-disable default-case */
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { buffers } from 'redux-saga';
import { call, put, select, all, fork, delay, take, actionChannel } from 'redux-saga/effects';
import LogRocket from 'logrocket';
import {
  getActionTypePath,
  appSelectors,
  sessionSelectors,
  preferencesSelectors,
  settingsSelectors,
  loginSelectors,
  userSelectors,
  deviceSelectors,
  questionnaireSelectors,
  intlSelectors,
  benefitsSelectors,
  evaluatorSelectors,
  cashflowsSelectors,
  sessionDetailsSelectors,
  nonRecurringCostsSelectors,
  recurringCostsSelectors,
  map,
  mapObjIndexed,
  values,
  curry,
  isEmpty,
} from '@sharkfinesse/sfl-lib';
import { actionCreators as persistenceActionScreators } from 'redux/modules/persistence';
import Whitelist, { getPeristenceField } from './whitelist';
import rsfApp from 'redux/rsf';
import ModalInfo from 'components/modal/modal-info';
import NiceModal from '@ebay/nice-modal-react';
import ReactMarkdown from 'react-markdown';
import {
  getSessionRef,
  getFileRef,
  sessionDoc_Update,
  sessionDoc_FieldUpdate,
  sessionDoc_FieldsUpdate,
} from './backend/session.js';
import { cashflow_Set } from './backend/cashflow.js';
import firebase from 'firebase/app';
import { types as sessionTypes } from 'redux/modules/session';
import { types as sessionDetailsTypes } from 'redux/modules/session-details';
import { types as benefitTypes } from 'redux/modules/evaluator';
import { types as defaultSessionValuesTypes } from 'redux/modules/default-session-values';
import { types as nonRecurringCostsTypes } from 'redux/modules/non-recurring-costs';
import { types as recurringCostsTypes } from 'redux/modules/recurring-costs';
import { types as layoutTypes } from 'redux/modules/layout';
import { types as cashflowTypes } from 'redux/modules/cashflow';

const THROTTLE_TIME = 1500; // throttle time in milisseconds

const throttle = (ms, pattern, task, ...args) => {
  return fork(function* () {
    const throttleChannel = yield actionChannel(pattern, buffers.sliding(1));

    while (true) {
      const action = yield take(throttleChannel);
      const persistState = yield select(appSelectors.getPersistState);
      if (persistState) {
        yield put(persistenceActionScreators.serverSave.request());
        yield fork(task, ...args, action);
        yield delay(ms);
        yield put(persistenceActionScreators.serverSave.success());
      }
    }
  });
};

export const saveActions = values(
  mapObjIndexed((type, key) => throttle(THROTTLE_TIME, key, save))(Whitelist)
);

const getUpdateFileObject = ({ fileObj, session, name, email, uid }) => {
  fileObj = {
    ...fileObj,
    dealName: session['details'].dealName,
    ...(session.owner === uid && { ownerName: name, ownerEmail: email }),
  };

  if (session['details']?.media?.src) {
    fileObj = {
      ...fileObj,
      ...(session['details']?.media?.id && { imageId: session['details'].media.id }),
      ...(session['details']?.media?.src && {
        imageUrl: session['details'].media.src,
      }),
    };
  } else {
    fileObj = {
      ...fileObj,
      imageUrl: firebase.firestore.FieldValue.delete(),
      imageId: firebase.firestore.FieldValue.delete(),
    };
  }
  return fileObj;
};

const getField = (key, val) => ({
  [key]: val ? val : firebase.firestore.FieldValue.delete(),
});

// let's separate this function for better modularity
export function* save(action) {
  const { type, payload } = action;
  const [account, path, session, onlineState, name, email, uid] = yield all([
    select(userSelectors.getAccount),
    getActionTypePath(type),
    select(sessionSelectors.getSession),
    select(deviceSelectors.getState),
    select(userSelectors.getName),
    select(userSelectors.getEmail),
    select(userSelectors.getId),
  ]);

  const field = getPeristenceField(type);
  const path1 = path[0];
  const path2 = path[1];
  const pathSuffix = '';
  const currentField = getPeristenceField(benefitTypes.CURRENT.UPDATE);

  try {
    if (path1 === 'session') {
      const sessionRef = getSessionRef({ account, id: session.id });
      const fileRef = getFileRef({ sessionRef });
      const firestore = rsfApp.firestore;
      const batch = yield call([firestore, firestore.batch]);

      const docUpdate = curry(sessionDoc_Update)(fileRef, batch, session);
      const fieldsUpdate = curry(sessionDoc_FieldsUpdate)(fileRef, batch);
      const cfSet = curry(cashflow_Set)(fileRef, batch, session);

      let updateFileObject =
        action.type !== benefitTypes.CURRENT.UPDATE
          ? {
              updated: new Date(),
              updatedBy: uid,
              updatedByName: name,
              updatedByEmail: email,
              updateAction: 'save',
            }
          : {};

      if (
        action.type === sessionTypes.UNDO ||
        action.type === sessionTypes.REDO ||
        action.type === sessionTypes.SAVE ||
        action.type === benefitTypes.ENDMONTH_LINKED_FLAG.UPDATE ||
        onlineState === 'offline'
      ) {
        updateFileObject = getUpdateFileObject({
          fileObj: updateFileObject,
          session,
          name,
          email,
          uid,
        });

        yield all([
          docUpdate('details'),
          docUpdate('businessCase'),
          docUpdate('businessPreparation'),
          docUpdate('suiteList'),
          docUpdate('benefits'),
          docUpdate('nonRecurringCosts'),
          docUpdate('recurringCosts'),
          docUpdate('layout'),
          docUpdate('defaultSessionValues'),
          docUpdate('delay'),
          docUpdate('tender'),
          docUpdate('doNothing'),
          cfSet('benefits'),
          cfSet('nonRecurringCosts'),
          cfSet('recurringCosts'),
          cfSet('editted_benefits'),
          cfSet('editted_nonRecurringCosts'),
          cfSet('editted_recurringCosts'),
          docUpdate('plugins'),
          docUpdate('questionnaire'),
        ]);
      } else {
        const fieldUpdate = curry(sessionDoc_FieldUpdate)(fileRef, batch, path2);
        switch (path2) {
          case 'cashflow':
            if (action.type === cashflowTypes.COSTS.RESET) {
              yield all([
                docUpdate('nonRecurringCosts'),
                docUpdate('recurringCosts'),
                cfSet('nonRecurringCosts'),
                cfSet('recurringCosts'),
                cfSet('editted_nonRecurringCosts'),
                cfSet('editted_recurringCosts'),
              ]);
            }

            if (action.type === cashflowTypes.COSTS.RECURRING.CALCULATE) {
              yield all([cfSet('recurringCosts'), cfSet('editted_recurringCosts')]);
            }
            if (action.type === cashflowTypes.COSTS.NONRECURRING.CALCULATE) {
              yield all([cfSet('nonRecurringCosts'), cfSet('editted_nonRecurringCosts')]);
            }

            break;
          case 'details':
            switch (action.type) {
              case sessionDetailsTypes.REVIEW_PERIOD.UPDATE:
                const rP = yield select(sessionDetailsSelectors.getRevPeriod);
                yield fieldUpdate(field, rP);
                break;
              case sessionDetailsTypes.DEFAULT_START_MONTH.COMPLETE:
                yield all([
                  docUpdate('benefits'),
                  cfSet('benefits'),
                  cfSet('nonRecurringCosts'),
                  cfSet('recurringCosts'),
                  cfSet('editted_benefits'),
                  cfSet('editted_nonRecurringCosts'),
                  cfSet('editted_recurringCosts'),
                  docUpdate('details'),
                ]);
                break;
              default:
                yield fieldUpdate(field, payload);
                break;
            }

            updateFileObject = getUpdateFileObject({
              fileObj: updateFileObject,
              session,
              name,
              email,
              uid,
            });

            break;
          case 'benefits':
            switch (action.type) {
              case benefitTypes.SUBHEADING.UPDATE:
              case benefitTypes.NOTES.UPDATE:
                yield fieldUpdate(`data.${payload.id}.${field}`, payload[field]);
                break;
              case benefitTypes.USER_TITLE:
                yield fieldUpdate(`data.${payload.id}.values.${field}`, payload[field]);
                break;
              case benefitTypes.CURRENT.UPDATE:
                yield fieldUpdate(field, payload);
                break;
              default:
                yield docUpdate(path2);
                break;
            }
            break;
          case 'nonRecurringCosts':
            switch (action.type) {
              case nonRecurringCostsTypes.DESCRIPTION.CHANGE:
              case nonRecurringCostsTypes.TYPE.CHANGE:
              case nonRecurringCostsTypes.CALCULATE:
              case nonRecurringCostsTypes.ROW.DYNAMIC_ADD:
                const [cost, result, ids, cf, eCf] = yield all([
                  select(nonRecurringCostsSelectors.getCost, { id: payload.id }),
                  select(nonRecurringCostsSelectors.getResult),
                  select(nonRecurringCostsSelectors.getIds),
                  select(cashflowsSelectors.getCashflowNonRecurringCostsById, payload.id),
                  select(cashflowsSelectors.getCashflowNonRecurringCostsEdittedById, payload.id),
                ]);
                if (ids.length === 1 || isEmpty(cost)) {
                  yield all([
                    docUpdate(path2),
                    cfSet('nonRecurringCosts'),
                    cfSet('editted_nonRecurringCosts'),
                  ]);
                } else {
                  yield all([
                    fieldsUpdate(path2, {
                      ...getField(`costs.${payload.id}`, cost),
                      ids,
                      result,
                    }),

                    fieldsUpdate(
                      'nonRecurringCostsCashflow',
                      getField(`nonRecurringCosts.${payload.id}`, cf)
                    ),
                    fieldsUpdate(
                      'editted_nonRecurringCostsCashflow',
                      getField(`editted_nonRecurringCosts.${payload.id}`, eCf)
                    ),
                  ]);
                }
                break;
              case nonRecurringCostsTypes.CASHFLOW.SHOW:
                const showCf = yield select(nonRecurringCostsSelectors.getShowCashflow, {
                  id: payload.id,
                });
                yield fieldUpdate(`costs.${payload.id}.${field}`, showCf);
                break;

              case nonRecurringCostsTypes.ROW.REORDER:
              case nonRecurringCostsTypes.NOTES.CHANGE:
                yield fieldUpdate(field, payload);
                break;
            }
            break;
          case 'recurringCosts':
            switch (action.type) {
              case recurringCostsTypes.DESCRIPTION.CHANGE:
              case recurringCostsTypes.TYPE.CHANGE:
              case recurringCostsTypes.CALCULATE:
              case recurringCostsTypes.ROW.DYNAMIC_ADD:
              case recurringCostsTypes.END.DEAL_PERIOD_CHANGE:
                const [cost, result, ids, cf, eCf] = yield all([
                  select(recurringCostsSelectors.getCost, { id: payload.id }),
                  select(recurringCostsSelectors.getResult),
                  select(recurringCostsSelectors.getIds),
                  select(cashflowsSelectors.getCashflowRecurringCostsById, payload.id),
                  select(cashflowsSelectors.getCashflowRecurringCostsEdittedById, payload.id),
                ]);

                if (ids.length === 1 || isEmpty(cost)) {
                  yield all([
                    docUpdate(path2),
                    cfSet('recurringCosts'),
                    cfSet('editted_recurringCosts'),
                  ]);
                } else {
                  yield all([
                    fieldsUpdate(path2, {
                      ...getField(`costs.${payload.id}`, cost),
                      ids,
                      result,
                    }),

                    fieldsUpdate(
                      'recurringCostsCashflow',
                      getField(`recurringCosts.${payload.id}`, cf)
                    ),
                    fieldsUpdate(
                      'editted_recurringCostsCashflow',
                      getField(`editted_recurringCosts.${payload.id}`, eCf)
                    ),
                  ]);
                }

                break;
              case recurringCostsTypes.CASHFLOW.SHOW:
                const showCf = yield select(recurringCostsSelectors.getShowCashflow, {
                  id: payload.id,
                });
                yield fieldUpdate(`costs.${payload.id}.${field}`, showCf);
                break;
              case recurringCostsTypes.ROW.REORDER:
              case recurringCostsTypes.NOTES.CHANGE:
                yield fieldUpdate(field, payload);
                break;
            }
            break;
          case 'delay':
          case 'tender':
          case 'doNothing':
          case 'businessCase':
          case 'businessPreparation':
          case 'suiteList':
          case 'plugins':
          case 'questionnaire':
            yield docUpdate(path2);
            break;
          case 'layout':
            switch (action.type) {
              case layoutTypes.SUITE.DUPLICATING_COMPLETE:
              case layoutTypes.SUITE.DELETE_COMPLETE:
                yield all([
                  docUpdate('benefits'),
                  cfSet('benefits'),
                  cfSet('editted_benefits'),
                  docUpdate(path2),
                  docUpdate('layout'),
                  docUpdate('defaultSessionValues'),
                  docUpdate('suiteList'),
                ]);
                break;
              case layoutTypes.BENEFIT.DO_DELETE:
                yield all([
                  sessionDoc_FieldUpdate(
                    fileRef,
                    batch,
                    'benefits',
                    currentField,
                    payload.currentBenefit
                  ),
                  docUpdate(path2),
                  cfSet('benefits'),
                  cfSet('editted_benefits'),
                ]);

                break;
              case layoutTypes.BENEFIT.INSERTING_COMPLETE:
              case layoutTypes.BENEFIT.DUPLICATING_COMPLETE:
                const [benefit, cf, eCf, currentBenfit] = yield all([
                  select(evaluatorSelectors.getBenefitData, payload.id),
                  select(cashflowsSelectors.getBenefitCashflow, payload.id),
                  select(cashflowsSelectors.getEdittedBenefitCashflow, payload.id),
                  select(evaluatorSelectors.getCurrent),
                ]);

                yield sessionDoc_FieldUpdate(
                  fileRef,
                  batch,
                  'benefits',
                  currentField,
                  currentBenfit
                );

                if (payload?.linkedIds) {
                  yield all(
                    map(
                      defaultId =>
                        sessionDoc_FieldUpdate(
                          fileRef,
                          batch,
                          'defaultSessionValues',
                          `linked.${defaultId}`,
                          firebase.firestore.FieldValue.arrayUnion(payload.id)
                        ),
                      payload.linkedIds
                    )
                  );
                }

                if (benefit) {
                  yield fieldsUpdate('benefits', {
                    ...getField(`data.${payload.id}`, benefit),
                    ids: firebase.firestore.FieldValue.arrayUnion(payload.id),
                  });
                }
                if (cf) {
                  yield fieldsUpdate('benefitsCashflow', getField(`benefits.${payload.id}`, cf));
                }

                if (eCf) {
                  yield fieldsUpdate(
                    'editted_benefitsCashflow',
                    getField(`editted_benefits.${payload.id}`, eCf)
                  );
                }

                yield docUpdate(path2);

                break;
              default:
                yield docUpdate(path2);
                break;
            }
            break;
          case 'defaultSessionValues':
            switch (action.type) {
              case defaultSessionValuesTypes.LINK_INPUT_COMPLETE:
                yield fieldUpdate(
                  `linked.${action.payload.defaultSessionValueId}`,
                  firebase.firestore.FieldValue.arrayUnion(action.payload.evalUid)
                );

                break;
              case defaultSessionValuesTypes.CALCULATE:
              case defaultSessionValuesTypes.DELETE.EVAL:
                const { evalUid: id } =
                  action.type === defaultSessionValuesTypes.DELETE.EVAL
                    ? action.values
                    : action.payload;
                const [ids, benefit, cf, eCf] = yield all([
                  select(benefitsSelectors.getIds),
                  select(evaluatorSelectors.getBenefitData, id),
                  select(cashflowsSelectors.getBenefitCashflow, id),
                  select(cashflowsSelectors.getEdittedBenefitCashflow, id),
                ]);

                if (payload?.linkedIds) {
                  yield all(
                    map(
                      defaultId =>
                        fieldUpdate(
                          `linked.${defaultId}`,
                          firebase.firestore.FieldValue.arrayUnion(action.payload.evalUid)
                        ),
                      payload.linkedIds
                    )
                  );
                }

                if (payload?.unlinkedIds) {
                  yield all(
                    map(
                      defaultId =>
                        fieldUpdate(
                          `linked.${defaultId}`,
                          firebase.firestore.FieldValue.arrayRemove(action.payload.evalUid)
                        ),
                      payload.unlinkedIds
                    )
                  );
                }

                if (type === defaultSessionValuesTypes.DELETE.EVAL) {
                  yield all(
                    mapObjIndexed(
                      (defaultId, linkedField) =>
                        fieldUpdate(
                          `linked.${linkedField}`,
                          firebase.firestore.FieldValue.arrayRemove(action?.values?.evalUid)
                        ),
                      action?.values?.linked
                    )
                  );
                }

                yield all([
                  fieldsUpdate('benefits', {
                    ...getField(`data.${id}`, benefit),
                    ...getField('ids', ids),
                  }),

                  fieldsUpdate('benefitsCashflow', getField(`benefits.${id}`, cf)),
                  fieldsUpdate('editted_benefitsCashflow', getField(`editted_benefits.${id}`, eCf)),
                ]);
                break;
              case defaultSessionValuesTypes.PRISTINE_CHANGE:
                const { defaultSessionValueId, value, decimals, measurement, multiYearValues } =
                  action.payload;
                yield fieldsUpdate(path2, {
                  ...getField(`data.${defaultSessionValueId}`, value),
                  ...getField(`schema.${defaultSessionValueId}`, {
                    decimals,
                    measurement,
                    defaultSessionValueId,
                    ...(multiYearValues && { multiYear: true }),
                  }),
                  [`linked.${defaultSessionValueId}`]: firebase.firestore.FieldValue.arrayUnion(
                    action.payload.evalUid
                  ),
                  showInfo: false,
                });
                break;
              case defaultSessionValuesTypes.UNLINK_INPUT:
                yield fieldUpdate(
                  `linked.${action.payload.defaultSessionValueId}`,
                  firebase.firestore.FieldValue.arrayRemove(action.payload.evalUid)
                );
                break;
              case defaultSessionValuesTypes.CHANGE:
                yield fieldUpdate(
                  `data.${action.payload.defaultSessionValueId}`,
                  action.payload.value
                );
                break;
              case defaultSessionValuesTypes.UPDATE:
              case defaultSessionValuesTypes.DELETE.ALL:
              case defaultSessionValuesTypes.UPDATE_COMPLETE:
                yield all([
                  docUpdate('benefits'),
                  cfSet('benefits'),
                  cfSet('editted_benefits'),
                  docUpdate(path2),
                ]);
                if (payload?.saveLayout) {
                  yield docUpdate('layout');
                  yield docUpdate('defaultSessionValues');
                }
                break;
            }
            break;
        }
      }

      yield call([batch, batch.update], sessionRef, updateFileObject);
      if (onlineState === 'offline') {
        batch.commit();
      } else {
        yield call([batch, batch.commit]);
      }
    } else if (path1 === 'preferences') {
      let [state, id] = yield all([
        select(preferencesSelectors.getPreferences),
        select(loginSelectors.getUID),
      ]);
      state = {
        [path2]: state[path2],
      };
      yield call(rsfApp.rsf.firestore.updateDocument, `/${path1}/${id}${pathSuffix}`, state);
    } else if (path1 === 'settings') {
      let [state, id] = yield all([
        select(settingsSelectors.getSettings),
        select(loginSelectors.getUID),
      ]);
      state = {
        [path2]: state[path2],
      };
      yield call(rsfApp.rsf.firestore.updateDocument, `/${path1}/${id}${pathSuffix}`, state);
    } else if (path1 === 'questionnaire') {
      const questionnaireId = yield select(questionnaireSelectors.getId);
      const { type, payload } = action;

      if (type === '@@questionnaire/SUBMIT.UPDATE') {
        yield call(
          rsfApp.rsf.firestore.updateDocument,
          `/accounts/${account}/questionnaires/${questionnaireId}`,
          { submitted: payload }
        );
      }
      if (type === '@@questionnaire/BENEFIT_DATA.SUBMITTED') {
        yield call(
          rsfApp.rsf.firestore.updateDocument,
          `/accounts/${account}/questionnaires/${questionnaireId}`,
          { benefitData: payload, updated: firebase.firestore.FieldValue.serverTimestamp() }
        );
      }
      if (type === '@@questionnaire/DEFAULT_SESSION_VALUES.SUBMITTED') {
        yield call(
          rsfApp.rsf.firestore.updateDocument,
          `/accounts/${account}/questionnaires/${questionnaireId}`,
          {
            defaultSessionValues: payload,
            updated: firebase.firestore.FieldValue.serverTimestamp(),
          }
        );
      }
      if (type === '@@questionnaire/TERMS_ACCEPTED.SUBMIT') {
        yield call(
          rsfApp.rsf.firestore.updateDocument,
          `/accounts/${account}/questionnaires/${questionnaireId}`,
          {
            termsAccepted: payload,
          }
        );
      }
    }
  } catch (error) {
    console.log('error', error);
    const [intl] = yield all([
      select(intlSelectors.getMessages),
      put(persistenceActionScreators.serverSave.failure()),
    ]);
    yield call([NiceModal, NiceModal.show], ModalInfo, {
      title: (
        <FormattedMessage
          id="app.state.offline.notAvailable.modal.title"
          defaultMessage="We're sorry..."
        />
      ),
      body: (
        <>
          <ReactMarkdown>{intl['error.saving']}</ReactMarkdown>
        </>
      ),
    });

    LogRocket.captureException(error);
  }
}
