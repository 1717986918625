import applyTheme from '../../styles/apply-theme';
import styled from 'styled-components';
import { animated } from 'react-spring';

const timeline = applyTheme('timeline');

export const Timeline = timeline('base', styled.ul);
export const TimelineItem = timeline('item', styled.li);
export const TimelineSeparator = timeline('separator');
export const TimelineAvatar = timeline('avatar');
export const TimelineConnector = timeline('connector');
export const TimelineContent = timeline('content');
export const TimelineContentAnimated = timeline('content', styled(animated.div));
export const TimelineOppositeContent = timeline('oppositeContent');
export const TimelineBubble = timeline('bubble');
export const TimelineDate = timeline('date');
export const TimelineLabel = timeline('label');
export const TimelineDetail = timeline('detail', styled.ul);
export const TimelineTitle = timeline('title');
export const TimelineBoldTitle = timeline('boldTitle');
export const TimelineDateLabel = timeline('dateLabel');
export const TimelineDot = timeline('timelineDot');
export const TimelineLabelBubble = timeline('labelBubble');
export const TimelineLabelContainer = timeline('labelContainer');
