import { css } from 'styled-components';

const Styles = ({ font, colors, scale }) => ({
  base: src => css`
    font-size: 12px;
    color: #cacaca;
    padding-left: 4px;
  `,
});

export default Styles;
