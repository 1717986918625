import rsfApp from 'redux/rsf';
import { contains } from '@sharkfinesse/sfl-lib';

export const copyCollection = async ({ src, dest, immediate, omitted }) => {
  const documents = await rsfApp.firestore.collection(src).get();
  let writeBatch = rsfApp.firestore.batch();
  const destCollection = rsfApp.firestore.collection(dest);
  let i = 0;

  for (const doc of documents.docs) {
    if (!contains(doc.id, omitted)) {
      writeBatch.set(destCollection.doc(doc.id), doc.data());
      i++;
      if (i > 400) {
        // write batch only allows maximum 500 writes per batch
        i = 0;
        if (immediate) {
          writeBatch.commit();
        } else {
          await writeBatch.commit();
        }
        writeBatch = rsfApp.firestore.batch();
      }
    }
  }
  if (i > 0) {
    if (immediate) {
      writeBatch.commit();
    } else {
      await writeBatch.commit();
    }
  }
};
