// @flow
/* eslint-disable no-magic-numbers, react/prop-types */
import React, { useState } from 'react';
import { SlideBody, SlideIcon, SlideImage, SlideGraphics, Checkbox, Tag } from './styled';
import EmptyImage from './empty-image';

const SlideBodyComponent = ({
  imageUrl,
  iconUrl,
  checked,
  onCheckChange,
  onCheckClick,
  tag,
  checkBoxVisibility,
  icon,
  ...other
}) => {
  const [brokenImage, setBrokenImage] = useState(false);

  return (
    <SlideBody $empty={brokenImage || (!imageUrl && !iconUrl && !icon)} {...other}>
      <SlideGraphics>
        {imageUrl && !brokenImage && (
          <SlideImage src={imageUrl} onError={() => setBrokenImage(true)} />
        )}
        {iconUrl && <SlideIcon src={iconUrl} />}
        {icon}
        {!imageUrl && !iconUrl && !icon && <EmptyImage />}
        {brokenImage && <EmptyImage />}
      </SlideGraphics>
      {tag && <Tag>{tag}</Tag>}
      {onCheckChange && (
        <Checkbox
          checked={checked}
          onClick={e => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            if (onCheckClick) onCheckClick();
          }}
          onChange={onCheckChange}
          visibility={checked ? 'visible' : checkBoxVisibility}
          round
        />
      )}
    </SlideBody>
  );
};

export default SlideBodyComponent;
