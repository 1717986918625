// @flow
import React, { useRef, useState, useEffect, useMemo, memo, Fragment } from 'react';
import CellInput from './cell-input';
import { formatForDisplay, monthsPerYear } from '@sharkfinesse/sfl-lib';
import {
  inc,
  insertAll,
  remove,
  repeat,
  includes,
  length,
  isNil,
  isEmpty,
  moneyMask,
  update,
} from '@sharkfinesse/sfl-lib';
import ToolbarButton from '../toolbar-button';
import { ToolbarDivider } from '../toolbar';
import { IconSmooth, IconPencil, IconArrowExpand, IconArrowCollapse } from '../icon';
import { FormattedMessage } from 'react-intl';
import Scroll from '../scroll';
import {
  Toolbar,
  ToolbarButtonGroup,
  Grid,
  Cell,
  FirstColumnHeader,
  RowHeader,
  ColumnHeader,
  Ellipsis,
  Container,
  Wrapper,
} from './styled';
import 'styled-components/macro';

const Cashflow = ({
  cashflow,
  editMode = false,
  smooth,
  revert,
  updateEditMode,
  updateSmooth,
  updateCashflow,
  canUseEditing,
  reviewPeriod,
  numberFormatObj,
  editted,
  canUseSmooth,
  editable,
  height = '544px',
  modifiers,
  toolbarProps,
  id,
  UserPosition,
  cellInputProps,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [selecting, setSelecting] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [start, setStart] = useState();
  const [startValue, setStartValue] = useState();
  const [dragStart, setDragStart] = useState();
  const [gridColumnHeadings, setGridColumnHeadings] = useState([]);
  const tempCashflow = useRef(cashflow);

  useEffect(() => {
    tempCashflow.current = cashflow;
  }, [cashflow]);

  const years = !isNil(cashflow) ? Math.ceil((cashflow.length - 1) / monthsPerYear) : 0;
  const cellNumberFormat = {
    ...numberFormatObj,
    decimalPlaces: 2,
  };

  const editOnClick = () => updateEditMode(!editMode);
  const resetOnClick = () => {
    revert();
  };

  const onValueChanged = ({ value, month, year }) => {
    const newCashflow = update(year * monthsPerYear + month, Number(value), cashflow);
    updateCashflow(newCashflow);
  };

  const onMouseDown = (e, month, year) => {
    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('touchend', onMouseUp);
    const cell = year * monthsPerYear + month;
    setSelecting(true);
    setStart(cell);
    setStartValue(cashflow[cell]);
    setDragStart(`${month}-${year}`);
  };

  const onMouseOver = (e, month, year) => {
    const yr = year * monthsPerYear;
    const end = yr + month;
    const repeatCount = inc(Math.abs(start - end));
    const dragValueArray = repeat(startValue, repeatCount);
    const removedArray = remove(end < start ? end : start, repeatCount, cashflow);
    const newCashflow = insertAll(end < start ? end : start, dragValueArray, removedArray);
    const startKey = end < start ? `${month}-${year}` : dragStart;
    const startArray = startKey.split('-');
    let startMonth = Number(startArray[0]);
    let startYear = Number(startArray[1]);
    const selectedKeysArray = [startKey];
    while (length(selectedKeysArray) < repeatCount) {
      if (startMonth === 12) {
        startYear++;
        startMonth = 1;
      } else {
        startMonth++;
      }
      selectedKeysArray.push(`${startMonth}-${startYear}`);
    }
    tempCashflow.current = newCashflow;
    setSelectedKeys(selectedKeysArray);
  };

  const onMouseUp = (e, month, year) => {
    e.stopPropagation();
    window.removeEventListener('mouseup', onMouseUp);
    window.removeEventListener('touchend', onMouseUp);
    if (!isNil(tempCashflow.current)) {
      updateCashflow(tempCashflow.current);
    }
    setSelectedKeys([]);
    setSelecting(false);
  };

  const getCell = (value, month, year) => (
    <Fragment key={`${month}-${year}`}>
      {canUseEditing && editable && editMode ? (
        <Cell>
          <CellInput
            id={id}
            month={month}
            year={year}
            value={value}
            trackId={`${id}_${month}_${year}`}
            trackOnFocusProps={{ fieldId: `${id}_${month}_${year}` }}
            trackOnBlurProps={{}}
            onChange={e => onValueChanged({ value: e, month, year })}
            onMouseDown={onMouseDown}
            onMouseOver={onMouseOver}
            onMouseUp={onMouseUp}
            selecting={selecting}
            selected={includes(`${month}-${year}`, selectedKeys)}
            numberFormatObj={cellNumberFormat}
            autoFocus={month === 0 && year === 0}
            {...cellInputProps}
          />
        </Cell>
      ) : (
        <UserPosition id={`${id}_${month}_${year}`} key={`${month}-${year}`}>
          <Cell>{formatForDisplay('money', numberFormatObj, value)}</Cell>
        </UserPosition>
      )}
    </Fragment>
  );

  useMemo(() => {
    const tmpColumnHeadings = [
      <FirstColumnHeader key="header-month">
        <FormattedMessage id="cashflow.month.title" defaultMessage="Month" />
      </FirstColumnHeader>,
    ];

    if (years !== 0) {
      for (let i = 0; i < years; i++) {
        tmpColumnHeadings.push(
          <ColumnHeader key={i}>
            <FormattedMessage
              id="session.benefits.profile.year"
              defaultMessage="Year {year}"
              values={{ year: i + 1 }}
            />
          </ColumnHeader>
        );
      }

      setGridColumnHeadings(tmpColumnHeadings);
    }
  }, [years]);

  const getGrid = currentCashflow => {
    const gridRowHeadings = [];
    const gridCells = [];
    if (currentCashflow) {
      for (let month = 0; month <= monthsPerYear; month++) {
        if (currentCashflow.length !== month && currentCashflow.length > month) {
          gridRowHeadings.push(
            <RowHeader key={month}>
              <Ellipsis>{month}</Ellipsis>
            </RowHeader>
          );

          for (let year = 0; year < years; year++) {
            const key = `${month}-${year}`;
            const current = month + year * monthsPerYear;
            if (month === 0) {
              if (year === 0) {
                gridCells.push(getCell(currentCashflow[0], month, year));
              } else {
                gridCells.push(<Cell key={key} />);
              }
            } else if (!isNil(currentCashflow?.[current])) {
              gridCells.push(getCell(currentCashflow[current], month, year));
            } else if (month < 13) {
              gridCells.push(<Cell key={key} />);
            }
          }
        }
      }
    }
    return [gridRowHeadings, gridCells];
  };

  return isEmpty(cashflow) ? (
    <div>
      <FormattedMessage id="cashflow.empty" defaultMessage="Complete the calculation" />
    </div>
  ) : (
    <Wrapper $expanded={expanded}>
      <Toolbar
        {...toolbarProps}
        $borderTop={toolbarProps?.$borderTop ? true : expanded ? true : false}
      >
        {canUseSmooth ? (
          <ToolbarButton
            icon={<IconSmooth size="small" />}
            onClick={updateSmooth}
            toggled={!editted && smooth}
            disabled={editted}
            label={<FormattedMessage id="cashflow.smooth" defaultMessage="Smooth" />}
          />
        ) : (
          <div />
        )}

        <ToolbarButtonGroup>
          {canUseEditing && editable && (
            <>
              {editted && (
                <>
                  <ToolbarButton
                    onClick={resetOnClick}
                    label={<FormattedMessage id="cashflow.reset" defaultMessage="Reset" />}
                  />
                  <ToolbarDivider />
                </>
              )}
              <ToolbarButton
                icon={<IconPencil size="small" />}
                onClick={editOnClick}
                toggled={editMode}
                label={<FormattedMessage id="cashflow.edit" defaultMessage="Edit" />}
              />
            </>
          )}
          <ToolbarDivider />
          <ToolbarButton
            onClick={() => setExpanded(!expanded)}
            iconPosition="center"
            toggled={expanded}
            icon={expanded ? <IconArrowCollapse size="small" /> : <IconArrowExpand size="small" />}
          />
        </ToolbarButtonGroup>
      </Toolbar>

      <Container $height={height}>
        <Scroll>
          <Grid years={years}>
            {gridColumnHeadings}
            {getGrid(selecting ? tempCashflow.current : cashflow)}
          </Grid>
          {modifiers}
        </Scroll>
      </Container>
    </Wrapper>
  );
};

export default memo(Cashflow);
