/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon viewBox="0 0 28 28" {...props}>
    <path
      fill="#00A1E0"
      d="M16.6,3.1c1.3-1.3,3.1-2.2,5.1-2.2c2.6,0,4.9,1.5,6.2,3.7c1.1-0.5,2.2-0.7,3.5-0.7c4.8,0,8.6,3.9,8.6,8.7
	s-3.9,8.7-8.6,8.7c-0.6,0-1.1-0.1-1.7-0.2c-1.1,1.9-3.1,3.2-5.5,3.2c-1,0-1.9-0.2-2.8-0.6c-1.1,2.6-3.6,4.4-6.6,4.4
	c-3.1,0-5.7-2-6.7-4.7c-0.4,0.1-0.9,0.1-1.4,0.1c-3.7,0-6.7-3-6.7-6.7c0-2.5,1.3-4.7,3.3-5.8c-0.4-0.9-0.6-2-0.6-3.1
	C2.7,3.5,6.2,0,10.5,0C13,0,15.2,1.2,16.6,3.1"
    />
    <path
      fill="#FFFFFF"
      d="M5.8,14.5C5.8,14.6,5.8,14.6,5.8,14.5C5.9,14.7,6,14.7,6,14.7C6.4,15,6.8,15,7.2,15c0.8,0,1.3-0.4,1.3-1.1v0
	c0-0.6-0.6-0.9-1.1-1.1l-0.1,0c-0.4-0.1-0.8-0.2-0.8-0.5v0c0-0.2,0.2-0.4,0.5-0.4c0.4,0,0.8,0.1,1,0.3c0,0,0.1,0.1,0.1,0
	c0,0,0.2-0.4,0.2-0.4c0,0,0-0.1,0-0.1c-0.3-0.2-0.7-0.3-1.2-0.3H7.2c-0.8,0-1.3,0.5-1.3,1.1v0c0,0.7,0.6,0.9,1.1,1.1l0.1,0
	c0.4,0.1,0.7,0.2,0.7,0.5v0c0,0.3-0.2,0.4-0.6,0.4c-0.1,0-0.6,0-1.1-0.3c-0.1,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0L5.8,14.5L5.8,14.5z
	 M17.8,14.5C17.8,14.6,17.8,14.6,17.8,14.5c0.1,0.1,0.2,0.2,0.2,0.2c0.4,0.2,0.8,0.3,1.2,0.3c0.8,0,1.3-0.4,1.3-1.1v0
	c0-0.6-0.6-0.9-1.1-1.1l-0.1,0c-0.4-0.1-0.8-0.2-0.8-0.5v0c0-0.2,0.2-0.4,0.5-0.4c0.4,0,0.8,0.1,1,0.3c0,0,0.1,0.1,0.1,0
	c0,0,0.2-0.4,0.2-0.4c0,0,0-0.1,0-0.1c-0.3-0.2-0.7-0.3-1.2-0.3h-0.1c-0.8,0-1.3,0.5-1.3,1.1v0c0,0.7,0.6,0.9,1.1,1.1l0.1,0
	c0.4,0.1,0.7,0.2,0.7,0.5v0c0,0.3-0.2,0.4-0.6,0.4c-0.1,0-0.6,0-1.1-0.3c-0.1,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0L17.8,14.5
	L17.8,14.5z M26,13.1c0,0.4-0.1,0.7-0.2,0.9c-0.1,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3c-0.1-0.2-0.2-0.5-0.2-0.9
	s0.1-0.7,0.2-0.9c0.1-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3C25.9,12.4,26,12.7,26,13.1 M26.7,12.4c-0.1-0.2-0.2-0.4-0.3-0.6
	c-0.1-0.2-0.3-0.3-0.5-0.4c-0.2-0.1-0.5-0.1-0.7-0.1c-0.3,0-0.5,0-0.7,0.1c-0.2,0.1-0.4,0.2-0.5,0.4c-0.1,0.2-0.2,0.4-0.3,0.6
	c-0.1,0.2-0.1,0.5-0.1,0.7s0,0.5,0.1,0.7c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.3,0.3,0.5,0.4c0.2,0.1,0.5,0.1,0.7,0.1
	c0.3,0,0.5,0,0.7-0.1c0.2-0.1,0.4-0.2,0.5-0.4c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.1-0.5,0.1-0.7S26.8,12.6,26.7,12.4 M32.3,14.3
	C32.3,14.2,32.2,14.3,32.3,14.3c-0.2,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.4,0c-0.3,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.9
	c0-0.4,0.1-0.7,0.3-0.9c0.2-0.2,0.4-0.3,0.8-0.3c0.3,0,0.5,0,0.7,0.1c0,0,0.1,0,0.1,0c0.1-0.2,0.1-0.3,0.2-0.5c0-0.1,0-0.1,0-0.1
	c-0.1,0-0.3-0.1-0.5-0.1c-0.1,0-0.3,0-0.5,0c-0.3,0-0.5,0-0.8,0.1c-0.2,0.1-0.4,0.2-0.6,0.4s-0.3,0.4-0.3,0.6
	c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.6,0.2,1,0.5,1.4c0.3,0.3,0.8,0.5,1.4,0.5c0.4,0,0.7-0.1,1-0.2c0,0,0,0,0-0.1L32.3,14.3L32.3,14.3z
	 M33.5,12.8c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.6,0.3c0.1,0.1,0.1,0.3,0.2,0.5H33.5L33.5,12.8z
	 M35.8,12.3c-0.1-0.2-0.2-0.4-0.3-0.5c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2-0.1-0.4-0.1-0.7-0.1c-0.3,0-0.6,0-0.8,0.1
	c-0.2,0.1-0.4,0.2-0.5,0.4c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.3,0,0.5,0.1,0.7c0.1,0.2,0.2,0.4,0.3,0.6
	c0.2,0.2,0.3,0.3,0.6,0.4C34,15,34.2,15,34.6,15c0.7,0,1-0.2,1.2-0.2c0,0,0,0,0-0.1l-0.2-0.4c0-0.1-0.1,0-0.1,0
	c-0.2,0.1-0.4,0.2-1,0.2c-0.4,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.4-0.3-0.8l2.3,0c0,0,0.1,0,0.1-0.1C35.9,13.3,36,12.8,35.8,12.3
	 M14.9,12.8c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.4-0.3,0.7-0.3s0.5,0.1,0.6,0.3c0.1,0.1,0.1,0.3,0.2,0.5H14.9z M17.3,12.3
	c-0.1-0.2-0.2-0.4-0.3-0.5c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2-0.1-0.4-0.1-0.7-0.1c-0.3,0-0.6,0-0.8,0.1c-0.2,0.1-0.4,0.2-0.5,0.4
	c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.3,0,0.5,0.1,0.7c0.1,0.2,0.2,0.4,0.3,0.6c0.2,0.2,0.3,0.3,0.6,0.4
	C15.4,15,15.7,15,16,15c0.7,0,1-0.2,1.2-0.2c0,0,0,0,0-0.1L17,14.2c0-0.1-0.1,0-0.1,0c-0.2,0.1-0.4,0.2-1,0.2
	c-0.4,0-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.4-0.3-0.8l2.3,0c0,0,0.1,0,0.1-0.1C17.3,13.3,17.4,12.8,17.3,12.3 M9.9,14.3
	c-0.1-0.1-0.1-0.1-0.1-0.1c0-0.1-0.1-0.2-0.1-0.3c0-0.2,0.1-0.4,0.2-0.4c0,0,0.2-0.2,0.7-0.2c0.3,0,0.6,0.1,0.6,0.1v1.1l0,0
	c0,0-0.3,0.1-0.6,0.1C10.1,14.4,9.9,14.3,9.9,14.3 M10.9,12.7c-0.1,0-0.2,0-0.4,0c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.3,0.1-0.5,0.2
	c-0.1,0.1-0.3,0.2-0.3,0.4S9,13.7,9,13.9c0,0.2,0,0.4,0.1,0.5c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.1,0.3,0.2,0.5,0.2
	c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.5,0,0.7-0.1c0.2,0,0.5-0.1,0.6-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1-0.1,0.1-0.1l0-2.1
	c0-0.5-0.1-0.8-0.4-1c-0.2-0.2-0.6-0.3-1.1-0.3c-0.2,0-0.5,0-0.6,0.1c0,0-0.5,0.1-0.7,0.3c0,0,0,0,0,0.1l0.2,0.4c0,0.1,0.1,0,0.1,0
	s0,0,0,0c0.4-0.2,1-0.2,1-0.2c0.3,0,0.4,0,0.6,0.2c0.1,0.1,0.2,0.2,0.2,0.6v0.1C11,12.7,10.9,12.7,10.9,12.7 M29.6,11.5
	C29.6,11.4,29.5,11.4,29.6,11.5c-0.1-0.1-0.3-0.1-0.4-0.2c-0.3,0-0.5,0-0.6,0.1c-0.1,0.1-0.3,0.2-0.4,0.3v-0.3c0,0,0-0.1-0.1-0.1
	h-0.6c0,0-0.1,0-0.1,0.1v3.5c0,0,0,0.1,0.1,0.1h0.6c0,0,0.1,0,0.1-0.1v-1.7c0-0.2,0-0.5,0.1-0.6c0-0.1,0.1-0.3,0.2-0.3
	s0.2-0.1,0.3-0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.3,0,0.3,0c0,0,0.1,0,0.1-0.1C29.4,11.8,29.5,11.5,29.6,11.5"
    />
    <path
      fill="#FFFFFF"
      d="M23.8,9.9c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0.1-1,0.4c-0.2,0.2-0.4,0.6-0.5,1.1l0,0.2
	h-0.5c0,0-0.1,0-0.1,0.1l-0.1,0.5c0,0,0,0.1,0.1,0.1h0.5l-0.5,2.9c0,0.2-0.1,0.4-0.1,0.6c-0.1,0.1-0.1,0.3-0.2,0.3
	c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.2,0.4-0.2,0.5
	c0,0,0,0.1,0,0.1c0.1,0,0.1,0,0.2,0.1c0.1,0,0.2,0,0.3,0c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.3,0.3-0.5
	c0.1-0.2,0.1-0.5,0.2-0.8l0.5-3h0.8c0,0,0.1,0,0.1-0.1l0.1-0.5c0,0,0-0.1-0.1-0.1h-0.7c0,0,0-0.3,0.1-0.5c0-0.1,0.1-0.2,0.2-0.2
	c0.1-0.1,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0L23.8,9.9
	C23.9,9.9,23.9,9.9,23.8,9.9 M13.5,14.9C13.5,14.9,13.5,14.9,13.5,14.9l-0.7,0.1c0,0-0.1,0-0.1-0.1V9.9c0,0,0-0.1,0.1-0.1h0.6
	c0,0,0.1,0,0.1,0.1L13.5,14.9L13.5,14.9z"
    />
  </Icon>
);

export default memo(IconComponent);
