import rsfApp from 'redux/rsf';

export function getUsersByAccount({ account }) {
  return rsfApp.firestore.collection('users').where('account', '==', account).get();
}

export function getUsersByEmailProduct({ product, email }) {
  return rsfApp.firestore
    .collection('users')
    .where('product', '==', product)
    .where('email', '==', email)
    .get();
}

export const fetchUsers = async ({ account, product }) => {
  if (account && !product)
    return rsfApp.firestore.collection('users').where('account', '==', account).get();

  return rsfApp.firestore
    .collection('users')
    .where('account', '==', account)
    .where('product', '==', product)
    .get();
};
