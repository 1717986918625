/* @flow */
import React from 'react';
import { Container, Outline, Selected, Label, HiddenRadioButton } from './styled';

const RadioButton = ({ checked, onChange, label, disabled, ...props }) => (
  <Container {...props}>
    <HiddenRadioButton checked={checked} onChange={onChange} disabled={disabled} />
    <Outline $checked={checked}>{checked && <Selected />}</Outline>
    {label && (
      <Label
        onClick={() => {
          if (!disabled) onChange();
        }}
      >
        {label}
      </Label>
    )}
  </Container>
);

export default RadioButton;
