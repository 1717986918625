import { useQuery } from 'react-query';
import fetchSession from './fetch-session';

const getItems = async props => {
  return fetchSession(props);
};

const useSession = (props, config) => {
  return useQuery(`session_${props.id}`, () => getItems(props), config);
};

export default useSession;
