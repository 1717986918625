/* @flow */
import {
  createReducer,
  simpleAction,
  sessionPluginsSalesforceInitialState,
} from '@sharkfinesse/sfl-lib';
import firebase from 'firebase/app';
const namespace = (name: string): string => `@@session.plugins.salesforce/${name}`;

//Actions
export const types = {
  ACCOUNT: { UPDATE: namespace('ACCOUNT.UPDATE') },
  ACCOUNT_NAME: { UPDATE: namespace('ACCOUNT_NAME.UPDATE') },
  OPPORTUNITY: { UPDATE: namespace('OPPORTUNITY.UPDATE') },
  OPPORTUNITY_NAME: { UPDATE: namespace('OPPORTUNITY_NAME.UPDATE') },
  OPPORTUNITY_OBJECT_ID: { UPDATE: namespace('OPPORTUNITY_OBJECT_ID.UPDATE') },
  RESET: namespace('RESET'),
  SYNC: namespace('SYNC'),
};

//Reducer
export const initialState = sessionPluginsSalesforceInitialState;

//const reduceAccount = simpleReducer('account');

const reduceAccount = (state, action) => {
  return {
    ...state,
    account: action.payload,
    opportunity: '',
    opportunityName: '',
    opportunityObjectId: '',
    opportunitySyncDate: '',
  };
};

//const reduceAccountName = simpleReducer('accountName');

const reduceAccountName = (state, action) => {
  return {
    ...state,
    accountName: action.payload,
    opportunity: '',
    opportunityName: '',
    opportunityObjectId: '',
    opportunitySyncDate: '',
  };
};

//const reduceOpportunity = simpleReducer('opportunity');
const reduceOpportunity = (state, action) => {
  return {
    ...state,
    opportunity: action.payload,
    opportunityObjectId: '',
    opportunitySyncDate: '',
  };
};

//const reduceOpportunityName = simpleReducer('opportunityName');
const reduceOpportunityName = (state, action) => {
  return {
    ...state,
    opportunityName: action.payload,
    opportunityObjectId: '',
    opportunitySyncDate: '',
  };
};

const reduceOpportunityObjectId = (state, action) => {
  return {
    ...state,
    opportunityObjectId: action.payload,
    opportunitySyncDate: firebase.firestore.Timestamp.fromDate(new Date()),
  };
};

const reduceReset = () => initialState;

const reduceSync = (state, action) => ({
  ...state,
  ...action.payload,
});

export default createReducer(
  {
    [types.ACCOUNT.UPDATE]: reduceAccount,
    [types.ACCOUNT_NAME.UPDATE]: reduceAccountName,
    [types.OPPORTUNITY.UPDATE]: reduceOpportunity,
    [types.OPPORTUNITY_NAME.UPDATE]: reduceOpportunityName,
    [types.OPPORTUNITY_OBJECT_ID.UPDATE]: reduceOpportunityObjectId,
    [types.RESET]: reduceReset,
    [types.SYNC]: reduceSync,
  },
  initialState
);

//Action Creators
const updateAccount = simpleAction(types.ACCOUNT.UPDATE);
const updateAccountName = simpleAction(types.ACCOUNT_NAME.UPDATE);
const updateOpportunity = simpleAction(types.OPPORTUNITY.UPDATE);
const updateOpportunityName = simpleAction(types.OPPORTUNITY_NAME.UPDATE);
const updateOpportunityObjectId = simpleAction(types.OPPORTUNITY_OBJECT_ID.UPDATE);
const doReset = simpleAction(types.RESET);
const sync = simpleAction(types.SYNC);

export const actionCreators = {
  updateAccount,
  updateAccountName,
  updateOpportunity,
  updateOpportunityName,
  updateOpportunityObjectId,
  doReset,
  sync,
};
