/* @flow */
import React from 'react';
import { StyledHandle } from './styled';

type Props = {
  domain: Array,
  handle: Object,
  disabled: Boolean,
  getHandleProps: Function,
};

const Handle = ({
  domain: [min, max],
  handle: { id, value, percent },
  disabled = false,
  getHandleProps,
}: Props) => (
  <StyledHandle
    role="slider"
    aria-valuemin={min}
    aria-valuemax={max}
    aria-valuenow={value}
    percent={percent}
    disabled={disabled}
    {...getHandleProps(id)}
  />
);

export default Handle;
