/* @flow */
/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { Cell } from './styled';
import TableContext from './context';
import TableContext2 from './context2';
import { includes } from '@sharkfinesse/sfl-lib';

const Component = ({ children, isLastRow, index, ...props }) => {
  const { stickyColumns } = useContext(TableContext);
  const { variant } = useContext(TableContext2);
  return (
    <Cell $variant={variant} $isSticky={includes(index, stickyColumns)} {...props}>
      {children}
    </Cell>
  );
};

export default Component;
