import { css } from 'styled-components';
import { lightenHsla } from '@sharkfinesse/sfl-lib';

const Styles = ({ font, colors, scale, device: { min_xxs, min_xs, min_md } }) => ({
  ul: () => css`
    margin: 0;
    padding: 0;
    li:not(:last-child) {
      margin-bottom: 10px;
    }
  `,
  ulColumns: () => css`
    margin: 0;
    padding: 10px;
    display: grid;
    grid-gap: 10px;
    li:not(:last-child) {
      margin: 0;
    }

    @media ${min_xxs} {
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    }
    @media ${min_xs} {
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
    @media ${min_md} {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
  `,
  container: ({ isOpen, selected, small }) => css`
    display: flex;
    flex-direction: column;
    list-style: none;
    border-radius: ${small ? '4px' : '6px'};
    background: ${colors.white};
    ${selected
      ? css`
          box-shadow: 0 0 0 ${small ? '1px' : '2px'} ${colors.primary};
          background: ${lightenHsla(33, colors.primary)};
        `
      : css`
          transition: background 0.3s, box-shadow 0.3s;
          box-shadow: 0 0 0 1px ${colors.uiLight};
          &:hover {
            box-shadow: 0 0 0 ${small ? '1px' : '2px'} ${lightenHsla(10, colors.primary)};
            background: ${lightenHsla(36, colors.primary)};
          }
        `}
  `,
  wrapper: ({ small }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    @media ${min_xxs} {
      padding: ${small ? '2px 4px' : '2px 6px 2px 2px'};
    }
    @media ${min_md} {
      padding: ${small ? '2px 6px' : '6px 10px 6px 6px'};
    }
    justify-content: space-between;
    cursor: pointer;
  `,
  label: ({ small }) => css`
    @media ${min_xxs} {
      font-size: ${small ? '14px' : '14px'};
      line-height: 16px;
    }
    @media ${min_md} {
      font-size: ${small ? '16px' : '18px'};
      line-height: 20px;
    }
    min-height: 36px;
    display: flex;
    align-items: center;
    margin-left: 12px;
  `,
  body: () => css`
    overflow: hidden;
  `,
  expandButton: () => css`
    height: 32px;
  `,
  chevronIcon: () => css`
    color: ${font.color};
  `,
  leftContent: () => css`
    display: flex;
    align-items: center;
  `,
  rightContent: () => css`
    display: flex;
    align-items: center;
  `,
});

export default Styles;
