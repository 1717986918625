/* eslint-disable no-magic-numbers */

import {
  Colors,
  Font,
  Scale,
  SuiteButton,
  ButtonPrimary,
  ButtonSmall,
  ButtonNormal,
  ButtonLarge,
  ButtonDefault,
  Images,
  Panel,
  Suite,
  Toast,
  Costs,
  Form,
  Scroll,
  Modal,
  BenefitLayoutDesigner,
  CompareSessions,
  ReactTable,
  breakpoints,
  Device,
  Slide,
  ResultBox,
  Skeleton,
  Notification,
  ListPanel,
  ExpandingListItem,
  Pill,
  General,
} from './core';
import BaseTheme from './base';
import { hsla, lightenHsla, darkenHsla, setAlphaHsla, merge, isNil } from '@sharkfinesse/sfl-lib';
import { borderStyle, borderWidth, backgrounds } from 'polished';
import { css } from 'styled-components';

const baseAlpha = 1;

//colors
const grays = hsla(0, 0);
const uiBase = grays(7, baseAlpha);

//*******************************
// config
//******************************
const colors = Colors();
colors.primaryHex = '#2387aa';
colors.primary = hsla(196, 66, 40, baseAlpha);
colors.secondary = hsla(25, 83, 47, baseAlpha);

colors.uiDarkest = hsla(200, 11, 26, baseAlpha);
colors.uiDark = lightenHsla(2, uiBase);
colors.ui = hsla(206, 17, 60, baseAlpha);
colors.uiLight = hsla(204, 24, 79, baseAlpha);
colors.uiLightest = hsla(228, 19, 95, baseAlpha);
colors.popperBox = hsla(0, 0, 100, baseAlpha);
colors.gradientsCls = css`
  ${colors.gradientsCls}

  .gradient2 {
    background: rgba(0, 100, 110, 0.85);
  }
  .gradient1,
  .gradient12,
  .gradient9,
  .gradient8,
  .gradient11,
  .gradient4,
  .gradient13,
  .gradient14,
  .gradient15 {
    background: linear-gradient(90deg, #50bebe 0, #41aaaa 50%, #0099cb);
  }
`;

const scale = Scale();

const font = Font();
font.size = '16px';
font.color = hsla(200, 4, 33, baseAlpha);
font.sizeSmall = scale.minusOne;
font.sizeNormal = scale.zero;
font.sizeLarge = scale.one;

const config = {
  colors,
  scale,
  font,
  breakpoints: breakpoints,
  device: Device,
};

//*******************************
// overrides
//******************************

const chartTheme = 'aqua';

const buttonSmall = ButtonSmall(config);
buttonSmall.borderRadius = 0;

const buttonNormal = ButtonNormal(config);
buttonNormal.borderRadius = 0;

const buttonLarge = ButtonLarge(config);
buttonLarge.borderRadius = 0;

const buttonDefault = ButtonDefault(config);
buttonDefault.background = `background: ${hsla(196, 66, 40, baseAlpha)}`;
buttonDefault.hoverBackground = `background: ${hsla(198, 100, 26, baseAlpha)}`;
buttonDefault.borderStyle = borderStyle('none');
buttonDefault.borderWidth = borderWidth('0px');
buttonDefault.textShadow = 'none';
buttonDefault.boxShadow = 'none';
buttonDefault.activeBoxShadow = 'none';
buttonDefault.hoverBorderWidth = borderWidth('0px');
buttonDefault.fontColor = colors.white;
buttonDefault.fontWeight = font.weightNormal;

const buttonPrimary = ButtonPrimary(config);
buttonPrimary.background = `background: ${hsla(25, 83, 47, baseAlpha)}`;
buttonPrimary.hoverBackground = `background: ${hsla(21, 74, 45, baseAlpha)}`;
buttonPrimary.borderStyle = borderStyle('none');
buttonPrimary.borderWidth = borderWidth('0px');
buttonPrimary.textShadow = 'none';
buttonPrimary.boxShadow = 'none';
buttonPrimary.activeBoxShadow = 'none';
buttonPrimary.hoverBorderWidth = borderWidth('0px');
buttonPrimary.fontWeight = font.weightNormal;

const panelBase = Panel(config);
const panel = merge(panelBase, {
  base: () => css`
    ${panelBase.base}
    border-radius: 0;
    border-width: 0;
  `,
  header: ({ collapsible, isOpen }) => css`
    ${panelBase.header}
    border-width: 0;
    ${collapsible &&
    !isOpen &&
    css`
      transition: background 0.3s;

      &:hover {
        background: ${lightenHsla(45, colors.primary)};
      }
      cursor: pointer;
    `}
  `,
  panelBody: () => css`
    ${panelBase.panelBody}
    padding: ${scale.zero};
  `,
});

const suiteButtonBase = SuiteButton(config);
const suiteButton = merge(suiteButtonBase, {
  button: ({ result, isError }) => css`
    ${suiteButtonBase.button({ result })}

    border-radius: 0;

    ${!isNil(result)
      ? css`
          border: 2px solid ${colors.secondary};
        `
      : css`
          border: none;
        `}

    ${isError
      ? css`
          border: 2px solid ${colors.invalid};
        `
      : null}
  `,
  hoveredButton: ({ btnStyle }) => css`
    ${suiteButtonBase.hoveredButton}
    ${btnStyle === 'dark'
      ? css`
          background: radial-gradient(
            farthest-corner at 50% 50%,
            rgba(85, 104, 119, 0.5) 30%,
            #384956 100%
          );
        `
      : css`
          background: radial-gradient(
            farthest-corner at 50% 50%,
            rgba(255, 255, 255, 0.5) 30%,
            #e8e8e8 100%
          );
        `};
    border-radius: 0;
  `,
});

const costs = Costs(config);
costs.headBackground = backgrounds('#f0f1f5');
costs.footBackground = backgrounds('#f0f1f5');

const images = Images(config);
images.benefits = {
  leftBubble: {
    background: '#56b0b9',
    highlight: '#66d3de',
    lines: '#01404a',
    border: '#01404a',
  },
  rightBubble: {
    background: '#016792',
    highlight: '#2984aa',
    lines: '#cccccc',
    border: '#01404a',
  },
};
images.costs = {
  bag: {
    background: '#ffb236',
    highlight: '#ffc567',
    cord: '#dc6914',
    label: {
      background: '#FFFFFF',
      highlight: '#EFEFEF',
    },
  },
  money: {
    stack: '#56b0b9',
    note: {
      outer: '#56b0b9',
      inner: '#66d3de',
      middleCircle: {
        background: '#E7ECED',
        highlight: '#D4D8DA',
      },
      leftCircle: '#E7ECED',
      rightCircle: '#E7ECED',
    },
  },
  coin: {
    stack: {
      background: '#ed8b43',
      highlight: '#dc6914',
    },
    background: '#ed8b43',
  },
  border: '#01404a',
};
images.reports = {
  border: '#01404a',
  background: '#F9F9F9',
  highlight: '#EFEFEF',
  squareOne: '#66d3de',
  squareTwo: '#2984aa',
  lines: '#C1C1c1',
  corner: '#C1C1c1',
};
images.spreadsheets = {
  border: '#484848',
  background: '#F7F9FC',
  highlight: '#EAEDF4',
  titleBar: '#56b0b9',
  titleBarButtons: '#66d3de',
  labelCells: '#66d3de',
  mainCells: '#dddddd',
  headerCells: '#7f7f7f',
  toolbarBackground: '#d7deed',
  headingBarBackground: '#c7cfe2',
  toolBarButton1: '#c7cfe2',
  toolBarButton2: '#ed8b43',
  toolBarButton3: '#b4e1fa',
  toolBarButton4: '#eff2fa',
};
images.presentations = {
  border: '#484848',
  background: '#66d3de',
  highlight: '#209e99',
  topBottomBarBackground: '#a3adaf',
  topBottomBarHighlight: '#949b9b',
  labels: '#ffffff',
  pieSegment1: '#ed8b43',
  pieSegment2: '#e7edec',
  pole: '#ededed',
};
images.interactive = {
  border: '#484848',
  circles: '#ed8b43',
  circlesHighlight: '#dc6914',
  bubble: '#66d3de',
  bubbleText: '#484848',
  bubbleHighlight: '#209e99',
  hand: '#ffece4',
  handHighlight: '#e8d0c8',
};

const suiteBase = Suite(config);
const suite = merge(suiteBase, {
  base: () => css`
    ${suiteBase.base}
    border-radius: 0;
    border: none;
  `,
  footer: () => css`
    ${suiteBase.footer}
    border-top: none;
    padding: 0 ${scale.minusOne} ${scale.minusOne} ${scale.minusOne};
  `,
});

const toastBase = Toast(config);
const toast = merge(toastBase, {
  base: () => css`
    ${toastBase.base}
    border-radius: 0;
    border: none;
  `,
});

const scrollBase = Scroll(config);
const scroll = merge(scrollBase, {
  thumb: () => css`
    .ScrollbarsCustom-Thumb {
      border-radius: 0px !important;
      background: ${colors.ui} !important;
      &:hover {
        background: ${lightenHsla(15, colors.uiDark)} !important;
      }
    }
    .dragging {
      background: ${lightenHsla(15, colors.uiDark)} !important;
    }
  `,
  track: src => css`
    .ScrollbarsCustom-Track {
      border-radius: 0px !important;
      background: ${src ? 'transparent' : colors.uiLightest} !important;
    }
  `,
});

const modalBase = Modal(config);
const modal = merge(modalBase, {
  header: () => css`
    ${modalBase.header}
    border-radius: 0;
  `,
  body: ({ showFooterKeyline, showHeaderKeyline, showFooter }) => css`
    ${modalBase.body({ showFooterKeyline, showHeaderKeyline, showFooter })}
    ${!showFooter &&
    css`
      border-radius: 0;
    `}
  `,
  footer: () => css`
    ${modalBase.footer}
    border-radius: 0;
  `,
});

const benefitLayoutDesignerBase = BenefitLayoutDesigner(config);
const benefitLayoutDesigner = merge(benefitLayoutDesignerBase, {
  rightColumn: expanded => css`
    ${benefitLayoutDesignerBase.rightColumn}
    background: ${darkenHsla(20, colors.ui)};
  `,
  benfitListFooter: () => css`
    ${benefitLayoutDesignerBase.benfitListFooter}
    padding-top: ${scale.minusOne};
  `,
});

const compareSessionsBase = CompareSessions(config);
const compareSessions = merge(compareSessionsBase, {
  selected: {
    background: '#F6F6F9',
  },
  unselected: {
    background: 'transparent',
  },
});

const reactTableBase = ReactTable(config);
const reactTable = merge(reactTableBase, {
  container: () => css`
    ${reactTableBase.container}
  `,
  table: () => css`
    ${reactTableBase.table}
    background: ${colors.white};
  `,
  head: () => css`
    ${reactTableBase.head}
    background: ${colors.white};
  `,
  row: ({ $variant, $numberOfChildren, $selected, $useRowSelect }) => css`
    ${reactTableBase.row({ $variant, $numberOfChildren, $selected, $useRowSelect })}
    background: ${colors.white};
    border-bottom: solid 1px ${colors.uiLight};
    ${$useRowSelect &&
    css`
      cursor: pointer;
      ${!$selected &&
      css`
        &:hover {
          background-color: ${colors.uiLightest};
        }
      `}
      ${$selected &&
      css`
        background-color: ${colors.uiLightest};
        box-shadow: 0 0 0 2px ${colors.primary};
        border-radius: 2px;
        position: relative;
      `}
    `}
    ${$variant === 'head' &&
    css`
      user-select: none;
      border-bottom: none;
      background: #f0f1f5;
      &:first-child {
        background: #c2c6d6;
        font-weight: ${font.weightSemiBold};
        border-bottom: none;
      }
    `}
  `,
  cell: ({ $variant, $isSticky, $hover, $hovered, $selected }) => css`
    ${reactTableBase.cell({ $variant, $isSticky, $hover, $hovered, $selected })}
    border-bottom: none;
    ${$hovered &&
    !$selected &&
    css`
      background-color: #f9f9fb;
      border: 1px solid #f9f9fb;
    `}
    ${$selected &&
    css`
      background-color: #f9f9fb;
      border: 1px solid #f9f9fb;
    `}
    ${$variant === 'head' &&
    css`
      border-right: solid 1px ${colors.uiLight};
      &:first-child {
        justify-content: center;
      }
      &:last-child {
        border-right: none;
      }
    `};
  `,
});

const slideBase = Slide(config);
const slide = merge(slideBase, {
  slideBox: ({ checked, disabled }) => css`
    ${slideBase.slideBox({ checked, disabled })}
    border-radius: 0;
    box-shadow: 0 0 0 ${checked ? '2px' : '1px'} ${checked ? colors.primary : 'transparent'};
  `,
  slideBody: ({ $empty }) => css`
    ${slideBase.slideBody({ $empty })}
    border-radius: 0;
    background: none;
  `,
  slideImage: ({ $empty }) => css`
    ${slideBase.slideImage({ $empty })}
    border-radius: 0;
  `,
  pendingBox: ({ $empty }) => css`
    ${slideBase.pendingBox({ $empty })}
    border-radius: 0;
  `,
  tag: () => css`
    ${slideBase.tag()}
    border-radius: 0;
    padding: 4px 6px 0 10px;
  `,
  loading: () => css`
    ${slideBase.loading()}
    border-radius: 0;
  `,
});

const resultBoxBase = ResultBox(config);
const resultBox = merge(resultBoxBase, {
  bgBox: () => css`
    ${resultBoxBase.bgBox()}
    border-radius: 0;
  `,
});

const getBgColor = ({ color, colors }) => {
  //const color = css`background: linear-gradient(0deg, rgba(30, 135, 240, 1) 0%, rgba(64, 188, 249, 1) 100%)`
  if (color === 'uiLightest') return colors.uiLightest;
  if (color === 'white') return colors.white;
  if (color === 'primary') return colors.primary;
};

const skeletonBase = Skeleton({ ...config, bgColor: getBgColor });
const skeleton = merge(skeletonBase, {
  container: ({ color = 'uiLightest', borderRadius, id }) => css`
    ${skeletonBase.container({ color, borderRadius, id })}
    border-radius: 0;
  `,
});

const notificationBase = Notification(config);
const notification = merge(notificationBase, {
  wrapper: ({ onClick, $variant }) => css`
    ${notificationBase.wrapper({ onClick, $variant })}
    border-radius: 0px;
  `,
});

const listPanelBase = ListPanel(config);
const listPanel = merge(listPanelBase, {
  panel: () => css`
    ${listPanelBase.panel()}
    border-radius: 0px;
  `,
  headerImage: () => css`
    ${listPanelBase.headerImage()}
    border-radius: 0px;
  `,
  itemRow: () => css`
    ${listPanelBase.itemRow()}
    &:hover {
      background: ${setAlphaHsla(0.2, colors.primary)};
      border-radius: 0;
      border: 0;
    }
  `,
});

const expandingListItemBase = ExpandingListItem(config);
const expandingListItem = merge(expandingListItemBase, {
  container: ({ isOpen, selected, small }) => css`
    ${expandingListItemBase.container({ isOpen, selected, small })}
    border-radius: 0;
  `,
});
const pillBase = Pill(config);
const pill = merge(pillBase, {
  base: () => css`
    ${pillBase.base()}
    background: #099;
    border-radius: 0px;
  `,
});

const generalBase = General(config);
const general = merge(generalBase, {
  pillBg: () => css`
    ${generalBase.pillBg()}
    border-radius: 0;
  `,
});

const formBase = Form(config);
const form = merge(formBase, {
  outputWrapper: ({ $align }) => css`
    ${formBase.outputWrapper({ $align })}
    background: #f0f1f5;
  `,
});

const overrides = {
  chartTheme,
  buttonSmall,
  buttonNormal,
  buttonLarge,
  buttonDefault,
  buttonPrimary,
  images,
  panel,
  suiteButton,
  suite,
  toast,
  costs,
  form,
  scroll,
  modal,
  benefitLayoutDesigner,
  compareSessions,
  reactTable,
  slide,
  resultBox,
  skeleton,
  notification,
  listPanel,
  expandingListItem,
  pill,
  general,
};

let theme = BaseTheme('Aqua', config, overrides);

export default theme;
