/* @flow */
/* eslint-disable no-confusing-arrow */
import React, { useRef, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { formatForDisplay } from '@sharkfinesse/sfl-lib';

type Props = {
  numberFormatObj: Object,
  valueUnit: String,
  value: String | Number,
};

const AnimatedNumber = ({ numberFormatObj, valueUnit = 'money', value = 0 }: Props) => {
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
  });

  const animatedProps = useSpring({
    value,
    config: {
      duration: 500,
    },
  });

  const getValue = val =>
    numberFormatObj ? formatForDisplay(valueUnit, numberFormatObj, val) : Math.floor(val);

  return (
    <animated.div>
      {isFirstRender.current ? getValue(value) : animatedProps.value.to(value => getValue(value))}
    </animated.div>
  );
};

export default AnimatedNumber;
