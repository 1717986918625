import { useQuery } from 'react-query';
import useQuestionnaires from './useQuestionnaires';
import { questionnaireStatus } from '@sharkfinesse/sfl-lib';

const useQuestionnairesWithStatus = (props, config) => {
  const { data, isSuccess, isFetching } = useQuestionnaires(props, config);

  return useQuery(['questionnairesWithStatus'], () => questionnaireStatus(data), {
    enabled: isSuccess && !isFetching,
    ...config,
  });
};

export default useQuestionnairesWithStatus;
