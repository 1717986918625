import { path } from '@sharkfinesse/sfl-lib';
import { put, call } from 'redux-saga/effects';
import { actionCreators as evaluatorActionCreators } from 'redux/modules/evaluator';
import { actionCreators as cashflowActionCreators } from 'redux/modules/cashflow';
import undoToast from 'redux/sagas/toast/undo';

function* reset(action) {
  const meta = path(['meta'], action);
  const { benefitId } = action.payload;

  if (action.payload.duplicateEmpty) {
    yield put(evaluatorActionCreators.updateDelete({ id: benefitId, meta }));
    yield put(cashflowActionCreators.updateEvaluatorReset({ id: benefitId, meta }));
  } else {
    yield call(undoToast, {
      action,
      undoActions: [
        put(evaluatorActionCreators.updateDelete({ id: benefitId, meta })),
        put(cashflowActionCreators.updateEvaluatorReset({ id: benefitId, meta })),
      ],
      formattedMessageId: 'benefits.evaluator.reset.toast.message.value',
      defaultMessage: 'Undo reset benefit',
    });
  }
}

export default reset;
