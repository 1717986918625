import rsfApp from 'redux/rsf';

export const getTemplates = ({ product, account, limit = 20 }) =>
  rsfApp.firestore
    .collection(`accounts/${account}/sessions`)
    .where('templateFor', 'array-contains', product)
    .where('archived', '<', true)
    .orderBy('archived', 'desc')
    .orderBy('updated', 'desc')
    .limit(limit)
    .get();
