import { css } from 'styled-components';

const Headings = ({ font, colors, scale }) => ({
  subHeading: () => css`
    margin-bottom: 0px;
    font-size: 18px;
    padding: 10px 0px;
  `,
});

export default Headings;
