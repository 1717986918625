// @flow weak
/* eslint-disable no-magic-numbers */
import React from 'react';
import { IconInvalid } from '../icon';
import useFormControl from '../form-control/useFormControl';
import Popper from '../popper';
import { TooltipErrorContainer, TooltipErrorLabel, TooltipErrorContentContainer } from './styled';

const TootltipError = ({
  children,
  label,
  usePortal = true,
  placement = 'bottom',
  portalContainer,
  ...other
}) => {
  const formControl = useFormControl();

  return (
    <Popper
      popperProps={{
        visible: formControl?.invalid || formControl?.focused,
        trigger: 'none',
      }}
      content={
        formControl?.invalid ? (
          <TooltipErrorContainer>
            <IconInvalid />
            <TooltipErrorLabel>{label}</TooltipErrorLabel>
          </TooltipErrorContainer>
        ) : (
          <TooltipErrorContentContainer>{label}</TooltipErrorContentContainer>
        )
      }
      tipStyle={formControl?.invalid ? 'light' : 'dark'}
      usePortal={usePortal}
      placement={placement}
      portalContainer={portalContainer}
    >
      {children}
    </Popper>
  );
};

export default TootltipError;
