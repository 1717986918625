/* @flow */
const namespace = (name: string): string => `@@session.reports/${name}`;

//Actions
export const types = {
  LONG: {
    UPDATE: namespace('LONG.UPDATE'),
    GENERATE: namespace('LONG.GENERATE'),
  },
  SHORT: {
    UPDATE: namespace('SHORT.UPDATE'),
    GENERATE: namespace('SHORT.GENERATE'),
  },
  XLSX_SINGLE: {
    UPDATE: namespace('XLSX_SINGLE.UPDATE'),
    GENERATE: namespace('XLSX_SINGLE.GENERATE'),
  },
  PPTX_SINGLE: {
    UPDATE: namespace('PPTX_SINGLE.UPDATE'),
    GENERATE: namespace('PPTX_SINGLE.GENERATE'),
  },
  COMPARE_DOC: {
    UPDATE: namespace('COMPARE_DOC.UPDATE'),
    GENERATE: namespace('COMPARE_DOC.GENERATE'),
  },
  COMPARE_XLSX: {
    UPDATE: namespace('COMPARE_XLSX.UPDATE'),
    GENERATE: namespace('COMPARE_XLSX.GENERATE'),
  },
  PREP: {
    UPDATE: namespace('PREP.UPDATE'),
    GENERATE: namespace('PREP.GENERATE'),
  },
  LONG_SALESFORCE: {
    UPDATE: namespace('LONG_SALESFORCE.UPDATE'),
    GENERATE: namespace('LONG_SALESFORCE.GENERATE'),
  },
  PPT_SALESFORCE: {
    UPDATE: namespace('PPT_SALESFORCE.UPDATE'),
    GENERATE: namespace('PPT_SALESFORCE.GENERATE'),
  },
  XLSX_SALESFORCE: {
    UPDATE: namespace('XLSX_SALESFORCE.UPDATE'),
    GENERATE: namespace('XLSX_SALESFORCE.GENERATE'),
  },
};
