/* @flow */
import { simpleAction, createReducer, settingsBenefitsInitialState } from '@sharkfinesse/sfl-lib';
const namespace = (name: string): string => `@@settings.benefits/${name}`;

//Actions
export const types = {
  FILTERS: {
    VISIBLE: namespace('FILTERS.VISIBLE'),
  },
};

//Reducer
export const initialState = settingsBenefitsInitialState;
const reduceFiltersVisible = (state, action) => {
  return {
    ...state,
    filtersVisible: action.payload,
  };
};

export default createReducer(
  {
    [types.FILTERS.VISIBLE]: reduceFiltersVisible,
  },
  initialState
);

//Action Creators
const updateFiltersVisible = simpleAction(types.FILTERS.VISIBLE);

export const actionCreators = {
  updateFiltersVisible,
};
