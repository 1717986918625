// @flow
import React, { useState } from 'react';
import { withTheme } from 'styled-components';
import { IconAnimated } from '../icon';
import { NavItemWrapper, NavLabel, IconWrapper } from './styled';
import ProIcon from '../pro-icon';
import { PinTopRight } from '../general-styles';
import Tooltip from '../tooltip';

export type Props = {
  label?: Element<*> | String,
  icon?: Element<*> | String,
  theme: Object,
  onClick: Function,
  selected: Boolean,
};

const NavItem = ({
  icon,
  label,
  theme,
  onClick,
  selected,
  proIcon,
  proIconDisabled,
  tooltip,
  tabHeight,
}: Props) => {
  const [playing, setPlaying] = useState('stopped');
  const animatedIcon =
    typeof icon.type === 'object' || typeof icon.type === 'string' ? false : true;

  const nav = (
    <NavItemWrapper
      selected={selected}
      onMouseEnter={() => {
        setPlaying('playing');
      }}
      onMouseLeave={() => {
        setPlaying('stopped');
      }}
      onClick={onClick}
      tabHeight={tabHeight}
    >
      {proIcon && (
        <PinTopRight>
          <ProIcon disabled={proIconDisabled} />
        </PinTopRight>
      )}
      <div style={{ zIndex: 2 }}>
        {animatedIcon ? (
          <IconAnimated
            playingState={playing}
            speed={1}
            direction={1}
            primaryColor={theme.sidebar.iconHoverColor}
            secondaryColor={theme.sidebar.iconHoverColor}
            size="width: 34px; height: 34px;"
            animationData={icon}
          />
        ) : (
          <IconWrapper>{icon}</IconWrapper>
        )}
      </div>
      {!tooltip && <NavLabel selected={selected}>{label}</NavLabel>}
    </NavItemWrapper>
  );

  return tooltip ? (
    <Tooltip
      tooltip={label}
      tooltipConfig={{
        placement: 'right',
        usePortal: true,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-4, 0],
            },
          },
        ],
      }}
      delayShow={500}
    >
      {nav}
    </Tooltip>
  ) : (
    nav
  );
};

export default withTheme(NavItem);
