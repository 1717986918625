import { select, call, all, takeLatest } from 'redux-saga/effects';
import { history } from '../../../index';
import { types as benefitsTypes } from 'redux/modules/benefits';
import {
  isNil,
  isEmpty,
  evaluatorSelectors,
  browserSelectors,
  sessionSelectors,
} from '@sharkfinesse/sfl-lib';

export function* goToCurrent({ id }) {
  const [benefitId, browser, sessionId] = yield all([
    select(evaluatorSelectors.getCurrent),
    select(browserSelectors.getBrowser),
    select(sessionSelectors.getSessionId),
  ]);

  const benId = !isNil(id) ? id : benefitId;

  const isMobile = browser.lessThan.xl;
  if (isMobile) {
    history.push({
      pathname: `/s/${sessionId}/benefits`,
    });
  } else if (isEmpty(benId)) {
    yield call([history, history.push], {
      pathname: `/s/${sessionId}/benefits`,
    });
  } else {
    yield call([history, history.push], {
      pathname: `/s/${sessionId}/benefits/b/${benId}/calculator`,
    });
  }
}

export const effects = [takeLatest(benefitsTypes.GO_TO, goToCurrent)];

export default effects;
