/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon viewBox="0 0 58 58" {...props}>
    <circle fill="inherit" opacity="0.8" cx="29" cy="29" r="29" />

    <path
      fill="currentColor"
      d="M29,2c14.9,0,27,12.1,27,27S43.9,56,29,56S2,43.9,2,29S14.1,2,29,2 M29,0C13,0,0,13,0,29s13,29,29,29s29-13,29-29S45,0,29,0
	L29,0z"
    />

    <path
      fill="currentColor"
      d="M18.3,41.4c0,2,1.6,3.6,3.6,3.6h14.2c2,0,3.6-1.6,3.6-3.6V20.1H18.3V41.4z M41.4,14.8h-6.2L33.4,13h-8.9l-1.8,1.8h-6.2v3.6
		h24.9V14.8z"
    />
  </Icon>
);

export default memo(IconComponent);
