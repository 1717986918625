import { css } from 'styled-components';
import { darkenHsla } from '@sharkfinesse/sfl-lib';

const Alerts = ({ font, colors, scale }) => ({
  base: () => css`
    border: 1px solid ${colors.uiLight};
    border-radius: 4px;
    padding: 16px 12px;
  `,
  warning: () => css`
    border-left: 5px solid ${darkenHsla(5, colors.warning)};
  `,
  standard: () => css`
    border-left: 5px solid ${darkenHsla(5, colors.primary)};
  `,
});

export default Alerts;
