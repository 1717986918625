// @flow

import React, { useState, useMemo, useCallback } from 'react';
import Context from './context';
const FormControl = ({
  disabled = false,
  invalid = false,
  required = false,
  helpTextLabel,
  margin = 'dense',
  helpTextType = 'normal',
  helpTextStyle = { type: 'dropdown', config: {} },
  labelVisible = true,
  onFocus,
  onBlur,
  children,
  fullWidth,
  inverse,
  expanded,
  autosize,
  rounded,
  id,
  selected = false,
  multiline,
  ...other
}) => {
  const [dirty, setDirty] = useState();
  const [focused, setFocused] = useState();

  const onDirtyHandler = useCallback(() => {
    if (!dirty) setDirty(true);
  }, [dirty]);

  const onCleanHandler = useCallback(() => {
    if (dirty) setDirty(false);
  }, [dirty]);

  const contextProps = useMemo(
    () => ({
      id,
      dirty,
      disabled,
      invalid,
      helpTextLabel,
      helpTextType,
      helpTextStyle,
      focused,
      margin,
      required,
      selected,
      inverse,
      expanded,
      autosize,
      rounded,
      multiline,
      fullWidth,
      onDirty: onDirtyHandler,
      onClean: onCleanHandler,
      onFocus: () => {
        setFocused(true);
      },
      onBlur: () => {
        setFocused(false);
      },
    }),
    [
      id,
      dirty,
      disabled,
      invalid,
      helpTextLabel,
      helpTextType,
      helpTextStyle,
      focused,
      margin,
      required,
      inverse,
      expanded,
      autosize,
      rounded,
      multiline,
      fullWidth,
      selected,
      onDirtyHandler,
      onCleanHandler,
    ]
  );

  return <Context.Provider value={contextProps}>{children}</Context.Provider>;
};

export default FormControl;
