// @flow
import React from 'react';
import { Modal, useNiceModal } from '@sharkfinesse/sfl-ui';
import styled from 'styled-components';
import NiceModal from '@ebay/nice-modal-react';

const BodyWrapper = styled.div`
  padding: 30px;
`;

const ModalInfo = ({ title, body }) => {
  const { modal, modalProps } = useNiceModal();

  return (
    <Modal
      {...modalProps}
      onClose={() => {
        modal.resolve();
        modalProps.onClose();
      }}
      title={title}
      showHeaderKeyline
      showFooterKeyline
      closeOnOutsideClick={true}
    >
      <BodyWrapper>{body}</BodyWrapper>
    </Modal>
  );
};

export default NiceModal.create(ModalInfo);
