// @flow
/* eslint-disable no-magic-numbers */
import React from 'react';
import { Container, Initials } from './styled';

const DefaultAvatar = ({ color, number, name, size, style, border, hideBorder, ...props }) => (
  <Container
    $border={border}
    $hideBorder={hideBorder}
    style={{
      fontSize: `${size}px`,
      width: `${size}px`,
      height: `${size}px`,
      minWidth: `${size}px`,
      minHeight: `${size}px`,
      backgroundColor: color,
      ...style,
    }}
  >
    {(number || name) && <Initials>{number ? number : name.charAt(0).toUpperCase()}</Initials>}
  </Container>
);

export default DefaultAvatar;
