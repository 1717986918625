import { css } from 'styled-components';
import styledBreakpoint from 'styled-components-breakpoint';
import { lightenHsla } from '@sharkfinesse/sfl-lib';

export const Styles = ({ font, colors, scale }) => ({
  calculatorWrapper: () => css`
    width: 100%;
    margin-top: 20px;
  `,
  results: () => css`
    display: flex;
    margin-bottom: 20px;
    margin-top: ${scale.minusFour};
    text-align: center;
  `,
  descriptionWrapper: () => css`
    width: 100%;
    font-size: 14px;
    line-height: 22px;
    ${styledBreakpoint('xxs')`
        margin-bottom: 20px;
  `};
    ${styledBreakpoint('xs')`
    margin-bottom: 20px;
  `};
    ${styledBreakpoint('sm')`
    margin-bottom: 0px;

  `};
  `,
  calcWrapper: () => css`
    margin: 0px 20px 20px 20px;
    ${styledBreakpoint('xxs')`
        margin: 0px 10px 10px 10px;
  `};
    ${styledBreakpoint('xs')`
    margin: 0px 10px 10px 10px;
  `};
    ${styledBreakpoint('sm')`
    margin: 0px 10px 10px 10px;
  `};
  `,
  resultPages: () => css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    > div {
      grid-row-start: 1;
      grid-column-start: 1;
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      will-change: transform, opacity;
      padding-left: 10px;
      padding-right: 10px;
    }
  `,
  animatedGraphic: () => css`
    margin-top: -6%;
    width: 70%;
    height: 70%;
    align-self: center;
    ${styledBreakpoint('xxs')`
    width: 90%;
    height: 90%;
    margin-top: 0;
  `};
    ${styledBreakpoint('xs')`
    width: 90%;
    height: 90%;
    margin-top: 0;
  `};
    ${styledBreakpoint('sm')`
    width: 90%;
    height: 90%;
    margin-top: 0;
  `};
    ${styledBreakpoint('md')`
  
    width: 70%;
    height: 100%;
    margin-top: -20px;
  `};
    /* filter: hue-rotate(90deg); */
    .graphicMainFill {
      fill: ${colors.primary};
    }
  `,
  value: () => css`
    font-size: 34px;
    color: ${colors.primary};

    ${styledBreakpoint('xxs', 'md')`
      font-size: 22px;
    `};
  `,
  label: () => css`
    font-size: 14px;
    font-weight: ${font.weightSemiBold};
    ${styledBreakpoint('xxs', 'md')`
    font-size: 14px;
    `};
  `,
  graphicContainer: () => css`
    justify-content: center;
  `,
  priceImporvementWrapper: () => css`
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px 0px;
    background: ${lightenHsla(4, colors.uiLightest)};
    border-radius: 6px;
    padding: 6px;
    ${styledBreakpoint('xxs')`
        margin-bottom: 6px;
  `};
    ${styledBreakpoint('xs')`
    margin-bottom: 6px;
  `};
    ${styledBreakpoint('sm')`
    margin-right: 6px;

  `};
    ${styledBreakpoint('md')`
  margin-bottom: 6px;
  margin-right: 6px;
  `};
  `,
  lostBenefitsWrapper: () => css`
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px 0px;
    background: ${lightenHsla(4, colors.uiLightest)};
    border-radius: 6px;
    padding: 6px;
    ${styledBreakpoint('sm')`
  margin-left: 6px;
  `};
    ${styledBreakpoint('md')`
  margin-left: 6px;
  `};
  `,
  chartWrapper: () => css`
    display: flex;
    flex-direction: column;
    ${styledBreakpoint('xxs')`
        margin-bottom: 20px;
  `};
    ${styledBreakpoint('xs')`
    margin-bottom: 20px;
  `};
    ${styledBreakpoint('sm')`
    margin-bottom: 0px;

  `};
  `,
  legend: () => css`
    display: flex;
    flex-direction: row;
    align-self: center;
  `,
  legendItem: () => css`
    width: 20px;
    display: flex;
    align-items: center;
  `,
  legenedLine: () => css`
    border-bottom: 3px dotted ${colors.invalid};
    width: 20px;
  `,
  legenedLabel: () => css`
    font-size: 14px;
    margin-left: 8px;
    color: ${font.color};
  `,
  chart: {
    xAxis: {
      lineStyle: {
        type: 'solid',
        color: colors.uiLight,
        width: 1,
      },
      axisLabel: {
        show: true,
        color: font.color,
        lineHeight: 16,
      },
    },
    backgroundSeries: {
      itemStyle: {
        color: lightenHsla(3, colors.uiLightest),
      },
    },
    mainSeries: {
      color: colors.primary,
    },
    markLine: {
      lineStyle: {
        type: 'dotted',
        color: colors.invalid,
        width: 3,
      },
    },
  },
});

export default Styles;
