/* @flow */
/* eslint-disable max-len */
import React, { memo } from 'react';
import Icon from '../icon';
import { isEmpty } from '@sharkfinesse/sfl-lib';

let IconComponent = ({ imageUrl, ...props }): React.Element<any> => {
  return (
    <Icon {...props}>
      <path
        fill="currentColor"
        d="M77,19H29.8l-4.9-6.8c-0.6-0.8-1.5-1.2-2.4-1.2H3c-1.7,0-3,1.3-3,3v59c0,1.7,1.3,3,3,3h74c1.7,0,3-1.3,3-3V22
	C80,20.3,78.7,19,77,19z M77,73H3V33h74V73z M77,30H3V14h19.5l5.8,8H77V30z"
      />
      {imageUrl && (
        <g>
          <clipPath id="folderImage">
            <rect x="3" y="33" width="74" height="40" />
          </clipPath>
        </g>
      )}

      {imageUrl && (
        <image
          clipPath="url(#folderImage)"
          height="100%"
          width="100%"
          y="15%"
          xlinkHref={imageUrl}
        />
      )}
    </Icon>
  );
};

export default memo(IconComponent);
