/* @flow */
import { combineReducers } from 'redux';
import { simpleAction, mergeDeepRight, settingsInitialState } from '@sharkfinesse/sfl-lib';
import benefits from './settings/benefits';
import sessions from './settings/sessions';
import ui from './settings/ui';

const namespace = (name: string): string => `@@settings/${name}`;

// types
export const types = {
  LOAD: namespace('LOAD'),
  LOADED: namespace('LOADED'),
  SYNC: namespace('SYNC'),
  RESET: namespace('RESET'),
};
export const id = (state = '', action) => {
  return state;
};

const settings = combineReducers({
  id,
  benefits,
  sessions,
  ui,
});

export const initialState = settingsInitialState;

const SettingsReducer =
  () =>
  (state = initialState, action) => {
    switch (action.type) {
      case types.SYNC:
        //loaded state from firestore
        if (!action.payload) {
          return state;
        } else {
          return mergeDeepRight(state, action.payload);
        }
      case types.RESET:
        return settings({}, action);
      default:
        return settings(state, action);
    }
  };

//Action Creators
const load = simpleAction(types.LOAD);
const sync = simpleAction(types.SYNC);
const reset = () => ({ type: types.RESET });
const loaded = simpleAction(types.LOADED);

export const actionCreators = {
  load,
  sync,
  loaded,
  reset,
};

export default SettingsReducer();
