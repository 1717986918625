/* @flow */
/* eslint-disable id-length, id-match, filenames/match-regex */
/**
 * External dependencies
 */
import React, { Element } from 'react';
import styled from 'styled-components';

const StyledP = styled.p`
  font-size: ${({ theme, color }) => theme[`headingSub${color}`].fontSize};
  font-weight: ${({ theme, color }) => theme[`headingSub${color}`].fontColor};
  color: ${({ theme, color }) => theme[`headingSub${color}`].fontWeight};
  margin: 0 0 0 0;
`;

export type Props = {
  color?: string,
  children?: Element<any>,
};

const Sub = ({ children, color, ...props }: Props): React.Element<any> =>
  <StyledP color={color} {...props}>
    {children}
  </StyledP>;

export default Sub;
