import { css } from 'styled-components';

const Scroll = ({ font, colors, scale }) => ({
  base: () => css`
    width: 100%;
    height: 100%;
  `,
  thumb: () => css`
    .ScrollbarsCustom-Thumb {
      border-radius: 4px !important;
      background: ${colors.ui} !important;
    }
  `,
  track: transparenttrackbar => {
    return css`
      .ScrollbarsCustom-Track {
        border-radius: 0px !important;
        background: ${transparenttrackbar ? 'transparent' : colors.uiLightest} !important;
      }
    `;
  },
  trackX: () => css`
    .ScrollbarsCustom-TrackX {
      left: 0px !important;
      width: 100% !important;
    }
  `,
  trackY: () => css`
    .ScrollbarsCustom-TrackY {
      border-radius: 0px !important;
      height: 100% !important;
      top: 0 !important;
    }
  `,
});

export default Scroll;
