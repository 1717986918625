/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

let IconTick = ({ ...props }): React.Element<any> => (
  <Icon {...props}>
    <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" fill="currentColor" />
  </Icon>
);

export default memo(IconTick);
