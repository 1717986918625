"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.unformat = exports.normalizeVolume = exports.normalizeUnit = exports.normalizeTime = exports.normalizeStorage = exports.normalizePower = exports.normalizeMass = exports.normalizeFlowRate = exports.normalizeEnergy = exports.normalizeDistance = exports.normalizeChange = exports.normalizeArea = exports.formatForExcel = exports.formatForDisplay = exports.formatFieldChange = exports.format = exports.decreaseFormFieldValueByFfpf = exports.adjustVolumeUnit = exports.adjustToDisplayUnit = exports.adjustTimeUnit = exports.adjustStorageUnit = exports.adjustPowerUnit = exports.adjustMassUnit = exports.adjustFormatDecimalPlaces = exports.adjustFlowRateUnit = exports.adjustEnergyUnit = exports.adjustDistanceUnit = exports.adjustDecimalPlaces = exports.adjustAreaUnit = void 0;

var _accounting = _interopRequireDefault(require("accounting"));

var _units = require("./units");

var _fp = require("./fp");

var _constants = require("./constants");

var _math = require("./math");

var _arguments = require("./arguments");

var _type = require("./type");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const format = (0, _fp.curry)((formatObj, value) => _accounting.default.formatNumber(value, formatObj.decimalPlaces, formatObj.thousandsSeparatorSymbol, formatObj.decimalSeparatorSymbol));
exports.format = format;

const unformat = (formatObj, value) => _accounting.default.unformat(value, formatObj.decimalSeparatorSymbol, formatObj.thousandsSeparatorSymbol); // prettier-ignore


exports.unformat = unformat;
const normalizeChange = (0, _fp.curry)((0, _fp.pipe)(unformat, (0, _fp.ifElse)((0, _fp.pipe)(parseFloat, isNaN), (0, _fp.always)(''), (0, _fp.pipe)((0, _math.increaseByFloatingFixedPointFactor)(1))))); // prettier-ignore

exports.normalizeChange = normalizeChange;
const decreaseFormFieldValueByFfpf = (0, _fp.cond)([[_fp.isNil, _fp.identity], [(0, _fp.equals)(''), (0, _fp.always)('')], [_fp.T, (0, _math.decreaseByFloatingFixedPointFactor)(1)]]); // prettier-ignore

exports.decreaseFormFieldValueByFfpf = decreaseFormFieldValueByFfpf;

const formatFieldChange = (formatObj, measurement, unit, value) => (0, _fp.ifElse)((0, _fp.equals)(''), (0, _fp.always)(''), (0, _fp.pipe)(normalizeChange(formatObj), normalizeUnit(measurement, unit)))(value); // prettier-ignore


exports.formatFieldChange = formatFieldChange;
const adjustDecimalPlaces = (0, _fp.curryN)(2, (dp, value) => (0, _fp.pipe)((0, _math.round)(dp), parseFloat, (0, _math.getDecimalPartAsString)('.'), _fp.length, (0, _fp.min)(dp))(value)); // prettier-ignore

exports.adjustDecimalPlaces = adjustDecimalPlaces;

const adjustFormatDecimalPlaces = (formatObj, value) => ({ ...formatObj,
  decimalPlaces: adjustDecimalPlaces(formatObj.decimalPlaces, value)
});
/* eslint-disable */


exports.adjustFormatDecimalPlaces = adjustFormatDecimalPlaces;
const formatForDisplay = (0, _fp.curry)((valueType, formatObj, value) => {
  if ((0, _type.isNumber)(value)) {
    if (valueType !== 'month') {
      // REMOVE MUTATION
      value = (0, _math.decreaseByFloatingFixedPointFactor)(1, value);
    }

    switch (valueType) {
      case 'number':
        return format(adjustFormatDecimalPlaces(formatObj, value), value);

      case 'money':
        return (0, _fp.join)('', [formatObj.currencySymbolPosition === 'prefix' ? formatObj.currencySymbol : '', format(formatObj, value), formatObj.currencySymbolPosition === 'suffix' ? formatObj.currencySymbol : '']);

      case 'time':
        if (formatObj.unit) {
          value = adjustToDisplayUnit('time', formatObj.unit, value);
        }

        return format(adjustFormatDecimalPlaces(formatObj, value), value);

      case 'storage':
        if (formatObj.unit) {
          value = adjustToDisplayUnit('storage', formatObj.unit, value);
        }

        return format(adjustFormatDecimalPlaces(formatObj, value), value);

      case 'volume':
        if (formatObj.unit) {
          value = adjustToDisplayUnit('volume', formatObj.unit, value);
        }

        return format(adjustFormatDecimalPlaces(formatObj, value), value);

      case 'energy':
        if (formatObj.unit) {
          value = adjustToDisplayUnit('energy', formatObj.unit, value);
        }

        return format(adjustFormatDecimalPlaces(formatObj, value), value);

      case 'mass':
        if (formatObj.unit) {
          value = adjustToDisplayUnit('mass', formatObj.unit, value);
        }

        return format(adjustFormatDecimalPlaces(formatObj, value), value);

      case 'distance':
        if (formatObj.unit) {
          value = adjustToDisplayUnit('distance', formatObj.unit, value);
        }

        return format(adjustFormatDecimalPlaces(formatObj, value), value);

      case 'percentage':
        return (0, _fp.join)('', [format(adjustFormatDecimalPlaces(formatObj, value), value), '%']);

      case 'percentageNoSymbol':
        return format(adjustFormatDecimalPlaces(formatObj, value), value);

      case 'month':
        return format(adjustFormatDecimalPlaces(formatObj, value), value);

      case 'moneyNoSymbol':
        return format(formatObj, value);

      default:
        return format(formatObj, value);
    }
  }

  return (0, _fp.either)(_fp.identity, (0, _fp.always)(''))(value);
});
/* eslint-disable */

exports.formatForDisplay = formatForDisplay;
const formatForExcel = (0, _fp.curry)((valueType, formatObj, value) => {
  if ((0, _type.isNumber)(value)) {
    if (valueType !== 'month') {
      // REMOVE MUTATION
      value = (0, _math.decreaseByFloatingFixedPointFactor)(1, value);
    }

    switch (valueType) {
      case 'time':
        return adjustToDisplayUnit('time', formatObj.unit, value);

      case 'storage':
        return adjustToDisplayUnit('storage', formatObj.unit, value);

      case 'volume':
        return adjustToDisplayUnit('volume', formatObj.unit, value);

      case 'energy':
        return adjustToDisplayUnit('energy', formatObj.unit, value);

      case 'mass':
        return adjustToDisplayUnit('mass', formatObj.unit, value);

      case 'distance':
        return adjustToDisplayUnit('distance', formatObj.unit, value);

      default:
        return value;
    }
  }

  return '';
});
/* eslint-enable */
// prettier-ignore

exports.formatForExcel = formatForExcel;
const adjustTimeUnit = (0, _fp.cond)([[(0, _fp.equals)('minutes'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.secondsToMinutes))], [(0, _fp.equals)('hours'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.secondsToHours))], [(0, _fp.equals)('days'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.secondsToDays))], [(0, _fp.equals)('weeks'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.secondsToWeeks))], [(0, _fp.equals)('months'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.secondsToMonths))], [(0, _fp.equals)('fteDays'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.secondsToFteDays))], [_fp.T, (0, _fp.nthArg)(1)]]); // prettier-ignore

exports.adjustTimeUnit = adjustTimeUnit;
const adjustStorageUnit = (0, _fp.cond)([[(0, _fp.equals)('KB'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('one', 'kilo')))], [(0, _fp.equals)('MB'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('one', 'mega')))], [(0, _fp.equals)('GB'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('one', 'giga')))], [(0, _fp.equals)('TB'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('one', 'tera')))], [(0, _fp.equals)('PB'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('one', 'peta')))], [(0, _fp.equals)('EB'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('one', 'exa')))], [_fp.T, (0, _fp.nthArg)(1)]]); // prettier-ignore

exports.adjustStorageUnit = adjustStorageUnit;
const adjustMassUnit = (0, _fp.cond)([[(0, _fp.equals)('tonne'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('one', 'kilo')))], [(0, _fp.equals)('g'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('kilo', 'one')))], [(0, _fp.equals)('ton'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.kgToTons))], [(0, _fp.equals)('pound'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.kgToPounds))], [(0, _fp.equals)('gram'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('kilo', 'one')))], [_fp.T, (0, _fp.nthArg)(1)]]); // prettier-ignore

exports.adjustMassUnit = adjustMassUnit;
const adjustPowerUnit = (0, _fp.cond)([[(0, _fp.equals)('kW'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('one', 'kilo')))], [(0, _fp.equals)('MW'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('one', 'mega')))], [(0, _fp.equals)('GW'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('one', 'giga')))], [(0, _fp.equals)('TW'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('one', 'tera')))], [_fp.T, (0, _fp.nthArg)(1)]]); // prettier-ignore

exports.adjustPowerUnit = adjustPowerUnit;
const adjustDistanceUnit = (0, _fp.cond)([[(0, _fp.equals)('km'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('one', 'kilo')))], [(0, _fp.equals)('cm'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _fp.divide)(_fp.__, _constants.base10), (0, _math.adjustSiPrefix)('one', 'milli')))], [(0, _fp.equals)('mm'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('one', 'milli')))], [(0, _fp.equals)('µm'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('one', 'micro')))], [(0, _fp.equals)('nm'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('one', 'nano')))], [(0, _fp.equals)('miles'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.metresToMiles))], [(0, _fp.equals)('feet'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.metresToFeet))], [(0, _fp.equals)('inches'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.metresToInches))], [_fp.T, (0, _fp.nthArg)(1)]]); // prettier-ignore

exports.adjustDistanceUnit = adjustDistanceUnit;
const adjustEnergyUnit = (0, _fp.cond)([[(0, _fp.equals)('Wh'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('kilo', 'one')))], [(0, _fp.equals)('MWh'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('kilo', 'mega')))], [(0, _fp.equals)('GWh'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('kilo', 'giga')))], [(0, _fp.equals)('TWh'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('kilo', 'tera')))], [(0, _fp.equals)('MMBtu'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.joulesToMmbtu))], [_fp.T, (0, _fp.nthArg)(1)]]); // prettier-ignore

exports.adjustEnergyUnit = adjustEnergyUnit;
const adjustFlowRateUnit = (0, _fp.cond)([[(0, _fp.equals)('MMSCFD'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.m3PerSecToMmscfd))], [_fp.T, (0, _fp.nthArg)(1)]]); // prettier-ignore

exports.adjustFlowRateUnit = adjustFlowRateUnit;
const adjustAreaUnit = (0, _fp.cond)([[(0, _fp.equals)('km2'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _fp.divide)(_fp.__, 1000000)))], // eslint-disable-line no-magic-numbers
[(0, _fp.equals)('cm2'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _fp.multiply)(10000)))], // eslint-disable-line no-magic-numbers
[(0, _fp.equals)('sqft'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _fp.pipe)((0, _fp.multiply)((0, _math.normalizingFloatingFixedPointFactor)(2)), (0, _fp.divide)(_fp.__, Math.pow(_constants.mToFtRatio, 2)))))], // eslint-disable-line no-magic-numbers
[(0, _fp.equals)('mile2'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.m2ToMile2))], [(0, _fp.equals)('inch2'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.m2ToInch2))], [_fp.T, (0, _fp.nthArg)(1)]]); // prettier-ignore

exports.adjustAreaUnit = adjustAreaUnit;
const adjustVolumeUnit = (0, _fp.cond)([[(0, _fp.equals)('mm3'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _fp.divide)(_fp.__, 1000000000)))], // eslint-disable-line no-magic-numbers
[(0, _fp.equals)('cm3'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _fp.divide)(_fp.__, 1000000)))], // eslint-disable-line no-magic-numbers
[(0, _fp.equals)('gallon'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.m3ToGallons))], [(0, _fp.equals)('L'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.m3ToLitres))], [(0, _fp.equals)('ft3'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.m3ToFt3))], [(0, _fp.equals)('MMSCFD'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.mmscfdToM3PerSec))], [_fp.T, (0, _fp.nthArg)(1)]]); // prettier-ignore

exports.adjustVolumeUnit = adjustVolumeUnit;
const adjustToDisplayUnit = (0, _fp.cond)([[(0, _fp.equals)('time'), (0, _fp.curryN)(3, (0, _fp.pipe)((0, _arguments.nthArgs)(1, 3), (0, _fp.apply)(adjustTimeUnit)))], [(0, _fp.equals)('storage'), (0, _fp.curryN)(3, (0, _fp.pipe)((0, _arguments.nthArgs)(1, 3), (0, _fp.apply)(adjustStorageUnit)))], [(0, _fp.equals)('mass'), (0, _fp.curryN)(3, (0, _fp.pipe)((0, _arguments.nthArgs)(1, 3), (0, _fp.apply)(adjustMassUnit)))], [(0, _fp.equals)('area'), (0, _fp.curryN)(3, (0, _fp.pipe)((0, _arguments.nthArgs)(1, 3), (0, _fp.apply)(adjustAreaUnit)))], [(0, _fp.equals)('energy'), (0, _fp.curryN)(3, (0, _fp.pipe)((0, _arguments.nthArgs)(1, 3), (0, _fp.apply)(adjustEnergyUnit)))], [(0, _fp.equals)('distance'), (0, _fp.curryN)(3, (0, _fp.pipe)((0, _arguments.nthArgs)(1, 3), (0, _fp.apply)(adjustDistanceUnit)))], [(0, _fp.equals)('power'), (0, _fp.curryN)(3, (0, _fp.pipe)((0, _arguments.nthArgs)(1, 3), (0, _fp.apply)(adjustPowerUnit)))], [(0, _fp.equals)('volume'), (0, _fp.curryN)(3, (0, _fp.pipe)((0, _arguments.nthArgs)(1, 3), (0, _fp.apply)(adjustVolumeUnit)))], [(0, _fp.equals)('flowRate'), (0, _fp.curryN)(3, (0, _fp.pipe)((0, _arguments.nthArgs)(1, 3), (0, _fp.apply)(adjustFlowRateUnit)))], [_fp.T, (0, _fp.nthArg)(2)]]); // prettier-ignore

exports.adjustToDisplayUnit = adjustToDisplayUnit;
const normalizeTime = (0, _fp.cond)([[(0, _fp.equals)('minutes'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.minutesToSeconds))], [(0, _fp.equals)('hours'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.hoursToSeconds))], [(0, _fp.equals)('days'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.daysToSeconds))], [(0, _fp.equals)('weeks'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.weeksToSeconds))], [(0, _fp.equals)('months'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.monthsToSeconds))], [(0, _fp.equals)('fteDays'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.fteDaysToSeconds))], [_fp.T, (0, _fp.nthArg)(1)]]); // prettier-ignore

exports.normalizeTime = normalizeTime;
const normalizeStorage = (0, _fp.cond)([[(0, _fp.equals)('KB'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('kilo', 'one')))], [(0, _fp.equals)('MB'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('mega', 'one')))], [(0, _fp.equals)('GB'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('giga', 'one')))], [(0, _fp.equals)('TB'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('tera', 'one')))], [(0, _fp.equals)('PB'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('peta', 'one')))], [(0, _fp.equals)('EB'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('exa', 'one')))], [_fp.T, (0, _fp.nthArg)(1)]]); // prettier-ignore

exports.normalizeStorage = normalizeStorage;
const normalizeMass = (0, _fp.cond)([[(0, _fp.equals)('tonne'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('kilo', 'one')))], [(0, _fp.equals)('g'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('one', 'kilo')))], [(0, _fp.equals)('ton'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.tonsToKg))], [(0, _fp.equals)('pound'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.poundsToKg))], [(0, _fp.equals)('gram'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('one', 'kilo')))], [_fp.T, (0, _fp.nthArg)(1)]]); // prettier-ignore

exports.normalizeMass = normalizeMass;
const normalizePower = (0, _fp.cond)([[(0, _fp.equals)('kW'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('kilo', 'one')))], [(0, _fp.equals)('MW'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('mega', 'one')))], [(0, _fp.equals)('GW'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('giga', 'one')))], [(0, _fp.equals)('TW'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('tera', 'one')))], [_fp.T, (0, _fp.nthArg)(1)]]); // prettier-ignore

exports.normalizePower = normalizePower;
const normalizeDistance = (0, _fp.cond)([[(0, _fp.equals)('km'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('kilo', 'one')))], [(0, _fp.equals)('cm'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _fp.multiply)(_constants.base10), (0, _math.adjustSiPrefix)('milli', 'one')))], [(0, _fp.equals)('mm'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('milli', 'one')))], [(0, _fp.equals)('µm'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('micro', 'one')))], [(0, _fp.equals)('nm'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('nano', 'one')))], [(0, _fp.equals)('miles'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.milesToMetres))], [(0, _fp.equals)('feet'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.feetToMetres))], [(0, _fp.equals)('inches'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.inchesToMetres))], [_fp.T, (0, _fp.nthArg)(1)]]); // prettier-ignore

exports.normalizeDistance = normalizeDistance;
const normalizeEnergy = (0, _fp.cond)([[(0, _fp.equals)('Wh'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('one', 'kilo')))], [(0, _fp.equals)('MWh'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('mega', 'kilo')))], [(0, _fp.equals)('GWh'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('giga', 'kilo')))], [(0, _fp.equals)('TWh'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _math.adjustSiPrefix)('tera', 'kilo')))], [(0, _fp.equals)('MMBtu'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.mmbtuToJoules))], [_fp.T, (0, _fp.nthArg)(1)]]); // prettier-ignore

exports.normalizeEnergy = normalizeEnergy;
const normalizeFlowRate = (0, _fp.cond)([[(0, _fp.equals)('MMSCFD'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.mmscfdToM3PerSec))], [_fp.T, (0, _fp.nthArg)(1)]]); // prettier-ignore

exports.normalizeFlowRate = normalizeFlowRate;
const normalizeArea = (0, _fp.cond)([[(0, _fp.equals)('km2'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _fp.multiply)(1000000)))], // eslint-disable-line no-magic-numbers
[(0, _fp.equals)('cm2'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _fp.divide)(_fp.__, 10000)))], // eslint-disable-line no-magic-numbers
[(0, _fp.equals)('sqft'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _fp.pipe)((0, _fp.multiply)(Math.pow(_constants.mToFtRatio, 2)), (0, _fp.divide)(_fp.__, (0, _math.normalizingFloatingFixedPointFactor)(2)))))], // eslint-disable-line no-magic-numbers
[(0, _fp.equals)('mile2'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.sqMileToM2))], // eslint-disable-line no-magic-numbers
[(0, _fp.equals)('inch2'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.sqInchToM2))], [_fp.T, (0, _fp.nthArg)(1)]]); // prettier-ignore

exports.normalizeArea = normalizeArea;
const normalizeVolume = (0, _fp.cond)([[(0, _fp.equals)('cm3'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _fp.multiply)(1000000)))], // eslint-disable-line no-magic-numbers
[(0, _fp.equals)('mm3'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), (0, _fp.multiply)(1000000000)))], // eslint-disable-line no-magic-numbers
[(0, _fp.equals)('L'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.litreToM3))], [(0, _fp.equals)('ft3'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.ft3ToM3))], [(0, _fp.equals)('gallon'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.gallonToM3))], [(0, _fp.equals)('MMSCFD'), (0, _fp.curry)((0, _fp.pipe)((0, _fp.nthArg)(1), _units.m3PerSecToMmscfd))], [_fp.T, (0, _fp.nthArg)(1)]]); // prettier-ignore

exports.normalizeVolume = normalizeVolume;
const normalizeUnit = (0, _fp.cond)([[(0, _fp.equals)('time'), (0, _fp.curryN)(3, (0, _fp.pipe)((0, _arguments.nthArgs)(1, 3), (0, _fp.apply)(normalizeTime)))], [(0, _fp.equals)('storage'), (0, _fp.curryN)(3, (0, _fp.pipe)((0, _arguments.nthArgs)(1, 3), (0, _fp.apply)(normalizeStorage)))], [(0, _fp.equals)('mass'), (0, _fp.curryN)(3, (0, _fp.pipe)((0, _arguments.nthArgs)(1, 3), (0, _fp.apply)(normalizeMass)))], [(0, _fp.equals)('area'), (0, _fp.curryN)(3, (0, _fp.pipe)((0, _arguments.nthArgs)(1, 3), (0, _fp.apply)(normalizeArea)))], [(0, _fp.equals)('energy'), (0, _fp.curryN)(3, (0, _fp.pipe)((0, _arguments.nthArgs)(1, 3), (0, _fp.apply)(normalizeEnergy)))], [(0, _fp.equals)('distance'), (0, _fp.curryN)(3, (0, _fp.pipe)((0, _arguments.nthArgs)(1, 3), (0, _fp.apply)(normalizeDistance)))], [(0, _fp.equals)('power'), (0, _fp.curryN)(3, (0, _fp.pipe)((0, _arguments.nthArgs)(1, 3), (0, _fp.apply)(normalizePower)))], [(0, _fp.equals)('volume'), (0, _fp.curryN)(3, (0, _fp.pipe)((0, _arguments.nthArgs)(1, 3), (0, _fp.apply)(normalizeVolume)))], [(0, _fp.equals)('flowRate'), (0, _fp.curryN)(3, (0, _fp.pipe)((0, _arguments.nthArgs)(1, 3), (0, _fp.apply)(normalizeFlowRate)))], [_fp.T, (0, _fp.nthArg)(2)]]);
exports.normalizeUnit = normalizeUnit;