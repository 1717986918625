import { select, put, call, all } from 'redux-saga/effects';
import {
  includes,
  accountSelectors,
  layoutSelectors,
  userSelectors,
  intlSelectors,
} from '@sharkfinesse/sfl-lib';
import { actionCreators as appActionCreators } from 'redux/modules/app';
import { actionCreators as layoutActionCreators } from 'redux/modules/layout';
import create from './create';
import { history } from '../../../index';

function* initialise(action) {
  const [intl, account] = yield all([
    select(intlSelectors.getMessages),
    select(userSelectors.getAccount),
  ]);

  yield all([
    put(appActionCreators.updateLoadingActive(true)),
    put(appActionCreators.updateLoadingText(intl['app.loader.creating'])),
  ]);
  const [categoryType, categories] = yield all([
    select(accountSelectors.getCategoryType, action.payload.categoryType),
    select(accountSelectors.getCategories),
  ]);

  yield put(
    layoutActionCreators.create({
      categoryType: action.payload.defaultCategory,
      categories,
    })
  );
  yield put(layoutActionCreators.updateOriginal());
  let presetSuiteList = [];

  if (action.payload.defaultCategory.id !== action.payload.categoryType) {
    const [suites, suitesOrder] = yield all([
      select(layoutSelectors.getSuites),
      select(layoutSelectors.getSuitesOrder),
    ]);

    suitesOrder.forEach(suiteId => {
      const { id, name, categoryId } = suites[suiteId];

      if (includes(categoryId, categoryType.categories)) {
        presetSuiteList.push({
          value: id,
          label: name,
          categoryId,
        });
      }
    });
  }

  const sessionId = yield call(create, {
    action,
    account,
    presetSuiteList,
    folder: action.payload?.folderId,
  });

  yield call([history, history.push], {
    pathname: `/s/${sessionId}`,
    state: { from: action.payload.location.pathname },
  });

  return sessionId;
}

export default initialise;
