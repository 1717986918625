import { lightenHsla } from '@sharkfinesse/sfl-lib';

const R = 94;
const CompareSessions = ({ font, colors, scale }) => ({
  selected: {
    background: lightenHsla(R, colors.uiBase),
  },
  unselected: {
    background: 'transparent',
  },
});

export default CompareSessions;
