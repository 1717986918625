import React from 'react';
import LoaderTransition from './loader-transition';
import LoaderOverlay from './loader-overlay';
import LoaderLineWobble from './line-wobble';
import LoaderRing from './ring';
import { Label } from './styled';

const Component = ({
  isLoading,
  color,
  variant = 'line-wobble',
  background,
  zIndex,
  label,
  immediate,
}) => (
  <LoaderTransition
    isLoading={isLoading}
    background={background}
    zIndex={zIndex}
    immediate={immediate}
  >
    <LoaderOverlay>
      {variant === 'line-wobble' && <LoaderLineWobble color={color} />}
      {variant === 'ring' && <LoaderRing color={color} />}
      {label && <Label>{label}</Label>}
    </LoaderOverlay>
  </LoaderTransition>
);

export default Component;
