import { css } from 'styled-components';
import { setAlphaHsla } from '@sharkfinesse/sfl-lib';

const SelectToolbar = ({ font, colors, scale }) => ({
  container: () => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    padding: 10px;
    z-index: 4;
    background-color: hsla(206, 34%, 11%, 0.9);
    color: ${colors.white};
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
    justify-content: center;
  `,
  left: () => css`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    margin-left: 8px;
  `,
  numberWrapper: () => css`
    display: flex;
    font-size: 20px;
    padding: 0 16px;
    background: ${setAlphaHsla(0.2, colors.white)};
    color: ${colors.white};
    border-radius: 14px;
    line-height: 24px;
    height: 32px;
    align-items: center;
    margin-right: 8px;
  `,
  number: () => css`
    margin-top: -2px;
  `,
  numberLabel: () => css`
    display: flex;
    font-size: 16px;
    font-weight: ${font.weightNormal};
  `,
  selectedLabel: () => css`
    display: flex;
    flex-direction: column;
  `,
  selectLink: () => css`
    color: ${setAlphaHsla(0.8, colors.white)};
    font-size: 13px;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
      color: ${colors.white};
    }
  `,
  center: () => css`
    display: flex;
    justify-content: center;
  `,
  right: () => css`
    display: flex;
  `,
  wrapper: () => css`
    display: flex;
    width: 100%;
    max-width: 1600px;
    justify-content: space-between;
  `,

  button: () => css`
    display: flex;
    flex-direction: column;
    color: ${colors.white};
    border-radius: 0;
    transition: unset;
    margin-left: 16px;
    margin-right: 16px;

    > div:nth-of-type(1) {
      box-shadow: 0 0 1px 2px ${colors.white};
      transition: all 0.3s;
      border-radius: 25px;
      padding: 4px;
    }

    > div:nth-of-type(2) {
      transition: color 0.3s;
      margin-top: 6px;
      font-size: 13px;
      font-weight: ${font.weightNormal};
    }

    &:hover {
      > div:nth-of-type(1) {
        box-shadow: 0 0 1px 2px ${colors.primary};
      }
      color: ${colors.primary};
    }
  `,
});

export default SelectToolbar;
