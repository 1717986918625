/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon viewBox="0 0 28 28" {...props}>
    <path
      fill="#000000"
      d="M26.3,0H1.8C0.8,0,0,0.8,0,1.8v24.5c0,1,0.8,1.8,1.8,1.8h24.5c1,0,1.8-0.8,1.8-1.8V1.8
	C28.1,0.8,27.3,0,26.3,0z"
    />
    <path fill="#00FF99" d="M7.5,7.4h1.8v13.3H7.5V7.4z" />
    <path fill="#9933FF" d="M18.4,11.1h1.8v9.6h-1.8V11.1z" />
    <path fill="#00CCFF" d="M7.5,20.2h12.7v1.8H7.5V20.2z" />
    <path fill="#FFF35C" d="M7.5,6h7.3v1.8H7.5V6z" />
    <path fill="#FF6666" d="M18.4,9.7V7.9h-1.8V6h-1.8v5.5h5.4V9.7" />
    <path fill="#0E1318" fillOpacity="0.3" d="M18.4,13.4v-1.8h1.8L18.4,13.4z M14.8,6v1.8H13" />
  </Icon>
);

export default memo(IconComponent);
