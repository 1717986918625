"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reduceIndexed = exports.mapIndexed = exports.getItemByName = exports.generateIndexedArray = exports.generateArrayOfConsecutiveValues = exports.arrayLengthGt = exports.arrayLengthEq = void 0;

var _fp = require("./fp");

var _objects = require("./objects");

// prettier-ignore
const arrayLengthGt = (0, _fp.curryN)(2, (0, _fp.useWith)(_fp.gt, [_fp.idiot, _fp.length])); // prettier-ignore

exports.arrayLengthGt = arrayLengthGt;
const arrayLengthEq = (0, _fp.curryN)(2, (0, _fp.useWith)(_fp.equals, [_fp.idiot, _fp.length])); // prettier-ignore

exports.arrayLengthEq = arrayLengthEq;
const mapIndexed = (0, _fp.addIndex)(_fp.map); // prettier-ignore

exports.mapIndexed = mapIndexed;
const reduceIndexed = (0, _fp.addIndex)(_fp.reduce); // prettier-ignore

exports.reduceIndexed = reduceIndexed;
const generateIndexedArray = (0, _fp.converge)(mapIndexed, [(0, _fp.pipe)(_fp.idiot, _objects.indexData), (0, _fp.finchstar)((0, _fp.jalt_)(_fp.repeat))]); // prettier-ignore

exports.generateIndexedArray = generateIndexedArray;
const getItemByName = (0, _fp.goldfinch)((0, _fp.flip)(_fp.find))((0, _fp.propEq)('name')); // prettier-ignore

exports.getItemByName = getItemByName;

const generateArrayOfConsecutiveValues = period => (0, _fp.addIndex)(_fp.reduce)((acc, val, idx) => (0, _fp.append)(idx, acc), [], (0, _fp.repeat)(0, period));

exports.generateArrayOfConsecutiveValues = generateArrayOfConsecutiveValues;