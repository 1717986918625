/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

const IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M16.8,18.6V21H0v-2.4c0,0,0-4.8,8.4-4.8S16.8,18.6,16.8,18.6 M12.6,7.2c0-2.3-1.9-4.2-4.2-4.2S4.2,4.9,4.2,7.2
	s1.9,4.2,4.2,4.2S12.6,9.5,12.6,7.2 M16.7,13.8c1.5,1.2,2.4,2.9,2.5,4.8V21H24v-2.4C24,18.6,24,14.2,16.7,13.8 M15.6,3
	c-0.8,0-1.6,0.2-2.3,0.7c1.5,2.1,1.5,4.9,0,7c0.7,0.5,1.5,0.7,2.3,0.7c2.3,0,4.2-1.9,4.2-4.2S17.9,3,15.6,3z"
    />
  </Icon>
);

export default memo(IconComponent);
