import { css } from 'styled-components';

const Toolbar = ({ font, colors, scale }) => ({
  base: () => css`
    justify-content: flex-end;
    display: flex;
    flex: 1;
    align-items: center;
  `,
  divider: ({ color, margin }) => css`
    border-right: 1px solid ${color === 'dark' ? '#dedede' : '#e8edf2'};
    display: block;
    height: 100%;
    position: relative;
    top: 0;
    margin: ${margin ? margin : '0 10px'};
  `,
});

export default Toolbar;
