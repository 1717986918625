/* @flow */
/* eslint-disable react/prop-types */
import React from 'react';
import { IconContainer } from './styled';
import Tooltip from '../tooltip';

const NotificationIcon = ({ children, tooltip, ...props }) => (
  <IconContainer {...props}>
    <Tooltip
      tooltip={tooltip}
      tooltipConfig={{
        usePortal: true,
      }}
    >
      {children}
    </Tooltip>
  </IconContainer>
);

export default NotificationIcon;
