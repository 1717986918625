/* @flow */
import {
  simpleAction,
  createReducer,
  simpleReducer,
  sessionSuiteListInitialState,
} from '@sharkfinesse/sfl-lib';

const namespace = (name: string): string => `@@session.suiteList/${name}`;

// types
export const types = {
  VISIBLE: namespace('VISIBLE'),
};

export const initialState = sessionSuiteListInitialState;

const reduceVisible = simpleReducer('visible');

export default createReducer(
  {
    [types.VISIBLE]: reduceVisible,
  },
  initialState
);

//Action Creators

const updateVisible = simpleAction(types.VISIBLE);

export const actionCreators = {
  updateVisible,
};
