/* @flow */
/* eslint-disable max-len */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path fill="currentColor" d="M16,1H4C2.9,1,2,1.9,2,3v14h2V3h12V1z" />
    <path
      fill="currentColor"
      d="M20.3,18c-0.6,0-1-0.3-1.3-0.7V21H8V7h11v3.7c0.3-0.4,0.8-0.7,1.3-0.7c0.2,0,0.5,0,0.7,0.1V7c0-1.1-0.9-2-2-2H8
	C6.9,5,6,5.9,6,7v14c0,1.1,0.9,2,2,2h11c1.1,0,2-0.9,2-2v-3.1C20.8,18,20.6,18,20.3,18z"
    />
    <path
      fill="currentColor"
      d="M14.8,16.8l2.2-2.2h1.8c0.2,0.7,0.8,1.1,1.6,1.1c0.9,0,1.7-0.7,1.7-1.7c0-0.9-0.7-1.7-1.7-1.7c-0.7,0-1.3,0.4-1.6,1.1H17
	l-2.2-2.2V9H12v2.8h1.7L16,14l-2.3,2.2H12V19h2.8V16.8z"
    />
  </Icon>
);

export default memo(IconComponent);
