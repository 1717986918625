/* @flow */
/* eslint-disable react/prop-types */
import React, { useState, forwardRef } from 'react';
import { Container, Label, Body, Wrapper, Icon } from './styled';
import useMeasure from '../utils/useMeasure';
import { useSpring } from 'react-spring';
import { IconCheckCircle, IconCheckboxBlankCircleOutline, IconChevronRightLarge } from '../icon';
import { Link } from 'react-router-dom';

const ProgressMenu = (
  {
    label,
    children,
    state = 'default',
    onClick,
    isOpen,
    fontColor = '#333333',
    highlightColor,
    child,
    to,
    postition,
    ...other
  },
  ref
) => {
  const [showChildren, setShowChildren] = useState(false);
  const [bind, { height: viewHeight }] = useMeasure();

  const heightProps = useSpring({
    height: isOpen ? viewHeight : 0,
    onRest: () => {
      isOpen ? setShowChildren(true) : setShowChildren(false);
    },
  });

  return (
    <Container $state={state} isOpen={isOpen} $isChild={child} ref={ref} {...other}>
      <Wrapper
        $isChild={child}
        onClick={e => {
          e.stopPropagation();
          onClick && onClick(postition);
        }}
        as={to ? Link : 'div'}
        to={to}
      >
        {state === 'selected' && (
          <Icon $state={state} $color={highlightColor}>
            <IconChevronRightLarge size="width: 18px; height: 18px;" />
          </Icon>
        )}

        {state === 'default' && (
          <Icon $state={state} $color={fontColor}>
            <IconCheckboxBlankCircleOutline size="width: 18px; height: 20px;" />
          </Icon>
        )}
        {state === 'complete' && (
          <Icon $state={state} $color={highlightColor}>
            <IconCheckCircle size="width: 18px; height: 20px;" />
          </Icon>
        )}

        <Label $fontColor={fontColor} $state={state} $isChild={child}>
          {label}
        </Label>
      </Wrapper>

      {children && (
        <Body style={heightProps}>
          <div {...bind}>{(showChildren || isOpen) && children}</div>
        </Body>
      )}
    </Container>
  );
};

export default forwardRef(ProgressMenu);
