import { all, call, fork, put, take, takeEvery, select, delay } from 'redux-saga/effects';
import {
  auth0Selectors,
  loginSelectors,
  generateShortId,
  isNil,
  isEmpty,
  avatarUrl,
  deviceSelectors,
} from '@sharkfinesse/sfl-lib';
import { types as loginTypes, actionCreators as loginActionCreators } from 'redux/modules/login';
import { types as auth0Types } from 'redux/modules/auth0';
import rsfApp, { initRsf, goOnline, startFirestore } from '../rsf';
import validateDevice, { setDeviceIdLocalStorage, getDeviceIdLocalStorage } from './device';
import Cookies from 'universal-cookie';
import { persistor } from '../../index';
import fetchAuth from 'utils/fetchAuth';
import retryFetch from './utils/retryFetch';
import { history } from '../../index';
import { getFirebaseEnvironment } from 'environment';
const environment = getFirebaseEnvironment();

const getFirebaseToken = async ({ token, deviceid }) => {
  const result = await fetch(`${environment.functionsRoot}auth/token`, {
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      deviceid,
    },
  });
  const json = await result.json();
  return json;
};

export const setLoginCookies = async user => {
  const cookies = new Cookies();
  cookies.set('sfl.loggedIn', 'true', { path: '/', domain: '.sharkfinesse.com' });

  if (user.photoURL) {
    cookies.set('sfl.photoUrl', user.photoURL, { path: '/', domain: '.sharkfinesse.com' });
  } else {
    try {
      const gravatarUrl = avatarUrl({
        email: user.email,
        size: 200,
      });
      const gravatar = await fetch(gravatarUrl);

      if (gravatar.ok) {
        cookies.set('sfl.photoUrl', gravatarUrl, { path: '/', domain: '.sharkfinesse.com' });
      } else {
        cookies.remove('sfl.photoUrl', { path: '/', domain: '.sharkfinesse.com' });
      }
    } catch (error) {
      console.log('gravatar error', error);
    }
  }
};

function* loginSaga() {
  const [token, profile, userId, localDeviceId] = yield all([
    select(auth0Selectors.getToken),
    select(auth0Selectors.getProfile),
    select(auth0Selectors.getUserId),
    call(getDeviceIdLocalStorage, 'did'),
    put(loginActionCreators.loginStart()),
    call([persistor, persistor.purge]),
    call(initRsf),
  ]);

  try {
    const deviceId = isNil(localDeviceId) ? generateShortId() : localDeviceId;

    const response = yield call(retryFetch, getFirebaseToken, {
      token,
      deviceid: deviceId,
      userId,
    });

    if (response.status === 'success') {
      const auth = rsfApp.firebaseApp.auth();
      const { user } = yield call([auth, auth.signInWithCustomToken], response.firebaseToken);

      yield call([user, user.updateProfile], {
        displayName: profile.name,
      });

      const device = yield call(validateDevice, deviceId);

      if (device.status === 'success') {
        if (localDeviceId !== deviceId) yield call(setDeviceIdLocalStorage, deviceId);

        if (profile?.email) {
          if (isNil(user?.email) || isEmpty(user?.email) || user?.email !== profile.email) {
            yield call([user, user.updateEmail], profile.email);
          }
        }

        yield all([fork(setClaims, user), fork(loginStatusWatcher, true), call(goOnline)]);
        yield put(loginActionCreators.loginComplete());
      } else {
        yield call([history, history.push], { pathname: `/logout/${device.errorCode}` });
      }
    } else {
      console.log('L200 1', response);
      yield call([history, history.push], { pathname: '/logout/U100' });
    }
    // successful login will trigger the loginStatusWatcher, which will update the state
  } catch (error) {
    console.log('L200 2', error);
    yield call([history, history.push], { pathname: '/logout/L200' });
  }
}

function* loginStatusWatcher(intial) {
  // events on this channel fire when the user logs in or logs out
  yield call(initRsf);

  const [channel, isOnline] = yield all([
    call(rsfApp.rsf.auth.channel),
    select(deviceSelectors.isOnline),
    put(loginActionCreators.loginLoading(true)),
  ]);

  while (true) {
    const { user } = yield take(channel);
    if (user) {
      if (!intial) yield fork(setClaims, user);
      const complete = yield select(loginSelectors.getComplete);

      if (!complete) yield take(loginTypes.LOGIN.COMPLETE);
      yield call(startFirestore);
      // make sure login is complete before syncing device

      yield all([put(loginActionCreators.loginSuccess(user)), call(setLoginCookies, user)]);
    } else {
      yield delay(500);
      yield call([history, history.push], { pathname: '/logout' });
    }
  }
}

function* setClaims(user) {
  try {
    const setClaimsResult = yield call(retryFetch, fetchAuth, {
      url: 'claims/set',
      config: {
        method: 'POST',
      },
    });
    if (setClaimsResult.status === 'success') yield call([user, user.getIdTokenResult], true);
    return setClaimsResult;
  } catch (error) {
    console.log('Set claims error', error);
    return { status: 'error' };
  }
}

function* runLoginWatcher() {
  const cookies = new Cookies();
  const loggedInCookie = cookies.get('sfl.loggedIn');
  const loggedInLS = localStorage.getItem('auth0LoggedIn');
  if (loggedInLS || loggedInCookie) {
    yield fork(loginStatusWatcher);
  } else {
    yield put(loginActionCreators.loginFailure());
  }
}

function* rootSaga() {
  yield all([
    takeEvery(auth0Types.LOGIN.SUCCESS, loginSaga),
    takeEvery(loginTypes.LOGIN.REQUEST, loginSaga),
  ]);
  yield fork(runLoginWatcher);
}

export default rootSaga;
