// @flow
import applyTheme from '../../styles/apply-theme';

const theme = applyTheme('form');
export const Column = theme('column');
export const Row = theme('row');
export const TextFieldContainer = theme('textFieldContainer');
export const HelpText = theme('helpText');
export const BeforeContainerRelative = theme('beforeContainerRelative');
export const AfterContainerRelative = theme('afterContainerRelative');

const tooltipError = applyTheme('tooltipError');
export const TooltipErrorContainer = tooltipError('base');
export const TooltipErrorLabel = tooltipError('label');
export const TooltipErrorContentContainer = tooltipError('contentContainer');
