"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.costOfDoNothing = void 0;

var _fp = require("./fp");

var _math = require("./math");

const costOfDoNothing = (0, _fp.ifElse)(_math.isNegativeOrZero, (0, _fp.always)(_fp.Maybe.Nothing), _fp.Maybe.Just);
exports.costOfDoNothing = costOfDoNothing;