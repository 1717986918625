"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _moment = _interopRequireDefault(require("moment"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const initialState = {
  emails: [],
  benefits: [],
  showNotes: true,
  showValues: true,
  showInfo: true,
  notify: true,
  expiry: (0, _moment.default)().add(1, 'week').toDate()
};
var _default = initialState;
exports.default = _default;