/* @flow */
import React from 'react';
import type { Element } from 'react';
import TextField from '../text-field';
import Button from '../button/button';
import { IconSearch, IconCross } from '../icon';
import { isEmpty } from '@sharkfinesse/sfl-lib';
import { BeforeContainer, AfterContainer, Wrapper, Toolbar } from './styled';

type Props = {
  placeholder?: Element<*> | string,
  searchValue?: string,
  onSearch?: Function,
  onSort?: Function,
  sorting?: object,
  toolbar?: Element<*> | string,
  ...other,
};

const Search = ({
  placeholder,
  searchValue,
  onSearch,
  onSort,
  sorting,
  toolbar,
  rounded,
  wrapperProps,
  ...props
}: Props): Element<any> => (
  <Wrapper>
    <TextField
      placeholder={placeholder}
      autoComplete="off"
      value={searchValue}
      onChange={e => onSearch(e.target.value)}
      before={
        <BeforeContainer $rounded={rounded}>
          <IconSearch />
        </BeforeContainer>
      }
      rounded={rounded}
      wrapperProps={wrapperProps}
      layout="column"
      fullWidth
      after={
        !isEmpty(searchValue) && (
          <AfterContainer $rounded={rounded}>
            <Button
              size="icon"
              variant="flat"
              color="light"
              onClick={e => {
                onSearch('');
              }}
            >
              <IconCross size="small" />
            </Button>
          </AfterContainer>
        )
      }
      {...props}
    />
    {toolbar && <Toolbar>{toolbar}</Toolbar>}
  </Wrapper>
);

export default Search;
