/* @flow */
/* eslint-disable no-confusing-arrow, no-nested-ternary  */
import React from 'react';
import PropTypes from 'prop-types';
import type { Element } from 'react';
import styled, { css } from 'styled-components';
import { grid } from 'styled-components-grid';

const StyledTableCell = styled.div`
  text-align: ${p => p.textAlign};
  ${({ theme, themeKey, columnKey, id, size }) => css`
    ${theme[themeKey].headBorderColor ? theme[themeKey].headBorderColor : 'border-color: black;'};
    ${theme[themeKey].borderStyle ? theme[themeKey].borderStyle : 'border-style: solid;'};
    ${p =>
      p.size
        ? grid.unit({
            size: size,
          })
        : grid.unit({
            size: theme[`${columnKey}`][`${id}`].size,
          })}

    ${p =>
      p.head
        ? css`
            ${theme[columnKey][id].head.borderWidth
              ? theme[columnKey][id].head.borderWidth
              : 'border-width: 0px;'};
            ${theme[themeKey].headCellPadding ? theme[themeKey].headCellPadding : 'padding: 0;'};
          `
        : css`
            ${theme[columnKey][id].borderWidth
              ? theme[columnKey][id].borderWidth
              : 'border-width: 0px;'};
            ${theme[themeKey].bodyCellPadidng ? theme[themeKey].bodyCellPadidng : 'padding: 0;'};
          `}
  `};
`;

type DefaultProps = {
  textAlign?: 'left' | 'center' | 'right',
};

type Props = DefaultProps & {
  themeKey?: string,
  columnKey?: string,
  head: boolean,
  id?: string | number,
  size?: number | Object,
  children?: Node,
  borderTop?: string,
  borderRight?: string,
  borderBottom?: string,
  borderLeft?: string,
  ...other,
};

const TableCell = (
  {
    id,
    themeKey: themeKeyProp,
    columnKey: columnKeyProp,
    children,
    borderTop,
    borderRight,
    borderBottom,
    borderLeft,
    size,
    head: headProp,
    textAlign,
  }: Props,
  context
): Element<any> => {
  const { sfluiTable, sfluiTableHeader } = context;

  let themeKey = themeKeyProp;
  let columnKey = columnKeyProp;
  if (sfluiTable) {
    if (typeof themeKey === 'undefined') {
      themeKey = sfluiTable.themeKey;
    }
    if (typeof columnKey === 'undefined') {
      columnKey = sfluiTable.columnKey;
    }
  }

  let head = headProp;
  if (sfluiTableHeader) {
    if (typeof head === 'undefined') {
      head = sfluiTableHeader.head;
    }
  }

  return (
    <StyledTableCell
      themeKey={themeKey}
      columnKey={columnKey}
      id={id}
      size={size}
      head={head}
      textAlign={textAlign}
      borderTop={borderTop}
      borderRight={borderRight}
      borderBottom={borderBottom}
      borderLeft={borderLeft}
    >
      {children}
    </StyledTableCell>
  );
};

TableCell.contextTypes = {
  sfluiTable: PropTypes.object,
  sfluiTableHeader: PropTypes.object,
};

TableCell.defaultProps = {
  textAlign: 'center',
  borderTop: '0px',
  borderRight: '0px',
  borderBottom: '0px',
  borderLeft: '0px',
};

export default TableCell;
