"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reselect = require("reselect");

var _index = require("../../index");

var _sessionDetails = _interopRequireDefault(require("./session-details"));

var _charts = _interopRequireDefault(require("./charts"));

var _cashflows = _interopRequireDefault(require("./cashflows"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* eslint-disable no-unused-vars  */
// Selectors
const getResultObj = (0, _reselect.createSelector)([_sessionDetails.default.getReviewPeriod, _cashflows.default.getTotalNonRecurringCostsCashflow, _cashflows.default.getTotalRecurringCostsCashflow, _cashflows.default.getTotalOneTimeBenefitsCashflow, _cashflows.default.getTotalMonthlyBenefitsCashflow, _sessionDetails.default.getMinimumReturn], (0, _index.pipe)(_index.generateIrrObject, _index.irrWithDcf));
const getResult = (0, _reselect.createSelector)([getResultObj], (0, _index.pipe)(_index.Maybe.fromMaybe({}), (0, _index.prop)('irr'), (0, _index.ifElse)((0, _index.lt)(1000000000000), (0, _index.always)(1000000000000), _index.identity)));
const getCashflow = (0, _reselect.createSelector)([getResultObj], resultObj => {
  return resultObj.isNothing ? null : resultObj.value.cashflow;
});
const getCashflowChartData = (0, _reselect.createSelector)([getCashflow, _charts.default.getAddChartMonths], (cashflow, addChartMonths) => {
  return addChartMonths(cashflow);
});
const getYearlyCashflowSummed = (0, _reselect.createSelector)([getCashflow], (cashflow = {}) => {
  return (0, _index.tryCatch)((0, _index.pipe)((0, _index.drop)(1), (0, _index.splitEvery)(12), (0, _index.map)(_index.sum)), _index.F)(cashflow);
});
const getYearlyCashflowSummedDisplay = (0, _reselect.createSelector)([getYearlyCashflowSummed, _sessionDetails.default.getNumberFormatObj], (yearlySummed, numberFormatObj) => {
  const addYrAndFormatting = (val, idx) => {
    return {
      year: (0, _index.inc)(idx),
      value: (0, _index.formatForDisplay)('number', numberFormatObj, val)
    };
  };

  return (0, _index.mapIndexed)(addYrAndFormatting, yearlySummed);
});
const getCashflowDisplay = (0, _reselect.createSelector)([getCashflow, _sessionDetails.default.getReviewPeriod, _sessionDetails.default.getNumberFormatObj], (cashflow = [], reviewPeriod, numberFormatObj) => {
  if ((0, _index.isNil)(cashflow)) {
    return null;
  } else {
    const cashflowlength = (0, _index.ifElse)((0, _index.lt)(_index.placeholder, 60), (0, _index.always)((0, _index.subtract)(60, reviewPeriod)), (0, _index.always)(reviewPeriod));
    return (0, _index.pipe)((0, _index.concat)(_index.placeholder, (0, _index.repeat)(0, cashflowlength(reviewPeriod))), (0, _index.map)(x => (0, _index.formatForDisplay)('number', numberFormatObj, x)))(cashflow);
  }
});
const getCashflowSum = (0, _reselect.createSelector)([getCashflow], (cashflow = []) => {
  if ((0, _index.isNil)(cashflow)) {
    return null;
  } else {
    return (0, _index.sumCashflow)(cashflow);
  }
});
const getCashflowSumDisplay = (0, _reselect.createSelector)([getCashflowSum, _sessionDetails.default.getNumberFormatObj], (value, numberFormatObj) => {
  return (0, _index.formatForDisplay)('number', numberFormatObj, value);
});
const getResultDisplay = (0, _reselect.createSelector)([getResult, _sessionDetails.default.getNumberFormatObj], (value, numberFormatObj) => (0, _index.formatForDisplay)('percentage', { ...numberFormatObj,
  decimalPlaces: 1
}, value));
const getResultDisplayExcel = (0, _reselect.createSelector)([getResult, _sessionDetails.default.getNumberFormatObj], (value, numberFormatObj) => (0, _index.formatForExcel)('percentage', { ...numberFormatObj,
  decimalPlaces: 1
}, value));
const selectors = {
  getResultObj,
  getCashflow,
  getCashflowChartData,
  getYearlyCashflowSummed,
  getYearlyCashflowSummedDisplay,
  getCashflowDisplay,
  getCashflowSum,
  getCashflowSumDisplay,
  getResult,
  getResultDisplay,
  getResultDisplayExcel
};
var _default = selectors;
exports.default = _default;