"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setDoc = exports.getDoc = exports.getCollectionDocs = exports.getCollection = void 0;

const getCollectionDocs = data => {
  const result = {};
  data.forEach(doc => result[doc.id] = doc.data());
  return result;
};

exports.getCollectionDocs = getCollectionDocs;

const getDoc = ({
  firestore,
  path,
  source = 'default'
}) => firestore.doc(path).get({
  source
});

exports.getDoc = getDoc;

const getCollection = ({
  firestore,
  path,
  source = 'default'
}) => firestore.collection(path).get({
  source
});

exports.getCollection = getCollection;

const setDoc = ({
  firestore,
  path,
  props,
  options
}) => firestore.doc(path).set(props, options);

exports.setDoc = setDoc;