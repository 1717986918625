import { css } from 'styled-components';
import { setAlphaHsla } from '@sharkfinesse/sfl-lib';

const Breadcrumb = ({ font, colors, scale }) => ({
  breadcrumbWrapper: () => css`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style: none;
    color: ${font.color};
    min-height: 28px;
  `,
  breadcrumbItem: () => css`
    display: flex;
    user-select: none;
    margin-left: 2px;
    margin-right: 2px;
    color: ${font.color};
    button {
      border: 0;
    }
    a,
    button {
      color: ${colors.primary};
      text-decoration: none;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    &:first-child {
      margin-left: 0;

      button {
        padding: 0;
      }
    }
    &:last-child {
      margin-right: 0;
      cursor: auto;
      a {
        color: ${setAlphaHsla(0.5, colors.primary)};
        &:hover {
          text-decoration: none;
        }
      }
    }
  `,
  breadcrumbsSeparator: () => css`
    display: flex;
    user-select: none;
    margin-left: 0;
    margin-right: 0;
  `,
  breadcrumbsWrapper: () => css`
    width: 100%;
    display: flex;
    align-items: flex-start;
  `,
  breadcrumbsLeft: () => css`
    display: flex;
    flex-grow: 1;
    overflow: hidden;
  `,
  breadcrumbsRight: () => css`
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    overflow: hidden;
  `,
});

export default Breadcrumb;
