/* @flow */
import {
  createReducer,
  simpleAction,
  simpleReducer,
  without,
  pipe,
  uniq,
  sessionQuestionnaireInitialState,
} from '@sharkfinesse/sfl-lib';

const namespace = (name: string): string => `@@session.questionnaire/${name}`;

//Actions
export const types = {
  EMAILS: namespace('EMAILS'),
  BENEFITS: {
    ADD: namespace('BENEFITS.ADD'),
    REMOVE: namespace('BENEFITS.REMOVE'),
  },
  SHOW_NOTES: namespace('SHOW_NOTES'),
  SHOW_VALUES: namespace('SHOW_VALUES'),
  SHOW_INFO: namespace('SHOW_INFO'),
  NOTIFY: namespace('NOTIFY'),
  EXPIRY: namespace('EXPIRY'),
  MESSAGE: namespace('MESSAGE'),
  COMMON_NOTES: namespace('COMMON_NOTES'),
  LANGUAGE: namespace('LANGUAGE'),
  PREVIEW: namespace('PREVIEW'),
};

//Reducer
export const initialState = sessionQuestionnaireInitialState;

const reduceEmails = simpleReducer('emails');
const reduceShowNotes = simpleReducer('showNotes');
const reduceShowValues = simpleReducer('showValues');
const reduceShowInfo = simpleReducer('showInfo');
const reduceNotify = simpleReducer('notify');
const reduceExpiry = simpleReducer('expiry');
const reduceMessage = simpleReducer('message');
const reduceCommonNotes = simpleReducer('commonNotes');
const reduceLanguage = simpleReducer('language');
const reduceAddBenefits = (state, { payload: { ids } }) => ({
  ...state,
  benefits: [...state.benefits, ...ids],
});

const reduceRemoveBenefits = (state, { payload: { ids } }) => ({
  ...state,
  benefits: pipe(without, uniq)(ids, state.benefits),
});

const reducePreview = simpleReducer('preview');

export default createReducer(
  {
    [types.EMAILS]: reduceEmails,
    [types.BENEFITS.ADD]: reduceAddBenefits,
    [types.BENEFITS.REMOVE]: reduceRemoveBenefits,
    [types.SHOW_NOTES]: reduceShowNotes,
    [types.SHOW_VALUES]: reduceShowValues,
    [types.SHOW_INFO]: reduceShowInfo,
    [types.NOTIFY]: reduceNotify,
    [types.EXPIRY]: reduceExpiry,
    [types.MESSAGE]: reduceMessage,
    [types.COMMON_NOTES]: reduceCommonNotes,
    [types.LANGUAGE]: reduceLanguage,
    [types.PREVIEW]: reducePreview,
  },
  initialState
);

//Action Creators
const updateEmails = simpleAction(types.EMAILS);
const addBenefit = simpleAction(types.BENEFITS.ADD);
const removeBenefit = simpleAction(types.BENEFITS.REMOVE);
const updateShowNotes = simpleAction(types.SHOW_NOTES);
const updateShowValues = simpleAction(types.SHOW_VALUES);
const updateShowInfo = simpleAction(types.SHOW_INFO);
const updateNotify = simpleAction(types.NOTIFY);
const updateExpiry = simpleAction(types.EXPIRY);
const updateMessage = simpleAction(types.MESSAGE);
const updateCommonNotes = simpleAction(types.COMMON_NOTES);
const updateLanguage = simpleAction(types.LANGUAGE);
const updatePreview = simpleAction(types.PREVIEW);

export const actionCreators = {
  updateEmails,
  addBenefit,
  removeBenefit,
  updateShowNotes,
  updateShowValues,
  updateShowInfo,
  updateNotify,
  updateExpiry,
  updateMessage,
  updateCommonNotes,
  updateLanguage,
  updatePreview,
};
