import { getTemplates } from 'redux/firestore';
import { useQuery } from 'react-query';
import { getCollectionData } from '@sharkfinesse/sfl-lib';

const getItems = async (props) => {
  const response = await getTemplates(props);
  const templates = getCollectionData(response);
  return templates;
};

const useTemplates = (props, config) => useQuery('templates', () => getItems(props), config);

export default useTemplates;
