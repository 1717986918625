/* @flow */
/* eslint-disable no-magic-numbers, no-unused-vars */
import {
  Colors,
  Font,
  Scale,
  SuiteButton,
  ButtonPrimary,
  ButtonSmall,
  ButtonNormal,
  ButtonLarge,
  ButtonDefault,
  Images,
  Panel,
  Suite,
  Toast,
  Costs,
  Scroll,
  BenefitLayoutDesigner,
  CompareSessions,
  ReactTable,
  HeadingH1Default,
  Header,
  PageToolBar,
  Toolbar,
  Account,
  SelectControl,
  SelectDropdownIndicator,
  SelectMenu,
  Sections,
  Alerts,
  Modal,
  Icons,
  ButtonLight,
  Checkbox,
  NavBarEvaluator,
  Evaluator,
  Popper,
  Menu,
  Hyperlink,
  Sidebar,
  DealIndicator,
  breakpoints,
  Device,
  MainMenu,
  ResultBox,
  Slide,
  Page,
  ToggleSwitch,
  Tabs,
  Skeleton,
  MediaManager,
  Tree,
  BurgerMenu,
  Notification,
  ListPanel,
  ExpandingListItem,
  Pill,
  General,
  ToolbarButton,
  Drawer,
  Avatar,
  Form,
  Picker,
} from './core';
import BaseTheme from './base';
import {
  placeholder,
  hsla,
  lightenHsla,
  darkenHsla,
  setAlphaHsla,
  merge,
  isNil,
  update,
} from '@sharkfinesse/sfl-lib';
import {
  modularScale,
  borderStyle,
  borderWidth,
  borderColor,
  padding,
  backgrounds,
} from 'polished';
import { css, keyframes } from 'styled-components';

const baseSaturation = hsla(placeholder, 100);
const baseAlpha = 1;

//colors
const grays = hsla(0, 0);
const uiBase = grays(88, baseAlpha);

//*******************************
// config
//******************************

const colors = Colors();
colors.primaryHex = '#00b7c3';
colors.primary = hsla(184, 100, 38, baseAlpha);
colors.secondary = hsla(210, 51, 47, baseAlpha);
colors.highlightBlue = '#0166ff';
colors.popperBox = hsla(0, 0, 100, baseAlpha);

colors.gradientsCls = css`
  ${colors.gradientsCls}
  .gradient1 {
    background: #22385e;
  }
  .gradient2 {
    background: #2e2e2e;
  }
  .gradient12,
  .gradient9,
  .gradient8,
  .gradient11 {
    background: linear-gradient(109.6deg, rgba(48, 207, 208, 1) 11.2%, rgba(51, 8, 103, 1) 92.5%);
  }
  .gradient13 {
    background: linear-gradient(-90deg, #e08377 0%, #db3923 100%);
  }
  .gradient14 {
    background: linear-gradient(-90deg, #8a6ae2 0%, #5c36c7 100%);
  }
  .gradient15 {
    background: linear-gradient(-90deg, #7ce25f 0%, #3cb918 100%);
  }
`;

const scale = Scale();

const font = Font();
font.size = '16px';
font.color = '#474747';
font.sizeSmall = scale.minusOne;
font.sizeNormal = scale.zero;

font.weightSemiBold = 400;

const config = {
  colors,
  scale,
  font,
  breakpoints: breakpoints,
  device: Device,
};

//*******************************
// overrides
//******************************

const chartTheme = 'aqua';

const buttonSmall = ButtonSmall(config);
buttonSmall.borderRadius = 0;

const buttonNormal = ButtonNormal(config);
buttonNormal.borderRadius = 0;

const buttonLarge = ButtonLarge(config);
buttonLarge.borderRadius = 0;

const buttonDefault = ButtonDefault(config);
buttonDefault.background = `background: ${colors.white}`;
buttonDefault.hoverBackground = `background: ${hsla(198, 100, 26, baseAlpha)}`;
buttonDefault.borderStyle = borderStyle('solid');
buttonDefault.borderWidth = borderWidth('1px');
buttonDefault.borderColor = borderColor(colors.secondary);
buttonDefault.textShadow = 'none';
buttonDefault.boxShadow = 'none';
buttonDefault.activeBoxShadow = 'none';
buttonDefault.hoverBorderWidth = borderWidth('1px');
buttonDefault.hoverBorderStyle = borderStyle('solid');
buttonDefault.hoverBorderColor = borderColor(colors.secondary);
buttonDefault.hoverFontColor = colors.white;
buttonDefault.hoverBackground = `background: ${colors.secondary}`;
buttonDefault.fontColor = colors.secondary;
buttonDefault.fontWeight = font.weightNormal;

const buttonPrimary = ButtonPrimary(config);
buttonPrimary.background = `background: ${colors.secondary}`;
buttonPrimary.hoverBackground = `background: ${colors.secondary}`;
buttonPrimary.borderStyle = borderStyle('none');
buttonPrimary.borderWidth = borderWidth('0px');
buttonPrimary.textShadow = 'none';
buttonPrimary.boxShadow = 'none';
buttonPrimary.activeBoxShadow = 'none';
buttonPrimary.hoverBorderWidth = borderWidth('0px');
buttonPrimary.fontColor = colors.white;
buttonPrimary.fontWeight = font.weightNormal;
buttonPrimary.hoverFontColor = colors.white;
buttonPrimary.hoverBackground = backgrounds('#2f5f90');

const buttonLight = ButtonLight(config);
buttonLight.fontColor = '#333333';

const navBarEvaluator = NavBarEvaluator(config);
navBarEvaluator.fontColor = '#333333';
navBarEvaluator.fontColorHover = '#333333';
navBarEvaluator.fontColorActive = '#333333';

const selectControlBase = SelectControl(config);
const selectControl = merge(selectControlBase, {
  borderColor: '#666666',
  borderRadius: '0px',
  boxShadowColorHover: ({ inverse }) => (inverse ? '#666666' : '#666666'),
});

const selectDropdownIndicatorBase = SelectDropdownIndicator(config);
const selectDropdownIndicator = merge(selectDropdownIndicatorBase, {
  color: ({ inverse }) => (inverse ? '#666666' : '#666666'),
});
const selectMenuBase = SelectMenu(config);
const selectMenu = merge(selectMenuBase, {
  border: '1px solid #666666',
  borderRadius: '0px',
});

const panelBase = Panel(config);
const panel = merge(panelBase, {
  base: () => css`
    ${panelBase.base}
    border: 1px solid #d8d8d8;
    border-radius: 0;
  `,

  header: ({ collapsible, isOpen }) => css`
    ${panelBase.header}
    border: none;
    ${collapsible &&
    !isOpen &&
    css`
      transition: background 0.3s;

      &:hover {
        background: ${setAlphaHsla(0.3, colors.primary)};
      }
      cursor: pointer;
    `}
  `,
});

const headerBase = Header(config);
const header = merge(headerBase, {
  base: () => css`
    ${headerBase.base}
    background: #001433;
  `,
  h1: ({ headerIcon }) => css`
    ${headerBase.h1({ headerIcon })}
    color: ${colors.white};
  `,
  toolbar: () => css`
    ${headerBase.toolbar}
    background: #001433;
  `,
  iconVariant: 'dark',
  dividerVariant: 'dark',
  animatedIcons: {
    primary: colors.white,
    secondary: colors.primary,
  },
});

const pageToolBar = PageToolBar(config);
pageToolBar.iconSecondaryColor = '#001433';

const suiteButtonBase = SuiteButton(config);
const suiteButton = merge(suiteButtonBase, {
  button: ({ result, isError }) => css`
    ${suiteButtonBase.button}

    border-radius: 0;
    border: 1px solid #d8d8d8;

    ${!isNil(result)
      ? css`
          border-top: 3px solid ${colors.secondary};
        `
      : css`
          border: 1px solid #d8d8d8;
        `}

    ${isError
      ? css`
          border-top: 3px solid ${colors.invalid};
          &:hover {
            background: ${colors.white};
            box-shadow: 0px 0px 0px 2px
              ${result ? setAlphaHsla(0.5, colors.invalid) : setAlphaHsla(0.5, colors.invalid)};
          }
        `
      : null}
      &.nav-item-active {
      border: 1px solid #d8d8d8;
      border-top: 3px solid ${colors.primary};
    }
  `,
  hoveredButton: ({ btnStyle }) => css`
    ${suiteButtonBase.hoveredButton}
    ${btnStyle === 'dark'
      ? css`
          background: radial-gradient(
            farthest-corner at 50% 50%,
            rgba(85, 104, 119, 0.5) 30%,
            #384956 100%
          );
        `
      : css`
          background: radial-gradient(
            farthest-corner at 50% 50%,
            rgba(255, 255, 255, 0.5) 30%,
            #e8e8e8 100%
          );
        `};
    border-radius: 0;
  `,
  result: () => css`
    ${suiteButtonBase.result}
    color: #045999;
  `,
});

const costs = Costs(config);
costs.headBackground = backgrounds(colors.white);
costs.footBackground = backgrounds(colors.white);
costs.rowBackgroundHover = backgrounds('#d8d8d8');
costs.borderWidth = borderWidth(0);
costs.rowBackgroundDrag = colors.white;
costs.headerBorderWidth = borderWidth('0px', '0px', '2px', '0px');

const images = Images(config);
images.benefits = {
  leftBubble: {
    background: '#56b0b9',
    highlight: '#66d3de',
    lines: '#01404a',
    border: '#01404a',
  },
  rightBubble: {
    background: '#016792',
    highlight: '#2984aa',
    lines: '#cccccc',
    border: '#01404a',
  },
};
images.costs = {
  bag: {
    background: '#ffb236',
    highlight: '#ffc567',
    cord: '#dc6914',
    label: {
      background: '#FFFFFF',
      highlight: '#EFEFEF',
    },
  },
  money: {
    stack: '#56b0b9',
    note: {
      outer: '#56b0b9',
      inner: '#66d3de',
      middleCircle: {
        background: '#E7ECED',
        highlight: '#D4D8DA',
      },
      leftCircle: '#E7ECED',
      rightCircle: '#E7ECED',
    },
  },
  coin: {
    stack: {
      background: '#ed8b43',
      highlight: '#dc6914',
    },
    background: '#ed8b43',
  },
  border: '#01404a',
};
images.reports = {
  border: '#01404a',
  background: '#F9F9F9',
  highlight: '#EFEFEF',
  squareOne: '#66d3de',
  squareTwo: '#2984aa',
  lines: '#C1C1c1',
  corner: '#C1C1c1',
};
images.spreadsheets = {
  border: '#484848',
  background: '#F7F9FC',
  highlight: '#EAEDF4',
  titleBar: '#56b0b9',
  titleBarButtons: '#66d3de',
  labelCells: '#66d3de',
  mainCells: '#dddddd',
  headerCells: '#7f7f7f',
  toolbarBackground: '#d7deed',
  headingBarBackground: '#c7cfe2',
  toolBarButton1: '#c7cfe2',
  toolBarButton2: '#ed8b43',
  toolBarButton3: '#b4e1fa',
  toolBarButton4: '#eff2fa',
};
images.presentations = {
  border: '#484848',
  background: '#66d3de',
  highlight: '#209e99',
  topBottomBarBackground: '#a3adaf',
  topBottomBarHighlight: '#949b9b',
  labels: '#ffffff',
  pieSegment1: '#ed8b43',
  pieSegment2: '#e7edec',
  pole: '#ededed',
};
images.interactive = {
  border: '#484848',
  circles: '#ed8b43',
  circlesHighlight: '#dc6914',
  bubble: '#66d3de',
  bubbleText: '#484848',
  bubbleHighlight: '#209e99',
  hand: '#ffece4',
  handHighlight: '#e8d0c8',
};

const suiteBase = Suite(config);
const suite = merge(suiteBase, {
  base: () => css`
    ${suiteBase.base}
    border-radius: 0;
    border: 0;
    box-shadow: 0 0 0 1px #d8d8d8;
  `,
  footer: () => css`
    ${suiteBase.footer}
    border-top: none;
    padding: 0 ${scale.minusOne} ${scale.minusOne} ${scale.minusOne};
  `,
  heading: () => css`
    ${suiteBase.heading}
    font-weight: 500;
  `,
  designerBase: selected => css`
    ${suiteBase.designerBase(selected)}
    user-select: none;
    ${selected &&
    css`
      box-shadow: 0px 0px 0px 2px ${colors.secondary};
      background: ${lightenHsla(32, colors.secondary)};
    `}

    &.suite-chosen {
      box-shadow: 0px 0px 0px 3px ${colors.secondary}, 0px 15px 15px -15px rgba(0, 0, 0, 0.3);
    }
  `,
});

const toastBase = Toast(config);
const toast = merge(toastBase, {
  base: () => css`
    ${toastBase.base}
    border-radius: 0;
    border: none;
  `,
  savingContainer: () => css`
    ${toastBase.savingContainer}
    color: ${colors.white};
    background-color: #001433;
  `,
});

const scrollBase = Scroll(config);
const scroll = merge(scrollBase, {
  thumb: () => css`
    .ScrollbarsCustom-Thumb {
      border-radius: 0px !important;
      background: ${colors.ui} !important;
      &:hover {
        background: ${lightenHsla(15, colors.uiDark)} !important;
      }
    }
    .dragging {
      background: ${lightenHsla(15, colors.uiDark)} !important;
    }
  `,
  track: src => css`
    .ScrollbarsCustom-Track {
      border-radius: 0px !important;
      background: ${src ? 'transparent' : colors.uiLightest} !important;
    }
  `,
});

const benefitLayoutDesignerBase = BenefitLayoutDesigner(config);
const benefitLayoutDesigner = merge(benefitLayoutDesignerBase, {
  rightColumn: expanded => css`
    ${benefitLayoutDesignerBase.rightColumn}
    background: ${darkenHsla(20, colors.ui)};
  `,
  benfitListFooter: () => css`
    ${benefitLayoutDesignerBase.benfitListFooter}
    padding-top: ${scale.minusOne};
  `,
});

const compareSessionsBase = CompareSessions(config);
const compareSessions = merge(compareSessionsBase, {
  selected: {
    background: '#F6F6F9',
  },
  unselected: {
    background: 'transparent',
  },
});

const reactTableBase = ReactTable(config);
const reactTable = merge(reactTableBase, {
  container: () => css`
    ${reactTableBase.container}
    box-shadow: none;
  `,
  table: () => css`
    ${reactTableBase.table}
    background: ${colors.white};
  `,
  head: () => css`
    ${reactTableBase.head}
    background: ${colors.white};
  `,
  row: ({ $variant, $numberOfChildren, $selected, $useRowSelect }) => css`
    ${reactTableBase.row({ $variant, $numberOfChildren, $selected, $useRowSelect })}
    background-color: ${colors.white};
    ${$useRowSelect &&
    css`
      cursor: pointer;
      ${!$selected &&
      css`
        &:hover {
          background-color: ${colors.uiLightest};
        }
      `}
      ${$selected &&
      css`
        background-color: ${colors.uiLight};
        box-shadow: 0 0 0 2px ${colors.primary};
        border-radius: 0px;
        position: relative;
      `}
    `}
    border-bottom: solid 1px ${colors.uiLight};
    ${$variant === 'head' &&
    css`
      user-select: none;
      border-bottom: solid 2px ${colors.uiLight};
      &:first-child {
        background: ${colors.white};
        font-weight: ${font.weightSemiBold};
        border-bottom: none;
      }
    `}
  `,
  cell: ({ $variant, $isSticky, $hover, $hovered, $selected }) => css`
    ${reactTableBase.cell({ $variant, $isSticky, $hover, $hovered, $selected })}
    border-bottom: none;
    ${$hovered &&
    !$selected &&
    css`
      background-color: #f9f9fb;
      border: 1px solid #f9f9fb;
    `}
    ${$selected &&
    css`
      background-color: #f9f9fb;
      border: 1px solid #f9f9fb;
    `}
    ${$variant === 'head' &&
    css`
      border-right: solid 1px ${colors.uiLight};
      &:first-child {
        justify-content: center;
      }
      &:last-child {
        border-right: none;
      }
    `};
  `,
});

const toolbarBase = Toolbar(config);
const toolbar = merge(toolbarBase, {
  divider: ({ color, margin }) => css`
    ${toolbarBase.divider({ color, margin })}
    border-right: 1px solid ${color === 'dark' ? '#4c4c4c' : '#e8edf2'};
  `,
});

const accountBase = Account(config);
const account = merge(accountBase, {
  labelBackgroundTo: '#001433',
});

const sectionsBase = Sections(config);
const sections = merge(sectionsBase, {
  base: () => css`
    ${sectionsBase.base()}
    border: 1px solid #666666;
    border-radius: 0;
  `,
});

const alertsBase = Alerts(config);
const alerts = merge(alertsBase, {
  base: () => css`
    ${alertsBase.base()}
    border: 1px solid #666666;
    border-radius: 0;
  `,
});

const modalBase = Modal(config);
const modal = merge(modalBase, {
  header: () => css`
    ${modalBase.header()}
    background: ${colors.white};
    border-radius: 0;
  `,
  headerKeyline: () => css`
    ${modalBase.headerKeyline()}
    box-shadow: 0 1px 0 0 ${colors.white};
    background: ${colors.white};
  `,
  title: () => css`
    ${modalBase.title()}
    font-weight: ${font.weightSemiBold};
    font-size: ${font.sizeLarge};
  `,
  body: ({ showFooterKeyline, showHeaderKeyline, showFooter }) => css`
    ${modalBase.body({ showFooterKeyline, showHeaderKeyline, showFooter })}
    ${!showFooter &&
    css`
      border-radius: 0;
    `}
  `,
  footer: ({ showKeyline }) => css`
    ${modalBase.footer({ showKeyline })}
    border-radius: 0;
  `,
  footerKeyline: () => css`
    ${modalBase.footerKeyline()}
    box-shadow: 0 -1px 0 0 ${colors.white};
  `,
});

const iconsBase = Icons(config);
const icons = merge(iconsBase, {
  defaultColor: '#333333',
  inverseColor: colors.white,
});

const checkboxBase = Checkbox(config);
const checkbox = merge(checkboxBase, {
  base: ({ checked, round, indeterminate }) => css`
    ${checkboxBase.base({ checked, round, indeterminate })}
    border-radius: 0;
  `,
});

const evaluatorBase = Evaluator(config);
const evaluator = merge(evaluatorBase, {
  heading: () => css`
    ${evaluatorBase.heading()}
    font-weight: 500;
  `,
  info: () => css`
    h3 {
      margin: 0;
      font-size: ${font.sizeNormal};
      font-weight: 500;
    }
    p {
      margin-top: 0;
    }
  `,
});

const menuBase = Menu(config);
const menu = merge(menuBase, {
  item: ({ compact }) => css`
    ${menuBase.item({ compact })}
    color: ${font.color};
  `,
});

const hyperlinkBase = Hyperlink(config);
const hyperlink = merge(hyperlinkBase, {
  base: () => css`
    ${hyperlinkBase.base()}
    color: ${colors.secondary};
  `,
});

const sidebarBase = Sidebar({
  ...config,
  drawerColor: '#001950',
  navbarColor: '#001433',
  toggleColor: '#12366f',
});

const sidebar = merge(sidebarBase, {});

const dealIndicatorBase = DealIndicator(config);
const dealIndicator = merge(dealIndicatorBase, {
  wrapper: () => css`
    ${dealIndicatorBase.wrapper()}
    background: #001433;
  `,
  indicatorBorder: () => css`
    ${dealIndicatorBase.indicatorBorder()}
    border: none;
  `,
  indicatorColor: ({ status, indicatorColor }) => css`
    ${dealIndicatorBase.indicatorColor({ status, indicatorColor })}
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  `,
});
const mainMenuBase = MainMenu(config);
const mainMenu = merge(mainMenuBase, {
  aside: ({ open, float }) => css`
    ${mainMenuBase.aside({ open, float })}
    background: #ededed;
    border-right: 1px solid #d8d8d8;
    border-top: none;
  `,
  menuContainer: ({ open, float }) => css`
    ${mainMenuBase.menuContainer({ open, float })}
    padding: 0;
  `,
  menuItemWrapper: () => css`
    ${mainMenuBase.menuItemWrapper()}
    background: none;
    border-radius: 0;
    color: #333333;
    span {
      fill: #333333;
    }
    cursor: pointer;
    &:hover {
      background: #ffffff;
      box-shadow: 0px 6px 5px 0px rgba(0, 0, 0, 0.15);
    }
    &.active {
      background: #ffffff;
      span {
        position: relative;
        &::before {
          background: ${colors.highlightBlue};
          content: '';
          position: absolute;
          left: -10px;
          border-radius: 3px;
          width: 5px;
          height: 100%;
        }
      }
    }
  `,
});

const resultBoxBase = ResultBox(config);
const resultBox = merge(resultBoxBase, {
  bgBox: () => css`
    ${resultBoxBase.bgBox()}
    border-radius: 0;
  `,
});

const slideBase = Slide(config);
const slide = merge(slideBase, {
  slideBox: ({ checked, disabled }) => css`
    ${slideBase.slideBox({ checked, disabled })}
    border-radius: 0;
  `,
  slideBody: ({ $empty }) => css`
    ${slideBase.slideBody({ $empty })}
    border-radius: 0;
  `,
  slideImage: ({ $empty }) => css`
    ${slideBase.slideImage({ $empty })}
    border-radius: 0;
  `,
  pendingBox: ({ $empty }) => css`
    ${slideBase.pendingBox({ $empty })}
    border-radius: 0;
  `,
  tag: () => css`
    ${slideBase.tag()}
    border-radius: 0;
    padding: 4px 6px 0 10px;
  `,
  loading: () => css`
    ${slideBase.loading()}
    border-radius: 0;
  `,
});

const pageBase = Page(config);
const page = merge(pageBase, {
  mainSection: ({ floatingMenu }) => css`
    ${pageBase.mainSection({ floatingMenu })}
    background: #ffffff;
    border-top: none;
  `,
});

const toggleSwitchBase = ToggleSwitch(config);
const toggleSwitch = merge(toggleSwitchBase, {
  offColor: '#333333',
});

const tabsBase = Tabs(config);
const tabs = merge(tabsBase, {
  verticalTabs: ({ selected }) => css`
    ${tabsBase.verticalTabs({ selected })}
    border-right: none;
  `,
});

const getBgColor = ({ color, colors }) => {
  //const color = css`background: linear-gradient(0deg, rgba(30, 135, 240, 1) 0%, rgba(64, 188, 249, 1) 100%)`
  if (color === 'uiLightest') return colors.uiLightest;
  if (color === 'white') return '#f5f5f5';
  if (color === 'primary') return colors.primary;
};

const skeletonBase = Skeleton({ ...config, bgColor: getBgColor });
const skeleton = merge(skeletonBase, {
  container: ({ color = 'uiLightest', borderRadius, id }) => css`
    ${skeletonBase.container({ color, borderRadius, id })}
    border-radius: 0;
  `,
});

const mediaManagerBase = MediaManager(config);
const mediaManager = merge(mediaManagerBase, {
  sessionImageHeight: () => css`
    ${mediaManagerBase.sessionImageHeight()}
    border-radius: 0;
  `,
  skeleton: () => css`
    ${mediaManagerBase.skeleton()}
    background: #f5f5f5;
  `,
});

const treeBase = Tree(config);
const tree = merge(treeBase, {
  treeHeader: () => css`
    ${treeBase.treeHeader()}
    border-radius: 0;
  `,
  treeContainer: () => css`
    ${treeBase.treeContainer()}
    border-radius: 0;
    border: 1px solid #666666;
  `,
});
const burgerMenuBase = BurgerMenu(config);
const burgerMenu = merge(burgerMenuBase, {
  burger: ({ isOpen }) => css`
    ${burgerMenuBase.burger({ isOpen })}
    div {
      background: ${colors.white};
    }
  `,
});

const notificationBase = Notification(config);
const notification = merge(notificationBase, {
  wrapper: ({ onClick, $variant }) => css`
    ${notificationBase.wrapper({ onClick, $variant })}
    border-radius: 0px;
  `,
});

const listPanelBase = ListPanel(config);
const listPanel = merge(listPanelBase, {
  panel: () => css`
    ${listPanelBase.panel()}
    border-radius: 0px;
  `,
  headerImage: () => css`
    ${listPanelBase.headerImage()}
    border-radius: 0px;
  `,
  headerImageContainer: () => css`
    ${listPanelBase.headerImageContainer()}
    border-radius: 0px;
  `,
  itemRow: () => css`
    ${listPanelBase.itemRow()}
    &:hover {
      background: ${setAlphaHsla(0.2, colors.primary)};
      border-radius: 0;
      border: 0;
    }
  `,
});

const expandingListItemBase = ExpandingListItem(config);
const expandingListItem = merge(expandingListItemBase, {
  container: ({ isOpen, selected, small }) => css`
    ${expandingListItemBase.container({ isOpen, selected, small })}
    border-radius: 0;
    border: 1px soild #666666;
    background: ${colors.white};
    ${selected
      ? css`
          box-shadow: 0 0 0 ${small ? '1px' : '2px'} ${colors.primary};
          background: ${lightenHsla(15, colors.uiLight)};
        `
      : css`
          transition: background 0.3s, box-shadow 0.3s;
          box-shadow: 0 0 0 1px ${colors.uiLight};
          &:hover {
            box-shadow: 0 0 0 ${small ? '1px' : '2px'} ${lightenHsla(10, colors.primary)};
            background: ${lightenHsla(15, colors.uiLight)};
          }
        `}
  `,
});
const pillBase = Pill(config);
const pill = merge(pillBase, {
  base: () => css`
    ${pillBase.base()}
    background: ${colors.primary};
    border-radius: 0px;
  `,
});

const generalBase = General(config);
const general = merge(generalBase, {
  pillBg: () => css`
    ${generalBase.pillBg()}
    border-radius: 0;
  `,
});

const toolbarButtonBase = ToolbarButton(config);
const toolbarButton = merge(toolbarButtonBase, {
  button: ({ toggled, disabled }) => css`
    ${toolbarButtonBase.button({ toggled, disabled })}
    border-radius: 0;
  `,
});

const drawerBase = Drawer(config);
const drawer = merge(drawerBase, {
  background: ({ $draggable, $position, $showToggle, $background }) => css`
    ${drawerBase.background({ $draggable, $position, $showToggle, $background })}
    ${$draggable &&
    css`
      box-shadow: 0 0 0 1px rgb(128 128 128 / 35%), 0px 1px 15px 2px rgb(0 0 0 / 25%);
      border-radius: 0;
    `};
  `,
});

const TooltipColor = colors.white;
const TooltipBorderColor = colors.uiLight;
const popperBase = Popper(config);
const popper = merge(popperBase, {
  box: ({ tipStyle, zIndex = 9000 }) => css`
      ${popperBase.box}
      background-color: ${TooltipColor};
      border: ${TooltipBorderColor} 1px solid;
      color: ${font.color}};
      box-shadow: 0px 5px 10px 0px rgb(0 0 0 / 8%);
      &[data-popper-placement*='bottom'] .tooltip-arrow {
        top: 0;
      left: 0;
      margin-top: -10px;
      &::before {
        top: -11px;
        left: -1px;
        border: 11px solid;
        border-color: transparent transparent ${TooltipBorderColor} transparent;
      }
      &::after {
        bottom: 9px;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent ${TooltipColor} transparent;
      }
    }
    &[data-popper-placement*='top'] .tooltip-arrow {
      bottom: 0;
      left: 0;
      margin-bottom: -10px;
      &::before {
        bottom: -11px;
        left: -1px;
        border: 10px solid;
        border-color: ${TooltipBorderColor} transparent transparent transparent;
      }
      &::after {
        top: 9px;
        border-width: 10px 10px 0 9px;
        border-color: ${TooltipColor} transparent transparent transparent;
      }
    }
    &[data-popper-placement*='right'] .tooltip-arrow {
      left: 0;
      margin-left: -10px;
      &::before {
        left: -11px;
        border: 11px solid;
        border-color: transparent ${TooltipBorderColor} transparent transparent;
        top: -1px;
      }
      &::after {
        right: 9px;
        border-width: 10px 10px 10px 0;
        border-color: transparent ${TooltipColor} transparent transparent;
      }
    }
    &[data-popper-placement*='left'] .tooltip-arrow {
      right: 0;
      margin-right: -11px;
      &::before {
        right: -11px;
        border: 11px solid;
        border-color: transparent transparent transparent ${TooltipBorderColor};
        top: -1px;
      }
      &::after {
        left: 8px;
        border-width: 10px 0 10px 10px;
        border-color: transparent transparent transparent ${TooltipColor};
      }
    }
    `,
});

const avatarBase = Avatar(config);
const avatar = merge(avatarBase, {
  wrapper: ({ $border }) => css`
    ${avatarBase.wrapper({ $border })}
    border: none;
    ${$border &&
    css`
      box-shadow: 0 0 0 2px #001433;
    `}
  `,
  container: ({ $border }) => css`
    ${avatarBase.container({ $border })}
    ${$border &&
    css`
      box-shadow: 0 0 0 2px #001433;
    `}
  `,
});

const formBase = Form(config);
const form = merge(formBase, {
  wrapper: ({
    $focused,
    $autosize,
    $expanded,
    $invalid,
    $selected,
    $inverse,
    disabled,
    $rounded,
  }) => {
    const boxShadow = hover => {
      if ($rounded || $inverse || $autosize || $expanded) return 'none';
      if ($selected)
        return `0 0 0 1px ${colors.primary}${hover ? `, 0 0 8px ${colors.primary};` : ';'}`;
      if ($invalid)
        return `0 0 0 ${$focused ? '2px' : '1px'} ${colors.invalid}${
          hover ? `,0 0 8px ${colors.invalid};` : ';'
        };`;
      if ($focused)
        return `0 0 0 2px ${colors.primary}${hover ? `, 0 0 8px ${colors.primary};` : ';'}`;
      return `0 0 0 1px #666666${hover && !disabled ? `, 0 0 8px #666666;` : ';'}`;
    };
    const bg = hover => {
      if ($autosize || $expanded) return 'transparent';
      if ($inverse) {
        if (disabled) return setAlphaHsla(0.2, colors.white);
        if ($focused) return colors.white;
        if (hover) return setAlphaHsla(0.8, colors.white);
        return setAlphaHsla(0.6, colors.white);
      }
      if ($selected) return setAlphaHsla(0.15, colors.primary);
      if (disabled) return lightenHsla(3, colors.uiLightest);
      if ($invalid) return colors.white;
      return colors.white;
    };
    return css`
      color: ${disabled ? setAlphaHsla(0.5, font.color) : font.color};
      width: 100%;
      display: flex;
      transition: ${$inverse
        ? 'none'
        : 'background-color 200ms ease-in-out, ease-in-out, box-shadow 200ms ease-in-out'};
      border-radius: 0;
      box-shadow: ${boxShadow()};
      &:hover {
        box-shadow: ${boxShadow(true)};
        ${$inverse &&
        css`
          background: ${bg(true)};
        `}
      }
      background: ${bg()};
    `;
  },
  outputWrapper: ({ $align }) => css`
    ${formBase.outputWrapper({ $align })}
    background: #f0f1f5;
    color: #045999;
  `,
  expandedInput: () => css`
    ${formBase.expandedInput()}
    background: #f0f1f5;
  `,
  expandedInputFirst: () => css`
    border-radius: 0;
  `,
  expandedInputLast: () => css`
    border-radius: 0;
  `,
  expandedLabel: () => css`
    ${formBase.expandedLabel()}
    background: #f0f1f5;
  `,
  expandedLabelFirst: () => css`
    border-radius: 0;
  `,
  expandedLabelLast: () => css`
    border-radius: 0;
  `,
  expandedToggle: ({ $expanded }) => css`
    ${formBase.expandedToggle({ $expanded })}
    @media ${Device.min_sm} {
      justify-self: end;
      margin-right: -8px;
      background: #f0f1f5;
      padding: 4px 0 4px 4px;
      border-radius: 0;
    }
  `,
  panelHeading: () => css`
    ${formBase.panelHeading}
    font-size: 16px;
  `,
  label: ({ $inverse, $align, $bold }) => css`
    ${formBase.label}
    font-weight: ${$bold ? 600 : font.weightNormal};
  `,
});

const pickerBase = Picker(config);
const picker = merge(pickerBase, {
  expandedContainer: () => css`
    ${pickerBase.expandedContainer()}
    background: #f2fdff;
  `,

  listItem: () => css`
    ${pickerBase.listItem()}
    .hoverContainer {
      display: flex;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      justify-content: center;
      align-items: center;
      background: ${setAlphaHsla(0.9, colors.uiLightest)};
      transition: opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1);
      padding: 3px;
      height: 100%;
      .hoverContent {
        display: flex;
        flex-direction: row;
        transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
        transform: translateY(10px);
        height: 100%;
        align-items: center;
      }
    }
  `,
  hoverValue: () => css`
    ${pickerBase.hoverValue()}
    background-color: ${setAlphaHsla(1, colors.primary)};
    color: ${colors.white};
    border: 1px dashed ${colors.white};
  `,
});

const overrides = {
  chartTheme,
  buttonSmall,
  buttonNormal,
  buttonLarge,
  buttonDefault,
  buttonPrimary,
  images,
  panel,
  suiteButton,
  suite,
  toast,
  costs,
  scroll,
  benefitLayoutDesigner,
  compareSessions,
  reactTable,
  header,
  pageToolBar,
  toolbar,
  account,
  selectControl,
  selectDropdownIndicator,
  selectMenu,
  sections,
  alerts,
  modal,
  icons,
  buttonLight,
  checkbox,
  navBarEvaluator,
  evaluator,
  popper,
  menu,
  hyperlink,
  sidebar,
  dealIndicator,
  mainMenu,
  resultBox,
  slide,
  page,
  toggleSwitch,
  tabs,
  skeleton,
  mediaManager,
  tree,
  burgerMenu,
  notification,
  listPanel,
  expandingListItem,
  pill,
  general,
  toolbarButton,
  drawer,
  avatar,
  form,
  picker,
};

const theme = BaseTheme('Dynamic', config, overrides);

export default theme;
