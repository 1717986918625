import { css } from 'styled-components';

const CashFlow = ({ font, colors, scale, device: { min_md, min_lg } }) => ({
  wrapper: ({ $expanded }) => css`
    background: ${colors.white};
    ${$expanded &&
    css`
      position: fixed;
      top: 50px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 8900;
      width: 100%;
      height: calc(100% - 50px);
      padding: 0px 10px;
      overflow: auto;
    `}
  `,
  container: ({ $height }) => css`
    height: ${$height};
  `,
  toolbar: ({ $borderTop }) => css`
    ${$borderTop &&
    css`
      border-top: 1px solid ${colors.uiLightest};
    `}
    border-bottom: 1px solid ${colors.uiLightest};
    padding: 6px 0;
    display: flex;
    justify-content: space-between;
  `,
  toolbarButtonGroup: () => css`
    display: flex;
  `,
  dragger: () => css`
    width: 8px;
    height: 8px;
    border-style: solid;
    border-color: ${colors.white};
    border-width: 1px 0 0 1px;
    background-color: ${colors.primary};
  `,
  draggerWrapper: () => css`
    width: 14px;
    height: 14px;
    padding: 3px;
    cursor: crosshair;
    display: flex;
    position: absolute;
    bottom: -2px;
    right: 1px;
  `,
  grid: ({ years }) => css`
    align-self: flex-start;
    position: relative;
    display: grid;
    grid-template-columns: 100px repeat(${years}, minmax(190px, 400px));
    grid-gap: 0;
    grid-auto-flow: dense;
    height: auto;
    width: auto;
    border-bottom: 1px solid ${colors.uiLightest};
  `,
  header: () => css`
    font-weight: ${font.weightSemiBold};
    background: ${colors.white};
  `,
  rowHeader: () => css`
    background: ${colors.white};
    grid-column: 1 / span 1;
    position: sticky;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid ${colors.uiLightest};
    padding: 0 0.5em;
    height: 38px;
    z-index: 2;
  `,
  columnHeader: () => css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 4px;
  `,
  cell: () => css`
    background: ${colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 0.5em;
    height: 38px;
    position: relative;
    z-index: 1;
  `,
  ellipsis: () => css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  `,
});

export default CashFlow;
