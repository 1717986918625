/* @flow */
import {
  createReducer,
  simpleAction,
  simpleReducer,
  sessionTenderInitialState,
} from '@sharkfinesse/sfl-lib';
const namespace = (name: string): string => `@@session.tender/${name}`;

//Actions
export const types = {
  TENDER_PERIOD: { UPDATE: namespace('TENDER_PERIOD.UPDATE') },
  STAFF_ENGAGED: { UPDATE: namespace('STAFF_ENGAGED.UPDATE') },
  ANNUAL_LOADED: { UPDATE: namespace('ANNUAL_LOADED.UPDATE') },
  OTHER_COSTS: { UPDATE: namespace('OTHER_COSTS.UPDATE') },
  IS_DIRTY: namespace('IS_DIRTY'),
  IS_USED: namespace('IS_USED'),
  IS_COLLAPSED: namespace('IS_COLLAPSED'),
  DELETE_ALL: namespace('DELETE_ALL'),
  TRIGGER_DELETE: namespace('TRIGGER_DELETE'),
};

//Reducer
export const initialState = sessionTenderInitialState;

const reduceTenderPeriod = simpleReducer('tenderPeriod');
const reduceStaffEngaged = simpleReducer('staffEngaged');
const reduceLoadedCost = simpleReducer('annualLoadedCostPerPerson');
const reduceOtherCosts = simpleReducer('otherCosts');
const reduceIsDirty = simpleReducer('isDirty');
const reduceIsUsed = simpleReducer('isUsed');
const reduceIsCollapsed = simpleReducer('isCollapsed');
const reduceDeleteAll = (state, action) => {
  return { ...initialState, isCollapsed: state.isCollapsed };
};

export default createReducer(
  {
    [types.TENDER_PERIOD.UPDATE]: reduceTenderPeriod,
    [types.STAFF_ENGAGED.UPDATE]: reduceStaffEngaged,
    [types.ANNUAL_LOADED.UPDATE]: reduceLoadedCost,
    [types.OTHER_COSTS.UPDATE]: reduceOtherCosts,
    [types.IS_DIRTY]: reduceIsDirty,
    [types.IS_USED]: reduceIsUsed,
    [types.IS_COLLAPSED]: reduceIsCollapsed,
    [types.DELETE_ALL]: reduceDeleteAll,
  },
  initialState
);

//Action Creators
const updateTenderPeriod = simpleAction(types.TENDER_PERIOD.UPDATE);
const updateStaffEngaged = simpleAction(types.STAFF_ENGAGED.UPDATE);
const updateLoadedCost = simpleAction(types.ANNUAL_LOADED.UPDATE);
const updateOtherCosts = simpleAction(types.OTHER_COSTS.UPDATE);
const updateIsDirty = simpleAction(types.IS_DIRTY);
const updateIsUsed = simpleAction(types.IS_USED);
const updateIsCollapsed = simpleAction(types.IS_COLLAPSED);
const updateDeleteAll = simpleAction(types.DELETE_ALL);
const updateTriggerDelete = simpleAction(types.TRIGGER_DELETE);

export const actionCreators = {
  updateTenderPeriod,
  updateStaffEngaged,
  updateLoadedCost,
  updateOtherCosts,
  updateIsDirty,
  updateIsUsed,
  updateIsCollapsed,
  updateDeleteAll,
  updateTriggerDelete,
};
