import {
  length,
  evaluatorSelectors,
  sessionSelectors,
  routerSelectors,
} from '@sharkfinesse/sfl-lib';
import { select, all, call } from 'redux-saga/effects';
import { history } from '../../../index';

function* redoCurrent(action) {
  const [pastBenefitId, benefitId, sessionId, pathname] = yield all([
    select(sessionSelectors.getPast),
    select(evaluatorSelectors.getCurrent),
    select(sessionSelectors.getSessionId),
    select(routerSelectors.getPathname),
  ]);

  const pastBenefit = length(pastBenefitId) === 0 ? 'empty' : pastBenefitId[0].benefits.current;

  if (pastBenefit !== benefitId) {
    if (pathname.indexOf('/benefits/b/') !== -1) {
      yield call([history, history.push], {
        pathname: `/s/${sessionId}/benefits/b/${benefitId}/calculator`,
      });
    }
  }
}

export default redoCurrent;
