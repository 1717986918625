import { call, all, put } from 'redux-saga/effects';
import { actionCreators as cashflowActionCreators } from 'redux/modules/cashflow';
import { unlinkDefaultSessionValues } from './default-session-values';

function* evaluatorError({ payload, meta }) {
  const { id, schema, formData } = payload;
  yield all([
    put(cashflowActionCreators.updateEvaluatorReset({ id, meta })),
    call(unlinkDefaultSessionValues, { id, schema, formData, meta }),
  ]);
}

export default evaluatorError;
