import React, { useState, createContext } from 'react';
import Rnd from './rnd';
import DrawerComponent from './drawer-component';
import NiceModal from '@ebay/nice-modal-react';
import useDrawer from './useDrawer';
import { ViewportCover } from '../general-styles';
import { useLocalStorage } from 'hooks';

export const DrawerContext = createContext();

const Drawer = ({
  id,
  rnd,
  title,
  children,
  position: iPos,
  width: iWidth,
  height: iHeight,
  x: iX,
  y: iY,
  persist,
  ...other
}) => {
  const { drawerProps } = useDrawer();

  const [isHovered, setHovered] = useState();
  const [draggable, setDraggable] = useLocalStorage(persist, `${id}_draggable`, false);
  const [position, setPosition] = useLocalStorage(persist, `${id}_position`, iPos);
  const [width, setWidth] = useLocalStorage(persist, `${id}_width`, iWidth);
  const [height, setHeight] = useLocalStorage(persist, `${id}_height`, iHeight);
  const [x, setX] = useLocalStorage(persist, `${id}_x`, iX);
  const [y, setY] = useLocalStorage(persist, `${id}_y`, iY);
  const [showRightPin, setShowRightPin] = useLocalStorage(persist, `${id}_showRightPin`, false);
  const [showLeftPin, setShowLeftPin] = useLocalStorage(persist, `${id}_showLeftPin`, false);

  const toggle = () => {
    setHovered(false);
    if (drawerProps.isExpanded) drawerProps.close();
  };

  const drawerCmp = (
    <DrawerComponent {...drawerProps} title={title} rnd={rnd} {...other}>
      {children}
    </DrawerComponent>
  );

  return (
    <DrawerContext.Provider
      value={{
        isHovered,
        setHovered,
        draggable,
        setDraggable,
        position,
        setPosition,
        width,
        setWidth,
        height,
        setHeight,
        x,
        setX,
        y,
        setY,
        showRightPin,
        setShowRightPin,
        showLeftPin,
        setShowLeftPin,
        toggle,
      }}
    >
      {rnd ? (
        <ViewportCover>
          <Rnd {...drawerProps} {...other}>
            {drawerCmp}
          </Rnd>
        </ViewportCover>
      ) : (
        drawerCmp
      )}
    </DrawerContext.Provider>
  );
};

export default NiceModal.create(Drawer);
