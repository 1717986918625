/* @flow */
import { simpleAction, omit, loginInitialState } from '@sharkfinesse/sfl-lib';
import questionnaire from './login-questionnaire';

const namespace = (name: string): string => `@@login/${name}`;

// types
export const types = {
  LOGIN: {
    LOADING: namespace('LOGIN.LOADING'),
    REQUEST: namespace('LOGIN.REQUEST'),
    SUCCESS: namespace('LOGIN.SUCCESS'),
    FAILURE: namespace('LOGIN.FAILURE'),
    START: namespace('LOGIN.START'),
    COMPLETE: namespace('LOGIN.COMPLETE'),
    NOT_LOGGED_IN: namespace('LOGIN.COMPLETE'),
    DELETE_PHOTO: namespace('LOGIN.DELETE_PHOTO'),
    UPDATE_NAME: namespace('LOGIN.UPDATE_NAME'),
  },
};

const initialState = loginInitialState;

const reduceLoading = (state, { payload }) => {
  return {
    ...state,
    loading: payload,
  };
};

const reduceFailed = state => ({
  ...state,
  loading: false,
  loggedIn: false,
});

const reduceLoginSuccess = (state, { payload }) => ({
  ...state,
  loading: false,
  loggedIn: true,
  user: payload,
});

const reduceLoginStart = state => ({
  ...state,
  complete: false,
});

const reduceLoginComplete = state => ({
  ...state,
  complete: true,
});
const reduceDeletePhotoUrl = state => ({
  ...state,
  user: omit(['photoURL'], state.user),
});
const reduceUpdateName = (state, { payload }) => ({
  ...state,
  user: {
    ...state.user,
    displayName: payload,
  },
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN.LOADING:
      return reduceLoading(state, action);
    case types.LOGIN.REQUEST:
      return reduceLoading(state, action);
    case types.LOGIN.SUCCESS:
      return reduceLoginSuccess(state, action);
    case types.LOGIN.FAILURE:
      return reduceFailed(state, action);
    case types.LOGIN.START:
      return reduceLoginStart(state, action);
    case types.LOGIN.COMPLETE:
      return reduceLoginComplete(state, action);
    case types.LOGIN.DELETE_PHOTO:
      return reduceDeletePhotoUrl(state, action);
    case types.LOGIN.UPDATE_NAME:
      return reduceUpdateName(state, action);

    default:
      return { ...state, questionnaire: questionnaire(state.questionnaire, action) };
  }
};

//Action Creators
const login = () => ({ type: types.LOGIN.REQUEST });
const loginLoading = simpleAction(types.LOGIN.LOADING);
const loginSuccess = simpleAction(types.LOGIN.SUCCESS);
const loginFailure = simpleAction(types.LOGIN.FAILURE);
const loginStart = () => ({ type: types.LOGIN.START });
const loginComplete = () => ({ type: types.LOGIN.COMPLETE });
const deletePhotoURL = () => ({ type: types.LOGIN.DELETE_PHOTO });
const loginUpdateName = simpleAction(types.LOGIN.UPDATE_NAME);

export const actionCreators = {
  login,
  loginLoading,
  loginSuccess,
  loginFailure,
  loginStart,
  loginComplete,
  deletePhotoURL,
  loginUpdateName,
};

export default reducer;
