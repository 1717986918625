// @flow weak
/* eslint-disable no-magic-numbers */
import React from 'react';
import { OutputWrapper } from './styled';
import LoaderRing from '../loaders/ring';

const Output = ({ loading, value, align, ...other }) => {
  return (
    <OutputWrapper $align={align} {...other}>
      {loading ? <LoaderRing size={20} /> : value}
    </OutputWrapper>
  );
};
export default Output;
