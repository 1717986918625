import { css } from 'styled-components';

const BurgerMenu = ({ font, colors, scale }) => ({
  burger: ({ isOpen }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
    &:focus {
      outline: none;
    }

    div {
      width: 100%;
      height: 2px;
      background: ${colors.ui};
      border-radius: 10px;
      transition: all 0.3s linear;
      position: relative;
      transform-origin: 1px;
      will-change: transform;

      :first-child {
        transform: ${isOpen ? 'rotate(45deg)' : 'rotate(0)'};
      }

      :nth-child(2) {
        opacity: ${isOpen ? '0' : '1'};
        transform: ${isOpen ? 'translateX(-20px)' : 'translateX(0)'};
      }

      :nth-child(3) {
        transform: ${isOpen ? 'rotate(-45deg)' : 'rotate(0)'};
      }
    }
  `,
});

export default BurgerMenu;
