"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _reselect = require("reselect");

var _sessionDetails = _interopRequireDefault(require("./session-details"));

var _index = require("../../index");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* eslint-disable no-unused-vars  */
const getAddChartMonths = (0, _reselect.createSelector)([_sessionDetails.default.getReviewPeriod], reviewPeriod => {
  return (0, _index.pipe)((0, _index.slice)(0, reviewPeriod), (0, _index.append)(_index.placeholder, (0, _index.append)((0, _index.range)(0, (0, _index.inc)(reviewPeriod)), [])), _index.transpose);
});
const selectors = {
  getAddChartMonths
};
var _default = selectors;
exports.default = _default;