/* @flow */
import React, { memo } from 'react';
import Icon from '../icon';

let IconComponent = (props): React.Element<any> => (
  <Icon {...props}>
    <path fill="currentColor" d="M7,18.5l6.5-6.5L7,5.5l2-2l8.5,8.5L9,20.5L7,18.5z" />
  </Icon>
);

export default memo(IconComponent);
