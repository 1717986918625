const ChartColors = ({ font, colors, scale }) => ({
  series1: {
    background: '#2d8fdf',
  },
  series2: {
    background: '#ff7644',
  },
  series3: {
    background: '#7cb5ec',
  },
  series4: {
    background: '#434348',
  },
  series5: {
    background: '#8085e9',
  },
  series6: {
    background: '#f15c80',
  },
  series7: {
    background: '#e4d354',
  },
  series8: {
    background: '#2b908f',
  },
  series9: {
    background: '#f45b5b',
  },
  series10: {
    background: '#91e8e1',
  },
  benefitPie: {
    segment1: {
      border: colors.secondary,
      bg: colors.secondary,
    },
    segment2: {
      border: colors.uiLight,
      bg: colors.uiLightest,
    },
  },
});

export default ChartColors;
