/* @flow */
import React, { Fragment } from 'react';

import { RailOuter, RailInner } from './styled';

type Props = {
  getRailProps: Function,
};

const Rail = ({ getRailProps }: Props) => (
  <Fragment>
    <RailOuter {...getRailProps()} />
    <RailInner />
  </Fragment>
);

export default Rail;
