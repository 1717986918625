import { getFolders } from 'redux/firestore';
import { useQuery } from 'react-query';
import { getCollectionData } from '@sharkfinesse/sfl-lib';

const getItems = async (props) => {
  const response = await getFolders(props);
  return getCollectionData(response);
};

const useFolders = (props, config) => useQuery('folders', () => getItems(props), config);

export default useFolders;
