/* @flow */
/* eslint-disable no-confusing-arrow, no-nested-ternary, react/display-name */
import React from 'react';
import type { Element } from 'react';
import StyledModal from 'styled-modal';
import styled from 'styled-components';
import Button from '../button';
import IconCross from '../icon/icons/icon-cross';
import Header from './header';
import Body from './body';
import Footer from './footer';
import Toolbar, { ToolbarDivider } from '../toolbar';
import { Transition } from 'react-spring';
import { Blanket, ModalContainer, Positioner, LoadingWrapper } from './styled';
import { WIDTH_ENUM } from './width';
import { isNil } from '@sharkfinesse/sfl-lib';
import * as easings from 'd3-ease';
import LoaderRing from '../loaders/ring';

type ContainerComponentProps = {
  children: any,
  className?: string,
  isClientSide: boolean,
  open: boolean,
  theme: Object,
  widthValue: string | any,
  widthName: string,
};

const ContainerComponent = React.forwardRef(
  ({ children, className, isClientSide, open }: ContainerComponentProps, ref) => (
    <Transition
      native
      items={open}
      from={{ opacity: 0 }}
      enter={{ opacity: 1 }}
      leave={{ opacity: 0 }}
      config={{
        duration: 400,
        easing: easings.easeCubic,
      }}
    >
      {(styles, open) =>
        open && (
          <Blanket className={className} style={styles} key="container" ref={ref}>
            {children}
          </Blanket>
        )
      }
    </Transition>
  )
);

const Container = styled(ContainerComponent)`
  ${props => props.theme.container};
`;

const ModalComp = React.forwardRef(
  (
    { children, isClientSide, open, theme, widthValue, widthName, onRest }: ContainerComponentProps,
    ref
  ) => {
    return (
      <Transition
        native
        items={open}
        from={{ opacity: 0, transform: 'translate3d(0, 32px, 0)' }}
        enter={{ opacity: 1, transform: 'translate3d(0, 0px, 0)' }}
        leave={{ opacity: 0, transform: 'translate3d(0, -32px, 0)' }}
        config={{
          duration: 400,
          easing: easings.easeCubic,
        }}
        onRest={({ value }) => {
          if (value.opacity === 0 && onRest) {
            onRest();
          }
        }}
      >
        {(styles, open) =>
          open && (
            <Positioner widthName={widthName} widthValue={widthValue}>
              <ModalContainer ref={ref} style={styles}>
                {children}
              </ModalContainer>
            </Positioner>
          )
        }
      </Transition>
    );
  }
);
type DefaultProps = {
  showHeaderKeyline: boolean,
  showFooterKeyline: boolean,
  okButtonLabel: string,
  closeButtonTooltip: string,
  width: string | any,
};

type Props = DefaultProps & {
  showHeaderKeyline: boolean,
  showFooterKeyline: boolean,
  title?: Element<any> | string,
  onClose?: Function,
  children?: Element<any>,
  okButtonLabel?: string | Element<any>,
  closeButtonTooltip: string | Element<any>,
  okButtonProps: Object,
  toolbar?: Element<any>,
  isOpen: boolean,
  showFooter: boolean,
  footer?: Element<any>,
  loading: Boolean,
};

const Modal = ({
  showHeaderKeyline,
  showFooterKeyline,
  title,
  onClose,
  okButtonLabel,
  closeButtonTooltip,
  children,
  okButtonProps,
  toolbar,
  isOpen,
  width,
  showFooter,
  footer,
  loading,
  ...props
}: Props) => {
  const widthName = WIDTH_ENUM.values.includes(width) ? width : null;
  const widthValue = widthName ? null : width;

  return (
    <StyledModal
      closeOnEsc={true}
      closeOnOutsideClick={true}
      containerComponent={Container}
      onClose={onClose}
      modalComponent={ModalComp}
      open={isOpen}
      lockScrollWhenOpen={false}
      widthName={widthName}
      widthValue={widthValue}
      {...props}
    >
      <>
        {title && (
          <Header title={title} showKeyline={showHeaderKeyline}>
            <Toolbar style={{ height: '30px' }}>
              {toolbar}
              {toolbar && onClose && <ToolbarDivider color="dark" />}
              {onClose && (
                <Button
                  onClick={onClose}
                  size="icon"
                  variant="flat"
                  color="light"
                  tooltip={closeButtonTooltip}
                >
                  <IconCross />
                </Button>
              )}
            </Toolbar>
          </Header>
        )}
        <Body
          showHeaderKeyline={showHeaderKeyline}
          showFooterKeyline={showFooterKeyline}
          showFooter={showFooter}
        >
          {children}
          {loading && (
            <LoadingWrapper>
              <LoaderRing />
            </LoadingWrapper>
          )}
        </Body>
        {showFooter && (
          <Footer showKeyline={showFooterKeyline}>
            {isNil(footer) && (
              <Button onClick={onClose} {...okButtonProps}>
                {okButtonLabel}
              </Button>
            )}
            {footer}
          </Footer>
        )}
      </>
    </StyledModal>
  );
};

Modal.defaultProps = {
  showHeaderKeyline: true,
  showFooterKeyline: true,
  okButtonLabel: 'OK',
  closeButtonTooltip: 'Close',
  width: 'medium',
  showFooter: true,
};

export default Modal;
