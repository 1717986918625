"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const initialState = {
  account: '',
  accountName: '',
  opportunity: '',
  opportunityName: '',
  opportunityObjectId: '',
  opportunitySyncDate: ''
};
var _default = initialState;
exports.default = _default;