import styled from 'styled-components';

export const Button = styled.button`
  ${p => p.theme.addButton.button(p.disabled)};
`;

export const Icon = styled.span`
  ${p => p.theme.addButton.icon()};
`;

export const Label = styled.span`
  ${p => p.theme.addButton.label()};
`;
