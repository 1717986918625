// @flow
import React from 'react';
import type { Element } from 'react';
import Grid from 'styled-components-grid';
import styled from 'styled-components';
import { prop } from 'styled-tools';

const StyledGrid = styled(Grid)`
  ${() => prop('styles')};
`;

export type Props = {
  children?: Element<*> | string,
  styles?: Any,
};

const LayoutColumns = ({ children, ...props }: Props): Element<any> => (
  <StyledGrid {...props}>{children}</StyledGrid>
);

export default LayoutColumns;
