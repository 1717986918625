// @flow
import React from 'react';
import { Timeline } from './styled';
import TimelineContext from './timeline-context';

const Component = ({ position = 'right', children }) => (
  <TimelineContext.Provider value={{ position }}>
    <Timeline>{children}</Timeline>
  </TimelineContext.Provider>
);

export default Component;
