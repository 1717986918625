import { useState, useEffect } from 'react';
import { useUsers } from 'redux/firestore';
import createOption from './create-option';
import { includes } from '@sharkfinesse/sfl-lib';

const useSelectUsers = ({ account, product, byProductOnly, usersToExclude = [], email }) => {
  const [users, setUsers] = useState();
  const { data, isLoading } = useUsers({
    account,
    product: byProductOnly ? product : null,
  });

  useEffect(
    () => {
      if (!isLoading) {
        setUsers(
          data.reduce(
            (accum, user) =>
              user.email !== email && !includes(user.id, usersToExclude)
                ? [...accum, createOption(user)]
                : accum,
            []
          )
        );
      }
    },
    [data, usersToExclude, email, isLoading] // Only re-call effect if value or delay changes
  );

  return { users, isLoading };
};

export default useSelectUsers;
