"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchAccountLocale = exports.fetchAccount = void 0;

var _utils = require("./utils");

const fetchAccount = async ({
  firestore,
  account
}) => {
  const [accountDoc, categories, categoryTypes, evaluators, logos, preferences, coverpages] = await Promise.all([(0, _utils.getDoc)({
    firestore,
    path: `accounts/${account}`
  }), (0, _utils.getCollection)({
    firestore,
    path: `accounts/${account}/categories`
  }), (0, _utils.getCollection)({
    firestore,
    path: `accounts/${account}/categoryTypes`
  }), (0, _utils.getCollection)({
    firestore,
    path: `accounts/${account}/evaluators`
  }), (0, _utils.getCollection)({
    firestore,
    path: `accounts/${account}/logos`
  }), (0, _utils.getCollection)({
    firestore,
    path: `accounts/${account}/preferences`
  }), (0, _utils.getCollection)({
    firestore,
    path: `accounts/${account}/coverpages`
  })]);
  return { ...accountDoc.data(),
    categories: (0, _utils.getCollectionDocs)(categories),
    categoryTypes: (0, _utils.getCollectionDocs)(categoryTypes),
    evaluators: (0, _utils.getCollectionDocs)(evaluators),
    logos: (0, _utils.getCollectionDocs)(logos),
    preferences: (0, _utils.getCollectionDocs)(preferences),
    coverpages: (0, _utils.getCollectionDocs)(coverpages)
  };
};

exports.fetchAccount = fetchAccount;

const fetchAccountLocale = async ({
  firestore,
  account,
  locale
}) => {
  const lang = await (0, _utils.getDoc)({
    firestore,
    path: `accounts/${account}/locales/${locale}`
  });
  return lang.data();
};

exports.fetchAccountLocale = fetchAccountLocale;