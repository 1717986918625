import { select, fork, take, put, call } from 'redux-saga/effects';
import { mergeDeepRight, loginSelectors } from '@sharkfinesse/sfl-lib';
import {
  actionCreators as settingsActionCreators,
  types as settingsTypes,
  initialState as settingsInitialState,
} from '../modules/settings';
import rsfApp from '../rsf';

const settingsTransformer = settings => {
  if (!settings._hasPendingWrites) {
    return settings.data();
  } else {
    return false;
  }
};

export function* getSettings() {
  const user = yield select(loginSelectors.getUID);
  const settings = yield call(rsfApp.rsf.firestore.getDocument, `/settings/${user}`);
  yield put(settingsActionCreators.loaded(settings.data()));
}

export function* syncSettingsSaga() {
  const user = yield select(loginSelectors.getUID);
  yield fork(rsfApp.rsf.firestore.syncDocument, `/settings/${user}`, {
    successActionCreator: settingsActionCreators.sync,
    transform: settingsTransformer,
  });
}

export function* createSettings() {
  const settings = yield take(settingsTypes.LOADED);
  const user = yield select(loginSelectors.getUID);

  if (!settings.payload) {
    yield call(
      rsfApp.rsf.firestore.setDocument,
      `/settings/${user}`,
      mergeDeepRight({ id: user }, settingsInitialState)
    );
  }

  yield fork(syncSettingsSaga);
}

export const effects = [call(getSettings), fork(createSettings)];

export default effects;
