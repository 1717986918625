/* eslint-disable react-hooks/exhaustive-deps */

import { useModal } from '@ebay/nice-modal-react';
import { FormattedMessage } from 'react-intl';
import ModalInfo from 'components/modal/modal-info';

const useProModal = () => {
  const infoModal = useModal(ModalInfo);

  const show = () =>
    infoModal.show({
      title: (
        <FormattedMessage id="features.pro.modal.title" defaultMessage="Upgrade your version" />
      ),
      body: (
        <FormattedMessage
          id="features.pro.modal.body"
          defaultMessage="You do not have access to this feature. Please contact us to upgrade your version."
        />
      ),
    });

  return {
    show,
  };
};

export default useProModal;
