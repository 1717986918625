"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

const getPersistence = state => state.persistence;

const getUnsavedChanges = state => state.persistence.unsavedChanges;

const selectors = {
  getPersistence,
  getUnsavedChanges
};
var _default = selectors;
exports.default = _default;