import { useQuery } from 'react-query';
import fetchSessionDetails from './fetch-session-details';

const getItems = async props => fetchSessionDetails(props);

const useSessionDetails = (props, config) => {
  return useQuery(`session_details_${props.id}`, () => getItems(props), config);
};

export default useSessionDetails;
