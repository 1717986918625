"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hashId = exports.generateTwoSecondId = exports.generateShortId = void 0;

var _nanoid = require("nanoid");

var _fp = require("./fp");

var _md = _interopRequireDefault(require("crypto-js/md5"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* eslint-disable no-magic-numbers */
const generateShortId = length => {
  const len = length ? length : 14;
  return (0, _nanoid.nanoid)(len);
};

exports.generateShortId = generateShortId;

const inRange = (low, high) => (0, _fp.allPass)([x => x >= low, x => x < high]);

const generateTwoSecondId = () => {
  const d = new Date();
  const seconds = (0, _fp.toString)(d.getSeconds());
  const secondsLength = seconds.length;
  const secondsTens = secondsLength === 1 ? 0 : seconds.charAt(0);
  const secondsToRound = parseInt(secondsLength === 1 ? seconds : seconds.charAt(1));
  const secondsUnit = inRange(0, 2)(secondsToRound) ? 0 : inRange(2, 4)(secondsToRound) ? 2 : inRange(4, 6)(secondsToRound) ? 4 : inRange(6, 8)(secondsToRound) ? 6 : 8;
  return `${d.getFullYear()}${d.getMonth()}${d.getDate()}${d.getHours()}${d.getMinutes()}${secondsTens}${secondsTens}${secondsUnit}`;
};

exports.generateTwoSecondId = generateTwoSecondId;

const hashId = id => (0, _md.default)(id.trim().toLowerCase()).toString();

exports.hashId = hashId;