"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const initialState = {
  nonRecurringCosts: {},
  recurringCosts: {},
  benefits: {},
  editted_nonRecurringCosts: {},
  editted_recurringCosts: {},
  editted_benefits: {}
};
var _default = initialState;
exports.default = _default;